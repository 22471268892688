<template>
  <v-tour
    :callbacks="callbacks"
    name="onboarding"
    :options="{ startTimeout: 100 }"
    :steps="steps"
  >
    <template #default="tour">
      <div
        v-for="(step, index) of tour.steps"
        :key="index"
      >
        <transition name="fade">
          <v-step
            v-if="tour.currentStep === index"
            class="tour"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :step="step"
            :stop="tour.stop"
          >
            <template #actions>
              <icon
                class="tour__close"
                type="x"
                @click="endTour('close', step)"
              />
              <div class="tour__footer">
                <div class="tour__steps">
                  <SlideBullets
                    v-if="hasSteper"
                    :total="steps.length"
                    :current="tour.currentStep"
                  />
                </div>
                <div class="tour__actions">
                  <SButton
                    v-if="tour.isFirst"
                    variation="tertiary"
                    @click="endTour('skip', step)"
                  >
                    Sair do tutorial
                  </SButton>
                  <SButton
                    v-if="!tour.isFirst"
                    variation="secondary"
                    icon-left="arrow-left"
                    @click="previousStep(tour, step)"
                  >
                    Voltar
                  </SButton>

                  <SButton
                    v-if="!tour.isLast"
                    variation="primary"
                    icon-right="arrow-right"
                    @click="nextStep(tour, step)"
                  >
                    Avançar
                  </SButton>
                  <SButton
                    v-if="tour.isLast && steps.length > 1"
                    class="tour__stop"
                    variation="primary"
                    @click="finishTutorial()"
                  >
                    Finalizar
                  </SButton>
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </div>
    </template>
  </v-tour>
</template>

<script>
import { mapGetters } from 'vuex'
import { SButton } from '@sas-te/alfabeto-vue'
import SlideBullets from '@/components/SlideBullets'

const TOUR_ACTIVE_CLASS = 'tour-active'
const BODY_TOUR = 'in-tour'

export default {
  name: 'TourTooltip',
  components: {
    SButton,
    SlideBullets,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    hasSteper: {
      type: Boolean,
      default: false,
    },
    backdropElementId: {
      type: String,
      default: 'eurekaapp',
    },
  },
  data() {
    return {
      callbacks: {
        onStart: this.onStartTour,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep,
        onStop: this.onStop,
      },
    }
  },
  computed: {
    ...mapGetters([ 'onboarding' ]),
  },
  mounted() {
    this.$tours.onboarding.start()
  },
  methods: {
    onStartTour() {
      if (this.steps[0].target !== '#v-step-0') {
        const curStepEl = document.querySelector(this.steps[0].target)

        if (curStepEl) {
          document.querySelector(`#${this.backdropElementId}`).classList.add(BODY_TOUR)
          curStepEl.classList.add(TOUR_ACTIVE_CLASS)
        }
      }
    },
    onNextStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const nextStepEl = document.querySelector(this.steps[step + 1].target)

      if (curStepEl) {
        curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      }

      if (nextStepEl) {
        nextStepEl.classList.add(TOUR_ACTIVE_CLASS)
      }
    },
    onPreviousStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const prevStepEl = document.querySelector(this.steps[step - 1].target)

      if (curStepEl) {
        curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      }

      if (prevStepEl) {
        prevStepEl.classList.add(TOUR_ACTIVE_CLASS)
      }
    },
    finishTutorial() {
      this.$tours.onboarding.finish()
      this.$emit('finish')
    },
    endTour(type, step) {
      this.$tours.onboarding.stop()
      this.$emit('skip', { type, step })
    },
    onStop() {
      document.querySelector(`#${this.backdropElementId}`).classList.remove(BODY_TOUR)
      if (document.querySelector(`.${TOUR_ACTIVE_CLASS}`)) {
        document
          .querySelector(`.${TOUR_ACTIVE_CLASS}`)
          .classList.remove(TOUR_ACTIVE_CLASS)
      }
    },
    previousStep(tour, step) {
      this.$emit('previousStep', { tour, step })
      tour.previousStep()
    },
    nextStep(tour, step) {
      this.$emit('nextStep', { tour, step })
      tour.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>

.tour {
  background: $color-white;
  box-shadow: 0px 16px 24px rgba(52, 60, 88, 0.4);
  border-radius: $border-radius-m;
  color: $color-ink;
  text-align: left;
  min-width: min(400px, 100vw);
  position: relative;

  &__footer {
    display: flex;
    flex-direction: column;
  }

  ::v-deep &__close {
    position: absolute;
    top: 10px;
    right: 21px;
    cursor: pointer;
  }

  &__steps {
    margin-bottom: $size-m;
  }

  &__actions {
    @include flex-space-between();
    border-top: 1px solid $color-ink-lightest;
    padding-top: 14px;
  }

  // content tour components
  ::v-deep &__title {
    font-size: $line-height-heading;
    margin-bottom: $size-s;
  }

  ::v-deep &__content {
    font-size: .875rem;
  }
}
</style>
