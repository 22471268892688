<template>
  <div class="ancestral-arena-student">
    <g-alert
      v-if="error"
      :message="error.message"
      :icon="error.icon"
      :is-closeable="error.isCloseable"
      @close="error = null"
    />
    <GHeaderGame
      :title="title"
      class="ancestral-arena-student__header"
    >
      <template #menu>
        <MagnaMissionSatisfactionSurvey v-if="canReplySurvey" />
        <div class="ancestral-arena-student__menu">
          <GButton
            v-if="!isMagnaMissionReport"
            class="ancestral-arena-student__menu__items"
            variation="secondary"
            icon-left="x"
            :icon-stroke="3"
            @click="showLeaveMagnaMissionModal"
          >
            {{ closeButtonText }}
          </GButton>
        </div>
      </template>
    </GHeaderGame>
    <router-view
      :questions="questions"
      :answer-updating="answerUpdating"
      :mission-title="title"
      @error-ocurred="setError"
      @error-resolved="error = null"
      @setQuestions="handleSetQuestions"
      @setQuestionsById="handleSetQuestionById"
      @getQuestions="getHomeQuestions"
    />
    <div
      v-if="error"
      class="overlay-error"
    />
    <DangerMagnaMissionModal
      v-if="isModalVisible"
      class="magna-mission-modal"
      title="Deseja sair da missão?"
      description="Você consegue voltar enquanto a missão estiver ativa,
      mas talvez perca a oportunidade de responder algumas questões enquanto estiver fora."
      button-name="Sair"
      @confirm="leaveTheMatch"
      @close="closeMagnaMissionModal"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { pauseBackground } from '@/utils/soundEffects'
import mediaQueries from '@/mixins/mediaQueries'
import MagnaMissionSatisfactionSurvey from '@/components/MagnaMissionSatisfactionSurvey'
import GHeaderGame from '@/components/AncestralArena/GHeaderGame'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import arena from '@/service/arena'
import { debounce } from 'lodash-es'

const DEBOUNCE_TIME = 1000

export default {
  name: 'AncestralArenaStudentHomeLayout',
  components: {
    GHeaderGame,
    GButton,
    GAlert,
    DangerMagnaMissionModal,
    MagnaMissionSatisfactionSurvey,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      isModalVisible: false,
      error: null,
      questions: { questions: [], title: '', statement: '' },
      answerUpdating: false,
    }
  },
  computed: {
    title() {
      const title = this.questions?.title

      return title ?? this.$route.params.title
    },
    closeButtonText() {
      return this.mq_xl__mf ? 'Sair da partida' : 'Sair'
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    canReplySurvey() {
      return false
    },
    isMagnaMissionReport() {
      return this.$route.name === 'mission-summary-page'
    },
  },
  created() {
    this.debouncedPutQuestionAnswer = debounce(this.putQuestionAnswerDebounced, DEBOUNCE_TIME)
  },
  mounted() {
    pauseBackground()
    this.getHomeMissionsWhenNotReportPage()
  },
  methods: {
    ...mapActions('globalToast', [ 'showToast' ]),
    getHomeMissionsWhenNotReportPage() {
      if (!this.isMagnaMissionReport) {
        this.getHomeQuestions()
      }
    },
    showErrorToast({ text, description } = { text: '', description: '' }) {
      this.showToast({
        text,
        description,
        variation: 'error-secondary',
        asset: 'char/tata-sad.svg',
        autoClose: 7500,
      })
    },
    isMissionFinished() {
      this.$router.replace({
        name: 'teacher-finished-mission',
      })
    },
    handleSetQuestions(questions) {
      this.questions = questions
    },
    async putQuestionAnswerDebounced(params) {
      try {
        await arena.putQuestionAnswer(params)
      } catch (error) {
        this.handlePutQuestionError(error)
      } finally {
        this.answerUpdating = false
      }
    },
    handlePutQuestionError(error) {
      if (error.response.status === 422) {
        this.isMissionFinished()

        return
      }

      this.showErrorToast({
        text: 'Ocorreu um erro ao salvar a resposta',
        description: 'Tente novamente mais tarde.',
      })
    },
    async handleSetQuestionById({ id, data }) {
      this.questions.questions = this.questions.questions.map((question) => {
        if (question.id === id) {
          return { ...question, ...data }
        }

        return question
      })

      const params = {
        assignmentCode: this.questions.assignmentCode,
        questionId: id,
        markedOption: data?.markedOption ?? -1,
      }

      this.answerUpdating = true
      await this.debouncedPutQuestionAnswer(params)
    },
    async getHomeQuestions() {
      try {
        const questionsData = await arena.getHomeQuestions(this.questionnaireCode)
        this.questions = questionsData?.data
      } catch (error) {
        if (error.response?.status === 422) {
          this.isMissionFinished()

          return
        }

        this.showErrorToast({
          text: 'Ocorreu um erro ao carregar as questões',
          description: 'Tente novamente mais tarde',
        })
        this.leaveTheMatch()
      }
    },
    setError(error) {
      this.error = error
    },
    leaveTheMatch() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.studentLeftHomeMission,
      })
      this.$router.replace({
        name: 'arena',
      })
    },
    showLeaveMagnaMissionModal() {
      this.isModalVisible = true
    },
    closeMagnaMissionModal() {
      this.isModalVisible = false
    },
  },
}

</script>
<style lang="scss" scoped>
.overlay-error {
  @include cover;
  z-index: 9998;
  background-color: #393939;
  mix-blend-mode: color;
}

.ancestral-arena-student {
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: $zindex-modal;
  display: flex;
  flex-direction: column;

  background: {
    image: url('~@/assets/ancestral-arena/background.png');
    position: bottom;
    size: cover;
    attachment: fixed;
    color: $eureka-color-ink;
  }

  &__header {
    padding: $size-m $size-l $size-s;

    @media screen and (max-width: 768px) {
      padding: $size-s $size-m $size-xs;
      ::v-deep .g-header-game__title {
        font-size: 1.6rem;
      }
    }
  }

  &__menu {
    padding: 0 15px;

    @media screen and (max-width: 568px) {
      padding: 0;
    }

    &__items {
      border-radius: $border-radius-xs;
      cursor: pointer;
      display: block;
      padding: 25px 20px;
      position: relative;
      @include flex-center;
      @include transition($speed-x-fast);

      ::v-deep &.g-button {
        color: $game-background-color;
      }

      &:hover {
        background: rgba($color-black, 0.2);
      }

      &:active {
        background: rgba($color-black, 0.2);
      }
    }
  }

  &__content {
    @include flex-column-center;
    height: 80vh;
  }

  &__text {
    font-family: Rowdies;
    font-size: $font-size-heading-2;
    color: $color-white;
    text-align: center;
  }
}
</style>
