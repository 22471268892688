<template>
  <div class="error-message">
    <p class="error-message__description">
      {{ message }}
    </p>
    <GButton
      class="error-message__button"
      :asset="imageName"
      @click="$emit('click')"
    >
      {{ action }}
    </GButton>
  </div>
</template>

<script>
import GButton from './GButton'

export default {
  name: 'ErrorMessage',
  components: {
    GButton,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    imageName: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.error-message {
  text-align: center;

  &__description {
    margin: 0 auto $size-l;
    line-height: 1;
    font: {
      family: Rowdies;
      weight: $font-weight-light;
      size: 1.5rem
    }
    color: $eureka-color-ink;
  }

  &__button {
    margin-bottom: $size-xl;
    font: {
      family: Rowdies;
      size: 1.25rem
    }

    ::v-deep &.g-button {
      border: none;
    }

    ::v-deep .g-button__text {
      font-weight: $font-weight-regular;
      text-shadow: 0 1px 0 $color-black;
    }
  }
}
</style>
