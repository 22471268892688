<template>
  <div class="profile-card">
    <div class="profile-card__picture">
      <skeleton-loader
        width="136px"
        height="136px"
        border-radius="14px"
      />
    </div>
    <div class="profile-card__body">
      <div class="profile-card__body__title">
        <skeleton-loader
          width="343px"
          height="24px"
          border-radius="8px"
        />
      </div>
      <div class="profile-card__body__experience">
        <div class="profile-card__body__experience__item">
          <skeleton-loader
            width="40px"
            height="40px"
            border-radius="31px"
          />
          <skeleton-loader
            width="291px"
            height="24px"
            border-radius="35px"
          />
        </div>
        <div class="profile-card__body__experience__item">
          <skeleton-loader
            width="32px"
            height="32px"
            border-radius="8px"
          />
          <skeleton-loader
            width="100px"
            height="16px"
            border-radius="35px"
          />
        </div>
      </div>
      <div class="profile-card__body__wallet">
        <skeleton-loader
          width="64px"
          height="24px"
          border-radius="35px"
        />
        <skeleton-loader
          width="64px"
          height="24px"
          border-radius="35px"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileCard',
}
</script>

<style lang="sass" scoped>
.profile-card
  width: 100%
  background: #FBF1E1
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 20px #C19267
  border-radius: 24px
  display: grid
  grid-template-columns: auto 1fr
  padding: 13px 17px

  &__body
    margin-left: 30px

    &__title
      margin-bottom: $size-s

    &__experience
      margin-bottom: 10px
      display: grid
      grid-template-columns: max-content max-content
      gap: $size-m
      justify-items: center
      align-items: center

      &__item
        display: grid
        grid-template-columns: max-content max-content
        align-items: center

    &__wallet
      display: grid
      grid-template-columns: max-content max-content
      gap: $size-s

::v-deep .sas-skeleton-loader
  background: #CBA976 !important
  opacity: 0.5 !important
</style>
