function getGradeId(getters) {
  const { data: currentGrade } = getters.currentGrade

  if (currentGrade) {
    return currentGrade.id
  }

  return getters.userGrades.data?.length ? getters.userGrades.data[0].id : null
}

export default getGradeId
