<template>
  <MagnaMissionReportLayout
    :is-loading="report.loading"
    :footer-button-text="closeButtonText"
    :footer-button-size="closeButtonSize"
    :button-image-name="buttonImage"
    @click="handleAction"
  >
    <div class="magna-student-report">
      <section
        v-if="report.error"
        class="magna-student-report__message"
      >
        <h2 class="magna-student-report__message--title">
          Não foi possível carregar o relatório
        </h2>
        <p class="magna-student-report__message--description">
          Tente recarregar essa página ou acesse o relatório
          pela listagem de missões.
        </p>
      </section>
      <div
        v-else
        class="magna-student-report__content"
      >
        <HitRate
          :title="'Seus acertos'"
          :hit-rate="Math.round(report.data.hitPercentage)"
          :correct="report.data.correctAnswers"
          :total="report.data.totalQuestions"
          v-bind="dimension"
        >
          <p class="description">
            Acompanhe o gabarito em sala com <br> o seu professor
          </p>
        </HitRate>
      </div>
      <img
        class="magna-student-report__banner"
        :src="tataImage"
        alt=""
      >
    </div>
  </MagnaMissionReportLayout>
</template>

<script>
import { mapActions } from 'vuex'

import tataThrilledRight from '@/assets/char/tata-thrilled.svg'
import tataThinkingRight from '@/assets/char/tata-thinking.svg'
import tataThrilledLeft from '@/assets/char/tata-thrilled-left.svg'
import tataThinkingLeft from '@/assets/char/tata-thinking-left.svg'
import mediaQueries from '@/mixins/mediaQueries'
import arenaApi from '@/service/arena'
import MagnaMissionReportLayout from '@/views/AncestralArena/layouts/MagnaMissionReportLayout'
import HitRate from '@/components/AncestralArena/HitRate'

export default {
  name: 'MissionSummaryPage',
  components: {
    HitRate,
    MagnaMissionReportLayout,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      report: {
        loading: null,
        error: null,
        data: {},
      },
    }
  },
  computed: {
    dimension() {
      return this.mq_xl__mf ? {}
        : {
          size: 160,
          stroke: 18,
        }
    },
    buttonImage() {
      return this.report.error ? 'refresh.svg' : ''
    },
    closeButtonText() {
      if (this.report.error) return 'Recarregar'

      return this.mq_xl__mf ? 'Fechar Missão' : 'Fechar'
    },
    closeButtonSize() {
      return this.mq_xl__mf ? 'large' : 'medium'
    },
    tataImage() {
      if (this.report.error || this.report.data.correctAnswers <= 0) {
        return this.mq_xl__mf ? tataThinkingRight : tataThinkingLeft
      }

      return this.mq_xl__mf ? tataThrilledRight : tataThrilledLeft
    },
    assignmentCode() {
      return this.$route.params.assignmentCode
    },
    totalQuestions() {
      const { correctAnswers, wrongAnswers } = this.report.data

      return correctAnswers + wrongAnswers
    },
  },
  created() {
    this.fetchReport()
  },
  methods: {
    ...mapActions([ 'setMagnaMission', 'globalToast', [ 'showToast' ]]),
    showErrorToast({ text, description } = { text: '', description: '' }) {
      this.showToast({
        text,
        description,
        variation: 'error-secondary',
        asset: 'char/tata-sad.svg',
        autoClose: 7500,
      })
    },
    handleAction() {
      if (this.report.error) {
        this.fetchReport()
      } else {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.studentFinishMagnaMission,
        })

        this.$router.replace({ name: 'arena' })
      }
    },
    async fetchReport() {
      this.report.loading = true
      try {
        const { data } = await arenaApi.getAssignmentReport(this.assignmentCode)
        this.report = { data, loading: false }
      } catch (error) {
        this.report.error = error
      } finally {
        this.report.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-student-report {
  .description {
    color: $color-ink-light;
    font-family: Lato;
    font-size: $font-size-heading-5;
    font-weight: $font-weight-medium;

    @media screen and (max-height: 750px) {
      font-size: $font-size-heading-6-small;
    }
  }

  &__banner {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    max-height: 40vw;
    width: auto;

    @media screen and (max-width: 991px) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      height: 45%;
      max-height: 50vw;
    }
  }

  &__message {
    width: 40%;
    margin-left: 40px;

    @media screen and (max-width: 991px) {
      width: 80%;
      margin: 0 auto;
      text-align: center;

      &--title {
        margin-top: 72px;
        font-size: $font-size-heading-4;
        line-height: $line-height-reset;
      }

      &--description {
        margin-top: $size-s;
        font-size: $font-size-m;
      }
    }

    &--title {
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: 2.5rem;
      }
      line-height: $line-height-heading;
      color: $eureka-color-ink;
    }

    &--description {
      margin-top: $size-m;
      font-family: Lato;
      font-size: $font-size-heading-5;
      font-weight: $font-weight-medium;
      line-height: $line-height-text;
      color: $eureka-color-ink;
      letter-spacing: 0.0275rem;
    }
  }
}

@media screen and (max-width: 991px) {
  ::v-deep .hit-rate {
    &__body {
      gap: 8px;
      flex-direction: column-reverse;
    }

    &__chart {
      margin: 0;
    }

    &__text {
      text-align: center;

      &__title {
        font-size: $font-size-heading-4;
        font-weight: $font-weight-regular;
      }

      &__count {
        font-size: $font-size-m;
        line-height: $line-height-text;
      }
    }
  }

  .magna-report {
    height: 65vh;
    max-height: unset;

    @media screen and (max-width: 579px) {
      width: 100%;
      margin-top: auto;
    }

    ::v-deep .g-frame__inner {
      display: block !important;
      height: 65vh;
    }

    ::v-deep &__content {
      max-width: 480px;
      height: 100%;

      @media screen and (max-width: 579px) {
        max-width: unset;
        width: 100%;
      }

      &__header {
        margin-top: 2rem;
        text-align: center;

        &__title {
          margin: 0;
        }
      }

      &__footer {
        position: absolute;
        bottom: 0;
        right: 0;

        .g-button:last-child {
          min-width: 150px;
          margin: 0 1rem 1rem 0;
        }

        .g-button__text {
          font-family: Rowdies;
          font-size: $font-size-m;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
</style>
