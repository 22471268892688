<template>
  <button
    class="shop-button"
    aria-label="Ir para o mercado"
    @click="$emit('click')"
  >
    <img
      class="shop-button__image"
      src="@/assets/shop/icon.svg"
    >
    <div class="shop-button__text">
      Mercado
    </div>
  </button>
</template>

<script>
export default {
  name: 'ShopButton',
}
</script>

<style lang="sass" scoped>
.shop-button
  background: #FBF1E1
  border: 4px solid rgba(255, 255, 255, 0.49)
  box-shadow: 0px 7px 24px rgba(227, 143, 96, 0.45), 0px 3px 0px #CBA976
  border-top-left-radius: 73px
  padding: 8px 24px 8px 32px
  cursor: pointer

  &:focus
    outline: none

  &__text
    font-weight: bold
    font-size: 18px
    line-height: 1
</style>
