import mediaQueries from './mediaQueries'

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      isSmallScreen: this.mq_s,
      isMediumScreen: this.mq_m,
      isLargeScreen: this.mq_l,
    }
  },
  mixins: [ mediaQueries ],
  created() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth

      if (window.innerWidth <= 991) {
        this.isLargeScreen = true
      } else {
        this.isLargeScreen = false
      }

      if (window.innerWidth <= 768) {
        this.isMediumScreen = true
      } else {
        this.isMediumScreen = false
      }

      if (window.innerWidth <= 579) {
        this.isSmallScreen = true
      } else {
        this.isSmallScreen = false
      }
    },
  },
}
