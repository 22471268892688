<template>
  <div class="congratulations-icon">
    <div class="congratulations-icon__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CongratulationsIcon',
}
</script>

<style lang="scss" scoped>
.congratulations-icon {
  @include flex-center;
  width: 100%;
  height: 33vh;
  position: relative;

  &__content {
    @include flex-center;
    width: 100%;
    height: 33vh;

    &:before {
      content: '';
      position: absolute;
      width: 350px;
      height: 350px;
      z-index: -1;
      background-image:
        url(~@/assets/retrospective/aura-effect/top-left.svg),
        url(~@/assets/retrospective/aura-effect/top-center.svg),
        url(~@/assets/retrospective/aura-effect/top-right.svg),
        url(~@/assets/retrospective/aura-effect/bottom-left.svg),
        url(~@/assets/retrospective/aura-effect/bottom-center.svg),
        url(~@/assets/retrospective/aura-effect/bottom-right.svg),
        url(~@/assets/retrospective/flower/left.svg),
        url(~@/assets/retrospective/flower/right.svg);
      background-position:
        left top 35px,
        center top 10px,
        right top 35px,
        left bottom 35px,
        center bottom 10px,
        right bottom 35px,
        left 5px bottom 70px,
        right 5px bottom 70px;
      background-repeat: no-repeat;
      opacity: 0.75;
    }
  }
}
</style>
