<template>
  <div class="xp-display">
    <div class="xp-display__container">
      <img
        class="xp-display__icon"
        :src="require(`@/assets/${variation}-xp.svg`)"
      >
      <div
        v-if="level"
        class="xp-display__rank"
      >
        {{ level }}
      </div>
    </div>
    <ResourceBar
      data-testid="xp"
      :value="barAmount"
      :total="barTotal"
      :frame="hasFrame"
    >
      <template v-if="xpAmount">
        <div class="score-amount">
          {{ barAmount }} / {{ barTotal }} xp
        </div>
      </template>
    </ResourceBar>
  </div>
</template>
<script>
import ResourceBar from './ResourceBar'

export default {
  name: 'XpDisplay',
  components: {
    ResourceBar,
  },
  props: {
    xpAmount: {
      type: Boolean,
      required: false,
      default: false,
    },
    experience: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      required: false,
      default: 'circle',
      validator: (value) => (
        value.match(/(circle|polygon)/)
      ),
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    hasFrame: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    barAmount() {
      return this.experience.amount
    },
    barTotal() {
      return this.experience.nextLevelValue
    },
  },
}
</script>
<style lang="scss" scoped>
.xp-display {
  @include flex-center-start;

  &__container {
    display: flex;
    align-content: center;
    position: relative;
    width: 40px;
    height: 40px;
  }

  &__icon {
    z-index: 1;
    margin-right: -36px;
    width: 40px;
    height: 40px;
    position: relative;
  }

  &__rank {
    position: absolute;
    left: 14px;
    top: 12px;
    z-index: 2;

    color: $color-white;
    font-style: normal;
    font-size: 16px;
    font-weight: 900;
    line-height: 16px;
    text-shadow: 0px 2px 0px #31261D;
  }

  ::v-deep .resource-bar {
    flex-grow: 1;
    margin-left: -28px;

    &__slot {

      &__padding {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    &__bar {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      &::after {
        left: 0;
      }
    }
  }
}

.score-amount {
  text-shadow: 0px 2px 0px #31261D;
}

</style>
