<template>
  <div
    :class="['ancestral-arena-enigma', `--${variation}`]"
  >
    <template v-if="isLoading">
      <section class="ancestral-arena-enigma__header">
        <span class="ancestral-arena-enigma__header__title">
          <span class="ancestral-arena-enigma__header__title__current">
            <skeleton-loader
              border-radius="30px"
              opacity="0.5"
              width="100px"
              height="32px"
            />
          </span>
        </span>
        <skeleton-loader
          border-radius="30px"
          opacity="0.5"
          width="140px"
          height="36px"
        />
      </section>
      <section class="ancestral-arena-enigma__content">
        <div class="ancestral-arena-enigma__content__statement__is-loading">
          <skeleton-loader
            v-for="(width, index) in skeletonLoaderWidthsList"
            :key="index"
            border-radius="30px"
            opacity="0.5"
            :width="width"
            height="20px"
          />
        </div>

        <div class="ancestral-arena-enigma__content__alternatives">
          <AncestralArenaCardQuestion
            v-for="(_, i) in 4"
            :key="i"
            :option="{}"
            :loading="isLoading"
          />
        </div>
      </section>
    </template>

    <template v-else-if="error">
      <section class="ancestral-arena-enigma__header">
        <span class="ancestral-arena-enigma__header__title">
          <span class="ancestral-arena-enigma__header__title__current">
            <skeleton-loader
              border-radius="30px"
              opacity="0.5"
              width="100px"
              height="32px"
            />
          </span>
        </span>
        <skeleton-loader
          border-radius="30px"
          opacity="0.5"
          width="140px"
          height="36px"
        />
      </section>
      <section class="ancestral-arena-enigma__content">
        <AncestralArenaEnigmaError
          v-bind="errorState"
          @click="$emit('retry')"
        />
      </section>
    </template>

    <template v-else>
      <section class="ancestral-arena-enigma__header">
        <span class="ancestral-arena-enigma__header__title">
          <span class="ancestral-arena-enigma__header__title__current">{{ enigmaPosition }}</span>
          <span
            v-if="totalEnigmas"
            class="ancestral-arena-enigma__header__title__total"
          >
            /{{ totalEnigmas }}
          </span>
        </span>
        <slot name="enigmaAction" />
      </section>
      <slot name="layout">
        <section class="ancestral-arena-enigma__content">
          <div
            class="ancestral-arena-enigma__content__statement"
            v-html="magnaMissionEnigma.statement"
          />
          <div
            v-if="magnaMissionEnigma"
            class="ancestral-arena-enigma__content__alternatives"
          >
            <AncestralArenaCardQuestion
              v-for="option in magnaMissionEnigma.options"
              :key="option.id"
              :option="option"
              :loading="isLoading"
              :variation="option.variation"
              :disabled="option.isDisabled"
              @select-option="emitSelectedOption"
            />
          </div>
        </section>
      </slot>
    </template>
  </div>
</template>

<script>
import { skeletonLoaderWidths } from '@/utils/ancestralArenaUtils'
import AncestralArenaCardQuestion from '@/components/AncestralArena/AncestralArenaCardQuestion/AncestralArenaCardQuestion'
import AncestralArenaEnigmaError from './AncestralArenaEnigmaError'

export default {
  name: 'AncestralArenaEnigma',
  components: {
    AncestralArenaCardQuestion,
    AncestralArenaEnigmaError,
  },
  props: {
    magnaMissionEnigma: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: String,
      default: () => {},
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => [ 'primary', 'secondary' ].includes(value),
    },
  },
  data() {
    return {
      skeletonLoaderWidthsList: skeletonLoaderWidths,
      errorState: {
        imagePath: 'char/cora/cora-worried.svg',
        description: this.$t('ancestralArena.magnaMissionRoom.error.unknown.message'),
        message: this.$t('ancestralArena.magnaMissionRoom.error.unknown.title'),
        action: {
          text: this.$t('ancestralArena.magnaMissionRoom.error.unknown.retryButton'),
          icon: 'refresh-ccw',
        },
      },
    }
  },
  computed: {
    isLastQuestion() {
      if (this.enigmaPosition === 0 && this.totalEnigmas === 0) {
        return false
      }

      return this.enigmaPosition === this.totalEnigmas
    },
    enigmaPosition() {
      return this.magnaMissionEnigma?.magnaMissionInfo?.enigmaPosition || 0
    },
    totalEnigmas() {
      return this.magnaMissionEnigma?.magnaMissionInfo?.totalEnigmas || 0
    },
  },
  watch: {
    isLastQuestion: {
      handler() {
        if (this.isLastQuestion) {
          this.$emit('last-question')
        }
      },
      immediate: true,
    },
  },
  methods: {
    emitSelectedOption(option) {
      this.$emit('select-option', option)
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-enigma {
  font-family: Rowdies;
  border-radius: $size-l;
  max-height: 75vh;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    overflow: auto;
    max-height: 100%;
  }

  @media screen and (max-width: 579px) {
    width: calc(100vw - 40px);
  }

  &__header {
    @include flex-space-between;
    padding: $size-s $size-l;

   &__title {
    display: flex;
    align-items: flex-start;
    line-height: 100%;
    font: {
      size: $font-size-heading-4;
      weight: bold;
    }

    &__current {
      font-size: 40px;
      line-height: 100%;
    }
   }
  }

  &__content {
    max-height: 70vh;
    box-sizing: border-box;
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: 10px;
    }

    @media screen and (max-width: 768px) {
      padding: $size-s !important;
    }

    &__statement {
      height: 100%;
      width: 100%;
      overflow-y: auto;

      word-wrap: break-word;
      font-weight: $font-weight-regular;

      @media screen and (max-width: 991px) {
        overflow: visible;
      }

      ::v-deep img {
        max-width: 100%;
      }

      &__is-loading {
        width: 50%;
        display: flex;
        flex-direction: column;

        & .sas-skeleton-loader {
          margin-bottom: $size-xxs;
        }
      }
    }

    &__alternatives {
      width: fit-content;
      overflow-y: auto;
      min-width: 300px;

      margin-left: $size-xxl;
      padding: 0 4px 4px 0;

      @media screen and (max-width: 991px) {
        width: 100%;
        min-width: unset;
        margin: 0 !important;
        padding: 0 !important;
        overflow-y: visible;
      }

      & > .ancestral-arena-card-question {
        margin-bottom: $size-m;

        @media screen and (max-width: 991px) {
          margin-bottom: $size-s;
        }
      }
    }
  }

  &.--primary {
    background: $color-white;
    color: $eureka-color-ink;
    box-shadow: 0px 20px 36px -8px rgba(0, 0, 0, 0.42);

    &__header {
      background: $eureka-color-base-lighter;
      border: {
        radius: $size-l $size-l 0 0;
        bottom: 1px solid $eureka-color-base-darker;
      }
    }
  }
  &.--secondary {
    background: transparent;
    color: $color-white;
    box-shadow: none;

    &__header {
      background: transparent;
      border: none;
    }
  }
}

.g-button {
  border: 0;

  &:disabled {
    border: 0 !important;
  }
}
</style>
