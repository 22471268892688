<template>
  <ContentLoading v-if="magnaMission.loading" />
  <CreateMagnaMissionQuestionSelector
    v-else-if="isQuestionSelectorVisible"
    :is-save-disabled="isSaveDisabled"
    @add-sas-question="addSASQuestion"
    @add-my-question="addNewQuestion"
  />
  <div
    v-else
    class="create-magna-mission-content-tab"
  >
    <ThumbnailsList
      class="create-magna-mission-content-tab__thumbnails-list"
      :thumbnails="thumbnails"
      :is-removable="isNotChallengeReinforcement"
      :is-visible-add-question-button="isNotChallengeReinforcement"
      @select="selectQuestion"
      @delete-thumb="setItemToRemove"
      @set-item-order="setItemOrder"
      @add-new-question="addNewQuestion"
    />
    <div class="create-magna-mission-content-tab__editor-area">
      <QuestionSasBadge v-show="showQuestionSasBadge" />
      <div class="create-magna-mission-content-tab__editor-area__card">
        <div class="create-magna-mission-content-tab__editor-area__card__header">
          <div class="create-magna-mission-content-tab__editor-area__card__header--top-content">
            <span class="order">
              {{ selectedQuestionOrder }}
            </span>
            <GButton
              v-if="isMobile"
              class="delete-btn"
              size="medium"
              asset="trash-filled.svg"
              variation="secondary"
              @click="setItemToRemove(selectedThumbnail)"
            >
              Remover
            </GButton>
            <img
              v-if="!isSasQuestionSelectedThumbnail"
              v-tooltip.top="'Minha missão'"
              class="mission-type-icon"
              src="@/assets/ancestral-arena/my-mission-icon.svg"
              alt=""
            >
            <img
              v-else
              v-tooltip.top="'Questão SAS Plataforma de Educação'"
              class="mission-type-icon"
              src="@/assets/ancestral-arena/sas-mission-icon.svg"
              alt=""
            >
          </div>

          <div class="actions">
            <TimeForm
              v-if="showTimerByFeatureToggle"
              :is-checked="selectedThumbnail.hasTimer"
              :time="selectedThumbnail.timer"
              @change-response-time-flag="setResponseTimeFlag"
              @change-time="setTime"
            />
            <GButton
              v-if="isNotChallengeReinforcement && !isLargeScreen"
              class="delete-btn"
              size="medium"
              asset="trash-filled.svg"
              variation="secondary"
              @click="setItemToRemove(selectedThumbnail)"
            >
              Remover
            </GButton>
          </div>
        </div>
        <div class="create-magna-mission-content-tab__editor-area__card__body">
          <div
            v-if="!isMobile"
            :class="[
              'create-magna-mission-content-tab__editor-area__card__body__question-wrapper',
              { '--error': questionHasWordingError },
            ]"
          >
            <div
              class="tour__mission-statement"
              data-tour="new-magna-mission-statement"
            >
              <RichTextEditor
                v-if="isNotChallengeReinforcement && !isSasQuestionSelectedThumbnail"
                v-model="editorContentHTML"
                :error="hasWordingError"
                class="question-editor"
                placeholder="Escreva o enunciado da questão aqui."
                @content-text-change="setEditorContentText"
              />
              <ScrollContainer
                v-else
                v-html="editorContentHTML"
              />
            </div>
            <AncestralArenaErrorReport
              v-if="questionHasWordingError"
              :error-message="wordingErrorMessage"
            />
          </div>
          <div
            v-else
            :class="[
              'create-magna-mission-content-tab__editor-area__card__body__question-wrapper-mobile',
              { '--error': questionHasWordingError },
            ]"
          >
            <div
              class="tour__mission-statement"
              data-tour="new-magna-mission-statement"
            >
              <RichTextEditor
                v-if="isNotChallengeReinforcement && !isSasQuestionSelectedThumbnail"
                v-model="editorContentHTML"
                :error="hasWordingError"
                class="question-editor"
                placeholder="Escreva o enunciado da questão aqui."
                @content-text-change="setEditorContentText"
              />
              <ScrollContainer
                v-else
                v-html="editorContentHTML"
              />
            </div>
            <AncestralArenaErrorReport
              v-if="questionHasWordingError"
              :error-message="wordingErrorMessage"
            />
          </div>
          <div
            class="create-magna-mission-content-tab__alternatives tour__mission-alternatives"
            data-tour="new-magna-mission-alternatives"
          >
            <AncestralArenaEditableAlternativesList
              v-if="isNotChallengeReinforcement && !isSasQuestionSelectedThumbnail"
              v-model="alternatives"
              class="alternative-list"
              :selected-alternative="selectedAlternative"
              :error-messages="alternativesErrorMessages"
              @selected-alternative-changes="setSelectedAlternative"
            />
            <template v-else>
              <AncestralArenaCardQuestion
                v-for="(option, index) in renderQuestionOptions"
                :key="option.id"
                :option="option"
                :variation="option.variation"
                :disabled="option.isDisabled"
                class="create-magna-mission-content-tab__alternative-item"
                :data-tour="`new-magna-mission-correct-alternative-${index}`"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <DangerMagnaMissionModal
      v-if="isNotChallengeReinforcement && isDeleteModalVisible"
      title="Deseja remover a questão?"
      description="Você não conseguirá desfazer essa ação e precisará buscar
      novamente no banco SAS Educação para adicioná-la na missão."
      button-name="Remover"
      @confirm="removeThumbnail"
      @close="toggleDeleteMagnaMissionModal"
    />
    <InfoMagnaMissionModal
      v-if="isInfoModalVisible"
      title="Não é possível remover a última questão"
      description="Para conseguir jogar com a turma você precisa
      ter pelo menos uma questão na missão."
      :button-name="$t('commons.close')"
      @confirm="toggleInfoMagnaMissionModal"
      @close="toggleInfoMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import isNotEmpty from '@/utils/isNotEmpty'
import { isEmpty } from 'lodash'

import { possibleErrorsOnCreateMissions, possibleLetters } from '@/utils/ancestralArenaUtils'
import checkIfValidQuestion from '@/utils/questionValidate'
import ThumbnailsList from '@/components/ThumbnailsList/ThumbnailsList'
import GButton from '@/components/GButton'
import RichTextEditor from '@/components/RichTextEditor'
import ContentLoading from '@/views/AncestralArena/MagnaMissionDetail/partials/ContentLoading'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import AncestralArenaErrorReport from '@/components/AncestralArenaErrorReport/AncestralArenaErrorReport'
import ScrollContainer from '@/components/ScrollContainer'
import AncestralArenaCardQuestion from '@/components/AncestralArena/AncestralArenaCardQuestion/AncestralArenaCardQuestion'
import CreateMagnaMissionQuestionSelector from '@/components/AncestralArena/CreateMagnaMissionQuestionSelector/CreateMagnaMissionQuestionSelector'
import AncestralArenaEditableAlternativesList from '@/components/AncestralArena/AncestralArenaEditableAlternativesList'
import handleResize from '@/mixins/handleResize'
import mediaQueries from '@/mixins/mediaQueries'
import TimeForm from '../TimerForm/TimerForm'
import QuestionSasBadge from './partials/QuestionSasBadge'

const originEnum = {
  TEACHER: 'TEACHER',
  SAS: 'SAS',
}

export default {
  name: 'CreateMagnaMissionContentTab',
  components: {
    ThumbnailsList,
    RichTextEditor,
    DangerMagnaMissionModal,
    InfoMagnaMissionModal,
    AncestralArenaEditableAlternativesList,
    ContentLoading,
    GButton,
    AncestralArenaErrorReport,
    TimeForm,
    ScrollContainer,
    AncestralArenaCardQuestion,
    CreateMagnaMissionQuestionSelector,
    QuestionSasBadge,
  },
  mixins: [ handleResize, mediaQueries ],
  props: {
    magnaMission: {
      type: Object,
      default: null,
    },
    violatedRules: {
      type: Array,
      default: () => [],
    },
    hasTotalTime: Boolean,
    isSaveDisabled: Boolean,
  },
  data() {
    return {
      isDeleteModalVisible: false,
      isInfoModalVisible: false,
      itemToRemove: null,
      selectedQuestionOrder: 1,
      editorContentHTML: '',
      thumbnails: [
      ],
      selectedAlternative: '',
      alternatives: {
        A: {
          value: '',
        },
        B: {
          value: '',
        },
        C: {
          value: '',
        },
        D: {
          value: '',
        },
      },
      currentQuestionHasRequiredAlternatives: false,
      showQuestionSasBadge: false,
    }
  },
  computed: {
    ...mapState({
      showTimerByFeatureToggle: ({ featureToggles }) => featureToggles
        .toggles.eurekaTimer,
      isSasQuestionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaSasQuestions,
      selectedSasQuestionsStore: ({ arena }) => arena.selectedSasQuestions,
    }),
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    isSasQuestionSelectedThumbnail() {
      return this.selectedThumbnail?.origin === originEnum.SAS ?? false
    },
    renderQuestionOptions() {
      if (this.isNotChallengeReinforcement && !this.isSasQuestionSelectedThumbnail) {
        return this.selectedThumbnail.options
      }

      const optionsReadonly = this.alternativesReadonly?.options

      if (optionsReadonly) {
        return optionsReadonly
      }

      return this.selectedThumbnail.options
    },
    isQuestionSelectorVisible() {
      return !this.magnaMission.loading && this.isEmptyThumbnails && this.isEmptyMagnaMissions
    },
    isEmptyThumbnails() {
      return isEmpty(this.thumbnails)
    },
    isEmptyMagnaMissions() {
      return isEmpty(this.magnaMission.data)
    },
    magnaMissionData() {
      return this.magnaMission.data
    },
    nextQuestionOrder() {
      return this.thumbnails.length + 1
    },
    selectedThumbnail() {
      return this.thumbnails
        .find(({ isSelected }) => isSelected) ?? {}
    },
    selectedThumbnailIndex() {
      return this.thumbnails
        .findIndex((thumb) => thumb.order === this.selectedThumbnail.order)
    },
    selectedThumbnailErrors() {
      const selectedThumbnailViolatedRules = this.violatedRules
        .find(({ order }) => order === this.selectedThumbnail.order)
        ?.violatedRules

      return selectedThumbnailViolatedRules || []
    },
    wordingErrors() {
      return this.selectedThumbnailErrors
        .filter(({ type }) => type === 'wording') || []
    },
    alternativesErrorMessages() {
      return this.selectedThumbnailErrors
        .filter(({ type }) => type === 'option') || []
    },
    hasWordingError() {
      return this.isDuplicatedStatementError || this.isMissingWordingError
    },
    isDuplicatedStatementError() {
      if (isEmpty(this.wordingErrors)) {
        return false
      }

      const textsOfThumbnails = this.thumbnails.map(({ text }) => text)
      const hasRepeatStatement = textsOfThumbnails.length
      !== new Set(textsOfThumbnails).size

      return this.wordingErrors[0].error
        .includes(possibleErrorsOnCreateMissions.duplicatedWording)
        && !this.statementIsEmpty
        && hasRepeatStatement
    },
    isMissingWordingError() {
      if (isEmpty(this.wordingErrors)) {
        return false
      }

      return this.wordingErrors[0].error.includes(possibleErrorsOnCreateMissions.missingWording)
      && this.statementIsEmpty
    },

    wordingErrorMessage() {
      if (isNotEmpty(this.wordingErrors) && this.hasWordingError) {
        return this.wordingErrors[0].error
      }

      return ''
    },
    questionHasWordingError() {
      return isNotEmpty(this.wordingErrorMessage.split(''))
    },
    selectedAlternativeIsEmpty() {
      if (this.selectedAlternative) {
        return this.alternatives[this.selectedAlternative].value.length < 1
      }

      return true
    },
    statementIsEmpty() {
      return this.editorContentHTML === undefined || this.editorContentHTML.length === 0
    },
    isNotChallengeReinforcement() {
      return this.magnaMission.data?.type?.toUpperCase() !== 'CHALLENGE_REINFORCEMENT'
    },
    alternativesReadonly() {
      const selectedQuestion = this.magnaMission.data?.questions?.map((question, order) => ({
        ...question,
        timer: question.timer,
        hasTimer: Boolean(question.timer),
        statement: question.formattedWording,
        origin: question.origin,
        order: order + 1,
        options: question.options.map((option, optionIndex) => {
          const letter = possibleLetters.find((_, index) => index === optionIndex)

          return {
            id: optionIndex,
            description: option,
            letter,
            isCorrectOption: question.answerKey === optionIndex,
            variation: this.getCardVariation({
              optionId: optionIndex,
              correctAnswerKey: question.answerKey,
            }),
            color: this.getCardColor(letter),
          }
        }),
      }))[this.selectedThumbnailIndex]

      return selectedQuestion ?? {}
    },
  },
  watch: {
    thumbnails: {
      handler(thumbnails) {
        this.$emit('mission-questions-change', thumbnails)
        this.emitEventIfHasValidQuestion()
      },
      deep: true,
    },
    selectedSasQuestionsStore: {
      handler(selectedSasQuestionsStore) {
        if (!isEmpty(selectedSasQuestionsStore)) {
          this.addSasQuestionsToThumbnails(selectedSasQuestionsStore)
        }
      },
      immediate: true,
      deep: true,
    },
    isQuestionSelectorVisible: {
      immediate: true,
      handler(value) {
        this.$emit('question-selector-visible', value)
      },
    },
    magnaMissionData() {
      this.initThumbnailsFromMagnaMission()
      this.setCurrentAlternativesInfo()
      this.setEditorContentHTML()
    },
    'selectedThumbnail.text': {
      handler() {
        if (this.isSasQuestionSelectedThumbnail) return

        this.setQuestionValidation(this.selectedThumbnail, this.thumbnails)
        this.emitQuestionChangeEvent()
        this.emitEventIfHasValidQuestion()
      },
    },
    selectedThumbnail(value) {
      this.setCurrentQuestionErrors(value)
    },
    violatedRules() {
      if (this.isSasQuestionSelectedThumbnail) return
      this.thumbnails.forEach((question) => {
        this.setCurrentQuestionErrors(question)
      })
    },
    alternatives: {
      deep: true,
      handler() {
        if (this.isSasQuestionSelectedThumbnail) return
        if (isEmpty(this.alternatives)) return

        const hasValue = Object.values(this.alternatives)
          .map(({ value }) => value)
          .filter((alternative) => alternative.length > 0)
          .length > 0

        if (this.selectedAlternativeIsEmpty) {
          this.setSelectedAlternative('')
        }

        if (hasValue) {
          this.saveAlternativesInfoOnSelectedThumbnail()
        }

        this.setQuestionValidation(this.selectedThumbnail, this.thumbnails)
        this.emitQuestionChangeEvent()
        this.emitEventIfHasValidQuestion()
      },
    },
    selectedAlternative: {
      handler(value) {
        if (this.isSasQuestionSelectedThumbnail) return
        if (isEmpty(value)) return
        const hasAlternative = value.length > 0

        if (hasAlternative) {
          this.saveAlternativesInfoOnSelectedThumbnail()
        }

        this.setQuestionValidation(this.selectedThumbnail, this.thumbnails)
        this.emitQuestionChangeEvent()
        this.emitEventIfHasValidQuestion()
      },
    },
    editorContentHTML: {
      immediate: true,
      handler() {
        if (this.isSasQuestionSelectedThumbnail) return
        if (this.isEmptyThumbnails && this.isEmptyMagnaMissions) return

        this.thumbnails[this.selectedQuestionOrder - 1].formattedText = this.editorContentHTML
      },
    },
    selectedQuestionOrder() {
      this.setQuestionValidation(this.selectedThumbnail, this.thumbnails)
      this.updateSelectedItem()
      this.setCurrentAlternativesInfo()
      this.setEditorContentHTML()
    },
  },
  methods: {
    ...mapActions([ 'setSelectedSasQuestions' ]),
    generateSasQuestionsPreview(questions = []) {
      const questionsPreview = questions?.map((question, index) => ({
        ...question,
        isSelected: false,
        origin: originEnum.SAS,
        order: this.thumbnails.length + (index + 1),
        type: 'preview',
        text: question.shortStatement,
        timer: question?.timer ?? 30,
        hasTimer: Boolean(question?.timer ?? false),
        statement: question.statement,
        formattedText: question.statement,
        originalData: question,
        options: question.options.map((option, optionIndex) => {
          const letter = possibleLetters.find((_, _index) => _index === optionIndex)

          return {
            id: optionIndex,
            description: option.description,
            letter,
            isCorrectOption: Number(question.answerKey) === optionIndex,
            variation: this.getCardVariation({
              optionId: optionIndex,
              correctAnswerKey: Number(question.answerKey),
            }),
            color: this.getCardColor(letter),
          }
        }),
      }))

      return questionsPreview ?? []
    },
    addSasQuestionsToThumbnails(selectedSasQuestionsStore) {
      const newSelectedQuestionsWithoutDuplicates = selectedSasQuestionsStore.filter((question) => {
        const isNotDuplicated = !this.thumbnails.find((thumbnail) => thumbnail.id === question.id)

        return isNotDuplicated
      })
      const questionsPreview = this.generateSasQuestionsPreview(
        newSelectedQuestionsWithoutDuplicates
      )

      this.setSelectedSasQuestions([])
      this.showQuestionSasBadge = true

      const newThumbnailsWithLastSelected = [ ...this.thumbnails, ...questionsPreview ]
        .map((thumbnail, index, arr) => ({
          ...thumbnail,
          isSelected: arr.length === index + 1,
        }))

      this.thumbnails = newThumbnailsWithLastSelected
      this.selectedQuestionOrder = this.thumbnails?.length
      this.emitQuestionChangeEvent()
    },
    addSASQuestions(question) {
      this.resetAlternativeInputAndSelected()

      this.thumbnails.push({
        type: 'preview',
        order: this.nextQuestionOrder,
        isSelected: true,
        text: '',
        hasTimer: false,
        timer: null,
        selectedAlternative: this.selectedAlternative,
        alternatives: this.alternatives,
        origin: originEnum.SAS,
        originalData: question,
      })
    },
    initThumbnailsFromMagnaMission() {
      if (this.magnaMissionData) {
        this.thumbnails = [{
          type: 'preview',
          text: '',
          order: 1,
          isSelected: true,
          selectedAlternative: '',
          timer: undefined,
          hasTimer: false,
          alternatives: {
            A: {
              value: '',
            },
            B: {
              value: '',
            },
            C: {
              value: '',
            },
            D: {
              value: '',
            },
          },
        }]

        this.thumbnails = this.magnaMissionData.questions.map(({
          wording,
          options,
          formattedWording,
          answerKey,
          timer,
          id,
          origin,
        }, index) => {
          const option = (optionIndex) => options[optionIndex] ?? ''
          const thumbnail = {
            id,
            type: 'preview',
            text: wording,
            order: index + 1,
            isSelected: index === 0,
            selectedAlternative: '',
            origin,
            timer,
            hasTimer: Boolean(timer),
            alternatives: {
              A: {
                value: option(0),
              },
              B: {
                value: option(1),
              },
              C: {
                value: option(2),
              },
              D: {
                value: option(3),
              },
            },
            formattedText: formattedWording,
          }

          thumbnail.selectedAlternative = Object.keys(thumbnail.alternatives)[answerKey]
          this.selectQuestion(this.thumbnails[0])

          return thumbnail
        })
      }
    },
    addSASQuestion() {
      this.$router.push({
        name: 'magna-mission-questions-search',
        params: {
          ...this.$route.params,
          questionnaireCode: this.$route.params?.questionnaireCode,
        },
      })
    },
    addNewQuestion() {
      this.resetAlternativeInputAndSelected()

      this.selectedQuestionOrder = this.nextQuestionOrder
      this.thumbnails.push({
        type: 'preview',
        order: this.nextQuestionOrder,
        isSelected: true,
        text: '',
        hasTimer: false,
        timer: null,
        selectedAlternative: this.selectedAlternative,
        alternatives: this.alternatives,
        origin: originEnum.TEACHER,
      })
    },
    removeThumbnail() {
      const { thumbnails, itemToRemove } = this

      if (!isEmpty(thumbnails)) {
        const indexOfItemToRemove = thumbnails
          .findIndex(({ order }) => order === itemToRemove.order)

        thumbnails.splice(indexOfItemToRemove, 1)
        this.thumbnails = thumbnails.map((thumb, index) => ({ ...thumb, order: index + 1 }))

        if (this.selectedQuestionOrder > 1) this.selectedQuestionOrder -= 1
        this.updateSelectedItem()
        this.setCurrentAlternativesInfo()
        this.setEditorContentHTML()

        this.toggleDeleteMagnaMissionModal()
        this.$emit('delete-question', indexOfItemToRemove)
      }
    },
    selectQuestion(question) {
      this.selectedQuestionOrder = question.order
    },
    setItemToRemove(item) {
      this.itemToRemove = item

      if (this.thumbnails.length === 1) this.toggleInfoMagnaMissionModal()
      else this.toggleDeleteMagnaMissionModal()
    },
    updateSelectedItem() {
      this.thumbnails = this.thumbnails.map((thumb) => ({
        ...thumb,
        isSelected: thumb.order === this.selectedQuestionOrder,
      }))
    },
    reorderThumbnails() {
      const [ selectedQuestion ] = this.thumbnails.filter((question) => question.isSelected)

      this.thumbnails.sort((a, b) => (a.order - b.order))

      this.selectQuestion(selectedQuestion)
      this.emitQuestionChangeEvent()
    },
    setItemOrder(thumbnail) {
      const { order, newOrder } = thumbnail

      this.thumbnails[newOrder - 1].order = order
      this.thumbnails[order - 1].order = newOrder

      this.reorderThumbnails()
    },
    saveAlternativesInfoOnSelectedThumbnail() {
      this.thumbnails[this.selectedThumbnailIndex].selectedAlternative = this.selectedAlternative
      this.thumbnails[this.selectedThumbnailIndex].alternatives = this.alternatives
    },
    setCurrentAlternativesInfo() {
      this.setSelectedAlternative(this.selectedThumbnail.selectedAlternative)
      this.alternatives = this.selectedThumbnail.alternatives
    },
    setSelectedAlternative(value) {
      this.selectedAlternative = value
    },
    resetAlternativeInputAndSelected() {
      this.setSelectedAlternative('')
      this.alternatives = {
        A: {
          value: '',
        },
        B: {
          value: '',
        },
        C: {
          value: '',
        },
        D: {
          value: '',
        },
      }
    },
    setEditorContentHTML() {
      if (this.selectedThumbnail) this.editorContentHTML = this.selectedThumbnail.formattedText
    },
    setQuestionValidation(question, listOfQuestions) {
      const { alternatives, selectedAlternative } = question
      const alternativesIsUndefined = isEmpty(alternatives)
      const selectedAlternativeIsUndefined = isEmpty(selectedAlternative)

      if (!alternativesIsUndefined && !selectedAlternativeIsUndefined) {
        const indexOfQuestion = this.thumbnails.findIndex(({ order }) => order === question.order)

        this.thumbnails[indexOfQuestion].validation = checkIfValidQuestion(question,
          listOfQuestions)
      }
    },
    toggleInfoMagnaMissionModal() {
      this.isInfoModalVisible = !this.isInfoModalVisible
    },
    toggleDeleteMagnaMissionModal() {
      this.isDeleteModalVisible = !this.isDeleteModalVisible
    },
    setEditorContentText(value) {
      this.thumbnails[this.selectedThumbnailIndex].text = value
      this.emitQuestionChangeEvent()
    },
    setResponseTimeFlag(value) {
      this.thumbnails[this.selectedThumbnailIndex].hasTimer = value
      this.emitQuestionChangeEvent()
    },
    setTime(value) {
      this.thumbnails[this.selectedThumbnailIndex].timer = value
      this.emitQuestionChangeEvent()
    },
    emitEventIfHasValidQuestion() {
      const hasAValidQuestion = this.thumbnails
        .filter((thumb) => thumb.validation?.questionIsValid || thumb.origin === originEnum.SAS)
        .length > 0

      this.$emit('has-some-valid-question', hasAValidQuestion)
    },
    emitQuestionChangeEvent() {
      this.$emit('mission-questions-change', this.thumbnails)
    },
    setCurrentQuestionErrors(question) {
      if (isEmpty(question.order)) return
      const questionIndex = this.thumbnails.findIndex(({ order }) => order === question.order)

      if (question.origin === originEnum.SAS) {
        this.thumbnails[questionIndex].errors = []

        return
      }

      const currentQuestionHasError = this.violatedRules
        .some(({ order }) => order === question.order)

      if (!currentQuestionHasError) {
        this.clearErrors(questionIndex)
      } else {
        const { violatedRules } = this.violatedRules.find(({ order }) => order === question.order)

        this.thumbnails[questionIndex].errors = violatedRules
        this.setAlternativesError(question)
      }
    },
    setAlternativesError({ errors, order: questionOrder }) {
      let alternativesWithError = {}
      const questionIndex = this.thumbnails.findIndex(({ order }) => order === questionOrder)
      const thumbnailAlternatives = this.thumbnails[questionIndex].alternatives
      const alternativesKeys = Object.keys(thumbnailAlternatives)
      const missingOptionError = errors
        .some(({ error }) => error === possibleErrorsOnCreateMissions.missingOptions)
      const missingAnswerError = errors
        .some(({ error }) => error === possibleErrorsOnCreateMissions.missingAnswer)

      alternativesKeys.forEach((alternative) => {
        const { value: currentAlternativeValue } = thumbnailAlternatives[alternative]

        alternativesWithError = {
          ...alternativesWithError,
          [alternative]: {
            value: currentAlternativeValue,
            missingOptions: missingOptionError,
            missingAnswer: missingAnswerError,
          },
        }
      })

      this.thumbnails[questionIndex].alternatives = alternativesWithError

      if (questionIndex === this.selectedThumbnailIndex) {
        this.alternatives = alternativesWithError
      }
    },
    clearErrors(questionIndex) {
      const alternativesKeys = Object.keys(this.thumbnails[questionIndex].alternatives)

      this.thumbnails[questionIndex].errors = []

      alternativesKeys.forEach((alternative) => {
        this.thumbnails[questionIndex].alternatives[alternative].missingOptions = false
        this.thumbnails[questionIndex].alternatives[alternative].missingAnswer = false
      })
    },
    getCardVariation({ optionId, correctAnswerKey }) {
      if (optionId === correctAnswerKey) {
        return 'secondary'
      }

      return 'tertiary'
    },
    getCardColor(option) {
      const colorsByLetter = {
        A: '#8A5BC0',
        B: '#48ACB3',
        C: '#E39F4F',
        D: '#D66565',
        E: '#48ACB3',
      }

      return colorsByLetter[option]
    },
  },
}
</script>

<style lang="scss" scoped>

.create-magna-mission-content-tab {
  --header-height: 131px;
  height: calc(100% - var(--header-height));
  color: $color-white;
  display: flex;

  &__header-mobile {
    gap: $size-xs;
    display: flex;
    flex-direction: column;
    background-color: $eureka-color-base;
    border-bottom: 1px solid rgba($eureka-color-ink, 0.2);

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($eureka-color-ink, 0.2);
      padding: 0 $size-m;
    }

    &__text {
      padding: $size-m $size-m $size-s;
    }

    &__title-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: $size-xs;
    }

    &__title {
      font-family: Rowdies;
      font-size: $font-size-heading-5;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100% !important;
      color: black;
    }

    &__subtitle {
      font-family: Lato;
      font-size: $font-size-s;
      font-weight: $font-weight-medium;
      line-height: $line-height-input;
      letter-spacing: 0.4px;
    }
  }

  @media screen and (max-width: 579px) {
    flex-direction: column;
  }

  &__thumbnails-list {
    min-width: 235px;
  }

  &__editor-area {
    width: 100%;

    @media screen and (max-width: 991px) {
      overflow-y: auto;
    }

    &__card {
      margin: $size-s;
      background-color: $color-white;
      box-shadow: 0 3px 0 #aeaeae, inset 0 1px 0 rgba($color-white, 0.75);
      border-radius: 17px;
      font-family: Rowdies;
      border-radius: $size-l;
      overflow: hidden;
      height: max-content;

      @media screen and (max-width: 991px) {
        margin: 0;
        border: 0;
        border-radius: 0;
        background: unset;
        box-shadow: unset;
      }

      &__header {
        gap: 5px 75px;
        display: flex;

        flex-direction: row;

        position: relative;
        background-color: $eureka-color-base;
        padding: 10px $size-m;
        padding-bottom: 0;
        color: #473f78;

        &--top-content {
          display: flex;
          align-items: center;
          width: 100%;
          padding-bottom: $size-xs;
          border-bottom: 1px solid rgba($eureka-color-ink, 0.2);
          span, img, button {
            margin: 0 $size-s;
          }

          @media screen and (max-width: 991px) {
            justify-content: space-between !important;

          }
        }

        @media screen and (max-width: 991px) {
          padding: 0;
          flex-wrap: wrap;
        }

        font: {
          size: $size-m;
          family: Rowdies;
        }

        .order {
          border-right: 1px solid rgba($eureka-color-ink, 0.2);
          padding-right: 15px;
          margin-top: -2px;

          @media screen and (max-width: 991px) {
            margin-top: 7px;
          }
        }

        .mission-type-icon {
          position: absolute;
          top: 0;
          left: 55px;
          height: 60px;
          width: 65px;
          object-fit: contain;

          @media screen and (max-width: 991px) {
            left: 48px;
          }
        }

        .actions {
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 991px) {
            float: unset;
            margin: $size-xs 0;
            .timerform {
              flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: unset;
              margin: 0 $size-s;
            }
            &::before {
              content: "";
              position: absolute;
              background: $eureka-color-ink;
              width: 100vw;
              height: 1px;
              opacity: 20%;
              left: 0;
              top: 0;
            }
          }

          @media screen and (max-width: 991px) {
            &::before {
              display: none;
            }
          }
        }

        .delete-btn {
          margin: 0 $size-xs;
          opacity: 0.7;
          @media screen and (max-width: 991px) {
            margin-left: auto;
          }

          ::v-deep .g-button__text {
            color: $eureka-color-ink;
            font-weight: $font-weight-regular;
            font-size: $font-size-m;
          }
        }
      }

      &__body {
        display: grid;
        gap: 20px;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;

        width: 100%;
        padding: $size-l;

        @media screen and (max-width: 991px) {
          grid-template-columns: auto;
          padding: 16px;
        }

        @media screen and (max-width: 579px) {
          padding-bottom: 91px;
        }

        &__question-wrapper {
          grid-row: 1 / 3;
          padding-right: 22px;
          border-right: 1px solid $eureka-color-base-light;
          color: $color-black;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: 65vh;
          overflow-y: auto;

          @media screen and (max-width: 991px) {
            grid-row: unset;
            padding: 0;
            border: 0;
            max-height: unset;
            background: $eureka-color-base;
            border-radius: 16px;
            overflow-y: visible;
            width: calc(100% - 12%);
          }

          & .question-editor {
            min-height: 300px;
            height: 100% !important;
          }

          &.--error .question-editor {
            height: 92% !important;
          }
        }

        &__question-wrapper-mobile {
          display: flex;
          color: $color-black;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: 65vh;
          overflow-y: auto;
          background: $eureka-color-base;
          border-radius: 16px;

          & .question-editor {
            min-height: 300px;
            height: 100% !important;
          }

          &.--error .question-editor {
            height: 92% !important;
          }
        }

        .alternative-list {
          grid-row: 1 / 3;
          overflow-wrap: break-word;
        }
      }
    }
  }

  ::v-deep .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
  }

  &__alternatives {
    min-width: 350px;
    height: 65vh;
    overflow-y: auto;

    @media screen and (max-width: 991px) {
      min-width: initial;
      overflow: visible;
    }
  }

  &__alternative-item {
    margin-bottom: $size-m;
  }
}

.tour {
  &__mission-statement {
    height: 100%;

    ::v-deep img {
      max-width: 100%;
      object-fit: contain;
    }

    @media screen and (max-width: 991px) {
      ::v-deep .container{
        padding: $size-s;
        word-break: break-word;
      }
    }

    &.tour-active {
      padding: $size-xxs;
    }
  }

  &__mission-alternatives {
    &.tour-active {
      padding: $size-xxs;
    }
  }
}

::v-deep .tox.tox-tinymce {
  height: 100% !important;
}

.save-btn.g-button {
  margin-left: auto;
  margin-top: 9px;
  border: none !important;
  ::v-deep .g-button__text {
    font: {
      family: Rowdies;
      weight: $font-weight-regular !important;
    }
  }

  &:disabled {
    background-color: $eureka-color-ink-lighter !important;
  }
}

.edit-btn {
  margin-bottom: -5px;
  @include mq_m {
    float: right;
  }

  ::v-deep .g-button__text {
    color: $eureka-color-ink;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
  }

  @media screen and (max-width: 991px) {
    padding: 0;
    margin-right: 0;
  }
}

</style>
