<template>
  <Dropdown
    ref="multiselectDrop"
    :class="$style.dropdown"
    :disabled="disabled"
    :loading="loading"
    :multiselect-tags="multiselectTags || multiselectCounter"
    origin="bottom-left"
    :size="size"
    :status="status"
    :text="text"
    @blur="$emit('blur')"
    @clicked="$emit('clicked')"
    @toggle="onDropdownToggle($event)"
  >
    <template
      v-if="multiselectTags || multiselectCounter"
      #multiselect-tags
    >
      <div
        v-if="hasItems"
        :id="labelId"
        :class="$style.selectedItem"
      >
        {{ placeholder }}
      </div>

      <div
        v-if="hasMultiSelect"
        :id="labelId"
        :class="$style.selectedItem"
        :title="selectedItems[0].name || selectedItems[0].text"
      >
        {{ selectedItems[0].name || selectedItems[0].text }}
      </div>

      <template v-if="selectedItems.length !== 0">
        <div
          v-if="multiselectCounter && selectedItems.length > 1"
          ref="dropdownCounter"
          :class="$style.counter"
        >
          {{ `+${selectedItems.length - 1}` }}
        </div>
        <div v-if="!multiselectCounter">
          <div
            v-for="(item, index) of selectedItems"
            :key="index"
            ref="dropdownCounter"
            :class="$style.tagText"
          >
            {{ getText(item) }}

            <Icon
              v-if="isOpen"
              size="18"
              stroke-width="2"
              type="x"
              @click="unselectItem(item)"
            />
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="!isMobile"
      :aria-labelledby="labelId"
      :class="$style.header"
    >
      <template v-if="!selectedDeepTree.length && !isMobile">
        <span>Árvore de assuntos</span>
      </template>
      <template v-else-if="!isMobile">
        <div :class="$style.headerContainer">
          <icon
            role="button"
            aria-label="voltar filtro"
            class="dropdownHeaderIcon"
            type="chevron-left"
            stroke-width="2"
            @click.stop="goBackTree"
          />
          <div :class="$style.headerContent">
            <span :class="$style.headerDeepTitle">Árvore de assuntos</span>
            <span
              :class="$style.headerDeepSubtitle"
              :title="deepItemSelected.name"
            >
              {{ deepItemSelected.name }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <template v-if="isMobile">
      <div :class="$style.headerMobile">
        <h2 :class="$style.headerTitleMobile">
          Árvore de assuntos
        </h2>
        <s-button
          icon="x"
          variation="tertiary"
          @click="close"
        />
      </div>
    </template>
    <div :class="$style.separator" />
    <div
      v-if="filterable"
      :class="$style.search"
    >
      <s-input
        icon-left="search"
        width="100%"
        placeholder="Digite um termo"
        :value="searchString"
        @input="handleInput"
      >
        <template
          v-if="searchString.length"
          #extras
        >
          <icon
            class="dropdownTreeSelectorSearchCloseIcon"
            type="x"
            @click.stop="cleanSearch"
          />
        </template>
      </s-input>
    </div>
    <div :class="$style.separator" />
    <span :class="$style.selectedCounterLabel">
      {{ selectedItemsCount }}
    </span>
    <ul
      :aria-labelledby="labelId"
      :class="[{ [$style.scrollContainer]: scrollOptionsContainer }]"
    >
      <div
        v-show="emptyFilter"
        :class="$style.emptyImage"
      >
        <img
          :src="emptyImage"
          alt=""
        >
        <p :title="searchString">
          Nenhum assunto encontrado para “{{ searchString }}”
        </p>
      </div>
      <li
        v-for="(item, index) of dynamicItems"
        v-show="!emptyFilter"
        :key="index.id"
      >
        <div
          :class="[
            $style.listItem,
            {
              [$style.itemActive]: !multiselect && isSelected(item),
              [$style.hasTree]: item.hasTree,
            },
          ]"
          @click.stop="setValue(item)"
        >
          <template v-if="multiselect">
            <template v-if="!item.hasTree">
              <input
                :checked="isSelected(item)"
                class="dropdown-action-input"
                type="checkbox"
              >
              <label :title="getText(item)">{{ getText(item) }}</label>
            </template>
            <template v-else>
              <span :title="getText(item)">
                {{ getText(item) }}
              </span>
              <icon
                type="chevron-right"
                class="dropdownActionItemIcon"
                stroke-width="2"
              />
            </template>
          </template>

          <template v-else>
            <span :title="getText(item)">
              {{ getText(item) }}
            </span>
            <icon
              v-if="isSelected(item)"
              class="dropdownActionItemIcon"
              type="check"
              stroke-width="2"
            />
          </template>
        </div>
      </li>
    </ul>
    <div :class="$style.footer">
      <GButton
        class="dropdown-tree-selector-footer-button"
        variation="secondary"
        size="medium"
        @click.stop="cleanAll"
      >
        Limpar
      </GButton>
      <GButton
        type="submit"
        class="dropdown-tree-selector-footer-button"
        size="medium"
        no-border
        :disabled="false"
        @click.stop="confirm"
      >
        Confirmar
      </GButton>
    </div>
  </Dropdown>
</template>

<script>
import { Dropdown, Icon } from '@sas-te/alfabeto-vue'
import {
  isEmpty, groupBy, deburr, isEqual
} from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import emptyImage from '@/assets/ancestral-arena/empty-search-dropdown.svg'
import GButton from '@/components/GButton'
import mediaQueries from '@/mixins/mediaQueries'
import findById from './utils/findById'
import flatMapDeepUtil from './utils/flatMapDeep'

import { getPropertyFromItem } from './utils/helpers'

export default {
  name: 'DropdownTreeSelector',
  components: {
    Dropdown,
    Icon,
    GButton,
  },
  mixins: [ mediaQueries ],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    actions: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: [ String, Array, Function ],
      default: 'text',
    },
    itemValue: {
      type: [ String, Array, Function ],
      default: 'value',
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    staticPlaceholder: Boolean,
    multiselect: {
      type: Boolean,
      default: true,
    },
    multiselectTags: Boolean,
    multiselectCounter: {
      type: Boolean,
      default: true,
    },
    scrollOptionsContainer: {
      type: Boolean,
      default: true,
    },
    groupBy: {
      type: String,
      default: null,
    },
    value: {
      type: [ Array ],
      default: () => [],
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => value.match(/(small|medium|large)/),
    },
    status: {
      type: String,
      default: null,
      validator: (value) => value.match(/(success|error|warning)/),
    },
    disabled: Boolean,
    loading: Boolean,
    canSelectAll: Boolean,
    resetWhenItemsChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastAction: null,
      selectedItems: [],
      selectedDeepTree: [],
      searchString: '',
    }
  },
  computed: {
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    hasMultiSelect() {
      return this.multiselectCounter && this.selectedItems?.length > 0
    },
    hasItems() {
      return this.selectedItems?.length === 0 ?? false
    },
    selectedItemsCount() {
      const quantity = this.selectedItems.length
      if (quantity === 0) {
        return 'Nenhum assunto selecionado'
      }

      return `${quantity} assuntos selecionados`
    },
    filteredItems() {
      if (!this.items) {
        return []
      }

      const searchString = deburr(this.searchString.toLocaleLowerCase())

      const flattenItems = flatMapDeepUtil(this.items)

      return flattenItems.filter((item) => deburr(item.name.toLocaleLowerCase())
        .includes(searchString))
    },
    filterable() {
      return !isEmpty(this.items)
    },
    emptyFilter() {
      return !isEmpty(this.searchString) && isEmpty(this.filteredItems)
    },
    deepItemSelected() {
      if (!isEmpty(this.selectedDeepTree)) {
        const lastItem = this.selectedDeepTree[this.selectedDeepTree.length - 1]
        const foundedItem = findById({ array: this.items, id: lastItem.id })

        return foundedItem
      }

      return null
    },
    dynamicItems() {
      if (this.deepItemSelected) {
        return this.addTreeInfo(this.deepItemSelected?.children)
      }

      if (!isEmpty(this.searchString)) {
        const items = this.addTreeInfo(this.filteredItems)

        return items
      }

      return this.addTreeInfo(this.items)
    },

    labelId() {
      // TODO: substituir por nanoid
      return `${uuidv4()}Label`
    },
    text() {
      const {
        returnObject,
        getValue,
        getText,
        items,
        placeholder,
        multiselect,
        multiselectTags,
        selectedItems,
        lastAction,
        value,
      } = this

      if (lastAction !== null) {
        return lastAction.name
      }

      if (selectedItems.length === 0 || this.staticPlaceholder) {
        return placeholder
      }

      if (multiselect) {
        let labels = selectedItems

        if (multiselectTags) {
          return ''
        }

        if (!returnObject) {
          labels = items.filter((_item) => selectedItems.includes(getValue(_item)))
        }

        return labels.map((item) => getText(item)).join(', ')
      }

      const item = items.find(
        (_item) => getValue(_item) === (returnObject ? getValue(value) : value)
      )

      return item ? getText(item) : placeholder
    },
    isAllSelected() {
      return this.selectedItems?.length === this.items?.length ?? false
    },
    organizedItems() {
      if (!this.groupBy) {
        return this.dynamicItems
      }

      return groupBy(this.dynamicItems, this.groupBy)
    },
    isOpen() {
      if (this.$refs.multiselectDrop) {
        return this.$refs.multiselectDrop.menuVisible
      }

      return false
    },
  },
  watch: {
    items: {
      deep: true,
      handler(value, oldValue) {
        const isDifferent = !isEqual(value, oldValue)

        if (this.resetWhenItemsChange && isDifferent) {
          this.lastAction = null
          this.selectedItems = []
          this.selectedDeepTree = []
          this.close()
        }
      },
    },
    disabled() {
      if (this.disabled) this.cleanSubjects()
    },
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.selectedItems = this.multiselect
            ? [ ...this.value ]
            : [ this.value ]
        }
      },
    },
  },
  created() {
    this.emptyImage = emptyImage
  },
  methods: {
    onCloseMultiselect() {
      this.cleanAll()
    },
    cleanSubjects() {
      this.lastAction = null
      this.selectedItems = []
      this.selectedDeepTree = []
      this.$emit('change', this.selectedItems[0])
      this.close()
    },
    cleanSearch() {
      this.searchString = ''
    },
    cleanAll() {
      this.lastAction = null
      this.selectedItems = []
      this.selectedDeepTree = []
      this.$emit('change', this.selectedItems[0])
      this.close()
    },
    confirm() {
      if (this.multiselect) {
        this.$emit('change', this.selectedItems)
      } else {
        this.$emit('change', this.selectedItems[0])
      }

      this.close()
    },
    resetDeepTree() {
      if (!isEmpty(this.selectedDeepTree)) {
        this.selectedDeepTree = []
      }
    },
    handleInput(event) {
      this.resetDeepTree()
      this.searchString = event
    },
    goBackTree() {
      this.selectedDeepTree.pop()
    },
    addTreeInfo(array) {
      return (
        array?.map((item) => ({
          ...item,
          hasTree: !isEmpty(item.children),
        })) ?? []
      )
    },
    getText(item) {
      return String(getPropertyFromItem(item, this.itemText, item))
    },
    getValue(item) {
      return getPropertyFromItem(item, this.itemValue, this.getText(item))
    },
    setValue(item) {
      if (item.hasTree) {
        this.selectedDeepTree.push(item)

        return
      }

      const returnValue = this.returnObject ? item : this.getValue(item)

      this.lastAction = null

      if (!this.multiselect) {
        this.selectedItems = [ returnValue ]
        this.close()

        return
      }

      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => {
          if (this.returnObject) {
            return this.getValue(selectedItem) !== this.getValue(item)
          }

          return selectedItem !== returnValue
        })
      } else {
        this.selectedItems.push(returnValue)
      }
    },
    doAction(action) {
      if (typeof action.callback !== 'function') {
        return
      }

      if (!action.hideLabel) {
        this.lastAction = action
        this.selectedItems = []
      }

      this.close()
      action.callback()
    },
    unselectItem(item) {
      this.$emit(
        'unselect-tag',
        this.returnObject ? item : this.getValue(item)
      )
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedItems = []
      } else if (this.returnObject) {
        this.selectedItems = this.items
      } else {
        this.selectedItems = this.items.map((item) => this.getValue(item))
      }
    },
    isSelected(item) {
      const { getValue, selectedItems, returnObject } = this

      if (returnObject) {
        return (Array.isArray(selectedItems) ? selectedItems : []).some(
          (_item) => getValue(_item) === getValue(item)
        )
      }

      return selectedItems.includes(getValue(item))
    },
    onDropdownToggle(isOpen) {
      const returnValue = this.multiselect
        ? this.selectedItems
        : this.selectedItems[0]

      if (isOpen) {
        return this.$emit('open')
      }

      if (!!returnValue && this.value !== returnValue && !this.multiselect) {
        this.$emit('change', returnValue)
      } else {
        this.selectedDeepTree = []

        if (
          this.multiselect
          && !isEmpty(this.value)
          && Array.isArray(this.value)
        ) {
          this.selectedItems = [ ...this.value ]
        } else {
          this.selectedItems = this.value ?? []
        }
      }

      this.searchString = ''

      return this.close()
    },
    close() {
      this.$refs.multiselectDrop.menuVisible = false

      return this.$emit('close')
    },
  },
}
</script>

<style lang="scss" module>
.dropdown {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 0 !important;

  @media screen and (max-width: 991px) {
    margin-bottom: $size-s;
  }

  :global {
    .sas-dropdown-menu {
      width: 100%;
      background-color: $eureka-color-base-light !important;
      box-shadow: 0px 3px 0px #c2ab8c,
        inset 0px 1px 0px rgba($color-white, 0.75);
      border-radius: $size-s;

      input[type="checkbox"]:checked {
        background-color: $eureka-color-ink-light;
      }

      @media screen and (max-width: 579px) {
        border-radius: 0;
        position: fixed;
        bottom: 0;
        top: $size-s;
        justify-content: flex-start;
      }

      .dropdown-action-input {
        margin-right: 12px;
        min-width: $size-m;
        background: $eureka-color-base-lighter;
        box-shadow: inset 0px 2px 2px rgba($color-ink, 0.2);
        border: 1px solid $eureka-color-base-dark;
        border-radius: $border-radius-xs;
      }
    }

    .sas-button {
      height: auto;
      min-height: 44px;
      padding-right: $size-s;
      padding-left: $size-s;
      background-color: $eureka-color-base-light;
      font-family: Lato;
      font-weight: $font-weight-medium;
      font-size: $size-s;
      line-height: $line-height-text;
      box-shadow: 0 $size-xxs 0 #c2ab8c;
      background: $eureka-color-base-light;
      border: 1px solid $eureka-color-base-dark;
      border-radius: $border-radius-m;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      overflow: hidden;
      display: grid !important;
      grid-template-columns: 1fr 30px;

      @media screen and (max-width: 991px) {
        display: flex !important;
        grid-template-columns: none;
      }

      &:hover {
        border-color: $color-ink-lighter;
        box-shadow: $shadow-s rgba($color-black, 0.15);
      }

      &.--secondary:focus {
        box-shadow: 0 0 0 2px $eureka-color-ink-light !important;
      }

      .sas-button__text {
        width: 100%;
        text-align: left;
        overflow: hidden;
      }

      .sas-button__icon {
        display: flex;
      }

      .multiselect-container {
        white-space: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .dropdownTreeSelectorSearchCloseIcon {
      width: $size-s;
      height: $size-s;
      color: $eureka-color-ink;
      margin-right: $size-xxs;
    }

    .dropdownActionItemIcon {
      color: $eureka-color-ink-light;
    }
  }
}

.headerMobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $size-s;

  .sas-button {
    width: fit-content;
  }
}

.headerTitleMobile {
  font-family: Rowdies;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-regular;
  line-height: $line-height-heading;
  text-align: left;
}

.separator {
  border-bottom: 1px solid $eureka-color-base-dark;
  width: 100%;
}

.selectedCounterLabel {
  font-family: Lato;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  line-height: $line-height-text;
  letter-spacing: 0.44px;
  color: $eureka-color-ink-light;
  text-align: left;
  width: 100%;
  padding: $size-xs $size-s;
}

.selectedItem {
  color: $color-ink-light;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.counter {
  display: inline-flex;
  align-items: center;
  margin-top: $size-xs;
  margin-bottom: $size-xs;
  margin-left: $size-s;
  padding: 2px $size-xs;
  background: $eureka-color-ink-light;
  border-radius: 20px;
  font-size: $font-size-s;
  cursor: default;
  color: $eureka-color-ice;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagText {
  display: inline-flex;
  align-items: center;
  margin: $size-xxs;
  padding: $size-xxs 10px;
  background: $color-primary-lightest;
  border-radius: 20px;
  font-size: $font-size-s;
  cursor: default;
  color: $color-primary-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  font-family: Rowdies;
  font-weight: $font-weight-base;
  font-size: $font-size-heading-5;
  line-height: $line-height-reset;
  color: $eureka-color-ink;
  padding: $size-s;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  cursor: auto;
  width: 100%;
  flex: 1;

  @media screen and (max-width: 579px) {
    flex: initial;
  }

  :global(.dropdownHeaderIcon) {
    color: $eureka-color-ink;
    cursor: pointer;
    padding: 10px 8px 10px $size-xxs;
  }
}
.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: $size-xxs;
  width: 100%;
}

.headerDeepTitle {
  font-family: Lato;
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: $line-height-text;
  letter-spacing: 0.44px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.headerDeepSubtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.scrollContainer {
  overflow-y: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: auto;
  scrollbar-width: thin;
  scrollbar-color: $eureka-color-base-darker $eureka-color-base-lighter;
  min-height: 5vh;
  max-height: 20vh;

  @media (max-height: 991px) {
    max-height: 100%;
  }
}
.emptyImage {
  padding: $size-s;
  font-family: Lato;
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  line-height: $line-height-text;
  text-align: center;
  letter-spacing: 0.44px;
  color: $eureka-color-ink;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px $size-s 6px;

  @media (max-height: 991px) {
    margin-top: auto;
    border-top: 1px solid #e4ccaa;
  }
}

.search {
  padding: 10px;

  @media (max-height: 991px) {
    width: 100%;
  }

  :global {
    .sas-input__field {
      background: $eureka-color-base-lighter;
      color: $eureka-color-ink;
      border: 1px solid $eureka-color-base-darker;
      border-radius: $border-radius-m;

      @media (max-height: 991px) {
        padding: 12px 14px;
      }

      &:focus {
        box-shadow: 0 0 0 2px $eureka-color-ink-light !important;
      }
    }

    .sas-input__icon .feather {
      height: $size-s;
      width: $size-s;
    }
  }
}

.listItem {
  padding: $size-s 14px;
  font-size: $font-size-m;
  display: flex;
  align-items: center;
  text-align: left;
  font-family: Rowdies;
  font-weight: $font-weight-base;
  line-height: $line-height-heading;
  color: $eureka-color-ink;

  label,
  span {
    color: $eureka-color-ink;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    cursor: pointer;
  }

  &.hasTree {
    justify-content: space-between;
  }

  .itemActive {
    justify-content: space-between;
  }

  &:hover {
    background-color: $eureka-color-base;
  }
}

@media screen and (max-width: 991px) {
  :global .MagnaMissionQuestionSearchFilters {
    width: calc(50% - 8px) !important;
  }

  :global .MagnaMissionQuestionSearchFiltersMobile {
    width: 100% !important;

    .sas-button {
      border: none;
      box-shadow: none;
    }
  }
}
</style>
