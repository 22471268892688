<template>
  <div class="question-selector__card">
    <h5 class="question-selector__title">
      Nova questão
    </h5>
    <div class="question-selector__separator" />
    <ul class="question-selector__content">
      <li
        v-if="isSasQuestionsEnabled"
        class="question-selector__separator"
      >
        <button
          class="question-selector__listItem"
          aria-label="Adicionar questão do banco SAS Educação"
          @click="addSASQuestion"
        >
          <img
            src="@/assets/ancestral-arena/questions/sas-question-type.svg"
            alt=""
            class="question-selector__icon"
          >
          <p>
            Adicionar questão do banco SAS Educação
          </p>
        </button>
      </li>
      <li>
        <button
          class="question-selector__listItem"
          aria-label="Adicionar sua própria questão"
          @click="addTeacherQuestion"
        >
          <img
            src="@/assets/ancestral-arena/questions/my-question-type.svg"
            alt=""
            class="question-selector__icon"
          >
          <p>
            Adicionar sua própria questão
          </p>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'QuestionSelector',
  computed: {
    ...mapState({
      isSasQuestionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaSasQuestions,
    }),
  },
  methods: {
    addSASQuestion() {
      this.$router.push({
        name: 'magna-mission-questions-search',
        params: {
          questionnaireCode: this.$route.params?.questionnaireCode,
        },
      })
      this.$emit('click-sas-question')
    },
    addTeacherQuestion() {
      this.$emit('add-new-question', 'TEACHER')
      this.$emit('click-teacher-question')
    },
  },
}
</script>

<style lang="scss" scoped>
.question-selector {
  &__card{
    @include mq_s{
      width: 100vw;
      align-self: center;
      margin-bottom: 16px;
    }
  }

  &__title {
    padding: $size-s;
    font-family: Rowdies;
    font-weight: $font-weight-base;
    font-size: $font-size-heading-5;
    line-height: $line-height-reset;
    @include mq_s{
      color: $eureka-color-ink;
    }
  }

  &__separator {
    border-bottom: 1px solid $eureka-color-base-darker;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: $eureka-color-base-light;
    font-family: Rowdies;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $font-size-m;
    line-height: $line-height-heading;
    @include mq_s{
      background-color: $eureka-color-base;
    }
  }

  &__listItem {
    display: inline-flex;
    padding: $size-s;
    gap: $size-s;
    align-items: center;
    cursor: pointer;
    width: 100%;
    appearance: none;
    border: none;
    background-color: $eureka-color-base-light;
    @include mq_s{
      background-color: $eureka-color-base;
    }

    &:hover {
      background: $eureka-color-base;
    }
  }

  &__icon {
    width: 36px;
    height: 36px;
  }
}
</style>
