<template>
  <component :is="currentComponent" />
</template>

<script>
import MissionResults from '@/views/MissionReport/MissionResults'
import MissionReport from '@/views/MissionReport/MissionReport'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'MissionReportView',
  components: {
    MissionResults,
    MissionReport,
  },
  mixins: [ mediaQueries ],
  computed: {
    currentComponent() {
      return this.mq_xl__mf ? MissionReport : MissionResults
    },
  },
}
</script>
