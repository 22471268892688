<template>
  <div class="on-boarding">
    <GModal
      v-if="mission !== null"
      @close="$emit('close-on-boarding')"
    >
      <header class="on-boarding__header">
        <h4 class="on-boarding__header__order">
          {{ $t('game.missionNumber', { number: mission.order }) }}
        </h4>
        <h3 class="on-boarding__header__title">
          {{ mission.title }}
        </h3>
      </header>
      <div
        v-if="video.loading"
        class="on-boarding__loader"
      >
        <spinner />
      </div>
      <transition
        name="fade"
      >
        <div
          v-if="!video.loading"
          class="on-boarding__body"
        >
          <div
            v-if="video.error"
            class="on-boarding__body__video --error"
          >
            <p class="on-boarding__body__video__alt-text">
              Não foi possível carregar o vídeo. Feche a missão e tente novamente.
            </p>
          </div>
          <div
            v-else
            class="on-boarding__body__video"
            v-html="video.data.embedHtml"
          />
        </div>
      </transition>
      <transition name="fade">
        <div class="on-boarding__items">
          <div class="on-boarding__items__divider">
            <hr class="on-boarding__items__divider--hr">
            <div class="on-boarding__items__divider--block" />
          </div>
          <p
            v-if="itemsAvailable"
            class="on-boarding__items__info"
          >
            {{ $tc(
              'game.missionOnBoarding.activateItem',
              [ mission.status === 'resume' ? 2 : 1 ]
            ) }}
          </p>
          <div class="on-boarding__items__button">
            <div
              v-for="allowedItem in missionItems"
              :key="allowedItem.item.id"
            >
              <ItemThumbnail
                v-if="allowedItem.item.useAtMission"
                class="on-boarding__items__button--thumbnail"
                :bag-item="allowedItem"
                :disabled="mission.status === 'resume'"
                :border="false"
                :already-used="alreadyUsedItem(allowedItem)"
                :loading="bagItems.loading"
                @click="handleItemEffect(allowedItem)"
              />
            </div>
          </div>
        </div>
      </transition>
      <footer class="on-boarding__footer">
        <GButton
          data-testid="onboarding-action"
          variation="primary"
          size="large"
          @click="startMission"
        >
          {{ $t(`game.missionOnBoarding.${mission.status}`) }}
        </GButton>
      </footer>
    </GModal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import trackPlayerEvent from '@/mixins/trackPlayerEvent'
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'
import ItemThumbnail from '@/components/Item/ItemThumbnail/ItemThumbnail'
import * as types from '@/store/items/types'

export default {
  name: 'OnBoarding',
  components: {
    GModal,
    GButton,
    ItemThumbnail,
  },
  mixins: [ trackPlayerEvent ],
  props: {
    mission: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'video',
      'bagItems',
      'usedItems',
      'assignments',
    ]),
    studentName() {
      return typeof this.user.data.name === 'string'
        ? this.user.data.name.split(' ')[0]
        : ''
    },
    missionItems() {
      const { data } = this.bagItems

      return data
    },
    itemsAvailable() {
      return this.missionItems.find(({ item }) => item.useAtMission)
    },
  },
  watch: {
    mission(mission) {
      if (mission !== null) {
        this.fetchVideo(this.mission.questionnaireCode)
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  methods: {
    ...mapActions([
      'getVideo',
    ]),
    ...mapMutations({
      addMissionItem: types.HANDLE_MISSION_ITEM,
    }),
    async fetchVideo(questionnaireCode) {
      try {
        await this.getVideo(questionnaireCode)
        this.setupPlayerEvents({ chapter: this.mission.id })
      } catch (error) {
        console.error(error)
      }
    },
    alreadyUsedItem(allowedItem) {
      if (!this.usedItems.loading && this.usedItems.data.length) {
        const { id } = allowedItem.item
        const [ itemMission ] = this.usedItems.data.filter(
          ({ category }) => category === 'MISSION'
        )

        return itemMission.items.some((item) => item.id === id)
      }

      return false
    },
    handleItemEffect(payload) {
      if (this.mission.status !== 'resume') {
        const { id, name } = payload.item

        if (this.items.includes(id)) {
          this.items.splice(this.items.indexOf(id), 1)
        } else {
          this.items.push(id)
        }

        this.addMissionItem(payload.item)
        this.$trackEvent({
          category: this.$track.category.modalMission,
          action: this.$track.action.enableItem,
          label: name,
        })
      }
    },
    startMission() {
      this.items.forEach((item) => {
        this.$emit('active-item', item)
      })
      this.$emit('start-mission', {
        mission: this.mission,
        items: this.items,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.on-boarding {
  &__header {
    margin: 0 28px;
    padding: 20px 0;
    border-bottom: 2px solid $eureka-color-base-dark;
    text-align: center;

    &__order {
      font-weight: $font-weight-bold;
      font-size: 16px;
      color: #9F7752;
      margin-bottom: 6px;
    }

    &__title {
      font-weight: $font-weight-extra-bold;
      font-size: 28px;
      max-width: 344px;

      @media screen and (max-width: 479px) {
        font-size: 24px;
      }
    }
  }

  &__body {
    width: 100%;
    padding: 32px 28px;
    @include flex-center;
    flex-wrap: wrap;

    &__video {
      @include proportional-video;
      flex: 1 0 100%;
      height: 0;
      background: transparentize(#382517, 0.85);
      filter: drop-shadow(0px 2px 0px #CD8747);
      border-radius: 11px;

      &.--error {
        background: #F7EAD7;
        filter: none;
      }

      &__alt-text {
        position: absolute;
        top: 40%;
        margin: 0 10%;
        font-size: $font-size-m;
        font-weight: $font-weight-semi-bold;
        color: $game-ink;
        line-height: $line-height-reset;
      }

      ::v-deep iframe {
        @include proportional-video-iframe;
      }
    }
  }

  &__items {
    max-width: 400px;
    padding: 0 28px 24px 28px;
    display: block;

    &__divider {
      position: relative;
      margin-bottom: 20px;

      &--hr {
        border-top: 1px solid #CBA976;
        width: 100%;
      }

      &--block {
        position: absolute;
        top: -4px;
        left: 50%;
        width: 8px;
        height: 8px;
        background: $eureka-color-base-dark;
        transform: rotate(45deg);
        outline: #fdfbf0 solid 10px;
      }
    }

    &__info {
      font-weight: $font-weight-bold;
      font-size: $size-s;
      line-height: 125%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      &--thumbnail {
        margin: 0 6px;
      }

      ::v-deep .item-thumbnail {
        box-shadow: none;
        background: #fdfbf0;

        &:hover {
          box-shadow: none;
          transform: none;
        }
      }
    }
  }

  &__loader {
    color: transparentize(#382517, 0.55);
    min-height: 364px;
    width: 400px;
    @include flex-center;
  }

  &__footer {
    padding: 0 28px 32px;

    ::v-deep .g-button {
      width: 100%;
    }
  }

  @media screen and (max-width: 479px) {
    .g-modal-container {
       margin: 10px;
    }
    ::v-deep .g-modal__close-button {
      right: 10px;
    }
  }
}

</style>
