<template>
  <div class="news-feed-list">
    <FeedHeader @close="$emit('close')" />
    <div class="news-feed-list__content">
      <template v-if="allNews.loading">
        <ArticlePreviewSkeleton
          v-for="index in minimumSkeletonItems"
          :key="`loading-${index}`"
        />
      </template>
      <NewsErrorState
        v-if="allNews.error"
        :title="$t('game.news.error.title')"
        :description="$t('game.news.error.description')"
        @click="getAllNews"
      />
      <ArticlePreviewList
        v-else
        :list="allNews.data"
        @show-article="showArticle"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import ArticlePreviewList from './partials/ArticlePreviewList'
import FeedHeader from './partials/FeedHeader'
import ArticlePreviewSkeleton from './partials/ArticlePreviewSkeleton'
import NewsErrorState from './partials/NewsErrorState'

export default {
  name: 'NewsFeed',
  components: {
    FeedHeader,
    ArticlePreviewList,
    ArticlePreviewSkeleton,
    NewsErrorState,
  },
  data() {
    return {
      minimumSkeletonItems: 3,
      newsRead: [],
    }
  },
  computed: {
    ...mapState({
      isToOpenNewsDrawer: (state) => state.news.isToOpenNewsDrawer,
    }),
    ...mapGetters([
      'allNews',
      'importantNovelty',
    ]),
  },
  created() {
    if (this.isToOpenNewsDrawer) {
      this.getAllNews()
      this.setIsToOpenNewsDrawer(false)
    } else if (this.importantNovelty) {
      this.showArticle(this.importantNovelty)
    } else {
      this.getAllNews()
    }
  },
  methods: {
    ...mapActions([
      'getAllNews',
      'getNovelty',
      'setIsToOpenNewsDrawer',
    ]),
    showArticle(article) {
      this.$trackEvent({
        category: this.$track.category.news,
        action: this.$track.action.readNews,
        label: article.title,
      })
      this.getNovelty(article.id)
      this.$emit('set-article-visible', true)

      if (!this.newsRead.includes(article.id)) {
        this.newsRead.push(article.id)
        this.$emit('read-article')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.news-feed-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    position: relative;
    height: 100%;
    flex-grow: 1;
  }

  .news-list {
    position: absolute;
    height: 100%;
    padding: 1rem;
  }
}
</style>
