import { apiBff } from '@/service/apiBff'

export default {
  buyItem: async ({ itemId, quantity = 1, gradeId }) => {
    const body = { quantity }

    if (gradeId) {
      body.gradeId = gradeId
    }

    await apiBff.post(`shop-items/${itemId}`, body)
  },
}
