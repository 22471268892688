<template>
  <div class="highlighted-icon">
    <img
      class="
        highlighted-icon__stars
        highlighted-icon__stars--upper-left-corner"
      :src="require('@/assets/retrospective/stars.svg')"
      alt=""
    >
    <img
      class="
      highlighted-icon__stars
      highlighted-icon__stars--upper-right-corner"
      :src="require('@/assets/retrospective/stars2.svg')"
      alt=""
    >
    <div class="highlighted-icon__content">
      <slot />
    </div>
    <img
      class="highlighted-icon__stars
      highlighted-icon__stars--lower-left-corner"
      :src="require('@/assets/retrospective/stars2.svg')"
      alt=""
    >
    <img
      class="highlighted-icon__stars
      highlighted-icon__stars--lower-right-corner"
      :src="require('@/assets/retrospective/stars.svg')"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'HighlightedIcon',
}
</script>

<style lang="scss" scoped>
.highlighted-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 240px;
    height: 200px;

  &__stars {
    &--upper-left-corner {
      position: absolute;
      top: 0;
      left: 0;
    }

    &--upper-right-corner {
      position: absolute;
      top: 0;
      right: 0;
    }

    &--lower-left-corner {
      position: absolute;
      bottom: 0;
      left: 0;
      transform:  rotate(212deg);
    }

    &--lower-right-corner {
      position: absolute;
      bottom: 0;
      right: 0;
      transform:  rotate(180deg);
    }
  }

  &__content {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    padding: 40px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%,
     rgba(255, 255, 255, 0.02) 10%, rgba(255, 255, 255, 0.08) 22%,
      rgba(255, 255, 255, 0.19) 36%, rgba(255, 255, 255, 0.33) 51%,
       rgba(255, 255, 255, 0.51) 67%, rgba(255, 255, 255, 0.74) 83%, #FFFFFF 100%);
  }
}
</style>
