import mainBackground from '../../assets/audio/BGS_TemaPrincipal.mp3'
import shopBackground from '../../assets/audio/BGS_LojadeItens.mp3'
import click from '../../assets/audio/SFX_Clique.mp3'
import swipeSound from '../../assets/audio/SFX_NavegarEntreMundos.mp3'
import correctAnswer from '../../assets/audio/SFX_RespostaCerta.mp3'
import wrongAnswer from '../../assets/audio/SFX_RespostaErrada.mp3'
import ziperOpening from '../../assets/audio/SFX_ZiperAbrindo.mp3'
import ziperClosing from '../../assets/audio/SFX_ZiperFechando.mp3'
import buyItem from '../../assets/audio/SFX_Comprar.mp3'
import goodPerformance from '../../assets/audio/SFX_BomDesempenho.mp3'
import badPerformance from '../../assets/audio/SFX_MauDesempenho.mp3'
import rewardChest from '../../assets/audio/SFX_Conquista.mp3'
import rewardLevelUp from '../../assets/audio/SFX_LevelUp.mp3'
import wiseScroll from '../../assets/audio/SFX_UsarItem.mp3'
import truthCompass from '../../assets/audio/SFX_TruthCompass.mp3'
import deleteAlternative from '../../assets/audio/SFX_DeleteAlternative.mp3'

export const soundTypes = {
  swipeSound,
  click,
  correctAnswer,
  wrongAnswer,
  ziperOpening,
  ziperClosing,
  wiseScroll,
  buyItem,
  goodPerformance,
  badPerformance,
  rewardChest,
  rewardLevelUp,
  truthCompass,
  deleteAlternative,
}

export const backgroundTypes = {
  mainBackground,
  shopBackground,
}
