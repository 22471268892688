var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.className,
    {
      '--fadeIn': _vm.isShow,
      '--fadeOut': !_vm.isShow,
    },
  ],attrs:{"data-testid":"arena-timer"}},[_c('div',{class:[
      `${_vm.className}__icon-effect`,
      {
        '--in-progress': _vm.isProgressStatus,
      },
    ]},[_c('i',{class:[
        `${_vm.className}__icon`,
        {
          '--idle': _vm.isIdleStatus,
          '--in-progress': _vm.isProgressStatus,
          '--finished': _vm.isFinishedStatus,
        },
      ]})]),_c('span',{class:[
      `${_vm.className}__text`,
      {
        '--idle': _vm.isIdleStatus,
        '--finished': _vm.isFinishedStatus,
      },
    ]},[(!_vm.isFinishedStatus)?_c('img',{class:[
        `${_vm.className}__clock`,
      ],attrs:{"alt":"","src":require('@/assets/ancestral-arena/timer-clock.svg')}}):_vm._e(),_vm._v(" "+_vm._s(_vm.finishedText)+" "),(!_vm.isFinishedStatus && _vm.isHorizontalTimer)?_c('span',{class:`${_vm.className}__additional-text`},[_vm._v(" Tempo restante ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }