<template>
  <div class="new-record-card">
    <section class="new-record-card__header">
      <p
        v-t="'game.report.bestScore'"
        class="new-record-card__header__description"
      />
      <icon
        v-tooltip.bottom="{ content: $t('game.report.bestScoreInfo') }"
        class="new-record-card__header__icon"
        type="help-circle"
        stroke="#CBA976"
      />
    </section>
    <section class="new-record-card__body">
      <div class="new-record-card__body__description">
        <span class="current-score">
          {{ bestPerformance.correct }}
        </span>/<span class="max-score">
          {{ bestPerformance.total }}
        </span>
      </div>
      <img
        :src="require(`@/assets/diamonds/gems-${maxGems}_small.svg`)"
        class="new-record-card__body__gems"
      >
    </section>
    <img
      v-if="newRecord"
      :src="require(`@/assets/new-record-medal.svg`)"
      class="new-record-card__medal"
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NewRecordCard',
  computed: {
    ...mapGetters([ 'report' ]),
    bestPerformance() {
      return this.report.data.mission.bestPerformance
    },
    maxGems() {
      return this.report.data.mission.maxGems
    },
    newRecord() {
      return this.report.data.mission.record
    },
  },
}
</script>

<style lang="sass" scoped>
$custom-font-weight-bold: 800

.new-record-card
  position: relative
  background: #FFF8E8
  border: solid 6px #F5E4C9
  border-radius: 16px
  box-shadow: 0px 1px 0px #E1BA7B
  padding: 5px 20px 0 20px
  height: 82px
  width: 278px

  &__medal
    position: absolute
    left: 85%
    top: 60%

  &__header
    display: grid
    grid-template-columns: repeat(2, max-content)
    text-align: center
    align-items: center

    &__description
      font-weight: $font-weight-bold
      font-size: $font-size-s
      opacity: 0.7
      margin-left: $size-s

    &__icon
       font-weight: $font-weight-bold
       transform: scale(0.7)
       stroke: #CBA976
       margin-left: $size-xxs

  &__body
    display: flex
    justify-content: center
    align-items: center
    border-top: solid 1.5px rgba(197, 102, 46, 0.15)

    &__description
      font-size: $size-m
      line-height: $size-m
      font-weight: $custom-font-weight-bold
      margin: 0 8px

.current-score
  font-size: $font-size-heading-4
  font-weight: $custom-font-weight-bold

.max-score
  font-size: $font-size-heading-6
  font-weight: $custom-font-weight-bold
</style>
