<template>
  <div class="mission-report-header">
    <div
      v-if="order > 0"
      class="mission-report-header__wrapper-badge"
    >
      <span
        ref="points"
        data-testid="order"
        class="mission-report-header__order-badge"
      >
        {{ order }}
      </span>
    </div>
    <h1 class="mission-report-header__title">
      {{ title }}
    </h1>
    <h3 class="mission-report-header__subtitle">
      {{ subtitle }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'MissionReportHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    order: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-report-header
  +flex-column-center
  text-align: center
  color: #382517

  &__title
    font-size: $size-l
    word-wrap: break-word
    padding: 0 15px

  &__subtitle
    font-size: $size-s
    font-weight: 500
    opacity: 0.7

  &__wrapper-badge
    margin-bottom: 12px

  &__order-badge
    +transition($speed-fast)
    width: 48px
    height: 38px
    border-radius: 19px
    color: #4B3728
    display: block
    text-align: center
    line-height: 32px
    font-weight: bold
    flex-shrink: 0
    user-select: none
    font-size: 20px
    position: relative
    border: $size-xxs solid $eureka-color-base-dark
    box-shadow: 0 6px 0 #CBA976
    margin: 0 16px
    z-index: 2
</style>
