<template>
  <div class="error-state">
    <img
      v-if="hasImage"
      alt=""
      class="error-state__icon"
      :src="imageSrc"
    >
    <h4 class="error-state__message">
      {{ messageText }}
    </h4>
    <p
      class="error-state__description"
    >
      {{ descriptionText }}
    </p>
    <GButton
      v-if="hasAction"
      :size="actionSize"
      @click="onClick"
    >
      {{ actionText }}
    </GButton>
  </div>
</template>

<script>
import isEmpty from 'lodash-es/isEmpty'
import GButton from './GButton'

export default {
  name: 'ErrorState',
  components: {
    GButton,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    imagePath: {
      type: String,
      default: '',
    },
    actionSize: {
      type: String,
      default: 'medium',
    },
    hasAction: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasImage() {
      return !isEmpty(this.imagePath)
    },
    imageSrc() {
      // FIXME: analisar outra abordagem futuramente devido a atual buscar imagens em runtime
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/error/${this.imagePath}/illustration.svg`)
    },
    messageText() {
      return this.message || this.$t('game.error.default.message')
    },
    descriptionText() {
      return this.description || this.$t('game.error.default.observation')
    },
    actionText() {
      return this.action || this.$t('game.error.default.action')
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="sass" scoped>
.error-state
  +flex-column-center
  width: 100%
  color: $game-ink

  &__icon
    margin-bottom: $size-m

  &__message
    font-weight: $font-weight-bold
    font-size: $font-size-heading-4
    margin-bottom: 6px

  &__description
    text-align: center
    margin-bottom: $size-l
    opacity: 0.6
</style>
