<template>
  <div
    :ref="parseMathML"
    :class="$style.content"
    v-html="text"
  />
</template>

<script>
import { parseMathML } from '@/utils/dom'
import loadMathTypeViewer from '@/helpers/loadMathTypeViewer'

loadMathTypeViewer()

export default {
  name: 'RichTextViewer',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    parseMathML,
  },
}
</script>

<style lang="scss" module>
.content {
  table {
    max-width: 100%;
  }
}

</style>
