<template>
  <div class="mission-actions">
    <g-alert
      v-if="answer.error"
      variation="error"
      :message="$t('game.error.answer.message')"
    />
    <g-button
      variation="primary"
      size="large"
      :loading="answer.loading"
      @click="$emit('sendAnswer')"
    >
      {{ $t('questions.actions.answer') }}
    </g-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'

export default {
  name: 'MissionActions',
  components: {
    GButton,
    GAlert,
  },
  computed: {
    ...mapGetters([
      'answer',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.mission-actions {
  padding: 22px 0 20px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  @media screen and (max-width: 479px) {
    padding: 8px 0;
  }

  .g-button {
    width: 250px;

    @media screen and (max-width: 479px) {
      width: 150px;

      ::v-deep .g-button__text {
        font-size: 1.15rem;
      }
    }
  }

  .g-alert{
    margin-bottom: 22px;
  }
}
</style>
