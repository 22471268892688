<template>
  <div
    id="magna-mission-layout"
    class="magna-mission-detail-layout"
  >
    <header
      v-if="isMobile"
      class="magna-mission-detail-layout__header-mobile"
    >
      <div class="magna-mission-detail-layout__header-mobile__container">
        <div class="magna-mission-detail-layout__header-mobile__buttons">
          <GButton
            v-if="goBackRouteName || isShowBackButton"
            class="back-button"
            icon="arrow-left"
            size="large"
            :icon-stroke="5"
            variation="secondary"
            @click="goBack"
          /> <GButton
            v-if="isMobile && isQuestionSearch"
            size="medium"
            no-border
            rowdies
            :disabled="!isAllowedFinishSelection"
            class="save-btn-mobile"
            @click="$emit('finishSelection')"
          >
            Concluir seleção
          </GButton>

          <GButton
            v-else
            size="medium"
            class="save-btn-mobile"
            :disabled="isSaveDisabled"
            @click="$emit('save-mission')"
          >
            Salvar missão
          </GButton>
        </div>
        <div
          v-if="!isQuestionSearch"
          class="magna-mission-detail-layout__header-mobile__text"
        >
          <div
            class="magna-mission-detail-layout__header-mobile__title-container"
          >
            <h1
              class="magna-mission-detail-layout__header-mobile__title"
              :style="hideBigTitle"
            >
              {{ title }}
            </h1>
            <GButton
              v-if="!isEditButtonHidden"
              class="edit-btn"
              size="medium"
              variation="secondary"
              asset="edit.svg"
              @click="$emit('edit')"
            >
              <span class="show-desktop">Editar</span>
            </GButton>
          </div>
          <h2 class="magna-mission-detail-layout__header-mobile__subtitle">
            {{ subtitle }}
          </h2>
          <slot name="headerAction" />
        </div>
      </div>
      <slot name="subHeaderInfo" />
      <template v-if="hasSubHeaderSlot">
        <hr class="magna-mission-detail-layout__header__line">
        <div class="magna-mission-detail-layout__header sub-header">
          <slot name="subHeader" />
        </div>
      </template>
    </header>
    <header
      v-else
      class="magna-mission-detail-layout__header"
      :class="{ 'mfe-margin': isMicrofrontend }"
    >
      <div class="magna-mission-detail-layout__header__main">
        <div class="magna-mission-detail-layout__header__main__container">
          <div class="magna-mission-detail-layout__header__main__title-section">
            <GButton
              v-if="goBackRouteName || isShowBackButton"
              class="back-button"
              icon="arrow-left"
              size="large"
              :icon-stroke="5"
              variation="secondary"
              @click="goBack"
            />
            <div>
              <h1
                class="magna-mission-detail-layout__header__main__title"
                :style="hideBigTitle"
              >
                {{ title }}
              </h1>
              <h2
                v-if="subtitle && !isMobile"
                class="magna-mission-detail-layout__header__main__subtitle"
              >
                {{ subtitle }}
              </h2>
            </div>

            <GButton
              v-if="!isEditButtonHidden"
              class="edit-btn"
              size="medium"
              variation="secondary"
              asset="edit.svg"
              @click="$emit('edit')"
            >
              <span class="show-desktop">Editar</span>
            </GButton>

            <slot name="header" />
          </div>
        </div>
        <slot name="headerAction" />
      </div>
      <h1
        v-if="isMobile"
        class="magna-mission-detail-layout__header__main__title"
      >
        {{ title }}
      </h1>
      <h2
        v-if="subtitle && isMobile"
        class="magna-mission-detail-layout__header__main__subtitle"
      >
        {{ subtitle }}
      </h2>
      <slot name="subHeaderInfo" />
      <template v-if="hasSubHeaderSlot">
        <hr class="magna-mission-detail-layout__header__line">
        <div class="magna-mission-detail-layout__header sub-header">
          <slot name="subHeader" />
        </div>
      </template>
    </header>
    <slot />
  </div>
</template>

<script>
import arrowLeft from '@/assets/ancestral-arena/arrow-left.svg'
import GButton from '@/components/GButton'
import handleResize from '@/mixins/handleResize'
import mediaQueries from '@/mixins/mediaQueries'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

const orientationEnum = {
  PORTRAIT: 'portrait-primary',
  LANDSCAPE: 'landscape-primary',
}

export default {
  name: 'MagnaMissionDetailLayout',
  components: {
    GButton,
  },
  mixins: [ handleResize, mediaQueries ],
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    goBackRouteName: {
      type: String,
      default: '',
    },
    isEditButtonHidden: {
      type: Boolean,
      default: false,
    },
    isShowBackButton: {
      type: Boolean,
      default: false,
    },
    isSaveDisabled: {
      type: Boolean,
      default: false,
    },
    isAllowedFinishSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      arrowLeft,
      windowWidth: window.innerWidth,
      isOrientationModalVisible: false,
    }
  },
  computed: {
    isDefaultWidth() {
      return this.windowWidth >= 1024
    },
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    isQuestionSearch() {
      const url = window.location.href

      return url.includes('/ancestral-arena/teacher/mission/questions-search')
    },
    hasSubHeaderSlot() {
      return Object.keys(this.$slots).includes('subHeader')
    },
    hideBigTitle() {
      if (this.windowWidth > 579) return { maxWidth: `${this.windowWidth - 320}px` }

      return { maxWidth: `${this.windowWidth - 120}px` }
    },
  },
  beforeMount() {
    this.handleOrientationChange()
    this.isMicrofrontend = getIsMicrofrontend()
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange)
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === orientationEnum.PORTRAIT) {
        this.showOrientationModal()
      } else if (orientation === orientationEnum.LANDSCAPE) {
        this.closeOrientationModal()
      }
    },
    showOrientationModal() {
      this.isOrientationModalVisible = true
    },
    closeOrientationModal() {
      this.isOrientationModalVisible = false
    },
    goBack() {
      this.$emit('on-go-back')
      if (this.goBackRouteName) {
        this.$router.replace({ name: this.goBackRouteName })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.show-desktop {
  @media screen and (max-width: 579px) {
    display: none;
  }
}
::v-deep .back-button {
  width: fit-content !important;
  justify-content: flex-start;
}

.mfe-margin {
  margin-top: 4em;
}

.magna-mission-detail-layout {
  @include cover;
  height: 100vh;
  z-index: $zindex-fixed;
  background: {
    image: url("~@/assets/ancestral-arena/background.png");
    size: cover;
    attachment: fixed;
    color: $eureka-color-ink;
  }

  @include mq_s {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    height: auto;
  }

  &__header {
    gap: $size-s;
    display: flex;
    flex-direction: column;
    padding: $size-s $size-m;
    background-color: $eureka-color-base;
    border-bottom: 1px solid rgba($eureka-color-ink, 0.2);

    &__main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 579px) {
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }

      &__container {
        display: flex;
        flex-direction: column;
      }

      &__title-section {
        gap: $size-s;
        display: flex;
        width: 100%;
      }

      &__title-section-texts {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
      }

      &__title-container {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      &__title {
        width: 100%;
        font-size: 2rem;
        font-family: Rowdies;
        color: $eureka-color-ink;
        max-width: 395px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 12px;
      }

      &__subtitle {
        font-family: Lato;
        font-size: $size-s;
        font-weight: $font-weight-medium;
        color: $eureka-color-ink-light;
        letter-spacing: 0.0275rem;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: 991px) {
          font-family: Lato;
          font-size: $font-size-s;
          font-weight: $font-weight-medium;
          line-height: $line-height-input;
          letter-spacing: 0.43px;
          text-align: left;
        }
      }
    }

    &__line {
      width: 100%;
      height: 0;
      border: 1px solid $eureka-color-base-darker;
      margin-bottom: $size-xs;
      margin-top: 0;
    }

    &.sub-header {
      display: flex;
      flex-direction: column;
      padding-top: 0;
    }
  }

  &__header-mobile {
    gap: $size-xs;
    display: flex;
    flex-direction: column;
    background-color: $eureka-color-base;
    border-bottom: 1px solid rgba($eureka-color-ink, 0.2);

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($eureka-color-ink, 0.2);
      padding: 0 $size-m;
    }

    &__text {
      padding: $size-m $size-m $size-s;
    }

    &__title-container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      margin-bottom: $size-xs;
    }

    &__title {
      font-family: Rowdies;
      font-size: $font-size-heading-5;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100% !important;
    }

    &__subtitle {
      font-family: Lato;
      font-size: $font-size-s;
      font-weight: $font-weight-medium;
      line-height: $line-height-input;
      letter-spacing: 0.4px;
    }
  }

  &__sub-header {
    width: 100%;
  }
}

::v-deep .save-btn-mobile {
  height: 36px;
  border: none !important;
  box-shadow: 0 3px 0 #2d1fc8 !important;

  &:disabled {
    background: #666e75 !important;
    box-shadow: 0 3px 0 #848484 !important;
    opacity: 1 !important;
  }
}

::v-deep .g-button__image {
  margin-right: 0;
}

.edit-btn {
  margin-bottom: -5px;
  @include mq_m {
    float: right;
  }

  ::v-deep .g-button__text {
    color: $eureka-color-ink;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
  }

  @media screen and (max-width: 991px) {
    padding: 0;
    margin-right: 0;
  }
}
</style>
