<template>
  <div class="slide">
    <section class="retrospective-summary">
      <h1 class="retrospective-summary__title">
        Minha retrospectiva {{ year }}
      </h1>
      <img
        class="retrospective-summary__logo"
        :src="require('@/assets/retrospective/eureka.svg')"
        alt="Eureka"
      >
      <div class="retrospective-summary__content">
        <div class="retrospective-summary__info">
          <img
            v-if="userIcon"
            class="retrospective-summary__user-icon"
            :src="userIcon.image"
            :alt="userIcon.title"
          >
          <div class="retrospective-summary__info-content">
            <div class="retrospective-summary__info-item">
              {{ userGradeAndClass }}
            </div>
            <div
              v-if="rank"
              class="retrospective-summary__info-item"
            >
              <LevelDisplay
                class="retrospective-summary__level"
                :rank="rank"
                rank-label-is-visible
                :show-level-number="false"
              />
            </div>
            <div
              class="retrospective-summary__info-item
                retrospective-summary__info-item--important"
            >
              <img
                class="retrospective-summary__icon"
                src="@/assets/gem.svg"
                alt=""
              >
              {{ reward.gems }}
              <img
                class="retrospective-summary__icon"
                src="@/assets/coin.svg"
                alt=""
              >
              {{ reward.coins }}
            </div>
          </div>
        </div>
        <div class="retrospective-summary__separator" />
        <div
          class="retrospective-summary__other-info-item
          retrospective-summary__other-info-ifirst tem--spaced"
        >
          <span>
            Total de missões:
            <strong>{{ missions.missionCount }}</strong>
          </span>
          <span>
            Total de enigmas:
            <strong>{{ missions.enigmaCount }}</strong>
          </span>
        </div>
        <div class="retrospective-summary__other-info">
          <div
            v-if="adventureStyle"
            class="retrospective-summary__other-info-item"
          >
            Estilo de aventura:
            <strong class="retrospective-summary__other-info-text">
              {{ adventureStyle.style }}
            </strong>
            <img
              class="retrospective-summary__other-info-icon"
              :src="adventureStyle.image"
              alt=""
            >
          </div>
          <div
            v-if="mostAccessedWorld"
            class="retrospective-summary__other-info-item"
          >
            Mundo mais frequentado:
            <strong class="retrospective-summary__other-info-text">
              {{ mostAccessedWorld.world }}
            </strong>
            <img
              class="retrospective-summary__other-info-icon"
              :src="mostAccessedWorld.image"
              alt=""
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LevelDisplay from '@/components/LevelDisplay'
import isNotValid from '@/utils/isNotValidObjectAndValues'

export default {
  name: 'RetrospectiveSummary',
  components: {
    LevelDisplay,
  },
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    year() {
      return this.$route.params.retrospectiveId
    },
    userIcon() {
      const { gameIcon } = this.retrospectiveData
      if (isNotValid(gameIcon, [ 'title', 'squareImage' ])) return null

      const { title, squareImage } = this.retrospectiveData?.gameIcon

      return {
        title,
        image: squareImage?.png,
      }
    },
    userGradeAndClass() {
      const { gradeName, className } = this.retrospectiveData

      return `${gradeName} | ${className}`
    },
    className() {
      return this.retrospectiveData?.className
    },
    rank() {
      const { insignia } = this.retrospectiveData
      if (isNotValid(insignia, [ 'title', 'image' ])) return null

      return this.generateRank(insignia)
    },
    reward() {
      const { gems, coins } = this.retrospectiveData

      return {
        gems: `${gems?.conquered ?? 0}/${gems?.total ?? 0}`,
        coins,
      }
    },
    adventureStyle() {
      const { mostUsedItem } = this.retrospectiveData
      if (isNotValid(mostUsedItem, [ 'adventureStyle', 'image' ])) return null

      const { adventureStyle, image } = mostUsedItem

      return {
        style: adventureStyle,
        image: image?.svg,
      }
    },
    mostAccessedWorld() {
      const { mostAccessedWorld } = this.retrospectiveData
      if (isNotValid(mostAccessedWorld, [ 'title', 'image' ])) return null

      return {
        world: mostAccessedWorld?.title,
        image: mostAccessedWorld?.image.svg,
      }
    },
    missions() {
      const { missionCount, enigmaCount } = this.retrospectiveData

      return {
        missionCount,
        enigmaCount,
      }
    },
  },
  methods: {
    generateRank({ image, title }) {
      return {
        insigniaImage: image,
        insigniaTitle: title,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  background-image: url('~@/assets/retrospective/background-retro-1.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.retrospective-summary {
  color: $color-white;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 890;
  font-family: Lato;

  &__title {
    margin-bottom: $size-m;
    font-family: Rowdies;
    font-weight: $font-weight-light;
    font-size: $font-size-heading-4;
  }

  &__logo {
    max-height: 70px;
    margin-bottom: $size-m;
    filter: drop-shadow(0px 2px 0px #B75107);
  }

  &__level {
    margin-bottom: 0;
    font-family: Rowdies;

    ::v-deep {
      img {
        height: 28px;
      }

      .level-display__text__rank {
        color: $color-white !important;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }

  &__info-content {
    text-align: left;
  }

  &__info-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: $size-s;
    }

    &--important {
      font-weight: $font-weight-bold;
    }
  }

  &__icon {
    width: $size-m;
    height: $size-m;
    margin: 0 $size-xs 0 $size-xxs;

    &:last-child {
      margin-left: $size-s;
    }
  }

  &__user-icon {
    border-radius: 16px;
    margin-right: $size-l;
    height: 100px;
  }

  &__separator {
    opacity: 0.32;
    border: 1px solid $color-white;
    margin-bottom: $size-m;
  }

  &__other-info {
    text-align: center;
  }

  &__other-info-item {
    @include flex-center;

    strong {
      margin: 0  $size-xs;
      font-family: Rowdies;
    }

    &:not(:last-child) {
      margin-bottom: $size-m;
    }

    &--spaced {
      grid-gap: $size-xs;
    }
  }

  &__other-info-text {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__other-info-icon {
    width: $size-m;
    height: $size-m;
    border-radius: 2px;
  }

}
</style>
