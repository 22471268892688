var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"question-selector-list"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowButtons),expression:"isShowButtons"}],class:[
      'question-selector-list__buttons',
      'question-selector-list__btn-prev',
      { '--active': _vm.hasPrev, '--inactive': !_vm.hasPrev },
    ],attrs:{"aria-label":"voltar questões","role":"button"},on:{"click":_vm.onPrev}},[_c('icon',{staticClass:"question-selector-list__icon",attrs:{"type":"chevron-left","stroke-width":"4"}})],1),_c('VueHorizontal',{ref:"horizontal",class:[
      'question-selector-list__container',
      { '--has-buttons': _vm.isShowButtons, }
    ],attrs:{"button":false},on:{"scroll-debounce":_vm.onScroll}},_vm._l((_vm.questions),function(question){return _c('QuestionSelector',{key:question.id,staticClass:"question-selector-list__item",attrs:{"id":question.id,"text":question.text,"is-answered":question.isAnswered,"is-selected":question.isSelected},on:{"click":_vm.handleClick}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowButtons),expression:"isShowButtons"}],class:[
      'question-selector-list__buttons',
      'question-selector-list__btn-next',
      { '--active': _vm.hasNext, '--inactive': !_vm.hasNext },
    ],attrs:{"role":"button","aria-label":"avançar questões"},on:{"click":_vm.onNext}},[_c('icon',{staticClass:"question-selector-list__icon",attrs:{"type":"chevron-right","stroke-width":"4"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }