<template>
  <div
    class="game-error"
    :style="{
      backgroundImage: `url(${require(`@/assets/error/${code}/bg.svg`)})`
    }"
  >
    <img
      class="game-error__illustration"
      :src="require(`@/assets/error/${code}/illustration.svg`)"
    >
    <h2 class="game-error__message">
      {{ $t(`game.error.${code}.message`) }}
    </h2>
    <p
      v-if="$te(`game.error.${code}.observation`)"
      class="game-error__observation"
    >
      {{ $t(`game.error.${code}.observation`) }}
    </p>
    <g-button
      class="game-error__action"
      size="large"
      @click="action"
    >
      {{ $t(`game.error.${code}.action`) }}
    </g-button>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'
import redirectTo from '@/utils/redirectTo'
import { getPortalHomeURL } from '@/utils/urls'

export default {
  name: 'GameError',
  components: {
    GButton,
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  computed: {
    code() {
      return this.$te(`game.error.${this.status}`)
        ? this.status
        : this.status - (this.status % 100)
    },
  },
  created() {
    this.$prependTitle(`${this.$t(`game.error.${this.code}.title`)} | `)
  },
  methods: {
    action() {
      switch (this.code) {
        case 403: {
          const isMicrofrontend = getIsMicrofrontend()
          const portalHomeURL = getPortalHomeURL(isMicrofrontend)

          redirectTo(portalHomeURL)
          break
        }
        case 404:
          this.$router.push({ name: 'worlds' })
          break

        default:
          window.location.reload()
          break
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.game-error
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 100vh
  padding-top: 70px
  +flex-column-center

  &__illustration
    margin-bottom: 32px

  &__message
    font-weight: bold
    font-size: 32px
    color: $color-white
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25)
    max-width: 480px
    text-align: center

  &__observation
    color: $color-white
    margin-top: 16px

  &__action
    margin-top: 32px
</style>
