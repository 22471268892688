<template>
  <div
    :class="['ancestral-arena-card-question', {
      '--disabled': option.isDisabledOption,
      '--correct' : option.isCorrectOption,
      '--wrong' : option.isWrongOption,
      '--selected': option.isSelectedOption,
      '--error': option.hasSomeError,
    }]"
    aria-label="Alternativa"
    :style="styleCard"
    @click="selectOption"
  >
    <div
      v-if="option.markOption"
      aria-label="Alternativa Selecionada"
      class="--selected-mark"
      :style="cardMarkStyle"
    />
    <div
      v-if="loading"
      class="ancestral-arena-card-question__info"
    >
      <skeleton-loader
        class="ancestral-arena-card-question__info__letter"
        opacity="0.5"
        width="40px"
        height="68px"
        border-radius="8px 0px 0px 8px"
      />
      <skeleton-loader
        class="ancestral-arena-card-question__info__description__skeleton"
        opacity="0.5"
        width="300px"
        height="20px"
        border-radius="8px"
      />
    </div>
    <template v-else>
      <div class="ancestral-arena-card-question__info">
        <div
          class="ancestral-arena-card-question__info__letter"
          :style="styleLetter"
          :aria-label="`Alternativa ${option.letter}`"
        >
          {{ option.letter }}
        </div>
        <slot name="description">
          <div
            class="ancestral-arena-card-question__info__description"
            aria-label="Descrição"
            v-html="option.description"
          />
        </slot>
        <slot name="icon">
          <img
            v-if="option.isCorrectOption"
            aria-label="Alternativa Correta"
            class="ancestral-arena-card-question__info__icon"
            src="@/assets/feedback/check-badge.svg"
            alt=""
          >
          <img
            v-if="option.isWrongOption"
            data-testid="Alternativa Incorreta"
            class="ancestral-arena-card-question__info__icon"
            src="@/assets/feedback/wrong-badge.svg"
            alt=""
          >
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AncestralArenaCardQuestion',
  props: {
    option: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => [ 'primary', 'secondary', 'tertiary', 'editable' ].includes(value),
    },
  },
  data() {
    return {
      styleCard: {},
      styleLetter: {},
    }
  },
  computed: {
    cardMarkStyle() {
      return {
        borderColor: `transparent ${this.option.color} transparent transparent`,
      }
    },
  },
  watch: {
    variation: {
      immediate: true,
      handler() {
        const styles = {
          primary: {
            card: { border: 'none' },
            letter: { background: this.option.color, borderRadius: '8px 0 0 8px' },
          },
          secondary: {
            card: { border: `8px solid ${this.option.color}` },
            letter: { background: this.option.color, borderRadius: 'initial' },
          },
          tertiary: {
            letter: { background: '#605870', borderRadius: '8px 0 0 8px' },
          },
          editable: {
            card: { border: '2px solid #E4CCAA', boxShadow: 'none' },
            letter: {
              background: this.option.color,
              borderRadius: '6px 0 0 6px',
              fontSize: '2rem',
              fontWeight: '300',
            },
          },
        }

        if (this.variation) {
          this.styleCard = styles[this.variation].card
          this.styleLetter = styles[this.variation].letter
        }
      },
    },
  },
  methods: {
    selectOption() {
      this.$emit('select-option', this.option)
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-card-question {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $eureka-color-ink;
  font-family: 'Rowdies';
  background-color: $eureka-color-base-light;
  box-shadow: 0 3px 0 #C2AB8C, 0 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-m;
  height: min-content;

  &.--error {
    border: 3px solid $eureka-color-danger !important;
  }

  &__info {
    width: 100%;
    display: flex;

    &__letter {
      color: $color-white;
      font-family: 'Rowdies';
      padding: $size-m;
      @include flex-center;
    }

    &__description {
      word-break: break-word;
      padding: $size-s;
      font-weight: $font-weight-regular;
      flex: 1;

      &__skeleton {
        margin: $size-s;
      }
    }

    &__icon {
      max-width: 36px;
      height: auto;
      margin-right: $size-s;
    }
  }

  &.--disabled {
    cursor: default;
    opacity: 0.5;

    &.--selected,
    &.--correct,
    &.--wrong {
      opacity: 1;
    }

    & .--selected-mark {
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-width: 0 32px 32px 0;
      border-style: solid;
      border-color: transparent red transparent transparent;
    }
  }
}
</style>
