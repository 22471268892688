<template>
  <ScrollContainer
    ref="missionScreen"
    :class="[
      'missions',
      {
        '--error': assignments.error,
      },
    ]"
    :style="`background: ${missionBackground}`"
    moz-full-scroll
  >
    <div
      class="missions__topbar"
      :class="{
        '--scroll' : showTopbarBackground,
        '--mfe-margin': isMFE
      }"
    >
      <TeacherTopbar
        v-if="isTeacherTopbarVisible"
        selector-is-hidden
      />
      <div class="missions__topbar__user-status">
        <div class="missions__topbar__wrapper">
          <div class="missions__topbar__back">
            <GButton
              class="missions__topbar__back__button"
              icon="arrow-left"
              size="medium"
              variation="framed"
              @click="goBackHome"
            />
          </div>
          <UserStatusBar
            :color="levelDisplayColor"
            :hide-badge-on-mobile="true"
          />
        </div>
      </div>
    </div>
    <div
      ref="header"
      class="missions__header"
      :style="{
        backgroundColor,
        backgroundImage,
      }"
    >
      <WorldTitle
        v-if="themes"
        ref="worldTitle"
        :title="$t(`lectures.${slug}`)"
        :gems="gemsAmount"
      />
    </div>
    <div class="missions__container">
      <div
        v-if="!assignments.error"
        ref="grid"
        class="missions__grid"
      >
        <MapSide
          v-if="assignments.success"
          ref="leftTiles"
          side="left"
          :mission-track-height="trackHeight"
          :theme="theme"
          :missions-amount="assignments.data.length"
        />

        <div
          v-if="theme && assignments.success"
          ref="track"
          class="missions__grid__track-container"
        >
          <Track
            :color="theme.ground.color"
            :dark-mode="theme.ground.darkMode"
            :stroke-color="theme.ground.trackColor"
            :missions="assignments.data"
            @click-on-mission="clickOnMission"
          />
        </div>

        <MapSide
          v-if="assignments.success"
          ref="rightTiles"
          side="right"
          :mission-track-height="trackHeight"
          :theme="theme"
          :missions-amount="assignments.data.length"
        />
      </div>
      <transition name="fade">
        <div
          v-if="assignments.error"
          class="missions__error"
        >
          <img
            class="missions__error__illustration"
            :src="require(`@/assets/error/missions/illustration.svg`)"
          >
          <h2
            :class="[
              'missions__error__message',
              { '--dark-mode': theme.ground.darkMode }
            ]"
            :style="{ color: theme.ground.color }"
          >
            {{ $t(`game.error.missions.message`) }}
          </h2>
          <p
            v-if="$te(`game.error.${assignments.error.status}.obs`)"
            class="missions__error__obs"
            :style="{
              color: theme.ground.color
            }"
          >
            {{ $t(`game.error.${assignments.error.status}.obs`) }}
          </p>
          <g-button
            class="missions__error__action"
            size="large"
            @click="onErrorAction"
          >
            {{ assignmentButtonText }}
          </g-button>
        </div>
      </transition>
    </div>
    <OnBoarding
      :mission="missionOnBoarding"
      @start-mission="goToMission"
      @active-item="activeItem($event)"
      @close-on-boarding="closeOnBoarding"
    />
    <NextWorldModal
      v-if="!nextLecture.loading && nextLecture.data"
      :next="nextLecture"
      @goToNext="goToNextWorld(nextLecture.data)"
    />
  </ScrollContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { gsap } from 'gsap'
import {
  playBackground,
  playSoundFX,
  pauseBackground,
  resumeBackground
} from '@/utils/soundEffects'
import gradeId from '@/mixins/gradeId'
import ScrollContainer from '@/components/ScrollContainer'
import GButton from '@/components/GButton'
import UserStatusBar from '@/components/UserStatusBar'
import NextWorldModal from '@/components/NextWorldModal'
import TeacherTopbar from '../../components/TeacherTopbar'
import MapSide from './partials/MapSide'
import Track from './partials/Track'
import OnBoarding from './partials/OnBoarding'
import WorldTitle from './partials/WorldTitle'

export default {
  name: 'Missions',
  components: {
    MapSide,
    Track,
    OnBoarding,
    UserStatusBar,
    GButton,
    NextWorldModal,
    TeacherTopbar,
    WorldTitle,
    ScrollContainer,
  },
  mixins: [ gradeId ],
  props: {
    lectureId: {
      type: Number,
      required: true,
    },
    chapterId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      trackHeight: 0,
      scrollPosition: null,
      missionOnBoarding: null,
      missionItem: [],
    }
  },
  computed: {
    ...mapGetters([
      'themes',
      'lecture',
      'lectures',
      'assignments',
      'nextLecture',
      'user',
      'userGrades',
      'assignment',
    ]),
    isMFE() {
      return window.eureka_init
    },
    assignmentButtonText() {
      const especificErrorAction = `game.error.${this.assignments.error.status}.action`
      const translatePath = this.$te(especificErrorAction) ? especificErrorAction : 'game.error.default.action'

      return this.$t(translatePath)
    },
    levelDisplayColor() {
      return this.showTopbarBackground ? '' : this.theme.sky.color
    },
    theme() {
      if (!this.themes) {
        return null
      }

      const { data } = this.themes
      const { lectureId } = this

      return data.find(({ worldId }) => worldId === lectureId)
    },
    slug() {
      if (this.theme) {
        return this.theme.slug
      }

      return ''
    },
    backgroundColor() {
      return this.theme?.sky.background
    },
    backgroundImage() {
      return this.theme ? `url(${this.theme.missions.top.sky})` : ''
    },
    gemsAmount() {
      if (this.lectures.success) {
        const { lectureId } = this
        const { data } = this.lectures

        const currentLecture = data.find(({ id }) => id === lectureId)

        return currentLecture
      }

      return {}
    },
    missionBackground() {
      return this.theme
        ? this.theme.ground.background
        : 'transparent'
    },
    showTopbarBackground() {
      return this.scrollPosition > 20
    },
    userGradeId() {
      const studentGradeId = this.userGrades.data?.find((grade) => grade).id
      const teacherGradeId = this.gradeId

      if (this.user.data) {
        return this.user.data.userIsStudent
          ? studentGradeId
          : teacherGradeId
      }

      return null
    },
    trackReady() {
      return this.assignments.success
    },
    isTeacherTopbarVisible() {
      if (this.user.data) {
        return !this.user.data.userIsStudent && this.userGrades?.data?.length
      }

      return false
    },
  },
  watch: {
    trackReady() {
      if (this.trackReady) {
        this.$nextTick(() => {
          gsap.from(
            this.$refs.track,
            {
              duration: 1,
              y: '10%',
            }
          )
          gsap.from(
            this.$refs.leftTiles.$el,
            {
              duration: 1,
              x: -300,
            }
          )
          gsap.from(
            this.$refs.rightTiles.$el,
            {
              duration: 1,
              x: 300,
            }
          )
        })
      }
    },
    userGradeId(newGradeId, oldGradeId) {
      if (newGradeId !== oldGradeId && (Boolean(oldGradeId))) {
        const { lectureId } = this
        this.getTheme(lectureId)
      }
    },
  },
  async created() {
    if (!this.theme) {
      const worldId = this.lectureId
      const gradeTemplateId = this.userGradeId
      await this.setThemes({ worldId, gradeTemplateId })
    }
    this.getBagItems()
    if (!this.userGradeId) {
      await this.getUser()
    }

    if (!this.lectures.data) {
      const [ grade ] = this.user.data.grades
      await this.getLectures(grade.id)
    }

    playBackground()

    await this.setMissionsWorld(this.userGradeId, this.lectureId)

    if (this.chapterId) {
      this.handleChapterAction()
    }
  },
  mounted() {
    this.$refs.missionScreen.$el.addEventListener('scroll', this.updateScroll)
    this.$watch(
      'theme',
      (theme) => {
        if (theme) {
          this.initialDrawing()
        }
      },
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.$refs.missionScreen.$el.removeEventListener('scroll', this.updateScroll)
  },
  destroyed() {
    this.resetNextLecture()
  },
  methods: {
    ...mapActions([
      'setThemes',
      'getLecture',
      'setAssignment',
      'getAssignments',
      'getNextLecture',
      'resetNextLecture',
      'getUser',
      'getTheme',
      'getBagItems',
      'getItemEffects',
      'getLectures',
    ]),
    activeItem(payload) {
      if (payload) {
        this.missionItem.push(payload)
      } else {
        this.missionItem = []
      }
    },
    initialDrawing() {
      this.$prependTitle(`${this.$t(`lectures.${this.slug}`)} | `)
      this.$nextTick(() => {
        gsap.from(
          '.world-title',
          {
            duration: 1,
            y: -40,
            opacity: 0,
          }
        )
        gsap.from(
          this.$refs.header,
          {
            duration: 1,
            opacity: 0,
            'background-position-y': '-30%',
          }
        )
      })
    },
    async getAssignmentsAndSetTrackHeight() {
      if (this.lectureId) {
        try {
          await this.getAssignments({
            lectureId: this.lectureId,
            gradeId: this.userGradeId,
          })
          this.$nextTick(() => {
            if (this.$refs.track) {
              this.trackHeight = this.$refs.track.firstChild.offsetHeight
            }
          })
        } catch (error) {
          console.error(error)
        }
      }
    },
    updateScroll() {
      this.scrollPosition = parseInt(this.$refs.missionScreen.$el.scrollTop, 10)
    },
    goBackHome() {
      playSoundFX()
      this.$router.push({
        name: 'worlds',
        query: {
          ...this.$route.query,
          lectureId: this.lectureId,
        },
        params: {
          hasInitialSplashScreen: false,
        },
      })
    },
    clickOnMission(mission) {
      this.getBagItems({
        missionId: mission.code,
      })
      if (mission.status === 'finished') {
        playSoundFX()
        pauseBackground()
        this.setAssignment({
          title: mission.title,
          subtitle: this.$t(`lectures.${this.slug}`),
        })
        this.$router.push({
          name: 'report',
          query: { ...this.$route.query },
          params: {
            lectureId: this.lectureId,
            chapterId: mission.id,
            questionnaireCode: mission.questionnaireCode,
          },
        })
      } else if (mission.status !== 'unreleased') {
        playSoundFX()
        pauseBackground()
        this.missionOnBoarding = { ...mission }
      }
    },
    goToMission({ mission, items }) {
      playSoundFX()
      this.setAssignment({
        title: mission.title,
        subtitle: this.$t(`lectures.${this.slug}`),
      })

      if (mission.status === 'start') {
        this.$trackEvent({
          category: this.$track.category.modalMission,
          action: this.$track.action.startMission,
          label: items.length
            ? this.$track.label.enabledItem
            : this.$track.label.disabledItem,
        })
      } else if (mission.status === 'resume') {
        this.$trackEvent({
          category: this.$track.category.modalMission,
          action: this.$track.action.continueMission,
        })
      }

      this.$router.push({
        name: 'question',
        query: { ...this.$route.query },
        params: {
          chapterId: mission.id,
          lecture: this.lectureId,
          questionnaireCode: mission.questionnaireCode,
          missionItem: this.missionItem,
        },
      })
    },
    closeOnBoarding() {
      resumeBackground()
      playSoundFX()
      this.missionOnBoarding = null
      this.$trackEvent({
        category: this.$track.category.modalMission,
        action: this.$track.action.closeModalMission,
      })
    },
    onErrorAction() {
      if (this.assignments.error.status === 404) {
        this.$router.push({
          name: 'worlds',
          query: {
            ...this.$route.query,
            lectureId: this.lectureId,
          },
        })
      } else {
        window.location.reload()
      }
    },
    goToNextWorld(lectureId) {
      playSoundFX()
      this.$trackEvent({
        category: this.$track.category.trackMissions,
        action: this.$track.action.toGoOtherWorld,
      })
      this.$router.push({
        name: 'assignments',
        query: { ...this.$route.query },
        params: { lectureId },
      }, () => this.setMissionsWorld(this.userGradeId, lectureId))
    },
    async setMissionsWorld(grade, lectureId) {
      if (grade && lectureId) {
        await this.getAssignmentsAndSetTrackHeight()
        await this.getLecture(lectureId)
        this.getTheme(lectureId)
        this.getNextLecture({ gradeId: grade, lectureId })
      }
    },
    async handleChapterAction() {
      const routeQueryParams = { ...this.$route.query }
      const { chapterId, assignments } = this
      const [ selectedChapter ] = assignments.data
        .filter(({ id }) => id === chapterId)
        .map((chapter) => ({
          ...chapter,
          isFinished: chapter.status === 'finished',
          isUnreleased: chapter.status === 'unreleased',
        }))

      if (selectedChapter.isFinished) {
        this.setAssignment({
          title: selectedChapter.title,
          subtitle: this.$t(`lectures.${this.slug}`),
        })

        delete routeQueryParams.chapterId

        this.$router.push({
          name: 'report',
          query: routeQueryParams,
          params: {
            lectureId: this.lectureId,
            chapterId: selectedChapter.id,
            questionnaireCode: selectedChapter.questionnaireCode,
          },
        })
      } else if (!selectedChapter.isUnreleased) {
        await this.getBagItems({
          missionId: selectedChapter.code,
        })

        playSoundFX()
        pauseBackground()
        delete this.$route.query.chapterId
        this.missionOnBoarding = selectedChapter
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.missions {
  position: relative;
  height: 100vh;

  @media screen and (max-width: 991px) {
    padding-bottom: 120px;
  }

  &__container {
    position: relative;
    z-index: 9;
    @include flex-center;
    overflow: hidden;

    .--error & {
      height: calc(100vh - 320px);
      @include flex-center;
    }
  }

  &__topbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;

    &__user-status {
      padding: 1rem 2rem;

      @media screen and (max-width: 991px) {
        padding: 2px 12px;
      }
    }

    &.--scroll {
      position: fixed;
      top: 0;
    }

    &__wrapper {
      @include flex-space-between;
      max-width: 1920px;
      margin: auto;
    }

    &::after {
      @include pseudo;
      @include cover(absolute);
      background: $frame-bg-color;
      box-shadow: $frame-shadow;
      z-index: -1;
      transform: translateY(-110%);
      @include transition($speed-normal);
    }

    &.--scroll::after {
      transform: translateY(0);
      @include transition($speed-normal);
    }

    &__back {
      display: inline-flex;
      align-items: center;

      &__button {
        margin-right: 1rem;

        @media screen and (max-width: 768px) {
          margin-right: 2px;
        }
        ::v-deep svg {
          stroke-width: 4;
        }
      }
    }
  }

  &__header {
    height: 25rem;
    @include flex-center;
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;

    .--error & {
      height: 20rem;
    }

    &__status-bar {
      position: absolute;
      top: $menu-height;
      width: 100%;
      padding: 1.5rem 1.5rem 0;
      @include flex-space-between;
    }
  }

  &__grid {
    --topOffset: min(14vh, 100px);
    grid-template-columns: 1fr min(50vw, 45rem) 1fr;

    display: grid;
    margin: 0 auto;
    background-image: url(~@/assets/terrain-patterns.svg);
    justify-content: center;
    overflow: hidden;

    &__track-container {
      padding-top: var(--topOffset);
    }
  }

  &__error {
    @include flex-column-center;

    @media only screen and (max-height: 821px) {
      position: absolute;
      bottom: 1.5rem;
    }

    &__illustration {
      margin-bottom: 1rem;
    }

    &__message {
      font-weight: bold;
      font-size: 1.5rem;
      max-width: 480px;
      text-align: center;

      &.--dark-mode {
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
      }
    }

    &__obs {
      color: $color-white;
      margin-top: 1rem;
      opacity: 0.75;
    }

    &__action {
      margin-top: 2rem;
    }
  }
}

.--mfe-margin {
  margin-top: $portal-cross-menu-height;

  .teacher-top-bar {
    margin-top: 55px;
  }
}
</style>
