<template>
  <div
    class="slide"
    :style="{'background-image': `url(${background})`}"
  >
    <section
      v-if="hasValidData"
      class="slide__content"
    >
      <h1 class="slide__title --large">
        Conhecemos diversos lugares novos
      </h1>
      <p class="slide__text">
        Dentre eles, parece que você consegue fazer um ótimo roteiro
        turístico do mundo de {{ mostAccessedWorldData.title }}!
      </p>
      <p class="slide__text">
        Esse foi o mundo que você mais explorou, totalizando
        <strong class="slide__text --important">
          {{ mostAccessedWorldData.missionCount }} missões
        </strong>
      </p>
    </section>
  </div>
</template>
<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import { mapGetters } from 'vuex'
import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'
import isNotEmpty from '@/utils/isNotEmpty'

import { lectures } from '@/store/theme/utils'
import getGradeId from '@/mixins/getGradeId'
import { gradeTemplates } from '@/themes/grades'

export default {
  name: 'RetrospectiveMostAccessedWorld',
  mixins: [ getGradeId ],
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'userGrades',
      'lectures',
    ]),
    mostAccessedWorldData() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const mostAccessedWorldData = pick(this.retrospectiveData, [ 'mostAccessedWorld' ])

      return mostAccessedWorldData.mostAccessedWorld
    },
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    hasValidData() {
      if (isEmpty(this.mostAccessedWorldData)) {
        return false
      }

      const {
        title,
        missionCount,
      } = this.mostAccessedWorldData

      return isNotEmpty(title)
      && isNotEmpty(missionCount)
    },
    background() {
      if (!this.mostAccessedLecture) {
        return ''
      }

      const gradeTemplateId = this.userGradeId
      const { lecture } = lectures.find(({ id }) => id === this.mostAccessedLecture.id)
      const { templates } = gradeTemplates.find(({ name }) => name === lecture)
      const template = templates.find((temp) => temp.gradeId === gradeTemplateId)
      const { gradeTemplate } = template

      return require(`@/themes/${lecture}/${gradeTemplate}/mission-bg.svg`)
    },
    mostAccessedLecture() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const { mostAccessedWorld } = pick(this.retrospectiveData, [ 'mostAccessedWorld' ])

      if (mostAccessedWorld && this.lectures.data) {
        return this.lectures.data.find(({ name }) => name === mostAccessedWorld.title)
      }

      return null
    },
    userGradeId() {
      const studentGradeId = this.userGrades.data?.find((grade) => grade).id
      const teacherGradeId = this.getGradeId()

      if (this.user.data) {
        return this.user.data.userIsStudent
          ? studentGradeId
          : teacherGradeId
      }

      return null
    },
  },
  watch: {
    mostAccessedWorldData: {
      immediate: true,
      handler(newMostAccessedWorldData, oldMostAccessedWorldData) {
        if (newMostAccessedWorldData !== oldMostAccessedWorldData) {
          this.$emit('updateValidation', { name: 'mostAccessedWorld', isValid: this.hasValidData })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
    width: 100%;
    height: 100%;
    @include flex-center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
