<template>
  <div class="slide">
    <section
      v-if="hasValidData"
      class="slide__content"
    >
      <h1 class="slide__title --large">
        Consultamos a sua jornada na Magna Libris
      </h1>
      <p class="slide__text">
        Parece que <strong class="slide__text --important">{{ lastGameIconData.title }}</strong>
        esteve presente na sua trajetória
      </p>
      <HighlightedIcon class="slide__highlighted-icon">
        <img
          v-if="lastGameIconData.roundImage"
          class="slide__image --no-margin"
          :src="lastGameIconData.roundImage.png"
          :alt="lastGameIconData.title"
        >
      </HighlightedIcon>
      <CollectionItems
        v-if="!isMicrofrontend && lastGameIconData.collectionItems.length"
        title="Esses foram os personagens mais escolhidos pela sua turma no ano!"
        :items="lastGameIconData.collectionItems"
      />
    </section>
  </div>
</template>
<script>
import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'
import isNotEmpty from '@/utils/isNotEmpty'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

import HighlightedIcon from './HighlightedIcon'
import CollectionItems from './CollectionItems'

export default {
  name: 'RetrospectiveLastGameIconChosen',
  components: {
    HighlightedIcon,
    CollectionItems,
  },
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isMicrofrontend() {
      return getIsMicrofrontend()
    },
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    hasValidData() {
      if (isEmpty(this.lastGameIconData)) {
        return false
      }

      const {
        title,
        roundImage,
        collectionItems,
      } = this.lastGameIconData

      return isNotEmpty(title)
      && isNotEmpty(roundImage)
      && isNotEmpty(collectionItems)
    },
    lastGameIconData() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const lastGameIconData = pick(this.retrospectiveData, [ 'gameIcon', 'classroomGameIcons' ])
      let collectionItems = []

      if (isNotEmpty(lastGameIconData.classroomGameIcons)) {
        collectionItems = lastGameIconData.classroomGameIcons.map(
          ({ roundImage, title }, index) => ({
            image: roundImage?.svg,
            textTop: `${index + 1}.`,
            text: title,
            size: 'large',
            themeColor: 'transparent',
            type: undefined,
          })
        )
      }

      return { ...lastGameIconData.gameIcon, collectionItems }
    },
  },
  watch: {
    lastGameIconData: {
      immediate: true,
      handler(newLastGameIconData, oldLastGameIconData) {
        if (newLastGameIconData !== oldLastGameIconData) {
          this.$emit('updateValidation', { name: 'lastGameIconChosen', isValid: this.hasValidData })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  background-image: url('~@/assets/retrospective/background-retro-5.png');
  background-repeat: no-repeat;
  background-size: cover;

  ::v-deep .collection-items__title {
    @media screen and (max-height: 789px) {
      margin-bottom: $size-s;
    }
  }

  ::v-deep .collection-items__group {
    .collection-item__text-top {
      font: {
        size: $font-size-heading-4;
        family: Rowdies;
      }
      color: $color-white;
      text-shadow: 0 1px 0 $color-black;
    }

    .g-block {
      display: inline-flex;
      padding-left: $size-xs;
      margin-top: -5px;

      &__text {
        font-size: $font-size-heading-6;
      }
    }
  }
}
</style>
