var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    disabled: !_vm.isAnswered,
    content: 'Questão respondida',
    placement: 'top',
  }),expression:"{\n    disabled: !isAnswered,\n    content: 'Questão respondida',\n    placement: 'top',\n  }"}],class:[
    'question-selector',
    {
      '--answered': _vm.isAnswered,
      '--selected': _vm.isSelected,
    },
  ],attrs:{"aria-label":`questão ${_vm.text}`},on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }