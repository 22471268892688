var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"item-thumbnail"},[_c('skeleton-loader',{attrs:{"width":"64px","height":"64px","border-radius":"50%"}})],1):_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.bagItem.item.name),expression:"bagItem.item.name",modifiers:{"bottom":true}}],class:[
      'item-thumbnail',
      { '--hoverable': _vm.hoverable }
    ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('img',{staticClass:"item-thumbnail__image",class:_vm.disabled ? '--disabled' : '',attrs:{"src":_vm.bagItem.item.image
        ? _vm.bagItem.item.image.svg
        : _vm.bookMissionPath}}),(_vm.bagItem.quantity >= 0 && !_vm.alreadyUsed)?_c('div',{class:[
        'item-thumbnail__quantity',
        { '--has-border': _vm.border }
      ]},[_c('span',{staticClass:"item-thumbnail__quantity__number"},[_vm._v(" "+_vm._s(_vm.bagItem.quantity)+" ")])]):(_vm.alreadyUsed)?_c('div',{staticClass:"item-thumbnail__icon"},[_c('icon',{attrs:{"type":"check","size":"16","stroke":"white","stroke-width":5}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }