<template>
  <footer :class="['mission-footer', { '--no-feedback': isMobile && !feedbackIsVisible }]">
    <div
      v-if="!feedbackIsVisible && !isMobile"
      class="answer-background"
    />

    <slot
      v-if="!feedbackIsVisible && isMobile"
      name="bag"
    />

    <slot name="feedback" />
  </footer>
</template>

<script>
export default {
  name: 'MissionFooter',
  props: {
    actionLabel: {
      type: String,
      default: '',
    },
    feedbackIsVisible: Boolean,
    isMobile: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.mission-footer {
  @include flex-space-between;
  position: fixed;
  width: 100%;
  padding: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  background: $eureka-color-base;
  z-index: 10;

  &.--no-feedback {
    padding: 0 2rem;

    @media screen and (max-width: 479px) {
      padding: 0 1.25rem;
    }
  }
}

@media screen and (min-width:992px) {
  .mission-footer {
    width: min(65%, 52rem);
    left: calc((100% - min(65%, 52rem)) / 2);
    background: none;
    justify-content: center;

    .answer-background {
      position: absolute;
      width: min(91%,50rem);
      height: 100%;
      background: $color-white;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
