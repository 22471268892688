<template>
  <div
    :class="[
      'worlds-list-grid',
      { '--small': amountOfWorlds === 2}
    ]"
  >
    <template v-if="lectures.success">
      <div class="worlds-list-grid__content">
        <WorldsListItem
          v-for="(world, index) in worlds"
          :key="index"
          :active="false"
          show-title
          size="small"
          show-diamonds
          :world="world"
          :grade-templates="grades"
          data-testid="world"
          class="word-content-grid"
          @click="goToWorld($event, index)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import gsap from 'gsap'
import { mapGetters, mapActions } from 'vuex'

import { themedLectures } from '@/themes'
import { playSoundFX } from '@/utils/soundEffects'
import { gradeTemplates } from '@/themes/grades'
import WorldsListItem from './WorldsListItem'

export default {
  name: 'WorldsListGrid',
  components: {
    WorldsListItem,
  },
  data() {
    return {
      grades: gradeTemplates,
    }
  },
  computed: {
    ...mapGetters([ 'lectures' ]),
    worlds() {
      return this.lectures.data.filter((lecture) => (
        themedLectures.includes(lecture.id)
      ))
    },
    amountOfWorlds() {
      return this.worlds?.length || 0
    },
    lectureId() {
      return parseInt(this.$route.query.lectureId, 10)
    },
  },
  mounted() {
    gsap.from('.worlds-list-item', {
      scale: 0,
      duration: 0.5,
      stagger: 0.02,
      ease: 'power2',
    })
  },
  created() {
    if (this.lectures.success) {
      if (this.lectureId) {
        const worldIndex = this.worlds.findIndex((world) => (
          world.id === this.lectureId
        ))
        this.setActiveWorld(worldIndex)
      } else {
        this.setActiveWorld(0)
      }
    }
  },
  methods: {
    ...mapActions([
      'setLecture',
    ]),
    goToWorld(lecture, index) {
      playSoundFX()
      this.setLecture(lecture)

      if (index !== this.activeWorld) {
        this.setActiveWorld(index)
        setTimeout(() => {
          this.$emit('go-to-world', lecture)
        }, this.$tokens.speed_normal)
      } else {
        this.$emit('go-to-world', lecture)
      }
    },
    setActiveWorld(index) {
      const world = this.worlds[index]
      if (parseInt(this.lectureId, 10) !== world.id) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            lectureId: world.id,
          },
          params: {
            hasInitialSplashScreen: false,
          },
        })
      }
      this.activeWorld = index
      this.$emit('active-world', world)
    },
  },
}
</script>

<style lang="sass" scoped>
.worlds-list-grid
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center

  &::before
    content: ''
    +pseudo
    background: linear-gradient(0deg, #D0D8F4 0%, rgba(208, 216, 244, 0) 100%)
    opacity: 0.75
    width: 100%
    height: 10%
    bottom: 0px
    left: 0
    position: fixed
    z-index: 200
    user-select: none
    pointer-events: none

  &__content
    padding-bottom: 60px
    height: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    overflow: auto

::v-deep .worlds-list-item
  box-sizing: border-box
  padding: 16px 24px
  border-radius: 16px

  &:hover
    background: rgba(0,0,0,0.05)

  &__jewels-card
    margin-top: 25px
</style>
