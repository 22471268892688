<template>
  <div>
    <div class="separator" />
    <div class="magna-mission-student-home-room">
      <section class="magna-mission-student-home-room__question-selector">
        <QuestionSelectorList
          :questions="mappedQuestionSelectorList"
          :initial-question-index="selectedQuestionOrder"
          @click="handleSelectorQuestion"
        />
        <div class="magna-mission-student-home-room__finish-mission">
          <GButton
            no-border
            size="medium"
            variation="tertiary"
            :disabled="isDisableFinishButton || isLoadingReport"
            class="magna-mission-student-home-room__finish-mission-button"
            @click="finishMission"
          >
            Finalizar missão
          </GButton>
        </div>
      </section>
      <div class="magna-mission-student-home-room__container">
        <section class="magna-mission-student-home-room__question-container">
          <div class="magna-mission-student-home-room__question-container__content">
            <div
              class="magna-mission-student-home-room__question-container__content__body"
              v-html="mappedSelectedQuestion.statement"
            />
          </div>
        </section>
        <section class="magna-mission-student-home-room__options-container">
          <QuestionOption
            v-for="(options) in mappedSelectedQuestion.options"
            :key="options.id"
            :option="options"
            :question="mappedSelectedQuestion"
            @select-option="selectOption"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil, isEmpty } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CSAT_HOME_MISSION_FORM_ID } from '@/constants/csatFormIDs'

import GButton from '@/components/GButton'
import assignments from '@/service/assignments'
import typeformService from '@/service/typeform'

import urls from '@/utils/urls'
import QuestionOption from './components/QuestionOption/QuestionOption'
import QuestionSelectorList from './components/QuestionSelectorList/QuestionSelectorList'

export const statusEnum = {
  answered: 'answered',
  notAnswered: 'not_answered',
}

export default {
  name: 'MagnaMissionStudentHomeRoom',
  components: {
    QuestionOption,
    QuestionSelectorList,
    GButton,
  },
  props: {
    questions: {
      type: Object,
      default: () => ({ questions: [], title: '' }),
    },
    answerUpdating: {
      type: Boolean,
      default: false,
      required: true,
    },
    missionTitle: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      selectedQuestionId: null,
      teacherFinishedMission: false,
    }
  },
  computed: {
    ...mapGetters([ 'grade', 'report' ]),
    ...mapState({
      magnaMission: ({ arena }) => arena.magnaMission.data,
      user: ({ account }) => account.user.data,
      isTypeformReady: ({ typeform }) => typeform.isTypeformReady,
    }),
    isLoadingReport() {
      return this.report.data?.isLoading ?? false
    },
    selectedQuestionOrder() {
      return this.questionsList?.questions
        ?.find((question) => question.id === this.selectedQuestionId)?.order ?? null
    },
    assignmentCode() {
      return this.questions?.assignmentCode ?? null
    },
    isDisableFinishButton() {
      return (this.answerUpdating || !this.questionsList?.questions
        ?.every(
          (question) => question.status === statusEnum.answered
        )) ?? false
    },
    mappedQuestionsOptionsWithLetters() {
      return this.questionsList?.questions?.map((question) => ({
        ...question,
        options: question?.options?.map(this.questionWithLetter) ?? [],
      })) ?? []
    },
    mappedQuestionSelectorList() {
      return this.questionsList?.questions?.map(({
        id, order, selected, status,
      }) => {
        const isAnswered = status === statusEnum.answered
        const text = Number(order) + 1

        return ({
          id,
          text,
          isAnswered,
          isSelected: selected,
        })
      }) ?? []
    },
    mappedSelectedQuestion() {
      return this.mappedQuestionsOptionsWithLetters
        ?.find((question) => question.id === this.selectedQuestionId)
        ?? { options: [], statement: '' }
    },
    questionsList() {
      return this.questions ?? { questions: [], title: '' }
    },
    hasSelectedQuestion() {
      return this.questionsList?.questions?.some((question) => question.selected) ?? false
    },
  },
  watch: {
    questions: {
      immediate: true,
      handler() {
        if (!this.selectedQuestionId) {
          const selected = this.questionsList?.questions
            ?.find((question) => question.selected)?.id

          if (!isEmpty(selected)) {
            this.selectedQuestionId = selected

            return
          }

          const selectedQuestionId = this.questionsList?.questions?.[0]?.id ?? null

          if (isNil(selectedQuestionId)) return

          this.selectedQuestionId = selectedQuestionId
          this.handleSelectorQuestion({ id: selectedQuestionId })
        }
      },
    },
    async selectedQuestionId(questionId) {
      await this.setSelectedQuestionId(questionId)
    },
    teacherFinishedMission() {
      if (this.teacherFinishedMission) {
        this.goToMissionFinished()
      }
    },
  },
  methods: {
    ...mapActions('globalToast', [ 'showToast' ]),
    ...mapActions([
      'getReport',
    ]),
    openFeedbackSurvey() {
      const formId = CSAT_HOME_MISSION_FORM_ID
      const url = urls.BASE_URL.concat(this.$route.path)
      const accountId = this.user?.id
      const grade = this.grade?.name
      const platform = 'web'
      const profile = this.user.profiles?.[0]?.name
      const magnaTitle = this.missionTitle
      const hitPercentage = this.report.data?.hitPercentage ?? 0

      const typeformPopup = typeformService.createPopup({
        formId,
        hiddenFields: {
          url,
          accountId,
          grade,
          platform,
          profile,
          magnaTitle,
          acertos: hitPercentage,
        },
      })

      typeformPopup.open()
    },
    showErrorToast({ text, description } = { text: '', description: '' }) {
      this.showToast({
        text,
        description,
        variation: 'error-secondary',
        asset: 'char/tata-sad.svg',
        autoClose: 7500,
      })
    },
    getQuestions() {
      this.$emit('getQuestions')
    },
    async finishMission() {
      try {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.studentFinishedHomeMission,
        })
        if (this.assignmentCode) {
          await assignments.finishAssignmentMission(this.assignmentCode)

          const { questionnaireCode } = this.$route.params
          await this.getReport(questionnaireCode)

          await this.openFeedbackSurvey()
          this.goToReportPage()
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.goToMissionFinished()

          return
        }

        this.getQuestions()

        this.showErrorToast({
          text: 'Ops, algo deu errado!',
          description: 'Não foi possível finalizar missão.',
        })
      }
    },
    async setSelectedQuestionId(questionId) {
      const body = {
        selected: questionId,
      }

      try {
        if (this.assignmentCode) {
          await assignments.putHomeAssignment(this.assignmentCode, body)
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.goToMissionFinished()

          return
        }

        this.showErrorToast({
          text: 'Ops, algo deu errado!',
          description: 'Não foi possível selecionar questão.',
        })
      }
    },
    goToMissionFinished() {
      this.$router.replace({
        name: 'teacher-finished-mission',
      })
    },
    goToReportPage() {
      this.$router.replace({
        name: 'mission-summary-page',
        params: {
          assignmentCode: this.questions.assignmentCode,
        },
      })
    },
    handleSelectorQuestion({ id } = { id: null }) {
      this.selectedQuestionId = id

      const questionsWithNewSelected = this.questionsList?.questions?.map((question) => ({
        ...question,
        selected: question.id === id,
      })) ?? []

      this.$emit('setQuestions', {
        ...this.questionsList,
        questions: questionsWithNewSelected,
      })
    },
    selectOption(option) {
      const { markedOption } = option

      const isValidOption = !isNil(markedOption) && Number(markedOption) >= 0
      const status = isValidOption ? statusEnum.answered : statusEnum.notAnswered

      this.$emit('setQuestionsById', {
        id: this.selectedQuestionId,
        data: { markedOption: option.markedOption, status },
      })
    },
    questionWithLetter(question, index) {
      return {
        ...question,
        letter: String.fromCharCode(65 + index),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  border-top: 1px solid $color-white;
  opacity: 0.2;
  margin: 0 $size-l;
}

.magna-mission-student-home-room {
  padding: 40px $size-xxl $size-l $size-xxl;

  @media screen and (max-width: 991px) {
    padding: $size-m $size-l $size-s $size-l;
  }

  @media screen and (max-width: 768px) {
    padding: $size-s $size-m $size-xs $size-m;
  }

  @media screen and (max-width: 568px) {
    padding: $size-xs $size-s $size-s $size-s;
  }

  &__question-selector {
    display: flex;
    gap: $size-xs;
    margin-bottom: $size-l;
    align-items: center;
  }

  &__finish-mission {
    display: inline-flex;
    margin-left: auto;
    max-width: 160px;
    min-width: 150px;
  }

  &__finish-mission-button {
    display: inline-flex;
    width: 150px;
  }

  &__container {
    gap: $size-xl;
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: $size-s;
    }
  }

  &__question-container {
    width: 60%;
    height: 100%;
    border-radius: $size-s;
    background: $eureka-color-base-lighter;
    filter: drop-shadow(0px 3px 0px #c2ab8c);

    @media screen and (max-width: 991px) {
      width: 85%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: Rowdies;
      font-weight: $font-weight-light;
      font-size: $font-size-m;
      color: $eureka-color-ink;

      padding: $size-s;
      scrollbar-color: $eureka-color-base-light rgba(#0d0425, 0.32);

      table {
        max-width: 100%;
      }

      img {
        width: 100%;
      }

      &__body {
        height: 100%;
        display: flex;
        overflow: hidden;
        text-align: center;
        align-items: center;
        flex-direction: column;

        --trackColor: $eureka-color-base-dark !important;
        background: $eureka-color-base-lighter;
        border-radius: $size-xs;

        ::v-deep p img {
          height: 70vw;
          max-height: 50vh;
          width: 100%;
        }

        &::-webkit-scrollbar-thumb {
          background: $eureka-color-base-darker !important;
          border: 8px solid $eureka-color-base-darker;
          box-shadow: inset -1px -4px 0 $eureka-color-base-darker;
        }

        &::-webkit-scrollbar {
          width: $size-xs;
          padding: 0.5rem;
        }
      }
    }
  }

  &__options-container {
    gap: 12px;
    width: 40%;
    display: flex;
    align-content: flex-start;
    flex-direction: column;

    @media screen and (max-width: 991px) {
      gap: 6px;
      width: 85%;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>
