<template>
  <div class="adventures-list">
    <GFrame
      class="adventures-list-frame"
      variation="tertiary"
      :class="{ '--mfe-margin': isMFE }"
    >
      <div class="adventures-list-frame__header">
        <img
          class="adventures-list-frame__header__icon"
          :src="headerInfo.icon"
        >
        <div class="adventures-list-frame__header__text">
          <h1 class="adventures-list-frame__header__title">
            {{ headerInfo.title }}
          </h1>
          <h2 class="adventures-list-frame__header__subtitle">
            {{ headerInfo.subtitle }}
          </h2>
        </div>
        <GButton
          :icon-stroke="4"
          class="button"
          variation="secondary"
          icon="x"
          @click="closeButton"
        />
      </div>
      <ScrollContainer class="adventures-list-frame__content">
        <template v-if="loading">
          <skeleton-loader
            height="30px"
            width="50%"
            class="adventures-list-frame__skeleton--title"
          />
          <skeleton-loader
            :border-radius="$tokens.size_s"
            height="118px"
            class="adventures-list-frame__skeleton--card"
          />
          <skeleton-loader
            :border-radius="$tokens.size_s"
            height="118px"
            class="adventures-list-frame__skeleton--card"
          />
        </template>
        <template v-else-if="error">
          <ErrorState
            image-path="adventure"
            @click="fetchHistorySeasons"
          />
        </template>
        <template v-else>
          <AdventureSeasonLayout
            v-for="(season) in historySeasons.data"
            :id="season.id"
            :key="`${season.title}-${season.id}`"
            :title="season.title"
            :episodes="season.episodes"
            :season-id="season.id"
            :loading="historySeasons.loading"
            @select-episode="handleSelectEpisode"
          />
        </template>
      </ScrollContainer>
    </GFrame>
    <div
      class="adventures-list__overlay"
      @click="toggleComponentVisible"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import AdventureSeasonLayout from '@/components/Adventure/AdventureSeasonLayout'
import ErrorState from '@/components/ErrorState'
import GFrame from '@/components/GFrame'
import GButton from '@/components/GButton'
import ScrollContainer from '@/components/ScrollContainer'
import { isEmpty } from '@sas-te/frontend-utils/modules/arrays'

export default {
  name: 'AdventuresList',
  components: {
    GFrame,
    AdventureSeasonLayout,
    ErrorState,
    GButton,
    ScrollContainer,
  },
  props: {
    headerInfo: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      playedEpisodes: [],
    }
  },
  computed: {
    ...mapGetters([ 'historySeasons' ]),
    isMFE() {
      return window.eureka_init
    },
    loading() {
      return this.historySeasons.loading
    },
    error() {
      return this.historySeasons.error
    },
  },
  created() {
    if (isEmpty(this.historySeasons.data)) this.getHistorySeasons()
  },
  methods: {
    ...mapActions([
      'getHistorySeasons',
    ]),
    toggleComponentVisible() {
      this.$emit('toggle-component-visibility', { id: 'adventure' })
    },
    handleSelectEpisode({
      episodeId,
      episodeName,
      seasonId,
      newEpisode,
    }) {
      playSoundFX()
      const { name } = this.$route

      if (newEpisode && !this.playedEpisodes.includes(episodeId)) {
        this.playedEpisodes.push(episodeId)
        this.$emit('decrement-notifications')
      }

      this.toggleComponentVisible()
      this.$router.push(
        {
          name: 'storytelling',
          params: {
            ...this.$route.params,
            seasonId,
            episodeId,
            hasInitialSplashScreen: false,
            redirect: name,
            openAdventure: false,
          },
          query: { ...this.$route.query },
        }
      )
      this.$trackEvent({
        category: this.$track.category.history,
        action: this.$track.action.watchHistory,
        label: episodeName,
      })
    },
    fetchHistorySeasons() {
      playSoundFX()
      this.getHistorySeasons()
    },
    closeButton() {
      this.$trackEvent({
        category: this.$track.category.history,
        action: this.$track.action.exitHistory,
      })
      playSoundFX()
      this.toggleComponentVisible()
    },
  },
}
</script>

<style lang="scss" scoped>
.adventures-list__overlay {
  @include cover(fixed);
  @include transition($speed-normal);
  background: transparent;
  z-index: 889;
}

.adventures-list-frame {
  display: flex;
  flex-direction: column;
  position: relative;
  width: var(--list-frame-width);
  height: 100% !important;
  margin-top: 9%;
  border-radius: 0;
  padding: $size-m !important;
  background: $eureka-color-base;
  border: none;
  overflow: hidden !important;
  z-index: 890;

  @media screen and (max-width: 479px) {
    padding: $size-s !important;
  }

  &__header {
    display: flex;
    position: relative;
    margin-bottom: 2%;
    padding-bottom: 2%;
    align-items: center;
    border-bottom: 1px solid #EAD0BB;

    &__icon {
      width: 3.5rem;
      height: auto;
      margin-right: 2%;
    }

    &__text {
      width: 100%;
      margin-left: 2%;
    }

    &__title {
      font-size: $font-size-heading-2;
      font-weight: $font-weight-bold;
      color: #25193E;
    }

    &__subtitle {
      color: $eureka-color-ink;
      opacity: 0.7;
      font-size: $font-size-m;
    }

    &__close-button {
      color: $eureka-color-ink;
      opacity: 0.5;
    }
  }

  &__content {
    height: 100% !important;
    overflow: auto;
    padding-right: 1rem;
  }

  &__content .adventures-seasons-layout {
    overflow: hidden;

    &:not(:first-child) {
      margin-top: $size-l;

      @media screen and (max-width: 479px) {
        margin-top: $size-xs;
      }
    }
  }

  &__skeleton {
    &--card {
      margin: 2% 0;
      border-bottom: 1px solid #EAD0BB;
    }
  }
}

.--mfe-margin {
  margin-top: $portal-cross-menu-height;
}

.button {
  background: transparent;
  color: $eureka-color-ink;
  box-shadow: none;
  border: none;

  &:hover {
    box-shadow: none;
    background: rgba(0, 0 , 0, 0.1);
  }
}
</style>
