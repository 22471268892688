<template>
  <div
    :class="[
      'mission',
      {'--unreleased': isUnreleased}
    ]"
    :aria-disabled="isUnreleased"
    :tabindex="mission.order"
    @click="$emit('click', mission)"
  >
    <div
      :id="mission.order"
      ref="order"
      class="mission__order__badge"
    >
      <component :is="missionBadge" />
      <span
        v-if="!isUnreleased"

        :class="[
          {'--light':isFinished}
        ]"
      >
        {{ mission.order }}
      </span>
    </div>
    <div class="mission__info">
      <h4
        class="mission__info__title"
        :style="{ color }"
      >
        {{ mission.title }}
      </h4>
      <ResourceBar
        v-if="isResume"
        class="mission__info__progress"
        :value="mission.progress.current"
        :total="mission.progress.total"
        size="xsmall"
        variation="purple"
      />
      <img
        v-if="isFinished"
        class="mission__gems"
        :src="diamondsSrc"
      >
      <p
        v-if="isUnreleased"
        class="mission__release-date"
        :style="{ color }"
      >
        {{ formatReleaseDate }}
      </p>
    </div>
  </div>
</template>

<script>
import formatDate from '@/mixins/formatDate'
import ResourceBar from '@/components/ResourceBar'
import MissionBadgeStart from '@/components/MissionBadgeStart'
import MissionBadgeResume from '@/components/MissionBadgeResume'
import MissionBadgeFinished from '@/components/MissionBadgeFinished'
import MissionBadgeUnreleased from '@/components/MissionBadgeUnreleased'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

export default {
  name: 'MissionListItem',
  components: {
    ResourceBar,
  },
  mixins: [ formatDate ],
  props: {
    mission: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      missionStatusType: {
        START: 'start',
        RESUME: 'resume',
        FINISHED: 'finished',
        UNRELEASED: 'unreleased',
      },
    }
  },
  computed: {
    diamondsSrc() {
      if (this.mission.mission.maxGems !== null) {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require(`@/assets/diamonds/diamonds-${this.mission.mission.maxGems}.svg`)
      }

      return null
    },
    missionBadge() {
      const { mission, missionStatusType } = this
      switch (mission.status) {
        case missionStatusType.START:
          return MissionBadgeStart

        case missionStatusType.RESUME:
          return MissionBadgeResume

        case missionStatusType.FINISHED:
          return MissionBadgeFinished

        case missionStatusType.UNRELEASED:
          return MissionBadgeUnreleased

        default:
          return null
      }
    },
    formatReleaseDate() {
      return this.localizedDayAndMonth(this.mission.releaseDate)
    },
    isResume() {
      return this.mission.status === this.missionStatusType.RESUME
    },
    isFinished() {
      return this.mission.status === this.missionStatusType.FINISHED
    },
    isUnreleased() {
      return this.mission.status === this.missionStatusType.UNRELEASED
    },
  },
  created() {
    this.isMicrofrontend = getIsMicrofrontend()
  },
}
</script>

<style lang="scss" scoped>
.mission {
  @include flex-center-start;
  @include space-stack(230px);
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  color: $eureka-color-ink;
  z-index: 3;

  &.--unreleased {
    cursor: not-allowed;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    align-self: flex-end;
    text-align: right;

    .mission__info {
      align-items: flex-end;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:not(.--unreleased):hover {
    .mission__order__badge {
      transform: translateY(-5px);
      @include transition($speed-fast);
    }
  }

  &:focus {
    outline: none;
  }

  &__order__badge {
    @include flex-center;
    margin: 0 $size-s;
    @include transition($speed-fast);
    box-shadow: 0 0 0 #CBA976;

    img {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    }

    span {
      position: absolute;
      font-size: 2rem;
      font-weight: bold;
      top: 50%;
      transform: translateY(-56%);

      &.--light {
        color: $color-white;
        text-shadow: 0 1px 0 #000000;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__title {
      font-size: 1.25rem;
      font-weight: $font-weight-bold;
    }

    &__progress {
      margin-top: 1%;
      width: unset;
    }
  }

  &__release-date {
    font-weight: $font-weight-bold;
  }
}
</style>
