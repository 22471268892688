<template>
  <div class="retrospective-template">
    <header class="retrospective-template__header">
      <GButton
        class="retrospective-template__close-btn"
        variation="secondary"
        icon-right="x"
        :icon-stroke="6"
        @click="$emit('close')"
      >
        Fechar
      </GButton>
    </header>
    <main class="retrospective-template__main">
      <slot />
    </main>
    <footer
      v-if="isDisplayPagination"
      class="retrospective-template__footer"
    >
      <p
        v-if="currentPage === 1"
        class="retrospective-template__text --center --thin"
      >
        Clique na seta abaixo ou role a tela para continuar
      </p>
      <div class="retrospective-template__controls">
        <div
          class="retrospective-template__controls-content"
          role="button"
          @click="$emit('nextPage')"
        >
          <img
            :class="[
              'retrospective-template__image',
              { 'arrow-up': isLastPage },
            ]"
            :src="arrowDown"
            alt=""
          >
          <span
            class="retrospective-template__text"
          >
            {{ paginationText }}
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import arrowDown from '@/assets/retrospective/arrow-down.svg'
import GButton from '@/components/GButton'

export default {
  name: 'RetrospectiveTemplate',
  components: {
    GButton,
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    isDisplayPagination: Boolean,
  },
  data() {
    return {
      arrowDown,
      image: '',
    }
  },
  computed: {
    paginationText() {
      return this.currentPage === this.totalPages ? 'Voltar para o topo' : `${this.currentPage} / ${this.totalPages}`
    },
    isLastPage() {
      return this.currentPage === this.totalPages
    },
  },
}
</script>

<style lang="scss" scoped>
.retrospective-template {
    height: 100%;
    background-image: url('~@/assets/retrospective/background-retro-1.png');
    background-repeat: no-repeat;
    background-size: cover;

    &__header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 2%;
      z-index: $zindex-fixed;
    }

    &__close-btn {
      float: right;
      font-family: Rowdies;
      color: white;
    }

    &__main {
      height: 100%;
      overflow-y: hidden;
    }

    &__footer {
      width: 100%;
      position: fixed;
      bottom: 2%;
      left: 50%;
      transform: translateX(-50%);
      z-index: $zindex-fixed;

      @media screen and (max-width: 991px) {
        padding: 0 12.5%;
      }

      @media screen and (max-width: 579px) {
        padding: 0 5%;
      }

      @media screen and (max-height: 789px) {
        bottom: 3px;
      }

      @include mq_xl__mf {
        padding: 0 30%;
      }
    }

    &__controls {
      @include flex-center;
      margin-top: $size-l;

      @media screen and (max-height: 789px) {
        margin-top: $size-xs;
      }
    }

    &__controls-content {
      cursor: pointer;
      padding: 4px 12px;
    }

    &__image {
      margin: 0 $size-xs;

      &.arrow-up {
        transform: rotate(180deg);
      }
    }

    &__text {
      color: $color-white;
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: $font-size-heading-4;
      }

      &.--center {
        text-align: center;
      }

      &.--thin {
        font-weight: $font-weight-light;
      }
    }
}
</style>
