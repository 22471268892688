<template>
  <div class="content-loading">
    <div class="content-loading__list-cards">
      <div class="content-loading__list-cards__title">
        <skeleton-loader
          opacity="0.5"
          width="100px"
          height="16px"
          border-radius="8px"
        />
      </div>
      <div class="content-loading__list-cards__card">
        <div class="content-loading__list-cards__card__header">
          <div class="content-loading__list-cards__card__header__options">
            <strong class="content-loading__list-cards__card__header__options__order">
              <skeleton-loader class="loader" />
            </strong>
          </div>
        </div>
        <div class="content-loading__list-cards__card__body">
          <skeleton-loader class="text-loader" />
          <skeleton-loader class="text-loader" />
          <skeleton-loader class="text-loader" />
        </div>
      </div>
      <div class="content-loading__list-cards__card">
        <div class="content-loading__list-cards__card__header">
          <div class="content-loading__list-cards__card__header__options">
            <strong class="content-loading__list-cards__card__header__options__order">
              <skeleton-loader class="loader" />
            </strong>
          </div>
        </div>
        <div class="content-loading__list-cards__card__body">
          <skeleton-loader class="text-loader" />
          <skeleton-loader class="text-loader" />
          <skeleton-loader class="text-loader" />
        </div>
      </div>
    </div>
    <div class="content-loading__enigma">
      <AncestralArenaEnigma is-loading />
    </div>
  </div>
</template>

<script>
import AncestralArenaEnigma from '@/components/AncestralArena/AncestralArenaEnigma'

export default {
  name: 'ContentLoading',
  components: {
    AncestralArenaEnigma,
  },
}
</script>

<style lang="scss" scoped>
.content-loading {
  display: flex;

  &__list-cards {
    min-width: 235px;
    height: 100vh;
    overflow-y: auto;
    background: rgba($eureka-color-ink, 0.5);

    @media screen and (max-width: 768px) {
      display: none;
    }

    &__title {
      margin: $size-m;
    }

    &__card {
      width: 194px;
      min-height: 104px;
      background-color: $eureka-color-base-lighter;
      margin: $size-m;
      box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
      border-radius: 16px;

      &__header {
        display: flex;
        justify-content: space-between;
        position: relative;
        border-radius: 12px 12px 0 0;
        background-color: $eureka-color-base;
        width: 100%;
        height: 23px;

        &__options {
          &__order{
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 39px;
            height: 100%;
            background-color: #473F78;
            text-align: center;
            border-radius: $border-radius-l 0 16px;
          }
        }
      }

      &__body {
         height: calc(104px - 23px);
      }
    }
  }

  &__enigma {
    width: 85%;
    padding: 16px;

    @media screen and (max-width: 768px) {
      width: 100%;

      ::v-deep .ancestral-arena-enigma__content__statement__is-loading {
        display: none;
      }

      ::v-deep .ancestral-arena-enigma__content__alternatives {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.loader {
  opacity: 0.5 !important;
  width: 24px !important;
  border-radius: $border-radius-m  !important;
}

.text-loader {
  opacity: 0.5 !important;
  width: 163px !important;
  height: 12px !important;
  border-radius: $border-radius-m  !important;
  margin: $size-xs $size-s;

  &:last-child {
    width: 96px !important;
  }
}

::v-deep .ancestral-arena-enigma {

  &__header {
    background-color: $eureka-color-base;

    &__title {
      border-right: 1px solid rgba($eureka-color-ink, 0.2);
      padding-right: $size-s;
      font-size: $font-size-m;
    }
  }

  &__content {
    padding: $size-l;
  }
}

</style>
