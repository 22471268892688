<template>
  <div>
    <MagnaMissionReportLayout
      :is-loading="report.loading"
      :footer-button-text="buttonText"
      :button-image-name="buttonImage"
      @click="handleAction"
    >
      <div class="magna-teacher-report">
        <section
          v-if="hasMessage"
          class="magna-teacher-report__message"
        >
          <h1 class="magna-teacher-report__message__title">
            {{ messageTitle }}
          </h1>
          <p class="magna-teacher-report__message__description">
            {{ messageDescription }}
          </p>
        </section>
        <div
          v-else
          class="magna-teacher-report__content"
        >
          <HitRate
            :standard="false"
            title="Acertos da Turma"
            :hit-rate="hitRate"
            :total="totalQuestions"
          >
            <Info
              :icon="{ name: 'user', color: '#2D1F49' }"
              size="medium"
              padding="16px 0 0 0"
            >
              {{ `${loggedMessage} Jogadores` }}
            </Info>
          </HitRate>
        </div>
        <img
          class="magna-teacher-report__banner"
          :src="coraImage"
          alt=""
        >
      </div>
    </MagnaMissionReportLayout>
    <InfoMagnaMissionModal
      v-if="isModalVisible"
      title="Já parou de compartilhar a tela?"
      description="Confirme se está compartilhando a tela com os alunos
      antes de visualizar o relatório completo."
      button-name="Ver relatório"
      has-cancel-button
      @confirm="redirectToAssignmentReport"
      @close="toggleMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import coraThinking from '@/assets/char/cora/cora-thinking.svg'
import coraDelighted from '@/assets/char/cora/cora-delighted.svg'
import socketClient from '@/client/socketClient'
import redirectTo from '@/utils/redirectTo'
import arenaApi from '@/service/arena'
import MagnaMissionReportLayout from '@/views/AncestralArena/layouts/MagnaMissionReportLayout'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import HitRate from '@/components/AncestralArena/HitRate'
import { getAssignmentReportURL } from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

const isMicrofrontend = getIsMicrofrontend()

export default {
  name: 'MagnaMissionTeacherReport',
  components: {
    HitRate,
    MagnaMissionReportLayout,
    InfoMagnaMissionModal,
  },
  data() {
    return {
      report: {
        loading: null,
        error: null,
        data: {},
      },
      isModalVisible: false,
    }
  },
  computed: {
    hasMessage() {
      return this.report.error || this.loggedStudents === 0
    },
    messageTitle() {
      return this.report.error ? 'Não foi possível carregar o relatório' : 'Nenhum aluno participou da missão'
    },
    messageDescription() {
      return this.report.error
        ? 'Tente recarregar essa página ou acesse o relatório pela listagem de missões.'
        : 'Para que um relatório seja gerado, pelo menos um aluno precisa entrar na partida.'
    },
    buttonImage() {
      return this.report.error ? 'refresh.svg' : ''
    },
    buttonText() {
      if (this.report.error) return 'Recarregar'

      return this.loggedStudents === 0 ? 'Fechar missão' : 'Relatório Completo'
    },
    coraImage() {
      return this.report.error || this.loggedStudents === 0 ? coraThinking : coraDelighted
    },
    loggedStudents() {
      const result = this.report.data.students?.filter(({ status }) => status === 'finished')

      return result?.length || 0
    },
    maxStudents() {
      return this.report.data.students?.length || 0
    },
    hitRate() {
      return Math.round(this.report.data.hitRate)
    },
    totalQuestions() {
      return this.report.data.questions?.length || 0
    },
    loggedMessage() {
      return `${this.loggedStudents} / ${this.maxStudents}`
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    classroomId() {
      return this.$route.params.classroomId
    },
  },
  created() {
    this.fetchReport()
  },
  beforeDestroy() {
    socketClient.disconnect()
  },
  methods: {
    ...mapActions([ 'setMagnaMission' ]),
    handleAction() {
      if (this.report.error) this.fetchReport()
      else if (this.loggedStudents === 0) this.$router.replace({ name: 'arena' })
      else this.toggleMagnaMissionModal()
    },
    async fetchReport() {
      this.report.error = null
      this.report.loading = true
      this.setMagnaMission({ loading: true, error: null })

      try {
        const { data } = await arenaApi.getStatistics(this.questionnaireCode, this.classroomId)
        this.report.data = data
        this.setMagnaMission({
          data: {
            title: this.report.data.questionnaire.title,
            hit: this.hitRate,
          },
          loading: false,
        })
      } catch (error) {
        this.report.error = error
        this.setMagnaMission({ error, loading: false })
      } finally {
        this.report.loading = false
      }
    },
    toggleMagnaMissionModal() {
      this.isModalVisible = !this.isModalVisible
    },
    redirectToAssignmentReport() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.seeFullReport,
      })

      const baseUrl = getAssignmentReportURL(isMicrofrontend)

      redirectTo(`${baseUrl}/questionnaires/${this.questionnaireCode}/classes/${this.classroomId}/general`)
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-teacher-report {
  width: 100%;

  &__banner {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  &__message {
    width: 40%;
    margin-left: 40px;

    &__title {
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: 2.5rem;
      }
      line-height: $line-height-heading;
      color: $eureka-color-ink;
    }

    &__description {
      margin-top: $size-m;
      font: {
        family: Lato;
        size: 1.25rem;
        weight: $font-weight-medium;
      }
      line-height: $line-height-text;
      color: $eureka-color-ink;
      letter-spacing: 0.0275rem;
    }
  }

  @media screen and (max-width: 991px) {
    .magna-teacher-report__banner {
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      height: 45%;
    }

    .magna-teacher-report__message {
      width: 80%;
      margin: 0 auto;
      text-align: center;

      &__title {
        margin-top: 72px;
        font-size: $font-size-heading-4;
        line-height: $line-height-reset;
      }

      &__description {
        margin-top: $size-s;
        font-size: $font-size-m;
      }
    }

    ::v-deep .hit-rate {

      &__body {
        flex-direction: column-reverse;
        padding: 2rem 1.5rem;

        .radial-progress-container {
          margin-top: 1rem;
        }
      }

      &__chart {
        margin-right: 0;
      }

      &__text {
        text-align: center;

        &__title {
          font: {
            size: 1.5rem;
            weight: $font-weight-regular;
          }
        }

        &__count {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .magna-report {
    height: auto;
    width: 100%;
  }

  ::v-deep .g-frame__inner {
    display: block !important;
  }

  ::v-deep .magna-report__content {
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: $size-s;
    }

    &__header {
      margin-top: 2rem;
      text-align: center;

      &__title {
        margin: 0;
      }
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 32px;

      .g-button:last-child {
        min-width: 150px;
        margin: 0 1rem 1rem 0;
      }

      .g-button__text {
        font: {
          family: Rowdies;
          size: 1rem;
          weight: $font-weight-regular;
        }
      }
    }
  }
}
</style>
