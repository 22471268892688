import { render, staticRenderFns } from "./AncestralArenaEnigma.vue?vue&type=template&id=00623ae7&scoped=true"
import script from "./AncestralArenaEnigma.vue?vue&type=script&lang=js"
export * from "./AncestralArenaEnigma.vue?vue&type=script&lang=js"
import style0 from "./AncestralArenaEnigma.vue?vue&type=style&index=0&id=00623ae7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00623ae7",
  null
  
)

export default component.exports