var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission-content-options"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,class:[
      'mission-content-options__option',
      {
        '--hoverable': _vm.correctOptionIndex === -1,
        '--right': index === _vm.correctOptionIndex,
        '--wrong': (
          index === _vm.selectedOptionIndex
          && _vm.correctOptionIndex !== -1
          && index !== _vm.correctOptionIndex
        ),
        '--disabled': _vm.isDisabledOption(index),
        '--active': (
          index === _vm.selectedOptionIndex && _vm.correctOptionIndex === -1
        ),
      },
    ],on:{"click":function($event){return _vm.onClick(option, index)}}},[(_vm.isDisabledOption(index))?_c('div',{staticClass:"mission-content-options__option-risked"}):_vm._e(),_c('RichTextViewer',{attrs:{"text":option.description}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }