<template>
  <div class="review-video">
    <BagSection title="Revise a missão">
      <div class="review-video__frame">
        <div class="review-video__video">
          <div
            v-if="video.loading"
            class="review-video__video__loader"
          >
            <spinner data-testid="spinner" />
          </div>
          <div
            v-else
            v-html="video.data.embedHtml"
          />
        </div>
      </div>
    </BagSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import trackPlayerEvent from '@/mixins/trackPlayerEvent'

import BagSection from '../BagSection/BagSection'

export default {
  name: 'ReviewVideo',
  components: {
    BagSection,
  },
  mixins: [ trackPlayerEvent ],
  computed: {
    ...mapGetters([
      'video',
      'user',
    ]),
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
  },
  watch: {
    video: {
      immediate: true,
      handler() {
        if (this.video.data) {
          const chapter = parseInt(this.$route.params.chapterId, 10)

          this.setupPlayerEvents({ chapter })
        }
      },
    },
  },
  created() {
    if (!this.video.success) {
      this.getVideo(this.questionnaireCode)
        .catch((error) => {
          console.error(error)
        })
    }
  },
  methods: {
    ...mapActions([
      'getVideo',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.review-video {
  &__frame {
    background: $eureka-color-base-light;
    border: $size-xs solid rgba($color-white, 0.4);
    box-shadow: 0 $size-xxs 0 #D9AF54;
    border-radius: 12px;
  }

  &__video {
    @include proportional-video;
    height: 0;
    background: transparentize($game-ink, 0.85);
    border-radius: 10px;

    ::v-deep iframe {
      @include proportional-video-iframe;
    }

    &__loader {
      color: transparentize($game-ink, 0.55);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
