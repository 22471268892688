<template>
  <section :class="$style.container">
    <ScrollContainer :class="$style.containerContent">
      <header :class="$style.header">
        <div :class="$style.badge">
          <skeleton-loader
            opacity="0.5"
            width="50px"
            height="25px"
            border-radius="8px"
          />
        </div>
        <div :class="$style.badge">
          <skeleton-loader
            opacity="0.5"
            width="50px"
            height="25px"
            border-radius="8px"
          />
        </div>
      </header>
      <div :class="$style.separator" />
      <div :class="$style.content">
        <skeleton-loader
          opacity="0.5"
          width="650px"
          height="250px"
          border-radius="8px"
        />
      </div>
      <div :class="$style.optionsContainer">
        <div :class="$style.ancestralArenaCardQuestion">
          <div :class="$style.ancestralArenaCardQuestion__info">
            <skeleton-loader
              :class="$style.ancestralArenaCardQuestion__info__letter"
              opacity="0.5"
              width="65px"
              height="65px"
              border-radius="8px 0px 0px 8px"
            />
            <div :class="$style.ancestralArenaCardQuestion__info__description__container">
              <skeleton-loader
                :class="$style.ancestralArenaCardQuestion__info__description__skeleton"
                opacity="0.5"
                width="450px"
                border-radius="8px"
              />
            </div>
          </div>
        </div>
        <div :class="$style.ancestralArenaCardQuestion">
          <div :class="$style.ancestralArenaCardQuestion__info">
            <skeleton-loader
              :class="$style.ancestralArenaCardQuestion__info__letter"
              opacity="0.5"
              width="65px"
              height="65px"
              border-radius="8px 0px 0px 8px"
            />
            <div :class="$style.ancestralArenaCardQuestion__info__description__container">
              <skeleton-loader
                :class="$style.ancestralArenaCardQuestion__info__description__skeleton"
                opacity="0.5"
                width="450px"
                border-radius="8px"
              />
            </div>
          </div>
        </div>
        <div :class="$style.ancestralArenaCardQuestion">
          <div :class="$style.ancestralArenaCardQuestion__info">
            <skeleton-loader
              :class="$style.ancestralArenaCardQuestion__info__letter"
              opacity="0.5"
              width="65px"
              height="65px"
              border-radius="8px 0px 0px 8px"
            />
            <div :class="$style.ancestralArenaCardQuestion__info__description__container">
              <skeleton-loader
                :class="$style.ancestralArenaCardQuestion__info__description__skeleton"
                opacity="0.5"
                width="450px"
                border-radius="8px"
              />
            </div>
          </div>
        </div>
        <div :class="$style.ancestralArenaCardQuestion">
          <div :class="$style.ancestralArenaCardQuestion__info">
            <skeleton-loader
              :class="$style.ancestralArenaCardQuestion__info__letter"
              opacity="0.5"
              width="65px"
              height="65px"
              border-radius="8px 0px 0px 8px"
            />
            <div :class="$style.ancestralArenaCardQuestion__info__description__container">
              <skeleton-loader
                :class="$style.ancestralArenaCardQuestion__info__description__skeleton"
                opacity="0.5"
                width="450px"
                border-radius="8px"
              />
            </div>
          </div>
        </div>
      </div>
    </ScrollContainer>
    <footer :class="$style.footer">
      <skeleton-loader
        opacity="0.8"
        width="36px"
        height="36px"
        border-radius="99px"
      />
      <skeleton-loader
        opacity="0.8"
        width="156px"
        height="36px"
        border-radius="99px"
      />
      <skeleton-loader
        opacity="0.8"
        width="36px"
        height="36px"
        border-radius="99px"
      />
    </footer>
  </section>
</template>

<script>
import ScrollContainer from '@/components/ScrollContainer'

export default {
  name: 'MagnaMissionContentPreview',
  components: {
    ScrollContainer,
  },
}
</script>
<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  border-radius: 16px;
  background: $eureka-color-base;
  box-shadow: inset 0px 1px 0px rgba($color-white, 0.75);
  filter: drop-shadow(0px 3px 0px #c2ab8c);
  height: 83vh;
  width: 100%;
  padding-right: 0.5rem;
}

.containerContent {
  padding: $size-s;
  height: 100%;
  background: $eureka-color-base;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  --trackColor: #EFDABE !important;
  --scrollbar-width: .75rem !important;

  &::-webkit-scrollbar-thumb {
    background: $eureka-color-base-darker !important;
    border: 8px solid #E4CCAA;
    box-shadow: inset -1px -4px 0px #CCA56D;
  }
  &::-webkit-scrollbar {
    padding: 0.5rem;
  }
}

.header {
  display: flex;
  flex-direction: row;
  gap: $size-xs;
  font-family: Rowdies;
  font-weight: $font-weight-regular;
  font-size: $font-size-s;
  text-align: center;
  color: #161616;
  background-color: $color-white;
  box-shadow: 0px 1px 0px $color-ink-lightest;
  border-radius: 16px 16px 0 0;
  padding: 8px;
}

.badge {
  padding: $size-xs 12px;
  background: $eureka_color_ice;
  border: 1px solid $eureka_color_ink_lightest;
  border-radius: $size-l;
  line-height: $line-height-reset;
}

.separator {
  border-bottom: 1px solid $color-ink-lightest;
}

.content {
  padding: $size-s $size-m;
  font-family: Rowdies;
  font-weight: $font-weight-light;
  font-size: $font-size-m;
  color: $eureka-color-ink;
  background-color: $color-white;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  max-width: 100%;
  scrollbar-color: $eureka-color-base-light rgba(#0d0425, 0.32);

  table {
    max-width: 100%;
  }

  img {
    max-width: 100%;
  }
}

.optionsContainer {
  background-color: $color-white;
  gap: $size-s;
  padding: $size-s;
  display: flex;
  flex-direction: column;
}

.options {
  cursor: auto !important;
}

.footer {
  padding: $size-s $size-m $size-xs;
  gap: $size-xl;
  display: flex;
  justify-content: center;
  border-top: 1px solid #efdabe;
}

.ancestralArenaCardQuestion {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $eureka-color-ink;
  font-family: 'Rowdies';
  background-color: $eureka-color-base-light;
  box-shadow: 0 3px 0 #C2AB8C, 0 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-m;
  max-height: 100%;

  &__info {
    width: 100%;
    display: flex;
    align-items: center;

    &__description {
      padding: $size-s;

      &__container {
        max-width: 100%;
      }

      &__skeleton {
        margin: $size-xs;
      }
    }
  }
}
</style>
