<template>
  <div class="mission-report-error">
    <div class="mission-report-error__body">
      <img
        class="mission-report-error__illustration"
        :src="require(`@/assets/error/mission/illustration.svg`)"
      >
      <h2 class="mission-report-error__message">
        {{ $t('game.error.report.message') }}
      </h2>
      <p class="mission-report-error__observation">
        {{ $t('game.error.report.observation') }}
      </p>
      <g-button
        class="mission-report-error__action"
        size="large"
        @click="action"
      >
        {{ $t('game.error.report.action') }}
      </g-button>
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'MissionReportError',
  components: {
    GButton,
  },
  methods: {
    action() {
      window.location.reload()
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-report-error
  margin: 50px auto 0
  width: $mission-max-width
  background: #FBF1E1
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3)
  border-radius: 24px 24px 0 0
  padding: 10px 10px 0
  position: relative

  &__body
    position: relative
    background: $color-white
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25)
    border-radius: 16px 16px 0 0
    padding: 60px 88px 40vh
    min-height: calc(100vh - 150px)
    +flex-column-center

  &__illustration
      margin-bottom: 16px

  &__message
    font-weight: bold
    font-size: 24px
    max-width: 480px
    text-align: center

  &__observation
    margin-top: 16px
    opacity: 0.75

  &__action
    margin-top: 32px
</style>
