<template>
  <button
    :class="['button-control', `--${shape}`, `--${theme}`]"
    @click="$emit('click')"
  >
    <img
      :src="icon"
      data-testid="button-control-icon"
      alt=""
    >
  </button>
</template>

<script>
export default {
  name: 'ButtonControl',
  props: {
    icon: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
      validator(theme) {
        return [ 'light', 'dark' ].includes(theme)
      },
    },
    shape: {
      type: String,
      default: 'square',
      validator(shape) {
        return [ 'square', 'rectangle' ].includes(shape)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.button-control {
  @include flex-center;
  cursor: pointer;
  padding: $size-xxs;
  background-color: #FBF1E1;
  box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border: 0;
  border-radius: 6px;
  transition: background-color .3s;

  &.--square {
    width: $size-xl;
    height: $size-xl;
  }

  &.--rectangle {
    width: 80px;
    height: 48px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    img {
      width: 20px;
      height: auto;
    }
  }

  &:hover {
    background-color: $eureka-color-base-dark;
  }

  &.--dark {
    background-color: #1D0946;
    box-shadow: 0 3px 0 #151142;
  }
}
</style>
