const originEnum = {
  TEACHER: 'TEACHER',
  SAS: 'SAS',
}

export function questionHasRequiredAlternatives(question) {
  const NUMBER_OF_REQUIRED_ALTERNATIVES = 2
  const alternativesValues = Object.entries(question.alternatives)
    .map(([ letter, { value }]) => ({ letter, value }))

  return alternativesValues
    .filter((alternative) => Boolean(alternative.value.trim()))
    .length >= NUMBER_OF_REQUIRED_ALTERNATIVES
}

export function questionHasValidStatement(question) {
  return Boolean(question.text.trim())
}

export function questionHasSelectedAlternative(question) {
  return Boolean(question.selectedAlternative)
}

export function questionHasDuplicatedStatement(question, listOfQuestions) {
  return Boolean(listOfQuestions
    .find(({ text, order }) => text === question.text && order !== question.order))
}

export default function checkIfValidQuestion(question, listOfQuestions) {
  if (question.origin === originEnum.SAS) {
    return {
      hasValidStatement: true,
      hasRequiredAlternatives: true,
      hasSelectedAlternative: true,
      questionIsValid: true,
    }
  }

  return {
    hasValidStatement: questionHasValidStatement(question),
    hasRequiredAlternatives: questionHasRequiredAlternatives(question),
    hasSelectedAlternative: questionHasSelectedAlternative(question),
    questionIsValid: questionHasValidStatement(question)
    && questionHasRequiredAlternatives(question)
    && questionHasSelectedAlternative(question)
    && !questionHasDuplicatedStatement(question, listOfQuestions),
  }
}
