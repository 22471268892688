<template>
  <div
    :class="[
      'g-block',
      `--${size}`,
      `--${theme}`,
      `--${variation}`,
      {
        '--clickable': clickable,
        '--has-image': hasImage
      },
    ]"
    :style="{
      fontSize,
    }"
  >
    <img
      v-if="asset"
      :class="[
        'g-block__image',
        `--${size}`,
      ]"
      :src="require(`@/assets/${asset}`)"
    >
    <img
      v-else-if="image"
      :class="[
        'g-block__image',
        `--${size}`,
      ]"
      :src="image"
    >
    <div class="g-block__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GBlock',
  props: {
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    asset: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
      required: false,
      validator: (value) => (
        value.match(/(default|bright|success-inverted|ocean-blue|transparent)/)
      ),
    },
    variation: {
      type: String,
      default: 'round',
      required: false,
      validator: (value) => (
        value.match(/(round|square)/)
      ),
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => (
        value.match(/(small|medium|large)/)
      ),
    },
    fontSize: {
      type: String,
      default: '16px',
    },
  },
  computed: {
    hasImage() {
      return Boolean(this.asset) || Boolean(this.image)
    },
  },
}
</script>

<style lang="scss" scoped>
.g-block {
  font-weight: bold;
  width: fit-content;
  @include transition($speed-fast);
  @include flex-center-start;

  &.--clickable:hover {
    transform: translateY(-4px);
    cursor: pointer;
  }

  &.--has-image {
    margin-left: $size-s;
  }

  &.--small {
    height: 24px;
  }

  &.--medium {
    height: 28px;
  }

  &.--large {
    height: 36px;
  }

  &__image {
    display: block;
    position: relative;
    margin-left: -16px;
    height: 34px;
    width: 34px;

    .--small & {
      height: 30px;
      width: 30px;
    }

    .--large & {
      height: 44px;
      width: 44px;
    }
  }

  &__text {
    padding: $size-xs 12px $size-xs $size-xs;

    .--small & {
      padding-left: $size-xxs;
    }

    .--large & {
      padding-left: 12px;
    }
  }

  &.--round {
    border-radius: 50px;
  }

  &.--square {
    border-radius: $border-radius-m;
  }

  &.--default {
    background: #FBF1E1;
    box-shadow: 0px 3px 0px #CBA976, inset 0px 1px 0px fade-out($color-white, 0.15);
    color: #4B3728;

    &.--small {
      box-shadow: 0px 2px 0px #CBA976, inset 0px 1px 0px fade-out($color-white, 0.15);
    }
  }

  &.--bright {
    background: $color-white;
    box-shadow: 0px 3px 0px #EDB684;

    &.--small {
      box-shadow: 0px 2px 0px #EDB684;
    }
  }

  &.--success-inverted {
    background: $color-white;
    box-shadow: 0px 3px 0px #66B69C;
    color: #0D6857;

    &.--small {
      box-shadow: 0px 2px 0px #66B69C;
    }
  }

  &.--ocean-blue {
    background: $eureka-color-ink;
    text-shadow: 0px 1px 0px #000000;
    color: $color-white;
  }

  &.--transparent {
    background: transparent;
    text-shadow: 0px 1px 0px #000000;
    color: $color-white;
  }
}
</style>
