import SoundController from '@/service/soundController/SoundController'
import store from '../store/userPreferences'
import {
  effectsNames,
  backgroundNames,
  loadBackgrounds,
  loadExternalBackground
} from '../service/soundController/loadSounds'

function playSound() {
  const soundController = SoundController.getInstance()

  const playSoundFX = (sound = effectsNames.CLICK) => {
    const soundsIsAllowed = store.state.soundPreferences.data.sound

    if (soundsIsAllowed) {
      return soundController.playSoundFX(sound)
    }

    return null
  }

  const setDisabledSound = (value) => (
    soundController.setDisabledSound(value)
  )
  const getBackground = () => (
    soundController.getBackground()
  )

  const playBackground = (soundInstance = backgroundNames.MAIN_BACKGROUND) => {
    const musicIsAllowed = store.state.soundPreferences.data.music

    if (musicIsAllowed) {
      if (soundInstance in loadBackgrounds) {
        return (
          soundController.playBackground(loadBackgrounds[soundInstance])
        )
      }

      const customBG = loadExternalBackground(soundInstance)

      return soundController.playBackground(customBG)
    }

    return null
  }
  const pauseBackground = () => (
    soundController.pauseBackground()
  )

  const resumeBackground = () => {
    const musicIsAllowed = store.state.soundPreferences.data.music
    if (musicIsAllowed) {
      return soundController.resumeBackground()
    }

    return null
  }

  const setDisabledBackground = (value) => (
    soundController.setDisabledBackground(value)
  )

  return {
    soundController,
    getBackground,
    playBackground,
    resumeBackground,
    pauseBackground,
    playSoundFX,
    setDisabledBackground,
    setDisabledSound,
  }
}

export const {
  soundController,
  getBackground,
  playBackground,
  resumeBackground,
  pauseBackground,
  playSoundFX,
  setDisabledBackground,
  setDisabledSound,
} = playSound()
