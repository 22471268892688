<template>
  <div class="ancestral-arena-student">
    <g-alert
      v-if="error"
      :message="error.message"
      :icon="error.icon"
      :is-closeable="error.isCloseable"
      @close="error = null"
    />
    <GHeaderGame class="ancestral-arena-student__header">
      <template #menu>
        <MagnaMissionSatisfactionSurvey v-if="canReplySurvey" />
        <div class="ancestral-arena-student__menu">
          <GButton
            v-if="!isMagnaMissionReport"
            class="ancestral-arena-student__menu__items"
            variation="secondary"
            icon-left="x"
            :icon-stroke="3"
            @click="showLeaveMagnaMissionModal"
          >
            {{ closeButtonText }}
          </GButton>
        </div>
      </template>
    </GHeaderGame>
    <div
      v-show="timerInfo.hasTimer && !isMagnaMissionReport"
      class="ancestral-arena-student__subheader"
    >
      <ArenaTimer
        :status="timerInfo.timerStatus"
        :is-show="timerInfo.hasTimer && !isMagnaMissionReport"
        :timer="timerInfo.currentTime"
        :is-horizontal-timer="true"
        class="ancestral-arena-student__timer"
      />
    </div>
    <router-view
      @enigma-position="setEnigmaPosition"
      @error-ocurred="setError"
      @error-resolved="error = null"
      @update-time="setTimerInfo"
    />
    <div
      v-if="error"
      class="overlay-error"
    />
    <DangerMagnaMissionModal
      v-if="isModalVisible"
      class="magna-mission-modal"
      title="Deseja sair da missão?"
      description="Você consegue voltar enquanto a missão estiver ativa,
      mas talvez perca a oportunidade de responder algumas questões enquanto estiver fora."
      :button-name="$t('commons.leave')"
      @confirm="leaveTheMatch"
      @close="closeMagnaMissionModal"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'

import { pauseBackground } from '@/utils/soundEffects'
import mediaQueries from '@/mixins/mediaQueries'
import socketClient from '@/client/socketClient'
import MagnaMissionSatisfactionSurvey from '@/components/MagnaMissionSatisfactionSurvey'
import GHeaderGame from '@/components/AncestralArena/GHeaderGame'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import ArenaTimer from '@/components/AncestralArena/ArenaTimer/ArenaTimer'

export default {
  name: 'AncestralArenaStudentLayout',
  components: {
    GHeaderGame,
    GButton,
    GAlert,
    DangerMagnaMissionModal,
    MagnaMissionSatisfactionSurvey,
    ArenaTimer,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      isModalVisible: false,
      error: null,
      enigmaPosition: 0,
      timerInfo: {
        currentTime: '00:00',
        hasTimer: false,
        timerStatus: null,
      },
    }
  },
  computed: {
    ...mapState({
      magnaMission: (state) => state.arena.magnaMission,
    }),
    closeButtonText() {
      return this.mq_xl__mf ? 'Sair da partida' : 'Sair'
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    canReplySurvey() {
      return !this.magnaMission.loading && this.isMagnaMissionReport
    },
    isMagnaMissionReport() {
      return this.$route.name === 'magna-mission-student-report'
    },
  },
  mounted() {
    pauseBackground()
  },
  methods: {
    setError(error) {
      this.error = error
    },
    leaveTheMatch() {
      this.unsubscribeStudent()
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.leaveMatchConfirms,
        label: this.$track.label.questionNumber,
        value: this.enigmaPosition,
      })
    },
    unsubscribeStudent() {
      const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/unsubscribe`

      try {
        socketClient.emit(destination)
        setTimeout(() => {
          socketClient.disconnect()
        }, 1000)
      } finally {
        this.$router.back()
      }
    },
    showLeaveMagnaMissionModal() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.leaveMatch,
        label: this.$track.label.questionNumber,
        value: this.enigmaPosition,
      })
      this.isModalVisible = true
    },
    closeMagnaMissionModal() {
      this.isModalVisible = false
    },
    setEnigmaPosition(position) {
      this.enigmaPosition = position
    },
    setTimerInfo(timeInfo) {
      this.timerInfo = timeInfo
    },
  },
}
</script>
<style lang="scss" scoped>
.overlay-error {
  @include cover;
  z-index: 9998;
  background-color: #393939;
  mix-blend-mode: color;
}

.ancestral-arena-student {
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: $zindex-modal;
  background: {
    image: url('~@/assets/ancestral-arena/background.png');
    position: bottom;
    size: cover;
    attachment: fixed;
    color: $eureka-color-ink;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 80px;

    ::v-deep .g-header__game__title {
      font-size: 1.6rem;
    }
  }

  &__header {
    padding: $size-m $size-l $size-s;
  }

  &__timer {
    max-width: 50%;
    min-width: 550px;
    margin-bottom: $size-s;
  }

  &__menu {
    padding: 0 15px;

    &__items {
      border-radius: $border-radius-xs;
      cursor: pointer;
      display: block;
      padding: 25px 20px;
      position: relative;
      @include flex-center;
      @include transition($speed-x-fast);

      ::v-deep &.g-button {
        color: $game-background-color;
      }

      &:hover {
        background: rgba($color-black, 0.2);
      }

      &:active {
        background: rgba($color-black, 0.2);
      }
    }
  }

  &__content {
    @include flex-column-center;
    height: 80vh;
  }

  &__text {
    font-family: Rowdies;
    font-size: $font-size-heading-2;
    color: $color-white;
    text-align: center;
  }
}

.magna-mission-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(12, 11, 31, 0.75);
  z-index: $zindex-modal-backdrop;
  @include cover;
  @include flex-center;
}

.g-alert {
  z-index: $zindex-modal;
  position: fixed;
  top: 0;
  border-radius: 0;
  justify-content: center;
  border-bottom: 6px solid #D97979;

  ::v-deep i {
    color: $color-danger-dark;
  }
}
::v-deep .g-button {
  &:disabled {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    color:  rgb(255, 255, 255, 0.5) !important;
    cursor: not-allowed;
  }
}
</style>
