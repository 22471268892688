<template>
  <div
    class="eureka-on-boarding"
    data-testid="onboarding"
  >
    <div class="eureka-on-boarding__overlay" />
    <div class="eureka-on-boarding__content">
      <button
        class="skip"
        data-testid="onboarding-skip"
        @click="close"
      >
        <div class="skip__icon" />
        <transition name="shrink-text">
          <p
            v-if="activeSlide === 1"
            class="skip__text"
          >
            {{ $t('commons.skipIntro') }}
          </p>
        </transition>
      </button>
      <transition-group name="fade">
        <div
          v-if="activeSlide === 1"
          key="1"
          class="slide slide-1"
        >
          <div class="slide__text">
            <div class="slide-1__text__hello">
              {{ $t('game.eurekaOnBoarding.hello', {
                student: studentName
              }) }}
            </div>
            <div class="slide-1__myNameIs">
              {{ $t('game.eurekaOnBoarding.myNameIs') }}
              <div class="slide-1__myNameIs__charName">
                {{ $t('game.eurekaOnBoarding.tata') }}
              </div>
            </div>
            <div>
              {{ $t('game.eurekaOnBoarding.letsGo') }}
            </div>
          </div>
          <div class="slide__action__wrapper">
            <GButton
              class="slide__action"
              size="large"
              data-testid="onboarding-goto-slide-2"
              @click="activeSlide = 2"
            >
              {{ $t('game.eurekaOnBoarding.action.start') }}
            </GButton>
          </div>
        </div>
        <div
          v-if="activeSlide === 2"
          key="2"
          class="slide slide-2"
        >
          <div class="slide__text">
            {{ $t('game.eurekaOnBoarding.worlds') }}
          </div>
          <div class="slide__action__wrapper">
            <GButton
              class="slide__action"
              size="large"
              data-testid="onboarding-goto-slide-3"
              @click="activeSlide = 3"
            >
              {{ $t('game.eurekaOnBoarding.action.advance') }}
            </GButton>
          </div>
        </div>
        <div
          v-if="activeSlide === 3"
          key="3"
          class="slide slide-3"
        >
          <div class="slide__text">
            {{ $t('game.eurekaOnBoarding.rewards') }}
          </div>
          <div class="slide__action__wrapper">
            <GButton
              class="slide__action"
              size="large"
              data-testid="onboarding-goto-slide-4"
              @click="activeSlide = 4"
            >
              {{ $t('game.eurekaOnBoarding.action.advance') }}
            </GButton>
          </div>
        </div>
        <div
          v-if="activeSlide === 4"
          key="4"
          class="slide slide-4"
        >
          <div class="slide__text">
            {{ $t('game.eurekaOnBoarding.items') }}
          </div>
          <div class="slide__action__wrapper">
            <GButton
              class="slide__action"
              size="large"
              data-testid="onboarding-end"
              @click="close"
            >
              {{ $t('game.eurekaOnBoarding.action.finish') }}
            </GButton>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import GButton from '@/components/GButton'

export default {
  name: 'EurekaOnBoarding',
  components: {
    GButton,
  },
  data() {
    return {
      activeSlide: 1,
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    studentName() {
      return typeof this.user.data.name === 'string'
        ? this.user.data.name.split(' ')[0]
        : ''
    },
  },
  methods: {
    close() {
      this.$emit('eurekaOnBoardingDone')
    },
  },
}
</script>

<style lang="sass" scoped>
.eureka-on-boarding
  &__overlay
    z-index: 9998
    +cover(fixed)
    +transition($speed-slow)
    background: rgba($color-ink, .5)

  &__content
    z-index: 9999
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: 80%
    max-height: 640px
    width: 960px
    background: $color-white
    border-radius: 40px
    box-shadow: 0px 12px 36px rgba(0, 0, 0, 0.25)
    overflow: hidden

  .slide
    z-index: 1
    +cover(absolute)
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    display: flex

    &__text
      font-weight: bold
      font-size: $size-l
      line-height: 120%
      margin-top: 40px

    &__action
      width: 240px

      &__wrapper
        +flex-center
        width: 100%
        position: absolute
        left: 0
        right: 0
        bottom: 0
        z-index: 2
        padding: $size-l

  .slide-1
    background-image: url(~@/assets/onboarding/slide1.svg)

    .slide__text
      margin-left: 64px

    &__myNameIs
      margin-bottom: 44px

    &__myNameIs__charName
      color: #FF961C
      display: inline-block

  .slide-2
    background-image: url(~@/assets/onboarding/slide2.svg)
    justify-content: center

    .slide__text
      width: 580px
      text-align: center

  .slide-3
    background-image: url(~@/assets/onboarding/slide3.svg)
    justify-content: center

    .slide__text
      width: 440px
      text-align: center

  .slide-4
    background-image: url(~@/assets/onboarding/slide4.svg)
    justify-content: center

    .slide__text
      width: 500px
      text-align: center

  .skip
    z-index: 2
    position: absolute
    top: 20px
    right: $size-m
    cursor: pointer
    background-color: transparent
    border: none
    border-radius: 6px
    padding-right: $size-xxs
    +transition($speed-x-fast)
    +flex-center

    &:focus
      outline: none

    &__icon
      background-image: url(~@/assets/icon-x-secondary.svg)
      background-position: center
      background-repeat: no-repeat
      background-size: $size-l
      height: $size-l
      width: $size-l

    &__text
      font-size: $size-s
      font-weight: 800
      margin-left: $size-xxs
      width: 136px
      white-space: nowrap
      overflow: hidden

+v-transition-out(shrink-text)
  width: 0px !important
</style>
