<template>
  <div
    :class="[
      'thumbnail',
      {'--selected': isSelected},
      {'--opaque': isNewThumbnail}
    ]"
    role="button"
    aria-label="Selecionar thumbnail"
    @click="callSelect"
  >
    <header class="thumbnail__header">
      <div class="thumbnail__header__options">
        <strong
          :class="['thumbnail__order', {'--selected': isSelected}]"
        >
          {{ order }}
        </strong>
        <template v-if="optionsIsVisible && isOrderable">
          <span
            v-tooltip.bottom="arrowUpTooltip"
            :class="[
              'icon-btn',
              'arrow-up',
              {'--disabled': isFirst}
            ]"
            role="button"
            data-function="reorder"
            aria-label="ordenar para cima"
            :disabled="isFirst"
            :aria-disabled="isFirst"
            @click="$emit('order-up')"
          />
          <span
            v-tooltip.bottom="arrowDownTooltip"
            :class="[
              'icon-btn',
              'arrow-down',
              {'--disabled': isLast}
            ]"
            role="button"
            aria-label="ordenar para baixo"
            data-function="reorder"
            :disabled="isLast"
            :aria-disabled="isLast"
            @click="$emit('order-down')"
          />
        </template>
      </div>
      <div>
        <span
          v-if="optionsIsVisible && isDuplicable"
          v-tooltip.bottom="'Duplicar'"
          class="icon-btn copy"
          role="button"
          aria-label="duplicar"
        />
        <span
          v-if="optionsIsVisible && isRemovable"
          v-tooltip.bottom="'Remover'"
          class="icon-btn trash"
          role="button"
          aria-label="remover"
          data-function="remove"
          @click="$emit('delete-thumb')"
        />
      </div>
    </header>
    <div class="thumbnail__body">
      <GButton
        v-if="isNewThumbnail"
        class="thumbnail__body__btn"
        variation="secondary"
        size="small"
        icon-left="plus"
        :icon-stroke="4"
        @click="addNewQuestion"
      >
        Adicionar Questão
      </GButton>
      <p
        v-else
        :class="[
          'thumbnail__text',
          {'--centered --opaque': isEmptyThumbnail}
        ]"
      >
        {{ thumbnailText }}
      </p>
    </div>
    <AncestralArenaErrorReport
      v-if="hasError"
      icon-only
      class="thumbnail-error"
      aria-label="mensagem de erro"
    />
  </div>
</template>

<script>
import { truncateText } from '@sas-te/frontend-utils/modules/truncate'

import GButton from '@/components/GButton'
import AncestralArenaErrorReport from '../AncestralArenaErrorReport/AncestralArenaErrorReport'

export default {
  name: 'Thumbnail',
  components: {
    GButton,
    AncestralArenaErrorReport,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'preview',
      validator: (value) => (
        value.match(/(preview|readonly|new)/)
      ),
    },
    order: {
      type: Number,
      required: true,
    },
    isSelected: Boolean,
    hasError: Boolean,
    isOrderable: Boolean,
    isDuplicable: Boolean,
    isRemovable: Boolean,
    isFirst: Boolean,
    isLast: Boolean,
  },
  computed: {
    isNewThumbnail() {
      return this.type === 'new'
    },
    isEmptyThumbnail() {
      return this.type === 'preview' && this.text === ''
    },
    optionsIsVisible() {
      return this.type === 'preview' || this.isEmptyThumbnail
    },
    thumbnailText() {
      if (this.hasError && !this.isEmptyThumbnail) {
        return 'Revise os problemas para salvar a questão'
      }

      if (this.isEmptyThumbnail) {
        return 'Questão em branco'
      }

      return truncateText(this.text, 80)
    },
    arrowUpTooltip() {
      return this.isFirst ? 'Já sou a primeira questão' : 'Mover para cima'
    },
    arrowDownTooltip() {
      return this.isLast ? 'Já sou a última questão' : 'Mover para baixo'
    },
  },
  methods: {
    addNewQuestion() {
      this.$emit('add-new-question')
    },
    callSelect() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
$order-width: 39px;
$thumbnail-min-height: 108px;
$thumbnail-header-height: 23px;

.thumbnail {
  position: relative;
  width: 194px;
  min-height: $thumbnail-min-height;
  background-color: $eureka-color-base-lighter;
  box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 16px;

  @media screen and (max-width: 579px) {
    width: calc(50% - 18px);
  }

  &.--selected {
    box-shadow: 0 4px 0 #004EB3;
    border: 5px solid $eureka-color-secondary;
  }

  &.--opaque {
    opacity: 0.7;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    border-radius: 12px 12px 0 0;
    background-color: $eureka-color-base;
    width: 100%;
    height: $thumbnail-header-height;

    &__options {
      &:first-child {
        padding-left: $order-width;
      }
    }

  }

  &__body {
    @include flex-center;
    height: $thumbnail-min-height - $thumbnail-header-height;
    cursor: pointer;

    &__btn{
      ::v-deep &.g-button {
        opacity: 0.7;
        color: $eureka-color-ink;
      }

      ::v-deep .g-button__text {
        font: {
          family: Rowdies;
          weight: $font-weight-regular;
          size: $font-size-s;
        }
        line-height: 100%;
      }
    }
  }

  &__order {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: $order-width;
    height: 100%;
    background-color: #473F78;
    text-align: center;
    color: white;
    border-radius: $border-radius-l 0 16px;
    font: {
      family: Rowdies;
      weight: $font-weight-regular;
      size: $font-size-s;
    }

    &.--selected {
      background-color: $eureka-color-secondary;
      left: -1px;
      top: -1px;
    }
  }

  &__text {
    font: {
      family: Rowdies;
      weight: $font-weight-light;
      size: 0.8125rem;
    }
    color: $eureka-color-ink;
    line-height: 130%;
    letter-spacing: 0.25px;
    width: 100%;
    padding: 14px 15px;
    word-break: break-all;

    &.--centered {
      text-align: center;
    }

    &.--opaque {
      opacity: 0.6;
    }
  }
}

.icon-btn {
  display: inline-block;
  height: 100%;
  width: 26px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;

  &.--disabled {
    opacity: 0.7;
  }
}

.thumbnail-error  {
  position: absolute;
  top: 65%;
  transform: translateY(-65%);
  right: -12px;
}

.arrow-up {
  background-image: url('~@/assets/arrow-up-thin.svg');
}

.arrow-down {
  background-image: url('~@/assets/arrow-down-thin.svg');
}

.trash {
  background-image: url('~@/assets/trash-filled.svg');
}

.copy {
  background-image: url('~@/assets/copy.svg');
}
</style>
