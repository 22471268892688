<template>
  <ButtonControl
    v-tooltip.top="tooltipMessage"
    class="quick-sound-toggle"
    :icon="sound"
    :theme="theme"
    :shape="shape"
    @click="toggleSounds"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {
  playBackground,
  playSoundFX,
  pauseBackground,
  getBackground,
  resumeBackground
} from '@/utils/soundEffects'
import { backgroundNames } from '@/service/soundController/loadSounds'
import ButtonControl from '@/components/ButtonControl'
import lightSoundOn from '@/assets/audio/light-sound-on.svg'
import lightSoundOff from '@/assets/audio/light-sound-off.svg'
import darkSoundOff from '@/assets/audio/dark-sound-off.svg'
import darkSoundOn from '@/assets/audio/dark-sound-on.svg'

/* eslint-disable max-len */
export default {
  name: 'QuickSoundToggle',
  components: { ButtonControl },
  props: {
    theme: {
      type: String,
      default: 'light',
      validator(value) {
        return [ 'light', 'dark' ].includes(value)
      },
    },
    shape: {
      type: String,
      default: 'square',
    },
  },
  computed: {
    ...mapGetters([
      'soundPreferences',
      'currentEpisodeScene',
      'effectsAlreadyPlayed',
    ]),
    tooltipMessage() {
      const { soundsState } = this

      return soundsState
        ? 'Desativar efeitos sonoros e música de fundo'
        : 'Ativar efeitos sonoros e música de fundo'
    },
    soundsState() {
      const { music, sound } = this.soundPreferences.data

      return music || sound
    },
    currentBackgroundByRoute() {
      const { name } = this.$route
      switch (name) {
        case 'storytelling':
          return this.currentEpisodeScene.data?.sound?.trackUrl

        case 'shop':
          return backgroundNames.SHOP_BACKGROUND

        default:
          return backgroundNames.MAIN_BACKGROUND
      }
    },
    currentEffectByRoute() {
      const { name } = this.$route

      if (name === 'storytelling') {
        return this.currentEpisodeScene.data?.sound?.effectUrl
      }

      return null
    },
    sound() {
      const iconByTheme = {
        light: this.soundsState ? lightSoundOn : lightSoundOff,
        dark: this.soundsState ? darkSoundOn : darkSoundOff,
      }

      return iconByTheme[this.theme]
    },
    currentSceneId() {
      return this.currentEpisodeScene.data?.number
    },
  },
  watch: {
    'soundPreferences.data.music': {
      handler() {
        this.backgroundController()
      },
    },
    'soundPreferences.data.sound': {
      handler() {
        this.soundEffectsController()
      },
    },
    currentEffectByRoute() {
      this.soundEffectsController()
    },
  },
  methods: {
    ...mapActions([
      'setSoundPreferences',
      'setScenesAlreadyPlayedEffects',
    ]),
    toggleSounds() {
      this.setSoundPreferences({
        music: !this.soundsState,
        sound: !this.soundsState,
      })
      this.soundsTracking()
    },
    backgroundController() {
      // eslint-disable-next-line no-underscore-dangle
      const backgroundSource = getBackground()?._src
      const { music } = this.soundPreferences.data
      const { currentBackgroundByRoute, soundsState } = this

      if (soundsState && currentBackgroundByRoute) {
        if (!music) {
          pauseBackground()
        } else if (currentBackgroundByRoute === backgroundSource) {
          resumeBackground()
        } else {
          playBackground(currentBackgroundByRoute)
        }
      } else {
        pauseBackground()
      }
    },
    soundEffectsController() {
      const { sound } = this.soundPreferences.data
      const {
        soundsState,
        currentEffectByRoute,
        currentSceneId,
        setScenesAlreadyPlayedEffects,
        effectsAlreadyPlayed,
      } = this

      if (soundsState && currentEffectByRoute) {
        if (sound && !effectsAlreadyPlayed) {
          playSoundFX(currentEffectByRoute)
          setScenesAlreadyPlayedEffects(currentSceneId)
        }
      }
    },
    soundsTracking() {
      this.$trackEvent({
        category: this.$track.category.soundEffects,
        action: this.$track.action.soundEffectsPreferences,
        label: this.soundsState ? this.$track.label.on : this.$track.label.off,
      })
    },
  },
}
</script>
