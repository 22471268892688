import { render, staticRenderFns } from "./WorldsListCarousel.vue?vue&type=template&id=3945f56c&scoped=true"
import script from "./WorldsListCarousel.vue?vue&type=script&lang=js"
export * from "./WorldsListCarousel.vue?vue&type=script&lang=js"
import style0 from "./WorldsListCarousel.vue?vue&type=style&index=0&id=3945f56c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3945f56c",
  null
  
)

export default component.exports