<template>
  <div
    v-if="visible"
    class="collect-reward-modal"
  >
    <GModal
      :close-button-enabled="false"
      :close-on-click-outside="false"
      :overlay-enabled="false"
    >
      <div class="collect-reward-modal__header">
        <img
          class="collect-reward-modal__header__ribbon"
          src="@/assets/reward/reward-ribbon.svg"
        >
        <h3 class="collect-reward-modal__header__title">
          {{ ribbonText }}
        </h3>
      </div>
      <slot>
        <div class="collect-reward-modal__body">
          <h4 class="collect-reward-modal__body__title">
            {{ greetingText }}
          </h4>
          <div class="collect-reward-modal__body__items">
            <div
              v-for="(rewardItem) in rewardsItems"
              :key="rewardItem.id"
              class="collect-reward-modal__body__item"
            >
              <img
                :alt="rewardItem.name"
                :src="rewardItem.image"
              >
              <span
                v-if="rewardItem.quantity > 1"
                class="collect-reward-modal__body__item__badge"
              >
                {{ `x${rewardItem.quantity}` }}
              </span>
            </div>
          </div>
          <img
            :src="chestImage "
            class="collect-reward-modal__body__chest"
          >
          <GButton
            class="collect-reward-modal__body__action"
            size="large"
            @click="confirm"
          >
            {{ actionText }}
          </GButton>
        </div>
      </slot>
    </GModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import defaultChest from '@/assets/reward/chest-open-shadow.svg'
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'
import typeform from '@/service/typeform'
import userIsStudent from '@/mixins/userIsStudent'
import { arrayUniq } from '@sas-te/frontend-utils/modules/arrays'
import urls from '@/utils/urls'

const SURVEY_ID = 'ZgrkC5Tj'

export default {
  name: 'CollectRewardModal',
  components: {
    GModal, GButton,
  },
  mixins: [ userIsStudent ],
  props: {
    rewards: {
      type: Array,
      default: () => [],
    },
    ribbonText: {
      type: String,
      default: 'Nova Conquista',
    },
    greetingText: {
      type: String,
      default: 'Você ganhou',
    },
    chestImage: {
      type: String,
      default: defaultChest,
    },
    actionText: {
      type: String,
      default: 'Continuar',
    },
  },
  data() {
    return {
      visible: false,
      defaultChest,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'grade',
    ]),
    rewardsItems() {
      if (this.rewards.length) {
        return this.rewards.filter(({ quantity }) => quantity > 0)
          .map(({ item, quantity, id }) => ({
            image: item.image.png,
            id,
            quantity,
          }))
      }

      return []
    },
    teacherGrades() {
      const gradeNamesList = this.user.data.grades.map(({ name }) => name)

      return arrayUniq(gradeNamesList)
    },
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    confirm() {
      this.$emit('click')
      this.openFeedbackSurvey()
    },
    openFeedbackSurvey() {
      const appBaseUrl = urls.BASE_URL
      const formId = SURVEY_ID
      const url = appBaseUrl.concat(this.$route.path)
      const accountId = this.user.data.id
      const grade = this.userIsStudent ? this.grade?.name : this.teacherGrades
      const platform = 'web'
      const profile = this.user.data.profiles[0].name

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields: {
          account_id: accountId,
          grade,
          url,
          platform,
          profile,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>

<style lang="scss" scoped>
.collect-reward-modal {
  &__header {
    &__ribbon {
      position: absolute;
      top: -16%;
      top: -62px;
      left: 50%;
      transform: translate(-50%);
    }

    &__title {
      position: absolute;
      top: -34px;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      color: $color-white;
      text: {
        align: center;
        shadow: 0 1.40055px 0 rgba(0, 0, 0, 0.5);
      }
      font: {
        size: $font-size-heading-5;
        weight: $font-weight-bold;
      }
    }
  }

  &__body {
    @include flex-center-start;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5px {
      top: 30px;
      bottom: 20px;
    }
    background: {
      image: url('~@/assets/reward/reward-bg.svg');
      position: center;
      repeat: no-repeat;
      size: contain;
    }

    &__title {
      margin-bottom: $size-xs;
    }

    &__items {
      @include flex-center;
      width: 100%;
      flex-wrap: wrap;
      gap: $size-s;
      margin-bottom: $size-s;
    }

    &__item {
      position: relative;

      img {
        max: {
          width: $size-xxl;
          height: $size-xxl;
        }
      }

      &__badge {
        padding: 0 $size-xxs;
        position: absolute;
        bottom: 5px;
        right: 0;
        border-radius: 100%;
        background: $color-white;
        text-align: center;
        font: {
          weight: $font-weight-extra-bold;
          size: $font-size-xs;
        }
      }
    }

    &__chest {
      margin-bottom: $size-s;
    }
  }
}

::v-deep .g-frame {
  width: 267px;
  min-height: 378px;

  .g-frame__inner {
    @include flex-center;
    height: 100%;
  }
}
</style>
