import { isEmpty, flatMapDeep } from 'lodash-es'

function mapItems(item) {
  const itemCopy = { ...item }
  delete itemCopy.children

  if (isEmpty(item.children)) {
    return itemCopy
  }

  return [
    itemCopy,
    flatMapDeep(item.children, mapItems),
  ]
}

export default function flatMapDeepUtil(array) {
  return flatMapDeep(array, mapItems)
}
