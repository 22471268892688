import { mapActions, mapGetters, mapState } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'Tour',
  computed: {
    ...mapGetters([
      'user',
      'onboarding',
    ]),
    ...mapActions([
      'getUser',
    ]),
    ...mapState({
      eurekaArenaOnboardingEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaArenaOnboarding,
      eurekaHomeMissionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaHomeMissions,
      isSasQuestionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaSasQuestions,
    }),
    userId() {
      return this.user.data?.id
    },
  },
  methods: {
    ...mapActions([ 'setOnboarding' ]),
    isTourActive(tourName) {
      if (!this.isUserActive()) {
        this.onboardUser()
      }

      if (this.onboarding?.users?.[this.userId]) {
        return !!this.onboarding.users[this.userId][tourName]
      }

      return false
    },
    endTour(tour) {
      const onboarding = cloneDeep(this.onboarding)
      onboarding.users[this.userId] = {
        ...onboarding.users[this.userId],
        ...tour,
      }
      this.setOnboarding(onboarding)
    },
    onboardUser() {
      const onboarding = cloneDeep(this.onboarding)

      onboarding.users[this.userId] = {
        id: this.userId,
        createNewMission: true,
        missionList: true,
        newMagnaMission: true,
        magnaMissionQuestionSelector: true,
        homeMissionModal: true,
        eurekaHomeMissions: true,
      }

      this.setOnboarding(onboarding)
    },
    isUserActive() {
      if (!this.onboarding.users) {
        return false
      }

      return !!this.onboarding.users[this.userId]
    },
  },
}
