<template>
  <Accordion
    :is-visible="isVisible"
    @change-visibility="setIsVisible"
  >
    <template #accordion-header>
      <header>
        <img
          :class="[
            'player-accordion__icon',
            { '--right' : !isVisible },
          ]"
          class="player-accordion__icon"
          src="@/assets/chevron-down.svg"
          alt=""
        >
        <h5 class="player-accordion__title">
          {{ title }}
        </h5>
      </header>
    </template>
    <template #accordion-content>
      <slot />
    </template>
  </Accordion>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion'

export default {
  components: {
    Accordion,
  },
  props: {
    playersAmount: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      required: true,
    },
    initialVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: this.initialVisible,
    }
  },
  computed: {
    hasPlayers() {
      return this.playersAmount > 0
    },
    title() {
      return `${this.text} (${this.playersAmount})`
    },
  },
  watch: {
    hasPlayers(value) {
      this.setIsVisible(value)
    },
  },
  methods: {
    setIsVisible(isVisible) {
      this.isVisible = isVisible
    },
  },
}
</script>

<style lang="scss" scoped>
.player-accordion {
  &__icon {
    vertical-align: top;
    margin-right: 14px;
    transition: all $speed-fast linear;

    &.--right {
      transform: rotate(-90deg);
      transition: all $speed-fast linear;
    }
  }

  &__title {
    display: inline-block;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
    color: $color_white;
    vertical-align: baseline;
  }
}
</style>
