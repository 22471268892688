<template>
  <GModal
    v-if="visible"
    class="reward-modal"
    :close-button-enabled="false"
    :close-on-click-outside="false"
    @close="close"
  >
    <div class="reward-modal__body">
      <img
        class="reward-modal__ribbon"
        src="@/assets/reward/reward-ribbon.svg"
      >
      <h3 class="reward-modal__title">
        <template v-if="isChest">
          Nova Conquista
        </template>
        <template v-if="isLevel">
          {{ $t('game.reward.levelUpTitle') }}
        </template>
      </h3>
      <ChestModalContent
        v-if="isChest"
        :items="nextReward.items"
        :is-first-chest="nextReward.first"
        @close="close"
      />
      <LevelModalContent
        v-if="isLevel"
        :level="parseInt(nextReward.value, 10)"
        :items="nextReward.items"
        @close="close"
      />
    </div>
  </GModal>
</template>

<script>
import { mapGetters } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import { effectsNames } from '@/service/soundController/loadSounds'
import GModal from '@/components/GModal'
import ChestModalContent from './ChestModalContent'
import LevelModalContent from './LevelModalContent'

export default {
  name: 'RewardModal',
  components: {
    GModal,
    ChestModalContent,
    LevelModalContent,
  },
  data() {
    return {
      visible: false,
      rewardQueue: [],
    }
  },
  computed: {
    ...mapGetters([
      'rewards',
    ]),
    nextReward() {
      return this.rewardQueue.length
        ? this.rewardQueue[0]
        : null
    },
    isChest() {
      return this.nextReward && this.nextReward.type === 'chest'
    },
    isLevel() {
      return this.nextReward && this.nextReward.type === 'level'
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.playRewardSound()
      }
    },
    rewards() {
      if (this.rewards.success) {
        this.rewardQueue = this.filterRewards(this.rewards.data)
        if (this.rewardQueue.length > 0) {
          setTimeout(() => {
            this.visible = true
          }, 700)
        }
      }
    },
  },
  methods: {
    filterRewards(rewards) {
      return rewards.filter((reward) => (
        /(chest|level)/.test(reward.type)
      )).sort((a) => {
        if (a.type === 'chest') return -1
        if (a.type === 'level') return 1

        return 0
      })
    },
    close() {
      const { isChest, isLevel, nextReward } = this

      this.visible = false
      this.rewardQueue.shift()
      if (this.rewardQueue.length) {
        setTimeout(() => {
          this.visible = true
        }, 250)
      }
      this.$emit('close', { type: { isChest, isLevel }, reward: nextReward })
    },
    playRewardSound() {
      let soundEffect = null

      if (this.isChest) {
        soundEffect = 'REWARD_CHEST'
      }

      if (this.isLevel) {
        soundEffect = 'REWARD_LEVEL_UP'
      }

      playSoundFX(effectsNames[soundEffect])
    },
  },
}
</script>

<style lang="sass" scoped>
.reward-modal
  &__body
    +flex-center
    position: relative

  &__ribbon
    position: absolute
    top: -62px

  &__title
    color: $color-white
    font-size: 24px
    font-weight: bold
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5)
    position: absolute
    top: -38px
</style>
