<template>
  <button
    :class="['bag-button', { '--mobile': isMobile }]"
    @click="$emit('click')"
  >
    <img
      class="bag-button__image"
      src="@/assets/bag.svg"
    >
    <div class="bag-button__text">
      {{ $t('game.bag.label') }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'BagButton',
  props: {
    isMobile: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.bag-button {
  padding: $size-xs $size-s;
  background: $eureka-color-base;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-left: none;
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.25), 0 6px 0 #DFB959;
  border-radius: 0 $size-s $size-s 0;
  font-weight: $font_weight_bold;
  font-size: $font-size-s;
  cursor: pointer;
  transform-origin: top left;

  transition: transform speed-fast;

  &:focus {
    outline: none;
  }

  &:active {
    transform: translate(-1px, 2px);
  }

  &__image {
    width: $size-xl;
    margin-bottom: $size-xs;
    -webkit-user-drag: none;
  }

  &.--mobile {
    @include flex-space-between;
    width: min(21%, 9.2rem);
    height: 8.8vh;
    background: none !important;
    box-shadow: none;
    padding: 0.5rem 0 0;
    border: none;
    font-size: 1.5rem;
    font-weight: bold !important;

    img {
      width: 3rem !important;

      @media screen and (max-width: 768px) {
        width: 2rem !important;
      }
    }
  }
}
</style>
