<template>
  <div class="item-showcase">
    <header class="item-showcase__header">
      <img
        class="item-showcase__header__image"
        :src="image"
      >
      <h3 class="item-showcase__header__title">
        {{ name }}
      </h3>
    </header>

    <div class="item-showcase__text">
      <div
        v-if="isAvailableItem"
      >
        {{ description }}
      </div>
    </div>

    <footer class="item-showcase__footer">
      <div
        v-if="isAvailableItem"
        class="item-showcase__footer__requirements"
      >
        <div
          v-if="minGems"
          class="item-showcase__footer__requirements__gems"
        >
          <img :src="require(`@/assets/diamond-small.svg`)">
          <p class="item-showcase__footer__requirements__gems__description">
            {{ minGems }}
          </p>
        </div>
        <div
          v-if="amountLimitPerWeek"
          class="item-showcase__footer__requirements__limit"
        >
          <img
            v-tooltip.bottom="$t('game.shop.limitPerWeek')"
            class="items-icon"
            :src="require('@/assets/shopping_basket.svg')"
          >
          <p class="item-showcase__footer__requirements__limit__description">
            {{ `${amountAvailablePerWeek} / ${amountLimitPerWeek}` }}
          </p>
        </div>
      </div>

      <template v-if="isAvailableItem">
        <div v-tooltip.bottom="purchaseRequirement">
          <GButton
            size="medium"
            asset="coin.svg"
            :disabled="isInvalidToBuy"
            @click="$emit('buy')"
          >
            {{ price }}
          </GButton>
        </div>
      </template>
      <template v-else>
        <img
          class="item-showcase__footer__unreleased-item"
          src="@/assets/soon-banner.svg"
        >
      </template>
    </footer>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'ItemShowcase',
  components: {
    GButton,
  },
  props: {
    item: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    description: {
      type: String,
      required: true,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    minGems: {
      type: Number,
      default: 0,
    },
    amountAvailablePerWeek: {
      type: Number,
      default: 0,
    },
    amountLimitPerWeek: {
      type: Number,
      default: 0,
    },
    buyItemRules: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      required: true,
      validator: (value) => (
        value.match(/(AVAILABLE|UNRELEASED)/)
      ),
    },
  },
  computed: {
    isAvailableItem() {
      return this.status === 'AVAILABLE'
    },
    isInvalidToBuy() {
      return this.buyItemRules.length > 0
    },
    purchaseRequirement() {
      return this.isInvalidToBuy ? this.buyItemRules[0] : ''
    },
  },
}
</script>

<style lang="scss" scoped>

.item-showcase {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: space-between;
  border: 5px solid rgba($color-white, 0.6);
  border-radius: 12px;
  background: #F9EDDB;
  box-sizing: border-box;
  box-shadow: 0 4px 0 #D9AF54, 0 8px 4px rgba(0, 0, 0, 0.25);
  @include mq_m {
    box-shadow: 0 0 0;
  }

  &__header {
    display: flex;
    align-items: center;

    &__image {
      width: 3rem;
      margin-right: $size-xs;
      -webkit-user-drag: none;
    }

    &__title {
      line-height: 1;

      font: {
        weight: $font-weight-medium;
        size: $font-size-heading-4;
      }
      @include mq_m {
        font: {
          weight: $font-weight-regular;
          family: Rowdies;
          size: $font-size-heading-4-small;
      }
      }
    }
  }

  &__text {
    width: 100%;
    padding-bottom: 0.2rem;
    color: transparentize($game-ink, 0.3);
    font-weight: 500;
    font-size: $font-size-m;
    line-height: 1.3;
    @include mq_m {
      font-size: $font-size-s;
      padding: 0.5rem 0;
    }
    @include mq_xl__mf {
      font-size: $font-size-s;
    }
  }

  &__footer {
    @include flex-space-between;
    z-index: 1;

    &__requirements {
      display: flex;

      &__gems {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: $size-s;

        &__description {
          margin-left: 6px;
          font: {
            weight: $font-weight-extra-bold;
            size: $font-size-s;
          }
        }
      }

      &__limit {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        margin-right: $size-s;

        &__description {
          margin-left: 6px;
          font: {
            weight: $font-weight-extra-bold;
            size: $font-size-s;
          }
        }
      }
    }
  }

  &__unreleased-item {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}
</style>
