<template>
  <div class="adventures-seasons-layout">
    <h3 class="adventures-seasons-layout__title">
      {{ title }}
    </h3>
    <div class="adventures-episodes-list">
      <AdventureEpisodeCard
        v-for="(episode, index) in episodes"
        :key="index"
        :loading="loading"
        :title="episode.name"
        :number="episode.number"
        :rewards="episode.rewardItems"
        :cover="episode.image.png || episode.image.svg"
        :locked="episode.locked"
        :is-new="episode.newEpisode"
        :min-gems="episode.minGems"
        :reward-received="episode.rewardReceived"
        @click="$emit('select-episode', {
          episodeId: episode.id,
          episodeName: episode.name,
          newEpisode: episode.newEpisode,
          seasonId: id })"
      />
    </div>
  </div>
</template>

<script>
import AdventureEpisodeCard
  from '@/components/Adventure/AdventureEpisodeCard'

export default {
  name: 'AdventureSeasonLayout',
  components: {
    AdventureEpisodeCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    episodes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    seasonId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.adventures-seasons-layout {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: $font-size-heading-4;
    font-weight: $font-weight-semi-bold;
    color: #25193E;
    margin-bottom: 12px;

    @media screen and (max-width: 479px) {
      margin-top: 6px;
    }
  }
}

.adventures-episodes-list {
  height: 100% !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2%;
  padding-bottom: 6px;

  @media screen and (max-width: 479px) {
    gap: 8px;
  }

  &::v-deep .adventure-episode-card {
    &:not(:last-child) {
      margin-bottom: 2%;
    }
  }
}
</style>
