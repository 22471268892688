<template>
  <div class="collection-item">
    <span
      v-if="textTop"
      data-testid="collection-item-text-top"
      class="collection-item__text-top"
    >
      {{ textTop }}
    </span>
    <g-block
      v-bind="dinamicProps"
      :theme="themeColor"
      :size="size"
    >
      {{ value }}
    </g-block>
  </div>
</template>

<script>

import GBlock from '@/components/GBlock'

export default {
  name: 'CollectionItem',
  components: {
    GBlock,
  },
  props: {
    image: {
      type: String,
      required: false,
      default: '',
    },
    quantity: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => (
        value.match(/(small|medium|large)/)
      ),
    },
    themeColor: {
      type: String,
      default: 'default',
      required: false,
      validator: (value) => (
        value.match(/(default|bright|success-inverted|ocean-blue|transparent)/)
      ),
    },
    type: {
      type: String,
      default: '',
      validator: (value) => value.match(/(^$|coin|gem)/),
    },
    textTop: {
      type: String,
      default: '',
    },
  },
  computed: {
    value() {
      return this.text ? this.text : this.quantity
    },
    dinamicProps() {
      const props = {}

      if (this.type) {
        props.asset = `${this.type}.svg`
      } else if (this.image) {
        props.image = this.image
      }

      return props
    },
  },
}
</script>
<style lang="scss" scoped>
.collection-item {
  &__text-top {
    position: relative;
    top: -$size-xs;
    display: inline-block;
    vertical-align: top;
    margin-left: $size-s;
  }
}
</style>
