<template>
  <div
    class="level-display"
    :style="{ marginTop: isMFE ? '0.5rem' : 'unset' }"
  >
    <div
      v-if="rank"
      class="level-display__badge"
    >
      <img
        :src="insigniaImage"
        alt=""
      >
    </div>
    <div
      v-if="rankLabelIsVisible"
      class="level-display__text"
    >
      <span
        v-if="showLevelNumber"
        class="level-display__text__level"
        data-testid="level"
        :style="{
          color,
          opacity: 0.7,
        }"
      >
        {{ $t('game.levelNumber', { number: level}) }}
      </span>
      <div
        class="level-display__text__rank"
        data-testid="rank"
        :style="{ color }"
      >
        {{ insigniaTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'LevelDisplay',
  mixins: [ mediaQueries ],
  props: {
    avatar: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: null,
    },
    showLevelNumber: {
      type: Boolean,
      default: true,
    },
    rank: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: '#000000',
    },
    rankLabelIsVisible: Boolean,
  },
  computed: {
    insigniaImage() {
      return this.rank?.insigniaImage?.png || this.rank?.insigniaImage?.svg
    },
    insigniaTitle() {
      return this.rank?.insigniaTitle || ''
    },
    isMFE() {
      return window.eureka_init
    },
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateLevelProp()
      },
    },
  },
  methods: {
    validateLevelProp() {
      if ((this.showLevelNumber && !this.level)) {
        throw new Error(`Missing required prop: "level". Level is required when showLevelNumber
         is true`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.level-display {
  gap: 0.5rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 479px) {
    gap: 0;
  }

  &__text {
    &__level {
      font-weight: $font-weight-bold;
      line-height: 13px;
      color: #1E2124;
    }

    &__rank {
      font-weight: $font-weight-bold;
      line-height: 15px;
      color: #1E2124;
    }
  }

  &__badge {
    @include flex-center;

    img {
      width: auto;
      max-width: initial;
      height: 40px;
    }

    &__level {
      line-height: 1;
      font-size: 13px;
      font-weight: 500;
      color: transparentize($game-ink, 0.2);
      white-space: nowrap;
    }

    &__rank {
      line-height: 1;
      font-size: 15px;
      font-weight: 700;
      white-space: nowrap;
    }
  }
}
</style>
