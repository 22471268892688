<template>
  <div class="retrospective-view">
    <RetrospectiveTemplate
      :current-page="currentPage"
      :total-pages="totalPages"
      :is-display-pagination="isShowPagintion"
      @nextPage="slideNext"
      @close="closeRetrospective"
    >
      <div
        v-if="isLoading"
        class="retrospective-view --loading"
      >
        <GLoader />
        <p class="content__text">
          Estamos estudando os astros em breve te mostramos a retrospectiva...
        </p>
      </div>
      <ErrorMessage
        v-else-if="retrospective.error"
        image-name="refresh.svg"
        message="Não foi possível carregar os dados da sua retrospectiva"
        action="Recarregar"
        @click="fetchRetrospective"
      />
      <hooper
        v-else
        ref="carousel"
        :vertical="true"
        :items-to-show="1"
        :transition="600"
        @slide="updateCarousel"
      >
        <slide>
          <RetrospectiveApresentation
            :retrospective-data="retrospective.data"
          />
        </slide>
        <slide v-if="validation.totalMissions">
          <RetrospectiveTotalMissions
            :retrospective-data="retrospective.data"
            @updateValidation="updateValidation"
          />
        </slide>
        <slide v-if="validation.mostUsedItem">
          <RetrospectiveMostUsedItem
            :retrospective-data="retrospective.data"
            @updateValidation="updateValidation"
          />
        </slide>
        <slide v-if="validation.mostAccessedWorld">
          <RetrospectiveMostAccessedWorld
            :retrospective-data="retrospective.data"
            @updateValidation="updateValidation"
          />
        </slide>
        <slide v-if="validation.lastGameIconChosen">
          <RetrospectiveLastGameIconChosen
            :retrospective-data="retrospective.data"
            @updateValidation="updateValidation"
          />
        </slide>
        <slide v-if="validation.studentInsignia">
          <RetrospectiveStudentInsignia
            :retrospective-data="retrospective.data"
            @updateValidation="updateValidation"
          />
        </slide>
        <slide>
          <RetrospectiveSummary
            :retrospective-data="retrospective.data"
          />
        </slide>
      </hooper>
    </RetrospectiveTemplate>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { Hooper, Slide } from 'hooper'

import academicRecordsService from '@/service/academicRecords'
import GLoader from '@/components/GLoader'
import ErrorMessage from '@/components/ErrorMessage'
import RetrospectiveTemplate from './partials/RetrospectiveTemplate'
import RetrospectiveSummary from './partials/RetrospectiveSummary'
import RetrospectiveApresentation from './partials/RetrospectiveApresentation'
import RetrospectiveTotalMissions from './partials/RetrospectiveTotalMissions'
import RetrospectiveMostUsedItem from './partials/RetrospectiveMostUsedItem'
import RetrospectiveMostAccessedWorld from './partials/RetrospectiveMostAccessedWorld'
import RetrospectiveLastGameIconChosen from './partials/RetrospectiveLastGameIconChosen'
import RetrospectiveStudentInsignia from './partials/RetrospectiveStudentInsignia'

export default {
  name: 'RetrospectiveView',
  components: {
    RetrospectiveTemplate,
    Hooper,
    GLoader,
    ErrorMessage,
    RetrospectiveSummary,
    RetrospectiveApresentation,
    RetrospectiveTotalMissions,
    RetrospectiveMostUsedItem,
    RetrospectiveMostAccessedWorld,
    RetrospectiveLastGameIconChosen,
    RetrospectiveStudentInsignia,
    Slide,
  },
  data() {
    return {
      currentSlide: 0,
      retrospective: {
        loading: false,
        data: null,
        error: null,
      },
      validation: {
        totalMissions: true,
        mostUsedItem: true,
        mostAccessedWorld: true,
        lastGameIconChosen: true,
        studentInsignia: true,
      },
      numberOfSlidesWithoutValidation: 2,
    }
  },
  computed: {
    ...mapState({
      showRetrospectiveByFeatureToggle: ({ featureToggles }) => featureToggles
        .toggles.eurekaRetrospective,
      isLoadingToggles: ({ featureToggles }) => featureToggles.toggles.loading,
    }),
    ...mapGetters([
      'lectures',
    ]),
    isLoading() {
      if (!this.showRetrospectiveByFeatureToggle) {
        return true
      }

      return this.retrospective.loading
    },
    currentPage() {
      return this.currentSlide + 1
    },
    totalPages() {
      const numberOfSlidesWithValidation = [
        this.validation.totalMissions,
        this.validation.mostUsedItem,
        this.validation.mostAccessedWorld,
        this.validation.lastGameIconChosen,
        this.validation.studentInsignia,
      ].filter((isValid) => isValid).length

      return this.numberOfSlidesWithoutValidation + numberOfSlidesWithValidation
    },
    isShowPagintion() {
      return !(this.isLoading || this.retrospective.error)
    },
    year() {
      return this.$route.params.retrospectiveId
    },
  },
  watch: {
    isLoadingToggles(isLoading) {
      if (!isLoading) {
        if (this.showRetrospectiveByFeatureToggle) {
          this.fetchRetrospectiveDataWhenToggleActive()
        } else {
          this.$router.replace('/profile')
        }
      }
    },
  },
  created() {
    this.fetchRetrospectiveDataWhenToggleActive()
  },
  methods: {
    ...mapActions([ 'getLectures' ]),
    slideNext() {
      if (this.totalPages === this.currentPage) {
        this.$refs.carousel.slideTo(0)
      } else {
        this.$refs.carousel.slideNext()
      }
    },
    updateCarousel(payload) {
      this.currentSlide = payload.currentSlide
    },
    updateValidation({ name, isValid }) {
      this.validation[name] = isValid
    },
    fetchRetrospectiveDataWhenToggleActive() {
      if (!this.isLoadingToggles && this.showRetrospectiveByFeatureToggle) {
        if (!this.lectures.data) {
          this.getLectures(this.gradeId)
        }

        this.fetchRetrospective()
      }
    },
    async fetchRetrospective() {
      this.retrospective.error = null
      this.retrospective.loading = true

      try {
        const { data } = await academicRecordsService.getRetrospectiveByYear(this.year)
        this.retrospective.data = data
      } catch (error) {
        this.retrospective.error = error
      } finally {
        this.retrospective.loading = false
      }
    },
    closeRetrospective() {
      this.$trackEvent({
        category: this.$track.category.Retrospective,
        action: this.$track.action.closeRetrospective,
        label: this.$track.label.numberOfPage,
        value: this.currentSlide + 1,
      })

      this.$router.replace({
        name: 'profile',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.retrospective-view {
  height: 100vh;

  &.--loading {
    height: 100%;
    @include flex-column-center;
    font: {
      family: Lato;
      weight: $font-weight-regular;
      size: $font-size-heading-4;
    }
    text-align: center;
    color: $color-white;
    text-shadow: 0 1px 0 $color-black;

    @media screen and (max-width: 991px) {
      padding: 12.5%;
    }

    @media screen and (max-width: 579px) {
      padding: 5% 12.5%;
    }

    @media screen and (max-width: 479px) {
      padding: 5% 5%;
    }
  }

  ::v-deep .error-message {
     height: 100%;
     @include flex-column-center;

     &__description {
      font: {
        family: Rowdies;
        weight: $font-weight-light;
        size: $font-size-heading-4;
      }
      color: $color-white;
      text-shadow: 0 1px 0 $color-black;
     }
  }
}

::v-deep .hooper {
  width: 100%;
  height: 100%;
  z-index: 10000;
}

::v-deep .hooper-track .hooper-slide{
  @include flex-center;
}

::v-deep .slide {
  &__content {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    z-index: 890;
    padding: 25%;

    @media screen and (max-width: 991px) {
      padding: 12.5%;
    }

    @media screen and (max-width: 579px) {
      padding: 5% 12.5%;
    }

    @media screen and (max-width: 479px) {
      padding: 5% 5%;
    }

    @include mq_xl__mf {
      padding: 32.5%;
    }
  }

  &__title {
    font: {
      family: Rowdies;
      weight: $font-weight-regular;
    }
    color: $color-white;
    text-shadow: 0 1px 0 $color-black;
    margin-bottom: $size-l;

    @media screen and (max-height: 789px) {
      margin-bottom: $size-s;
    }

    &.--medium {
      font-size: $font-size-heading-4;

      @media screen and (max-height: 789px) {
        font-size: $font-size-heading-5;
      }
    }

    &.--large {
      font-size: $font-size-heading-2;

      @media screen and (max-height: 789px) {
        font-size: $font-size-heading-3;
      }
    }

    &.--important {
      color: $eureka-color-primary;
      white-space: nowrap;
    }
  }

  &__image {
    max-width: 100%;
    margin-bottom: $size-l;

    &.--no-margin {
      margin: 0;
    }
  }

   &__text {
      margin-bottom: $size-l;
      font: {
        family: Rowdies;
        weight: $font-weight-light;
        size: $font-size-heading-4;
      }
      color: $color-white;
      text-shadow: 0 1px 0 $color-black;

      @media screen and (max-height: 789px) {
        font-size: $font-size-heading-5;
        margin-bottom: $size-s;
      }

      &.--highlight {
        font-family: Lato;
        font-weight: $font-weight-bold;
      }

      &.--important {
        color: $eureka-color-primary;
        white-space: nowrap;
      }

      &.--info {
        font: {
          family: Lato;
          weight: $font-weight-medium;
        }
      }
    }

    &__highlighted-icon {
      display: flex;
      align-self: center;
      margin-bottom: $size-l;

      @media screen and (max-height: 789px) {
        margin-bottom: $size-s;
      }
    }
}

</style>
