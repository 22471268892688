<template>
  <div
    class="diamond-display"
    data-testid="hud-diamond"
  >
    <g-block
      asset="diamond.svg"
      :theme="themeDisplay"
      :size="size"
    >
      <p class="diamond-display__text">
        {{ content.conqueredGems }} / {{ content.totalGems }}
      </p>
    </g-block>
  </div>
</template>

<script>
import GBlock from './GBlock'

export default {
  name: 'DiamondDisplay',
  components: {
    GBlock,
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {},
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => (
        value.match(/(small|medium|large)/)
      ),
    },
    themeDisplay: {
      type: String,
      default: 'bright',
    },
  },
}
</script>

<style lang="scss" scoped>
.diamond-display {
  width: 100%;

  &__text {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
  }

  ::v-deep .g-button__text {
    padding: 0;
    margin-left: $size-xxs;
  }
}
</style>
