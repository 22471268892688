<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <SplashScreen
        v-if="hasInitialSplashScreen && splashScreen"
        @close="closeSplashScreen"
      />
      <div
        v-else
        :class="[
          'worlds',
          {
            '--has-teacher-bar': isTeacherTopbarVisible,
            '--adapt': userIsAdapt,
            '--mfe-margin': isMFE,
          }
        ]"
      >
        <TeacherTopbar
          v-if="isTeacherTopbarVisible"
        />
        <header class="worlds__header">
          <div class="worlds__header__title">
            <img
              class="worlds__header__logo"
              src="@/assets/logo.svg"
            >
            <UserConfig
              v-if="userPreferences.data || soundPreferences.data"
              class="user-status-bar__control hide-on-desktop"
              aria-label="Configuração de Som"
            >
              <ConfigControl
                label="Sons"
                :icon="soundIcon"
                :control-id="`${$route.name}-sounds`"
                :control-value="soundPreferences.data.sound"
                @valueChange="toggleSounds"
              />
              <ConfigControl
                label="Música"
                :icon="soundIcon"
                :control-id="`${$route.name}-background`"
                :control-value="soundPreferences.data.music"
                @valueChange="toggleMusic"
              />
              <ConfigControl
                label="Grade"
                :icon="gridIcon"
                :control-id="`${$route.name}-grid`"
                :control-value="userPreferences.data.worldsLayoutGrid"
                @valueChange="toggleGrid"
              />
            </UserConfig>
          </div>
          <UserStatusBar />
        </header>
        <div
          v-if="lectures.success && !lectures.loading"
          :class="['worlds__content', {'--column-4': columnStyle}]"
        >
          <transition name="fade">
            <WorldsListCarousel
              v-if="!isGridView && !isThirdYearUser"
              @go-to-world="goToWorld"
              @active-world="activeWorld"
            />
            <WorldsListGrid
              v-if="isGridView || isThirdYearUser"
              @go-to-world="goToWorld"
              @active-world="activeWorld"
            />
          </transition>
        </div>
        <div v-else>
          <GLoader class="load-state" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { playBackground, setDisabledBackground, pauseBackground } from '@/utils/soundEffects'

import { trackSoundPreferenceEvent, trackMusicPreferenceEvent } from '@/components/UserConfig/events'

import soundOn from '@/assets/audio/light-sound-on.svg'
import isNotEmpty from '@/utils/isNotEmpty'
import userIsStudent from '@/mixins/userIsStudent'
import gradeId from '@/mixins/gradeId'
import grid from '@/assets/grid.svg'
import getGradeId from '@/mixins/getGradeId'
import userIsAdapt from '@/mixins/userIsAdapt'
import GLoader from '@/components/GLoader'
import UserStatusBar from '@/components/UserStatusBar'
import UserConfig from '@/components/UserConfig/UserConfig'
import ConfigControl from '@/components/UserConfig/ConfigControl'
import TeacherTopbar from '../../components/TeacherTopbar'
import WorldsListCarousel from './partials/WorldsListCarousel'
import WorldsListGrid from './partials/WorldsListGrid'
import SplashScreen from '../SplashScreen/SplashScreen'

export default {
  name: 'Worlds',
  components: {
    UserConfig,
    ConfigControl,
    WorldsListCarousel,
    UserStatusBar,
    SplashScreen,
    TeacherTopbar,
    WorldsListGrid,
    GLoader,
  },
  mixins: [ userIsStudent, gradeId, getGradeId, userIsAdapt ],
  props: {
    hasInitialSplashScreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      splashScreen: true,
      newsFeedIsVisible: false,
      dayToDisableSplash: new Date('2021-01-18T09:00'),
      today: new Date(),
      soundIcon: soundOn,
      musicIcon: soundOn,
      gridIcon: grid,
    }
  },
  computed: {
    ...mapGetters([
      'userGameData',
      'lectures',
      'user',
      'historySeasons',
      'userPreferences',
      'soundPreferences',
    ]),
    isMFE() {
      return window.eureka_init
    },
    isTeacherTopbarVisible() {
      return this.user.data && !this.user.data.userIsStudent
    },
    isThirdYearUser() {
      const gradeID = this.gradeId ? this.gradeId : this.getGradeId()

      return gradeID === 8
    },
    columnStyle() {
      return this.lectures.data?.length > 5
    },
    isGridView() {
      return Boolean(this.userPreferences.data?.worldsLayoutGrid)
    },
    splashScreenIsVisible() {
      return this.splashScreen && this.hasInitialSplashScreen
    },
  },
  watch: {
    splashScreenIsVisible: {
      handler() {
        this.$emit('splash-screen-visibility', this.splashScreenIsVisible)
      },
      immediate: true,
    },
    splashScreen() {
      if (!this.splashScreen) {
        setDisabledBackground(false)
        playBackground()
      }
    },
    gradeId: {
      immediate: true,
      handler(newGradeId, oldGradeId) {
        if (newGradeId !== oldGradeId) {
          if (this.userIsStudent && !oldGradeId) return

          const { lectureId } = this.$route.query
          this.$route.query.gradeId = newGradeId

          if (!this.userIsStudent) {
            this.resetThemes()
          }
          this.getTheme(lectureId)
        }
      },
    },
  },
  async created() {
    const { hasInitialSplashScreen, splashScreen } = this
    const { name } = this.$route
    if (!this.user.data?.id) {
      await this.getUser()
    }

    if (hasInitialSplashScreen && splashScreen) {
      setDisabledBackground(true)
    } else {
      pauseBackground()
      playBackground()
    }

    this.$resetTitle()
    if (this.lectures.data === null && this.userIsStudent) {
      this.getLectures()
    }

    if (name.includes('news-')) {
      this.newsFeedIsVisible = true
    }
    this.getUnreadNews()
  },
  methods: {
    ...mapActions([
      'setUserPreferences',
      'setSoundPreferences',
      'getTheme',
      'getLectures',
      'getUnreadNews',
      'getUser',
      'resetThemes',
      'getAllNews',
    ]),
    toggleSounds({ value }) {
      trackSoundPreferenceEvent(this, value)
      this.setSoundPreferences({ sound: value })
    },
    toggleMusic({ value }) {
      trackMusicPreferenceEvent(this, value)
      this.setSoundPreferences({ music: value })
    },
    toggleGrid({ value }) {
      this.$trackEvent({
        category: this.$track.category.worlds,
        action: this.$track.action.worldsVisualization,
        label: value ? 'grid' : 'carousel',
      })
      this.setUserPreferences({
        worldsLayoutGrid: value,
      })
    },
    goToWorld(lecture) {
      this.getTheme(lecture.id)
      this.$router.push({
        name: 'assignments',
        query: { ...this.$route.query },
        params: {
          lectureId: lecture.id,
          hasInitialSplashScreen: false,
          hasTheme: true,
        },
      })
    },
    activeWorld(world) {
      this.getTheme(world.id)
    },
    closeSplashScreen() {
      const [ firstStorySeason ] = this.historySeasons.data

      let isAutostartStory = false
      let firstHistoryEpisode = null

      if (isNotEmpty(firstStorySeason?.episodes)) {
        firstHistoryEpisode = firstStorySeason?.episodes[0]
        const { rewardReceived, newEpisode } = firstHistoryEpisode
        isAutostartStory = !rewardReceived && newEpisode
      }

      setDisabledBackground(false)
      this.$router.replace({
        params: {
          ...this.$route.params,
          hasInitialSplashScreen: false,
        },
        query: {
          ...this.$route.query,
        },
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Erro ao fechar Splash: ${error}`)
      })

      if (isAutostartStory) {
        this.$router.push({
          name: 'storytelling',
          params: {
            seasonId: firstStorySeason.id,
            episodeId: firstHistoryEpisode.id,
            openAdventure: false,
          },
          query: {
            ...this.$route.query,
          },
        })
      } else {
        this.getAllNews()
        this.splashScreen = false
        this.$emit('show-on-boarding')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.load-state {
  position: absolute;
  top: 50%;
  left: 50%;
}

.worlds {
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0 !important;
  margin: 0;
  margin-top: 4.4rem;

  &.--has-teacher-bar {
    grid-template-rows: max-content 88px 1fr
  }

  .hide-on-desktop {
    margin-bottom: -5px;

    @include mq-media--mf(680px){
      display: none;
    }
  }

  &:before, &::after {
    @include pseudo;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 60%;
    height: 25%;
    z-index: 0;
    opacity: 0.25;
    pointer-events: none;
  }

  &:before {
    background-image: url('~@/assets/wave-top.svg');
    background-position: top left;
    left: 0;
  }

  &.--adapt {
    &:before {
      top: 0;
    }
  }

  &:after {
    background-image: url('~@/assets/wave-bottom.svg');
    background-position: bottom right;
    bottom: 0;
    right: 0;
  }

  &__header, &__content {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  &__header {
    width: 100%;
    z-index: 101;
    padding: 8px;
    position: relative;
    justify-content: space-between;
    flex-direction: column;

    @include mq-media--mf(680px){
      flex-direction: row;
    }

    @include mq_l__mf {
      flex-direction: row;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: $size-xs $size-s;
    }

    &__logo {
      width: 120px;

      @include mq-media--mf(680px){
        display: block;
        width: 150px;
      }

      @include mq_l__mf {
        width: 180px;
      }
    }
  }

  &__content {
    height: calc(100% - 130px);
    width: 100%;
    overflow: hidden;
  }
}

.--mfe-margin {
  margin-top: 0;
  height: 94vh;
}

.g-loader {
  @include float-center;
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>
