import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'

function invalidKeys(object, keys) {
  return keys.some((key) => !(key in object))
}

function invalidValues(object) {
  return !Object.values(object).some((value) => !isEmpty(value))
}

export default function isValidObjectAndValues(object, keys) {
  if (isEmpty(object)) {
    return false
  }

  const pickedObject = pick(object, keys)
  if (invalidKeys(object, keys) || invalidValues(pickedObject)) {
    return false
  }

  return true
}
