<template>
  <ScrollContainer class="mission-report-body">
    <transition
      v-if="animationModal"
      name="mission-modal"
      mode="out-in"
      appear
    >
      <div class="item-modal">
        <img
          class="mission-modal"
          width="220"
          height="220"
          :src="require('@/assets/slot/goldenMagnifier.svg')"
        >
        <h1 class="item-modal__text mission-modal">
          {{ $t('game.items.goldenMagnifier.name') }}
        </h1>
      </div>
    </transition>
    <section class="mission-report-body__content">
      <MissionReportHeader
        class="mission-report-body__content__header"
        :title="report.data.title"
        :subtitle="report.data.subtitle"
        :order="report.data.chapterOrder"
      />
      <img
        class="mission-report-body__report-divider"
        :src="require('@/assets/report-divider.svg')"
      >
      <div class="mission-report-body__content__score">
        <NewRecordCard class="mission-report-body__content__score__card" />
        <MissionReportCard :title="$t('game.report.yourScore')">
          <template #customStyle>
            <div class="mission-report-body__content__slot-score">
              <b>{{ `${correctAnswers} / ${totalQuestionsAssignments}` }}</b>
              <img :src="require(`@/assets/diamonds/gems-${gems}.svg`)">
            </div>
          </template>
        </MissionReportCard>
      </div>
      <div
        v-if="coins || xp"
        class="mission-report-body__content__conquests"
      >
        <MissionReportCard
          :title="$t('game.report.rewardsLabel')"
        >
          <template #gridStyle>
            <g-block
              v-if="coins"
              :class="[ animationModal ? '--overlay' : '']"
              class="coins"
              asset="coin.svg"
              theme="bright"
            >
              {{ `+${coins}` }}
            </g-block>
            <g-block
              v-if="xp"
              asset="xp.svg"
              theme="bright"
            >
              {{ `+${xp}` }}
            </g-block>
            <div
              v-for="reward in rewardItemsOnMission"
              :key="reward.id"
              class="item-reward"
            >
              <img
                width="38"
                :src="reward.item.image.svg"
              >
              <span
                v-if="reward.quantity > 1"
                class="item-reward__badge"
              >
                {{ `+${reward.quantity}` }}
              </span>
            </div>
          </template>
        </MissionReportCard>
      </div>
      <div
        v-if="usedItemsOnMission.length && !animationModal"
        class="mission-report-body__content__items"
      >
        <img
          class="mission-report-body__content__report-divider"
          :src="require('@/assets/report-divider.svg')"
        >
        <MissionReportCard
          :title="$t('game.report.usedItems')"
        >
          <template #gridStyle>
            <div
              v-for="(usedItem, index) in usedItemsOnMission"
              :key="index"
            >
              <img
                v-tooltip.bottom="usedItem.item.description"
                class="used-item-image"
                :src="usedItem.item.image.svg"
                width="60"
                height="60"
              >
            </div>
          </template>
        </MissionReportCard>
      </div>
    </section>
    <GModal
      v-if="animationModal"
      :close-button-enabled="false"
    />
  </ScrollContainer>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

import GBlock from '@/components/GBlock'
import GModal from '@/components/GModal'
import ScrollContainer from '@/components/ScrollContainer'
import MissionReportCard from './MissionReportCard'
import MissionReportHeader from './MissionReportHeader'
import NewRecordCard from './NewRecordCard'

export default {
  name: 'MissionReportBody',
  components: {
    MissionReportCard,
    MissionReportHeader,
    GBlock,
    NewRecordCard,
    GModal,
    ScrollContainer,
  },
  data() {
    return {
      coinsModify: false,
      animationModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'report',
    ]),
    coins() {
      if (!this.coinsModify) {
        return this.report.data.mission.coinQuantityWithoutReward
      }

      return this.report.data.mission.coinQuantity
    },
    xp() {
      return this.report.data.mission.experienceAmount
    },
    gems() {
      return this.report.data.mission.gems
    },
    correctAnswers() {
      return this.report.data.correctAnswers
    },
    totalQuestionsAssignments() {
      return this.report.data.correctAnswers + this.report.data.wrongAnswers
    },
    mission() {
      return this.report.data.mission
    },
    rewardItemsOnMission() {
      if (this.mission.rewardItems.length) {
        return this.mission.rewardItems
      }

      return []
    },
    usedItemsOnMission() {
      if (this.mission.usedItems) {
        return this.mission.usedItems.filter(({ item }) => item.id)
      }

      return []
    },
  },
  mounted() {
    const { params } = this.$route
    if (
      this.mission.coinQuantity
      !== this.mission.coinQuantityWithoutReward
      && params.shouldPlayAnimation
    ) {
      setTimeout(() => { this.startAnimation() }, 500)
    }
  },
  methods: {
    changeCoinAmount() {
      this.coinsModify = true
    },
    toggleAnimationModal() {
      this.animationModal = !this.animationModal
    },
    startAnimation() {
      this.toggleAnimationModal()
      const tl = gsap.timeline()
      tl.to('.coins', {
        duration: 1,
        scale: 1,
        x: -185,
        y: -100,
        ease: 'elastic.in',
      })
        .to('.coins', {
          duration: 0.5,
          scale: 1.5,
          filter: 'drop-shadow(0px 0px 30.6667px rgba(255, 184, 28, 0.9))',
          onComplete: this.changeCoinAmount,
        }, '+=0.5')
        .to('.coins', {
          duration: 0.5,
          scale: 1,
          filter: 'drop-shadow(0px 0px 30.6667px rgba(255, 184, 28, 0.0))',
        }, '+=1')
        .to('.coins', {
          duration: 1,
          x: 0,
          y: 0,
          ease: 'elastic.in',
        }, '+=0.5')
        .to('.coins', {
          onComplete: this.toggleAnimationModal,
        })
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-report-body
  max-height: 590px

  &__content
    +flex-column-center

    &__score
      &__card
        margin-top: 34px

    &__header
      margin:
        top: 8px
        bottom: $size-l

    &__slot-score
      font-size: $size-xl
      +flex-column-center

    &__conquests
      width: auto

      ::v-deep .mission-report-card
        &__title
          margin-bottom: 0

      .g-block
        width: max-content
        margin:
          right: 13px
          top: 13px

    &__report-divider
      margin-top: $size-xl

    &__items

      ::v-deep .mission-report-card
        &__title
          margin:
            top: 0
            bottom: $size-s

.--overlay
  z-index: 10000

.mission-modal
  +transition($speed-normal)

  &-enter
    transition-duration: $speed-normal

    .mission-modal
      transform-origin: center
      transform: translateY(15%)
      opacity: 0

      &-overlay
        opacity: 0

  &-leave-to
    transition-duration: $speed-slow

    .mission-modal
      transition-duration: $speed-slow
      transform-origin: center
      transform: translateY(15%)
      opacity: 0

      &-overlay
        transition-duration: $speed-slow
        opacity: 0

.used-item-image
  margin-right: 13px

.item-modal
  position: absolute
  left: 40%
  bottom: 40%
  z-index: 10000

  &__text
    color: $color-white
    font-weight: 800
    line-height: 36px
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.65)

.item-reward
  height: 42px
  width: max-content
  margin-right: 13px
  margin-top: 14px
  position: relative

  &__badge
    position: absolute
    top: 24px
    left: 25px
    padding: 2px
    width: 21px
    height: 21px
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3)
    background: $color-white
    border-radius: 40px
    color: $eureka-color-ink
    font-size: 12px
    align-items: center
    text-align: center
    font-weight: 700
</style>
