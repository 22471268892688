<template>
  <div class="arena-player-list">
    <header class="arena-player-list__header">
      <p class="arena-player-list__text --has-shadow">
        {{ classroom }}
      </p>
      <div class="arena-player-list__info">
        <img
          class="arena-player-list__icon"
          :src="userIcon"
          alt="Ícone de usuário"
        >
        <p
          :class="[
            'arena-player-list__text',
            '--medium',
            {'--blur-effect' : hasHighlightInformation}]"
        >
          {{ totalAmountPlayer }}
        </p>
      </div>
    </header>
    <div class="arena-player-list__content">
      <PlayerAccordion
        text="Entraram"
        :players-amount="loggedStudentsAmount"
      >
        <ul>
          <li
            v-for="(student) in loggedStudents"
            :key="student.id"
          >
            <ArenaPlayer
              :student="student"
            />
          </li>
        </ul>
      </PlayerAccordion>
      <PlayerAccordion
        text="Não Entraram"
        :players-amount="notLoggedStudentsAmount"
        :initial-visible="true"
      >
        <ul>
          <li
            v-for="(student) in notLoggedStudents"
            :key="student.id"
          >
            <ArenaPlayer :student="student" />
          </li>
        </ul>
      </PlayerAccordion>
    </div>
  </div>
</template>

<script>
import userIconOutline from '@/assets/user-outline.svg'
import userFilled from '@/assets/user-filled.svg'
import PlayerAccordion from '@/components/Accordion/PlayerAccordion'
import ArenaPlayer from './ArenaPlayer'

export default {
  name: 'ArenaPlayerList',
  components: {
    PlayerAccordion,
    ArenaPlayer,
  },
  props: {
    totalAmountPlayer: {
      type: String,
      default: '',
    },
    classroom: {
      type: String,
      default: '',
    },
    loggedStudents: {
      type: Array,
      default: () => [],
    },
    hasHighlightInformation: Boolean,
    notLoggedStudents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userIconOutline,
      userFilled,
    }
  },
  computed: {
    userIcon() {
      return this.hasHighlightInformation ? this.userFilled : this.userIconOutline
    },
    loggedStudentsAmount() {
      return this.loggedStudents.length
    },
    notLoggedStudentsAmount() {
      return this.notLoggedStudents.length
    },
  },
}
</script>

<style lang="scss" scoped>
.arena-player-list {
  background-color: rgba(#0A0A0A, 0.3);
  border-radius: 16px;

  &__header {
    @include flex-space-between;
    padding: $size-m 5%;
    border-bottom: 2px solid rgba($color-white, 0.32);
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  &__text {
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    line-height: 100%;
    color: $color-white;
    font-size: $font-size-heading-4;

    &.--medium {
      font-size: $font-size-heading-5;
    }

    &.--large {
      font-size: $font-size-heading-4;
    }

    &.--blur-effect {
       text-shadow: 0 0 20px $color-white;
    }

    &.--has-shadow {
      text-shadow: 0 1px 0 $color-black;
    }

    @media screen and (max-width: 579px) {
      font-size: $font-size-heading-6;
    }
  }

  &__content {
    height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    scrollbar-width: thin;
    scrollbar-color: $eureka-color-base-light #0d042552;
    border-radius: $size-s;

    @media screen and (max-width: 768px) {
      height: calc(100vh - 300px);
    }

    @media screen and (max-width: 579px) {
      height: calc(100vh - 325px);
    }
  }
}
</style>
