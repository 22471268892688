<template>
  <section
    :class="[$style.container, { [$style.mfeContainer]: isMicrofrontend }]"
  >
    <div
      v-if="showCardList"
      id="questionCardList"
      :class="$style.card"
      @scroll="onScroll"
    >
      <template v-if="questions.isLoading">
        <MagnaMissionSASQuestionPreviewCardSkeleton
          v-for="question in mappedQuestions"
          :key="question.id"
        />
      </template>
      <template v-else>
        <MagnaMissionSASQuestionPreviewCard
          v-for="question in mappedQuestions"
          :key="question.id"
          :question-number="question.indexSelected + 1"
          :statement="question.statement"
          :grade="question.grade.name"
          :difficulty="question.difficultyLevel"
          :is-selected="isSelectedQuestion(question)"
          :is-focused="question.isFocused"
          :question="question"
          :data-card-id="question.id"
          @on-click="selectQuestion(question)"
        />
      </template>
    </div>
  </section>
</template>

<script>
import userAgent from '@/utils/userAgent'
import handleResize from '@/mixins/handleResize'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'
import MagnaMissionSASQuestionPreviewCard from './partials/MagnaMissionSASQuestionPreviewCard'
import MagnaMissionSASQuestionPreviewCardSkeleton from './partials/MagnaMissionSASQuestionPreviewCardSkeleton'

const DEFAULT_WIDTH = 1024

const orientationEnum = {
  PORTRAIT: 'portrait-primary',
  LANDSCAPE: 'landscape-primary',
}

export default {
  name: 'MagnaMissionSASQuestionsList',
  components: {
    MagnaMissionSASQuestionPreviewCard,
    MagnaMissionSASQuestionPreviewCardSkeleton,
  },
  mixins: [ handleResize ],
  props: {
    questions: {
      type: Object,
      required: true,
      default: () => ({
        isLoading: true,
        data: null,
        error: null,
      }),
    },
  },
  data() {
    return {
      isMicrofrontend: false,
      hideCardList: false,
      currentPage: 0,
      selectedQuestionId: null,
    }
  },
  computed: {
    isDefaultWidth() {
      return window.innerWidth >= DEFAULT_WIDTH
    },
    showCardList() {
      if (!this.isTablet) return true
      if (!this.hideCardList) return true
      if (this.hideCardList) return false

      return true
    },
    isTablet() {
      return userAgent.isTablet()
    },
    mappedQuestions() {
      return this.questions.data?.list ?? []
    },
  },
  watch: {
    isDefaultWidth: {
      immediate: true,
      handler() {
        this.hideCardList = !this.isDefaultWidth
      },
    },
  },
  beforeMount() {
    this.isMicrofrontend = getIsMicrofrontend()
  },
  created() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.cardListOrientation)
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.cardListOrientation)
  },
  methods: {
    isSelectedQuestion(question) {
      return question.isSelected ?? false
    },
    cardListOrientation() {
      const orientationType = window.screen.orientation.type
      if (orientationType === orientationEnum.PORTRAIT) {
        this.hideCardList = true
      } else if (orientationType === orientationEnum.LANDSCAPE) {
        this.hideCardList = false
      }
    },
    selectQuestion(question) {
      this.$emit('on-set-preview-question', question)
    },
    async handleScrollPage() {
      this.$emit('scroll-page')
    },
    onScroll({
      target: {
        scrollTop, scrollLeft, clientHeight, clientWidth, scrollHeight, scrollWidth,
      },
    }) {
      if (this.isMediumScreen) {
        if (scrollLeft + clientWidth >= scrollWidth) {
          this.handleScrollPage()
        }
      } else if (scrollTop + clientHeight >= scrollHeight) {
        this.handleScrollPage()
      }
    },
  },
}
</script>

<style lang="scss" module>
.card {
  border-radius: $size-s;
  background-color: $eureka_color_base;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 380px;
  scrollbar-width: thin;
  gap: $size-s;

  @media screen and (max-width: 1200px) {
    height: calc(100vh - 200px);
    width: 350px;
  }

  @media screen and (max-width: 768px) {
    gap: $size-xs;
    flex-direction: row;

    width: 100%;
    height: fit-content;
    padding: $size-xs;
  }
}
.container {
  display: flex;
  scrollbar-color: $eureka-color-base-darker $eureka-color-base-light;
  scrollbar-width: auto;
  height: 100%;

  @media screen and (max-width: 991px) {
    display: none;
  }

  ::-webkit-scrollbar {
    width: .75rem;
    height: $size-xs;
    border-radius: $border-radius-s;

    &-button {
      height: 0;
    }

    &-track {
      border-radius: $border-radius-s;
      background: rgba($eureka-color-base-dark, 1);
      margin: $size-s;
    }
    &-thumb {
      background: $eureka-color-base-darker;
      box-shadow: inset -1px -4px 0px #cca56d;
      border-right: $border-radius-xs $eureka-color-base-dark solid;
      border-radius: $size-s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.mfeContainer {
  height: 94.5%;
}

</style>
