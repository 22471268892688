<template>
  <div class="lectures-performance">
    <div class="lectures-performance__container">
      <img
        class="lectures-performance__container__image"
        :src="require(`@/assets/world-thumbs/${getGradeId()}/${imageName}.svg`)"
      >
      <div class="lectures-performance__container__body">
        <div class="lectures-performance__container__body__description">
          <h5>{{ name }}</h5>
        </div>
        <div class="lectures-performance__container__body__diamond-bar">
          <img
            width="40"
            height="40"
            class="xp-display__icon"
            src="@/assets/diamond.svg"
          >
          <ResourceBar
            data-testid="level"
            :value="conqueredGems"
            :total="totalGems"
            variation="blue"
          >
            <div class="score-amount">
              {{ conqueredGems }} / {{ totalGems }}
            </div>
          </ResourceBar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getGradeId from '@/mixins/getGradeId'
import ResourceBar from '@/components/ResourceBar'

const Lectures = [
  { type: 'science', name: 'Ciências' },
  { type: 'math', name: 'Matemática' },
  { type: 'portuguese', name: 'Língua Portuguesa' },
  { type: 'physics', name: 'Física' },
  { type: 'chemistry', name: 'Química' },
  { type: 'biology', name: 'Biologia' },
  { type: 'history', name: 'História' },
  { type: 'geography', name: 'Geografia' },
]

export default {
  name: 'LecturesPerformance',
  components: {
    ResourceBar,
  },
  mixins: [ getGradeId ],
  props: {
    name: {
      type: String,
      default: '',
    },
    conqueredGems: {
      type: Number,
      default: 0,
    },
    totalGems: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    imageName() {
      const [ slugName ] = Lectures.filter(
        ({ name }) => name === this.name
      )

      return slugName?.type
    },
  },
}
</script>

<style lang="scss" scoped>
.lectures-performance {
  background: #FBF1E1;
  border-radius: $size-s;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.19), 0 4px 4px #C19267;

  &__container {
    display: grid;
    grid-template-columns: auto 1fr;

    &__image {
      border-top-left-radius: $size-s;
      border-bottom-left-radius: $size-s;
    }

    &__body {
      margin-left: 1rem;
      margin-top: .5rem;

      &__description {
        margin-top: .5rem;
        color: $game-ink;
        font: {
          weight: $font-weight-bold;
          size: $font-size-heading-5;
        }
        line-height: 1;
      }

      &__diamond-bar {
        position: relative;
        padding: 0 1rem;
        margin-top: .5rem;
      }
    }
  }
}
.xp-display__icon {
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 2;
}

.score-amount {
  text-shadow: 0 2px 0 #31261D;
}
</style>
