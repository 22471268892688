import api from './api'
import { apiBff } from './apiBff'

export default {
  finishAssignment: (assignmentCode) => {
    const data = {
      status: 'finished',
    }

    return api.put(`/assignments/${assignmentCode}`, data)
  },
  finishAssignments: (questionnaireCode) => (
    api.put(`/questionnaires/${questionnaireCode}/finish-assignments`)
  ),
  putHomeAssignment: (assignmentCode, body) => (
    apiBff.put(`/assignments/${assignmentCode}`, body)
  ),
  finishAssignmentMission: (assignmentCode) => {
    const data = {
      status: 'finished',
    }

    return apiBff.put(`/assignments/${assignmentCode}/finish`, data)
  },
}
