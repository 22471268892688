<template>
  <div class="stats-card">
    <skeleton-loader
      class="stats-card__header__title"
      width="124px"
      height="12px"
      opacity="0.5"
      border-radius="35px"
    />
    <skeleton-loader
      class="stats-card__content"
      width="103px"
      height="23px"
      opacity="0.5"
      border-radius="35px"
    />
  </div>
</template>

<script>
export default {
  name: 'StatsCardSkeleton',
}
</script>

<style lang="sass" scoped>
.stats-card
  background: #FBF1E1
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.19), 0px 4px 4px #C19267
  border-radius: $size-s
  padding: 12px $size-s

  &__content
    margin-top: $size-xs

  ::v-deep .sas-skeleton-loader
    background: #CBA976
</style>
