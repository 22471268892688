<template>
  <CineMode @skip="$emit('skip')">
    <div class="transition-scene">
      <h1>
        {{ scene.transitionText }}
      </h1>
    </div>
  </CineMode>
</template>

<script>
import CineMode from '@/components/Adventure/CineMode'

export default {
  name: 'TransitionScene',
  components: {
    CineMode,
  },
  props: {
    scene: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    scene: {
      handler() {
        if (this.scene.last) {
          this.$emit('is-last-scene', this.scene)
        } else {
          this.$emit('go-to-next-scene-from-transition', this.scene)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.transition-scene
  +flex-center
  width: 100%
  height: 100%
  background-color: #000
  color: $color-white
  animation: fade 4s

@keyframes fade
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0
</style>
