<template>
  <div class="game-card">
    <div class="game-card__avatar">
      <div class="game-card__avatar__container">
        <img
          class="game-card__avatar__container--image"
          :class="{ 'is-locked': isLocked }"
          :src="img"
        >
      </div>
      <slot name="gems" />
    </div>

    <div class="game-card__info">
      <div class="game-card__info--title">
        <p class="title">
          {{ episodeTitle }}
        </p>
        <span
          v-if="!isLocked && isNew"
          class="badge"
        >
          {{ $t('game.adventure.new') }}
        </span>
      </div>
      <div class="game-card__info--reward">
        <slot />
      </div>
    </div>

    <div class="game-card__action">
      <GButton
        v-if="!isLocked"
        class="game-card__button"
        size="medium"
        asset="play-icon.svg"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'GameCard',
  components: {
    GButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    number: {
      type: Number,
      default: null,
    },
    img: {
      type: String,
      default: '',
    },
    isLocked: {
      type: Boolean,
      default: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    episodeTitle() {
      const { number, title } = this

      return number > 0 ? `${number}. ${title}` : title
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .g-button {
  border: none !important;
  height: 45px !important;
  border-radius: 50% !important;
  width: 45px;

  &__image {
    margin-right: 0 !important;
  }
}

.game-card {
  display: flex;
  gap: 16px;
  background: $eureka-color-base-light;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.19), 0px 4px 4px #C19267;
  border-radius: $size-s;
  width: 100%;
  height: 107px;

  @media screen and (max-width: 479px) {
    font-weight: 600;
    flex-direction: column;
    height: 100%;
    max-height: 50vh;
    gap: 0px;
  }

  &__avatar {
    position: relative;

    .wallet-display {
      font-weight: 600;
      position: absolute;
      font-weight: 600;
      margin-top: -35px;
      margin-left: 10px;

      @media screen and (max-width: 479px) {
        margin-top: -40px;
      }
    }

    &__container {
      width: 122px;
      height: 107px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 479px) {
        width: 100%;
        height: 20vh;
      }

      &--image {
        border-top-left-radius: $size-s;
        background-color: #ECDDC9;
        border-bottom-left-radius: $size-s;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @media screen and (max-width: 479px) {
          border-top-right-radius: $size-s;
          border-top-left-radius: $size-s;
          border-bottom-left-radius: 0;
        }

      }
      .is-locked {
        object-fit: contain;
      }
    }
  }

  &__info {
    width: 100%;
    gap: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px 0 16px 0;

    @media screen and (max-width: 479px) {
      padding: 12px 8px 0;
    }

    &--title {
      width: 100%;
      gap: 4px;
      flex: 1;
      display: flex;
      align-items: center;

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: $eureka-color-ink;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .badge {
        height: fit-content;
        padding: 0 4px;
        color: $color-white;
        background: $eureka-color-notice;
        border-radius: 12px;
        font-size: 12px;
        line-height: 150%;
        text-shadow: 0px 1px 0px #000000;
      }
    }
  }

  &__action {
    margin: auto 16px auto auto;
    min-height: 42px;

    @media screen and (max-width: 768px) {
      margin: auto 12px auto auto;
      .g-button {
        width: 32px !important;
        height: 32px !important;
      }
      ::v-deep .g-button__image {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }
}

</style>
