import { mapActions } from 'vuex'

export default {
  created() {
    if (!this.theme.success && this.$route.params.lectureId) {
      this.getTheme(parseInt(this.$route.params.lectureId, 10))
    }
  },
  methods: {
    ...mapActions([
      'getTheme',
    ]),
  },
}
