<template>
  <section class="question-selector-list">
    <div
      v-show="isShowButtons"
      :class="[
        'question-selector-list__buttons',
        'question-selector-list__btn-prev',
        { '--active': hasPrev, '--inactive': !hasPrev },
      ]"
      aria-label="voltar questões"
      role="button"
      @click="onPrev"
    >
      <icon
        class="question-selector-list__icon"
        type="chevron-left"
        stroke-width="4"
      />
    </div>
    <VueHorizontal
      ref="horizontal"
      :class="[
        'question-selector-list__container',
        { '--has-buttons': isShowButtons, }
      ]"
      :button="false"
      @scroll-debounce="onScroll"
    >
      <QuestionSelector
        v-for="question in questions"
        :id="question.id"
        :key="question.id"
        class="question-selector-list__item"
        :text="question.text"
        :is-answered="question.isAnswered"
        :is-selected="question.isSelected"
        @click="handleClick"
      />
    </VueHorizontal>
    <div
      v-show="isShowButtons"
      :class="[
        'question-selector-list__buttons',
        'question-selector-list__btn-next',
        { '--active': hasNext, '--inactive': !hasNext },
      ]"
      role="button"
      aria-label="avançar questões"
      @click="onNext"
    >
      <icon
        class="question-selector-list__icon"
        type="chevron-right"
        stroke-width="4"
      />
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash-es'
import QuestionSelector from '../QuestionSelector/QuestionSelector'

export default {
  name: 'QuestionSelectorList',
  components: {
    QuestionSelector,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    initialQuestionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hasPrev: false,
      hasNext: false,
      isShowButtons: false,
      hasScrolledToInitialQuestion: null,
    }
  },
  watch: {
    initialQuestionIndex(index) {
      if (this.hasScrolledToInitialQuestion) return

      const isValidIndex = !isNil(index) && (index >= 0 && index < this.questions?.length)
      if (!isValidIndex) return

      this.hasScrolledToInitialQuestion = true
      this.scrollToIndex(index)
    },
  },
  mounted() {
    this.updateButtonVisibility()
    window.addEventListener('resize', this.updateButtonVisibility)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateButtonVisibility)
  },
  methods: {
    onScroll({ hasPrev, hasNext }) {
      this.hasPrev = hasPrev
      this.hasNext = hasNext

      this.updateButtonVisibility()
    },
    updateButtonVisibility() {
      const containerWidth = this.$refs.horizontal?.$el?.clientWidth ?? 0
      const contentWidth = this.$refs.horizontal?.$el?.scrollWidth ?? 0

      this.isShowButtons = this.hasPrev || this.hasNext || containerWidth < contentWidth
    },
    handleClick(id) {
      this.$emit('click', { id })
    },
    onPrev() {
      if (this.hasPrev) {
        this.$refs.horizontal.prev()
      }
    },
    onNext() {
      if (this.hasNext) {
        this.$refs.horizontal.next()
      }
    },
    scrollToIndex(index) {
      this.scrollVueHorizontalToIndex(index)
    },
    async scrollVueHorizontalToIndex(index) {
      await this.$nextTick()
      this.$refs.horizontal.scrollToIndex(index)
    },
  },
}
</script>

<style lang="scss" scoped>
.question-selector-list {
  max-width: calc(100% - 140px);
  display: inline-flex;
  align-items: center;
  flex: 1;

  &__container {
    display: inline-flex;
    padding: $size-xxs $size-xxs;
    width: 100%;
    max-width: 100%;

    &.--has-buttons {
      width: calc(100% - 100px);
    }
  }

  &__icon {
    ::v-deep &.feather {
      width: 27px;
      height: 27px;
    }
  }

  &__buttons {
    display: inline-flex;
    color: $color-white;
    cursor: pointer;
    padding: $size-xs 6px;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;

    &.--inactive {
      color: rgba($color-white, 0.5);
      cursor: not-allowed;
    }

    &.--active {
      color: $color-white;
    }
  }
}
</style>
