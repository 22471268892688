<template>
  <GModal
    class="quit-mission-modal"
    :close-button-enabled="false"
    @close="$emit('cancel')"
  >
    <div class="quit-mission-modal__content">
      <div class="quit-mission-modal__text">
        <h3 class="quit-mission-modal__text__title">
          {{ $t('game.quitMission.title') }}
        </h3>
        <div class="quit-mission-modal__text__message">
          {{ $t('game.quitMission.message') }}
        </div>
      </div>
      <img
        class="quit-mission-modal__avatar"
        src="@/assets/char/char-quit.svg"
      >
      <div class="quit-mission-modal__controls">
        <a
          class="quit-mission-modal__controls__cancel"
          @click="$emit('cancel')"
        >
          {{ $t('game.quitMission.cancel') }}
        </a>
        <g-button
          class="quit-mission-modal__controls__ok"
          variation="danger"
          size="large"
          @click="$emit('ok')"
        >
          {{ $t('game.quitMission.ok') }}
        </g-button>
      </div>
    </div>
  </GModal>
</template>

<script>
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'

export default {
  name: 'QuitMissionModal',
  components: {
    GModal,
    GButton,
  },
}
</script>

<style lang="sass" scoped>
.quit-mission-modal
  &__content
    max-width: 480px
    padding: 32px 24px 24px 32px
    flex-wrap: wrap
    display: flex
    justify-content: space-between
    align-items: flex-end

  &__text
    flex-basis: 50%
    flex-grow: 1

    &__title
      font-weight: 800
      font-size: 28px
      margin-bottom: 24px

    &__message
      font-weight: 500
      font-size: 18px

  &__controls
    margin-top: 28px
    width: 100%
    +flex-space-between

    &__cancel
      color: #736558
      display: block
      font-weight: 800
      font-size: 20px
      text-decoration-line: underline
      cursor: pointer
      +transition($speed-x-fast)

      &:hover
        color: #382517

    &__ok
      width: 170px

  &__avatar
    display: block
    margin-left: 20px
    margin-top: -84px

    @media screen and (max-width: 479px)
      margin: auto
</style>
