var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
    'g-button',
    `--${_vm.size}`,
    `--${_vm.variation}`,
    {
      '--icon-only' : _vm.icon || _vm.iconOnly,
      '--active' : _vm.active,
      '--loading' : _vm.loading,
      '--no-border': _vm.noBorder,
      '--rowdies': _vm.rowdies,
      '--disabled' : _vm.disabled,
    }
  ],attrs:{"disabled":_vm.disabled,"href":_vm.href},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('spinner',{staticClass:"g-button__spinner",attrs:{"custom-size":"75%"}}):_vm._e()],1),(_vm.iconLeft || _vm.icon || _vm.iconRight)?_c('div',{class:[
      'g-button__icon',
      `--${_vm.size}`,
      { '--left' : _vm.iconLeft },
      { '--right' : _vm.iconRight },
    ]},[_c('icon',{attrs:{"type":_vm.iconLeft || _vm.icon || _vm.iconRight,"stroke-width":_vm.getIconStroke()}})],1):_vm._e(),(!_vm.icon)?[(_vm.asset)?_c('img',{class:[
        'g-button__image',
        `--${_vm.size}`,
      ],attrs:{"src":require(`@/assets/${_vm.asset}`),"alt":""}}):_vm._e(),_c('b',{staticClass:"g-button__text"},[_vm._t("default")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }