import { Howl } from 'howler'

import { soundTypes, backgroundTypes } from './types'

export const effectsNames = Object.keys(soundTypes).reduce(
  (acc, soundType) => ({
    ...acc,
    [soundType.replace(/([A-Z])/g, '_$1').toUpperCase()]: soundType,
  }), {}
)

export const loadEffects = Object.keys(soundTypes).reduce(
  (acc, soundType) => ({
    ...acc,
    [soundType]: new Howl({ src: soundTypes[soundType] }),
  }), {}
)

export const backgroundNames = Object.keys(backgroundTypes).reduce(
  (acc, soundType) => ({
    ...acc,
    [soundType.replace(/([A-Z])/g, '_$1').toUpperCase()]: soundType,
  }), {}
)

export const loadBackgrounds = Object.keys(backgroundTypes).reduce(
  (acc, soundType) => ({
    ...acc,
    [soundType]: new Howl({
      src: backgroundTypes[soundType],
      autoUnlock: true,
      loop: true,
    }),
  }), {}
)

export const loadExternalBackground = (url) => new Howl({
  src: url,
  autoUnlock: true,
  loop: true,
})

export const loadExternalEffects = (url) => new Howl({
  src: url,
})
