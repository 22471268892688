<template>
  <GModal
    v-if="visible"
    :close-on-click-outside="false"
    @close="hide"
  >
    <div class="redo-confirmation">
      <h3 class="redo-confirmation__title">
        {{ report.data.title }}
      </h3>
      <p class="redo-confirmation__description">
        {{ report.data.subtitle }}
      </p>
      <div class="redo-confirmation__video">
        <transition
          name="fade"
        >
          <div
            v-if="!video.loading"
            class="redo-confirmation__body"
          >
            <div
              v-if="!video.error"
              class="redo-confirmation__body__video"
              v-html="video.data.embedHtml"
            />
          </div>
          <div
            v-else
            class="redo-confirmation__body"
          >
            <skeleton-loader
              class="redo-confirmation__body__skeleton"
              width="350px"
              height="190px"
              border-radius="12px"
            />
          </div>
        </transition>
      </div>
      <div
        v-if="missionItems.length"
        class="redo-confirmation__items"
      >
        <div
          v-if="!video.error"
          class="report-divider"
        >
          <img :src="require('@/assets/report-divider.svg')">
        </div>
        {{ $t('studentReport.confirm.activeItem') }}
        <div
          v-for="allowedItem in missionItems"
          :key="allowedItem.item.id"
          class="redo-confirmation__items__list"
        >
          <ItemThumbnail
            class="redo-confirmation__items__list--thumbnail"
            :bag-item="allowedItem"
            :border="false"
            :already-used="alreadyUsedItem(allowedItem)"
            :loading="bagItems.loading"
            @click="handleItemEffect(allowedItem)"
          />
        </div>
        <div class="report-divider">
          <img :src="require('@/assets/report-divider.svg')">
        </div>
      </div>
      <footer
        class="redo-confirmation__footer"
      >
        <g-button
          size="large"
          variation="secondary"
          @click="hide"
        >
          {{ $t('studentReport.confirm.cancel') }}
        </g-button>
        <g-button
          size="large"
          icon-left="repeat"
          @click="confirm"
        >
          {{ $t('studentReport.confirm.ok') }}
        </g-button>
      </footer>
    </div>
  </GModal>
</template>

<script>
import { playSoundFX } from '@/utils/soundEffects'
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'
import ItemThumbnail from '@/components/Item/ItemThumbnail/ItemThumbnail'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as types from '@/store/items/types'
import trackPlayerEvent from '@/mixins/trackPlayerEvent'

export default {
  name: 'RedoConfirmation',
  components: {
    GModal,
    GButton,
    ItemThumbnail,
  },
  mixins: [ trackPlayerEvent ],
  data() {
    return {
      visible: false,
      items: [],
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'report',
      'video',
      'bagItems',
      'usedItems',
    ]),
    missionItems() {
      if (this.bagItems.data?.length) {
        const missionItems = this.bagItems.data.filter(
          ({ item }) => item.useAtMission
        )

        return missionItems
      }

      return []
    },
  },
  watch: {
    video: {
      immediate: true,
      handler() {
        if (this.video.data) {
          const chapter = parseInt(this.$route.params.chapterId, 10)

          this.setupPlayerEvents({ chapter })
        }
      },
    },
  },
  methods: {
    ...mapActions([ 'getBagItems' ]),
    ...mapMutations({
      addMissionItem: types.HANDLE_MISSION_ITEM,
    }),
    show() {
      this.getBagItems()
      this.visible = true
    },
    hide() {
      playSoundFX()
      this.visible = false
    },
    confirm() {
      playSoundFX()
      this.$emit('confirm', this.items)
      this.hide()
    },
    alreadyUsedItem(allowedItem) {
      if (!this.usedItems.loading && this.usedItems.data.length) {
        const { id } = allowedItem.item
        const [ itemMission ] = this.usedItems.data.filter(
          ({ category }) => category === 'MISSION'
        )

        return itemMission.items.some((item) => item.id === id)
      }

      return false
    },
    handleItemEffect(payload) {
      const { id } = payload.item

      if (this.items.includes(id)) {
        this.items.pop(this.items.indexOf(id))
      } else {
        this.items.push(id)
      }

      this.addMissionItem(payload.item)
    },
  },
}
</script>

<style lang="sass" scoped>
::v-deep .g-frame
  background: #FBF1E1

  &__inner
    border: 2px solid rgba(203, 169, 118, 0.5)

.redo-confirmation
  padding: 24px

  &__title
    color: #9F7752
    font-weight: bold
    text-align: center
    margin-bottom: 8px
    font-size: 18px
    line-height: 18px

  &__description
    text-align: center
    font-weight: $font-weight-extra-bold
    font-size: 28px
    line-height: 110%

  &__body
    width: 400px
    padding: 32px 28px
    +flex-center
    flex-wrap: wrap

    &__video
      +proportional-video
      flex: 1 0 100%
      height: 0
      background: transparentize(#382517, 0.85)
      filter: drop-shadow(0px 2px 0px #CD8747)
      border-radius: 11px

      ::v-deep iframe
        +proportional-video-iframe

  &__items
    font-weight: bold
    font-size: 16px
    line-height: 125%
    text-align: center

    &__list
      display: flex
      align-items: center
      justify-content: center
      border:
        top: 16px
        bottom: 16px

      ::v-deep .item-thumbnail
          box-shadow: none
          background: #fdfbf0

          &:hover
            box-shadow: none
            transform: none

  &__footer
    margin-top: 32px
    +flex-center

    .g-button
      +space-inline(24px)

.report-divider
  text-align: center
  margin:
    top: 10px
    bottom: 10px
</style>
