import {
  loadEffects,
  loadExternalEffects
} from '@/service/soundController/loadSounds'

export default class SoundController {
  static instance = null

  background = null

  soundFX = null

  disabledSound = false

  disabledBackground = false

  static getInstance() {
    if (SoundController.instance === null) {
      SoundController.instance = new SoundController()
    }

    return this.instance
  }

  getDisabledSound() {
    return this.disabledSound
  }

  setDisabledSound(value) {
    this.disabledSound = value
    if (value) {
      this.stopSoundEffect()
    }
  }

  getDisabledBackground() {
    return this.disabledBackground
  }

  setDisabledBackground(value) {
    this.disabledBackground = value
    if (value) {
      this.stopBackground()
    }
  }

  getBackground() {
    return this.background
  }

  setBackground(sound) {
    this.background = sound
  }

  getSoundFX(sound) {
    if (!this.getDisabledSound()) {
      if (sound in loadEffects) {
        return loadEffects[sound]
      }

      return loadExternalEffects(sound)
    }

    return false
  }

  // eslint-disable-next-line consistent-return
  playSoundFX(soundInstance) {
    if (!this.getDisabledSound()) {
      return this.getSoundFX(soundInstance).play()
    }
  }

  // eslint-disable-next-line consistent-return
  playBackground(soundInstance) {
    if (!this.getDisabledBackground()) {
      if (this.getBackground() === null || !this.getBackground().playing()) {
        this.setBackground(soundInstance)

        return this.getBackground().play()
      }
    }
  }

  pauseBackground() {
    if (this.getBackground() !== null && !this.getDisabledBackground()) {
      this.getBackground().pause()
    }
  }

  resumeBackground() {
    if (this.getBackground() !== null && !this.getDisabledBackground()) {
      this.getBackground().play()
    }
  }

  stopBackground() {
    if (this.getBackground() !== null) {
      this.getBackground().pause()
      this.setBackground(null)
    }
  }

  stopSoundEffect(sound) {
    if (this.getSoundFX(sound) !== null && !this.getDisabledSound()) {
      this.getSoundFX(sound).stop()
    }
  }

  stopAllSounds() {
    this.stopBackground()
    this.stopSoundEffect()
  }
}
