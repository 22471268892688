<template>
  <div class="navigation">
    <NavigationMenu
      v-show="canShowNavigation"
      :items="mappedItems"
      :is-logos-route="isLogosRoute"
      :class="[
        'navigation__menu-navigation',

        { '--logos-navigation': isLogosRoute,'--has-teacher-top-bar': !userIsStudent }
      ]"
      :modal-component-visible="modalVisible"
      @show-modal-component="showModalComponent"
    />
    <keep-alive>
      <AdventuresList
        v-if="isAdventureVisible"
        :header-info="historyModalData"
        class="navigation__adventures-list"
        @toggle-component-visibility="setModalVisible"
        @decrement-notifications="decrementNotifications('adventure')"
      />
    </keep-alive>
    <Feedback
      v-if="isFeedbackVisible"
      class="navigation__feedback"
      @toggle-component-visibility="setModalVisible"
    />
    <NewsDrawer
      v-if="isNewsVisible"
      @toggle-component-visibility="setModalVisible"
      @decrement-news-notifications="updateNotifications()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { isEmpty, isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import userIsStudent from '@/mixins/userIsStudent'
import getGradeId from '@/mixins/getGradeId'
import menuApi from '@/service/menu'
import Feedback from '@/components/Feedback'
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu'
import AdventuresList from '@/components/Adventure/AdventuresList'
import NewsDrawer from '@/components/News/NewsDrawer'

const isNotAllowedIn = [
  'question',
  'game-error',
  'storytelling',
  'home-teacher',
  'retrospective',
  'logos-knowledge-test',
  'logos-recovery',
]

const isModalComponent = [
  'adventure',
  'news',
  'feedback',
]

export default {
  name: 'Navigation',
  components: {
    NavigationMenu,
    AdventuresList,
    NewsDrawer,
    Feedback,
  },
  mixins: [ getGradeId, userIsStudent ],
  props: {
    splashScreenIsVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: [],
      modalVisible: '',
    }
  },
  computed: {
    ...mapState({
      userGrades: (state) => state.account.userGrades,
    }),
    canShowNavigation() {
      const { name } = this.$route
      const { menuItems, splashScreenIsVisible } = this
      const menuIsDisabled = (isNotAllowedIn.includes(name) || splashScreenIsVisible)

      return !menuIsDisabled && isNotEmpty(menuItems)
    },
    isLogosRoute() {
      return this.$route.path.startsWith('/logos')
    },
    historyModalData() {
      const historyData = this.menuItems.find(({ slug }) => slug === 'adventure')

      return ({
        title: historyData?.name,
        icon: historyData?.image.svg,
        subtitle: this.$t('game.adventure.subtitle'),
      })
    },
    isAdventureVisible() {
      return this.modalVisible === 'adventure'
    },
    isNewsVisible() {
      return this.modalVisible === 'news'
    },
    isFeedbackVisible() {
      return this.modalVisible === 'feedback'
    },
    mappedItems() {
      if (isEmpty(this.menuItems)) {
        return []
      }

      return this.menuItems.map((item) => ({
        ...item,
        isModalComponent: isModalComponent.includes(item.slug),
      }))
    },
  },
  watch: {
    userGrades: {
      handler() {
        if (this.userGrades.data) {
          this.fetchMenuItems()
        }
      },
      immediate: true,
    },
    $route() {
      if (this.$route.params.openAdventure) {
        this.setModalVisible({ id: 'adventure' })
      }
    },
  },
  methods: {
    ...mapActions([ 'setIsToOpenNewsDrawer' ]),
    showModalComponent(modal) {
      this.setModalVisible(modal)

      if (this.modalVisible === 'news' && !modal.isAutoModal) {
        this.setIsToOpenNewsDrawer(true)
      }
    },
    async fetchMenuItems() {
      const params = {}
      const currentGradeId = this.getGradeId()

      if (currentGradeId) {
        params.gradeId = currentGradeId
      }

      try {
        const { data } = await menuApi.getMenuItems(params)
        const items = [ ...data ].filter(({ active, availableInWeb }) => active && availableInWeb)

        this.menuItems = items
      } catch (error) {
        console.error(error)
      }
    },
    updateNotifications() {
      this.fetchMenuItems()
    },
    decrementNotifications(itemSlug) {
      const itemIndex = this.menuItems.findIndex(({ slug }) => slug === itemSlug)

      if (itemIndex !== -1) {
        const item = this.menuItems[itemIndex]
        const { notificationCount } = item

        if (notificationCount > 0) item.notificationCount = notificationCount - 1
      }
    },
    setModalVisible({ id, callback }) {
      const alreadyVisible = id === this.modalVisible

      if (alreadyVisible) {
        this.modalVisible = ''
      } else {
        this.modalVisible = id
      }

      this.handleCallbacks(id, callback)
    },
    handleCallbacks(id, callback) {
      if (typeof callback === 'function') {
        callback()
      } else {
        this.predefinedActions(id)
      }
    },
    predefinedActions(id) {
      const actionsById = {
        news: {
          onOpen: () => {},
          onClose: () => this.$trackEvent({
            category: this.$track.category.news,
            action: this.$track.action.exitNews,
          }),
        },
      }
      const itemPredefinedActions = actionsById[id]
      const isOpenAction = this.modalVisible === id
      const isCloseAction = this.modalVisible === ''

      if (itemPredefinedActions) {
        if (isOpenAction) {
          itemPredefinedActions.onOpen()
        }

        if (isCloseAction) {
          itemPredefinedActions.onClose()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation {
  &__menu-navigation {
    z-index: 100;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 65px;
    width: 100%;
    left: 0px;
    bottom: 10px;
    transform: none;

    &.--logos-navigation {
      @media screen and (max-width: 992px) {
        bottom: -25px;
        height: 105px;
      }
    }

    @media screen and (min-width: 992px) {
      flex-direction: column;
      height: auto;
      width: auto;
      left: 40px;
      bottom: 50%;
      transform: translate(0, 50%);
    }

    @media screen and (max-width: 568px) {
      bottom: 0px;
    }
  }

  &__adventures-list {
    position: absolute;

    left: 140px;
    bottom: calc(50% - 50px);
    transform: translate(0, 50%);

    height: 620px;
    z-index: 999;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      transform: none;
    }
  }

  &__feedback {
    position: absolute;
    left: 140px;
    bottom: calc(50% - 50px);
    transform: translate(0, 50%);
    height: 620px;
    z-index: 999;

    @media screen and (max-width: 991px) {
      left: 0;
      top: 0;
      transform: none;
    }
  }
}
</style>
