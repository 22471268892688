<template>
  <div class="insignias-skeleton">
    <div class="insignias-skeleton__container">
      <skeleton-loader
        width="88px"
        height="88px"
        border-radius="16px 0 0 16px"
        :style="{background: '#E3CDAB !important'}"
        opacity="0.6"
      />
      <div class="insignias-skeleton__container__body">
        <skeleton-loader
          width="132px"
          height="20px"
          border-radius="35px"
        />
        <div class="insignias-skeleton__container__body__diamond-bar">
          <skeleton-loader
            width="40vw"
            height="35px"
            border-radius="35px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InsigniasSkeleton',
}
</script>

<style lang="scss" scoped>
.insignias-skeleton {
  background: #FBF1E1;
  border-radius: $size-s;
  box-shadow: 0 3px 0 rgba(107, 68, 68, 0.19), 0 4px 4px #C19267;

  &__container {
    display: grid;
    grid-template-columns: auto 1fr;

    &__body {
      margin-left: 1rem;
      margin-top: 0.5rem;

      &__diamond-bar {
        margin-top: 0.5rem;
        display: grid;
        grid-template-columns: auto 1fr;
        .sas-skeleton-loader {
          min-width: 180px;
          max-width: 251px;
        }
      }
    }
  }
}

::v-deep .sas-skeleton-loader {
  background: #CBA976 !important;
  opacity: 0.5 !important;
}
</style>
