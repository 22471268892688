<template>
  <div
    class="splash-screen"
    :class="{
      '--mfe-splash': isMFE
    }"
  >
    <div class="splash-screen__body">
      <ResourceBar
        v-if="showProgressBar"
        class="splash-screen__body__progress"
        variation="yellow"
        :total="100"
        :value="progressBar"
      />
      <GButton
        v-else
        class="splash-screen__body__play"
        size="large"
        @click="close"
      >
        {{ $t('commons.start') }}
      </GButton>
    </div>
    <g-button
      v-if="isTypeformReady"
      class="typeform-button"
      size="large"
      icon-left="message-square"
      @click="openFeedbackSurvey"
    >
      {{ $t('splashScreen.wasItEasyToGetHere') }}
    </g-button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import { arrayUniq } from '@sas-te/frontend-utils/modules/arrays'
import typeformService from '@/service/typeform'
import urls from '@/utils/urls'
import ResourceBar from '../../components/ResourceBar'
import GButton from '../../components/GButton'

export default {
  name: 'SplashScreen',
  components: {
    GButton,
    ResourceBar,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      progressBar: 0,
      interval: null,
      finished: false,
      limit: 0,
    }
  },
  computed: {
    ...mapState({
      isTypeformReady: ({ typeform }) => typeform.isTypeformReady,
    }),
    ...mapGetters([
      'historySeasons',
      'user',
      'grade',
    ]),
    isMFE() {
      return window.eureka_init
    },
    showProgressBar() {
      return this.showProgress && !this.finished
    },
    loading() {
      const { historySeasons } = this

      return historySeasons.loading
    },
    done() {
      const { historySeasons } = this

      return historySeasons.done
    },
    teacherGrades() {
      const gradeNamesList = this.user.data.grades.map(({ name }) => name)

      return arrayUniq(gradeNamesList)
    },
  },
  watch: {
    progressBar() {
      if (this.progressBar === this.limit && this.limit !== 0) {
        clearInterval(this.interval)
        this.interval = null
        setTimeout(() => { this.finished = true }, 400)
      }
    },
  },
  created() {
    this.progressInterval(100)
    this.getHistorySeasons()
  },
  methods: {
    ...mapActions([ 'getHistorySeasons' ]),
    close() {
      this.$emit('close')
    },
    progressInterval(end) {
      this.limit = end
      let progressIsPaused = false
      const minPeriod = end / 2

      if (this.interval === null) {
        this.interval = setInterval(() => {
          if (progressIsPaused && this.done) {
            progressIsPaused = false

            return
          }

          if (this.progressBar < end && !progressIsPaused) {
            if (this.progressBar === minPeriod && !this.done) {
              progressIsPaused = true
            } else {
              this.progressBar += 10
            }
          }
        }, 100)
      }
    },
    openFeedbackSurvey() {
      const url = urls.BASE_URL.concat(this.$route.path)
      const platform = 'web'
      const accountId = this.user.data.id
      const grade = this.user.data.userIsStudent ? this.grade?.name : this.teacherGrades
      const profile = this.user.data.profiles[0].name

      const typeformPopup = typeformService.createPopup({
        formId: 'PLKlcNgj',
        hiddenFields: {
          url,
          account_id: accountId,
          platform,
          grade,
          profile,
        },
      })

      typeformPopup.open()
    },
  },
}
</script>
<style lang="scss" scoped>
.--mfe-splash {
  z-index: 10 !important;

  .splash-screen__body {
    bottom: 100px;
  }
}
.splash-screen {
  z-index: $zindex-modal;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-image:
    url('../../assets/splashscreen/logo-small.svg'),
    url('../../assets/splashscreen/splash-screen-320.png');
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 129px, center;
  background-size: contain, cover;

  @media screen and (min-width: 375px) {
    background-image:
      url('../../assets/splashscreen/logo-small.svg'),
      url('../../assets/splashscreen/splash-screen-375.png');
    background-size: contain, cover;
  }

  @media screen and (min-width: 576px) {
    background-image:
      url('../../assets/splashscreen/logo-small.svg'),
      url('../../assets/splashscreen/splash-screen-576.png');
    background-size: auto 20%, cover;
  }

  @media screen and (min-width: 768px) {
    background-image:
      url('../../assets/splashscreen/logo-large.svg'),
      url('../../assets/splashscreen/splash-screen-768.png');
  }

  @media screen and (min-width: 1024px) {
    background-image:
      url('../../assets/splashscreen/logo-small.svg'),
      url('../../assets/splashscreen/splash-screen-1024.png');
  }

  @media screen and (min-width: 1366px) {
    background-image:
      url('../../assets/splashscreen/logo-large.svg'),
      url('../../assets/splashscreen/splash-screen-1366.png');
  }

  @media screen and (min-width: 1500px) {
    background-image:
      url('../../assets/splashscreen/logo-large.svg'),
      url('../../assets/splashscreen/splash-screen-1366.png');
  }

  @media screen and (min-width: 2000px) {
    background-image:
      url('../../assets/splashscreen/logo-large.svg'),
      url('../../assets/splashscreen/splash-screen-1366.png');
  }

  &__body {
    width: 100vw;
    position: absolute;
    bottom: $size-xxl;
    display: inline-block;
    text-align: center;

    &__play {
      width: 200px;
      font-family: Rowdies;

      ::v-deep .g-button {
        &__text {
          font-weight: $font-weight-base;
        }
      }
    }

    &__progress {
      display: inline-block;
      text-align: center;
      width: 300px;
    }
  }
}

.typeform-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: Lato;
}

::v-deep .typeform-button.g-button {
  background: #FBF1E1;
  border: 5px solid $color-white;
  color: #4E3128;
  box-shadow: 0px 4px 0px #EDAE49;
  padding: $size-s $size-m;

  &:hover {
    box-shadow: 0px 5px 0px #EDAE49;
  }

  &:active,
  &.--active {
    box-shadow: 0px 0px 0px #EDAE49;
  }
}
</style>
