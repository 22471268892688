<template>
  <div class="shop-error">
    <header
      v-if="!isSmallScreen"
      class="shop-error__header"
    >
      <h2 class="shop-error__header__text">
        {{ $t('game.shop.signboard') }}
      </h2>
    </header>
    <img
      class="shop-error__shelter"
      src="@/assets/shop/shelter.svg"
    >
    <div class="shop-error__body">
      <img
        class="shop-error__illustration"
        :src="require(`@/assets/error/shop/illustration.svg`)"
      >
      <h2 class="shop-error__message">
        {{ $t('game.error.shop.message') }}
      </h2>
      <p class="shop-error__observation">
        {{ $t('game.error.shop.observation') }}
      </p>
      <g-button
        class="shop-error__action"
        size="large"
        @click="$emit('tryAgain')"
      >
        {{ $t('game.error.shop.action') }}
      </g-button>
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'ShopError',
  components: {
    GButton,
  },
  props: {
    isSmallScreen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.shop-error{
  background-color: #F2E9D0;
  margin: 0 auto;
  position: relative;
  width: 810px;
  border: 10px solid #FAE199;
  border-bottom: none;
  box-shadow: inset 0 6px 0 6px #C78D12, 0px 13px 20px rgba(98, 73, 36, 0.31);
  min-height: calc(100vh - 182px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include mq_m{
    min-height: calc(100vh - 238px);
    width: 100%;
  }

  &__shelter{
    display: block;
    position: absolute;
    top: -34px;
    width: 108%;
  }

  &__header{
    background-image: url(~@/assets/shop/signboard.svg);
    height: 142px;
    width: 404px;
    padding: 26px;
    position: absolute;
    left: 72px;
    top: -160px;
    &__text{
      font-weight: $font-weight-extra-bold;
      font-size: $font-size-heading-4;
      line-height: 1;
      text-align: center;
      transform: rotate(-1deg);
    }
  }
  &__body{
    @include flex-column-center;
    padding-top: 120px;
    padding-bottom: 40vh;
    @include mq_m{
      padding-top: 8vh;
      padding-bottom: 16vh;
      height: 100%;
    }
  }

  &__illustration{
      margin-bottom: 16px;
      @include mq_m{
        width: 40vw;
      }
    }

  &__message{
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-heading-4;
    max-width: 480px;
    text-align: center;
    @include mq_m{
      font-size: 3vw;
      max-width: 100%;
    }
  }

  &__observation{
    margin-top: $size-s;
    opacity: 0.75;
    @include mq_m{
      font-size: 2vw;
      padding: 0 $size-s;
    }
  }

  &__action{
    margin-top: $size-l;
  }
}
</style>
