<template>
  <section class="arena-ranking">
    <Header
      :enigma-position="enigmaPosition"
      :total-enigmas="totalEnigmas"
      :click-action="clickAction"
      :text-button="textButton"
      :is-mobile="isMobile"
    />
    <div class="arena-ranking__separator --horizontal" />
    <ul
      v-if="isEmptyStudents"
      class="arena-ranking__list"
    >
      <li
        v-for="(rank, index) in ranking"
        :key="index"
        :class="[
          'arena-ranking__content',
          { '--best-ranking': showBestRanking(index) },
        ]"
      >
        <div class="arena-ranking__content-header">
          <div
            :class="[
              'arena-ranking__hit-quantity',
              { '--best-ranking': showBestRanking(index) },
            ]"
          >
            <img
              v-if="showBestRanking(index)"
              class="arena-ranking__hit-quantity-icon"
              :src="require('@/assets/ancestral-arena/best-scored.svg')"
            >
            <img
              v-else
              class="arena-ranking__hit-quantity-icon"
              :src="require('@/assets/ancestral-arena/check-circle-filled.svg')"
            >
            {{ hitQuantity(rank) }}
          </div>
          <div class="arena-ranking__students-quantity">
            <img
              class="arena-ranking__hit-quantity-icon"
              :src="require('@/assets/user-filled.svg')"
            >
            {{ studentsQuantity(rank) }} jogadores
          </div>
        </div>
        <ul class="arena-ranking__content-item">
          <li
            v-for="student in rank.students"
            :key="student.id"
          >
            <ArenaPlayerProfilePicture :student="student" />
          </li>
        </ul>
        <div
          v-if="showSeparator(index)"
          class="arena-ranking__separator --horizontal"
        />
      </li>
    </ul>
    <div
      v-else
      class="arena-ranking__empty"
    >
      <img
        class="arena-ranking__empty-image"
        :src="require('@/assets/char/cora/cora-thinking-2.svg')"
        alt=""
      >
      <div class="arena-ranking__empty-description">
        <div class="arena-ranking__empty-title">
          Parece que ninguém entrou na partida
        </div>
        <div class="arena-ranking__empty-subtitle">
          É preciso ter pelo menos um aluno para exibir a classificação. Convide
          seus alunos para entrarem na missão!
        </div>
      </div>
    </div>
    <div
      v-if="isMobile"
      class="arena-ranking__empty-footer"
    >
      <GButton
        class="arena-ranking-header__button"
        size="medium"
        variation="primary"
        no-border
        rowdies
        @click="clickAction"
      >
        {{ textButton }}
      </GButton>
    </div>
  </section>
</template>

<script>
import GButton from '@/components/GButton'
import mediaQueries from '@/mixins/mediaQueries'
import ArenaPlayerProfilePicture from '../ArenaPlayerProfilePicture'
import Header from './partials/AncestralArenaRankingHeader'

export default {
  name: 'AncestralArenaRanking',
  components: {
    ArenaPlayerProfilePicture,
    Header,
    GButton,
  },
  mixins: [ mediaQueries ],
  props: {
    lastQuestion: {
      type: Boolean,
      required: true,
    },
    ranking: {
      type: Array,
      default: () => [],
    },
    textButton: {
      type: String,
      required: true,
    },
    clickAction: {
      type: Function,
      required: true,
    },
    magnaMissionEnigma: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    enigmaPosition() {
      return this.magnaMissionEnigma?.magnaMissionInfo?.enigmaPosition ?? 0
    },
    totalEnigmas() {
      return this.magnaMissionEnigma?.magnaMissionInfo?.totalEnigmas ?? 0
    },
    isEmptyStudents() {
      return !!this.ranking?.length ?? false
    },
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
  },
  methods: {
    showBestRanking(index) {
      return index === 0 && this.lastQuestion
    },
    hitQuantity(rank) {
      const hitQuantityValue = rank?.hitQuantity ?? 0

      if (hitQuantityValue === 0) {
        return 'Nenhum acerto'
      }

      return `${hitQuantityValue} ${
        hitQuantityValue > 1 ? 'acertos' : 'acerto'
      }`
    },
    studentsQuantity(rank) {
      return rank?.students?.length ?? 0
    },
    showSeparator(index) {
      return index !== this.ranking?.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.arena-ranking {
  font-family: Rowdies;
  color: $color-white;
  width: 100%;
  margin-bottom: 50px;

  &__separator {
    opacity: 0.25;
    border-right: 1px solid $color-white;

    &.--horizontal {
      opacity: 0.5;
      border-right: 0;
      border-bottom: 2px solid $eureka-color-ice;
    }
  }

  &__list {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: auto;
    scrollbar-width: thin;
    scrollbar-color: $eureka-color-base-light #0d042552;
    border-radius: 0 0 16px 16px;
  }

  &__content {
    padding: $size-m $size-l 0 $size-l;
    background: rgba(10, 10, 10, 0.3);

    &.--best-ranking {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) -50%,
        rgba(255, 255, 255, 0) 90%
      );
    }

    &:last-child {
      border-radius: 0 0 16px 16px;
    }
  }

  &__content-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: $font-size-heading-4;
    line-height: 100%;
    margin-bottom: $size-m;
  }

  &__hit-quantity {
    display: grid;
    grid-template-columns: $size-m 1fr;
    grid-gap: 10px;
    align-items: center;

    &.--best-ranking {
      color: $eureka-color-primary-light;
      text-shadow: 0 0 20px $eureka-color-primary-light;
    }
  }

  &__hit-quantity-icon {
    height: $size-m;
    width: $size-m;
    display: inline-block;
  }

  &__students-quantity {
    font-size: $font-size-heading-5;
    line-height: 100%;
    display: grid;
    grid-template-columns: $size-m 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  &__content-item {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 90px));
    grid-gap: $size-s;
    padding-bottom: $size-m;
  }

  &__empty {
    background: rgba(10, 10, 10, 0.3);
    border-radius: 0 0 16px 16px;
    height: 70vh;
    display: grid;
    grid-template-areas: "image description";
    grid-template-columns: minmax(100px, 300px) minmax(auto, 400px);
    align-content: center;
    align-items: center;
    justify-content: center;
    grid-gap: $size-m;

    @media screen and (max-width: 991px) {
      padding: 88px $size-s;
      height: auto;
      font-family: Rowdies;
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      text-align: left;
      margin-bottom: $size-m;
    }
  }

  &__empty-image {
    grid-area: image;
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 50vh;

    @media screen and (max-width: 991px) {
      height: 215px;
    }
  }

  &__empty-description {
    grid-area: description;
    display: grid;
    grid-gap: $size-s;
    max-width: 380px;
  }

  &__empty-title {
    font-size: 2rem;
    line-height: 130%;

    @media screen and (max-width: 991px) {
      font-family: Rowdies;
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      text-align: left;
    }
  }

  &__empty-subtitle {
    font-family: Lato;
    letter-spacing: 0.44px;

    @media screen and (max-width: 991px) {
      font-family: Lato;
      font-size: 14px;
      font-weight: $font-weight-medium;
      line-height: $line-height-heading;
      text-align: left;
    }
  }

  &__empty-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: $size-m;
  }
}
</style>
