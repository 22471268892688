<template>
  <div
    class="mission-report"
    :style="missionBackground"
  >
    <TeacherTopbar
      v-if="isTeacherTopbarVisible"
      selector-is-hidden
    />
    <transition name="fade">
      <GLoader
        v-if="report.loading"
        size="small"
        :frame="true"
      />
      <div
        v-else-if="report.success"
        class="mission-report__wrapper"
      >
        <GFrame class="mission-report__wrapper__content">
          <img
            class="mission-report__wrapper__content__banner"
            src="@/assets/char/tata-success.svg"
          >
          <g-button
            v-if="isTypeformReady"
            variation="primary"
            class="typeform-button"
            size="large"
            icon-left="star"
            @click="openFeedbackSurvey"
          >
            {{ $t('survey.report.whatDidYouThinkAboutMission') }}
          </g-button>
          <div class="mission-report__wrapper__content__body">
            <MissionReportBody @toggle-background="toggleBackground()" />
            <MissionReportFooter
              @go-to-missions="goToMissions"
              @redo-mission="showRedoModal"
            />
          </div>
        </GFrame>
      </div>
      <MissionReportError
        v-else-if="report.error"
        context="report"
      />
    </transition>
    <RedoConfirmation
      ref="redoModal"
      @confirm="redoMission"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import typeformService from '@/service/typeform'
import { effectsNames } from '@/service/soundController/loadSounds'
import loadTheme from '@/mixins/loadTheme'
import mediaQueries from '@/mixins/mediaQueries'
import GLoader from '@/components/GLoader'
import GButton from '@/components/GButton'
import urls from '@/utils/urls'
import TeacherTopbar from '../../components/TeacherTopbar'
import GFrame from '../../components/GFrame'
import MissionReportBody from './partials/MissionReportBody'
import RedoConfirmation from './partials/RedoConfirmation'
import MissionReportFooter from './partials/MissionReportFooter'
import MissionReportError from './partials/MissionReportError'

export default {
  name: 'MissionReport',
  components: {
    GLoader,
    GButton,
    MissionReportBody,
    RedoConfirmation,
    MissionReportError,
    MissionReportFooter,
    GFrame,
    TeacherTopbar,
  },
  mixins: [ mediaQueries, loadTheme ],
  data() {
    return {
      backgroundOverlay: false,
      missionItem: [],
    }
  },
  computed: {
    ...mapState({
      isTypeformReady: ({ typeform }) => typeform.isTypeformReady,
    }),
    ...mapGetters([
      'theme',
      'report',
      'user',
      'userGrades',
      'currentGrade',
      'grade',
    ]),
    isTeacherTopbarVisible() {
      if (this.user.data) {
        return !this.user.data.userIsStudent && this.userGrades.data?.length
      }

      return false
    },
    lectureId() {
      return this.$route.params.lectureId
    },
    missionBackground() {
      if (this.theme.success) {
        if (this.theme.data.mission.background) {
          return `background-image: url(${this.theme.data.mission.background})`
        }
        if (this.theme.data.sky.background) {
          return `background-color: ${this.theme.data.sky.background}`
        }
      }

      return ''
    },
  },
  async created() {
    try {
      const { questionnaireCode } = this.$route.params
      await this.getReport(questionnaireCode)
      await this.setAssignment({
        title: this.report.data.title,
        subtitle: this.report.data.subtitle,
      })
      this.$prependTitle(`${this.report.data.title} | ${this.report.data.subtitle} | `)
      if (this.$route.params.shouldPlaySoundEffect) {
        playSoundFX(effectsNames.GOOD_PERFORMANCE)
      }

      this.$trackEvent({
        category: this.$track.category.eureka,
        action: this.$track.action.enterReport,
        label: `${this.report.data.subtitle} - ${this.report.data.title}`,
      })
    } catch (error) {
      this.$prependTitle(`${this.$t('commons.error')} | `)
      console.error(error)
    }
  },
  beforeDestroy() {
    const gradeId = parseInt(this.$route.query.gradeId, 10)
    this.getLectures(gradeId)
  },
  methods: {
    ...mapActions([
      'getReport',
      'redoAssignment',
      'setAssignment',
      'resetBagItems',
      'getVideo',
      'getLectures',
    ]),
    toggleBackground() {
      this.backgroundOverlay = !this.backgroundOverlay
    },
    goToMissions() {
      playSoundFX()
      const { lectureId } = this.$route.params
      this.$router.push({
        name: 'assignments',
        query: { ...this.$route.query },
        params: { lectureId },
      })
      this.$trackEvent({
        category: this.$track.category.report,
        action: this.$track.action.goToMissions,
      })
    },
    async redoMission(payload) {
      const { assignmentCode } = this.report.data
      const { questionnaireCode, lectureId, chapterId } = this.$route.params

      if (payload?.length) {
        payload.forEach((item) => {
          this.missionItem.push(item)
        })
      }
      try {
        await this.redoAssignment({ assignmentCode })
        this.$trackEvent({
          category: this.$track.category.report,
          action: this.$track.action.redoMission,
          label: `${this.report.data.subtitle} - ${this.report.data.title}`,
        })

        this.$router.push({
          name: 'question',
          query: { ...this.$route.query },
          params: {
            lectureId,
            questionnaireCode,
            chapterId,
            missionItem: this.missionItem,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
    showRedoModal() {
      const { questionnaireCode } = this.$route.params
      this.getVideo(questionnaireCode)
        .catch((error) => {
          console.error(error)
        })

      playSoundFX()
      this.resetBagItems()
      this.$refs.redoModal.show()
    },
    openFeedbackSurvey() {
      const {
        title,
        chapterOrder,
        correctAnswers,
        lectureName,
      } = this.report.data
      const url = urls.BASE_URL.concat(this.$route.path)
      const platform = 'web'
      const accountId = this.user.data.id
      const gradeName = this.grade?.name
      const chapterTitle = title
      const chapterNumber = chapterOrder
      const profile = this.user.data.profiles[0].name
      const acertos = correctAnswers

      const typeformPopup = typeformService.createPopup({
        formId: 'f5IUmsbA',
        hiddenFields: {
          url,
          account_id: accountId,
          platform,
          chapter_title: chapterTitle,
          chapter_number: chapterNumber,
          grade: gradeName,
          profile,
          acertos,
          lecture: lectureName,
        },
      })

      typeformPopup.open()

      this.$trackEvent({
        category: this.$track.category.feedback,
        action: this.$track.action.feedbackSurvey,
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-report
  min-height: 100vh
  background-size: cover
  background-position: center top
  background-attachment: fixed

  &__wrapper
    height: 1024px
    display: flex
    justify-content: center
    align-items: center
    position: relative

    &__content
      background: $color-white
      max-width: 1030px
      border: 12px solid #F5E4C9
      border-radius: $size-m  !important
      position: absolute
      padding: 0 !important
      margin: 0 10px

      ::v-deep .g-frame
        padding: 0
        background: none

      ::v-deep .g-frame__inner
        display: grid
        grid-template-columns: auto auto
        grid-template-rows: 1fr auto

      &__banner
        position: relative
        transform: scale(1.035)
        right: 4px
        border-radius: 20px

      &__body
        display: grid
        grid-template-rows: 1fr
        padding-top: $size-m

  .teacher-top-bar
    position: fixed
    top: 0
    z-index: 100

.g-loader
  +float-center

.typeform-button
  position: absolute
  left: 115px
  bottom: 35px
  z-index: 99

::v-deep .typeform-button.g-button
  background: #FBF1E1
  border: 5px solid $color-white
  color: #4E3128
  box-shadow: 0px 4px 0px #EDAE49

  &:hover
    box-shadow: 0px 5px 0px #EDAE49

  &:active,
  &.--active
    box-shadow: 0px 0px 0px #EDAE49
</style>
