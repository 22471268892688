var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"arena-ranking"},[_c('Header',{attrs:{"enigma-position":_vm.enigmaPosition,"total-enigmas":_vm.totalEnigmas,"click-action":_vm.clickAction,"text-button":_vm.textButton,"is-mobile":_vm.isMobile}}),_c('div',{staticClass:"arena-ranking__separator --horizontal"}),(_vm.isEmptyStudents)?_c('ul',{staticClass:"arena-ranking__list"},_vm._l((_vm.ranking),function(rank,index){return _c('li',{key:index,class:[
        'arena-ranking__content',
        { '--best-ranking': _vm.showBestRanking(index) },
      ]},[_c('div',{staticClass:"arena-ranking__content-header"},[_c('div',{class:[
            'arena-ranking__hit-quantity',
            { '--best-ranking': _vm.showBestRanking(index) },
          ]},[(_vm.showBestRanking(index))?_c('img',{staticClass:"arena-ranking__hit-quantity-icon",attrs:{"src":require('@/assets/ancestral-arena/best-scored.svg')}}):_c('img',{staticClass:"arena-ranking__hit-quantity-icon",attrs:{"src":require('@/assets/ancestral-arena/check-circle-filled.svg')}}),_vm._v(" "+_vm._s(_vm.hitQuantity(rank))+" ")]),_c('div',{staticClass:"arena-ranking__students-quantity"},[_c('img',{staticClass:"arena-ranking__hit-quantity-icon",attrs:{"src":require('@/assets/user-filled.svg')}}),_vm._v(" "+_vm._s(_vm.studentsQuantity(rank))+" jogadores ")])]),_c('ul',{staticClass:"arena-ranking__content-item"},_vm._l((rank.students),function(student){return _c('li',{key:student.id},[_c('ArenaPlayerProfilePicture',{attrs:{"student":student}})],1)}),0),(_vm.showSeparator(index))?_c('div',{staticClass:"arena-ranking__separator --horizontal"}):_vm._e()])}),0):_c('div',{staticClass:"arena-ranking__empty"},[_c('img',{staticClass:"arena-ranking__empty-image",attrs:{"src":require('@/assets/char/cora/cora-thinking-2.svg'),"alt":""}}),_vm._m(0)]),(_vm.isMobile)?_c('div',{staticClass:"arena-ranking__empty-footer"},[_c('GButton',{staticClass:"arena-ranking-header__button",attrs:{"size":"medium","variation":"primary","no-border":"","rowdies":""},on:{"click":_vm.clickAction}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")])],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arena-ranking__empty-description"},[_c('div',{staticClass:"arena-ranking__empty-title"},[_vm._v(" Parece que ninguém entrou na partida ")]),_c('div',{staticClass:"arena-ranking__empty-subtitle"},[_vm._v(" É preciso ter pelo menos um aluno para exibir a classificação. Convide seus alunos para entrarem na missão! ")])])
}]

export { render, staticRenderFns }