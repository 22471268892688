<template>
  <GModal
    class="confirm-purchase"
    :close-button-enabled="false"
    @close="$emit('cancel')"
  >
    <div class="confirm-purchase__body">
      <div class="confirm-purchase__icon">
        <img
          class="confirm-purchase__icon__image"
          :src="require(`@/assets/items/${item.id}.svg`)"
        >
      </div>
      <div class="confirm-purchase__message">
        {{ $t('game.shop.buyItem.start', {
          item: $t(`game.items.${item.id}.name`)
        }) }}
        <img
          class="confirm-purchase__message__coin"
          :src="require(`@/assets/coin.svg`)"
        >
        {{ `${item.price}${$t('game.shop.buyItem.end')}` }}
      </div>
      <g-alert
        v-if="error"
        variation="error"
        :message="$t('game.error.buyItem.message')"
      />
    </div>
    <div class="confirm-purchase__controls">
      <a
        :class="[
          'confirm-purchase__controls__cancel',
          { '--disabled': buying },
        ]"
        @click="$emit('cancel')"
      >
        {{ $t('game.shop.confirm.cancel') }}
      </a>
      <g-button
        class="confirm-purchase__controls__ok"
        size="large"
        :loading="buying"
        @click="$emit('ok')"
      >
        {{ $t('game.shop.confirm.ok') }}
      </g-button>
    </div>
  </GModal>
</template>

<script>
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'ConfirmPurchase',
  components: {
    GModal,
    GButton,
    GAlert,
  },
  mixins: [ mediaQueries ],
  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    buying: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.confirm-purchase{
  &__body{
    width: 500px;
    padding: $size-m;
    @include flex-center;
    flex-wrap: wrap;
    flex-direction: column;

    @include mq_s {
      width: 100%
    }
  }
  &__icon {
    background-color: #F3EBD8;
    border-radius: 16px;
    margin-right: $size-m;
    padding: 6px;
    @include flex-center;

    &__image{
      display: block
    }
  }
  &__message {
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-heading-4;
    line-height: $line-height-input;
    flex-grow: 1;
    flex-basis: 50%;

    @include mq_s{
      text-align: center;
      width: 80%;
      font-weight: $font-weight_regular;
      font-size: $font-size-m;
      font-family: Rowdies;
    }

    &__coin {
      width: 28px;
      vertical-align: middle;
      display: inline-block;
    }
  }
  &__controls{
    border-top: 1px solid $eureka-color-base-dark;
    padding: $size-s $size-m;
    @include flex-space-between;

    &__cancel{
      color: #736558;
      display: block;
      font-weight: $font-weight-extra-bold;
      font-size: $font-size-heading-5;
      text-decoration-line: underline;
      cursor: pointer;
      @include transition($speed-x-fast);

      &:hover{
        color: #382517;
      }
      &.--disabled{
        opacity: 0.5;
        pointer-events: none;
      }
      }
  }
}
.g-alert{
  margin-top: $size-l;
}
</style>
