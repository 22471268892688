<template>
  <div
    data-testid="arena-timer"
    :class="[
      className,
      {
        '--fadeIn': isShow,
        '--fadeOut': !isShow,
      },
    ]"
  >
    <div
      :class="[
        `${className}__icon-effect`,
        {
          '--in-progress': isProgressStatus,
        },
      ]"
    >
      <i

        :class="[
          `${className}__icon`,
          {
            '--idle': isIdleStatus,
            '--in-progress': isProgressStatus,
            '--finished': isFinishedStatus,
          },
        ]"
      />
    </div>
    <span
      :class="[
        `${className}__text`,
        {
          '--idle': isIdleStatus,
          '--finished': isFinishedStatus,
        },
      ]"
    >
      <img
        v-if="!isFinishedStatus"
        :class="[
          `${className}__clock`,
        ]"
        alt=""
        :src="require('@/assets/ancestral-arena/timer-clock.svg')"
      >
      {{ finishedText }}
      <span
        v-if="!isFinishedStatus && isHorizontalTimer"
        :class="`${className}__additional-text`"
      >
        Tempo restante
      </span>
    </span>
  </div>
</template>

<script>
const statusEnum = {
  IDLE: 'IDLE',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
}

export default {
  name: 'ArenaTimer',
  props: {
    timer: {
      type: String,
      required: true,
    },
    status: {
      validator(value) {
        return Object.values(statusEnum).includes(value)
      },
      default: statusEnum.IDLE,
    },
    isShow: Boolean,
    isHorizontalTimer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isIdleStatus() {
      return this.status === statusEnum.IDLE || this.status === statusEnum.PAUSED
    },
    isProgressStatus() {
      return this.status === statusEnum.IN_PROGRESS
    },
    isFinishedStatus() {
      return this.status === statusEnum.FINISHED
    },
    className() {
      return this.isHorizontalTimer ? 'horizontal-timer' : 'vertical-timer'
    },
    finishedText() {
      if (this.isFinishedStatus) {
        return this.isHorizontalTimer ? 'Tempo finalizado' : 'Finalizado'
      }

      return this.timer
    },
  },
}
</script>

<style lang="scss" scoped>

.vertical-timer {
  color: $color-white;
  @include flex-center;
  position: relative;
  text-align: center;
  min-width: 240px;
  margin-left: $size-s;

  &.--fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity $speed-slow;
  }

  &.--fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear $speed-slow, opacity $speed-slow;
  }

  &__text {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: 2rem;
    text-shadow: 0px 1px 0px $color-black;
    padding: $size-xxs $size-m $size-xs;
    background: linear-gradient(
      270deg,
      rgba(45, 31, 73, 0) 0%,
      #2d1f49 49.94%,
      rgba(45, 31, 73, 0) 100%
    );
    position: absolute;
    bottom: -10px;
    margin: 0 auto;
    line-height: 100%;

    &.--idle {
      opacity: 0.8;
    }

    &.--finished {
      font-size: $font-size-heading-4;
    }
  }

  &__clock {
    height: 28px;
    width: 24px;
  }

  &__icon {
    @include flex-center;
    background: url("~@/assets/ancestral-arena/sandglass.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 96px;
    width: 105px;

    &.--idle {
      opacity: 0.8;
    }

    &.--in-progress {
      &::before {
        @include pseudo;
        border-radius: $border-radius-circle;
        z-index: -1;
        box-shadow: 0px 0px 50px 55px rgba(227, 159, 79, 0.4);
        height: 1px;
        width: 1px;
      }
    }

    &.--finished {
      filter: grayscale(100%);
    }
  }
}

.horizontal-timer {
  background: linear-gradient(90deg, $eureka-color-ink-light 50.07%, rgba(91, 84, 138, 0) 102.16%);
  display: flex;
  padding: $size-xs $size-l;
  position: relative;
  height: $size-xl;
  color: $color-white;

   &.--fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity $speed-slow;
  }

  &.--fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear $speed-slow, opacity $speed-slow;
  }

  &__text {
    display: flex;
    grid-gap: $size-xs;
    align-items: center;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
    text-shadow: 0px 1px 0px $color-black;
    padding: $size-xxs $size-m $size-xs $size-s;
    line-height: 100%;
    z-index: 1;

    &.--idle {
      opacity: 0.8;
    }
  }

  &__additional-text {
    font-size: $font-size-heading-5;
    margin-left: $size-s;
  }

  &__clock {
    height: 26px;
  }

   &__icon-effect {
    @include flex-center;
    max-width: 60px;

    &.--in-progress {
      &::before {
        @include pseudo;
        border-radius: $border-radius-circle;
        z-index: 0;
        box-shadow: 0px 0px 30px 30px rgba(227, 159, 79, 0.5);
        height: 1px;
        width: 1px;
      }
    }
  }

  &__icon {
    @include flex-center;
    background: url("~@/assets/ancestral-arena/sandglass.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    height: 60px;
    width: 60px;

    &.--idle {
      opacity: 0.8;
    }

    &.--finished {
      filter: grayscale(100%);
    }
  }
}
</style>
