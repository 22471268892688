<template>
  <div
    :class="[
      'worlds-list-item',
      { '--active': active },
      `--${size}`,
    ]"
    @click="onClick"
  >
    <img
      v-if="theme"
      class="worlds-list-item__image"
      :src="theme.planet"
    >
    <WorldTitle
      v-if="showTitle"
      :title="world.name"
      :framed="false"
      :size="size"
      :gems="world"
      :show-badge="active"
      :badge-count="world.pendencies"
      class="title-banner"
    />
  </div>
</template>

<script>
import WorldTitle from '@/views/Missions/partials/WorldTitle'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WorldsListItem',
  components: {
    WorldTitle,
  },
  props: {
    world: {
      type: Object,
      default: null,
    },
    showDiamonds: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'themes',
    ]),
    theme() {
      const { id } = this.world
      const { data } = this.themes

      return data.find(({ worldId }) => worldId === id)
    },
    gradeTemplateId() {
      const { grades, userIsStudent } = this.user.data

      if (userIsStudent) {
        const { id } = grades.find((grade) => grade.id)

        return parseInt(id, 10)
      }

      return parseInt(this.$route.query.gradeId, 10)
    },
  },
  async created() {
    const { world, gradeTemplateId } = this
    await this.setThemes({ worldId: world.id, gradeTemplateId })
  },
  methods: {
    ...mapActions([ 'setThemes' ]),
    onClick() {
      this.$emit('click', this.world)
    },

  },
}
</script>

<style lang="sass" scoped>
$short-pulse: 20px
$long-pulse: 30px

$world-size-small: 180px
$world-size-large: calc(30vh + 8vw)
$world-max-size-large: 45vh

.worlds-list-item
  position: relative
  border-radius: 50%
  cursor: pointer
  +flex-center
  flex-direction: column
  height: auto

  &.--large
    width: $world-size-large
    max-width: $world-max-size-large

  &.--small
    width: 300px

    @media screen and (max-width: 1580px)
      width: 240px

    @media screen and (max-width: 1360px)
      width: 200px

  &:hover
    .worlds-list-item__image,
    .worlds-list-item__jewels-card
      transform: scale(1.05)

  &:hover .worlds-list-item-wrapper
    filter: blur(0px) !important
  &:hover .item-badge__count
    transform: scale(1.05) translate(7px, 4px)
    +transition($speed-fast)

  &:active .worlds-list-item__image
    transform: scale(1.02)
    +transition($speed-fast)

  &__image
    padding: 4px
    display: block
    width: 100%
    user-select: none
    -webkit-user-drag: none
    +transition($speed-fast)

  &__jewels-card
    display: block
    position: absolute
    top: 95%

  &.--active
    animation: pulse-short 3s infinite
    background: rgba(255, 255, 255, 0.8)
    border-radius: 50%
    position: relative

    &::after
      content: ""
      display: block
      z-index: -1
      border-radius: 50%
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      animation: pulse-long 3s infinite
      animation-delay: 0.5s

@keyframes pulse-short
  0%
    box-shadow: 0 0 0 0 rgba(255,255, 255, .8)
  60%
    box-shadow: 0 0 0 $short-pulse rgba(255,255, 255, 0)
  80%
    box-shadow: 0 0 0 0 rgba(255,255, 255, 0)

@keyframes pulse-long
  0%
    box-shadow: 0 0 0 0 rgba(255,255, 255, .8)
  60%
    box-shadow: 0 0 0 $long-pulse rgba(255,255, 255, 0)
  80%
    box-shadow: 0 0 0 0 rgba(255,255, 255, 0)
</style>
