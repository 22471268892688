<template>
  <ScrollContainer
    :class="[
      'performance-tab',
      { '--error' : lectures.error }
    ]"
  >
    <ErrorMessage
      v-if="lectures.error"
      image-name="refresh.svg"
      message="Não foi possível carregar o seu desempenho nos mundos"
      action="Recarregar"
      @click="fetchLectures"
    />
    <template v-else>
      <div class="performance-tab__stats">
        <template v-if="userStatsLoading">
          <StatsCardSkeleton
            v-for="(item, index) in 2"
            :key="index"
          />
        </template>
        <template v-else>
          <StatsCard
            v-for="(item, index) in userStats.data"
            :key="`user-statistic-${index + 1}`"
            :title="item.title"
            :content="item.value"
            :info="item.description"
          />
        </template>
      </div>
      <div
        class="performance-tab__list"
        :style="{ paddingBottom: isMFE ? '5em' : '' }"
      >
        <template v-if="lecturesLoading">
          <LecturesPerformanceSkeleton
            v-for="(item, index) in 4"
            :key="index"
            class="performance-card"
          />
        </template>
        <template v-else>
          <div
            v-for="lecture in lecturesItems"
            :key="lecture.id"
            class="performance-card"
          >
            <LecturesPerformance
              :name="lecture.name"
              :conquered-gems="lecture.conqueredGems"
              :total-gems="lecture.totalGems"
            />
          </div>
        </template>
      </div>
    </template>
  </ScrollContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import gradeId from '@/mixins/gradeId'
import ScrollContainer from '@/components/ScrollContainer'
import ErrorMessage from '@/components/ErrorMessage'
import LecturesPerformanceSkeleton from '../../LecturesPerformance/LecturesPerformanceSkeleton'
import StatsCardSkeleton from '../../StatsCard/StatsCardSkeleton'
import StatsCard from '../../StatsCard/StatsCard'
import LecturesPerformance from '../../LecturesPerformance/LecturesPerformance'

export default {
  name: 'PerformanceTab',
  components: {
    LecturesPerformance,
    StatsCard,
    StatsCardSkeleton,
    LecturesPerformanceSkeleton,
    ScrollContainer,
    ErrorMessage,
  },
  mixins: [ gradeId ],
  computed: {
    ...mapGetters([
      'lectures',
      'userStats',
    ]),
    isMFE() {
      return window.eureka_init
    },
    lecturesItems() {
      return this.lectures.data
    },
    lecturesLoading() {
      return this.lectures?.loading
    },
    userStatsLoading() {
      return this.userStats?.loading
    },
  },
  async created() {
    if (!this.userStats.data) {
      await this.getUserStats()
    }
    if (!this.lectures.data) {
      this.fetchLectures()
    }
  },
  methods: {
    ...mapActions([
      'getLectures',
      'getUserStats',
    ]),
    async fetchLectures() {
      try {
        await this.getLectures(this.gradeId)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.performance-tab {
  &.--error {
    @include flex-center;
  }

  &__stats {
    width: 100%;
    padding: 2rem 3rem 1.5rem 3rem;
    display: flex;
    gap: 12px;

    @media screen and (max-width: 768px) {
      gap: 8px;
      padding: 2rem 1rem 1.5rem 1rem;
    }
  }

  &__list {
    width: 100%;
    padding: 0 3rem 20px;

    @media screen and (max-width: 768px) {
      padding: 0 1rem 20px;
    }
  }

  ::v-deep .error-message {
    &__description {
      width: 64%;
    }
  }
}

.performance-card {
  margin: 0 0 1rem;
}
</style>
