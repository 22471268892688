<template>
  <div class="item-reward">
    <img
      height="22"
      :src="itemImage"
      class="item-reward__image"
    >
    <span
      v-if="rewardsCountIsVisible"
      class="item-reward__badge"
    >
      x{{ quantity }}
    </span>
    <icon
      v-if="acquired"
      :style="iconStyle"
      class="item-reward__acquired"
      type="check"
      size="14"
      stroke-width="5"
      stroke="green"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
export default {
  name: 'ItemReward',
  props: {
    lockedItem: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    acquired: {
      type: Boolean,
      default: false,
    },
    itemImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconStyle: {
        position: 'absolute',
        top: '-3px',
        minWidth: '14px',
        right: '-14px',
      },
    }
  },
  computed: {
    rewardsCountIsVisible() {
      return this.quantity > 1
    },
  },
}
</script>

<style lang="scss" scoped>
.item-reward {
  position: relative;
  width: 22px;
  height: 22px;

  &__badge {
    position: absolute;
    left: 15px;
    top: 10px;
    border-radius: $border-radius-circle;
    background: $color-white;
    text-align: center;
    font-size: $font-size-xs;
    font-weight: $font-weight-semi-bold;
    color: $eureka-color-ink;
  }
}
</style>
