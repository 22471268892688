<template>
  <div class="mission-content-options">
    <div
      v-for="(option, index) in options"
      :key="index"
      :class="[
        'mission-content-options__option',
        {
          '--hoverable': correctOptionIndex === -1,
          '--right': index === correctOptionIndex,
          '--wrong': (
            index === selectedOptionIndex
            && correctOptionIndex !== -1
            && index !== correctOptionIndex
          ),
          '--disabled': isDisabledOption(index),
          '--active': (
            index === selectedOptionIndex && correctOptionIndex === -1
          ),
        },
      ]"
      @click="onClick(option, index)"
    >
      <div
        v-if="isDisabledOption(index)"
        class="mission-content-options__option-risked"
      />
      <RichTextViewer :text="option.description" />
    </div>
  </div>
</template>

<script>
import RichTextViewer from '@/components/RichTextViewer'

export default {
  name: 'MissionContentOptions',
  components: {
    RichTextViewer,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectedOptionIndex: {
      type: Number,
      default: -1,
    },
    correctOptionIndex: {
      type: Number,
      default: -1,
    },
    indexToDisable: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    onClick(option) {
      if (this.correctOptionIndex === -1) {
        this.$emit('optionClicked', option)
      }
    },
    isDisabledOption(optionIndex) {
      const { indexToDisable } = this

      return indexToDisable !== -1 && indexToDisable === optionIndex
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-content-options
  &__option
    background: $color-white
    border: 1px solid #EECE8A
    box-shadow: 0px 4px 0px #EECE8A
    border-radius: 8px
    padding: 0 24px
    position: relative
    +space-stack(12px)
    +transition($speed-x-fast)
    +flex-center

    &.--hoverable
      cursor: pointer

      &:hover
        box-shadow: 0px 4px 0px shade(#EECE8A, 20%), inset 0 0 0 3px #EECE8A

      &:active
        box-shadow: 0px 0px 0px shade(#EECE8A, 20%), inset 0 0 0 3px #EECE8A
        transform: translateY(4px)

      &.--active
        border-color: #438DE4
        box-shadow: 0px 4px 0px #166BD0, inset 0 0 0 3px #438DE4

        &:active
          box-shadow: 0px 0px 0px shade(#166BD0, 20%), inset 0 0 0 3px #438DE4
          transform: translateY(4px)

    &.--right
      border-color: #19c5a6
      box-shadow: 0px 4px 0px #149d84, inset 0 0 0 3px #19c5a6

    &.--wrong
      border-color: #F2726F
      box-shadow: 0px 4px 0px #F23D38, inset 0 0 0 3px #F2726F

    &.--disabled
      color: grey
      cursor: not-allowed
      pointer-events: none
      text-decoration: line-through
      border: 1px solid #E9E9E9
      border-radius: 8px
      box-shadow: none
      transition: box-shadow 2s
      animation-duration: 6s
      animation-name: shadow

      &:hover
        box-shadow: 0px 0px 28px #FF3737, 0px 1px 0px #C0C0C0

    .feather
      flex-shrink: 0

    ::v-deep img
      width: auto
      max-width: 100%
      height: auto
      display: inline-block

    ::v-deep p
      font-size: 18px
      margin: 8px 0
      line-height: 1.5
      letter-spacing: 0.25px

.mission-content-options__option-risked
  width: calc(100% - 48px)
  height: 1px
  background: #E9E9E9
  position: absolute
  right: 24px
  animation-duration: 3s
  animation-name: risk

@keyframes shadow
  0%
    box-shadow: 0px 0px 28px #FF3737, 0px 1px 0px #C0C0C0

  100%
    box-shadow: none

@keyframes risk
  from
    width: 0

  to
    width: calc(100% - 48px)
    background: #000000
    opacity: 0.3

</style>
