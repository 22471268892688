<template>
  <div class="slide">
    <section class="slide__content">
      <h1 class="slide__title --medium">
        Retrospectiva {{ year }}
      </h1>
      <img
        class="slide__image"
        :src="require('@/assets/retrospective/eureka.svg')"
        alt="Eureka"
      >
      <p class="slide__text --info">
        Olá
        <strong class="slide__text --highlight">
          {{ firstName }}
        </strong>,
        foi uma jornada incrível
        que passamos juntos no ano anterior.
        Vamos relembrar os melhores momentos?
      </p>
    </section>
    <div class="slide__overlay" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'

export default {
  name: 'RetrospectiveApresentation',
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters([
      'user',
      'lectures',
    ]),
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    year() {
      return this.$route.params.retrospectiveId
    },
    firstName() {
      return this.userName ? this.userName.split(' ')[0] : ''
    },
    userName() {
      return this.user?.data.name
    },
    slide2() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const slide2 = pick(this.retrospectiveData,
        [ 'enigmaCount', 'missionCount', 'gems', 'coins' ])

      const collectionItems = [
        {
          size: 'small',
          type: 'gem',
          themeColor: 'transparent',
          text: `${slide2.gems?.conquered}/${slide2.gems?.total}`,
        },
        {
          size: 'small',
          type: 'coin',
          themeColor: 'transparent',
          quantity: slide2.coins,
        },
      ]

      return { ...slide2, collectionItems }
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  background-image: url('~@/assets/retrospective/background-retro-1.png');
  background-repeat: no-repeat;
  background-size: cover;

  &__overlay {
    @include cover();
    background-color: $eureka-color-ink;
    opacity: 0.6;
    z-index: 889
  }
}
</style>
