<template>
  <div class="create-magna-mission-modal">
    <MagnaMissionModal
      :title="title"
      button-name="Abrir Arena"
      :disable-submit="!hasSelectedClass"
      title-icon="ancestral-arena/missions/rounded-mission-type-live.svg"
      title-icon-tooltip="Missão ao vivo"
      @close="handleClose"
      @submit="handleStartMission"
    >
      <div class="create-magna-mission-modal__content">
        <div
          v-if="isLoading"
          class="create-magna-mission-modal__content__loading-state"
        >
          <GLoader
            size="small"
            sand="#F8A916"
            stroke="#2D1F49"
          />
        </div>
        <template v-else>
          <div class="create-magna-mission-modal__content__classroom-select">
            <img
              alt=""
              src="@/assets/char/cora/cora-modal.svg"
              class="create-magna-mission-modal__content__classroom-select__icon"
            >
            <div class="create-magna-mission-modal__content__classroom-select__main">
              <p class="create-magna-mission-modal__content__classroom-select__main__title">
                {{ classroomSelectTitle }}
              </p>
              <ClassPicker
                v-model="selectedClassroom"
                :classrooms="classrooms.data"
                :disabled="isReinforcementMission"
              >
                <template #selectedOption="{ option }">
                  <h1>{{ option.name }}</h1>
                </template>
                <template #skeleton>
                  <span class="classroom-select__class-picker__icon">
                    <icon
                      type="users"
                      stroke-width="3"
                    />
                  </span>
                  <p class="classroom-select__class-picker__title">
                    Selecione uma turma
                  </p>
                  <span class="classroom-select__class-picker__caret">
                    <icon
                      stroke-width="3"
                      type="chevron-down"
                    />
                  </span>
                </template>
              </ClassPicker>
              <div
                v-if="showEurekaChallengeRanking"
                class="create-magna-mission-modal__content__checkbox"
              >
                <input
                  id="ranking-optional"
                  v-model="hasRankingOptional"
                  type="checkbox"
                  name="ranking-optional"
                >
                <label
                  for="ranking-optional"
                  class="create-magna-mission-modal__content__label"
                >
                  Exibir classificação de alunos
                </label>
                <icon
                  v-tooltip.top="tooltipInfoText"
                  class="create-magna-mission-modal__content__icon"
                  type="info"
                  stroke="#9390A3"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
      <template
        v-if="hasTimerMessage"
        #footer-additional-message
      >
        <div class="magna-mission-modal__timer">
          <icon
            class="magna-mission-modal__icon"
            type="clock"
            stroke-width="3"
          />
          <p>
            Tempo disponível para resposta: {{ formmatedQuestionsTimerSum }}
          </p>
        </div>
      </template>
    </MagnaMissionModal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import GLoader from '@/components/GLoader'
import ClassPicker from '@/components/ClassPicker'
import { isEmpty } from 'lodash-es'
import MagnaMissionModal from './MagnaMissionModal'
import applicationType from '../enums/missionApplicationType'

export default {
  name: 'StartLiveMagnaMissionModal',
  components: {
    GLoader,
    MagnaMissionModal,
    ClassPicker,
  },
  props: {
    selectedItemCreateMissionModal: {
      type: Object,
      required: true,
      default: () => ({
        questionsTimerSum: 0,
        formmatedQuestionsTimerSum: '',
        title: '',
      }),
    },
  },
  data() {
    return {
      hasRankingOptional: false,
      selectedClassroom: this.selectedItemCreateMissionModal.classroom,
    }
  },
  computed: {
    ...mapState({
      showEurekaChallengeRanking: ({ featureToggles }) => featureToggles
        .toggles.eurekaChallengeRanking,
    }),
    ...mapGetters([ 'classrooms' ]),
    hasSelectedClass() {
      return !isEmpty(this.selectedClassroom)
    },
    tooltipInfoText() {
      return 'Exibe quais alunos tiveram mais acertos no final da questão'
    },
    isLoading() {
      return this.classrooms.loading
    },
    isReinforcementMission() {
      return this.selectedItemCreateMissionModal?.type?.toUpperCase() === 'CHALLENGE_REINFORCEMENT'
    },
    classroomSelectTitle() {
      return this.isReinforcementMission ? 'Você estará jogando com a turma:' : 'Com que turma você vai jogar?'
    },
    hasTimerMessage() {
      return this.selectedItemCreateMissionModal.questionsTimerSum > 0
    },
    formmatedQuestionsTimerSum() {
      return this.selectedItemCreateMissionModal.formmatedQuestionsTimerSum
    },
    title() {
      return this.selectedItemCreateMissionModal.title
    },
  },
  mounted() {
    this.getClassrooms()
  },
  beforeDestroy() {
    this.selectedClassroom = null
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    selectClassroom(classroom) {
      this.selectedClassroom = classroom
    },
    handleStartMission() {
      if (this.showEurekaChallengeRanking) {
        const label = this.hasRankingOptional
          ? this.$track.label.usedRanking : this.$track.label.notUsedRanking

        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.teacherOpenLiveArena,
          label,
        })
      }

      this.$emit('start', {
        selectedClassroom: this.selectedClassroom,
        hasRankingOptional: this.hasRankingOptional,
        applicationType: applicationType.LIVE,
      })
    },
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-modal {
  min-width: min(640px, 95vw);

  &__timer {
    margin: $size-xxs $size-m;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    font-weight: $font-weight-medium;
    color: $eureka-color-ink-light;
    letter-spacing: 0.0275rem;
    font-family: Lato;
    align-items: center;
  }

  ::v-deep &__icon {
    height: 24px;
    width: 24px;
  }
}

.create-magna-mission-modal {
  width: 100%;
  height: 100%;
  @include flex-center;

  &__content {
    padding: $size-xxl $size-m;
    @include mq_s {
      padding: $size-m 0;
    }

    &__loading-state {
      @include flex-center;
    }

    &__checkbox {
      @include flex-center;
      margin-top: $size-m;
    }

    &__label {
      font-family: Rowdies;
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      line-height: 1.24;
      color: $eureka-color-ink
    }

    ::v-deep &__icon {
      margin-left: $size-xs;
      stroke-width: 3;
      color: $eureka-color-ink-lighter;
      margin-right: $size-m;
      @include mq_s{
        margin-right: $size-xxs;
        margin-left: 0;
      }
    }

    &__classroom-select {
      @include flex-center;
      @include mq_s {
        flex-direction: column;
      }
      &__icon {
        width: 120px;
        height: auto;
        margin-right: $size-xl;
        @include mq_s {
          margin: 0;
          margin-bottom: $size-s;
          width: 30vw;
        }
      }

      &__main {
        .class-picker {
          ::v-deep .adaptive-selector-dropdown__handle {
            box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
            border: 1px solid $eureka-color-base-dark;
          }

          ::-webkit-scrollbar {
            width: 10px;
          }

          ::-webkit-scrollbar-thumb {
            background: rgba(26, 26, 26, 0.1);
          }

          ::-webkit-scrollbar-thumb:hover {
            background: rgba(26, 26, 26, 0.7);
          }
        }
      }

      &__main__title {
        color: $eureka-color-ink;
        font-weight: $font-weight-semi-bold;
        margin-bottom: $size-xs;
      }
    }
  }
}

.classroom-select {
  &__class-picker {
    &__icon {
      color: #473F78;
      opacity: 0.5;
      flex-shrink: 1;
      margin-right: $size-s;
      height: $size-m;
      width: $size-m;

      ::v-deep .feather__content {
        height: 100%;
        width: 100%;
      }
    }

    &__title {
      color: #473F78;
      font-weight: $font-weight-medium;
    }

    &__caret {
      margin-left: $size-xs;
      color: #473F78;
      opacity: 0.5;
    }
  }
}
</style>
