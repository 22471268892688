<template>
  <div
    class="arena-player"
    :style="cssVars"
  >
    <div
      v-tooltip.top="student.name"
      class="arena-player__profile-picture-container"
    >
      <img
        v-if="hasProfilePicture && !hasProfilePictureError"
        class="arena-player__profile-picture"
        :src="profilePicture"
        alt=""
        @error="imageUrlAlt"
      >
      <div
        v-else
        class="arena-player__profile-picture --initials"
      >
        {{ initials }}
      </div>
      <img
        alt=""
        class="arena-player__game-icon"
        :src=" student.gameIcon.url"
      >
    </div>
  </div>
</template>

<script>
import urls from '@/utils/urls'
import isEmpty from 'lodash/isEmpty'

const FALLBACK_COLOR = '#c9cccf'

export default {
  name: 'ArenaPlayerProfilePicture',
  props: {
    student: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        name: '',
        profilePicture: null,
        gameIcon: {
          url: null,
          color: null,
        },
        insignia: {
          name: null,
          image: null,
        },
        loggedAt: null,
        logged: false,
      }),
    },
  },
  data() {
    return {
      hasProfilePictureError: false,
    }
  },
  computed: {
    hasProfilePicture() {
      return !isEmpty(this.student.profilePicture)
    },
    profilePicture() {
      return `${urls.STORAGE_URL}/files/${this.student.profilePicture}`
    },
    initials() {
      return this.student.name.trim()[0]?.toUpperCase() ?? ''
    },
    gameIconColor() {
      return this.student?.gameIcon?.color ?? FALLBACK_COLOR
    },
    cssVars() {
      return {
        '--game-icon-color': this.gameIconColor,
      }
    },
  },
  methods: {
    imageUrlAlt() {
      this.hasProfilePictureError = true
    },
  },
}
</script>

<style lang="scss" scoped>
.arena-player {
  display: grid;
  grid-template:
    'profile-picture name'
    'profile-picture insignia'
    / 80px 1fr;
  grid-gap: $size-xs $size-m;
  padding: $size-s 0 20px;
  color: $color-white;

  &__profile-picture-container {
    grid-area: profile-picture;
    width: 80px;
    height: 56px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: contain;
    overflow: hidden;
    background-color: var(--game-icon-color);
    border: 3px solid var(--game-icon-color);

    &.--initials {
      background-color: $color-ink-lightest;
      font-size: 1.875rem;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      color: $color-ink-light;
      cursor: default;
    }
  }

  &__game-icon {
    position: absolute;
    height: 30px;
    width: 30px;
    bottom: -10px;
    right: 0px;
    border-radius: $border-radius-circle;
    background-color: var(--game-icon-color);
    object-fit: contain;
  }

  &__name {
    grid-area: name;
    text-align: left;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-4;
    display: flex;
    align-items: flex-start;
    color: $color-white;
    line-height: 100%;
  }

  &__insignia {
    grid-area: insignia;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-gap: $size-xs;
    align-items: center;
    text-align: left;
    font-family: Lato;
    font-weight: $font-weight-regular;
    font-size: $font-size-m;
    color: $color-white;
  }

  &__insignia-image {
    height: 24px;
    width: 24px;
    object-fit: contain;
    display: inline-flex;
  }

  &__insignia-text {
    font-family: Lato;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $font-size-m;
    color: $color-white;
  }
}
</style>
