import Vue from 'vue'
import Router from 'vue-router'
import { isAuthenticated, logout } from '@/utils/auth'
import { pauseBackground } from './utils/soundEffects'
import { createRoutes } from './utils/routesConfig'

const HomeTeacher = () => import('@/views/HomeTeacher')

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  routes: createRoutes(HomeTeacher),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }

    return null
  },
})

router.beforeEach((to, from, next) => {
  if (isAuthenticated()) {
    next()
  } else {
    logout({ redirectBack: true })
    next(false)
  }

  if (to.name === 'home-teacher') {
    pauseBackground()

    return next()
  }

  return next()
})

export default router
