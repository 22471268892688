<template>
  <img
    v-if="iconOnly"
    :src="exclamationCircle"
    alt=""
    class="ancestral-arena-error__exclamation-circle --icon-only"
  >
  <section
    v-else
    class="ancestral-arena-error"
  >
    <img
      :src="exclamationCircle"
      alt=""
      class="ancestral-arena-error__exclamation-circle"
    >
    <p class="ancestral-arena-error__message">
      {{ errorMessage }}
    </p>
  </section>
</template>

<script>
import exclamationCircle from '@/assets/exclamation-circle.svg'

export default {
  name: 'AncestralArenaErrorReport',
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    iconOnly: Boolean,
  },
  data() {
    return {
      exclamationCircle,
    }
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-error {
  @include flex-center;
  justify-content: flex-start;

  &__message {
    display: inline-block;
    color: $eureka-color-danger-dark;
    font-family: Lato;
  }

  &__exclamation-circle {
    margin-right: 4px;
    height: $size-m;
    width: $size-m;
    background: {
      repeat: no-repeat;
      position: center;
      image: url('~@/assets/exclamation-circle.svg');
    }

    &.--icon-only {
      margin: 0;
    }
  }
}
</style>
