<template>
  <div class="magna-mission-result-card">
    <slot name="skeleton">
      <div class="badge-container">
        <div class="badge">
          <span class="badge__number">{{ order }}</span>
        </div>
        <img
          v-show="hasIcon"
          v-tooltip.top="missionTypeContent.tooltip"
          alt=""
          :src="require(`@/assets/${missionTypeContent.icon}`)"
          class="magna-mission-result-card-icon"
        >
        <strong class="badge__title">{{ title }}</strong>
      </div>
      <div class="icon-text">
        <img
          class="icon-text__image"
          :src="user"
          alt=""
        >
        <span class="icon-text__info">{{ classroomName }}</span>
      </div>
      <div class="icon-text">
        <img
          class="icon-text__image"
          :src="calendar"
          alt=""
        >
        <span class="icon-text__info">{{ finishedMagnaMissionDate }}</span>
      </div>
      <GButton
        class="variant"
        size="large"
        @click="redirectToAssignmentReport"
      >
        Relatório
      </GButton>
    </slot>
  </div>
</template>

<script>
import formatDate from '@/utils/formatDate'
import redirectTo from '@/utils/redirectTo'
import calendar from '@/assets/ancestral-arena/calendar-filled.svg'
import user from '@/assets/ancestral-arena/user.svg'
import GButton from '@/components/GButton'
import missionApplicationType from '@/components/AncestralArena/enums/missionApplicationType'
import { getAssignmentReportURL } from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

const isMicrofrontend = getIsMicrofrontend()

export default {
  name: 'MagnaMissionResultCard',
  components: {
    GButton,
  },
  props: {
    magnaMission: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      calendar,
      user,
      isModalVisible: false,
    }
  },
  computed: {
    order() {
      return this.magnaMission?.order
    },
    hasIcon() {
      return this.magnaMission?.applicationType
    },
    missionTypeContent() {
      switch (this.magnaMission?.applicationType) {
        case missionApplicationType.LIVE:
          return {
            tooltip: 'Missão ao vivo',
            icon: 'ancestral-arena/missions/rounded-mission-type-live.svg',
          }

        case missionApplicationType.HOME:
          return {
            tooltip: 'Missão para casa',
            icon: 'ancestral-arena/missions/rounded-mission-type-home.svg',
          }

        default:
          return {
            tooltip: 'Missão ao vivo',
            icon: 'ancestral-arena/missions/rounded-mission-type-live.svg',
          }
      }
    },
    title() {
      return this.magnaMission?.title
    },
    classroomName() {
      return this.magnaMission?.classroom.name
    },
    questionnaireCode() {
      return this.magnaMission?.code
    },
    classroomId() {
      return this.magnaMission?.classroom.id
    },
    finishedMagnaMissionDate() {
      return formatDate.brazillianDateFormat(this.magnaMission?.finishedAt)
    },
  },
  methods: {
    redirectToAssignmentReport() {
      const baseUrl = getAssignmentReportURL(isMicrofrontend)

      redirectTo(`${baseUrl}/questionnaires/${this.questionnaireCode}/classes/${this.classroomId}/general`)
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-result-card {
  @include flex-space-between;
  padding: $size-s $size-m;
  height: fit-content;
  background-color: $eureka-color-base;
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    gap: 12px;
    flex-direction: column;
    padding: $size-s;
  }

  ::v-deep .g-button {
    border: none;
    font-family: Rowdies;
    text-shadow: 0 1px 0 $color-black;
  }

  &-icon {
    width: $size-l;
    height: $size-l;
    margin-right: $size-s;
  }
}

.icon-text {
  gap: $size-s;
  width: 100%;
  @include flex-center;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  &__image {
    width: 34px;
    padding: 0 6px;
    margin: 0;
  }

  &__info {
    font-family: Rowdies;
    color: $eureka-color-ink;
  }
}

.badge-container {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
}

.badge {
  display: inline;
  background:$eureka-color-base-darker;
  border-radius: $border-radius-s;
  width: $size-l;
  height: $size-l;
  margin-right: $size-s;
  text-align: center;

   &__title {
    font: {
      family: Rowdies;
      size: $font-size-heading-5;
      color: $eureka-color-ink;
      margin: $size-xxs 0;
    }
  }

  &__number {
    font: {
      family: Rowdies;
      size: $font-size-heading-5;
      opacity: 0.7;
      color: $eureka-color-ink;
      margin: 10px 0;
    }
  }
}
</style>
