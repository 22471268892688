<template>
  <ul class="accordion">
    <li class="accordion__item">
      <div
        class="accordion__trigger"
        :class="{'accordion__trigger_active': isVisible}"
        @click="toggleAccordion"
      >
        <slot name="accordion-header" />
      </div>
      <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
      >
        <div
          v-show="isVisible"
          ref="accordionContent"
          class="accordion__content"
        >
          <slot name="accordion-content" />
        </div>
      </transition>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
  },
  methods: {
    toggleAccordion() {
      this.$emit('change-visibility', !this.isVisible)
    },
    start(el) {
      this.$refs.accordionContent.style.height = `${el.scrollHeight}px`
    },
    end() {
      this.$refs.accordionContent.style.height = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item:last-child {
    border-bottom: none;
  }

  &__content {
    cursor: initial;
  }
}

.accordion__item {
  cursor: pointer;
  padding: 10px 20px 10px 40px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 10px 20px 10px 20px;
  }
}

.accordion__trigger {
  display: flex;
  justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height $speed-normal ease, opacity $speed-normal ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
