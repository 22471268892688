<template>
  <div
    :class="[
      $style.container,
      {
        [$style.isSelected]: isSelected,
        [$style.isFocused]: isFocused,
      }]"
    @click="onClick"
  >
    <div
      v-if="isSelected && questionNumber"
      :class="$style.badge"
    >
      Questão {{ questionNumber }}
    </div>

    <div
      :class="$style.content"
      v-html="statement"
    >
      {{ statement }}
    </div>
    <div :class="$style.separator" />
    <div :class="$style.footer">
      <div :class="$style.tagsContainer">
        <div
          v-tooltip.bottom="'Dificuldade'"
          tabindex="-1"
          :class="$style.tag"
        >
          {{ difficultyLvl }}
        </div>
        <div
          tabindex="-1"
          :class="$style.tag"
        >
          {{ grade }}
        </div>
      </div>
      <p class="view-question">
        <GButton
          variation="secondary"
          tabindex="0"
          size="small"
          :icon-stroke="3"
          :class="$style.questionPreviewViewQuestion"
          @click="onClick"
        >
          <p>Visualizar questão</p>
          <icon
            type="chevron-right"
            :class="$style.icon"
          />
        </GButton>
      </p>
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

const difficultyEnum = {
  EASY: 'Fácil',
  MEDIUM: 'Médio',
  HARD: 'Difícil',
}

export default {
  name: 'MagnaMissionSASQuestionPreviewCard',
  components: {
    GButton,
  },
  props: {
    question: {
      type: Object,
      default: () => ({}),
    },
    questionNumber: {
      type: Number,
      required: false,
      default: null,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    statement: {
      type: String,
      default: '',
      required: true,
    },
    grade: {
      type: String,
      default: '',
      required: true,
    },
    difficulty: {
      type: String,
      default: '',
      required: true,
      validator: (value) => value.match(/(EASY|MEDIUM|HARD)/),
    },
    isFocused: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    difficultyLvl() {
      return difficultyEnum[this.difficulty] ?? ''
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click')
    },
  },
}
</script>

<style lang="scss" module>
.container {
  height: 244px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-white;
  border-radius: $size-s;
  box-shadow: 0 3px 0 #c2ab8c, inset 0 1px 0 rgba($color-white, 0.75);
  padding-top: $size-xxs;
  margin: 0 $size-s;

  @media screen and (max-width: 768px) {
    min-width: 350px;
    max-width: 350px;
    padding-top: 0;
    margin: 0 2px;
  }

  &:first-of-type {
    margin-top: $size-s;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  &:hover:not(.isSelected) {
    box-shadow: 0 6px 0 #c2ab8c, inset 0 3px 0 rgba($color-white, 0.75);
    outline: $size-xxs solid $eureka-color-base-darker;
  }

  &:focus:not(.isSelected) {
    outline: $size-xxs solid $eureka-color-base-darker;
    background: $eureka-color-base-lighter;
  }

  :global {
    .g-button__text {
      font-weight: $font-weight-medium;
      display: flex;
      align-items: center;
    }
  }
}

.badge {
  width: 110px;
  padding-left: $size-xxs;
  font-family: Rowdies;
  font-weight: $font-weight-medium;
  color: $color-white;
  background: $eureka-color-secondary;
  border-radius: $size-s 0 $size-s;
  position: absolute;
  top: 0;
  left: 0;
}

.isFocused {
  outline: $size-xxs solid $eureka-color-base-darker;
  background: $eureka-color-base-lighter;
}

.isSelected {
  outline: 5px solid $eureka-color-secondary;
  filter: drop-shadow(0 $size-xxs 0 #004eb3);
  margin-top: $size-xxs;

  .content {
    margin-top: $size-m;
  }
}

.content {
  overflow: hidden;
  margin: $size-s;
  max-width: 344px;
  min-height: 160px;
  line-height: 20px;
  font-family: Rowdies;
  font-weight: $font-weight-light;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  img {
    height: 100%;
    width: 100%;
    margin-top: $size-s;
    object-fit: scale-down;
    display: block;
  }

  p {
    margin-left: 0 !important;
  }
}

.questionPreviewViewQuestion {
  &:focus,
  &:hover {
    background: rgba($color-black, 0.07);
  }

  svg {
    stroke-width: 3;
  }
}

.separator {
  border-bottom: 1px solid $eureka-color-ink-lightest;
  width: 100%;
}

.footer {
  font-family: Rowdies;
  color: $eureka-color-ink;
  display: flex;
  justify-content: space-between;
  padding: $size-xs;

  .tagsContainer {
    display: flex;
    gap: $size-s;

    .tag {
      background: $eureka-color-ice;
      padding: 0 12px;
      border: 1px solid $eureka-color-ink-lightest;
      border-radius: $size-m;
      font-size: $font-size-s;
    }
  }

  :global {
    .feather {
      height: $size-s;
    }
  }
}
</style>
