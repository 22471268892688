<template>
  <header class="arena-ranking-header">
    <div class="arena-ranking-header__max-hit">
      {{ enigmaPosition }}
      <div class="arena-ranking-header__max-hit-subtitle">
        / {{ totalEnigmas }}
      </div>
    </div>
    <div class="arena-ranking-header__separator" />
    <div class="arena-ranking-header__title">
      Classificação de acertos
    </div>
    <GButton
      v-if="!isMobile"
      class="arena-ranking-header__button"
      @click="clickAction"
    >
      {{ textButton }}
    </GButton>
  </header>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'AncestralArenaRankingHeader',
  components: {
    GButton,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true,
    },
    enigmaPosition: {
      type: Number,
      required: true,
    },
    totalEnigmas: {
      type: Number,
      required: true,
    },
    clickAction: {
      type: Function,
      required: true,
    },
    textButton: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.arena-ranking-header {
  font-family: Rowdies;
  color: $color-white;
  padding: $size-l;
  display: inline-grid;
  grid-gap: $size-s;
  grid-template-columns: auto auto 1fr auto;
  width: 100%;
  background: rgba(10, 10, 10, 0.3);
  border-radius: 16px 16px 0 0;

  @media screen and (max-width: 991px) {
    padding: $size-l $size-l $size-l $size-s;
  }

  &__max-hit {
    display: inline-flex;
    align-items: center;
    font-size: 2.5rem;
    line-height: 100%;

    @media screen and (max-width: 991px) {
      align-items: flex-end;
      display: flex;
      font-family: Rowdies;
      font-size: $font-size-heading-4;
      font-weight: $font-weight-regular;
      line-height: $line-height-input;
      text-align: left;
    }
  }

  &__max-hit-subtitle {
    align-self: flex-start;
    font-size: $font-size-heading-4;
    line-height: 100%;
    margin-left: 6px;

    @media screen and (max-width: 991px) {
      font-family: Rowdies;
      font-size: $font-size-m;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      text-align: left;
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
  }

  &__title {
    display: inline-flex;
    align-items: center;
    font-size: 2rem;
    line-height: 100%;

    @media screen and (max-width: 991px) {
      font-family: Rowdies;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }

  &__button {
    border: none;
    margin-top: 6px;
    font-size: $font-size-m;
    text-shadow: 0px 1px 0px $color-black;

    @media screen and (max-width: 991px) {
    }
  }
}
</style>
