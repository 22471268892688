<template>
  <div :class="[$style.content, { [$style.showSidebarContent]: !showSidebar }]">
    <GButton
      v-if="isTablet"
      :class="[
        $style.hideFilters,
        { [$style.slideSidebarButton]: !slideSidebarButton },
      ]"
      :icon="handleIcon"
      size="large"
      variation="secondary"
      @click="handleSideBar"
    />
    <aside
      :class="[
        $style.container,
        { [$style.sidebarTablet]: isTablet },
        { [$style.showSidebar]: !showSidebar },
      ]"
    >
      <header :class="$style.header">
        <h4 :class="$style.title">
          Filtrar Questões
        </h4>
        <h5 :class="$style.subtitle">
          {{ questionsCount }} questões encontradas
        </h5>
      </header>
      <section
        v-if="!isMobile"
        :class="$style.bodyContent"
      >
        <DropdownAction
          class="MagnaMissionQuestionSearchFilters"
          :items="grades"
          multiselect-counter
          multiselect
          placeholder="Selecionar séries"
          aria-label="Selecionar séries"
          :value="selectedFilters.grades"
          @change="setGrades($event)"
        />

        <DropdownAction
          class="MagnaMissionQuestionSearchFilters"
          :items="subjects"
          placeholder="Selecionar disciplina"
          aria-label="Selecionar disciplina"
          :value="selectedFilters.subjects"
          @change="setSubjects($event)"
        />

        <DropdownTreeSelector
          class="MagnaMissionQuestionSearchFilters"
          placeholder="Selecionar assuntos"
          aria-label="Selecionar assuntos"
          item-value="id"
          item-text="name"
          reset-when-items-change
          :disabled="!hasContents || !canGetContents"
          :items="filterContents.data"
          :value="selectedFiltersContents"
          @change="setContents($event)"
        />

        <DropdownAction
          class="MagnaMissionQuestionSearchFilters"
          :value="selectedFilters.difficulties"
          :items="difficulties"
          placeholder="Selecionar dificuldade"
          aria-label="Selecionar dificuldade"
          @change="setDifficulties($event)"
        />
      </section>
      <div
        v-else
        :class="$style.dropdownContainerMobile"
      >
        <DropdownActionQuestionSearch
          class="MagnaMissionQuestionSearchFilters"
          :items="grades"
          multiselect-counter
          multiselect
          placeholder="Selecionar séries"
          aria-label="Selecionar séries"
          title="Filtrar séries"
          :value="selectedFilters.grades"
          @change="setGrades($event)"
        />
        <DropdownActionQuestionSearch
          class="MagnaMissionQuestionSearchFilters"
          title="Filtrar disciplinas"
          :items="subjects"
          placeholder="Selecionar disciplina"
          aria-label="Selecionar disciplina"
          :value="selectedFilters.subjects"
          @change="setSubjects($event)"
        />
        <DropdownTreeSelector
          class="MagnaMissionQuestionSearchFiltersMobile"
          placeholder="Selecionar assuntos"
          aria-label="Selecionar assuntos"
          item-value="id"
          item-text="name"
          reset-when-items-change
          :disabled="!hasContents || !canGetContents"
          :items="filterContents.data"
          :value="selectedFiltersContents"
          @change="setContents($event)"
        />
        <DropdownActionQuestionSearch
          title="Filtrar dificuldade"
          class="MagnaMissionQuestionSearchFilters"
          :value="selectedFilters.difficulties"
          :items="difficulties"
          placeholder="Selecionar dificuldade"
          aria-label="Selecionar dificuldade"
          @change="setDifficulties($event)"
        />
      </div>
    </aside>
  </div>
</template>

<script>
import arenaApi from '@/service/arena'
import userAgent from '@/utils/userAgent'
import DropdownAction from '@/components/DropdownAction/DropdownAction'
import DropdownTreeSelector from '@/components/DropdownTreeSelector/DropdownTreeSelector'
import { isEmpty } from 'lodash-es'
import GButton from '@/components/GButton'
import arrowLeft from '@/assets/ancestral-arena/arrow-left.svg'
import DropdownActionQuestionSearch from '@/components/DropdownActionQuestionSearch/DropdownActionQuestionSearch'
import mediaQueries from '@/mixins/mediaQueries'

const difficultiesEnum = {
  EASY: 'Fácil',
  MEDIUM: 'Médio',
  HARD: 'Difícil',
}

const allSubjects = {
  text: 'Todas as disciplinas',
  value: 'all',
  id: 'all',
}

const allDifficulties = {
  text: 'Todas as dificuldades',
  value: 'all',
  id: 'all',
}

const pickIds = (array) => (!isEmpty(array) && array?.map((item) => item.id)) || []

export default {
  name: 'MagnaMissionQuestionSearchFilters',
  components: {
    DropdownAction,
    DropdownTreeSelector,
    GButton,
    DropdownActionQuestionSearch,
  },
  mixins: [ mediaQueries ],
  props: {
    questions: {
      type: Object,
      default: () => ({
        isLoading: false,
        data: null,
        error: null,
      }),
    },
  },
  data() {
    return {
      arrowLeft,
      slideSidebarButton: true,
      showSidebar: true,
      visible: true,
      paramFilters: {
        isLoading: false,
        data: null,
        error: null,
      },
      selectedFilters: {
        grades: [],
        subjects: null,
        difficulties: null,
        contents: [],
      },
      filterContents: {
        isLoading: false,
        data: null,
        error: null,
      },
    }
  },
  computed: {
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    selectedFiltersContents() {
      return this.selectedFilters.contents ?? []
    },
    hasContents() {
      return !isEmpty(this.filterContents.data)
    },
    handleIcon() {
      return this.showSidebar ? 'chevron-left' : 'chevron-right'
    },
    grades() {
      return (
        this.paramFilters.data?.grades?.map((grade) => ({
          text: grade.name,
          value: grade.id,
          id: grade.id,
        })) ?? []
      )
    },
    subjects() {
      const subjects = this.paramFilters.data?.subjects?.map((subject) => ({
        text: subject.name,
        value: subject.id,
        id: subject.id,
      })) ?? []

      return [ allSubjects, ...subjects ]
    },
    difficulties() {
      const difficulties = this.paramFilters.data?.difficulties?.map((difficult) => {
        const difficultText = difficultiesEnum[difficult] ?? ''

        return {
          text: difficultText,
          value: difficult,
          id: difficult,
        }
      }) ?? []

      return [ allDifficulties, ...difficulties ]
    },
    questionsParams() {
      const cleanAllOption = (value) => (value === 'all' ? null : value)

      const gradeIds = pickIds(this.selectedFilters.grades)
      const subjectIds = cleanAllOption(this.selectedFilters.subjects?.id)
      const difficulty = cleanAllOption(this.selectedFilters.difficulties?.id)
      const contentIds = pickIds(this.selectedFilters.contents)

      return {
        gradeIds,
        subjectIds,
        difficulty,
        contentIds,
      }
    },
    questionsCount() {
      return this.questions.data?.totalElements ?? 0
    },
    contentsParamsFetch() {
      const gradeIds = pickIds(this.selectedFilters.grades)
      const subjectId = this.selectedFilters.subjects?.id

      return {
        gradeIds,
        subjectId,
      }
    },
    canGetContents() {
      return (
        !isEmpty(this.selectedFilters.grades)
        && !isEmpty(this.selectedFilters.subjects)
        && this.selectedFilters.subjects.id !== allDifficulties.id
      )
    },
    isTablet() {
      return userAgent.isTablet()
    },
  },
  watch: {
    questionsParams: {
      handler(params, oldParams) {
        this.getFilterContents()

        if (params.subjectIds !== oldParams.subjectIds) {
          this.selectedFilters.contents = []
        }
        this.$emit('on-change', params)
      },
      deep: true,
    },
    canGetContents() {
      this.getFilterContents()
    },
  },
  activated() {
    this.getParamFilters()
  },
  deactivated() {
    this.initializeFilters()
  },
  created() {
    this.getParamFilters()
  },
  methods: {
    handleSideBar() {
      this.showSidebar = !this.showSidebar
      this.slideSidebarButton = !this.slideSidebarButton
    },
    initializeFilters() {
      this.selectedFilters = {
        grades: [],
        subjects: null,
        difficulties: null,
        contents: [],
      }
    },
    setGrades(grades) {
      this.selectedFilters.grades = grades
    },
    setSubjects(subjects) {
      this.selectedFilters.subjects = subjects
    },
    setDifficulties(difficulties) {
      this.selectedFilters.difficulties = difficulties
    },
    setContents(contents) {
      this.selectedFilters.contents = contents
    },
    async getParamFilters() {
      this.paramFilters.isLoading = true
      this.paramFilters.data = null
      this.paramFilters.error = null

      try {
        const { data } = await arenaApi.getFilters()
        this.paramFilters.data = data
      } catch (error) {
        this.paramFilters.error = error
      } finally {
        this.paramFilters.isLoading = false
      }
    },
    async getFilterContents() {
      if (!this.canGetContents) {
        return
      }

      this.filterContents.isLoading = true
      const params = this.contentsParamsFetch

      try {
        const { data } = await arenaApi.getFilterContents(params)
        this.filterContents.data = data
        this.filterContents.error = null
      } catch (error) {
        this.filterContents.data = null
        this.filterContents.error = error
      } finally {
        this.filterContents.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  background: $eureka-color-base;
  box-shadow: 0px 3px 0px #c2ab8c, inset 0px 1px 0px rgba($color-white, 0.75);
  height: 100vh;
  max-height: 100%;
  width: 280px;
  max-width: 290px;
  padding: $size-s;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  @media screen and (max-width: 579px) {
    flex-direction: column;
  }

  @media screen and (max-width: 991px) {
    border-radius: $size-s;
  }
}

.content {
  max-width: 290px !important;
  min-width: 255px;
  position: relative;
  background: $eureka-color-base;
  transition: all $speed-normal ease $speed-fast;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
    min-width: 100%;
  }

  @media screen and (max-width: 991px) {
    min-width: auto;
    max-width: 100% !important;
    margin-bottom: 0;
    border-radius: $size-s;
    margin: $size-s;
  }

  .hideFilters {
    right: -0.75rem;
    top: 0.5em;
    position: absolute;
    background-color: $eureka-color-base-dark;
    box-shadow: 0px 3px 0px #c2ab8c, inset 0px 1px 0px rgba($color-white, 0.75);
    border-radius: $border-radius-circle;
    z-index: $zindex-modal;

    &:hover {
      background-color: $eureka-color-base-light;
    }

    &:active {
      background-color: $eureka-color-base-darker;
      box-shadow: 0 1px 0 #c2ab8c,
        inset 0 1px 0 rgba($color-white, 0.75);
    }
  }
}

.showSidebar {
  transform: translateX(-95%);
  transition: all $speed-normal ease $speed-fast;
  opacity: 0;

  &Content {
    min-width: 20px !important;
    max-width: 20px !important;
  }
}

.slideSidebarButton {
  transform: translateX(10%);
  transition: all $speed-normal ease $speed-fast;
}

.bodyContent {
  display: flex;
  flex-direction: column;
  gap: $size-s;

  @media screen and (max-width: 1200px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
}

.dropdownContainerMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
  gap: $size-xs;
  margin-bottom: $size-s;

  @media screen and (max-width: 1200px) {
    margin-bottom: 0;
    min-width: 200px;
  }

  @media screen and (max-width: 579px) {
    text-align: center;
    width: 100%;
  }
}

.title {
  font-family: Rowdies;
  font-weight: $font-weight-regular;
  font-size: $font-size-heading-5;
  line-height: 100%;
  color: $eureka-color-ink;
}

.subtitle {
  font-family: Lato;
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: $line-height-text;
  display: flex;
  align-items: center;
  letter-spacing: 0.45px;

  @media screen and (max-width: 579px) {
    justify-content: center;
  }
}
</style>
