<template>
  <div class="input-timer">
    <GButton
      class="input-timer__button"
      size="medium"
      icon="minus-circle"
      data-testid="decrement-time"
      variation="secondary"
      aria-label="Diminuir tempo"
      :disabled="isDisabled"
      @click="decrementTime"
    />
    <input
      id="time"
      v-model="responseTimeText"
      type="text"
      data-testid="input-time"
      aria-label="Input de tempo"
      autocomplete="off"
      :disabled="isDisabled"
      @focus="focusHandler"
      @blur="blurHandler"
    >
    <GButton
      class="input-timer__button"
      size="medium"
      data-testid="increment-time"
      aria-label="Aumentar tempo"
      icon="plus-circle"
      variation="secondary"
      :disabled="isDisabled"
      @click="incrementTime"
    />
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'InputTimer',
  components: {
    GButton,
  },
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 300,
    },
    defaultTime: {
      type: Number,
      default: 30,
    },
    time: {
      type: Number,
      default: null,
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      responseTimeText: `${this.defaultTime} s`,
    }
  },
  watch: {
    time(data) {
      this.responseTimeText = !data ? `${this.defaultTime} s` : `${this.format(data)} s`
    },
    responseTimeText(data) {
      const formattedTime = this.format(data)

      this.$emit('change-time', Number(formattedTime))
    },
  },
  created() {
    this.responseTimeText = `${this.time} s`
  },
  methods: {
    focusHandler() {
      this.responseTimeText = this.format(this.responseTimeText)
    },
    blurHandler() {
      this.responseTimeText = `${this.format(this.responseTimeText)} s`
    },
    format(data) {
      const everythingButNumbers = data.toString().replace(/[^0-9]/g, '')

      if (this.min && Number.isNaN(Number(everythingButNumbers))) {
        return String(this.min)
      }

      const seconds = Number(everythingButNumbers)

      if (seconds < this.min) {
        return String(this.min)
      }

      if (seconds > this.max) {
        return String(this.max)
      }

      return String(seconds)
    },
    incrementTime() {
      const time = Number(this.format(this.responseTimeText))

      if (time < this.max) {
        this.responseTimeText = `${time + 1} s`
      }
    },
    decrementTime() {
      const time = Number(this.format(this.responseTimeText))

      if (time > this.min) {
        this.responseTimeText = `${time - 1} s`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.input-timer {
  display: flex;
  align-items: center;

  #time {
    max-width: 72px;
    max-height: 28px;
    background: $color-white url('~@/assets/timer.svg') no-repeat left center;
    padding: $size-xs 0 $size-xs $size-m;
    border-radius: $border-radius-m;
    border: 1px solid $color-ink-lightest;
    font-family: Lato;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    letter-spacing: 0.0275rem;

    &:disabled {
      background-color: $color-ice;
      box-shadow: inset 0 2px 2px rgba($color-ink, 0.07);
      opacity: 0.5;
      cursor: not-allowed;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }

  &__button {
    ::v-deep &.g-button {
      color: $eureka-color-ink;

      &:disabled {
        background: none !important;
        cursor: not-allowed;
        color: $color-ink-lightest !important;
        border: none !important;
      }
    }
  }

}
</style>
