<template>
  <div
    :class="[
      'map-side',
      `--${side}`,
    ]"
  >
    <img
      v-if="theme.missions.top[side]"
      class="map-side__header"
      :src="theme.missions.top[side]"
      alt=""
    >
    <div
      v-for="(key, index) in tiles"
      :key="index"
      class="map-side__group"
    >
      <img
        class="map-side__group__template"
        :src="theme.missions.middle[side][0]"
        alt=""
      >

      <img
        class="map-side__group__template"
        :src="theme.missions.middle[side][1]"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapSide',
  props: {
    side: {
      type: String,
      default: '',
      required: true,
      validator: (value) => value.match(/(left|right)/),
    },
    missionTrackHeight: {
      type: Number,
      default: 0,
      required: true,
    },
    theme: {
      type: Object,
      default: () => {},
      required: true,
    },
    missionsAmount: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      isBaseImageLoaded: false,
      tiles: [],
    }
  },
  computed: {
    assetsLength() {
      return this.theme.missions.middle[this.side].length
    },
    assetsIndexes() {
      return Array(this.assetsLength).fill(1).map((it, i) => i)
    },
    baseImageHeight() {
      return this.isBaseImageLoaded && this.$refs.baseImage.clientHeight
    },
  },
  watch: {
    missionTrackHeight() {
      this.replicateTiles()
    },
    baseImageHeight() {
      this.replicateTiles()
    },
  },
  methods: {
    screenRatio() {
      const { devicePixelRatio } = window
      const { width, height } = window.screen
      const screenSizes = [
        width * devicePixelRatio,
        height * devicePixelRatio,
      ]

      screenSizes.sort((a, b) => a - b)

      return (screenSizes[0] / screenSizes[1]) * 0.75
    },
    replicateTiles() {
      const tiles = []
      const count = parseInt(this.missionsAmount * this.screenRatio(), 10)

      for (let i = 0; i < count; i += 1) {
        tiles.push(this.assetsIndexes[Math.abs((i % 2) - 1)])
      }

      this.tiles = tiles
    },
  },
}
</script>

<style lang="scss" scoped>
.map-side {
  $gridGap: 8vh;

  position: relative;
  height: 100%;
  padding-top: var(--topOffset);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $gridGap;

  & * {
    user-select: none;
    -webkit-user-drag: none;
  }

  &__header {
    width: min(100%, 25rem);
  }

  &__group {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 20%;
    overflow: hidden;
    gap: $gridGap;

    &__template {
      width: min(100%, 20rem);
      height: 50%;
    }

    .--right & {
      align-items: flex-end;
    }
  }
}
</style>
