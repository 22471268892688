<template>
  <div class="teacher-top-bar">
    <p
      class="teacher-top-bar__title"
      v-html="topbarTitle"
    />
    <dropdown
      v-if="!selectorIsHidden"
      :text="dropdownText"
      size="medium"
      menu-width="100%"
      class="teacher-top-bar__grade-selector"
    >
      <dropdown-menu-item
        v-for="grade in userGradesData"
        :key="grade.name"
        class="teacher-top-bar__grade-selector__item"
        @click="selectGrade(grade)"
      >
        {{ grade.name }}
      </dropdown-menu-item>
    </dropdown>
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import gradeId from '@/mixins/gradeId'
import { logosGrades } from '@/utils/logosGrades'

export default {
  name: 'TeacherTopbar',
  mixins: [ gradeId ],
  props: {
    selectorIsHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedGrade: null,
    }
  },
  computed: {
    ...mapGetters([
      'userGrades',
      'lectures',
    ]),
    userGradesData() {
      const isLogosRoute = this.$route.path.includes('logos')

      if (isLogosRoute) {
        return this.filterLogosGrades(this.userGrades.data)
      }

      return this.userGrades.data
    },
    topbarTitle() {
      const gradeName = !this.selectorIsHidden
        ? ' '
        : `• ${this.previousSelectedGrade.name}`

      return this.$t('teacher.viewingEurekaAsStudent', { gradeName })
    },
    dropdownText() {
      return this.selectedGrade ? this.selectedGrade.name : ''
    },
    firstUserGrade() {
      return this.userGradesData?.length ? this.userGradesData[0] : null
    },
    previousSelectedGrade() {
      return this.userGradesData?.find((grade) => grade.id === this.gradeId)
    },
  },
  created() {
    const {
      previousSelectedGrade,
      firstUserGrade,
      lectures,
    } = this
    this.getReallocationDateLimit()
    this.setCurrentGrade({ id: this.gradeId })

    if (this.gradeId) {
      this.selectGrade(previousSelectedGrade)
      this.selectedGrade = previousSelectedGrade
    } else {
      this.selectGrade(firstUserGrade)
      this.selectedGrade = firstUserGrade
    }

    if (lectures.data === null && this.selectedGrade) {
      this.getLectures(this.selectedGrade.id)
    }
  },
  methods: {
    ...mapActions([
      'getLectures',
      'setCurrentGrade',
      'getUserGameData',
      'getReallocationDateLimit',
    ]),
    filterLogosGrades(grades) {
      return grades.filter((grade) => {
        const currentGradeId = grade.id

        const isLogosGrade = logosGrades.includes(currentGradeId)

        return isLogosGrade
      })
    },
    selectGrade(grade) {
      if (this.gradeId !== grade.id) {
        const lectureId = this.validateLecture(grade)

        this.selectedGrade = grade
        this.setCurrentGrade(grade)
        this.getLectures(grade.id)
        this.getUserGameData(grade.id)
        this.$trackEvent({
          category: this.$track.category.eurekaTeacher,
          action: this.$track.action.choiceGrade,
          label: this.selectedGrade.name,
        })

        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            gradeId: grade.id,
            lectureId,
          },
        })
      }
    },
    validateLecture(grade) {
      const { lectureId } = this.$route.query
      const isMiddleSchollGrade = 3
      const isHighSchoolGrade = 16

      const isMiddleSchoolScience = lectureId === '1153'
      const isHighSchoolScience = lectureId === '1123' || lectureId === '1110' || lectureId === '1111'

      if (
        (grade.id === isMiddleSchollGrade && isHighSchoolScience)
        || (grade.id === isMiddleSchollGrade && lectureId === undefined)
      ) {
        return '1153'
      } if (
        (grade.id === isHighSchoolGrade && isMiddleSchoolScience)
        || (grade.id === isHighSchoolGrade && lectureId === undefined)
      ) {
        return '1123'
      }

      return lectureId
    },
  },
}
</script>

<style lang="sass" scoped>
.teacher-top-bar
  width: 100%
  background-color: $color-main
  color: $color-white

  padding: 6px $size-xs
  gap: $size-m
  +flex-center

  @media (max-width: 768px)
    gap: $size-xxs
    padding: 3px $size-xs
    justify-content: space-between

  &__title
    font-size: 18px

    @media (max-width: 768px)
      font-size: 14px
      width: 100%

  &__grade-selector
    min-width: 100px
    ::v-deep .sas-dropdown-menu
      z-index: 890

  ::v-deep .sas-dropdown
    padding: 0
    width: unset

</style>
