<template>
  <TourTooltip
    v-if="isEnabled"
    id="TourNewMagnaMission"
    has-steper
    :steps="tourSteps"
    @previousStep="trackStepChangeEvent('previous', $event)"
    @nextStep="trackStepChangeEvent('next', $event)"
    @finish="closeTour"
    @skip="skipTour"
  />
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import TourTooltip from '@/components/TourTooltip'
import tourMixin from '@/mixins/tour'

export default {
  name: 'TourNewMagnaMission',
  components: {
    TourTooltip,
  },
  mixins: [ tourMixin, mediaQueries ],
  props: {
    enabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      hasPreviousStep: false,
      hasNextStep: true,
      steps: [
        {
          target: '[data-tour="new-magna-mission-statement"]',
          content: `
            <h4 class="tour__title">Enunciado</h4>
            <p class="tour__content">
             Aqui você inclui o texto do enunciado
             da questão com algumas opções de formatação de texto.
            </p>`,
          gtmLabel: 'Enunciado',
          params: {
            placement: 'top',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-alternatives"]',
          content: `
            <h4 class="tour__title">Alternativas</h4>
            <p class="tour__content">
              Uma questão precisa ter o mínimo de duas alternativas preenchidas
              e no máximo quatro alternativas.
            </p>`,
          gtmLabel: 'Alternativas',
          params: {
            placement: 'left',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-correct-alternative-1"]',
          content: `
            <h4 class="tour__title">Alternativa correta</h4>
            <p class="tour__content">
              Depois que você preencher as alternativas você precisa selecionar uma delas como a resposta da questão.
              Cada questão só pode ter uma alternativa correta.
            </p>`,
          gtmLabel: 'Alternativa Correta',
          params: {
            placement: 'left',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-save-mission"]',
          content: `
            <h4 class="tour__title">Salvar missão</h4>
            <p class="tour__content">
              Depois que tiver pelo menos uma questão preenchida você já pode salvar o
              seu progresso para testar a missão ou jogar com a turma.
            </p>`,
          gtmLabel: 'Salvar Missão',
          params: {
            placement: 'bottom',
          },
          offset: 150,
        },
      ],
      mobileSteps: [
        {
          target: '[data-tour="new-magna-mission-statement"]',
          content: `
            <h4 class="tour__title">Enunciado</h4>
            <p class="tour__content">
             Aqui você inclui o texto do enunciado
             da questão com algumas opções de formatação de texto.
            </p>`,
          gtmLabel: 'Enunciado',
          params: {
            placement: 'top',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-alternatives"]',
          content: `
            <h4 class="tour__title">Alternativas</h4>
            <p class="tour__content">
              Uma questão precisa ter o mínimo de duas alternativas preenchidas
              e no máximo quatro alternativas.
            </p>`,
          gtmLabel: 'Alternativas',
          params: {
            placement: 'top',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-correct-alternative-1"]',
          content: `
            <h4 class="tour__title">Alternativa correta</h4>
            <p class="tour__content">
              Depois que você preencher as alternativas você precisa selecionar uma delas como a resposta da questão.
              Cada questão só pode ter uma alternativa correta.
            </p>`,
          gtmLabel: 'Alternativa Correta',
          params: {
            placement: 'top',
          },
          offset: 150,
        },
        {
          target: '[data-tour="new-magna-mission-mobile-save-mission"]',
          content: `
            <h4 class="tour__title">Salvar missão</h4>
            <p class="tour__content">
              Depois que tiver pelo menos uma questão preenchida você já pode salvar o
              seu progresso para testar a missão ou jogar com a turma.
            </p>`,
          gtmLabel: 'Salvar Missão',
          params: {
            placement: 'bottom',
          },
          offset: 150,
        },
      ],
    }
  },
  computed: {
    isEnabled() {
      if (this.isSasQuestionsEnabled) {
        return this.eurekaArenaOnboardingEnabled
          && this.enabled
          && !this.isTourActive('magnaMissionQuestionSelector')
          && this.isTourActive('newMagnaMission')
      }

      return this.eurekaArenaOnboardingEnabled
        && this.enabled
        && this.isTourActive('newMagnaMission')
    },
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    tourSteps() {
      return this.isMobile ? this.mobileSteps : this.steps
    },
  },
  methods: {
    skipTour({ type, step }) {
      if (type === 'close') {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.closeButtonOnboardingArena,
          label: step.gtmLabel,
        })
      } else if (type === 'skip') {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.exitButtonOnboardingArena,
          label: step.gtmLabel,
        })
      }

      this.closeTour()
    },
    closeTour() {
      this.endTour({ newMagnaMission: false })
    },
    trackStepChangeEvent(eventType, { step }) {
      if (eventType === 'next') {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.advanceOnboardingArena,
          label: step.gtmLabel,
        })
      } else if (eventType === 'previous') {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.returnOnboardingArena,
          label: step.gtmLabel,
        })
      }
    },
  },
}
</script>
