/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
function findId({ object, id }) {
  if (object.id === id) {
    return object
  }

  return find({ array: object.children, id })
}

export default function find({ array, id }) {
  if (!array?.length) return null
  for (const item of array) {
    const foundItem = findId({ id, object: item })
    if (foundItem) {
      return foundItem
    }
  }

  return null
}
