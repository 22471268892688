<template>
  <div
    v-if="show"
  >
    <ScenesContainer
      @finish-history="handleFinishHistory"
    />
    <div
      v-if="enableOverlay"
      class="story__overlay"
    >
      <GLoader
        v-if="loadingState"
        size="small"
      />
      <CollectRewardModal
        ref="rewardModal"
        :rewards="episodeReward.data"
        @click="handleCollectReward"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { apiBff } from '@/service/apiBff'
import { playSoundFX } from '@/utils/soundEffects'
import CollectRewardModal from '@/components/CollectRewardModal'
import ScenesContainer from '@/components/Adventure/AdventureScenes/ScenesContainer'
import GLoader from '../GLoader'

export default {
  name: 'Story',
  components: {
    ScenesContainer,
    CollectRewardModal,
    GLoader,
  },
  data() {
    return {
      show: true,
      timeToRedirect: 500,
      loadingState: false,
      enableOverlay: false,
      episodeReward: {
        error: false,
        data: [],
        loading: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'historySeasons',
      'historyEpisode',
    ]),
    currentSeason() {
      return this.historySeasons.data?.find(
        ({ id }) => id === this.seasonId
      )
    },
    seasonId() {
      return this.$route.params.seasonId
    },
    episodeId() {
      return this.$route.params.episodeId
    },
    currentEpisode() {
      if (!this.currentSeason) {
        return null
      }

      return this.currentSeason?.episodes.find(({ id }) => id === this.episodeId)
    },
    backgroundImg() {
      if (this.historyEpisode.data?.length) {
        const { data } = this.historyEpisode
        const episode = Object.assign([], data).reverse()
        const background = episode.find(
          ({ scenario }) => scenario.backgroundType === 'IMAGE'
        )

        return background.scenario.image.svg
      }

      return ''
    },
  },
  async created() {
    if (!this.historySeasons.done) {
      await this.getHistorySeasons()
    }
  },
  beforeDestroy() {
    this.clearScenesAlreadyPlayedEffects()
  },
  methods: {
    ...mapActions([
      'getHistorySeasons',
      'clearScenesAlreadyPlayedEffects',
    ]),
    redirectToLastRoute(customTime) {
      const { redirect } = this.$route.params
      const time = customTime || this.timeToRedirect

      if (!redirect) {
        this.$emit('show-on-boarding')
      }

      setTimeout(() => {
        this.$router.push({
          params: {
            ...this.$route.params,
            hasInitialSplashScreen: false,
            openAdventure: true,
          },
          query: { ...this.$route.query },
          name: redirect || 'worlds',
        })
      }, time)
    },
    async handleFinishHistory() {
      this.enableOverlay = true
      this.loadingState = true

      await this.collectEpisodeReward()

      if (this.episodeReward.data.length) {
        this.$refs.rewardModal.show()
      } else {
        this.show = false
        this.redirectToLastRoute()
      }
    },
    handleCollectReward() {
      playSoundFX()
      this.show = false
      this.$refs.rewardModal.hide()
      this.redirectToLastRoute()
      this.getHistorySeasons()
      this.$trackEvent({
        category: this.$track.category.history,
        action: this.$track.action.receiveReward,
        label: this.currentEpisode?.name,
      })
    },
    async collectEpisodeReward() {
      // FIXME: migrar para um service
      this.episodeReward.loading = true

      try {
        const response = await apiBff
          .put(`/seasons/${this.seasonId}/episodes/${this.episodeId}/episode-rewards`)

        this.episodeReward.data = response.data
      } catch (error) {
        this.episodeReward.data = error.response.data || true
      } finally {
        this.episodeReward.loading = false
      }
    },
  },
}
</script>
