<template>
  <transition
    name="g-modal"
    mode="out-in"
    appear
  >
    <div class="g-modal-container">
      <GFrame
        class="g-modal"
        :variation="variation"
      >
        <div
          v-if="closeButtonEnabled"
          class="g-modal__close-button"
        >
          <GButton
            data-testid="modal-close"
            aria-label="Fechar"
            variation="danger"
            size="large"
            icon="x"
            :icon-stroke="6"
            @click="$emit('close')"
          />
        </div>
        <slot />
      </GFrame>
      <div
        v-if="overlayEnabled"
        class="g-modal-overlay"
        @click="closeOnClickOutside ? $emit('close') : ''"
      />
    </div>
  </transition>
</template>

<script>
import GFrame from './GFrame'
import GButton from './GButton'

export default {
  name: 'GModal',
  components: {
    GFrame,
    GButton,
  },
  props: {
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => [ 'primary', 'secondary' ].includes(value),
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    closeButtonEnabled: {
      type: Boolean,
      default: true,
    },
    overlayEnabled: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.g-modal
  z-index: 3
  position: relative
  +transition($speed-normal)

  &-container
    +flex-center
    +cover(fixed)
    z-index: 9999

  &-overlay
    background: rgba($color-ink, .5)
    +cover(fixed)
    +transition($speed-normal)

  &__close-button
    position: absolute
    right: 0
    top: 0
    transform: translate(calc(50% - 6px), calc(-50% + 6px))

  &-enter
    transition-duration: $speed-normal

    .g-modal
      transform-origin: center
      transform: translateY(15%)
      opacity: 0

      &-overlay
        opacity: 0

  &-leave-to
    transition-duration: $speed-slow

    .g-modal
      transition-duration: $speed-slow
      transform-origin: center
      transform: translateY(15%)
      opacity: 0

      &-overlay
        transition-duration: $speed-slow
        opacity: 0
</style>
