<template>
  <div class="scene">
    <CineMode
      :background-scene="sceneBackgroundImage"
      :must-hide-header="!isXLargeBreakpoint"
      @skip="$emit('skip')"
    >
      <div
        class="scene__body"
        :class="{ '--has-options': showOptions }"
      >
        <template v-if="isDialogTemplate && actors.length">
          <div
            v-for="(
              {
                presentInNext,
                position,
                image,
                className,
                inEvidence,
                characterIsTude,
                characterIsRocco,
              },
              index
            ) in actors"
            :key="index"
            :class="[
              'scene__body__character',
              `--${position}`,
              { '--flip': !(position === 'left') },
              {
                'leave-transition':
                  isSkiping || (goingToNext && !presentInNext),
              },
              { '--in-evidence': inEvidence },
              { '--tude-character': characterIsTude },
              { '--rocco-character': characterIsRocco },
            ]"
          >
            <img
              :src="image.png || image.svg"
              :class="`${className}__image`"
            >
          </div>
        </template>
      </div>
    </CineMode>
    <AdventureDialog
      v-if="isDialogTemplate && dialog"
      :class="['dialog', { 'leave-transition': isSkiping }]"
      :style="{ position: isMobile ? 'fixed': 'absolute' }"
      :title="dialog.actor"
      :dialog="dialog.text"
      :hide-next-button="dialog.hideNextButton"
      @next="$emit('go-to-next-scene', scene)"
    />
    <AdventureOptionsList
      v-if="isDialogTemplate && options.length && showOptions"
      :options="options"
      :used-options="usedOptions"
      @click="emitOption"
    />
  </div>
</template>

<script>
import AdventureDialog from '@/components/Adventure/AdventureDialog/AdventureDialog'
import AdventureOptionsList from '@/components/Adventure/AdventureOptionsList'
import CineMode from '@/components/Adventure/CineMode'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'DialogScene',
  components: {
    CineMode,
    AdventureDialog,
    AdventureOptionsList,
  },
  mixins: [ mediaQueries ],
  props: {
    template: {
      type: String,
      default: '',
    },
    scene: {
      type: Object,
      default: () => {},
    },
    goingToNext: {
      type: Boolean,
      default: false,
    },
    nextScene: {
      type: Object,
      default: () => {},
    },
    isSkiping: {
      type: Boolean,
      default: false,
    },
    usedOptions: {
      type: Array,
      default: () => [],
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return this.isSmallBreakpoint
      || this.isXSmallBreakpoint
      || this.isMediumBreakpoint
      || this.isLargeBreakpoint
    },

    isDialogTemplate() {
      return this.template === 'DIALOG'
    },
    sceneBackgroundImage() {
      if (this.scene.scenario.backgroundType === 'IMAGE') {
        return `url(${this.scene.scenario.image.png})`
      }

      return null
    },
    options() {
      if (this.scene.outgoingScenes.length) {
        return this.scene.outgoingScenes
      }

      return []
    },
    actors() {
      const sceneCharacters = this.mq_m
        ? this.scene.characters.filter(({ inEvidence }) => inEvidence)
        : this.scene.characters

      return sceneCharacters.map((char, index) => {
        const presentInNext = Boolean(
          this.nextScene?.characters.find(
            ({ name, position }) => name === char.name && position === char.position
          )
        )

        return {
          ...char,
          position: char.position.toLowerCase(),
          className: `character-${index + 1}`,
          presentInNext,
          characterIsTude: char.name === 'Tude',
          characterIsRocco: char.name === 'Rocco' || char.name === '???',
        }
      })
    },
    dialog() {
      const { scene } = this
      const [ actorInEvidence ] = scene.characters.filter(
        ({ inEvidence }) => inEvidence
      )

      return {
        actor: actorInEvidence.name,
        text: scene.dialog,
        hideNextButton: false || this.showOptions,
      }
    },
  },
  methods: {
    emitOption(option) {
      this.$emit('select-option', option)
    },
  },
}
</script>

<style lang="scss" scoped>
.scene {
  position: relative;
  height: 100vh;
  @include flex-center;
  margin: 0;

  @media screen and (min-width: 991px) {
    margin: 0 12%;
  }

  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: end;
    display: flex;
    justify-content: center;
    padding: 3% 1%;

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-areas: "left center right";
      justify-content: initial;
      padding: 3% 12%;
    }

    &::after {
      @include pseudo(absolute);
      width: 100%;
      height: 100%;
      opacity: 0;
      background: linear-gradient(0deg, black, rgba(0, 0, 0, 0));
      @include transition($speed-normal);
    }

    &__character img {
      min-height: 40vh;
      min-width: fit-content;
      max-height: 70vh;
      filter: brightness(0.4);
      transform: scale(0.9);
      transform-origin: bottom;
      @include transition($speed-normal);

      @media screen and (max-width: 500px) {
        max-width: 100%;
      }
    }

    .--tude-character img {
      min-height: 22vh;
      min-width: fit-content;
      position: absolute;
      bottom: 16vh;
    }

    .--rocco-character img {
      min-height: 34vh;
      min-width: fit-content;
    }

    .--in-evidence img {
      filter: brightness(1);
      transform: scale(1);
      min-width: fit-content;
    }

    &.--has-options {
      &::after {
        opacity: 0.5;
      }
    }

    .--left {
      grid-area: left;
      animation: move-char-to-top 1s;

      &.leave-transition, &.leave-in-next {
        animation: move-char-to-bottom 1s;
      }
    }

    .--center {
      grid-area: center;
      justify-self: center;
      animation: move-char-to-top .1s;

      &.leave-transition, &.leave-in-next {
        animation: move-char-to-bottom 1s;
      }
    }

    .--right {
      grid-area: right;
      justify-self: end;
      animation: move-char-to-top 1s;

      &.leave-transition, &.leave-in-next {
        animation: move-char-to-bottom 1s;
      }
    }

    .--flip {
      transform: scaleX(-1);
    }
  }

  .dialog {
    @include adventure-dialog-position;
  }

  @keyframes move-char-to-top {
    0% {
      position: relative;
      bottom: -700px;
    }

    100% {
      position: relative;
      bottom: 0;
    }
  }

  @keyframes move-char-to-bottom {
    0% {
      position: relative;
      bottom: 0;
    }

    100% {
      position: relative;
      bottom: -700px;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

</style>
