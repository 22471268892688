<template>
  <div class="article-preview">
    <div class="article-preview__info">
      <skeleton-loader
        width="60px"
        height="16px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        width="64px"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
    </div>
    <div class="article-preview__title">
      <skeleton-loader
        width="90%"
        height="19px"
        opacity="0.5"
        border-radius="8px"
      />
      <skeleton-loader
        width="60%"
        height="19px"
        opacity="0.5"
        border-radius="8px"
      />
    </div>
    <div class="article-preview__body">
      <skeleton-loader
        height="120px"
        opacity="0.3"
        border-radius="8px"
        class="article-preview__body__image"
      />
      <skeleton-loader
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticlePreviewSkeleton',
}
</script>

<style lang="sass" scoped>
.article-preview
  &__info
    +flex-center-start
    margin-bottom: $size-xs

    & > *
      margin-right:$size-xs

  &__title
    & > *
      margin-bottom: $size-xs

  &__body
    &__image
      margin: $size-s 0

    & > :not(:first-child)
      margin-bottom: $size-xs
</style>
