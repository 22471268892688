var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{ref:"container",staticClass:"g-header"},[_c('Wrapper',{class:[
      'g-header__wrapper',
      {
        '--has-center-slot': _vm.hasCenterSlot,
        '--is-responsive-report-page': _vm.isResponsiveRouteOrReportPage,
      },
    ],style:(_vm.headerStyle),attrs:{"full-width":true}},[_c('div',{staticClass:"group"},[(_vm.hasBackButton)?_c('GButton',{staticClass:"g-header-game__back-button",attrs:{"icon":"arrow-left","size":"large","variation":"secondary"},on:{"click":function($event){return _vm.$emit('go-back')}}}):_vm._e(),(_vm.hasBackButton)?_c('div',{staticClass:"separator"}):_vm._e(),_c('div',{staticClass:"g-header-game__container",style:({
          width: _vm.isMagnaMissionRoomTeacherRoute || _vm.isMagnaMissionReport ? '100%' : ''
        })},[(_vm.dynamicTitle && !_vm.isMobile
            || !_vm.isTeacherLobbyRoute)?_c('h1',{staticClass:"g-header-game__title",attrs:{"id":"game-title"}},[_vm._v(" "+_vm._s(_vm.dynamicTitle)+" ")]):_vm._e(),_c('div',{staticClass:"g-header-game__controls"},[_c('ToggleFullScreen',{staticClass:"g-header-game__items",attrs:{"references":_vm.$refs,"size":"large"}}),_c('GButton',{staticClass:"group__items",attrs:{"id":"gheader__options-button","variation":"secondary","size":"large","icon":"more-horizontal","icon-stroke":3},on:{"click":_vm.toggleOptions}})],1)])],1),(_vm.isGProfileActive)?_c('div',{staticClass:"g-header-game__profile"},[(_vm.author)?_c('GProfile',{attrs:{"profile-name":_vm.author.user.name,"profile-picture":_vm.author.user.profilePicture,"size":"medium","color":"white"}},[(_vm.classroomName)?_c('span',{staticClass:"classroom-name"},[_vm._v(" | "+_vm._s(_vm.classroomName)+" ")]):_vm._e()]):_vm._e()],1):_vm._e(),_vm._t("center"),_vm._t("menu")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }