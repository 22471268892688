<template>
  <div class="question-selector-popup">
    <VDropdown
      :distance="8"
      placement="auto-start"
      :shown="isOpen"
      :disabled="!isSasQuestionsEnabled"
    >
      <GButton
        :icon-stroke="5"
        class="button"
        size="large"
        icon="plus"
        variation="primary"
        aria-label="Adicionar questão"
        no-border
        @click="handleAddQuestions"
      />
      <template #popper>
        <QuestionSelector
          @add-new-question="addNewQuestion"
          @click-sas-question="closeModal"
          @click-teacher-question="closeModal"
        />
      </template>
    </VDropdown>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import { mapState } from 'vuex'
import QuestionSelector from './QuestionSelector'

export default {
  name: 'QuestionSelectorPopup',
  components: { GButton, QuestionSelector },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState({
      isSasQuestionsEnabled: ({ featureToggles }) => featureToggles
        .toggles.eurekaSasQuestions,
    }),
  },
  methods: {
    handleAddQuestions() {
      if (this.isSasQuestionsEnabled) {
        this.openModal()

        return
      }

      this.addNewQuestion('TEACHER')
    },
    toggleModal() {
      this.isOpen = !this.isOpen
    },
    openModal() {
      this.isOpen = true
    },
    closeModal() {
      this.isOpen = false
    },
    addNewQuestion(originType) {
      this.$emit('add-new-question', originType)
    },
  },
}
</script>

<style lang="scss" scoped>
.question-selector-popup {
  transition: all 0.3s ease-in-out;

    ::v-deep {
      .v-popper--theme-dropdown {
        .feather {
          transition: all 0.3s ease-in-out;
          transform: rotate(0);
        }

        &.v-popper--shown .feather {
          transform: rotate(22deg);
          transition: all 0.3s ease-in-out;
        }
      }
    }
}
</style>
