var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[(_vm.isTeacherTopbarVisible)?_c('TeacherTopbar'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"profile__body"},[_c('div',{class:[
          'profile__body__char',
          {'--topbar-padding': _vm.isTeacherTopbarVisible}
        ]},[_c('img',{staticClass:"profile__body__char__image",attrs:{"src":require("@/assets/char/tata-profile.svg")}})]),_c('div',{class:[
          'profile__body__lectures',
          {
            '--topbar-margin': _vm.isTeacherTopbarVisible,
            '--mfe-margin': _vm.isMFE,
          }
        ]},[(_vm.userInfoLoading)?_c('ProfileCardSkeleton'):_c('ProfileCard',{attrs:{"game-icon":_vm.gameIcon,"user-game-info":_vm.userGameData.data},on:{"edit-profile":_vm.toggleEditIconModalVisibility,"change-tab":_vm.setViewTab}}),_c(_vm.tabComponent,{tag:"component",class:[
            { '--hidden-scrollbar': _vm.isTablet}
          ]})],1)])]),(_vm.selectIconModalIsVisible)?_c('UserIconsList',{attrs:{"current-icon-selected":_vm.gameIcon},on:{"close":_vm.toggleEditIconModalVisibility}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }