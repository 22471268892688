const CSAT_FAST_FORM_ID = 'Gux2kdCK'
const CSAT_GENERAL_EXPERIENCE_FORM_ID = 'sE7y0e16'
const CSAT_HOME_MISSION_FORM_ID = 'qyY8AVR3'
const CSAT_START_RECOMPOSITION_FORM_ID = 'uBjjzEgJ'
const CSAT_RECOMPOSITION_RECOVERY_FORM_ID = 'U8iCSbnT'
const CSAT_RECOMPOSITION_DIAGNOSTIC_FORM_ID = 'kAKSv4gs'

export {
  CSAT_FAST_FORM_ID,
  CSAT_GENERAL_EXPERIENCE_FORM_ID,
  CSAT_HOME_MISSION_FORM_ID,
  CSAT_START_RECOMPOSITION_FORM_ID,
  CSAT_RECOMPOSITION_RECOVERY_FORM_ID,
  CSAT_RECOMPOSITION_DIAGNOSTIC_FORM_ID,
}
