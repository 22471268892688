<template>
  <section class="bag-section">
    <h4 class="bag-section__title">
      {{ title }}
    </h4>
    <div :class="['bag-section__items', { '--grid': isGrid }]">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: 'BagSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    isGrid: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.bag-section {
  margin-bottom: $size-m;

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font_size_heading_4_small;
    line-height: $font_size_heading_4_small;
    margin-bottom: $size-s;
  }

  &__items {

    &.--grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(auto, 100px));
      grid-auto-rows: 100px;
      gap: $size-s;
    }
  }
}
</style>
