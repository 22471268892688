<template>
  <div
    :class="[
      'user-preferences-controls', positionClassName]"
  >
    <QuickSoundToggle
      v-if="!isHideToggleSounds"
      class="user-preferences-controls__item"
      data-testid="sound-toggle"
      :shape="soundToggleShape"
      :theme="soundToggleTheme"
    />
    <WorldsViewToggle
      v-if="!isHideToggleWorld"
      class="user-preferences-controls__item"
      data-testid="worlds-view-toggle"
      :toggle-type="worldsView"
      @click="toggleWorldView"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import QuickSoundToggle from '@/components/UserConfig/QuickSoundToggle'
import WorldsViewToggle from '@/views/Worlds/partials/WorldsViewToggle'

import gradeId from '@/mixins/gradeId'
import getGradeId from '@/mixins/getGradeId'
import mediaQueries from '@/mixins/mediaQueries'

const ancestralArenaStudentRoutes = [ 'lobby-student', 'magna-mission-room-student', 'magna-mission-student-report' ]
const ancestralArenaTeacherRoutes = [ 'lobby-teacher', 'magna-mission-room-teacher', 'magna-mission-teacher-report', 'magna-mission-detail' ]

export default {
  name: 'QuickUserPreferencesToggle',
  components: {
    QuickSoundToggle,
    WorldsViewToggle,
  },
  mixins: [ gradeId, getGradeId, mediaQueries ],
  data() {
    return {
      worldsViewMode: false,
    }
  },
  computed: {
    ...mapGetters([ 'userPreferences', 'user' ]),
    isStoryTellingRoute() {
      const { name } = this.$route

      return name === 'storytelling'
    },
    positionClassName() {
      return this.isStoryTellingRoute && !this.isXLargeBreakpoint ? '--top' : '--bottom'
    },
    soundToggleShape() {
      return this.isStoryTellingRoute && !this.isXLargeBreakpoint ? 'rectangle' : 'square'
    },
    soundToggleTheme() {
      return this.isStoryTellingRoute && !this.isXLargeBreakpoint ? 'dark' : 'light'
    },
    worldsView() {
      return this.worldsViewMode ? 'carousel' : 'grid'
    },
    isHideQuickUserPreferencesControls() {
      return {
        musicControl: !this.isXLargeBreakpoint && !this.isStoryTellingRoute,
        worldsView: !this.isXLargeBreakpoint,
      }
    },
    isHideToggleSounds() {
      const { name } = this.$route
      const mutePages = [ 'question', 'home-teacher', 'retrospective', ...ancestralArenaStudentRoutes, ...ancestralArenaTeacherRoutes ]

      return this.isHideQuickUserPreferencesControls.musicControl
      || mutePages.includes(name)
      || this.userIsHighSchoolStudent
    },
    userIsHighSchoolStudent() {
      return this.user.data?.isHighSchoolStudent
    },
    isHideToggleWorld() {
      const { name } = this.$route
      const gradeID = this.gradeId ? this.gradeId : this.getGradeId()
      const isThirdYearUser = gradeID === 8

      return name !== 'worlds'
      || this.isHideQuickUserPreferencesControls.worldsView
      || isThirdYearUser
      || this.userIsHighSchoolStudent
    },
  },
  watch: {
    userPreferences() {
      const isValidData = this.userPreferences.data !== null

      if (isValidData) {
        this.worldsViewMode = this.userPreferences.data.worldsLayoutGrid
      }
    },
  },
  methods: {
    ...mapActions([
      'setUserPreferences',
    ]),
    trackChangeViewMode() {
      this.$trackEvent({
        category: this.$track.category.worlds,
        action: this.$track.action.worldsVisualization,
        label: this.worldsView,
      })
    },
    toggleWorldView() {
      const newViewMode = !this.userPreferences.data.worldsLayoutGrid

      this.trackChangeViewMode()
      this.setUserPreferences({
        worldsLayoutGrid: newViewMode,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-preferences-controls {
  position: fixed;
  height: min-content;
  display: flex;
  justify-content: center;
  margin: 1%;
  z-index: $zindex-fixed;

  &.--bottom {
    right: 0;
    bottom: 0;
  }

  &.--top {
    margin: 0;
    left: 0;
    top: 0;
  }

  &__item:not(:last-child) {
    margin-right: $size-xs;
  }
}
</style>
