<template>
  <div class="mission-content-skeleton">
    <div class="mission-content-skeleton__statement">
      <skeleton-loader
        width="40%"
        height="18px"
      />
      <skeleton-loader
        width="90%"
        height="18px"
      />
      <skeleton-loader
        width="70%"
        height="18px"
      />
      <skeleton-loader
        width="50%"
        height="18px"
      />
    </div>
    <div class="mission-content-skeleton__options">
      <div
        v-for="index in 4"
        :key="index"
        class="mission-content-skeleton__options__option"
      >
        <skeleton-loader
          width="40%"
          height="18px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionContentSkeleton',
}
</script>

<style lang="sass" scoped>
.mission-content-skeleton
  &__statement
    margin: 20px 0 40px

  &__options
    &__option
      background: $color-white
      border: 1px solid #EECE8A
      box-shadow: 0px 4px 0px #EECE8A
      border-radius: 8px
      padding: 12px 24px
      position: relative
      +space-stack(12px)

  .sas-skeleton-loader
    background: transparentize(#d0c3aa, 0.7)
    +space-stack(20px)
</style>
