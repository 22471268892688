<template>
  <div
    :class="[
      'g-frame',
      `--${variation}`
    ]"
  >
    <div class="g-frame__inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GFrame',
  props: {
    variation: {
      type: String,
      default: 'primary',
      required: false,
      validator: (value) => (
        value.match(/(primary|secondary|tertiary)/)
      ),
    },
  },
}
</script>

<style lang="scss" scoped>
.g-frame {
  position: relative;
  background: #F1BD8D;
  box-shadow: 0 4px 0 #CD8747;
  border-radius: 20px;
  padding: 12px;

  &__inner {
    max-height: 100%;
    background: #FDFBF0;
    border-radius: 12px;
  }

  &.--secondary {
    padding: $size-s $size-l;
    background: $eureka-color-base;
    overflow-y: auto;
    box-shadow: 0 4px 46px rgba(0,0,0,0.35);
    @include mq_s {
      padding: $size-s
    }

    .g-frame__inner {
      box-shadow: none;
      background: $eureka-color-base;
    }
  }

  &.--tertiary {
    padding: $size-s $size-l;
    background: $eureka-color-base;
    box-shadow: 0 4px 36px rgba(0, 0, 0, 0.35);
    @include mq_s {
      padding: $size-s
    }
    .g-frame__inner {
      display: flex;
      flex-direction: column;
      box-shadow: none;
      background: $eureka-color-base;
    }
  }
}
</style>
