<script>
import { Box } from '@sas-te/alfabeto-vue'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'DropdownMenu',
  components: {
    Box,
  },
  mixins: [ mediaQueries ],
  props: {
    origin: {
      type: String,
      default: 'bottom-right',
      validator: (value) => value
        .match(/(top-right|top-left|bottom-right|bottom-left)/),
    },
  },
}
</script>

<template>
  <transition
    :name="mq_l ? 'slide' : null"
    appear
  >
    <Box
      :class="['sas-dropdown-menu', `sas-dropdown-menu--${origin}`]"
      elevation="3"
      role="listbox"
      tabindex="-1"
    >
      <slot />
    </Box>
  </transition>
</template>

<style lang="scss">
.sas-dropdown-menu {
  position: absolute;
  z-index: 100;
  padding: $size-xs !important;
  background-color: $color-white;
  border: 1px solid rgba($color-ink, .25);
  border-radius: $border-radius-m;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include transition($speed-fast);

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10%);
  }

  &-leave-to {
    transition-delay: $speed-x-fast;
  }
}

.sas-dropdown-menu--bottom-right {
  top: 100%;
  right: 0;
}

.sas-dropdown-menu--bottom-left {
  top: 100%;
  left: 0;
}

.sas-dropdown-menu--top-right {
  right: 0;
  bottom: 100%;
}

.sas-dropdown-menu--top-left {
  bottom: 100%;
  left: 0;
}
</style>
