<template>
  <div class="g-profile">
    <div
      :class="[
        'g-profile__picture',
        `g-profile__picture--${size}`,
      ]"
    >
      <img
        v-if="hasValidProfilePicture"
        class="picture"
        :src="profilePicture"
        alt=""
        @error="hasProfilePictureError = true"
      >
      <div
        v-else
        class="initials"
      >
        {{ initials }}
      </div>
    </div>
    <div class="g-profile__informations">
      <span
        :style="{ color }"
        class="g-profile__informations__profile-name"
      >
        {{ profileName }}
      </span>
      <slot />
    </div>
  </div>
</template>

<script>
const sizes = [ 'small', 'medium', 'large' ]

export default {
  name: 'GProfile',
  props: {
    profileName: {
      type: String,
      default: '',
    },
    profilePicture: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => sizes.includes(value),
    },
    color: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hasProfilePictureError: false,
    }
  },
  computed: {
    hasValidProfilePicture() {
      return Boolean(this.profilePicture) && !this.hasProfilePictureError
    },
    initials() {
      return this.profileName.trim()[0]
    },
  },
}
</script>

<style lang="scss" scoped>
.g-profile {
  display: flex;
  align-items: center;

  &__picture {
    background: rgba($color-ink-lightest, 1);
    border-radius: 50%;
    box-shadow: $shadow-s;
    margin-right: $size-xs;
    @include flex-center;

    &--small {
       height: $element-size-s;
       width: $element-size-s;
    }

    &--medium {
       height: $element-size-m;
       width: $element-size-m;
    }

    &--large {
       height: $element-size-l;
       width: $element-size-l;
    }
  }
}

.picture {
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.initials {
  font-size: $font-size-m;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $color-ink-light;
}
</style>
