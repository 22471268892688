<template>
  <header class="mission-header">
    <div class="mission-header__main">
      <div
        v-if="mq_xl__mf"
        class="mission-header__content"
      >
        <h2 class="title">
          {{ title }}
        </h2>
        <h3 class="subtitle">
          {{ subtitle }}
        </h3>
      </div>

      <div class="wrapper">
        <div class="wrapper__flex">
          <section class="mission-items hide-on-mobile">
            <img
              v-for="item in items"
              :key="item.id"
              v-tooltip.bottom="item.description"
              :src="item.image.svg"
              width="65"
              height="65"
            >
          </section>
          <PerformanceBar
            v-if="performance.success && progressStatus"
            :performance="performance.data"
            :chest-levels="progressChest()"
          />
          <WalletDisplay
            v-if="!userGameData.error"
            class="user-wallet"
            currency-type="coin"
            :quantity="userGameData.data.coin.quantity"
          />
        </div>
        <div class="wrapper__flex game-controls">
          <div class="mission-header__controls">
            <SoundConfig v-if="soundPreferences.data">
              <ConfigControl
                :label="$t('commons.sounds')"
                control-id="mission-sounds"
                :control-value="soundPreferences.data.sound"
                @valueChange="handleMissionSounds"
              />
            </SoundConfig>
          </div>
          <section class="mission-items display-on-mobile">
            <img
              v-for="item in items"
              :key="item.id"
              v-tooltip.bottom="item.description"
              :src="item.image.svg"
              width="65"
              height="65"
            >
          </section>
          <div class="mission-header__controls">
            <a
              class="quit-mission"
              @click="$emit('quit-mission')"
            >
              <img
                alt="fechar"
                src="@/assets/icon-pause-secondary.svg"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// TODO: Rever comportamento da abertura de modais
import { mapGetters, mapActions } from 'vuex'

import { trackSoundPreferenceEvent } from '@/components/UserConfig/events'
import PerformanceBar from '@/components/PerformanceBar'
import WalletDisplay from '@/components/WalletDisplay'
import SoundConfig from '@/components/UserConfig/UserConfig'
import ConfigControl from '@/components/UserConfig/ConfigControl'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'MissionHeader',
  components: {
    PerformanceBar,
    WalletDisplay,
    SoundConfig,
    ConfigControl,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      missionItems: [],
      chests: [],
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'performance',
      'userGameData',
      'soundPreferences',
      'usedItems',
      'progress',
      'assignment',
    ]),
    items() {
      if (this.usedItems.data?.length && !this.missionItems.length) {
        return this.assignItems()
      }

      return this.missionItems
    },
    progressStatus() {
      return this.chests.length || this.progress.done
    },
    title() {
      return this.assignment.data?.title
        ? this.assignment.data.title
        : ''
    },
    subtitle() {
      return this.assignment.data
        && this.assignment.data.subtitle
        ? this.assignment.data.subtitle
        : ''
    },
  },
  methods: {
    ...mapActions([
      'setSoundPreferences',
    ]),
    assignItems() {
      const [ activeItem ] = this.usedItems.data.filter(
        ({ category }) => category === 'MISSION'
      )
      this.missionItems = Object.assign([], activeItem.items)

      return this.missionItems
    },
    handleMissionSounds({ value }) {
      trackSoundPreferenceEvent(this, value)
      this.setSoundPreferences({ sound: value })
    },
    progressChest() {
      if (!this.chests.length) {
        const { chests } = this.progress.data
        this.chests = [ ...chests ]
      }

      return this.chests
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-on-mobile {
  display: block;

  @media screen and (max-width: 479px) {
    display: none;
  }
}

.display-on-mobile {
  display: none;

  @media screen and (max-width: 479px) {
    display: block;
  }
}

.mission-header {
  width: 100%;
  overflow: visible;
  box-shadow: $frame-shadow;

  @media screen and (max-width: 479px) {
    ::v-deep .sound-config__frame {
      left: -10px;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    background-color: $frame-bg-color;
    padding: $size-m $size-s;

    @media screen and (max-width: 768px) {
      padding: 12px;
    }

    @include mq-l--mf {
      justify-content: center;
    }
  }

  &__content {
    .title {
      font-size: $font_size_heading_5;

      @include mq-l--mf {
        font-size: $font_size_heading_4;
      }
    }

    .subtitle {
      font-size: $font_size_s;
    }
  }

  &__controls {
    border-left: 2px solid $eureka-color-base-darker;
    padding-left: $size-s;
    cursor: pointer;

    @media screen and (max-width: 479px) {
      border-left: 0;
      padding-left: 0;
    }

    .sound-config {
      margin-right: $size-s;
    }

    .quit-mission {
      display: flex;
      cursor: pointer;
      height: 44px;
      width: 44px;
    }
  }
}
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    justify-content: center;
  }

  @media screen and (max-width: 479px) {
    flex-direction: column-reverse;
  }

  @include mq-l--mf {
    width: min-content;
  }

  &__flex {
    display: flex;
    align-items: center;

    @media screen and (max-width: 479px) {
      justify-content: center;
    }
  }
  .game-controls {
    @media screen and (max-width: 479px) {
      justify-content: space-between;
      flex-direction: row;
      padding: 0 16px 12px 8px;
      width: 100%;
    }
  }

  .mission-items {
    margin-left: $size-s;
  }

  .user-wallet {
    margin: 0 $size-m;
  }

  .performance-bar {
    margin-left: $game-size-m;
    width: fit-content;
    min-width: 300px;

    @media screen and (max-width: 768px) {
      min-width: unset;
      width: 65%;
    }

    ::v-deep .resource-bar__slot {
      width: 100%;
    }
  }
}
</style>
