<template>
  <div
    id="arenaFeedback"
    class="ancestral-arena-feedback"
  >
    <slot name="feedbackIcon" />
    <div>
      <p class="ancestral-arena-feedback__message">
        {{ message }}
      </p>
      <span class="ancestral-arena-feedback__description">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'AncestralArenaFeedback',
  props: {
    message: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.showFeedbackAnimation()
  },
  methods: {
    showFeedbackAnimation() {
      gsap.from('.ancestral-arena-feedback', { position: 'fixed', bottom: '-100px' })
    },
  },
}
</script>

<style lang="scss" scoped>
.ancestral-arena-feedback {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  background: $eureka-color-base;
  padding: $size-m;
  color: $eureka-color-ink;
  box-shadow: 0px -5px 32px rgba(0,0,0,0.35);
  border-top-left-radius: $size-m;
  border-top-right-radius: $size-m;

  &__message {
    font: {
      family: 'Rowdies';
      size: $size-m;
    }
  }

  &__description {
    font: {
      family: 'Lato';
      size: $font-size-s;
    }
  }
}
</style>
