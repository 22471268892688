<template>
  <div
    class="item-details"
    @click="$emit('click')"
  >
    <div class="item-details__icon">
      <img
        class="item-details__icon__image"
        :src="itemDetail.image.svg"
      >
      <div
        v-if="item.quantity >= 0"
        class="item-details__quantity"
      >
        <span class="item-details__quantity__number">
          {{ item.quantity }}
        </span>
      </div>
    </div>
    <h4 class="item-details__name">
      {{ itemDetail.name }}
    </h4>
    <div class="item-details__description">
      {{ itemDetail.description }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ItemDetails',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemDetail() {
      const { item } = this.item

      return item
    },
  },
}
</script>

<style lang="sass" scoped>
.item-details
  background: #FBF1E1
  border: 5px solid rgba(255, 255, 255, 0.6)
  box-shadow: 0px 4px 0px #D9AF54
  border-radius: 12px
  padding: 32px
  position: relative
  +flex-column-center
  +transition($speed-fast)

  &__icon
    background: #FDF8F0
    height: 180px
    width: 180px
    border-radius: 50%
    margin-bottom: 24px
    position: relative
    +flex-center

    &__image
      width: 152px
      -webkit-user-drag: none

  &__image
    width: 64px
    -webkit-user-drag: none

  &__quantity
    background: $color-white
    border: 3px solid $eureka-color-base-dark
    border-radius: 99px
    padding: 4px
    position: absolute
    bottom: -5px
    right: 20px
    min-width: 30px
    +flex-center

    &__number
      font-weight: bold
      font-size: 15px
      line-height: 1

  &__name
    font-weight: bold
    font-size: 24px
    margin-bottom: 16px
    text-align: center

  &__description
    color: transparentize($game-ink, 0.3)
    font-weight: 500
    font-size: 16px
    line-height: 1.2
    margin-bottom: 32px
    text-align: center
</style>
