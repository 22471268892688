<template>
  <div :class="['rich-text-editor', {'--error': error}]">
    <Editor
      v-model="contentHTML"
      :api-key="tinyApiKey"
      :init="init"
      :toolbar="toolbar"
      :plugins="plugins"
      @input="setContentText"
      @onInit="onEditorReady"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

import questionsApi from '@/service/questions'
import getImageAmount from '@/utils/getImageAmount'
import richTextDomEvents from '@/mixins/richTextDomEvents'

const toolbar = [
  {
    name: 'formatação',
    items: [
      'bold',
      'italic',
      'underline',
    ],
  },
  {
    name: 'alinhamento',
    items: [
      'alignleft',
      'aligncenter',
      'alignright',
      'alignjustify',
    ],
  },
  {
    name: 'listas',
    items: [
      'numlist',
      'bullist',
      'checklist',
      'image',
    ],
  },
]
const plugins = [
  'advlist',
  'lists',
  'paste',
  'image',
  'imagetools',
]

export default {
  name: 'RichTextEditor',
  components: {
    Editor,
  },
  mixins: [ richTextDomEvents ],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    error: Boolean,
  },
  data() {
    return {
      contentText: '',
      tinyApiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
      contentStyle: `
        @import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');

        body {
          background-color: #FFFBF3;
          font-family: Rowdies;
          font-weight: 300;
          font-size: 1.5rem;
          color: #2D1F49;
        }
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            opacity: 0.6;}
        `,
      toolbar,
      plugins,
    }
  },
  computed: {
    contentHTML: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    init() {
      return {
        menubar: false,
        language: 'pt_BR',
        statusbar: true,
        placeholder: this.placeholder,
        // images configuration options
        a11y_advanced_options: true,
        a11ychecker_allow_decorative_images: false,
        image_description: false,
        image_title: false,
        file_picker_types: 'image',
        images_reuse_filename: true,
        relative_urls: false,
        paste_data_images: false,
        paste_preprocess: (plugin, args) => {
          if (getImageAmount(args.content) > 0) {
            args.preventDefault()
          }
        },
        // allowing svg only to notify users that it is not supported
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
        images_upload_handler: this.imageUploadHandler,
        content_style: this.contentStyle,
      }
    },
  },
  watch: {
    contentText(value) {
      this.$emit('content-text-change', value)
    },
  },
  methods: {
    setContentText() {
      this.contentText = window.tinymce.activeEditor?.getContent({ format: 'text' }) || ''
    },
    trackUploadImageEvent() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherAddImage,
      })
    },
    async imageUploadHandler(blobInfo, success, failure, progress) {
      if (blobInfo.filename().endsWith('.svg')) {
        failure('Tipo de arquivo não suportado')

        return
      }

      try {
        const { data } = await questionsApi.uploadImage(blobInfo.blobUri(), {
          onUploadProgress({ loaded, total }) {
            const MAX_PERCENT = 100

            progress((loaded / total) * MAX_PERCENT)
          },
        })

        if (!data || data.url === '') {
          failure('Erro ao salvar imagem')

          return
        }

        success(data.url)
        this.trackUploadImageEvent()
        this.saveImageAutomatically()
      } catch (error) {
        failure(error)
      }
    },
  },
}
</script>
<style lang="scss">
.tox.tox-tinymce-aux {
  z-index: $zindex-modal;
}

.tox-dialog__body {
  .tox-label {
    pointer-events: none;
  }

  .tox-form__controls-h-stack input[type=url].tox-textfield {
    background-color: #f2f2f2;
    color: rgba(34,47,62,.85);
    pointer-events: none;
  }
}
</style>
<style lang="scss" scoped>
.rich-text-editor {
  &.--error {
    ::v-deep .tox-edit-area {
      border: 3px solid red;
      border-radius: $size-s;
    }
  }

  ::v-deep .tox.tox-tinymce {
    border-radius: 16px;
    border: 2px solid $eureka-color-base-darker;
  }

  ::v-deep .tox-toolbar__primary {
    background-color: $eureka-color-base-light;
    border-radius: 16px 16px 0 0;
  }

  ::v-deep .tox-statusbar {
    display: none;
  }
}
</style>
