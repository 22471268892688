<template>
  <div
    :style="{
      width: customBadgeSize,
      height: customBadgeSize,
      backgroundColor: color
    }"
    class="badge-count"
  >
    <div
      :style="{ fontSize: customFontSize }"
      class="badge-count__text"
    >
      {{ formatCount }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeCount',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    disableFormatCount: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#EF1A55',
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => (
        value.match(/(small|medium|large)/)
      ),
    },
  },
  computed: {
    formatCount() {
      if (this.disableFormatCount) {
        return this.count
      }

      if (this.count === 0) {
        return ''
      }

      return this.count > 3 ? '3+' : this.count
    },
    customBadgeSize() {
      switch (this.size) {
        case 'small':
          return '21px'
        case 'medium':
          return '28px'
        case 'large':
          return '35px'
        default:
          return '28px'
      }
    },
    customFontSize() {
      switch (this.size) {
        case 'small':
          return '12px'
        case 'medium':
          return '16px'
        case 'large':
          return '20px'
        default:
          return '16px'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.badge-count
  position: absolute
  border-radius: $border-radius-circle
  z-index: 99
  +flex-center
  +transition($speed-fast)

  &__text
    font-weight: $font-weight-bold
    color: $color-white
</style>
