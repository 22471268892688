<template>
  <footer
    :class="[
      'mission-feedback',
      {
        '--right': correct,
        '--wrong': !correct,
      },
    ]"
  >
    <g-alert
      v-if="finishError"
      variation="error"
      :message="$t('game.error.answer.message')"
    />
    <div class="mission-feedback__icon">
      <img
        v-if="correct"
        alt=""
        src="@/assets/feedback/right-badge.svg"
      >
      <img
        v-else
        alt=""
        src="@/assets/feedback/wrong-badge.svg"
      >
    </div>
    <div class="mission-feedback__text">
      <h4>
        {{
          correct
            ? $t('questions.actions.correct')
            : $t('questions.actions.incorrect')
        }}
      </h4>
      <template v-if="xp > 0 && correct">
        <g-block
          asset="xp.svg"
          size="small"
          theme="bright"
        >
          {{ `+${xp}` }}
        </g-block>
      </template>
    </div>
    <g-button
      variation="secondary"
      size="large"
      icon-left="book-open"
      @click="$emit('showExplanation')"
    >
      {{ $t('commons.explanation') }}
    </g-button>
    <g-button
      v-if="canBeFinished"
      variation="primary"
      size="large"
      :loading="finishingAssignment"
      @click="finishMission"
    >
      {{ $t('questions.actions.finish') }}
    </g-button>
    <g-button
      v-else
      variation="primary"
      size="large"
      @click="$emit('nextEnigma')"
    >
      {{ $t('commons.next.male') }}
    </g-button>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import { effectsNames } from '@/service/soundController/loadSounds'
import GBlock from '@/components/GBlock'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import assignmentsService from '@/service/assignments'

export default {
  name: 'MissionFeedback',
  components: {
    GBlock,
    GButton,
    GAlert,
  },
  props: {
    correct: {
      type: Boolean,
      default: false,
    },
    canBeFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      finishingAssignment: false,
      finishError: false,
    }
  },
  computed: {
    ...mapGetters([
      'question',
      'rewards',
    ]),
    chapterId() {
      return parseInt(this.$route.params.chapterId, 10)
    },
    assignmentCode() {
      return this.question.data.assignment
        ? this.question.data.assignment.code
        : ''
    },
    xp() {
      if (this.rewards.success && this.rewards.data.length) {
        const hitRewards = this.rewards.data.filter(
          ({ type }) => type === 'hit'
        )
        if (
          hitRewards.length === 1
          && hitRewards[0].items.length === 1
          && hitRewards[0].items[0].type === 'xp'
        ) {
          return parseInt(hitRewards[0].items[0].value, 10)
        }
      }

      return 0
    },
  },
  created() {
    if (this.correct) {
      playSoundFX(effectsNames.CORRECT_ANSWER)
    } else {
      playSoundFX(effectsNames.WRONG_ANSWER)
    }
    this.$trackEvent({
      category: this.$track.category.mission,
      action: this.$track.action.answer,
      chapter: this.chapterId,
      label: this.correct ? this.$track.label.hited : this.$track.label.missed,
    })
  },
  methods: {
    async finishMission() {
      this.finishingAssignment = true
      try {
        await assignmentsService.finishAssignment(this.assignmentCode)
        this.$trackEvent({
          category: this.$track.category.mission,
          action: this.$track.action.finish,
          chapter: this.chapterId,
          label: `${this.question.data.assignment.subtitle} - ${this.question.data.assignment.title}`,
        })
        this.$emit('finishMission')
      } catch (error) {
        this.finishError = true
      } finally {
        this.finishingAssignment = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mission-feedback {
  width: 100%;
  padding: $size-s $size-m;
  color: #4B3728;
  background: $eureka-color-base;
  border-radius: 22px 22px 0 0;
  border: 10px solid $eureka-color-base-darker;
  border-bottom: none;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__icon {
    margin-right: 20px;

    img {
      display: block;
    }
  }

  &__text {
    flex-grow: 1;

    h4 {
      font-weight: bold;
    }

    .g-block {
      margin-top: $size-xs;
    }

    .g-button:last-of-type {
      margin-left: $size-s;
    }

    .g-alert {
      margin-bottom: $size-s;
    }
  }
}
</style>
