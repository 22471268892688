<template>
  <div class="teacher-lobby">
    <div
      v-if="!hasLobbyInfo"
      class="teacher-lobby__loading"
    >
      <GLoader />
      {{ $t('commons.loading') }}
    </div>
    <div
      v-else-if="!startedMission"
      class="teacher-lobby__content"
    >
      <ArenaPlayerList
        class="teacher-lobby__player-list"
        :total-amount-player="totalAmountPlayerText"
        :classroom="lobbyInfo.classroom"
        :logged-students="loggedStudents"
        :not-logged-students="notLoggedStudents"
        :has-highlight-information="hasMaxStudents"
      />
      <div>
        <div class="teacher-lobby__button">
          <GButton
            class="variant"
            size="large"
            asset="play-icon.svg"
            @click="startMission"
          >
            {{ $t('ancestralArena.lobby.start') }}
          </GButton>
        </div>
        <img
          class="teacher-lobby__image"
          src="@/assets/char/cora/cora-happy.svg"
          alt="Personagem cora está feliz"
        >
      </div>
    </div>
    <div
      v-else
      class="countdown"
    >
      <p class="countdown__text">
        {{ $t('ancestralArena.lobby.startingMission') }}
      </p>
      <BadgeCount
        class="countdown__badge"
        :disable-format-count="true"
        color="$eureka-color-notice"
        :count="count"
        size="large"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import arenaApi from '@/service/arena'
import socketClient from '@/client/socketClient'
import GLoader from '@/components/GLoader'
import GButton from '@/components/GButton'
import BadgeCount from '@/components/BadgeCount'
import isEmpty from 'lodash/isEmpty'
import ArenaPlayerList from './partials/ArenaPlayerList'

export default {
  name: 'MagnaMissionLobbyTeacher',
  components: {
    ArenaPlayerList,
    GLoader,
    BadgeCount,
    GButton,
  },
  props: {
    classroomName: {
      type: String,
      default: '',
    },
    timerInfo: {
      type: Object,
      default: () => ({
        currentTime: '00:00',
        hasTimer: false,
        timerStatus: null,
      }),
    },
  },
  data() {
    return {
      startedMission: false,
      count: 3,
      teacherChallengeRoomsSubscription: null,
      lobbyInfo: null,
      userQueueErrorsSubscription: null,
      error: null,
    }
  },
  computed: {
    ...mapGetters([ 'user' ]),
    ...mapState({
      magnaMission: ({ arena }) => arena.magnaMission.data,
    }),
    totalAmountPlayerText() {
      return `${this.loggedMessage} ${this.hasMaxStudents ? 'Turma completa' : 'Jogadores'}`
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    loggedMessage() {
      return `${this.loggedStudentsAmount} / ${this.lobbyInfo?.maxStudentsAmount ?? 0}`
    },
    hasLobbyInfo() {
      return !isEmpty(this.lobbyInfo)
    },
    isStartedMission() {
      if (this.hasLobbyInfo) {
        return this.lobbyInfo?.status === 'STARTED'
      }

      return false
    },
    loggedStudents() {
      return this.lobbyInfo?.students.filter((student) => student.logged) ?? []
    },
    notLoggedStudents() {
      return this.lobbyInfo?.students.filter((student) => !student.logged) ?? []
    },
    loggedStudentsAmount() {
      return this.loggedStudents?.length ?? 0
    },
    notLoggedStudentsAmount() {
      return this.notLoggedStudents?.length ?? 0
    },
    hasMaxStudents() {
      return this.loggedStudents.length === this.lobbyInfo?.maxStudentsAmount
    },
  },
  watch: {
    count: {
      immediate: true,
      handler(count) {
        if (count === 0) {
          this.goToRoom()
        }
      },
    },
    isStartedMission: {
      handler() {
        this.goToRoom()
      },
    },
  },
  mounted() {
    this.connect()
  },
  methods: {
    ...mapActions([ 'setMagnaMission' ]),
    goToRoom() {
      this.$router.push({
        name: 'magna-mission-room-teacher',
        params: {
          classroomId: this.lobbyInfo.classroomId,
          questionnaireCode: this.$route.params.questionnaireCode,
        },
      })
    },
    connect() {
      this.setMagnaMission({ loading: true })
      if (socketClient.clientIsConnected()) {
        this.enterLobby()
      } else {
        socketClient.getClient(this.questionnaireCode).onConnect = () => {
          this.enterLobby()
        }
      }
    },
    enterLobby() {
      const topic = `/topic/teacher.challenge-rooms.${this.questionnaireCode}`
      const onSubscribe = (response) => {
        this.lobbyInfo = JSON.parse(response.body)

        const { title, questions } = this.lobbyInfo
        this.setMagnaMission({ data: { title, questions }, loading: false })
      }

      this.teacherChallengeRoomsSubscription = socketClient.subscribe(topic, onSubscribe)
      this.userQueueErrorsSubscription = this.subscribeQueueErrors()
      this.sendTeacherEntryConfirmation()
    },
    subscribeQueueErrors() {
      const topic = '/user/queue/errors'
      const onError = (response) => {
        const { status, message } = JSON.parse(response.body)
        this.error = {
          message: `[${status}] ${message}`,
          type: 'serverError',
          icon: 'alert-circle',
          isCloseable: true,
        }
        this.$emit('error-ocurred', this.error)
        this.error = false
      }

      return socketClient.subscribe(topic, onError)
    },
    sendTeacherEntryConfirmation() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/subscribe`

      socketClient.emit(destination)
    },
    startCountDown() {
      const timer = setInterval(() => {
        if (this.count > 0) {
          this.count -= 1
        } else {
          clearInterval(timer)
        }
      }, 1000)
    },
    async startMission() {
      this.startedMission = true
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.startMissionLobby,
        label: this.$track.label.numberOfParticipatingStudents,
        value: this.loggedStudentsAmount,
      })
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.startMissionLobby,
        label: this.$track.label.totalNumberOfStudents,
        value: this.lobbyInfo.maxStudentsAmount,
      })

      const anyQuestionHasTimer = this.magnaMission
        ?.questions
        ?.some((question) => question.hasTimer)

      const timerLabel = anyQuestionHasTimer
        ? this.$track.label.usedTimer
        : this.$track.label.notUsedTimer

      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherStartMission,
        label: timerLabel,
      })

      this.startCountDown()
      socketClient.unsubscribe(this.teacherChallengeRoomsSubscription)
      await arenaApi.updateChallengeQuestionnaire(this.questionnaireCode, { status: 'started' })
    },
  },
}
</script>

<style lang="scss" scoped>
.teacher-lobby {
  @include flex-center;
  height: calc(100vh - 252px);
  padding: $size-s;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  &__loading {
    font-family: Rowdies;
    font-size: $font-size-heading-2;
    color: $color-white;
    text-align: center;
  }

  &__content {
    gap: 32px;
    display: flex;

    height: calc(100vh - 252px);

    min-width: 50vw;
    margin: auto;
    margin-top: $size-xl;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      padding: 0 $size-xs;
      flex-direction: column;
      width: 100%;
      gap: 4px;
      height: calc(100vh - 125px);
    }
  }

  &__image {
    width: 100%;
    height: 50vh;

    @media screen and (max-width: 768px) {
      display: none;
      height: fit-content;
    }
  }

  &__player-list {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 579px) {
      height: calc(100vh - 252px);
    }
  }

  &__button {
    font-family: Rowdies;

    @media screen and (max-width: 768px) {
      text-align: center;
      margin: 0;
      padding: 0;
    }

    ::v-deep .g-button {
      border: none;
      margin: $size-s 0;
      width: 265px;

      &__image.--large {
        width: 18px;
        height: auto;
      }
    }
  }
}

.countdown {
  transform: scale(4);

  @media screen and (max-width: 768px) {
    transform: scale(3);
  }

  &__badge {
    position: static;
    margin: 0 auto;
    background-color: #8E53D2;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: 6rem;
    color: $color-white;
    text-shadow: 0px 1px 0px $color-black;
  }

  &__text {
    line-height: 2.5;
    font-size: $font-size-xs;
    text-shadow: 0 4px 4px $color-black;
    font-family: Rowdies;
    color: $color-white;
    font-weight: $font-weight-bold;
  }
}
</style>
