<template>
  <div class="feed-header">
    <div
      class="feed-header__content"
    >
      <GButton
        v-if="backButton"
        class="feed-header__back-button"
        variation="secondary"
        @click="$emit('back')"
      >
        <img
          class="feed-header__button-icon"
          src="@/assets/news-arrow-left.svg"
        >
      </GButton>
      <img
        v-else
        class="feed-header__news-icon"
        src="@/assets/news-icon.svg"
      >
      <FeedHeaderSkeleton v-if="loading" />
      <h4 v-else>
        {{ title || $t('game.news.news') }}
      </h4>
    </div>
    <GButton
      v-if="!backButton"
      variation="secondary"
      @click="$emit('close')"
    >
      <img
        class="feed-header__button-icon"
        src="@/assets/icon-x-secondary.svg"
      >
    </GButton>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import FeedHeaderSkeleton from './FeedHeaderSkeleton'

export default {
  name: 'FeedHeader',
  components: { GButton, FeedHeaderSkeleton },
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    backButton: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.feed-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $size-xs $size-s;
  background: #FBF1E1;
  border-bottom: 5px solid #EECE8A;
  box-shadow: -2px 2px 2px #D5C49A;

  &__content {
    @include flex-center-start;
    width: 100%;
  }

  &__back-button {
    margin-right: $size-xs
  }

  ::v-deep .g-button {
    flex-shrink: 0;
    padding: 12px;
    height: auto;
    width: auto;
  }

  &__news-icon {
    width: $size-xl;
    height: $size-xl;
    margin-right: $size-xs
  }

  &__button-icon {
    width: $size-l;
    height: $size-l;
  }
}
</style>
