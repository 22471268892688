<template>
  <div
    class="magna-mission-tabs"
    role="tablist"
  >
    <button
      v-for="tab in tabs"
      :key="tab.id"
      role="tab"
      :class="['magna-mission-tabs__tab-item', `--${variation}`, {'--active': isTabActive(tab)}]"
      @click="openTab(tab)"
    >
      <span
        :class="[
          'magna-mission-tabs__tab-item__text',
          `--${variation}`,
          {'--active': isTabActive(tab)}
        ]"
      >
        {{ tab.text }}
      </span>
    </button>
  </div>
</template>

<script>
import updateRoute from '@/mixins/updateRoute'

export default {
  name: 'MagnaMissionTabs',
  mixins: [ updateRoute ],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => value.match(/(primary|secondary)/),
    },
  },
  data() {
    return {
      currentTab: null,
    }
  },
  computed: {
    tabQueryParam() {
      return this.$route.query.tab
    },
  },
  watch: {
    tabQueryParam: {
      immediate: true,
      handler() {
        const { tabs, tabQueryParam, openTab } = this
        const [ firstTab ] = tabs
        const tabFound = tabs.find(({ name }) => name === tabQueryParam)

        if (tabFound) {
          openTab(tabFound)
        } else {
          openTab(firstTab)
        }
      },
    },
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab

      this.$emit('change-component', this.currentTab)
      this.updateRouteQueryWithReplace('tab', this.currentTab.name)
    },
    isTabActive(tab) {
      return this.currentTab.name === tab.name
    },
  },
}
</script>

<style lang="scss" scoped>
.active-tab-mark {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  height: $size-xxs;
  width: 100%;
  border-radius: $size-xs $size-xs 0 0;
}

.magna-mission-tabs {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 60px;

  @media screen and (max-width: 579px) {
    padding: 0 12px;
    overflow-x: auto;
  }

  &__tab-item {
    height: 50px;
    border: none;
    position: relative;
    background-color: transparent;
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &::after {
      @extend .active-tab-mark;
      background-color: transparent;
      transition: background-color .5s;
    }

    &.--active {
      position: relative;

    &.--primary {
      &::after {
        background-color: $color-white;
      }
    }

    &.--secondary {
      &::after {
        background-color: $eureka-color-secondary;
      }
    }
    }

    &__text {
      cursor: pointer;
      color: $color-white;
      word-wrap: none;
      line-break: none;

      font: {
        family: Rowdies;
      }
      opacity: 0.7;

      &.--secondary {
      color: $eureka-color-ink;
      }

      &.--active {
        opacity: 1;
      }
    }
  }
}
</style>
