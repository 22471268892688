<template>
  <div @click="$emit('click')">
    <div class="article-preview__header">
      <div class="article-preview__info">
        <span
          :class="[
            'category-badge',
            {'--important' : isImportantNovelty}
          ]"
        >
          {{ badgeTitle }}
        </span>
        <span class="article-preview__info__date">
          {{ articleDate }}
        </span>
        <span
          v-show="isArticleNew"
          class="category-badge --new"
        >
          Novo!
        </span>
      </div>
      <h6 class="article-preview__title">
        {{ article.title }}
      </h6>
    </div>
    <div
      class="article-preview__body"
      v-html="article.excerpt"
    />
    <b>
      {{ $t('game.news.readMore') }}
    </b>
  </div>
</template>

<script>
import formatDate from '@/utils/formatDate'
import { playSoundFX } from '@/utils/soundEffects'

export default {
  name: 'ArticlePreview',
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badgeTitle() {
      return this.isImportantNovelty ? 'Importante!' : 'Atualização'
    },
    isArticleNew() {
      return !this.article?.read
    },
    isImportantNovelty() {
      return this.article?.important
    },
    articleDate() {
      return formatDate.brazillianDateFormat(
        this.article.publishedAt
      )
    },
  },
  methods: {
    playSound() {
      playSoundFX()
    },
  },
}
</script>

<style lang="scss">
.article-preview {
  &__info {
    margin-bottom: $size-xs;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__date {
      font-weight: $font-weight-medium;
      font-size: $font-size-xs;
      color: $game-ink;
      opacity: 0.6;
    }
    span:last-of-type {
      margin-left: auto;
    }
  }

  &__header {
    margin-bottom: $size-s;
  }

  &__body {
    margin-bottom: $size-xs;

    img {
      max-width: 100%;
      border-radius: $size-s;
    }
  }
}

.category-badge {
  background: #FFB155;
  box-shadow: 0 2px 0 #FF961C;
  border-radius: 23px;
  color: #6C400B;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  padding: $size-xxs $size-xs;
  margin-right: $size-xs;

  &.--important {
    background: linear-gradient(0deg, #BF94EB, #BF94EB), #FFB155;
    box-shadow: 0 2px 0 #9960D2;
    color: #622C98;
  }

  &.--new {
    background: $eureka-color-notice;
    box-shadow: 0px 2px 0px $eureka-color-notice-dark;
    color: $color-white;
  }
}
</style>
