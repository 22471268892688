<template>
  <div class="layout">
    <div class="create-magna-mission-question-selector">
      <img
        alt=""
        src="./assets/top-icon.svg"
        class="topIcon"
      >
      <h3 class="title">
        Selecione como você deseja iniciar a criação da missão
      </h3>
      <div class="separator" />
      <div
        class="content"
        data-tour="question-selector"
      >
        <div
          v-if="isSasQuestionsEnabled"
          class="card cardLeft"
          role="button"
          aria-label="Adicionar questões do banco SAS Educação"
          @click="handleSASQuestionClick"
        >
          <img
            class="icon"
            alt=""
            src="@/assets/ancestral-arena/sas-mission-icon.svg"
          >
          <p class="cardTitle">
            Adicionar questões do banco SAS Educação
          </p>
        </div>
        <div
          class="card cardRight"
          role="button"
          aria-label="Adicionar sua própria questão"
          @click="handleMyQuestionClick"
        >
          <img
            class="icon"
            alt=""
            src="@/assets/ancestral-arena/my-mission-icon.svg"
          >
          <p class="cardTitle">
            Adicionar sua própria questão
          </p>
        </div>
      </div>
    </div>
    <TourQuestionSelector />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mediaQueries from '@/mixins/mediaQueries'
import TourQuestionSelector from './TourQuestionSelector'

export default {
  name: 'CreateMagnaMissionQuestionSelector',
  components: {
    TourQuestionSelector,
  },
  mixins: [ mediaQueries ],
  props: {
    debounceEventTime: {
      type: Number,
      default: 200,
    },
    isSaveDisabled: Boolean,
  },
  computed: {
    ...mapState({
      isSasQuestionsEnabled: ({ featureToggles }) => featureToggles.toggles.eurekaSasQuestions,
    }),
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
  },
  created() {
    if (!this.isSasQuestionsEnabled) {
      this.handleMyQuestionClick()
    }
  },
  methods: {
    handleSASQuestionClick() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherSelectQuestionType,
        label: this.$track.label.addSasQuestions,
      })

      if (this.debounceEventTime === 0) {
        this.$emit('add-sas-question')

        return
      }

      setTimeout(() => {
        this.$emit('add-sas-question')
      }, this.debounceEventTime)
    },
    handleMyQuestionClick() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherSelectQuestionType,
        label: this.$track.label.addTeacherQuestions,
      })
      this.$emit('add-my-question')
    },
  },
}
</script>
<style lang="scss" scoped>
.save-mission {
  display: none;

  @media screen and (max-width: 579px) {
    display: block;
    top: 80px;
    left: 10px;
  }
}
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @include mq_s {
    margin-top: 60px;
    justify-content: unset;
    height: auto;
  }
}

.create-magna-mission-question-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  background: $eureka-color-base-lighter;
  border-radius: 16px;
  padding: 40px;
  max-width: 850px;
  border: $size-s solid #dda57e;
  box-shadow: 0 3px 0 #b06e40,
    inset 0 1px 0 rgba($eureka-color-base-lighter, 0.75);
  border-radius: 24px;
  margin: 0 20px 60px;

  .topIcon {
    top: -113px;
    position: absolute;
    max-height: 150px;
    object-fit: contain;
    @include mq_s {
      top: -60px;
    }
  }

  .title {
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: 2rem;
    line-height: $line-height-heading;
    display: flex;
    align-items: center;
    text-align: center;
    color: $eureka-color-ink;
    max-width: 600px;
    border-bottom: 1px solid $eureka-color-base-dark;
    padding-bottom: $size-m;
    margin-bottom: $size-l;
    @include mq_s {
      padding-bottom: $size-s;
      margin-bottom: $size-m;
      font-size: $font-size-m;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $size-xxl;

    @media (max-width: 744px) {
      overflow: auto;
      grid-template-columns: none;
      min-height: 200px;
    }

    &:global(.tour-active) {
      padding: 10px;
    }
  }

  .card {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    height: 200px;
    font-family: Rowdies;
    font-style: normal;
    font-weight: $font-weight-base;
    font-size: $font-size-heading-4;
    line-height: $line-height-heading;
    color: $color-white;
    text-shadow: 0 1px 0 $color-black;
    background: linear-gradient(
      0,
      rgba($color-black, 0.8) 0%,
      rgba($color-black, 0) 100%
    );
    box-shadow: 0 3px 0 #c2ab8c, inset 0 1px 0 rgba($color-white, 0.75);
    border-radius: $border-radius-m;
    cursor: pointer;
    transition: all $speed-fast ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 15px;
    object-fit: contain;
    max-height: 60px;
  }

  .cardLeft {
    background: linear-gradient(
        0,
        rgba($color-black, 0.8) 0%,
        rgba($color-black, 0) 100%
      ),
      url(./assets/biblioteca-magna-ex-1.png);
  }

  .cardRight {
    background: linear-gradient(
        0,
        rgba($color-black, 0.8) 0%,
        rgba($color-black, 0) 100%
      ),
      url(./assets/eureka_bgs-story_200211.png);
  }

  .cardTitle {
    padding: $size-s;
    text-align: left;
  }
}

.save-btn.g-button {
  border: none !important;
  ::v-deep .g-button__text {
    font: {
      family: Rowdies;
      weight: $font-weight-regular !important;
    }
  }

  &:disabled {
    background-color: $eureka-color-ink-lighter !important;
  }
}

</style>
