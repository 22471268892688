<template>
  <div
    :class="[
      'sas-dropdown',
      { 'sas-dropdown--loading': loading },
    ]"
    @keyup.esc="hideMenu()"
  >
    <transition name="slide">
      <Spinner
        v-if="loading"
        class="sas-dropdown__spinner"
        custom-size="22px"
      />
    </transition>

    <Button
      :class="[
        'sas-dropdown__button',
        {
          [`sas-dropdown--${status}`]: status,
          multiselect,
          opened: menuVisible,
        },
      ]"
      :disabled="disabled"
      :icon-right="icon"
      :size="size"
      :variation="variation"
      @click="multiselect ? $emit('multiselect-click', $event) : toggleMenu()"
    >
      {{ multiselectTags ? '' : text }}
      <div
        v-if="multiselectTags"
        class="multiselect-container"
      >
        <slot name="multiselect-tags" />
        <icon
          v-if="!disabled"
          class="adaptive-selector-dropdown__handle__caret"
          type="chevron-down"
        />
      </div>
    </Button>

    <DropdownMenu
      v-if="menuVisible"
      :origin="origin"
    >
      <slot />
    </DropdownMenu>
  </div>
</template>

<script>
import { Button, Spinner } from '@sas-te/alfabeto-vue'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'

export default {
  name: 'Dropdown',
  components: {
    Button,
    Spinner,
    DropdownMenu,
  },
  props: {
    loading: Boolean,
    disabled: Boolean,
    size: {
      type: String,
      default: 'medium',
      validator: (value) => value.match(/(small|medium|large)/),
    },
    /**
     * Toggle's variations
     * `primary, secondary, danger`
     */
    variation: {
      type: String,
      default: 'secondary',
      validator: (value) => value.match(/(primary|secondary|danger)/),
    },
    /**
     * Status of the input
     * `success, error, warning`
     */
    status: {
      type: String,
      default: null,
      validator: (value) => value.match(/(success|error|warning)/),
    },
    /**
     * Text displayed in toggle
     */
    text: {
      type: String,
      default: '',
      required: true,
    },
    /**
     * Name of the Icon: adds a icon on the right of the button
     * Uses `feather-icons`
     */
    icon: {
      type: String,
      default: 'chevron-down',
    },
    /**
     * Origin of the dropdown menu relative to toggle's position
     * `top-right, top-left, bottom-right, bottom-left`
     */
    origin: {
      type: String,
      default: 'bottom-right',
      validator: (value) => value.match(/(top-right|top-left|bottom-right|bottom-left)/),
    },
    /**
     * Enable closing menu on blur
     */
    disableClickOutside: Boolean,
    /**
     * Manually open the menu
     */
    open: Boolean,
    multiselect: Boolean,
    multiselectTags: Boolean,
  },
  data() {
    return {
      menuVisible: false,
      documentClickHandler: null,
    }
  },
  watch: {
    open(value) {
      this.menuVisible = value
    },
    menuVisible(value) {
      this.$emit('toggle', value)
    },
  },
  mounted() {
    if (!this.disableClickOutside) {
      this.documentClickHandler = (event) => {
        const element = event.target
        const menuItems = Array.from(this.$el.getElementsByClassName('sas-dropdown-menu__item'))
        const isMenuItem = () => menuItems.some((menuItem) => menuItem.contains(element))

        if (this.menuVisible && (isMenuItem() || !this.$el.contains(element))) {
          this.hideMenu()
        }
      }

      document.addEventListener('click', this.documentClickHandler)
    }
  },
  destroyed() {
    if (!this.disableClickOutside && this.documentClickHandler) {
      document.removeEventListener('click', this.documentClickHandler)
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('clicked')
      this.menuVisible = !this.menuVisible

      if (!this.menuVisible) {
        this.$emit('blur')
      }
    },
    openMenu() {
      this.menuVisbile = true
    },
    hideMenu() {
      this.menuVisible = false
      this.$emit('blur')

      if (this.multiselect) {
        this.$emit('close-multiselect')
      }
    },
  },
}
</script>

<style lang="scss">

.sas-dropdown {
  position: relative;
  display: inline-block;

  background-color: #FCF5E8;
  border: 1px solid #c9cccf;
  box-shadow: 0 1px 2px rgba(30, 33, 36, 0.25);
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 16px;
  position: relative;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 200ms ease-in-out;

  &:focus {
    outline: 0;
  }
  button {
    background: none;
    border: none;
    display: flex;
  }
}

.sas-dropdown__button {
  justify-content: space-between;
}

.sas-dropdown__spinner {
  position: absolute;
  right: 8px;
  bottom: 6px;
  z-index: 1;
  color: $color-primary;
}

.sas-dropdown--loading {
  pointer-events: none;
  cursor: progress;

  .sas-button__icon {
    opacity: 0;
  }
}

@include v-transition(slide) {
  transform: translateY(100%)
}

@include v-transition(dropdown) {
  opacity: 0;
  transform: translateY(-10%);
}
</style>
