<template>
  <div class="collection-items">
    <h2 class="collection-items__title">
      {{ title }}
    </h2>
    <div class="collection-items__group">
      <CollectionItem
        v-for="(item, index) in items"
        :key="index"
        :size="item.size"
        :type="item.type"
        :image="item.image"
        :theme-color="item.themeColor"
        :quantity="item.quantity"
        :text="item.text"
        :text-top="item.textTop"
      />
    </div>
  </div>
</template>

<script>
import CollectionItem from '@/components/CollectionItem'

export default {
  name: 'CollectionItems',
  components: {
    CollectionItem,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      require: true,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss" scoped>
.collection-items {
  &__title {
    margin-bottom: $size-l;
    font: {
      family: Rowdies;
      weight: $font-weight-light;
      size: $font-size-heading-4;
    }
    line-height: 1.5;
    color: $color-white;
  }

  &__group {
    display: flex;
    flex-direction: row;
    justify-content: center;

    ::v-deep .g-block__text {
      font: {
        family: Rowdies;
        weight: $font-weight-light;
        size: $font-size-heading-4;
      }
    }
  }
}
</style>
