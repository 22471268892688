<template>
  <div
    class="adventure-dialog"
    @click="stopAnimation"
  >
    <div
      v-if="title"
      class="adventure-dialog__actor-badge"
      :style="{backgroundColor: color}"
    >
      <span>{{ title }}</span>
    </div>
    <p
      id="dialog-text"
      class="adventure-dialog__text"
      v-html="dialogText"
    />
    <GButton
      v-if="!hideNextButton"
      class="adventure-dialog__button"
      icon="chevron-right"
      size="large"
      @click="next"
    />
  </div>
</template>
<script>
import { playSoundFX } from '@/utils/soundEffects'
import GButton from '@/components/GButton'
import Splitting from 'splitting'

export default {
  name: 'AdventureDialog',
  components: {
    GButton,
  },
  props: {
    dialog: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hideNextButton: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#E73525',
    },
  },
  computed: {
    dialogText() {
      return Splitting.html({
        target: '.adventure-dialog__text',
        content: this.dialog,
      })
    },
  },
  watch: {
    dialog() {
      const dialogTextElement = document.getElementById('dialog-text')
      dialogTextElement.scrollTop = 0
    },
  },
  methods: {
    next() {
      playSoundFX()
      this.$emit('next')
    },
    stopAnimation() {
      const chars = document.querySelectorAll('.char')

      chars.forEach((char) => {
        const animationChar = char
        animationChar.style.animation = 'none'
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.adventure-dialog {
  position: relative;
  display: flex;
  gap: 2px;
  width: 100%;
  max-width: 1200px;
  max-height: 180px;
  min-height: 150px;
  background: $eureka-color-base;
  border: 8px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0px 4px 0px #CBA976;
  padding: $size-m;

  &__actor-badge {
    position: absolute;
    top: -25px;
    display: flex;
    flex-grow: 0;
    width: max-content;
    margin: 0px 10px;
    padding: 6px 10px;
    border-radius: 48px;
    background: #E73525;
    color: $color-white;
    text-shadow: 0px 1px 0px #000000;
    font-size: 24px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    line-height: 24px;
    align-items: center;
    text-align: center;
    z-index: 9999;
    align-self: center;
  }

  &__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: $eureka-color-ink;
    user-select: none;
    width: 100%;
    overflow: auto;
  }

  &__button {
    margin-right: -8px;
  }

  ::v-deep .splitting .char {
    position: relative;
    animation: slide-in 300ms cubic-bezier(0.8, -0.3, 0.08, 1.5) both;
    animation-delay: calc(40ms * var(--char-index));
    bottom: 0;
  }

  @keyframes slide-in {
    from {
      bottom: -8px;
      opacity: 0;
    }
  }
}
</style>
