<template>
  <div
    :class="[
      'adventure-options',
      {'--disabled': disabled }
    ]"
    @click="$emit('click')"
  >
    <div cclass="adventure-options__body">
      {{ option.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdventureOptions',
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass" scoped>
.adventure-options
  position: relative
  height: auto
  min-height: 60px
  background: $color-white
  border: 1px solid $eureka-color-base
  box-sizing: border-box
  box-shadow: 0px 4px 0px #CBA976, 0px 4px 24px rgba(0,0,0,0)
  border-radius: 12px
  align-self: stretch
  margin: 0px 16px
  max-width: 500px
  width: 100%
  padding: 16px 24px
  font-weight: 700
  font-size: 18px
  line-height: 150%
  align-items: center
  color: $eureka-color-ink
  cursor: pointer
  +transition($speed-fast)

  &:hover
    transform: scale(1.03)
    box-shadow: 0px 4px 0px #CBA976, 0px 4px 24px rgba(0,0,0,0.2)
    opacity: 1

  &:active
    transform: scale(0.95)
    box-shadow: 0px 4px 0px #CBA976, 0px 1px 20px rgba(0,0,0,0.1)

.--disabled
  animation: fade 1s
  opacity: 0.6

@keyframes fade
  0%
    opacity: 1

  100%
    opacity: 0.6
</style>
