<template>
  <div class="lectures-performance">
    <div class="lectures-performance__container">
      <skeleton-loader
        width="88px"
        height="88px"
        border-radius="16px 0 0 16px"
        :style="{background: '#E3CDAB !important'}"
        opacity="0.6"
      />
      <div class="lectures-performance__container__body">
        <skeleton-loader
          width="132px"
          height="20px"
          border-radius="35px"
        />
        <div class="lectures-performance__container__body__diamond-bar">
          <skeleton-loader
            width="36px"
            height="36px"
            border-radius="31px"
          />
          <skeleton-loader
            width="251px"
            height="35px"
            border-radius="35px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LecturesPerformance',
}
</script>

<style lang="scss" scoped>
.lectures-performance {
  background: #FBF1E1;
  border-radius: $size-s;
  box-shadow: 0 3px 0 rgba(107, 68, 68, 0.19), 0 4px 4px #C19267;

  &__container {
    display: grid;
    grid-template-columns: auto 1fr;

    &__body {
      margin-left: 1rem;
      margin-top: .5rem;

      &__diamond-bar {
        margin-top: .5rem;
        display: grid;
        grid-template-columns: auto 1fr;
      }
    }
  }
}

::v-deep .sas-skeleton-loader {
  background: #CBA976 !important;
  opacity: 0.5 !important;
}
</style>
