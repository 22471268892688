<template>
  <div class="slide">
    <section
      v-if="hasValidData"
      class="slide__content"
    >
      <h1 class="slide__title --large">
        {{ mostUsedItemData.description }} {{ mostUsedItemData.adventureStyle }}
      </h1>
      <p class="slide__text">
        O item <strong class="slide__text --important">{{ mostUsedItemData.title }}</strong>
        foi o mais utilizado em suas missões
      </p>
      <HighlightedIcon class="slide__highlighted-icon">
        <img
          v-if="mostUsedItemData.image"
          class="slide__image --no-margin"
          :src="mostUsedItemData.image.png"
          :alt="mostUsedItemData.title"
        >
      </HighlightedIcon>
      <CollectionItems
        title="Esse foi o total de itens que você conseguiu coletar:"
        :items="mostUsedItemData.collectionItems"
      />
    </section>
  </div>
</template>
<script>
import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'
import isNotEmpty from '@/utils/isNotEmpty'

import HighlightedIcon from './HighlightedIcon'
import CollectionItems from './CollectionItems'

export default {
  name: 'RetrospectiveMostUsedItem',
  components: {
    HighlightedIcon,
    CollectionItems,
  },
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    hasValidData() {
      if (isEmpty(this.mostUsedItemData)) {
        return false
      }

      const {
        description,
        adventureStyle,
        title,
        image,
        collectionItems,
      } = this.mostUsedItemData

      return isNotEmpty(description)
      && isNotEmpty(adventureStyle)
      && isNotEmpty(title)
      && isNotEmpty(image)
      && isNotEmpty(collectionItems)
    },
    mostUsedItemData() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const mostUsedItemData = pick(this.retrospectiveData, [ 'mostUsedItem', 'items' ])
      const collectionItems = mostUsedItemData.items?.map(({ image, quantity }) => ({
        image: image?.svg,
        quantity,
        size: 'small',
        themeColor: 'transparent',
        type: undefined,
      }))

      return { ...mostUsedItemData.mostUsedItem, collectionItems }
    },
  },
  watch: {
    mostUsedItemData: {
      immediate: true,
      handler(newMostUsedItemData, oldMostUsedItemData) {
        if (newMostUsedItemData !== oldMostUsedItemData) {
          this.$emit('updateValidation', { name: 'mostUsedItem', isValid: this.hasValidData })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  background-image: url('~@/assets/retrospective/background-retro-3.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
