<template>
  <div class="magna-mission-players-counter">
    <skeleton-loader
      v-if="loading"
      width="72px"
      height="23px"
    />
    <template v-else>
      <span v-tooltip.bottom="studentsResponsesTooltipMessage">
        {{ `${countStudentsWhoResponded}` }}
      </span>
      /
      <span v-tooltip.bottom="activeStudentsAnswersTooltipMessage">
        {{ `${loggedStudents}` }} Respostas
      </span>
    </template>
  </div>
</template>

<script>
import userFilledIcon from '@/assets/user-filled-icon.svg'

export default {
  name: 'MagnaMissionPlayersCounter',
  props: {
    loggedStudents: {
      type: Number,
      default: 0,
    },
    countStudentsWhoResponded: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userFilledIcon,
      studentsResponsesTooltipMessage: 'Estudantes ativos que responderam',
      activeStudentsAnswersTooltipMessage: 'Estudantes ativos neste momento',
    }
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-players-counter {
  @include flex-center;
  color: $color-white;
  font: {
    size: $size-m;
    weight: $font-weight-regular;
    family: "Rowdies";
  }

  @media screen and (max-width: 768px) {
    font-size: $font-size-m;
    font-weight: $font-weight-regular;
    line-height: $line-height-heading;
    text-align: left;
  }

  &__icon {
    margin-right: $size-xs;
    width: $size-m;
  }
}

.sas-skeleton-loader {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
</style>
