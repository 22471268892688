export function trackSoundPreferenceEvent(compontentRef, value) {
  compontentRef.$trackEvent({
    category: compontentRef.$track.category.menus,
    action: compontentRef.$track.action.soundPreferences,
    label: value ? compontentRef.$track.label.on : compontentRef.$track.label.off,
  })
}

export function trackMusicPreferenceEvent(compontentRef, value) {
  compontentRef.$trackEvent({
    category: compontentRef.$track.category.menus,
    action: compontentRef.$track.action.musicPreferences,
    label: value ? compontentRef.$track.label.on : compontentRef.$track.label.off,
  })
}
