<template>
  <div
    class="magna-mission-options-modal"
    :class="{ '--is-mobile': isMobile }"
  >
    <GFrame
      v-if="isVisible"
      class="options-frame"
      variation="secondary"
    >
      <div class="options-frame__header">
        <h1 class="options-frame__header__title">
          {{ $t('commons.options') }}
        </h1>
        <GButton
          :icon-stroke="4"
          class="button"
          variation="secondary"
          icon="x"
          @click="$emit('close')"
        />
      </div>
      <div class="options-frame__content">
        <GButton
          v-for="option in options"
          :key="option.id"
          v-bind="option.buttonProps"
          @click="toggleVisibleOption(option.id)"
        >
          {{ option.label }}
        </GButton>
      </div>
    </GFrame>
    <DangerMagnaMissionModal
      v-if="optionsActions.cancel"
      :title="modalTitle"
      :description="modalDescription"
      :button-name="modalButtonName"
      @confirm="cancelMagnaMission"
      @close="toggleVisibleOption('cancel')"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mediaQueries from '@/mixins/mediaQueries'
import socketClient from '@/client/socketClient'
import GFrame from '@/components/GFrame'
import GButton from '@/components/GButton'
import DangerMagnaMissionModal from './DangerMagnaMissionModal'

export default {
  name: 'MagnaMissionOptionsModal',
  components: {
    GFrame,
    GButton,
    DangerMagnaMissionModal,
  },
  mixins: [ mediaQueries ],
  props: {
    isVisible: {
      type: Boolean,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsActions: {
        cancel: false,
      },
    }
  },
  computed: {
    ...mapState({
      enigmaPosition: ({ arena }) => arena.currentEnigmaPosition,
    }),
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    isLobbyTeacher() {
      return this.$route.name === 'lobby-teacher'
    },
    modalTitle() {
      return this.isLobbyTeacher
        ? 'Deseja fechar a missão?'
        : 'Deseja encerrar a missão?'
    },
    modalDescription() {
      return this.isLobbyTeacher
        ? 'Se tiverem alunos esperando, eles vão precisar entrar novamente em outra missão para jogar.'
        : 'O progresso da turma na missão será perdido. Você só terá o relatório quando todas as questões forem respondidas.'
    },
    modalButtonName() {
      return this.isLobbyTeacher
        ? this.$t('commons.close')
        : this.$t('commons.shutDown')
    },
  },
  methods: {
    ...mapActions([ 'setEnigmaPosition' ]),
    toggleVisibleOption(id) {
      this.optionsActions[id] = !this.optionsActions[id]

      if (this.optionsActions[id]) {
        this.$emit('close')
      }
    },
    cancelMagnaMission() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/cancel`
      this.trackOptionsEvents()

      socketClient.emit(destination)
      setTimeout(() => socketClient.disconnect(), 2000)
      this.toggleVisibleOption('cancel')
      this.setEnigmaPosition(null)
      this.$router.replace({ name: 'arena' })
    },
    trackOptionsEvents() {
      const routeName = this.$route.name
      const isCancelMission = routeName === 'magna-mission-room-teacher'
      const isAbortMission = routeName === 'lobby-teacher'

      if (isCancelMission) {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.cancelMagnaMission,
          label: this.$track.label.canceledOnQuestion,
          value: this.enigmaPosition,
        })
      }

      if (isAbortMission) {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.abortMagnaMission,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.g-frame.options-frame {
  position: absolute;
  right: $size-m;
  top: 100px;
  min-width: 320px;
  max-height: 400px;
  border: none;
  padding: $size-s $size-m;
  background: $eureka-color-base;
  overflow-y: auto;
}

.--is-mobile {
  .options-frame {
    top: 65px;
    right: 16px;
  }
}

.options-frame {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $size-m;
    margin-bottom: $size-l;
    border-bottom: 1px solid #EAD0BB;
    position: relative;

    &__title {
      font-size: $size-m;
      font-weight: 700;
      color: #25193E;
    }

    &__close-button {
      color: $eureka-color-ink;
      opacity: 0.5;
    }
  }

  &__content {
    @include flex-column-start;

    ::v-deep .g-button__image {
      margin-right: $size-xs;
    }
  }
}
</style>
