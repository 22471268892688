<template>
  <div class="slide">
    <section
      v-if="hasValidData"
      class="slide__content"
    >
      <h1 class="slide__title --large">
        Parabéns pela conquista do título
        <strong class="slide__title --important">{{ studentInsignia.insignia.title }}</strong>
        esse ano!
      </h1>
      <CongratulationsIcon class="slide__highlighted-icon">
        <img
          v-if="studentInsignia.insignia.image"
          class="student-insignia__image"
          :src="studentInsignia.insignia.image.svg"
          :alt="studentInsignia.insignia.title"
        >
      </CongratulationsIcon>
      <p
        v-if="!isMicrofrontend && studentInsignia.classroomStudentInsigniaRatio !== 0"
        class="slide__text"
      >
        Você ficou entre os {{ studentInsignia.classroomStudentInsigniaRatio }}% da
        turma que conseguiu essa conquista em Eureka!
      </p>
    </section>
  </div>
</template>
<script>
import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'
import isValid from '@/utils/isValidObjectAndValues'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

import CongratulationsIcon from './CongratulationsIcon'

export default {
  name: 'RetrospectiveStudentInsignia',
  components: {
    CongratulationsIcon,
  },
  props: {
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isMicrofrontend() {
      return getIsMicrofrontend()
    },
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    hasValidData() {
      return isValid(this.studentInsignia, [ 'classroomStudentInsigniaRatio', 'insignia' ])
    },
    studentInsignia() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      return pick(this.retrospectiveData, [
        'classroomStudentInsigniaRatio',
        'insignia',
      ])
    },
  },
  watch: {
    studentInsignia: {
      immediate: true,
      handler(newMostUsedItemData, oldMostUsedItemData) {
        if (newMostUsedItemData !== oldMostUsedItemData) {
          this.$emit('updateValidation', { name: 'studentInsignia', isValid: this.hasValidData })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  position: relative;
  background-color: $eureka-color-ink;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/retrospective/background-retro-6.png');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
  }
}

.student-insignia {
  &__image {
    height: 140px;
  }
}
</style>
