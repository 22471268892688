const SIXTH_GRADE_ID = 3
const SEVENTH_GRADE_ID = 14
const EIGHT_GRADE_ID = 15
const NINTH_GRADE_ID = 16

export const logosGrades = [
  SIXTH_GRADE_ID,
  SEVENTH_GRADE_ID,
  EIGHT_GRADE_ID,
  NINTH_GRADE_ID,
]
