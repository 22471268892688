<template>
  <div
    :class="['option', { '--selected-mark': isSelected, '--animate': animationTriggered }]"
    :style="borderColor"
    @click="selectOption"
  >
    <div
      class="option__info"
      :style="cardMarkStyle"
    >
      <div
        class="option__info-letter"
        :style="styleLetter"
        :aria-label="`Alternativa ${option.letter}`"
      >
        {{ option.letter }}
      </div>
      <slot name="description">
        <div
          class="option__info-description"
          aria-label="Descrição"
          v-html="sanitize(option.description)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { sanitize } from 'dompurify'
import { isNil } from 'lodash-es'

export default {
  name: 'QuestionOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleLetter: {
        background: this.option.color,
        borderRadius: '8px 0 0 8px',
      },
      animationTriggered: false,
    }
  },
  computed: {
    isSelected() {
      const markedOption = this.question?.markedOption

      if (isNil(markedOption) || !Number.isInteger(Number(markedOption))) return false

      return Number(markedOption) === Number(this.option?.id)
    },
    borderColor() {
      const { color } = this.option

      return {
        '--borderColor': color,
      }
    },
    cardMarkStyle() {
      if (!this.isSelected) return {}

      return {
        boxShadow: 'none',
      }
    },
  },
  watch: {
    question(prevState, newState) {
      if (prevState?.id !== newState?.id) this.animationTriggered = false
    },
  },
  methods: {
    selectOption() {
      const markedOption = this.isSelected ? -1 : this.option.id
      this.$emit('select-option', { ...this.option, markedOption })
      this.animationTriggered = true
    },
    sanitize(html) {
      return sanitize(html)
    },
  },
}
</script>

<style lang="scss" scoped>
.option {
  cursor: pointer;
  position: relative;
  width: 100%;
  color: $eureka-color-ink;
  font-family: Rowdies;
  box-shadow: 0 3px 0 #C2AB8C, 0 6px 5px rgba($color-black, 0.1);
  border-radius: $border-radius-m;
  box-sizing: content-box;
  margin: 5px;
  height: min-content;

  &__info {
    display: flex;
    width: 100%;
    height: 100%;

    ::v-deep {
      p {
        @include embedded-paragraph;
        font-weight: $font-weight-light;
      }

      img {
        @include embedded-image;
      }
    }
  }

  &__info-letter {
    color: $color-white;
    font-family: Rowdies;
    font-size: $size-m;
    padding: $size-m $size-s;
    @include flex-center;
  }

  &__info-description {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: $size-s;

    word-break: break-word;
    font-weight: $font-weight-regular;

    border-radius: 0 $border-radius-m $border-radius-m 0;
    background-color: $eureka-color-base-light;
  }
}

.--selected-mark {
  box-shadow: none;
  margin: 0;
  overflow: hidden;
  border-left: 5px solid var(--borderColor);
  padding: 0 0 1px 0;

      &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      border: 5px solid var(--borderColor);
      border-left: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -15px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-left: 31px solid var(--borderColor);
      transform: rotate(90deg);
    }

  &.--animate {
    animation: borderRight 2s steps(9, end) forwards;

    &::before {
      animation: slide-in 2s forwards;
    }

    &::after {
      animation: appear 2s forwards;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    97% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    0% {
      border-right: 0;
      transform: translateX(-100%);
    }

    100% {
      border-right: 0;
      transform: translateX(0);
    }
  }

  @keyframes borderRight {
    90% {
      border-right: 2px solid var(--borderColor);
    }

    100% {
      border-right: 5px solid var(--borderColor);
    }
  }
}
</style>
