<template>
  <div class="feedback">
    <GFrame
      class="feedback-frame"
      variation="secondary"
    >
      <div class="feedback-frame__header">
        <img
          class="feedback-frame__header__icon"
          src="@/assets/feedback-icon.svg"
        >
        <div class="feedback-frame__header__text">
          <h1 class="feedback-frame__header__title">
            {{ $t('game.feedback.title') }}
          </h1>
        </div>
        <GButton
          :icon-stroke="4"
          class="button"
          variation="secondary"
          icon="x"
          @click="toggleComponentVisible"
        />
      </div>
      <div
        ref="feedbackModal"
        class="feedback-frame__content"
      />
    </GFrame>
    <div
      class="feedback__overlay"
      @click="toggleComponentVisible"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import typeform from '@/service/typeform'

import GButton from '@/components/GButton'
import GFrame from '@/components/GFrame'
import urls from '@/utils/urls'

export default {
  components: {
    GButton,
    GFrame,
  },
  computed: {
    ...mapGetters([
      'user',
      'userGrades',
      'grade',
    ]),
    ...mapState('typeform', [ 'isTypeformReady' ]),
  },
  mounted() {
    this.openFeedbackSurvey()
  },
  methods: {
    toggleComponentVisible() {
      this.$emit('toggle-component-visibility', { id: 'feedback' })
    },
    openFeedbackSurvey() {
      const url = urls.BASE_URL.concat(this.$route.path)
      const accountId = this.user.data.id
      const gradeName = this.grade?.name
      const platform = 'web'
      const profile = this.user.data.profiles[0].name
      typeform.createWidget({
        container: this.$refs.feedbackModal,
        formId: 'ywZjoaj8',
        hiddenFields: {
          url,
          account_id: accountId,
          grade: gradeName,
          platform,
          profile,
          'typeform-welcome': 0,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback__overlay {
  background: transparent;
  @include cover(fixed);
  @include transition($speed-normal);
  z-index: 889;
}

.feedback-frame {
  position: relative;
  width: 600px;
  height: 600px;
  z-index: 999;

  padding: $size-s $size-l;
  background: $eureka-color-base;
  overflow-y: auto;

  @media screen and (max-width: 991px) {
    width: 100vw;
    height: 100vh;
  }

  &__content {
    height: 480px;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 991px) {
      height: 80vh;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: min-content auto min-content;
    justify-content: space-space-between;
    align-items: center;
    padding-bottom: $size-xs;
    border-bottom: 1px solid #EAD0BB;
    position: relative;

    &__icon {
      min-width: 56px;
      height: auto;
      margin-right: $size-xs;
    }

    &__title {
      font-size: 40px;
      font-weight: 700;
      color: #25193E;
    }

    &__close-button {
      position: absolute;
      right: -24px;
      top: 0;
      color: $eureka-color-ink;
      opacity: 0.5;
    }
  }

  &__content .adventures-seasons-layout {
    &:not(:last-child) {
      padding-bottom: $size-m;
      border-bottom: 1px solid #EAD0BB;
      margin-bottom: $size-m;
    }
  }
}

.button {
  background: transparent;
  color: $eureka-color-ink;
  box-shadow: none;
  border: none;

  &:hover {
    box-shadow: none;
    background: rgba(0, 0 , 0, 0.1);
  }
}

</style>
