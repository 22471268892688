<template>
  <div class="news-feed">
    <Drawer
      size="380px"
      background="#F2E9D0"
      @close="toggleComponentVisible"
    >
      <div class="news-feed-container">
        <NewsArticle
          v-if="articleVisible"
          @back="closeArticle"
        />
        <NewsFeed
          v-else
          @close="toggleComponentVisible"
          @set-article-visible="setArticleVisible"
          @read-article="$emit('decrement-news-notifications')"
        />
      </div>
    </Drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Drawer } from '@sas-te/alfabeto-vue'

import NewsArticle from '@/components/News/NewsArticle'
import NewsFeed from '@/components/News/NewsFeed'

export default {
  name: 'NewsDrawer',
  components: {
    NewsFeed,
    Drawer,
    NewsArticle,
  },
  data() {
    return {
      articleVisible: false,
    }
  },
  computed: {
    ...mapGetters([ 'allNews' ]),
  },
  methods: {
    ...mapActions([
      'getUnreadNews',
      'getAllNews',
    ]),
    setArticleVisible(isVisible) {
      this.articleVisible = isVisible
    },
    toggleComponentVisible() {
      this.$emit('toggle-component-visibility', { id: 'news' })
    },
    closeArticle() {
      this.getAllNews()
      this.articleVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.news-feed-container {
  height: 100%;
}
</style>
