var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sas-dropdown',
    { 'sas-dropdown--loading': _vm.loading },
  ],on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.hideMenu()}}},[_c('transition',{attrs:{"name":"slide"}},[(_vm.loading)?_c('Spinner',{staticClass:"sas-dropdown__spinner",attrs:{"custom-size":"22px"}}):_vm._e()],1),_c('Button',{class:[
      'sas-dropdown__button',
      {
        [`sas-dropdown--${_vm.status}`]: _vm.status,
        multiselect: _vm.multiselect,
        opened: _vm.menuVisible,
      },
    ],attrs:{"disabled":_vm.disabled,"icon-right":_vm.icon,"size":_vm.size,"variation":_vm.variation},on:{"click":function($event){_vm.multiselect ? _vm.$emit('multiselect-click', $event) : _vm.toggleMenu()}}},[_vm._v(" "+_vm._s(_vm.multiselectTags ? '' : _vm.text)+" "),(_vm.multiselectTags)?_c('div',{staticClass:"multiselect-container"},[_vm._t("multiselect-tags"),(!_vm.disabled)?_c('icon',{staticClass:"adaptive-selector-dropdown__handle__caret",attrs:{"type":"chevron-down"}}):_vm._e()],2):_vm._e()]),(_vm.menuVisible)?_c('DropdownMenu',{attrs:{"origin":_vm.origin}},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }