<template>
  <div class="profile">
    <TeacherTopbar v-if="isTeacherTopbarVisible" />
    <transition name="fade">
      <div class="profile__body">
        <div
          :class="[
            'profile__body__char',
            {'--topbar-padding': isTeacherTopbarVisible}
          ]"
        >
          <img
            class="profile__body__char__image"
            src="@/assets/char/tata-profile.svg"
          >
        </div>
        <div
          :class="[
            'profile__body__lectures',
            {
              '--topbar-margin': isTeacherTopbarVisible,
              '--mfe-margin': isMFE,
            }
          ]"
        >
          <ProfileCardSkeleton
            v-if="userInfoLoading"
          />
          <ProfileCard
            v-else
            :game-icon="gameIcon"
            :user-game-info="userGameData.data"
            @edit-profile="toggleEditIconModalVisibility"
            @change-tab="setViewTab"
          />
          <component
            :is="tabComponent"
            :class="[
              { '--hidden-scrollbar': isTablet}
            ]"
          />
        </div>
      </div>
    </transition>
    <UserIconsList
      v-if="selectIconModalIsVisible"
      :current-icon-selected="gameIcon"
      @close="toggleEditIconModalVisibility"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userAgent from '@/utils/userAgent'

import { playBackground, pauseBackground } from '@/utils/soundEffects'
import gradeId from '@/mixins/gradeId'
import TeacherTopbar from '../../components/TeacherTopbar'
import PerformanceTab from './partials/tabs/PerformanceTab/PerformanceTab'
import InsigniasTab from './partials/tabs/InsigniasTab/InsigniasTab'
import AcademicRecordTab from './partials/tabs/AcademicRecordTab/AcademicRecordTab'
import ProfileCardSkeleton from './partials/ProfileCard/ProfileCardSkeleton'
import ProfileCard from './partials/ProfileCard/ProfileCard'
import UserIconsList from './partials/UserIconsList/UserIconsList'

export default {
  name: 'Profile',
  components: {
    ProfileCard,
    ProfileCardSkeleton,
    TeacherTopbar,
    UserIconsList,
    PerformanceTab,
    InsigniasTab,
    AcademicRecordTab,
  },
  mixins: [ gradeId ],
  data() {
    return {
      selectIconModalIsVisible: false,
      tabComponent: '',
    }
  },
  computed: {
    ...mapGetters([
      'userGameData',
      'user',
      'userGrades',
      'userPreferences',
    ]),
    isMFE() {
      return window.eureka_init
    },
    isTablet() {
      return userAgent.isTablet()
    },
    userCoins() {
      return this.userGameData?.data?.coin
        ? this.userGameData.data.coin?.quantity
        : 0
    },
    isTeacherTopbarVisible() {
      return !this.user?.data?.userIsStudent && this.userGrades?.data?.length
    },
    gameIcon() {
      return this.userPreferences.data?.gameIcon
    },
    userInfoLoading() {
      return this.userGameData?.loading || this.user?.loading
    },
  },
  created() {
    this.$trackEvent({
      category: this.$track.category.eureka,
      action: this.$track.action.enter,
      label: this.$track.label.profile,
    })
    pauseBackground()
    playBackground()
  },
  methods: {
    toggleEditIconModalVisibility() {
      this.selectIconModalIsVisible = !this.selectIconModalIsVisible
    },
    setViewTab(tab) {
      this.tabComponent = tab.component
    },
  },
}
</script>

<style lang="scss" scoped>
$profile-margin-top: 3.75rem;
$profile-card-height: 232px;

.profile {
  position: relative;
  min-height: 100vh;
  background: {
    image: url(~@/assets/profile/background.svg);
    position: bottom;
    size: cover;
    attachment: fixed;
  }

  &__body {
    height: 100vh;

    &__char {
      width: 50%;
      height: 100vh;
      padding-top: $size-xl;
      @include flex-center;

      @media screen and (max-width: 991px) {
        display: none;
      }

      &__image {
        margin-top: 20%;
      }
    }

    &__lectures {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 2;
      width: 50%;
      height: 100%;
      margin: $profile-margin-top 8% 0 16%;
      background-color: $eureka-color-base-dark;
      border-bottom: none;
      border-top-right-radius: $size-m;
      border-top-left-radius: $size-m;

      @media screen and (max-width: 991px) {
        width: 100%;
        margin: 0;
      }

      &.--topbar-margin {
        margin-top: $profile-margin-top;

        @media screen and (max-width: 991px) {
          margin-top: 49px;
        }
      }

      ::v-deep .performance-tab {
        height: calc(100vh - $profile-margin-top - $profile-card-height);

        &.--hidden-scrollbar {
          &::-webkit-scrollbar {
            width: 0;
          }
        }
      }
    }
  }

  .teacher-top-bar {
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .insignia-tab, .academic-record-tab {
    height: calc(100vh - $profile-margin-top - $profile-card-height);

     &.--hidden-scrollbar {
        &::-webkit-scrollbar {
          width: 0;
        }
      }
  }

  .profile-card {
    ::v-deep .magna-mission-tabs {
      position: absolute;
      bottom: 0;

      &__tab-item {
        &.--actived::after {
          border-radius: 8px 8px 0 0;
          height: 0.5rem;
        }
      }
    }
  }
}

.--mfe-margin {
  margin-top: $portal-cross-menu-height;
}
</style>
