<template>
  <div class="mission-error">
    <img
      class="mission-error__illustration"
      :src="require(`@/assets/error/mission/${illustration}.svg`)"
    >
    <h2 class="mission-error__message">
      {{ message }}
    </h2>
    <p class="mission-error__observation">
      {{ observation }}
    </p>
    <g-button
      class="mission-error__action"
      size="large"
      @click="action"
    >
      {{ actionLabel }}
    </g-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import GButton from '@/components/GButton'

export default {
  name: 'MissionError',
  components: {
    GButton,
  },
  computed: {
    ...mapGetters([
      'question',
    ]),
    illustration() {
      if (this.question.error.status === 404) {
        return 'illustration-404'
      }

      return 'illustration'
    },
    message() {
      if (this.question.error.status === 404) {
        return this.$t('game.error.mission.404.message')
      }

      return this.$t('game.error.mission.default.message')
    },
    observation() {
      if (this.question.error.status === 404) {
        return this.$t('game.error.mission.404.observation')
      }

      return this.$t('game.error.mission.default.observation')
    },
    actionLabel() {
      if (this.question.error.status === 404) {
        return this.$t('game.error.mission.404.action')
      }

      return this.$t('game.error.mission.default.action')
    },
  },
  methods: {
    action() {
      if (this.question.error.status === 404) {
        this.$router.push({
          name: 'assignments',
          query: { ...this.$route.query },
          params: { lectureId: this.$route.params?.lectureId },
        })
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-error
  +flex-column-center

  &__illustration
      margin-bottom: 16px

  &__message
    font-weight: bold
    font-size: 24px
    max-width: 480px
    text-align: center

  &__observation
    margin-top: 16px
    opacity: 0.75

  &__action
    margin-top: 32px
</style>
