<template>
  <div>
    <GModal
      :class="[
        'user-icons-list__frame',
        { '--error' : gameIcons.error }
      ]"
      :close-button-enabled="false"
      variation="secondary"
      @close="close"
    >
      <section class="user-icons-list__header">
        <h1 class="user-icons-list__header__title">
          Escolha um ícone de perfil
        </h1>
        <GButton
          icon-only
          class="close-button"
          size="medium"
          variation="secondary"
          asset="gray-x.svg"
          aria-label="Fechar"
          @click="close"
        />
      </section>
      <ErrorMessage
        v-if="gameIcons.error"
        image-name="refresh.svg"
        message="Não foi possível carregar a lista de ícones disponíveis"
        action="Recarregar"
        @click="getUserIcons"
      />
      <section
        v-else
        class="user-icons-list__body"
      >
        <template v-if="gameIcons.loading">
          <skeleton-loader
            v-for="index in 8"
            :key="index"
            width="136px"
            height="136px"
            border-radius="14px"
          />
        </template>
        <template v-else-if="gameIcons.data">
          <button
            v-for="icon in gameIcons.data"
            :key="icon.id"
            v-tooltip.top="getTooltipMessage(icon)"
            data-testid="user-icon"
            :class="[
              'user-icons-list__icon-btn',
              {'--selected': isSelectedIcon(icon.id)},
              {'--disabled': !icon.available}
            ]"
            @click="setSelectedIcon(icon)"
          >
            <img
              class="user-icons-list__icon-btn__image"
              :src="icon.image.png || icon.image.svg"
              :alt="icon.name"
            >
            <img
              v-if="isUserGameIcon(icon)"
              class="user-icons-list__icon-btn__check-badge"
              src="@/assets/check-badge.svg"
              alt=""
            >
          </button>
        </template>
      </section>
    </GModal>
    <GToast
      v-if="errorToastIsVisible"
      key="error-toast"
      text="Ocorreu um problema e não foi possível alterar o ícone, tente novamente"
      variation="error"
      @close="changeErrorToastVisibility"
    />
    <GToast
      v-if="successToastIsVisible"
      key="success-toast"
      :text="toastMessage"
      @close="changeSuccessToastVisibility"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import gradeId from '@/mixins/gradeId'
import userPreferencesApi from '@/service/userPreferences'
import GToast from '@/components/GToast'
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'
import ErrorMessage from '@/components/ErrorMessage'

export default {
  name: 'UserIconsList',
  components: {
    GModal,
    GButton,
    GToast,
    ErrorMessage,
  },
  mixins: [ gradeId ],
  props: {
    currentIconSelected: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedIcon: null,
      errorToastIsVisible: false,
      infoToastIsVisible: false,
      successToastIsVisible: false,
      gameIcons: {
        loading: false,
        error: null,
        data: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'userPreferences',
    ]),
    toastMessage() {
      const { name } = this.selectedIcon

      return `Sucesso! ícone alterado para ${name}`
    },
  },
  watch: {
    'userPreferences.error': {
      handler(value) {
        if (value) {
          this.changeErrorToastVisibility(true)
        }
      },
    },
  },
  mounted() {
    this.getUserIcons()
  },
  methods: {
    ...mapActions([
      'setUserGameIcon',
    ]),
    getTooltipMessage({ available, requirement }) {
      return !available ? requirement : ''
    },
    isUserGameIcon(icon) {
      return this.isSelectedIcon(icon.id) && icon.available
    },
    close() {
      this.$emit('close')
    },
    isSelectedIcon(id) {
      return this.selectedIcon?.id === id
    },
    async setSelectedIcon(icon) {
      if (!this.isSelectedIcon(icon.id)) {
        if (icon.available) {
          this.selectedIcon = icon
          await this.setUserGameIcon(this.selectedIcon)

          if (!this.userPreferences.error) {
            this.$trackEvent({
              category: this.$track.category.profile,
              action: this.$track.action.changeGameIcon,
            })

            this.changeSuccessToastVisibility(true)
          }
        } else {
          this.changeInfoToastVisibility(true)
        }
      }
    },
    changeSuccessToastVisibility(value) {
      this.errorToastIsVisible = false

      if (value !== undefined) this.successToastIsVisible = value
      else this.successToastIsVisible = !this.successToastIsVisible
    },
    changeErrorToastVisibility(value) {
      this.successToastIsVisible = false

      if (value !== undefined) this.errorToastIsVisible = value
      else this.errorToastIsVisible = !this.errorToastIsVisible
    },
    async getUserIcons() {
      const params = {}

      this.gameIcons.error = null
      this.gameIcons.loading = true

      if (this.gradeId) {
        params.gradeId = this.gradeId
      }

      try {
        const { data } = await userPreferencesApi.getGameIcons(params)

        this.gameIcons.data = data
        this.selectedIcon = data.find(({ id }) => id === this.currentIconSelected.id)
      } catch (error) {
        this.gameIcons.error = error
      } finally {
        this.gameIcons.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-icons-list {
  &__header {
    @include flex-center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $eureka-color-base;
    padding: $size-m;
    margin-bottom: $size-m;
    border-bottom: 1px solid #E4CCAA;

    &__title  {
      color: $eureka-color-ink;
      font: {
        size: $font_size_heading_4;
        family: Rowdies;
      }
    }

    &__subtitle {
      color: $eureka-color-ink;
      opacity: 0.7;
      font-size:$size-s;
    }

    &__close-button {
      position: absolute;
      right: -24px;
      top: 0;
      color: $eureka-color-ink;
      opacity: 0.5;
    }

    ::v-deep .g-button__image{
        margin-right: 0;
    }
  }

  &__body {
    padding: $size-m;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
    grid-gap: 12px;
  }

  &__icon-btn {
    position: relative;
    padding: 0;
    border: none;
    cursor: pointer;

    &.--disabled {
      cursor: default;
    }

    &__image {
      height:100%;
      width: 100%;
      object-fit: cover;
    }

    &__check-badge {
      position: absolute;
      top: -8px;
      right: -8px;
    }

    &.--selected {
     border: 6px solid $eureka-color-secondary;
     border-radius: 1.5rem;
    }
  }
}

.user-icons-list__frame {
  ::v-deep .g-frame {
    padding: 0;
    height: 460px;
    min-width: min-content;
    width: 628px;
    overflow-y: auto;

    &.--secondary {
      padding: 0;
    }
  }

  &.--error {
    ::v-deep .g-frame {
      width: 579px;
      height: auto;
    }
  }
}

::v-deep .g-toast {
  z-index: $zindex-modal;
}

::v-deep .error-message {
  &__description {
    width: 64%;
  }
}
</style>
