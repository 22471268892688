<template>
  <div
    ref="container"
    class="ancestral-arena-teacher"
  >
    <div class="ancestral-arena-teacher__header">
      <g-alert
        v-if="error"
        :message="error.message"
        :icon="error.icon"
        :is-closeable="error.isCloseable"
        @close="error = null"
      />
      <GHeaderGame
        :has-back-button="isMagnaMissionLobby"
        @toggle-options="toggleOptions"
        @go-back="showCloseMagnaMissionModal"
      >
        <template #center>
          <ArenaTimer
            v-if="!isMagnaMissionLobby
              && !isMagnaMissionReport
              && !isShowRanking
              && timerInfo.hasTimer"
            :status="timerInfo.timerStatus"
            :is-show="timerInfo.hasTimer"
            :timer="timerInfo.currentTime"
          />
        </template>
        <template #menu>
          <div
            v-show="isMagnaMissionReport"
            class="ancestral-arena-teacher__menu"
          >
            <!-- <MagnaMissionTeacherShareUrl /> -->
            <MagnaMissionPlayersCounter
              v-if="answersCounterIsVisible && isMagnaMissionRoomTeacherRoute"
              class="ancestral-arena-teacher__menu__items"
              :loading="magnaMission.loading"
              :logged-students="loggedStudentsCount"
              :count-students-who-responded="studentsResponses"
            />
            <GButton
              v-if="!isMagnaMissionLobby && magnaMissionOptions.length"
              id="ancestral-arena-teacher__layout__options-button"
              class="ancestral-arena-teacher__menu__items"
              variation="secondary"
              size="large"
              :disabled="magnaMission.loading"
              icon="more-horizontal"
              :icon-stroke="3"
              @click="toggleOptions"
            />
          </div>
        </template>
      </GHeaderGame>
      <MagnaMissionSatisfactionSurvey
        v-if="(canReplySurvey && isSmallBreakpoint) || isMagnaMissionReport"
        :style="{ justifyContent: isMagnaMissionReport && !isMobile ? 'flex-end' : 'flex-start' }"
        :is-open-automatically="canReplySurvey"
      />
      <MagnaMissionPlayersCounter
        v-if="answersCounterIsVisible && isMagnaMissionRoomTeacherRoute && isSmallBreakpoint"
        class="ancestral-arena-teacher__menu__items"
        :style="{ justifyContent: 'flex-start' }"
        :loading="magnaMission.loading"
        :logged-students="loggedStudentsCount"
        :count-students-who-responded="studentsResponses"
      />
      <h1
        v-if="isMagnaMissionLobby && isMobile"
        class="ancestral-arena-teacher__header__title"
      >
        {{ magnaMissionTitle }}
      </h1>
      <h2
        v-if="isMagnaMissionLobby"
        class="ancestral-arena-teacher__header__subtitle"
      >
        Acesse este desafio através de Eureka! pelo computador ou aplicativo
      </h2>
    </div>
    <router-view
      :timer-info="timerInfo"
      @error-ocurred="setError"
      @error-resolved="error = null"
      @update-time="setTimerInfo"
      @is-show-ranking="setShowRanking"
    />
    <div
      v-if="error"
      class="overlay-error"
    />
    <MagnaMissionOptionsModal
      :is-visible="isShowOptions"
      :options="magnaMissionOptions"
      @close="toggleOptions"
    />
    <DangerMagnaMissionModal
      v-if="isCloseModalVisible"
      title="Deseja fechar a missão ?"
      description="Se tiverem alunos esperando,
        eles vão precisar entrar novamente em outra missão para jogar."
      button-name="Fechar"
      @confirm="cancelMagnaMissionAndReturnToArena"
      @close="closeCloseMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import socketClient from '@/client/socketClient'
import { pauseBackground } from '@/utils/soundEffects'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import MagnaMissionSatisfactionSurvey from '@/components/MagnaMissionSatisfactionSurvey'
import MagnaMissionOptionsModal from '@/components/AncestralArena/MagnaMissionModal/MagnaMissionOptionsModal'
import GHeaderGame from '@/components/AncestralArena/GHeaderGame'
import mediaQueries from '@/mixins/mediaQueries'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import MagnaMissionPlayersCounter from '@/components/AncestralArena/MagnaMissionPlayersCounter'
import ArenaTimer from '@/components/AncestralArena/ArenaTimer/ArenaTimer'
import { truncateText } from '@sas-te/frontend-utils/modules/truncate'
// import MagnaMissionTeacherShareUrl from '@/components/AncestralArena/MagnaMissionTeacherShareUrl'

export default {
  name: 'AncestralArenaTeacherLayout',
  components: {
    GHeaderGame,
    GButton,
    GAlert,
    MagnaMissionSatisfactionSurvey,
    MagnaMissionOptionsModal,
    MagnaMissionPlayersCounter,
    ArenaTimer,
    // MagnaMissionTeacherShareUrl,
    DangerMagnaMissionModal,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      error: null,
      isShowOptions: false,
      isCloseModalVisible: false,
      studentsResponses: 0,
      timerInfo: {
        currentTime: '00:00',
        hasTimer: false,
        timerStatus: null,
      },
      isShowRanking: false,
    }
  },
  computed: {
    ...mapState({
      magnaMission: (state) => state.arena.magnaMission,
    }),
    isTeacherLobbyRoute() {
      return this.$route.name === 'lobby-teacher'
    },
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    isMagnaMissionRoomTeacherRoute() {
      return this.$route.name === 'magna-mission-room-teacher'
    },
    magnaMissionTitle() {
      const title = this.magnaMission?.data?.title
      if (this.isSmallBreakpoint) {
        if (this.isMagnaMissionLobby) {
          return truncateText(title, 20)
        }
        if (this.isMagnaMissionRoomTeacherRoute) {
          return truncateText(title, 15)
        }
      }

      return this.magnaMission.data?.title
    },
    canReplySurvey() {
      return !this.magnaMission.loading && this.isMagnaMissionReport
    },
    isMagnaMissionReport() {
      return this.$route.name === 'magna-mission-teacher-report'
    },
    isMagnaMissionLobby() {
      return this.$route.name === 'lobby-teacher'
    },
    magnaMissionOptions() {
      const routeName = this.$route.name
      const isLobbyView = routeName === 'lobby-teacher'

      return [
        {
          id: 'cancel',
          active: !this.isMagnaMissionReport,
          label: isLobbyView
            ? this.$t('ancestralArena.optionsModal.teacher.closeMission')
            : this.$t('ancestralArena.optionsModal.teacher.cancelMission'),
          buttonProps: {
            variation: 'secondary',
            size: 'large',
            asset: isLobbyView ? 'x-circle.svg' : 'stop-circle.svg',
          },
        },
      ].filter((option) => option.active)
    },
    currentQuestionCode() {
      return this.magnaMission.data?.currentQuestionCode || null
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    studentsResponsesCount() {
      const { data } = this.magnaMission

      return data ? data.currentQuestionAnswersCount : 0
    },
    answersCounterIsVisible() {
      return this.$route.name === 'magna-mission-room-teacher'
    },
    loggedStudentsCount() {
      const { data } = this.magnaMission

      return data?.loggedStudents?.length ?? 0
    },
  },
  watch: {
    studentsResponsesCount() {
      this.studentsResponses = this.studentsResponsesCount
    },
  },
  mounted() {
    pauseBackground()
  },
  methods: {
    ...mapActions([ 'setEnigmaPosition' ]),
    setError(error) {
      this.error = error
    },
    toggleOptions() {
      this.isShowOptions = !this.isShowOptions
    },
    setTimerInfo(timeInfo) {
      this.timerInfo = timeInfo
    },
    showCloseMagnaMissionModal() {
      this.isCloseModalVisible = true
    },
    closeCloseMagnaMissionModal() {
      this.isCloseModalVisible = false
    },
    cancelMagnaMission() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/cancel`
      socketClient.emit(destination)
    },
    cancelMagnaMissionAndReturnToArena() {
      this.cancelMagnaMission()
      this.trackEventOfCancel()
      setTimeout(() => socketClient.disconnect(), 2000)
      this.setEnigmaPosition(null)
      this.goToArena()
    },
    goToArena() {
      this.$router.replace({ name: 'arena' })
    },
    trackEventOfCancel() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.abortMagnaMission,
      })
    },
    setShowRanking(showRanking) {
      this.isShowRanking = showRanking
    },
  },
}
</script>
<style lang="scss" scoped>
.overlay-error {
  @include cover;
  z-index: 9998;
  background-color: #393939;
  mix-blend-mode: color;
}

.ancestral-arena-teacher {
  @include cover;

  z-index: 9998;
  overflow-y: auto;
  max-height: 100vh;
  background: {
    image: url('~@/assets/ancestral-arena/background.png');
    position: bottom;
    size: cover;
    attachment: fixed;
    color: $eureka-color-ink;
  }

  &__header {
    gap: 6px;
    display: flex;
    flex-direction: column;
    padding: $size-s $size-m;

    @media screen and (max-width: 579px) {
      padding: $size-s;
    }

    ::v-deep .g-header {
      padding: 0;
      min-height: fit-content;
    }

    &__title {
      display: inline-block;
      color: $color-white;
      font-family: Rowdies;
      font-size: $font-size-heading-3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__subtitle {
      font: {
        family: Lato;
        size: $font-size-heading-5;
        weight: $font-weight-medium
      }
      color: $color-white;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
    }

    &__controls {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 579px) {
      display: none;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: initial;
      margin-top: $size-m;
      margin-bottom: $size-m;
    }

    &__items {
      border-radius: $border-radius-xs;
      cursor: pointer;
      display: block;
      position: relative;
      color: $game-background-color !important;
      @include flex-center;
      @include transition($speed-x-fast);

      &:not(:last-child) {
        margin-right: $size-s;
      }

      & ::v-deep .g-button__icon i {
        transform: translateY(2px);
      }

      &:hover {
        background: rgba($color-black, 0.2);
      }

      &:active {
        background: rgba($color-black, 0.2);
      }

      &.magna-mission-players-counter {
        border-right: 1px solid rgba($color-white, 0.2);
        padding-right: $size-s;

        @media screen and (max-width: 768px) {
          border-right: none;
      }
      }
    }
  }
}
.g-alert {
  z-index: $zindex-modal;
  position: fixed;
  top: 0;
  border-radius: 0;
  justify-content: center;
  border-bottom: 6px solid #D97979;

  ::v-deep i {
    color: $color-danger-dark;
  }
}

::v-deep .g-button {
  &:disabled {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    color:  rgb(255, 255, 255, 0.5) !important;
    cursor: not-allowed;
  }
}
</style>
