<template>
  <div class="modal-container">
    <GFrame
      class="new-mission-modal"
      variation="secondary"
    >
      <header class="new-mission-modal__header">
        <h1 class="new-mission-modal__header__title">
          {{ title }}
        </h1>
        <GButton
          :icon-stroke="5"
          class="new-mission-modal__header__close-button"
          variation="secondary"
          :size="isSmallScreen ? 'medium' : 'large'"
          icon="x"
          @click="close"
        />
      </header>
      <main>
        <form @keydown.enter.prevent="submit">
          <label
            class="label"
            for="mission_name"
          >
            Nome da missão*
          </label>
          <SInput
            id="mission_name"
            v-model="mission.name"
            placeholder="Aqui você escreve um nome incrível para sua missão"
            width="100%"
          />
          <label
            class="label"
            for="mission_description"
          >
            Descrição
          </label>
          <textarea
            id="mission_description"
            v-model="mission.description"
            rows="10"
            placeholder="Aqui você pode detalhar sobre o que a missão vai abordar (opcional)"
            @keydown.enter.stop
          />
          <div class="new-mission-modal__footer">
            <GButton
              class="new-mission-modal__footer__cancel-button"
              variation="secondary"
              :size="isSmallScreen ? 'medium' : 'large'"
              @click.prevent="close"
            >
              Cancelar
            </GButton>
            <GButton
              type="submit"
              class="new-mission-modal__footer__submit-button"
              :size="isSmallScreen ? 'medium' : 'large'"
              :disabled="disableSubmit"
              @click.prevent="submit"
            >
              {{ buttonName }}
            </GButton>
          </div>
        </form>
      </main>
    </GFrame>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import GFrame from '@/components/GFrame'
import { SInput } from '@sas-te/alfabeto-vue'

export default {
  name: 'CreateNewMissionModal',
  components: {
    GFrame,
    GButton,
    SInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    missionName: {
      type: String,
      default: '',
    },
    missionDescription: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      required: true,
    },
    isSmallScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mission: {
        name: this.missionName,
        description: this.missionDescription,
      },
    }
  },
  computed: {
    disableSubmit() {
      return !this.mission.name
    },
  },
  created() {
    window.addEventListener('keydown', this.keyDownHandler)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler)
  },
  methods: {
    keyDownHandler(e) {
      if (e.key === 'Enter' && !this.disableSubmit) {
        this.submit()
      }
    },
    close() {
      this.$emit('close')
    },
    submit() {
      this.$emit('submit', this.mission)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-container {
  background-color: rgba(12, 11, 31, 0.75);
  z-index: $zindex_modal_backdrop;
  @include cover;
  @include flex-center;
}

.new-mission-modal {
  min-width: 625px;
  min-height: 482px;
  @include mq_s {
    min-width: auto;
    margin: 0 $size-xs;
  }
  &__header {
    @include flex-space-between;
    margin: $size-l 0;
    @include mq_s{
      margin:0
    }

    &__close-button {
      color: #454545;
      opacity: 0.5;
    }

    &__title {
      color: $eureka-color-ink;
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: 2rem;
      }
      @include mq_s{
        font-size: $font-size-heading-2-small;
      }
    }
  }

  &__footer {
    @include flex-space-between;
    margin-top: 40px;

    &__cancel-button {
      font-family: Rowdies;
      color: $eureka-color-ink;

      ::v-deep .g-button__text{
        font-weight: $font-weight-regular;
      }
    }

    &__submit-button {
      font-family: Rowdies;

      ::v-deep &.g-button {
        border: none;
      }

      ::v-deep .g-button__text{
        font-weight: $font-weight-regular;
      }

      &:disabled {
        border: none !important;
      }
    }
  }
}

.modal-container {
  width: 100%;
  height: 100%;
  @include flex-center;
}

#mission_description {
  display: block;
  width: 100%;
  font: {
    family: Lato;
    weight: $font-weight-medium;
    line-height: $line-height-text;
  }
  padding: 10px 12px;
  background: $eureka-color-base-lighter;
  border: 1px solid $eureka-color-base-lighter;
  border-radius: $border-radius-m;
  box-shadow: inset 0 2px 0 #C5C5C5;
  resize: none;

  &::placeholder {
     font-family: Lato;
     color: $color-ink-lighter;;
  }
}

#mission_name {
  ::v-deep &.sas-input__field {
    font: {
      family: Lato;
      weight: $font-weight-medium;
    }
    padding: 10px 12px;
    background: $eureka-color-base-lighter;
    box-shadow: inset 0 2px 0 #C5C5C5;

    &::placeholder {
      font-family: Lato;
    }
  }
}

.label {
  display: inline-block;
  color: $eureka-color-ink;
  margin: $size-xs 0;
  font: {
    family: Rowdies;
    size: $font-size-s;
  }
}
</style>
