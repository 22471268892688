<template>
  <component
    :is="tag"
    :disabled="disabled"
    :class="[
      'g-button',
      `--${size}`,
      `--${variation}`,
      {
        '--icon-only' : icon || iconOnly,
        '--active' : active,
        '--loading' : loading,
        '--no-border': noBorder,
        '--rowdies': rowdies,
        '--disabled' : disabled,
      }
    ]"
    :href="href"
    @click="$emit('click', $event)"
  >
    <transition name="fade">
      <spinner
        v-if="loading"
        custom-size="75%"
        class="g-button__spinner"
      />
    </transition>
    <div
      v-if="iconLeft || icon || iconRight"
      :class="[
        'g-button__icon',
        `--${size}`,
        { '--left' : iconLeft },
        { '--right' : iconRight },
      ]"
    >
      <icon
        :type="iconLeft || icon || iconRight"
        :stroke-width="getIconStroke()"
      />
    </div>
    <template v-if="!icon">
      <img
        v-if="asset"
        :class="[
          'g-button__image',
          `--${size}`,
        ]"
        :src="require(`@/assets/${asset}`)"
        alt=""
      >
      <b class="g-button__text">
        <slot />
      </b>
    </template>
  </component>
</template>

<script>
export default {
  name: 'GButton',
  status: 'ready',
  props: {
    variation: {
      type: String,
      default: 'primary',
      required: false,
      validator: (value) => (
        value.match(/(primary|secondary|success|danger|framed|tertiary)/)
      ),
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => value.match(/(small|medium|large)/),
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
      required: false,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
    iconOnly: Boolean,
    iconLeft: {
      type: String,
      default: null,
      required: false,
    },
    iconRight: {
      type: String,
      default: null,
      required: false,
    },
    iconStroke: {
      type: Number,
      default: null,
    },
    asset: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    rowdies: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      if (this.href) return 'a'

      return 'button'
    },
  },
  methods: {
    getIconStroke() {
      if (this.iconStroke) return this.iconStroke
      if (this.size === 'large') return 4

      return 2
    },
  },
}
</script>

<style lang="sass">
$button-size-l: 48px
$button-size-m: $element-size-m
$button-size-s: $element-size-s
$button-padding-l: $size-s
$button-padding-m: 12px
$button-padding-s: $size-xs
$button-icon-size-l: $size-m
$button-icon-size-m: 20px
$button-icon-size-s: $size-s

=button-style($color, $bg, $border, $shadow)
  background: $bg
  color: $color
  border: 5px solid $border
  transform: translateY(-3px)
  box-shadow: 0 3px 0 $shadow

  &:hover
    transform: translateY(-5px)
    box-shadow: 0 5px 0 $shadow

  &:active,
  &.--active
    transform: translateY(0px)
    box-shadow: 0 0 0 $shadow

.g-button
  +flex-center
  display: inline-flex
  position: relative
  appearance: none
  border: none
  cursor: pointer
  user-select: none
  +transition($speed-fast)

  &:focus
    outline: 0

  &:disabled
    background: #b9babe !important
    border: 5px solid #D1D2D4 !important
    box-shadow: none !important
    color: $color-white !important
    transform: none !important

  &.--loading
    justify-content: center
    pointer-events: none

  &.--large
    font-size: $font-size-m
    border-radius: $button-size-l / 2
    height: $button-size-l
    +space-inset(0 $button-padding-l)

  &.--medium
    border-radius: $button-size-m / 2
    height: $button-size-m
    font-size: $font-size-s
    +space-inset(0 $button-padding-m)

  &.--small
    border-radius: $button-size-s / 2
    height: $button-size-s
    font-size: $font-size-xs
    +space-inset(0 $button-padding-s)

  &__text
    +transition($speed-fast)
    line-height: $line-height-reset

    .--loading &
      opacity: 0

    .--large &
      font-size: $font-size-heading-5

  &__image
    &.--large
      width: 28px
      margin-right: $size-s

    &.--medium
      width: 20px
      margin-right: 12px

    &.--small
      width: 14px
      margin-right: $size-xs

  &__icon
    +transition($speed-fast)

    &.--large
      +element-icon-size($button-icon-size-l)

    &.--medium
      +element-icon-size($button-icon-size-m)
      stroke-width: 2.3

    &.--small
      +element-icon-size($button-icon-size-s)
      stroke-width: 2.5

    &.--right
      order: 1
      margin-left: $size-xs

      &.--large
        margin-right: -($button-padding-l / 2)

      &.--medium
        margin-right: -($button-padding-m / 2)

      &.--small
        margin-right: -($button-padding-s / 2)

    &.--left
      margin-right: $size-xs

      &.--large
        margin-left: -($button-padding-l / 2)

      &.--medium
        margin-left: -($button-padding-m / 2)

      &.--small
        margin-left: -($button-padding-s / 2)

  &.--icon-only
    justify-content: center
    +space-inset(0 !important)

    &.--large
      width: $button-size-l

    &.--medium
      width: $button-size-m

    &.--small
      width: $button-size-s

  &.--framed
    position: relative
    color: $color-white

    & > *
      z-index: 1

    &::before,
    &::after
      content: ''
      position: absolute
      +transition($speed-x-fast)

    &::before
      top: 0
      bottom: 0
      left: 0
      right: 0
      background: #FBF1E1
      box-shadow: 0 3px 0 #CBA976, inset 0 1px 0 rgba($color-white, 0.85)
      border-radius: 9999px

    &::after
      top: $size-xxs
      bottom: $size-xxs
      left: $size-xxs
      right: $size-xxs
      background: #4874E5
      box-shadow: inset 0 -3px 0 rgba($color-black, 0.25)
      border-radius: 9999px

    &:hover::after
      background: shade(#4874E5, 12%)

    &:active
      &::after
        background: shade(#4874E5, 24%)
        box-shadow: inset 0 3px 0 rgba($color-black, 0.25)

      & > *
        transform: translateY(3px)

  &.--rowdies
    font-family: Rowdies
    font-weight: $font-weight-regular
    text-shadow: 0px 1px 0px $color-black
    line-height: $line-height-heading

  &.--primary
    +button-style($color-white, #4874E5, #789BF6, #2D1FC8)

  &.--secondary
    background: transparent
    box-shadow: none
    color: $game-ink
    text-shadow: none

    &:hover
      background: rgba($color-black, 0.07)

    &:active, &.--active
      background: rgba($color-black, 0.135)

  &.--tertiary
    +button-style($color-white, #4874E5, #789BF6, #2D1FC8)

    &.--disabled
      background: $color-ink-light !important
      box-shadow: 0px 3px 0px #848484, inset 0px 1px 0px rgba($color-white, 0.75) !important
      border-radius: 32px !important
      cursor: not-allowed !important
      color:  rgb($color-white, 0.8) !important

  &.--success
    +button-style($color-white, #00BF9D, #10D1AE, #04806A)

  &.--success-inverted
    +button-style(#2D8978, $color-white, #E6F8F5, #4DA390)

  &.--danger
    +button-style($color-white, #f33d39, #ee6360, #be1510)

  &.--danger-inverted
    +button-style(#D92B26, $color-white, #F8DAD9, #CE4C49)

  &.--no-border
    border: none !important

  &__spinner
    position: absolute
</style>
