<template>
  <GFrame
    class="magna-mission-modal"
    variation="secondary"
  >
    <div class="magna-mission-modal__header">
      <div class="magna-mission-modal__header__text">
        <div class="magna-mission-modal__header-title">
          <img
            v-if="hasTitleIcon"
            v-tooltip.top="titleIconTooltip"
            alt=""
            :src="require(`@/assets/${titleIcon}`)"
            class="magna-mission-modal__title-icon"
          >
          <h2 class="magna-mission-modal__header__text__title">
            {{ title }}
          </h2>
        </div>
        <p
          v-if="subtitle"
          class="magna-mission-modal__header__text__subtitle"
        >
          {{ subtitle }}
        </p>
      </div>
      <GButton
        :icon-stroke="5"
        class="magna-mission-modal__header__text__button"
        variation="secondary"
        size="large"
        icon="x"
        data-testid="closeModal"
        @click="close"
      />
    </div>
    <div class="magna-mission-modal__content">
      <slot />
    </div>
    <div
      v-if="hasButtons"
      class="magna-mission-modal__footer"
    >
      <GButton
        v-if="hasCancelButton"
        class="magna-mission-modal__footer__cancel-button"
        variation="secondary"
        size="large"
        @click="close"
      >
        Cancelar
      </GButton>
      <slot name="footer-additional-message" />
      <GButton
        v-if="loading"
        class="magna-mission-modal__footer__submit-button"
        size="large"
        :loading="loading"
      >
        {{ buttonName }}
      </GButton>
      <GButton
        v-else
        class="magna-mission-modal__footer__submit-button"
        size="large"
        :asset="buttonIcon"
        :variation="buttonVariation"
        :disabled="disableSubmit"
        @click="submit"
      >
        {{ buttonName }}
      </GButton>
    </div>
  </GFrame>
</template>

<script>
import GButton from '@/components/GButton'
import GFrame from '@/components/GFrame'
import { isEmpty } from 'lodash-es'

export default {
  name: 'MagnaMissionModal',
  components: {
    GFrame,
    GButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
    buttonVariation: {
      type: String,
      default: 'primary',
      validator: (value) => (
        value.match(/(primary|secondary|success|danger|framed)/)
      ),
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    disableSubmit: Boolean,
    hasCancelButton: {
      type: Boolean,
      default: true,
    },
    hasButtons: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    hasTimerMessage: {
      type: Boolean,
      default: false,
    },
    titleIcon: {
      type: String,
      default: '',
    },
    titleIconTooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTitleIcon() {
      return !isEmpty(this.titleIcon)
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-modal {
  @media screen and (max-width: 568px) {
    margin-top: auto;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.g-frame {
    overflow-y: visible !important;
  }

  &__header {
    @include flex-space-between;
    border-bottom: 1px solid $eureka-color-base-darker;
    padding-bottom: $size-s;

    &__text {
      color: $eureka-color-ink;

      &__title {
        font-family: Rowdies;
        font-weight: $font-weight-regular;
        font-size: $font-size-heading-4;
        margin: auto;
      }

      &__subtitle {
        font-weight: $font-weight-medium;
      }

      &__button {
        color: #473F78;
        opacity: 0.5;

        ::v-deep .g-button__icon {
          margin-top: auto;
          margin-bottom: 6px;
        }
      }
    }
  }

  &__title-icon {
    width: $element-size-m;
    height: $element-size-m;
    margin: auto;
  }

  &__header-title {
    display: flex;
    gap: $size-s;
    flex-direction: row;
  }

  &__footer {
    @include flex-space-between;
    border-top: 1px solid $eureka-color-base-darker;
    padding: $size-m 0 $size-s;

    @media screen and (max-width: 579px) {
      flex-direction: column-reverse;

      padding: $size-s 0 $size-xs;
      gap: $size-xs;
    }

    &__cancel-button {
      background: transparent;
      font-family: Rowdies;
      color: $eureka-color-ink;

      ::v-deep .g-button__text{
        font-weight: $font-weight-regular;
      }
    }

    &__submit-button {
      font-family: Rowdies;

      ::v-deep &.g-button {
        border: none;
      }

      ::v-deep .g-button__text{
        font-weight: $font-weight-regular;
      }

      ::v-deep .g-button__image {
        width: 14px;
        margin-right: $size-xs;
      }

      &:disabled {
        border: none !important;
        padding: $size-s $size-m !important;
      }
    }
  }
}
</style>
