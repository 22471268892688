<template>
  <div class="create-magna-mission-layout">
    <template v-if="enableToCreate">
      <MagnaMissionDetailLayout
        :title="header.title"
        :subtitle="header.subtitle"
        go-back-route-name="arena"
        :is-save-disabled="isSASMission || saveMissionButtonIsDisabled"
        @edit="toggleNewMissionModalVisibility"
        @save-mission="saveMission"
      >
        <template
          v-if="!isMobile"
          #subHeaderInfo
        >
          <div
            class="header-info-stats"
          >
            <div>
              <TotalTime
                :list="newMissionQuestions"
                @change-has-time-available="setHasTime"
              />
            </div>
            <span class="mission-status">
              {{ missionStatus }}
            </span>
          </div>
        </template>
        <template #subHeader>
          <MagnaMissionTabs
            :tabs="tabs"
            variation="secondary"
            class="sub-header"
            @change-component="setViewTab"
          />
        </template>
        <template #headerAction>
          <span
            v-tooltip.bottom="tooltipMessage"
            class="show-desktop"
          >
            <div
              class="tour__save-mission"
              data-tour="new-magna-mission-save-mission"
            >
              <GButton
                size="large"
                class="save-btn"
                :disabled="isSASMission || saveMissionButtonIsDisabled"
                @click="saveMission"
              >
                Salvar missão
              </GButton>
            </div>
          </span>
        </template>
        <component
          :is="tabComponent"
          :magna-mission="magnaMission"
          :violated-rules="violatedRules"
          :has-total-time="hasTotalTimeAvailable"
          :is-save-disabled="isSASMission || saveMissionButtonIsDisabled"
          @save-mission="saveMission"
          @has-some-valid-question="toggleButtonVariation"
          @mission-questions-change="setNewMissionQuestions"
          @delete-question="deleteQuestion"
          @question-selector-visible="setIsQuestionSelectorVisibility"
        />
      </MagnaMissionDetailLayout>
      <DangerMagnaMissionModal
        v-if="isReturnModalVisible"
        title="Deseja voltar?"
        description="Essa missão possui alterações que não estão salvas.
        Se voltar para a listagem de missões agora, seu progresso será perdido."
        button-name="Sair sem salvar"
        @confirm="navigateToArena"
        @close="closeReturnMagnaMissionModal"
      />
      <InfoMagnaMissionModal
        v-if="magnaMission.error"
        title="Ocorreu um problema com o carregamento das questões"
        description="Não foi possível carregar as questões dessa missão.
        Tente fechar a página e voltar novamente."
        :button-name="$t('commons.close')"
        @confirm="navigateToArena"
        @close="navigateToArena"
      />
      <CreateNewMissionModal
        v-if="newMissionModalIsVisible"
        title="Editar Missão"
        button-name="Salvar Edição"
        :mission-name="header.title"
        :mission-description="header.subtitle"
        @submit="editHeaderTitleAndSubtitle"
        @close="toggleNewMissionModalVisibility"
      />
      <GToast
        v-if="toastIsVisible"
        key="success-toast"
        text="Missão salva com sucesso!"
        asset="arena-small.svg"
        @close="changeToastVisibility"
      />
      <GToast
        v-if="errorToastIsVisible"
        key="error-toast"
        text="Resolva os problemas sinalizados e tente salvar novamente"
        variation="error"
        asset="arena-small-error.svg"
        @close="changeErrorToastVisibility"
      />
      <TourNewMagnaMission :enabled="tourIsEnabled" />
    </template>
  </div>
</template>

<script>
import arenaApi from '@/service/arena'
import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import MagnaMissionDetailLayout from '@/views/AncestralArena/layouts/MagnaMissionDetailLayout'
import CreateMagnaMissionContentTab from '@/components/AncestralArena/CreateMagnaMissionContentTab/CreateMagnaMissionContentTab'
import CreateNewMissionModal from '@/views/AncestralArena/AncestralArenaList/partials/CreateNewMissionModal'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import TotalTime from '@/components/AncestralArena/TotalTime'
import GToast from '@/components/GToast'
import MagnaMissionTabs from '@/components/MagnaMissionTabs'
import GButton from '@/components/GButton'
import { omit, isEqual } from 'lodash-es'
import missionType from '@/views/AncestralArena/enums/missionType'
import handleResize from '@/mixins/handleResize'
import mediaQueries from '@/mixins/mediaQueries'
import TourNewMagnaMission from './TourNewMagnaMission'

const alternativesByIndex = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
}

const originEnum = {
  TEACHER: 'TEACHER',
  SAS: 'SAS',
}

export default {
  name: 'CreateMagnaMission',
  components: {
    MagnaMissionDetailLayout,
    CreateMagnaMissionContentTab,
    MagnaMissionTabs,
    GButton,
    DangerMagnaMissionModal,
    GToast,
    CreateNewMissionModal,
    InfoMagnaMissionModal,
    TourNewMagnaMission,
    TotalTime,
  },
  mixins: [ handleResize, mediaQueries ],
  beforeRouteLeave(to, from, next) {
    const isQuestionsSas = to.name === 'magna-mission-questions-search'
    if (
      this.goBackIsAllowed
      || this.isMissionSaved
      || isQuestionsSas
      || this.isSASMission
    ) {
      next()

      return
    }

    this.showReturnMagnaMissionModal()
  },
  props: {
    missionName: {
      type: String,
      default: '',
    },
    missionDescription: {
      type: String,
      default: '',
    },
    hasRequiredData: {
      type: Boolean,
      default: true,
    },
    questionnaireCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      header: {
        title: this.missionName,
        subtitle: this.missionDescription,
      },
      enableToCreate: false,
      magnaMission: {
        loading: false,
        data: null,
        error: null,
      },
      hasTotalTimeAvailable: false,
      newMissionModalIsVisible: false,
      isQuestionsSaved: false,
      isHeaderSaved: true,
      toastIsVisible: false,
      errorToastIsVisible: false,
      saveMissionButtonIsDisabled: true,
      tabComponent: '',
      tabs: [
        {
          id: 1,
          name: 'content',
          component: 'CreateMagnaMissionContentTab',
          text: 'Conteúdo da missão',
        },
      ],
      goBackIsAllowed: false,
      isReturnModalVisible: false,
      newMissionQuestions: [],
      newMission: {},
      violatedRules: [],
      isQuestionSelectorVisibility: false,
    }
  },
  computed: {
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    isCreateMissionPage() {
      return this.$route.name === 'magna-mission-create-edit'
    },
    percentageSasQuestions() {
      const questionsCount = this.newMissionQuestions?.length
      const sasQuestionsFilter = (question) => question?.origin === originEnum.SAS
      const sasQuestionsCount = this.newMissionQuestions?.filter(sasQuestionsFilter)?.length

      return ((sasQuestionsCount * 100) / questionsCount).toFixed(0)
    },
    isMissionSaved() {
      return this.isQuestionsSaved && this.isHeaderSaved
    },
    tooltipMessage() {
      return this.saveMissionButtonIsDisabled
        ? 'Resolva as questões em branco para salvar'
        : ''
    },
    missionStatus() {
      return this.isMissionSaved
        ? 'Missão pronta para jogar com a turma'
        : 'Missão possui alterações não salvas'
    },
    tourIsEnabled() {
      return (
        !this.isQuestionSelectorVisibility
        && !this.magnaMission.loading
        && !this.magnaMission.error
        && !this.magnaMission.data
      )
    },
    questionnaireType() {
      return this.magnaMission.data?.type ?? ''
    },
    isSASMission() {
      return this.questionnaireType === missionType.CHALLENGE_ACTIVITY
    },
    classroomId() {
      return this.magnaMission.data?.classroom?.id ?? null
    },
    classroom() {
      return this.magnaMission.data?.classroom ?? null
    },
  },
  watch: {
    isSASMission: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$router.replace({
            name: 'magna-mission-detail',
            params: {
              questionnaireCode: this.questionnaireCode,
            },
          })
        }
      },
    },
    questionnaireCode: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.getMission()
        }
      },
    },
    newMissionQuestions: {
      handler() {
        if (this.magnaMission.data) {
          const magnaMissionSaved = this.getMissionWithoutIdentifiersAndViolatedRules()

          this.setNewMission()
          this.isQuestionsSaved = isEqual(this.newMission, magnaMissionSaved)
        }
      },
    },
  },
  created() {
    // eslint-disable-next-line func-names
    window.onbeforeunload = function (event) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
    }

    if (this.hasRequiredData) {
      this.enableToCreate = true
    } else {
      this.navigateToArena({ hasCreateMissionError: true })
    }
  },
  beforeDestroy() {
    window.onbeforeunload = undefined
  },
  methods: {
    setIsQuestionSelectorVisibility(isVisible) {
      this.isQuestionSelectorVisibility = isVisible
    },
    setHasTime(hasTotalTime) {
      this.hasTotalTimeAvailable = hasTotalTime
    },
    deleteQuestion(index) {
      this.newMissionQuestions.splice(index, 1)
    },
    parseMagnaMission(data) {
      if (data) {
        const {
          code, questions, subtitle, title, type, classroom,
        } = data

        return {
          code,
          title,
          subtitle,
          type,
          classroom,
          questions: questions.map(
            ({
              id,
              wording,
              formattedWording,
              answerKey,
              options,
              timer,
              origin,
            }) => ({
              id,
              origin,
              wording,
              formattedWording,
              answerKey: parseInt(answerKey, 10),
              options: options.map(({ description }) => description),
              timer,
            })
          ),
        }
      }

      return data
    },
    getMissionWithoutIdentifiersAndViolatedRules() {
      let missionQuestion = null
      const { data } = this.magnaMission
      const mission = {
        ...data,
        questions: data.questions.map((question) => {
          missionQuestion = question
          delete missionQuestion.violatedRules

          if (question.origin === originEnum.SAS) {
            return omit(missionQuestion, [
              'wording',
              'formattedWording',
              'answerKey',
              'options',
            ])
          }

          return missionQuestion
        }),
      }

      delete mission.code
      delete mission.violatedRules

      mission.classroom = this.classroom
      mission.type = this.questionnaireType

      return mission
    },
    changeToastVisibility(value) {
      this.errorToastIsVisible = false

      if (value !== undefined) this.toastIsVisible = value
      else this.toastIsVisible = !this.toastIsVisible
    },
    changeErrorToastVisibility(value) {
      this.toastIsVisible = false

      if (value !== undefined) this.errorToastIsVisible = value
      else this.errorToastIsVisible = !this.errorToastIsVisible
    },
    toggleNewMissionModalVisibility() {
      this.newMissionModalIsVisible = !this.newMissionModalIsVisible
    },
    editHeaderTitleAndSubtitle({ name, description }) {
      this.header.title = name
      this.header.subtitle = description
      this.isHeaderSaved = this.magnaMission.data?.title === name
        && this.magnaMission.data?.subtitle === description
      this.toggleNewMissionModalVisibility()
    },
    navigateToArena(params) {
      this.goBackIsAllowed = true
      this.$router.replace({ name: 'arena', params })
    },
    setViewTab(tab) {
      this.tabComponent = tab.component
    },
    showReturnMagnaMissionModal() {
      this.isReturnModalVisible = true
    },
    closeReturnMagnaMissionModal() {
      this.isReturnModalVisible = false
    },
    toggleButtonVariation(value) {
      this.saveMissionButtonIsDisabled = !value
    },
    setNewMissionQuestions(value) {
      this.newMissionQuestions = [ ...value ]
    },
    getFormattedMissionQuestions() {
      return [ ...this.newMissionQuestions ].map((question) => {
        if (question.origin === originEnum.SAS) {
          return {
            id: question.id,
            timer: question.hasTimer ? question.timer : null,
            origin: question.origin,
          }
        }

        const missionOptions = Object.values(question.alternatives)
          .map(({ value }) => value)
          .filter((value) => value.length > 0)
        const alternativeIndex = alternativesByIndex[question.selectedAlternative]

        return {
          id: question.id,
          wording: question.text,
          formattedWording: question.formattedText,
          answerKey: alternativeIndex,
          options: missionOptions,
          timer: question.hasTimer ? question.timer : null,
          origin: question.origin ?? originEnum.TEACHER,
        }
      })
    },
    setNewMission() {
      this.newMission = {
        title: this.header.title,
        subtitle: this.header.subtitle,
        type: this.questionnaireType,
        classroom: this.classroom,
        questions: this.getFormattedMissionQuestions(),
      }
    },
    async getMission() {
      this.magnaMission.error = null
      this.magnaMission.loading = true

      try {
        const response = await arenaApi.getMagnaMissionByCode(
          this.questionnaireCode
        )
        this.magnaMission.data = this.parseMagnaMission(response.data)
        this.header.title = this.magnaMission.data.title
        this.header.subtitle = this.magnaMission.data.subtitle
        this.magnaMission.loading = false
      } catch (error) {
        this.magnaMission.error = error
      }
    },
    async saveMission() {
      const { magnaMission, isQuestionsSaved, newMissionQuestions } = this
      const { ancestralArena } = this.$track.category
      const { savedChanges, saveEditions, teacherCreateMission } = this.$track.action
      const {
        hybridQuestions,
        sasQuestions,
        teacherQuestions,
        percentageSasQuestions,
      } = this.$track.label
      const questionnaireCode = magnaMission.data?.code || this.questionnaireCode

      this.setNewMission()
      this.violatedRules = []

      this.$trackEvent({
        category: ancestralArena,
        action: saveEditions,
      })

      try {
        if (magnaMission.data) {
          const body = {
            ...this.newMission,
            classroomId: this.classroomId,
            questionnaireType: this.questionnaireType,
            pristineQuestions: isQuestionsSaved,
          }

          await arenaApi.updateMagnaMission(questionnaireCode, body)
        } else {
          const { data } = await arenaApi.createMagnaMission(this.newMission)
          this.magnaMission.data = this.parseMagnaMission(data)
        }

        this.$trackEvent({
          category: ancestralArena,
          action: teacherCreateMission,
          label: percentageSasQuestions,
          value: this.percentageSasQuestions,
        })

        const hasSASQuestion = newMissionQuestions?.some(
          (question) => question.origin === originEnum.SAS
        )

        const hasTeacherQuestion = newMissionQuestions?.some(
          (question) => question.origin === originEnum.TEACHER
        )

        if (hasSASQuestion && hasTeacherQuestion) {
          this.$trackEvent({
            category: ancestralArena,
            action: savedChanges,
            label: hybridQuestions,
          })
        }
        if (hasSASQuestion && !hasTeacherQuestion) {
          this.$trackEvent({
            category: ancestralArena,
            action: savedChanges,
            label: sasQuestions,
          })
        }

        if (!hasSASQuestion && hasTeacherQuestion) {
          this.$trackEvent({
            category: ancestralArena,
            action: savedChanges,
            label: teacherQuestions,
          })
        }

        this.changeToastVisibility(true)
        this.isQuestionsSaved = true
        this.isHeaderSaved = true
      } catch ({ response: { data } }) {
        this.changeErrorToastVisibility(true)
        const { message } = data

        this.violatedRules = message.questions
          ?.map(({ violatedRules }, index) => ({
            order: index + 1,
            violatedRules,
          }))
          ?.filter(({ violatedRules }) => isNotEmpty(violatedRules)) ?? []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.show-mobile {
  display: none;

  @media screen and (max-width: 579px) {
    display: block;
    position: absolute;
    right: 18px;
    margin-top: 12px;
    z-index: 100;
  }
}

.header-info-stats {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.create-magna-mission-layout {
  ::v-deep .magna-mission-detail-layout__header {
    &.sub-header {
      position: relative;
      display: none !important;
    }

    &__line {
      display: none;
    }
  }
}

.header-info {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include mq_s {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    gap: $size-xs;

    :nth-child(n) {
      font-size: $font-size-s;
    }

    ::v-deep .total-time {
      background-size: 20px;
    }
  }
}

.status-text {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font: {
    family: Lato;
    weight: $font-weight-medium;
  }
  line-height: $line-height-text;
  letter-spacing: 0.0275rem;
  color: $eureka-color-ink;
}

.mission-status {
  font-family: Lato;
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  color: $eureka-color-ink;
}

::v-deep .danger-magna-mission-modal,
.info-magna-mission-modal,
.modal-container {
  z-index: 9998;
}

::v-deep .g-toast {
  z-index: 9998;
}

.save-btn.g-button {
  white-space: nowrap;
  border: none !important;
  ::v-deep .g-button__text {
    font: {
      family: Rowdies;
      weight: $font-weight-regular !important;
    }
  }

  &:disabled {
    background: #666e75;
    border: 5px solid #666e75;
    box-shadow: 0 3px 0 #848484;
  }
}

.tour {
  &__save-mission {
    @include mq_s {
      text-align: end;
    }
    &.tour-active {
      padding: $size-xxs;
    }
  }
}

.show-desktop {
  width: fit-content;
}
</style>
