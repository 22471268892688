<template>
  <div class="class-picker-skeleton">
    <skeleton-loader
      class="class-picker-skeleton__icon"
      width="24px"
      height="24px"
    />
    <div class="class-picker__text">
      <skeleton-loader
        class="class-picker-skeleton__class-name"
        width="50%"
        height="22px"
      />
      <skeleton-loader
        width="140px"
        height="19px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassPickerSkeleton',
}
</script>

<style lang="sass" scoped>
.class-picker-skeleton
  background-color: $color-white
  border: 1px solid #C9CCCF
  box-shadow: $shadow-s rgba($color-ink, .25)
  border-radius: 10px
  padding: #{$size-m / 2} $size-s
  width: 100%
  +flex-center-start

  +mq-l--mf
    width: 240px

  &__icon
    margin-right: $size-s

  &__class-name
    margin-bottom: $size-xxs
</style>
