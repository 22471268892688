<template>
  <div
    :style="{ backgroundImage: `url(${backgroundChest})` }"
    class="chest-modal-content"
  >
    <section class="chest-modal-content__label">
      <strong>Você ganhou</strong>
    </section>
    <section
      :style="{ gridTemplateAreas: gridType }"
      class="chest-modal-content__items"
    >
      <div
        v-if="hasItem"
        class="item-area"
      >
        <div
          v-for="(reward, index) in itemsReward"
          :key="index"
        >
          <img
            width="64"
            :src="reward.item.image.svg"
          >
          <span class="badge">{{ `+${reward.quantity}` }}</span>
        </div>
      </div>
      <div class="coins-area">
        <g-block
          asset="coin.svg"
          theme="bright"
          size="large"
        >
          {{ `+ ${coins}` }}
        </g-block>
      </div>
      <div class="diamond-area">
        <g-block
          asset="diamond.svg"
          theme="bright"
          size="large"
        >
          + 1
        </g-block>
      </div>
    </section>
    <div class="chest-modal-content__chest">
      <img
        width="120"
        :src="chestType"
      >
    </div>
    <g-button
      class="chest-modal-content__close"
      size="large"
      @click="$emit('close')"
    >
      {{ $t('game.reward.ok') }}
    </g-button>
  </div>
</template>

<script>
import GButton from '@/components/GButton'
import GBlock from '@/components/GBlock'

export default {
  name: 'ChestModalContent',
  components: {
    GButton,
    GBlock,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isFirstChest: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    coins() {
      if (this.items.length) {
        const [ coins ] = this.items.filter((item) => item.type === 'coin')

        return coins.value
      }

      return 0
    },
    itemsReward() {
      if (this.hasItem) {
        const [ itemReward ] = this.items.filter(({ type }) => type === 'items')
        const { items } = itemReward

        return items
      }

      return []
    },
    gridType() {
      if (this.itemsReward.length) {
        return '"item item" "coins diamond"'
      }

      return '"coins diamond"'
    },
    hasItem() {
      return Boolean(this.items.find(({ type }) => type === 'items'))
    },
    chestType() {
      return this.transformImage(`./chest-open-${this.isFirstChest ? 'gold' : 'silver'}.svg`)
    },
    backgroundChest() {
      return this.transformImage(`./reward-bg-${this.isFirstChest ? 'gold' : 'silver'}.svg`)
    },
  },
  methods: {
    transformImage(imageType) {
      const image = require.context('@/assets/reward', false, /\.svg$/)

      return image(imageType)
    },
  },
}
</script>

<style lang="sass" scoped>
.badge
  position: absolute
  top: 45px
  left: 45px
  padding: 4px
  width: 24px
  height: 24px
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.3)
  background: $color-white
  border-radius: 40px
  color: $eureka-color-ink
  font-size: 12px
  align-items: center
  text-align: center
  font-weight: 700

.diamond-area
  grid-area: diamond

.coins-area
  grid-area: coins

.item-area
  grid-area: item
  text-align: -webkit-center
  position: absolute
  left: 40%
  top: 16%

.chest-modal-content
  background-repeat: no-repeat
  background-position: center 104px
  padding: 34px 0 12px
  position: relative
  width: 320px
  +flex-column-center

  &__label
    font-size: 14px
    font-weight: 500
    color: transparentize($game-ink, 0.3)
    margin-bottom: 12px

  &__items
    display: grid
    grid-template-rows: 55px
    gap: 8px
    margin-top: 24px

  &__chest
    margin: 25px 0
    position: relative
    +flex-center

    &::before
      +pseudo
      background-color: rgba(241, 189, 141, 0.3)
      height: 26px
      width: 160px
      bottom: 5px
      border-radius: 50%
      z-index: 1

    img
      display: block
      height: 150px
      z-index: 2

  &__close
    padding: 20px 0
    width: 154px
</style>
