<template>
  <div
    class="worlds-list-carousel"
  >
    <template v-if="lectures.success">
      <div
        class="worlds-list-carousel__wrapper"
      >
        <div
          class="worlds-list-carousel__content"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
        >
          <div
            v-for="(world, index) in worlds"
            :key="index"
            class="worlds-list-item-wrapper"
            :style="worldStyle(index)"
          >
            <WorldsListItem
              data-testid="world"
              :world="world"
              :active="index === activeWorld"
              :show-title="false"
              @click="goToWorld($event, index)"
            />
          </div>
        </div>
        <div class="worlds-list-carousel__controls">
          <button
            class="worlds-list-carousel__controls__button"
            data-testid="previous"
            @click="prev()"
          >
            <icon
              size="48"
              stroke-width="4"
              type="chevron-left"
              :stroke="arrowColor"
            />
          </button>
          <WorldTitle
            :title="activeWorldInfo.name"
            :framed="false"
            :gems="activeWorldInfo"
            :badge-count="activeWorldInfo.pendencies"
          />
          <button
            class="worlds-list-carousel__controls__button"
            data-testid="next"
            @click="next()"
          >
            <icon
              size="48"
              stroke-width="4"
              type="chevron-right"
              :stroke="arrowColor"
            />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gsap from 'gsap'
import { mapActions, mapGetters } from 'vuex'

import { themedLectures } from '@/themes'
import { playSoundFX } from '@/utils/soundEffects'
import { effectsNames } from '@/service/soundController/loadSounds'
import WorldTitle from '@/views/Missions/partials/WorldTitle'
import WorldsListItem from './WorldsListItem'

const TOUCH_SLIDER_MIN_RANGE = 30

export default {
  name: 'WorldsListCarousel',
  components: {
    WorldsListItem,
    WorldTitle,
  },
  data() {
    return {
      activeWorld: 0,
      horizontalSpacing: 96,
      verticalSpacing: 24,
      scaleFactor: 0.37,
      opacityFactor: 0.4,
      activeWorldScale: 1.2,
      blurFactor: 3,
      touchStartPos: 0,
      touchCurrPos: 0,
      onTouch: false,
    }
  },
  computed: {
    ...mapGetters([
      'lectures',
      'theme',
    ]),
    arrowColor() {
      return '#000'
    },
    lectureId() {
      return parseInt(this.$route.query.lectureId, 10)
    },
    worlds() {
      return this.lectures.data.filter((lecture) => (
        themedLectures.includes(lecture.id)
      ))
    },
    activeWorldInfo() {
      return this.worlds.find((world, index) => index === this.activeWorld)
    },
  },
  async created() {
    if (this.lectures.success) {
      if (this.lectureId) {
        const worldIndex = this.worlds.findIndex((world) => world.id === this.lectureId)
        this.setActiveWorld(worldIndex)
      } else {
        this.setActiveWorld(0)
      }
    }
  },
  mounted() {
    gsap.from('.worlds-list-item', {
      scale: 0,
      duration: 0.8,
      stagger: 0.02,
      ease: 'power2',
    })
    gsap.from('.worlds-list-carousel__controls', {
      y: 32,
      opacity: 0,
      duration: 0.7,
      delay: 0.5,
      ease: 'power2',
    })
  },
  methods: {
    ...mapActions([
      'setLecture',
    ]),
    onTouchMove(evt) {
      if (!this.onTouch) {
        this.touchStartPos = evt.targetTouches[0].clientX
        this.onTouch = true

        return
      }

      this.touchCurrPos = evt.targetTouches[0].clientX
    },
    onTouchEnd() {
      if (!this.onTouch) return false

      this.onTouch = false

      if (this.touchStartPos > this.touchCurrPos + TOUCH_SLIDER_MIN_RANGE) return this.next()
      if (this.touchStartPos < this.touchCurrPos - TOUCH_SLIDER_MIN_RANGE) return this.prev()

      return false
    },
    worldStyle(index) {
      const desloc = ((index - this.activeWorld) * 2 * Math.PI) / this.worlds.length
      const isActiveWorld = index === this.activeWorld
      const sin = Math.sin(desloc)
      const cos = Math.cos(desloc)
      const x = this.horizontalSpacing * sin
      const y = this.verticalSpacing * cos
      const z = 10 * cos
      const scale = isActiveWorld ? this.activeWorldScale : (1 - this.scaleFactor * (1 - cos))
      const blur = this.blurFactor * (1 - cos)

      return {
        transform: `translate(${x}%, ${y}%) scale(${scale})`,
        zIndex: Math.round(z),
        filter: `blur(${blur}px)`,
      }
    },
    prev() {
      this.throttle(500, () => {
        playSoundFX(effectsNames.SWIPE_SOUND)
        this.setActiveWorld(
          this.activeWorld === 0
            ? this.worlds.length - 1
            : (this.activeWorld - 1) % this.worlds.length
        )
      })
    },
    next() {
      this.throttle(500, () => {
        playSoundFX(effectsNames.SWIPE_SOUND)
        this.setActiveWorld((this.activeWorld + 1) % this.worlds.length)
      })
    },

    setActiveWorld(index) {
      const world = this.worlds[index]
      if (parseInt(this.$route.query.lectureId, 10) !== world.id) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            lectureId: world.id,
          },
          params: {
            hasInitialSplashScreen: false,
          },
        })
      }
      this.activeWorld = index
      this.$emit('active-world', world)
    },

    throttle(timeout, fn) {
      if (!window.timeoutId) {
        fn()
        window.timeoutId = setTimeout(() => {
          window.timeoutId = undefined
        }, timeout)
      }
    },

    goToWorld(lecture, index) {
      playSoundFX()
      this.setLecture(lecture)

      if (index !== this.activeWorld) {
        this.setActiveWorld(index)
      } else {
        this.$emit('go-to-world', lecture)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.worlds-list-carousel {
  width: 100%;
  height: 100%;

  &__wrapper {
    position: relative;
    width: min(60%, 40rem);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: calc(40.25vh + 11.5vw);
    display: flex;
    justify-content: center;
  }

  &__controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      padding: 1%;
      @include flex-center;
      background: rgba($color-black, 0);
      opacity: 0.6;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      @include transition($speed-x-fast);

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px $color-primary-light;
      }

      &:hover {
        opacity: 0.7;
        background: rgba($color-black, .15);
        transform: scale(1.1);
        @include transition($speed-x-fast);
      }

      &:active {
        background: rgba($color-black, .2);
        opacity: 0.8;
        transform: scale(0.9);
        @include transition($speed-x-fast);
      }
    }
  }
}

.worlds-list-item-wrapper {
  position: absolute;
  transform-origin: center;
  @include transition($speed-slow);

  &:hover {
    filter: blur(0) !important;
  }
}
</style>
