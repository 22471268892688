<template>
  <MagnaMissionReportLayout
    :is-loading="report.loading"
    :footer-button-text="closeButtonText"
    :footer-button-size="closeButtonSize"
    :button-image-name="buttonImage"
    @click="handleAction"
  >
    <div class="magna-student-report">
      <section
        v-if="report.error"
        class="magna-student-report__message"
      >
        <h2 class="magna-student-report__message__title">
          Não foi possível carregar o relatório
        </h2>
        <p class="magna-student-report__message__description">
          Tente recarregar essa página ou acesse o relatório
          pela listagem de missões.
        </p>
      </section>
      <div
        v-else
        class="magna-student-report__content"
      >
        <HitRate
          :title="'Seus acertos'"
          :hit-rate="Math.round(report.data.hitPercentage)"
          :correct="report.data.correctAnswers"
          :total="totalQuestions"
          v-bind="dimension"
        />
      </div>
      <img
        class="magna-student-report__banner"
        :src="tataImage"
        alt=""
      >
    </div>
  </MagnaMissionReportLayout>
</template>

<script>
import { mapActions } from 'vuex'

import tataThrilledRight from '@/assets/char/tata-thrilled.svg'
import tataThinkingRight from '@/assets/char/tata-thinking.svg'
import tataThrilledLeft from '@/assets/char/tata-thrilled-left.svg'
import tataThinkingLeft from '@/assets/char/tata-thinking-left.svg'
import mediaQueries from '@/mixins/mediaQueries'
import socketClient from '@/client/socketClient'
import arenaApi from '@/service/arena'
import MagnaMissionReportLayout from '@/views/AncestralArena/layouts/MagnaMissionReportLayout'
import HitRate from '@/components/AncestralArena/HitRate'

export default {
  name: 'MagnaMissionStudentReport',
  components: {
    HitRate,
    MagnaMissionReportLayout,
  },
  mixins: [ mediaQueries ],
  data() {
    return {
      report: {
        loading: null,
        error: null,
        data: {},
      },
    }
  },
  computed: {
    dimension() {
      return this.mq_xl__mf ? {}
        : {
          size: 160,
          stroke: 18,
        }
    },
    buttonImage() {
      return this.report.error ? 'refresh.svg' : ''
    },
    closeButtonText() {
      if (this.report.error) return 'Recarregar'

      return this.mq_xl__mf ? 'Fechar Missão' : 'Fechar'
    },
    closeButtonSize() {
      return this.mq_xl__mf ? 'large' : 'medium'
    },
    tataImage() {
      if (this.report.error || this.report.data.correctAnswers <= 0) {
        return this.mq_xl__mf ? tataThinkingRight : tataThinkingLeft
      }

      return this.mq_xl__mf ? tataThrilledRight : tataThrilledLeft
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    totalQuestions() {
      const { correctAnswers, wrongAnswers } = this.report.data

      return correctAnswers + wrongAnswers
    },
  },
  created() {
    this.fetchReport()
  },
  beforeDestroy() {
    socketClient.disconnect()
  },
  methods: {
    ...mapActions([ 'setMagnaMission' ]),
    handleAction() {
      if (this.report.error) {
        this.fetchReport()
      } else {
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.studentFinishMagnaMission,
        })

        this.$router.replace({ name: 'arena' })
      }
    },
    async fetchReport() {
      this.report.error = null
      this.report.loading = true
      this.setMagnaMission({ loading: true, error: null })

      try {
        const { data } = await arenaApi.getReport(this.questionnaireCode)
        this.report.data = data
        this.setMagnaMission({
          data: {
            title: this.report.data.title,
            hit: this.report.data.correctAnswers,
          },
          loading: false,
        })
      } catch (error) {
        this.report.error = error
        this.setMagnaMission({ error, loading: false })
      } finally {
        this.report.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-student-report {

  &__banner {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  &__message {
    width: 40%;
    margin-left: 40px;

    &__title {
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: 2.5rem;
      }
      line-height: $line-height-heading;
      color: $eureka-color-ink;
    }

    &__description {
      margin-top: $size-m;
      font: {
        family: Lato;
        size: 1.25rem;
        weight: $font-weight-medium;
      }
      line-height: $line-height-text;
      color: $eureka-color-ink;
      letter-spacing: 0.0275rem;
    }
  }

  @media screen and (max-width: 991px) {

    .magna-student-report__banner {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      height: 45%;
    }

    .magna-student-report__message {
      width: 80%;
      margin: 0 auto;
      text-align: center;

      &__title {
        margin-top: 72px;
        font-size: $font-size-heading-4;
        line-height: $line-height-reset;
      }

      &__description {
        margin-top: $size-s;
        font-size: $font-size-m;
      }
    }

    ::v-deep .hit-rate {

      &__body {
        flex-direction: column-reverse;
        padding: 2rem 1.5rem;

        .radial-progress-container {
          margin-top: 1rem;
        }
      }

      &__chart {
        margin-right: 0;
      }

      &__text {
        text-align: center;

        &__title {
          font: {
            size: 1.5rem;
            weight: $font-weight-regular;
          }
        }

        &__count {
          font-size: 1rem;
          line-height: 1.6;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {

  .magna-report {
   height: 80vh;
  }

  ::v-deep .g-frame__inner {
    display: block !important;
  }

  ::v-deep .magna-report__content {
    max-width: 480px;

     &__header {
       margin-top: 2rem;
       text-align: center;

       &__title {
         margin: 0;
       }
     }

     &__footer {
       position: absolute;
       bottom: 0;
       right: 0;

       .g-button:last-child {
         min-width: 150px;
         margin: 0 1rem 1rem 0;
       }

       .g-button__text {
          font: {
            family: Rowdies;
            size: 1rem;
            weight: $font-weight-regular;
          }
       }
     }
  }
}
</style>
