<template>
  <div class="info-magna-mission-modal">
    <MagnaMissionModal
      :class="[{'space-between' : hasCancelButton}]"
      :title="title"
      :button-name="buttonName"
      button-variation="primary"
      :has-cancel-button="hasCancelButton"
      :has-buttons="hasButtons"
      @submit="$emit('confirm')"
      @close="$emit('close')"
    >
      <div class="info-magna-mission-modal__content">
        <div class="info-magna-mission-modal__content__description">
          {{ description }}
        </div>
        <img
          v-if="asset"
          class="info-magna-mission-modal__content__image"
          :src="require(`@/assets/${asset}`)"
        >
      </div>
    </MagnaMissionModal>
  </div>
</template>
<script>
import MagnaMissionModal from './MagnaMissionModal'

export default {
  name: 'InfoMagnaMissionModal',
  components: {
    MagnaMissionModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    asset: {
      type: String,
      default: '',
    },
    hasCancelButton: Boolean,
    hasButtons: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.info-magna-mission-modal {
  background-color: rgba(#0c0b1f, 0.75);
  z-index: $zindex-modal-backdrop;
  @include cover;
  @include flex-center;

  &__content {
    display: flex;

    &__description {
      font: {
        family: Lato;
        size: $size-s;
        weight: $font-weight-medium;
      }
      letter-spacing: 0.0275em;
      color: $eureka-color-ink;
      margin:  $size-m 0;
    }

    &__image {
      max-height: 128px;
      max-width: 100%;
    }
  }

  ::v-deep .magna-mission-modal {
    max-width: 560px;

    &__header__text__title {
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: $size-l;
      }
    }

    &__footer {
      border-top: none;
      justify-content: center;

      &__cancel-button {
        padding: $size-xs;
      }

      &__submit-button {
        padding: 12px $size-m;
      }
    }
  }
}

.space-between {
  ::v-deep .magna-mission-modal {
    &__footer {
      justify-content: space-between;
    }
  }
}

</style>
