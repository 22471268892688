<template>
  <div class="magna-misson-detail">
    <header class="magna-misson-detail__header">
      <div class="magna-misson-detail__header__main">
        <GButton
          class="magna-misson-detail__header__main__back-button"
          icon="arrow-left"
          size="large"
          variation="secondary"
          @click="goBack"
        />
        <div>
          <h1
            class="magna-misson-detail__header__main__title"
            :style="{ maxWidth: windowWidth - (isSmallScreen ? 185 : 275) + 'px' }"
            :title="truncatedTitle"
          >
            {{ truncatedTitle }}
          </h1>
          <h4 class="magna-misson-detail__header__main__subtitle">
            {{ truncatedSubTitle }}
          </h4>
          <TotalTime
            :list="missionQuestions"
            @total-time-available="setTimer"
          />
          <p
            v-if="isActiveMission"
            class="magna-misson-detail__header-sub-info"
            title="Número de questões da missão"
          >
            <icon
              type="users"
              class="magna-misson-detail__header-sub-info-icon"
              alt=""
            />
            {{ studentsInfo }}
          </p>
        </div>
      </div>
      <hr
        v-show="!isActiveMission"
        class="magna-misson-detail__header__line"
      >
      <MagnaMissionTabs
        v-show="!isActiveMission"
        class="magna-misson-detail__header__tabs"
        :tabs="tabsComponents"
        variation="secondary"
        @change-component="setCurrentTab"
      />
      <div
        v-if="isActiveMission"
        class="magna-misson-detail__header-actions"
      >
        <GButton
          class="magna-misson-detail__header-action-left"
          variation="secondary"
          no-border
          rowdies
          size="large"
          icon-left="power"
          :icon-stroke="4"
          @click="() => openFinishedMissionModal()"
        >
          Finalizar missão
        </GButton>
        <GButton
          no-border
          size="large"
          rowdies
          @click="() => toReports()"
        >
          Ir para Relatório
        </GButton>
      </div>
      <div
        v-else-if="eurekaHomeMissionsEnabled"
        class="magna-misson-detail__header-actions"
      >
        <VDropdown
          :distance="15"
          placement="bottom"
          :disabled="!isOpenStartMissionOptions"
        >
          <GButton
            :class="[
              'magna-misson-detail__header__start-button',
            ]"
            :size="isSmallScreen ? 'small': 'large'"
            rowdies
            @click="handleOpenStartMissionOptions"
          >
            Iniciar Missão
          </GButton>
          <template #popper>
            <ul class="magna-misson-detail__start-mission-options">
              <li>
                <GButton
                  class="magna-misson-detail__start-mission-options-actions"
                  variation="secondary"
                  asset="ancestral-arena/missions/rounded-mission-type-live.svg"
                  rowdies
                  @click.stop="showLiveMissionModal"
                >
                  Missão ao vivo
                </GButton>
              </li>
              <li>
                <GButton
                  class="magna-misson-detail__start-mission-options-actions"
                  variation="secondary"
                  asset="ancestral-arena/missions/rounded-mission-type-home.svg"
                  rowdies
                  @click.stop="showHomeMissionModal"
                >
                  Missão para casa
                </GButton>
              </li>
            </ul>
          </template>
        </VDropdown>
      </div>
      <div
        v-else
        class="magna-misson-detail__header-actions"
      >
        <GButton
          class="magna-misson-detail__header__start-button"
          size="large"
          rowdies
          @click="showLiveMissionModal"
        >
          Iniciar Missão
        </GButton>
      </div>
    </header>
    <component
      :is="componentName"
      :magna-missions="magnaMissions"
    />
    <StartLiveMagnaMissionModal
      v-if="isLiveMissionModalVisible"
      class="magna-mission-modal"
      :selected-item-create-mission-modal="selectedItemCreateMissionModal"
      @start="startLiveMagnaMission"
      @close="closeLiveMissionModal"
    />
    <StartHomeMagnaMissionModal
      v-if="isHomeMissionModalVisible"
      class="magna-mission-modal"
      :selected-item-create-mission-modal="selectedItemCreateMissionModal"
      @start="startHomeMagnaMission"
      @close="closeHomeMissionModal"
    />
    <InfoMagnaMissionModal
      v-if="magnaMissions.error"
      title="Ocorreu um problema com o carregamento das questões"
      description="Não foi possível carregar as questões dessa missão.
       Tente fechar a página e voltar novamente."
      :button-name="$t('commons.close')"
      @confirm="navigateToAncestralArena"
      @close="navigateToAncestralArena"
    />
    <DangerMagnaMissionModal
      v-if="isFinishedModalVisible"
      title="Deseja finalizar a missão?"
      description="Ao finalizar a missão, os alunos não poderão mais respondê-la e
       você não poderá retomá-la."
      button-name="Finalizar"
      @confirm="finishedMission"
      @close="closeFinishedMissionModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import tourMixin from '@/mixins/tour'
import redirectTo from '@/utils/redirectTo'
import arenaApi from '@/service/arena'
import handleResize from '@/mixins/handleResize'
import arrowLeft from '@/assets/ancestral-arena/arrow-left.svg'
import GButton from '@/components/GButton'
import MagnaMissionTabs from '@/components/MagnaMissionTabs'
import TotalTime from '@/components/AncestralArena/TotalTime'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import MagnaMissionContentTab from '@/views/AncestralArena/MagnaMissionDetail/tabs/MagnaMissionContentTab'
import MagnaMissionResultTab from '@/views/AncestralArena/MagnaMissionDetail/tabs/MagnaMissionResultTab'
import StartLiveMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/StartLiveMagnaMissionModal'
import StartHomeMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/StartHomeMagnaMissionModal'
import DangerMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/DangerMagnaMissionModal'
import toString from 'lodash-es/toString'
import { getAssignmentReportURL } from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'
import applicationTypeEnum from '../AncestralArenaList/partials/AncestralArenaCard/enums/missionApplicationType'

const isMicrofrontend = getIsMicrofrontend()

export default {
  name: 'MagnaMissionDetail',
  components: {
    MagnaMissionTabs,
    MagnaMissionContentTab,
    MagnaMissionResultTab,
    StartLiveMagnaMissionModal,
    StartHomeMagnaMissionModal,
    GButton,
    InfoMagnaMissionModal,
    TotalTime,
    DangerMagnaMissionModal,
  },
  mixins: [ tourMixin, handleResize ],
  props: {
    isActiveMission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowLeft,
      tabsComponents: [
        {
          id: 1,
          name: 'content',
          componentName: 'MagnaMissionContentTab',
          text: 'Conteúdo',
        },
        {
          id: 2,
          name: 'result',
          componentName: 'MagnaMissionResultTab',
          text: 'Resultados',
        },
      ],
      currentTab: null,
      isLiveMissionModalVisible: false,
      isHomeMissionModalVisible: false,
      isFinishedModalVisible: false,
      magnaMissions: {
        loading: null,
        error: null,
        data: [],
      },
      timer: null,
      isOpenStartMissionOptions: false,
    }
  },
  computed: {
    ...mapState({
      magnaMission: ({ arena }) => arena.magnaMission.data,
    }),
    isApplicationTypeHome() {
      return toString(this.magnaMissions.data?.applicationType)
        ?.toUpperCase() === applicationTypeEnum.HOME
    },

    isApplicationTypeLive() {
      return toString(this.magnaMissions.data?.applicationType)
        ?.toUpperCase() === applicationTypeEnum.LIVE
    },
    studentsInfo() {
      const finishedAssignmentsQuantity = this.magnaMissions.data?.finishedAssignmentsQuantity ?? 0
      const studentsAmount = this.magnaMissions.data?.classroom?.studentsAmount ?? 0

      return `${finishedAssignmentsQuantity}/${studentsAmount}`
    },
    truncatedTitle() {
      return this.title
    },
    truncatedSubTitle() {
      return this.subtitle
    },
    title() {
      return this.magnaMissions.data?.title ?? ''
    },
    subtitle() {
      return this.magnaMissions.data?.subtitle ?? ''
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    componentName() {
      return this.currentTab?.componentName || null
    },
    missionQuestions() {
      return this.magnaMissions.data?.questions?.map((question) => ({
        ...question,
        hasTimer: Boolean(question.timer),
      })) || []
    },
    selectedItemCreateMissionModal() {
      return {
        title: this.title,
        questionsTimerSum: this.timer?.timeInSeconds,
        formmatedQuestionsTimerSum: this.timer?.formattedTime,
        type: this.magnaMissions.data?.type,
        classroom: this.magnaMissions.data?.classroom,
      }
    },
    questionnaireType() {
      return this.magnaMissions.data?.type ?? ''
    },
  },
  mounted() {
    this.fetchMagnaMissions()
  },
  methods: {
    handleOpenStartMissionOptions() {
      this.isOpenStartMissionOptions = true
    },
    openFinishedMissionModal() {
      this.isFinishedModalVisible = true
    },
    closeFinishedMissionModal() {
      this.isFinishedModalVisible = false
    },
    finishedMissionModal() {
      this.openFinishedMissionModal()
    },
    finishedMission() {
      const label = this.isApplicationTypeHome
        ? this.$track.label.teacherViewedHomeMission
        : this.$track.label.teacherViewedLiveMission

      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.viewedMission,
        label,
      })
      this.closeFinishedMissionModal()
      this.finishedMissionUpdate()
    },
    async finishedMissionUpdate() {
      try {
        const questionnaireCode = this.magnaMissions.data?.code

        if (!questionnaireCode) {
          return
        }

        const body = { status: 'FINISHED' }
        await arenaApi.updateQuestionnaireBff(questionnaireCode, body)
        this.goBack()
      } catch (error) {
        console.error(error)
      }
    },
    toReports() {
      this.redirectToAssignmentReport()
    },
    redirectToAssignmentReport() {
      const questionnaireCode = this.magnaMissions.data?.code
      const classroomId = this.magnaMissions.data?.classroom?.id

      if (!questionnaireCode || !classroomId) return

      const baseUrl = getAssignmentReportURL(isMicrofrontend)

      redirectTo(`${baseUrl}/questionnaires/${questionnaireCode}/classes/${classroomId}/general`)
    },
    setCurrentTab(tab) {
      this.currentTab = tab
    },
    goBack() {
      this.$router.replace({ name: 'arena' })
    },
    showCreateModal() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.openContentMission,
      })

      this.showLiveMissionModal()
    },
    showHomeMissionModal() {
      this.isOpenStartMissionOptions = false
      this.isHomeMissionModalVisible = true
    },
    closeHomeMissionModal() {
      this.isOpenStartMissionOptions = true
      this.isHomeMissionModalVisible = false
    },
    showLiveMissionModal() {
      this.isOpenStartMissionOptions = false
      this.isLiveMissionModalVisible = true

      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherStartLiveMissionLobby,
        label: this.$track.label.numberOfStudentsParticipating,
        value: this.magnaMissions.data?.classroom?.studentsAmount,
      })
    },
    closeLiveMissionModal() {
      this.isOpenStartMissionOptions = true
      this.isLiveMissionModalVisible = false
    },
    async startLiveMagnaMission({
      selectedClassroom, hasRankingOptional,
    }) {
      const { questionnaireCode, questionnaireType } = this
      const { id: classroomId } = selectedClassroom

      const response = await arenaApi.createLiveAndHomeMagnaMission({
        questionnaireCode,
        classroomIds: [ classroomId ],
        applicationType: applicationTypeEnum.LIVE,
        isRanked: hasRankingOptional,
        questionnaireType,
      })

      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.selectClassroom,
        label: selectedClassroom.name,
      })

      const newQuestionnaireCode = response?.data?.[0]?.code ?? null

      this.closeLiveMissionModal()

      this.$router.push({
        name: 'lobby-teacher',
        params: {
          classroomId,
          questionnaireCode: newQuestionnaireCode,
        },
      })
    },
    async startHomeMagnaMission({ selectedClasses, hasRankingOptional, type }) {
      const { code: questionnaireCode } = this.magnaMissions.data
      await arenaApi.createLiveAndHomeMagnaMission({
        questionnaireCode,
        classroomIds: selectedClasses,
        applicationType: applicationTypeEnum.HOME,
        isRanked: hasRankingOptional,
        questionnaireType: type,
      })

      this.closeHomeMissionModal()

      this.$router.replace({
        name: 'arena',
        query: { tab: 'active-missions' },
      })
    },
    navigateToAncestralArena() {
      this.$router.replace({ name: 'arena' })
    },
    async fetchMagnaMissions() {
      this.magnaMissions.error = null
      this.magnaMissions.loading = true

      try {
        const { data } = await arenaApi
          .getQuestionnairesMagnaMissionsBy(this.questionnaireCode, this.isActiveMission)
        this.magnaMissions.data = data
        this.magnaMissions.loading = false
      } catch (error) {
        this.magnaMissions.error = error
      }
    },
    setTimer(time) {
      this.timer = time
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-misson-detail {
  @include cover;

  height: 100vh;
  z-index: 100;
  background: {
    image: url('~@/assets/ancestral-arena/background.png');
    size: cover;
    attachment: fixed;
    color: $eureka-color-ink;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $size-s $size-s 0 $size-s;

    position: relative;
    align-items: flex-start;

    background-color: $eureka-color-base;

    @media screen and (max-width: 991px) {
    }

    &__start-button {
      height: 40px;
      text-shadow: 0 1px 0 $color-black;

      ::v-deep &.g-button {
        border: none;
      }
    }

    &__line {
      width: 100%;
      height: 0;
      border: 1px solid $eureka-color-base-darker;
      margin-top: $size-xs;
      margin-bottom: 0;
    }

    &__tabs {
      margin-left: 45px;

      @media screen and (max-width: 579px) {
        margin-left: 29px;
      }
    }
  }

  &__header-sub-info {
    color: $eureka-color-ink-light;
    font-family: Lato;
    font-weight: $font-weight-medium;
    font-size: $font-size-m;
    line-height: $line-height-text;
    margin-top: $size-xs;
    margin-bottom: $size-s;
    letter-spacing: 0.44px;
    color: $eureka-color-ink-light;
    display: flex;
    gap: $size-xs;
    align-items: center;
  }

  &__header-sub-info-icon {
    ::v-deep &.feather {
      height: 20px;
    }
  }

  &__header-actions {
    position: absolute;
    right: 0;
    top: $size-xs;
    padding: $size-m $size-xl 0 $size-l;
    display: flex;
    gap: $size-xs;

    @media screen and (max-width: 579px) {
      padding: $size-s;
    }

    .--sasMission {
      background: #b9babe !important;
      color: #fff !important;
    }
  }

  &__header-action-left {
    ::v-deep &.g-button {
      color: $eureka-color-ink;
    }
  }

  &__start-mission-options {
    padding: 12px 0;
  }

  &__start-mission-options-actions {
    width: 100%;
    border-radius: 0;
    text-align: left;
    justify-content: flex-start;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-m;
    line-height: $line-height-heading;
    color: $eureka-color-ink;

    ::v-deep .g-button__image.--medium {
      height: 26px;
      width: 26px;
    }
  }
}

.magna-misson-detail__header__main {
    display: flex;
    align-items: baseline;

    &__back-button {
      opacity: 0.7;
      padding: $size-xs;
    }

    &__title {
      font-family: Rowdies;
      min-height: 40px;
      color: $eureka-color-ink;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__subtitle {
      font: {
        family: Lato;
        weight: $font-weight-medium;
      }
      color: $eureka-color-ink;
      letter-spacing: 0.0275rem;
      margin-bottom: $size-xs;
    }
}

.magna-mission-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(#0c0b1f, 0.75);
  z-index: $zindex-modal-backdrop;
  @include cover;
  @include flex-center;
  min-width: min(640px, 95vw);

  ::v-deep .magna-mission-modal__footer__submit-button {
    &.g-button {
      &:disabled {
        background-color: #b9babe !important;
        color:  $color-white !important;
      }
    }
  }
}
</style>
