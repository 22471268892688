<template>
  <div class="academic-record-card">
    <h5 class="academic-record-card__title">
      <skeleton-loader
        width="200px"
        height="30px"
        border-radius="4px"
        opacity="0.6"
      />
    </h5>
    <div class="academic-record-card__year">
      <skeleton-loader
        width="45px"
        height="30px"
        border-radius="4px"
      />
    </div>
    <div class="academic-record-card__content">
      <div class="academic-record-card__info">
        <p class="academic-record-card__info-title">
          Insígnia Conquistada:
        </p>
        <span class="academic-record-card__description">
          <skeleton-loader
            width="180px"
            height="20px"
            border-radius="4px"
          />
        </span>
      </div>
      <div class="academic-record-card__info">
        <p class="academic-record-card__info-title">
          Gemas e Moedas:
        </p>
        <span class="academic-record-card__description">

          <img
            class="academic-record-card__icon"
            src="@/assets/gem.svg"
          >
          <skeleton-loader
            width="65px"
            height="20px"
            border-radius="4px"
          />

          <img
            class="academic-record-card__icon"
            src="@/assets/coin.svg"
          >
          <skeleton-loader
            width="30px"
            height="20px"
            border-radius="4px"
          />
        </span>
      </div>
      <div class="academic-record-card__info academic-record-card__explored-worlds">
        <p class="academic-record-card__info-title">
          Mundos explorados:
        </p>

        <span
          v-for="(item) in 4"
          :key="item"
          class="academic-record-card__explored-worlds"
        >
          <skeleton-loader
            width="20px"
            height="20px"
            border-radius="4px"
            class="academic-record-card__explored-worlds-icon"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AcademicRecordSkeleton',
}
</script>

<style lang="scss" scoped>

.academic-record-card {
  &__title {
    padding: $size-s;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
    color: $eureka-color-ink;
  }

  &__year {
    position: absolute;
    right: 0;
    background-color: $eureka-color-ink;
    padding: $size-xs $size-s;
    border-radius: 0 16px 0 $border-radius-m;
    color: $color-white;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
  }

  &__content {
    padding: $size-s;
    margin: 0 $size-s;
    background-color: $eureka-color-base-lighter;
    width: calc(100% - 32px);
    border-radius: $border-radius-m;
    margin-bottom: $size-s;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: $size-s;
    font-family: "Metropolis";
    color: $eureka-color-ink;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-title {
    margin-right: $size-s;
  }

  &__icon {
    width: $size-m;
    height: $size-m;
    margin: 0 $size-xs 0 $size-xxs;

    &:last-child {
      margin-left: $size-s;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-weight: $font-weight-bold;
  }

  &__explored-worlds-icon {
    border-radius: $border-radius-m;
    height: $size-m;
    width: $size-m;
    margin: 0 $size-xxs;
  }
}
</style>
