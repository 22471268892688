<template>
  <div class="mission-report-footer">
    <g-alert
      v-if="redo.error"
      variation="error"
      :message="$t('game.error.redo.message')"
    />
    <g-button
      variation="secondary"
      size="large"
      icon-left="repeat"
      @click="$emit('redo-mission')"
    >
      {{ $t('game.redo.label') }}
    </g-button>
    <g-button
      size="large"
      @click="$emit('go-to-missions')"
    >
      {{ $t('game.report.back') }}
    </g-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'

export default {
  name: 'MissionReportFooter',
  components: {
    GButton,
    GAlert,
  },
  computed: {
    ...mapGetters([
      'redo',
    ]),
  },
}
</script>

<style lang="sass" scoped>
.mission-report-footer
  padding: 16px
  +flex-center
  flex-wrap: wrap
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1)

  .g-button
    +space-inline(24px)

  .g-alert
    margin-bottom: $size-m
</style>
