<template>
  <div class="magna-report">
    <div
      v-if="isLoading"
      class="magna-report__info"
    >
      <GLoader />
      <div class="magna-report__info__text">
        Finalizando sua missão...
      </div>
    </div>
    <GFrame
      v-else
      class="magna-report__content"
    >
      <header class="magna-report__content__header">
        <h4 class="magna-report__content__header__title">
          Resumo da Missão
        </h4>
        <GButton
          :icon-stroke="5"
          class="magna-report__content__header__close-button"
          variation="secondary"
          size="large"
          icon="x"
          @click="close"
        />
      </header>
      <slot />
      <footer class="magna-report__content__footer">
        <GButton
          :size="footerButtonSize"
          :asset="buttonImageName"
          @click="$emit('click')"
        >
          {{ footerButtonText }}
        </GButton>
      </footer>
    </GFrame>
  </div>
</template>

<script>
import GFrame from '@/components/GFrame'
import GLoader from '@/components/GLoader'
import GButton from '@/components/GButton'

export default {
  name: 'MagnaMissionReportLayout',
  components: {
    GFrame,
    GButton,
    GLoader,
  },
  props: {
    isLoading: Boolean,
    footerButtonText: {
      type: String,
      default: 'Fechar Missão',
    },
    buttonImageName: {
      type: String,
      default: '',
    },
    footerButtonSize: {
      type: String,
      default: 'large',
    },
  },
  methods: {
    close() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.studentFinishMagnaMission,
      })

      this.$router.replace({ name: 'arena' })
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-report {
  margin: 0 $size-l;
  @include flex-center;

  @media screen and (max-width: 991px) {
    margin: 0;
  }

  &__info {
    @include flex-column-center;
    height: 80vh;

    &__text {
      font: {
        family: 'Rowdies';
        size: $font-size-heading-2;
      }
      color: $color-white;
      text-align: center;
    }
  }

  &__content {
    width: 80vw;
    max-width: 1100px;
    padding: 0 !important;
    box-shadow: 0px 9px 0px #C4914E, 0px 20px 36px -8px rgba(0, 0, 0, 0.42);
    border-radius: $size-m  !important;

    ::v-deep .g-frame__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: relative;
      overflow: hidden;
      height: 65vh;

      @media screen and (max-width: 991px) {
        max-height: unset;
      }
    }

    &__header {
      margin-top: 40px;

      &__title {
        display: inline;
        font: {
          family: 'Rowdies';
          weight: $font-weight-regular;
        }
        color: $eureka-color-ink;
        margin: 40px 0 0 40px;
        border-bottom: 1px solid $eureka-color-base-darker;
      }

      &__close-button {
        position: absolute;
        right: 4%;
        top: 4%;
        color: #473F78;
        opacity: 0.5;
        z-index: 1;
      }
    }

    &__footer {
        .g-button {
          @include space-inline($size-m);
          border: 0;
          min-width: 236px;
          margin: 0 0 $size-xl $size-l;

          &:disabled {
            border: 0 !important;
          }
        }
      }
  }
}
</style>
