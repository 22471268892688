<template>
  <transition name="fade">
    <div class="performance-bar">
      <ResourceBar
        class="performance-bar__hit-rate"
        :value="performance.hitRate"
        :total="100"
        variation="yellow"
      />
      <template
        v-if="hasChests"
      >
        <img
          v-for="(value, index) in performance.chests"
          :key="index"
          class="performance-bar__lock"
          src="@/assets/reward/reward-locked.svg"
          :style="{ left: `${value}%` }"
        >
        <transition-group
          name="chest"
          tag="div"
          class="performance-bar__chests"
        >
          <img
            v-for="(value, index) in unlockedChests"
            :key="value"
            class="performance-bar__chests__chest"
            :src="require(
              `@/assets/reward/reward-unlocked-${chestLevel(index)}-achieved.svg`
            )"
            :style="{ left: `${value}%` }"
          >
        </transition-group>
      </template>
    </div>
  </transition>
</template>

<script>
import ResourceBar from '@/components/ResourceBar'

export default {
  name: 'PerformanceBar',
  components: {
    ResourceBar,
  },
  props: {
    performance: {
      type: Object,
      default: () => ({
        chests: [],
        hitRate: 0,
      }),
    },
    chestLevels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    unlockedChests() {
      return this.performance.chests?.filter((value) => (
        value <= this.performance.hitRate
      )) ?? []
    },
    hasChests() {
      return this.performance?.chests?.length ?? false
    },
  },
  methods: {
    chestLevel(index) {
      const chestLevel = index + 1
      const { wasAchieved } = this.chestLevels.find(
        ({ level }) => parseInt(level, 10) === chestLevel
      )

      return wasAchieved ? 'was' : 'not'
    },
  },
}
</script>

<style lang="scss" scoped>
.performance-bar {
  min-width: 300px;
  position: relative;
  @include flex-center;

  &__hit-rate {
    flex-grow: 1;
  }

  &__lock {
    display: block;
    position: absolute;
    transform: translateX(-50%);
  }

  &__chests {
    position: absolute;
    width: 100%;
    @include flex-center;

    &__chest {
      display: block;
      position: absolute;
      transform: translateX(-50%);
      width: $size-l;

      @include mq-l--mf {
        width: auto;
        padding-right: $size-xs;
      }
    }
  }
}

.chest {
  &-enter-active,
  &-leave-active {
    transition: all $speed-normal cubic-bezier(0.610, 0.005, 0.240, 1.520) 400ms;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-50%) scale(0.3);
  }
}
</style>
