var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ScrollContainer',{ref:"missionScreen",class:[
    'missions',
    {
      '--error': _vm.assignments.error,
    },
  ],style:(`background: ${_vm.missionBackground}`),attrs:{"moz-full-scroll":""}},[_c('div',{staticClass:"missions__topbar",class:{
      '--scroll' : _vm.showTopbarBackground,
      '--mfe-margin': _vm.isMFE
    }},[(_vm.isTeacherTopbarVisible)?_c('TeacherTopbar',{attrs:{"selector-is-hidden":""}}):_vm._e(),_c('div',{staticClass:"missions__topbar__user-status"},[_c('div',{staticClass:"missions__topbar__wrapper"},[_c('div',{staticClass:"missions__topbar__back"},[_c('GButton',{staticClass:"missions__topbar__back__button",attrs:{"icon":"arrow-left","size":"medium","variation":"framed"},on:{"click":_vm.goBackHome}})],1),_c('UserStatusBar',{attrs:{"color":_vm.levelDisplayColor,"hide-badge-on-mobile":true}})],1)])],1),_c('div',{ref:"header",staticClass:"missions__header",style:({
      backgroundColor: _vm.backgroundColor,
      backgroundImage: _vm.backgroundImage,
    })},[(_vm.themes)?_c('WorldTitle',{ref:"worldTitle",attrs:{"title":_vm.$t(`lectures.${_vm.slug}`),"gems":_vm.gemsAmount}}):_vm._e()],1),_c('div',{staticClass:"missions__container"},[(!_vm.assignments.error)?_c('div',{ref:"grid",staticClass:"missions__grid"},[(_vm.assignments.success)?_c('MapSide',{ref:"leftTiles",attrs:{"side":"left","mission-track-height":_vm.trackHeight,"theme":_vm.theme,"missions-amount":_vm.assignments.data.length}}):_vm._e(),(_vm.theme && _vm.assignments.success)?_c('div',{ref:"track",staticClass:"missions__grid__track-container"},[_c('Track',{attrs:{"color":_vm.theme.ground.color,"dark-mode":_vm.theme.ground.darkMode,"stroke-color":_vm.theme.ground.trackColor,"missions":_vm.assignments.data},on:{"click-on-mission":_vm.clickOnMission}})],1):_vm._e(),(_vm.assignments.success)?_c('MapSide',{ref:"rightTiles",attrs:{"side":"right","mission-track-height":_vm.trackHeight,"theme":_vm.theme,"missions-amount":_vm.assignments.data.length}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.assignments.error)?_c('div',{staticClass:"missions__error"},[_c('img',{staticClass:"missions__error__illustration",attrs:{"src":require(`@/assets/error/missions/illustration.svg`)}}),_c('h2',{class:[
            'missions__error__message',
            { '--dark-mode': _vm.theme.ground.darkMode }
          ],style:({ color: _vm.theme.ground.color })},[_vm._v(" "+_vm._s(_vm.$t(`game.error.missions.message`))+" ")]),(_vm.$te(`game.error.${_vm.assignments.error.status}.obs`))?_c('p',{staticClass:"missions__error__obs",style:({
            color: _vm.theme.ground.color
          })},[_vm._v(" "+_vm._s(_vm.$t(`game.error.${_vm.assignments.error.status}.obs`))+" ")]):_vm._e(),_c('g-button',{staticClass:"missions__error__action",attrs:{"size":"large"},on:{"click":_vm.onErrorAction}},[_vm._v(" "+_vm._s(_vm.assignmentButtonText)+" ")])],1):_vm._e()])],1),_c('OnBoarding',{attrs:{"mission":_vm.missionOnBoarding},on:{"start-mission":_vm.goToMission,"active-item":function($event){return _vm.activeItem($event)},"close-on-boarding":_vm.closeOnBoarding}}),(!_vm.nextLecture.loading && _vm.nextLecture.data)?_c('NextWorldModal',{attrs:{"next":_vm.nextLecture},on:{"goToNext":function($event){return _vm.goToNextWorld(_vm.nextLecture.data)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }