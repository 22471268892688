<template>
  <div class="empty-bag">
    <img
      class="empty-bag__image"
      :src="require(`@/assets/bag-${state}.svg`)"
    >
    <h3 class="empty-bag__message">
      {{ $t(`game.bag.${state}.message`) }}
    </h3>
    <p class="empty-bag__observation">
      {{ $t(`game.bag.${state}.observation`) }}
    </p>
    <g-button
      v-if="bagItems.error || bagItems.loading"
      size="large"
      :loading="bagItems.loading"
      @click="$emit('tryAgain')"
    >
      Atualizar
    </g-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GButton from '@/components/GButton'

export default {
  name: 'EmptyBag',
  components: {
    GButton,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'bagItems',
    ]),
    state() {
      return this.bagItems.error || this.bagItems.loading
        ? 'error'
        : 'empty'
    },
  },
}
</script>

<style lang="sass" scoped>
.empty-bag
  text-align: center
  height: 100%
  +flex-column-center

  &__image
    margin-bottom: 20px

  &__message
    font-size: 20px
    font-weight: bold
    line-height: 1
    margin-bottom: 8px

  &__observation
    color: transparentize($game-ink, 0.3)
    font-weight: 500
    font-size: 14px
    line-height: 1.3

  .g-button
    margin-top: 24px
</style>
