<template>
  <div class="create-magna-mission-modal">
    <MagnaMissionModal
      :title="title"
      button-name="Abrir Arena"
      title-icon="ancestral-arena/missions/rounded-mission-type-home.svg"
      :disable-submit="!hasSelectedClass"
      title-icon-tooltip="Missão para casa"
      @close="handleClose"
      @submit="handleStartMission"
    >
      <div class="create-magna-mission-modal__content">
        <div
          v-if="isLoading"
          data-testid="loading"
          class="create-magna-mission-modal__content__loading-state"
        >
          <GLoader
            size="small"
            sand="#F8A916"
            stroke="#2D1F49"
          />
        </div>
        <template v-else>
          <div class="create-magna-mission-modal__content__classroom-select">
            <img
              src="@/assets/char/cora/cora-modal.svg"
              class="create-magna-mission-modal__content__classroom-select__icon"
            >
            <div class="create-magna-mission-modal__content__classroom-select__main">
              <p class="create-magna-mission-modal__content__classroom-select__main__title">
                {{ classroomSelectTitle }}
              </p>
              <MultiSelectDropdown
                placeholder="Selecionar turmas"
                aria-label="Selecionar turmas"
                multiselect-counter
                multiselect
                item-value="id"
                item-text="name"
                :items="classes"
                :value="selectedClasses"
                :disabled="isReinforcementMission"
                @change="selectClassroom($event)"
              />
            </div>
            <div
              v-if="showEurekaChallengeRanking"
              class="create-magna-mission-modal__content__checkbox"
            >
              <input
                id="ranking-optional"
                v-model="hasRankingOptional"
                type="checkbox"
                name="ranking-optional"
              >
              <label
                for="ranking-optional"
                class="create-magna-mission-modal__content__label"
              >
                Exibir classificação de alunos
              </label>
              <icon
                v-tooltip.top="tooltipInfoText"
                class="create-magna-mission-modal__content__icon"
                type="info"
                stroke="#9390A3"
              />
            </div>
          </div>
        </template>
      </div>
      <template
        v-if="hasTimerMessage"
        #footer-additional-message
      >
        <div class="magna-mission-modal__timer">
          <icon
            class="magna-mission-modal__icon"
            type="clock"
            stroke-width="3"
          />
          <p data-testid="formmatedQuestionsTimerSum">
            Temporizador indisponível para esse tipo de missão.
          </p>
        </div>
      </template>
    </MagnaMissionModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import GLoader from '@/components/GLoader'
import MultiSelectDropdown from '@/components/AncestralArena/MultiSelectDropdown/MultiSelectDropdown'
import { isEmpty } from 'lodash-es'
import MagnaMissionModal from './MagnaMissionModal'
import applicationType from '../enums/missionApplicationType'

export default {
  name: 'StartHomeMagnaMissionModal',
  components: {
    GLoader,
    MultiSelectDropdown,
    MagnaMissionModal,
  },
  props: {
    groupBy: {
      type: String,
      default: null,
    },
    selectedItemCreateMissionModal: {
      type: Object,
      required: true,
      default: () => ({
        questionsTimerSum: 0,
        formmatedQuestionsTimerSum: '',
        title: '',
      }),
    },
  },
  data() {
    return {
      hasRankingOptional: false,
      selectedClasses: this.initialSelectedClasses(),
    }
  },
  computed: {
    showEurekaChallengeRanking() {
      return false
    },
    ...mapGetters([ 'classrooms' ]),
    hasSelectedClass() {
      return !isEmpty(this.selectedClasses)
    },
    tooltipInfoText() {
      return 'Exibe quais alunos tiveram mais acertos no final da questão'
    },
    isLoading() {
      return this.classrooms.loading
    },
    isReinforcementMission() {
      return this.selectedItemCreateMissionModal?.type?.toUpperCase() === 'CHALLENGE_REINFORCEMENT'
    },
    classes() {
      return this.classrooms.data
    },
    classroomSelectTitle() {
      return this.isReinforcementMission ? 'Você estará jogando com a turma:' : 'Turmas'
    },
    hasTimerMessage() {
      return this.selectedItemCreateMissionModal.questionsTimerSum > 0
    },
    formmatedQuestionsTimerSum() {
      return this.selectedItemCreateMissionModal.formmatedQuestionsTimerSum
    },
    title() {
      return this.selectedItemCreateMissionModal.title
    },
  },
  mounted() {
    this.getClassrooms()
  },
  beforeDestroy() {
    this.selectedClasses = null
  },
  methods: {
    ...mapActions([
      'getClassrooms',
    ]),
    initialSelectedClasses() {
      const isEmptyClass = isEmpty(this.selectedItemCreateMissionModal?.classroom)
      if (isEmptyClass) return null

      return [ this.selectedItemCreateMissionModal?.classroom ]
    },
    selectClassroom(classroom) {
      this.selectedClasses = classroom
    },
    handleStartMission() {
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherOpenHomeArena,
        label: this.$track.label.selectedClassesQuantity,
        value: this.selectedClasses?.length ?? 0,
      })

      const selectedClasses = this.selectedClasses.map((item) => item.id)

      this.$emit('start', {
        selectedClasses,
        hasRankingOptional: this.hasRankingOptional,
        applicationType: applicationType.LIVE,
      })
    },
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-modal {
  min-width: min(640px, 95vw);

  &__timer {
    margin: $size-xxs $size-m;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    font-weight: $font-weight-medium;
    color: $eureka-color-ink-light;
    letter-spacing: 0.0275rem;
    font-family: Lato;
    align-items: center;
  }

  ::v-deep &__icon {
    height: 24px;
    width: 24px;
  }
}

.create-magna-mission-modal {
  width: 100%;
  height: 100%;
  @include flex-center;

  &__content {
    padding: $size-xxl $size-m;
    @include mq_s {
      padding: $size-m 0;
    }
    &__loading-state {
      @include flex-center;
    }

    &__checkbox {
      @include flex-center;
      margin-top: $size-m;
    }

    &__label {
      font-family: Rowdies;
      font-weight: $font-weight-regular;
      font-size: $font-size-m;
      line-height: 1.24;
      color: $eureka-color-ink
    }

    ::v-deep &__icon {
      margin-left: $size-xs;
      stroke-width: 3;
      color: $eureka-color-ink-lighter;
      margin-right: $size-m;
      @include mq_s{
      margin: 0;
      }
    }

    &__classroom-select {
      @include flex-center;
      @include mq_s {
        flex-direction: column;
      }
      &__icon {
        width: 120px;
        height: auto;
        margin-right: $size-xl;
        @include mq_s {
          margin: 0;
          margin-bottom: $size-s;
          width: 30vw;
        }
      }

      &__main {
        .class-picker {
          ::v-deep .adaptive-selector-dropdown__handle {
            box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba($color-white, 0.75);
            border: 1px solid $eureka-color-base-dark;
          }

          ::-webkit-scrollbar {
            width: 10px;
          }

          ::-webkit-scrollbar-thumb {
            background: rgba(#1a1a1a, 0.1);
          }

          ::-webkit-scrollbar-thumb:hover {
            background: rgba(#1a1a1a, 0.7);
          }
        }
      }

      &__main__title {
        color: $eureka-color-ink;
        font-weight: $font-weight-semi-bold;
        margin-bottom: $size-xs;
      }
    }
  }
}

.classroom-select {
  &__class-picker {
    &__icon {
      color: #473F78;
      opacity: 0.5;
      flex-shrink: 1;
      margin-right: $size-s;
      height: $size-m;
      width: $size-m;

      ::v-deep .feather__content {
        height: 100%;
        width: 100%;
      }
    }

    &__title {
      color: #473F78;
      font-weight: $font-weight-medium;
    }

    &__caret {
      margin-left: $size-xs;
      color: #473F78;
      opacity: 0.5;
    }
  }
}

</style>
