<template>
  <div class="shop">
    <TeacherTopbar
      v-if="isTeacherTopbarVisible"
      class="shop-teacher-topbar"
      selector-is-hidden
    />
    <div class="shop-content">
      <header
        v-if="!isSmallScreen"
        :class="[
          'shop-content__header',
          {'--has-back-button': enableBackButton},
          {'--teacher-view': isTeacherTopbarVisible}
        ]"
      >
        <GButton
          v-if="enableBackButton"
          class="shop-content__header__back-button"
          icon="arrow-left"
          size="large"
          variation="primary"
          @click="goBack"
        />
        <div class="shop-content__header__right-actions">
          <transition name="fade">
            <WalletDisplay
              class="shop-content__header__right-actions__gems"
              size="large"
              currency-type="gem"
              :quantity="userGems"
            />
          </transition>
          <transition name="fade">
            <WalletDisplay
              class="shop-content__header__right-actions__money"
              size="large"
              currency-type="coin"
              :quantity="userCoins"
            />
          </transition>
          <SoundConfig
            v-if="soundPreferences.data"
            class="shop-content__header__right-actions__sound-config"
          >
            <ConfigControl
              :label="$t('commons.sounds')"
              control-id="shop-sounds"
              :control-value="soundPreferences.data.sound"
              @valueChange="toggleSounds"
            />
            <ConfigControl
              :label="$t('commons.music')"
              control-id="shop-music"
              :control-value="soundPreferences.data.music"
              @valueChange="toggleMusic"
            />
          </SoundConfig>
        </div>
      </header>
      <header
        v-else
        :class="[
          'shop-content__header',
          { '--has-back-button': enableBackButton },
          { '--teacher-view': isTeacherTopbarVisible }
        ]"
      >
        <div class="shop-content__header__title">
          <img
            class="shop-content__header__title__icon"
            src="@/assets/shop/icon.svg"
          >
          <h2
            class="shop-content__header__title__text"
          >
            {{ $t('game.shop.signboard') }}
          </h2>
          <SoundConfig
            v-if="soundPreferences.data"
            class="shop-content__header__title__sound-config"
          >
            <ConfigControl
              :label="$t('commons.sounds')"
              control-id="shop-sounds"
              :control-value="soundPreferences.data.sound"
              @valueChange="toggleSounds"
            />
            <ConfigControl
              :label="$t('commons.music')"
              control-id="shop-music"
              :control-value="soundPreferences.data.music"
              @valueChange="toggleMusic"
            />
          </SoundConfig>
        </div>
        <div class="shop-content__header__right-actions">
          <transition name="fade">
            <WalletDisplay
              class="shop-content__header__right-actions__gems"
              size="small"
              currency-type="gem"
              :quantity="userGems"
            />
          </transition>
          <transition name="fade">
            <WalletDisplay
              class="shop-content__header__right-actions__money"
              size="small"
              currency-type="coin"
              :quantity="userCoins"
            />
          </transition>
          <button
            v-if="isOpenBag"
            class="shop-content__header__right-actions__btn-toggle"
            @click="handleToggle"
          >
            <img
              class="shop-content__header__right-actions__btn-toggle__image"
              src="@/assets/shop/icon.svg"
            >
            <p
              class="shop-content__header__right-actions__btn-toggle__text"
            >
              Mercado
            </p>
          </button>
          <button
            v-if="!isOpenBag"
            class="shop-content__header__right-actions__btn-toggle"
            @click="handleToggle"
          >
            <img
              class="shop-content__header__right-actions__btn-toggle__image"
              src="@/assets/bag.svg"
            >
            <p
              class="shop-content__header__right-actions__btn-toggle__text"
            >
              Mochila
            </p>
          </button>
        </div>
      </header>
      <transition name="fade">
        <GLoader
          v-if="!shopReady"
          size="small"
          :frame="true"
        />
        <div
          v-else-if="shopReady"
          :class="[
            'shop-content__body',
            {'--teacher-view': isTeacherTopbarVisible}
          ]"
        >
          <div
            :class="[
              'shop-content__items',
              {'--closeBag': isOpenBag}
            ]"
          >
            <header class="shop-content__items__header">
              <h2 class="shop-content__items__header__text">
                {{ $t('game.shop.signboard') }}
              </h2>
            </header>
            <img
              class="shop-content__items__shelter"
              src="@/assets/shop/shelter.svg"
            >
            <transition
              name="fade"
              mode="out-in"
            >
              <ScrollContainer
                v-if="shopItems.success"
                class="shop-content__items__list"
              >
                <ItemShowcase
                  v-for="(item, index) in allowedItemsToShow"
                  :key="index"
                  :item="item.id"
                  :image="item.image.svg"
                  :name="item.name"
                  :description="item.description"
                  :price="item.price"
                  :min-gems="item.minGems"
                  :amount-limit-per-week="item.amountLimitPerWeek"
                  :amount-available-per-week="item.amountAvailablePerWeek"
                  :buy-item-rules="item.violatedRules"
                  :status="item.statusItem"
                  @buy="addToCart(item)"
                />
              </ScrollContainer>
            </transition>
          </div>
          <div
            :class="[
              'shop-content__bag',
              {'--openBag': isOpenBag}
            ]"
          >
            <header class="shop-content__bag__header">
              <img
                class="shop-content__bag__header__image"
                src="@/assets/bag.svg"
              >
              <div class="shop-content__bag__header__text">
                <h3 class="shop-content__bag__header__text__title">
                  {{ $t('game.bag.label') }}
                </h3>
                <h4 class="shop-content__bag__header__text__subtitle">
                  {{ $t('game.bag.youOwn') }}
                </h4>
              </div>
            </header>
            <div class="shop-content__bag__content">
              <div
                v-if="bagItems.data.length > 0"
                class="shop-content__bag__content__grid"
              >
                <ItemThumbnail
                  v-for="(item, index) in bagItems.data"
                  :key="index"
                  :bag-item="item"
                  :hoverable="false"
                />
              </div>
              <EmptyBag v-else />
            </div>
          </div>
          <img
            class="shop-content__body__char"
            src="@/assets/char/char-shop.svg"
          >
        </div>
        <ShopError
          v-else
          :is-small-screen="isSmallScreen"
          @tryAgain="refresh"
        />
      </transition>
      <ConfirmPurchase
        v-if="selectedItem"
        ref="confirmPurchase"
        :item="selectedItem"
        :buying="buyingLoader"
        :error="buyingError"
        @ok="buyItem(selectedItem)"
        @cancel="closeConfirmation"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { trackSoundPreferenceEvent, trackMusicPreferenceEvent } from '@/components/UserConfig/events'
import { playSoundFX, playBackground, pauseBackground } from '@/utils/soundEffects'
import { effectsNames, backgroundNames } from '@/service/soundController/loadSounds'
import userIsStudent from '@/mixins/userIsStudent'
import gradeId from '@/mixins/gradeId'
import GLoader from '@/components/GLoader'
import GButton from '@/components/GButton'
import WalletDisplay from '@/components/WalletDisplay'
import SoundConfig from '@/components/UserConfig/UserConfig'
import ItemThumbnail from '@/components/Item/ItemThumbnail/ItemThumbnail'
import ItemShowcase from '@/components/Item/ItemShowcase/ItemShowcase'
import EmptyBag from '@/components/Bag/partials/EmptyBag/EmptyBag'
import ScrollContainer from '@/components/ScrollContainer'
import shop from '@/service/shop'
import mediaQueries from '@/mixins/mediaQueries'
import ConfigControl from '@/components/UserConfig/ConfigControl'
import TeacherTopbar from '../../components/TeacherTopbar'
import ConfirmPurchase from './partials/ConfirmPurchase'
import ShopError from './partials/ShopError'

export default {
  name: 'Shop',
  components: {
    GLoader,
    GButton,
    WalletDisplay,
    ItemThumbnail,
    ItemShowcase,
    ConfirmPurchase,
    ShopError,
    EmptyBag,
    SoundConfig,
    ConfigControl,
    TeacherTopbar,
    ScrollContainer,
  },
  mixins: [ userIsStudent, gradeId, mediaQueries ],
  data() {
    return {
      shopReady: false,
      selectedItem: null,
      buyingLoader: false,
      buyingError: false,
      error: false,
      isOpenBag: false,
    }
  },
  computed: {
    ...mapGetters([
      'soundPreferences',
      'userGameData',
      'shopItems',
      'bagItems',
      'user',
      'userGrades',
    ]),
    enableBackButton() {
      return !this.$route.params.history
    },
    userCoins() {
      return this.userGameData.data?.coin?.quantity || 0
    },
    userGems() {
      return this.userGameData.data?.gems
    },
    allowedItemsToShow() {
      return this.shopItems.data.filter(
        (item) => item.statusItem !== 'NOT_AVAILABLE'
      )
    },
    gradeId() {
      const teacherGradeId = parseInt(this.$route.query.gradeId, 10)
      if (this.user.data?.userIsStudent) {
        return null
      }

      return teacherGradeId
    },
    isTeacherTopbarVisible() {
      return this.userGrades.data?.length && !this.userIsStudent
    },
    isSmallScreen() {
      return this.mq_s
    },
  },
  created() {
    pauseBackground()
    playBackground(backgroundNames.SHOP_BACKGROUND)
    this.$prependTitle(`${this.$t('game.shop.signboard')} | `)
    this.$trackEvent({
      category: this.$track.category.eureka,
      action: this.$track.action.enter,
      label: this.$track.label.shop,
    })
    this.refresh()
  },
  beforeDestroy() {
    this.resetBagItems()
  },
  updated() {
    if (!this.mq_s) this.isOpenBag = false
  },
  methods: {
    ...mapActions([
      'getShopItems',
      'getBagItems',
      'getUserGameData',
      'resetBagItems',
      'setSoundPreferences',
    ]),
    refresh() {
      Promise.all([
        this.getShopItems({ gradeId: this.gradeId }),
        this.getBagItems(),
      ])
        .then(() => {
          this.$prependTitle(`${this.$t('game.shop.signboard')} | `)
          this.shopReady = true
        })
        .catch((error) => {
          this.$prependTitle(`${this.$t('commons.error')} | `)
          console.error(error)
        })
    },
    goBack() {
      this.$trackEvent({
        category: this.$track.category.relicsMarket,
        action: this.$track.action.goback,
      })
      playSoundFX()
      pauseBackground()
      this.$trackEvent({
        category: this.$track.category.eureka,
        action: this.$track.action.leave,
        label: this.$track.label.shop,
      })
      this.$router.back()
    },
    async buyItem(item) {
      try {
        this.buyingLoader = true
        await shop.buyItem({ itemId: item.id, gradeId: this.gradeId })
        this.buyingLoader = false
        const itemName = this.$t(`game.items.${item.id}.name`)
        this.$toasted.global.success({
          title: this.$t('game.shop.success', { itemName }),
        })
        playSoundFX(effectsNames.BUY_ITEM)
        this.refresh()
        this.fetchUserGameData()
        this.$trackEvent({
          category: this.$track.category.relicsMarket,
          action: this.$track.action.buyItem,
          label: this.$track.label[item.id],
        })
        this.selectedItem = null
      } catch (error) {
        this.buyingLoader = false
        this.buyingError = true
      }
    },
    closeConfirmation() {
      playSoundFX()
      this.selectedItem = null
      this.buyingError = false
    },
    addToCart(item) {
      playSoundFX()
      this.selectedItem = item
    },
    toggleSounds({ value }) {
      trackSoundPreferenceEvent(this, value)
      this.setSoundPreferences({ sound: value })
    },
    handleToggle() {
      this.isOpenBag = !this.isOpenBag
    },
    toggleMusic({ value }) {
      trackMusicPreferenceEvent(this, value)
      this.setSoundPreferences({ music: value })
      if (value) {
        playBackground(backgroundNames.SHOP_BACKGROUND)
      }
    },
    fetchUserGameData() {
      let params

      if (!this.userIsStudent) {
        params = this.gradeId
      }

      this.getUserGameData(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.shop-teacher-topbar {
  position: fixed;
  top: 0;
  z-index: 100;
}

.shop-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #A2DCED;
  background-image: url(~@/assets/shop/background.svg);
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    padding: $size-m $size-l 0;
    margin-bottom: 20vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq_xl__mf {
      margin-bottom: 15vh;
    }

    &.--teacher-view {
      margin-top: 54px;
      @include mq_s {
        margin-top: 10px;
      }
    }

    &.--has-back-button {
      justify-content: space-between;
    }

      &__title {
        &__sound-config{
          @include mq_s {
            justify-self: flex-end;
          }
        }
        &__icon {
          @include mq_s {
            width: 35px;
          }
        }
        &__text{
          @include mq_s {
            display: inline-block;
            font-family: Rowdies;
            font-weight: $font-weight-regular;
            font-size: 1.5rem;
            line-height: $line-height-heading;
            color: $color-ink;
            vertical-align: baseline;
          }
        }
        @include mq_s {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 6fr 1fr;
        align-items: center;
        }
      }
    &__right-actions {
      @include flex-center;
      @include mq_s {
        padding: 16px 0 24px;
        &__money {
          margin-left: $size-xs;
        }
        &__btn-toggle {
          background: unset;
          border: 0;
          display: flex;
          align-items: center;
          margin-left: $size-s;
          &__image{
            width: 35px;
          }
          &__text{
            font-size: $font-size-heading-5;
            font-family: Rowdies;
            line-height: $line-height-reset;
            margin-left: $size-xs;
          }
        }
      }
    }
    @include mq_s{
      padding: $size-m $size-xs 0;
      background: #FBF1E1;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: $shadow-s rgb(193, 146, 103);
      margin-bottom: 10vh;
    }
  }

  &__body {
    --items-padding-bottom: 2vh;
    position: relative;
    height: calc(100vh - 238px);
    padding-left: 6rem;
    display: flex;
    justify-content: center;

    @include mq_s {
      padding-left: 0;
      width: 200vw;
      height: calc(100vh - 260px);
      margin-bottom: 76px;
    }
    &.--teacher-view {
      --items-padding-bottom: 6vh;
    }

    &__char {
      display: none;
      @include mq-media--mf(1366px) {
        display: block;
        position: absolute;
        z-index: 99;
        bottom: 0;
        transform: translateX(-600px);
      }
    }
  }

  .item-showcase {
    @include space-stack(16px);
  }

  &__items {
    position: relative;
    width: 690px;
    background-color: #D0A96F;
    border: 10px solid #FAE199;
    border-bottom: none;
    box-shadow: inset 0 6px 0 6px #C78D12, 0 13px 20px rgba(98, 73, 36, 0.31);
    padding: 12vh 40px var(--items-padding-bottom) 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    z-index: 2;

    @include mq_s{
      width: 50%;
      padding: 4vh 3vh var(--items-padding-bottom);
      --scrollbar-width: 0;
      transition: transform 0.3s ease-in;
    }

    &__shelter {
      display: block;
      position: absolute;
      top: -2rem;
      width: 108%;
      min-width: 738px;

      @media screen and (max-width: 991px) {
        min-width: 560px;
      }
    }

    &__list {
      padding-right: 1rem;
      @include mq_s{
        padding-right: 0;
        padding-bottom: 7vh;
      }
    }

    &__header {
      @include mq_s {
      display: none;
      }
      position: absolute;
      width: 404px;
      height: 142px;
      padding: 26px;
      left: 72px;
      top: -160px;
      background-image: url(~@/assets/shop/signboard.svg);

      &__text {
        font-weight: bold;
        font-size: 2rem;
        line-height: 1;
        text-align: center;
      }
    }
  }

  &__bag {
    width: 270px;
    margin-top: min(5%, 2rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
    z-index: 1;

    @include mq_xl__mf {
      margin-top: 5.5rem;
    }
    @include mq_s {
      display: flex;
      width: 50%;
      transition: transform 0.3s ease-in-out;
    }
    &__header {
      position: relative;
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
      background-color: #FBF1E1;
      border-top-left-radius: 24px;
      box-shadow: 0 4px 0 #EECE8A, 0 5px 1px rgba(144, 111, 26, 0.42);
      z-index: 2;

      @include mq_m__mf {
        border-top-left-radius: 0;
        border-top-right-radius: 24px;
      }

      &__image {
        width: 36px;
        margin: 0 0.5rem;

        @include mq_xl__mf {
          width: 42px;
          margin: 0 1rem 0 1rem;
        }
      }

      &__text {
        width: 100%;

        &__title {
          font-weight: bold;
          font-size: 1.2rem;
          line-height: 1;
          margin-bottom: 0.2rem;
        }

        &__subtitle {
          color: transparentize($game-ink, 0.3);
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
    }

    &__content {
      width: 100%;
      background-color: #F2E9D0;
      flex-grow: 1;
      padding: $size-m 0.5rem 72px 0;
      position: relative;
      z-index: 1;

      &__grid {
        height: 85%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(auto, 100px));
        grid-auto-rows: 100px;
        gap: $size-s;
        justify-content: center;
        overflow: auto;
      }
    }
  }
}
.--openBag {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in;
}
.--closeBag {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}
.g-loader {
  @include float-center;
}
</style>
