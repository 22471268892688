<template>
  <ScrollContainer
    :class="[
      'insignia-tab',
      { '--error' : insignias.error }
    ]"
  >
    <ErrorMessage
      v-if="insignias.error"
      image-name="refresh.svg"
      message="Não foi possível carregar a listagem de insígnias"
      action="Recarregar"
      @click="fetchInsignias"
    />
    <div
      v-else
      class="insignia-tab__content"
    >
      <template v-if="insignias.loading">
        <InsigniasSkeleton
          v-for="(item, index) in 4"
          :key="index"
          class="insignia-card"
        />
      </template>
      <template v-else>
        <div
          v-for="insignia in insignias.data"
          :key="insignia.id"
          class="insignia-card"
        >
          <img
            class="insignia-card__image"
            :src="insignia.image.png || insignia.image.svg"
            alt=""
          >
          <div class="insignia-card__info">
            <h1 class="insignia-card__info__title">
              {{ insignia.title }}
            </h1>
            <p class="insignia-card__info__description">
              {{ insignia.subtitle }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </ScrollContainer>
</template>

<script>
import { mapGetters } from 'vuex'

import gradeId from '@/mixins/gradeId'
import { getInsignias } from '@/service/profiles'
import ScrollContainer from '@/components/ScrollContainer'
import ErrorMessage from '@/components/ErrorMessage'
import InsigniasSkeleton from './InsigniasSkeleton'

export default {
  name: 'InsigniasTab',
  components: {
    InsigniasSkeleton,
    ScrollContainer,
    ErrorMessage,
  },
  mixins: [ gradeId ],
  data() {
    return {
      insignias: {
        loading: null,
        error: null,
        data: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'lectures',
      'userStats',
    ]),
  },
  watch: {
    gradeId(newGradeId, oldGradeId) {
      if (newGradeId !== oldGradeId) {
        this.fetchInsignias()
      }
    },
  },
  created() {
    this.fetchInsignias()
  },
  methods: {
    async fetchInsignias() {
      const params = {}

      this.insignias.error = null
      this.insignias.loading = true

      if (this.gradeId) {
        params.gradeId = this.gradeId
      }

      try {
        const { data } = await getInsignias(params)
        this.insignias.data = data
      } catch (error) {
        this.insignias.error = error
      } finally {
        this.insignias.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.insignia-tab {
  &.--error {
    @include flex-center;
  }

  &__content {
    width: 100%;
    padding: 2rem 3rem 1.5rem 3rem;

    @media screen and (max-width: 579px) {
      padding: 2rem 1rem 1.5rem 1rem;
    }
  }

  ::v-deep .error-message {
    &__description {
      width: 64%;
    }
  }
}

.insignia-card {
  margin: 0 {
    right: 1rem;
    bottom: 1rem;
  }
  width: 100%;
  padding: .75rem 1rem;
  display: flex;
  align-items: center;
  background-color: $eureka-color-base-light;
  box-shadow: 0 4px 4px #C19267, 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 1rem;

  &__image {
    margin-right: 1rem;
  }

  &__info {
    color: $eureka-color-ink;

    &__title {
      font: {
        family: Rowdies;
        weight: $font-weight-regular;
        size: 1.25rem;
      }
    }

    &__description {
      font: {
        family: Lato;
        weight: $font-weight-bold;
        size: 1rem;
        opacity: 0.75;
      }
    }
  }
}
</style>
