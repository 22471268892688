<template>
  <ButtonControl
    v-tooltip.top="tooltipMessage"
    class="worlds-view-toggle"
    :icon="viewIcon"
    @click="$emit('click')"
  />
</template>

<script>
import ButtonControl from '@/components/ButtonControl'
import carousel from '@/assets/carousel.svg'
import grid from '@/assets/grid.svg'

export default {
  name: 'WorldsViewToggle',
  components: { ButtonControl },
  props: {
    toggleType: {
      type: String,
      default: 'carousel',
      validator: (value) => [ 'carousel', 'grid' ].includes(value),
    },
  },
  computed: {
    tooltipMessage() {
      const { toggleType } = this

      switch (toggleType) {
        case 'carousel':
          return 'Exibir mundos em carrossel'

        case 'grid':
          return 'Exibir mundos em grade'

        default:
          return ''
      }
    },
    viewIcon() {
      return this.toggleType === 'carousel' ? carousel : grid
    },
  },
}
</script>
