export default {
  computed: {
    isXSmallBreakpoint() {
      return this.$mq === 'x_small'
    },
    isSmallBreakpoint() {
      return this.$mq === 'small'
    },
    isMediumBreakpoint() {
      return this.$mq === 'medium'
    },
    isLargeBreakpoint() {
      return this.$mq === 'large'
    },
    isXLargeBreakpoint() {
      return this.$mq === 'x_large'
    },
    mq_xs() {
      return this.$mq === 'x_small'
    },
    mq_s() {
      return [ 'x_small', 'small' ].includes(this.$mq)
    },
    mq_m() {
      return [ 'x_small', 'small', 'medium' ].includes(this.$mq)
    },
    mq_l() {
      return [ 'x_small', 'small', 'medium', 'large' ].includes(this.$mq)
    },
    mq_xl() {
      return [ 'x_small', 'small', 'medium', 'large', 'x_large' ].includes(
        this.$mq
      )
    },
    mq_s__mf() {
      return [ 'small', 'medium', 'large', 'x_large' ].includes(this.$mq)
    },
    mq_m__mf() {
      return [ 'medium', 'large', 'x_large' ].includes(this.$mq)
    },
    mq_l__mf() {
      return [ 'large', 'x_large' ].includes(this.$mq)
    },
    mq_xl__mf() {
      return [ 'x_large' ].includes(
        this.$mq
      )
    },
  },
}
