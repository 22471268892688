var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'g-block',
    `--${_vm.size}`,
    `--${_vm.theme}`,
    `--${_vm.variation}`,
    {
      '--clickable': _vm.clickable,
      '--has-image': _vm.hasImage
    },
  ],style:({
    fontSize: _vm.fontSize,
  })},[(_vm.asset)?_c('img',{class:[
      'g-block__image',
      `--${_vm.size}`,
    ],attrs:{"src":require(`@/assets/${_vm.asset}`)}}):(_vm.image)?_c('img',{class:[
      'g-block__image',
      `--${_vm.size}`,
    ],attrs:{"src":_vm.image}}):_vm._e(),_c('div',{staticClass:"g-block__text"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }