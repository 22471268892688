import { render, staticRenderFns } from "./WorldsListItem.vue?vue&type=template&id=0540ff5c&scoped=true"
import script from "./WorldsListItem.vue?vue&type=script&lang=js"
export * from "./WorldsListItem.vue?vue&type=script&lang=js"
import style0 from "./WorldsListItem.vue?vue&type=style&index=0&id=0540ff5c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0540ff5c",
  null
  
)

export default component.exports