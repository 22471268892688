<template>
  <MagnaMissionDetailLayout
    title="Adicionar questões do banco SAS Educação"
    subtitle="Selecione quantas questões desejar para criar sua missão"
    is-edit-button-hidden
    is-show-back-button
    :is-allowed-finish-selection="isAllowedFinishSelection"
    @on-go-back="backButtonClicked"
    @finishSelection="finishSelection"
  >
    <template
      v-if="isMobile"
      #headerAction
    >
      <span :class="$style.description">
        {{ questionsCount }} questões selecionadas
      </span>
    </template>
    <template
      v-else
      #headerAction
    >
      <div :class="$style.headerAction">
        <span :class="$style.description">
          {{ questionsCount }} questões selecionadas
        </span>
        <GButton
          size="large"
          variation="primary"
          no-border
          rowdies
          :disabled="!isAllowedFinishSelection"
          class="save-btn-mobile"
          @click="finishSelection"
        >
          Concluir seleção
        </GButton>
      </div>
    </template>
    <div :class="$style.main">
      <div
        v-if="isMobile"
        :class="$style.textContainer"
      >
        <div :class="$style.titleContainer">
          <h1
            :class="$style.mobileTitle"
            :style="hideBigTitle"
          >
            Adicionar questões do banco SAS Educação
          </h1>
        </div>
        <h2 :class="$style.mobileSubtitle">
          Selecione quantas questões desejar para criar sua missão
        </h2>
        <span :class="$style.description">
          {{ questionsCount }} questões selecionadas
        </span>
      </div>
      <MagnaMissionQuestionSearchFilters
        :questions="mappedQuestions"
        @on-change="setFilterParams"
      />
      <div
        v-if="hasQuestions"
        :class="[$style.content, { [$style.tabletContent]: isTablet }]"
      >
        <MagnaMissionSASQuestionsList
          :questions="mappedQuestions"
          @on-set-preview-question="setPreviewQuestion"
          @scroll-page="addQuestionPage"
        />
        <MagnaMissionContentPreviewSkeleton
          v-if="questionsData.isLoading && !questionsData.initialized"
        />
        <MagnaMissionContentPreview
          v-else
          :is-loading="questionsData.isLoading"
          :question="selectedQuestionPreview"
          :is-first-question="isQuestionPreviewFirstElement"
          :is-last-question="isQuestionPreviewLastElement"
          :is-selected="selectedQuestionPreview.isSelected"
          @on-select-question="setSelectedQuestion"
          @on-remove-question="setRemoveQuestion"
          @on-prev-question="setPreviousQuestion"
          @on-next-question="setNextQuestionPreview"
        />
        <footer
          v-if="isMobile"
          :class="$style.footerMobile"
        >
          <GButton
            icon="arrow-left"
            size="medium"
            :icon-stroke="5"
            variation="primary"
            no-border
            :disabled="isQuestionPreviewFirstElement"
            @click="setPreviousQuestion"
          />
          <GButton
            v-if="!selectedQuestionPreview.isSelected"
            size="medium"
            variation="primary"
            no-border
            rowdies
            @click="setSelectedQuestion(selectedQuestionPreview)"
          >
            Selecionar questão
          </GButton>
          <GButton
            v-else
            size="medium"
            variation="danger"
            no-border
            rowdies
            @click="setRemoveQuestion(selectedQuestionPreview)"
          >
            Remover Questão
          </GButton>
          <GButton
            icon="arrow-right"
            size="medium"
            :icon-stroke="5"
            variation="primary"
            no-border
            :disabled="isQuestionPreviewLastElement"
            @click="setNextQuestionPreview"
          />
        </footer>
      </div>
      <div
        v-else
        :class="$style.emptyState"
      >
        <EmptyState v-bind="emptyState" />
      </div>
    </div>
    <GoBackModal
      v-if="showBackModal"
      @cancel="hideModal"
      @back="backMission"
    />
  </MagnaMissionDetailLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmpty, some, findIndex } from 'lodash-es'

import userAgent from '@/utils/userAgent'
import arenaApi from '@/service/arena'
import MagnaMissionDetailLayout from '@/views/AncestralArena/layouts/MagnaMissionDetailLayout'
import GButton from '@/components/GButton'
import EmptyState from '@/components/EmptyState'
import cora from '@/assets/char/cora/cora-sad.svg'
import mediaQueries from '@/mixins/mediaQueries'
import GoBackModal from './partials/GoBackModal'
import MagnaMissionQuestionSearchFilters from './partials/MagnaMissionQuestionSearchFilters/MagnaMissionQuestionSearchFilters'
import MagnaMissionSASQuestionsList from './partials/MagnaMissionSASQuestionsList/MagnaMissionSASQuestionsList'
import MagnaMissionContentPreview from './partials/MagnaMissionContentPreview/MagnaMissionContentPreview'
import MagnaMissionContentPreviewSkeleton from './partials/MagnaMissionContentPreview/MagnaMissionContentPreviewSkeleton'

export default {
  name: 'MagnaMissionQuestionSearch',
  components: {
    GButton,
    MagnaMissionDetailLayout,
    MagnaMissionQuestionSearchFilters,
    MagnaMissionSASQuestionsList,
    MagnaMissionContentPreview,
    MagnaMissionContentPreviewSkeleton,
    EmptyState,
    GoBackModal,
  },
  mixins: [ mediaQueries ],
  beforeRouteEnter(to, from, next) {
    if (from.name === 'magna-mission-create-edit') {
      next()

      return
    }

    next((it) => it.goBack())
  },
  props: {
    goBackRouteName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBackModal: false,
      filterParams: {},
      selectedQuestionPreviewCode: null,
      selectedQuestions: [],
      hasUsedSubject: false,

      questionsData: {
        initialized: false,
        pageNumber: 1,
        isLoading: false,
        data: null,
        error: null,
      },
    }
  },
  computed: {
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
    isTablet() {
      return userAgent.isTablet()
    },
    isAllowedFinishSelection() {
      return this.questionsCount > 0
    },
    questionsCount() {
      return this.selectedQuestions?.length ?? 0
    },
    mappedQuestions() {
      const list = this.questionsData?.data?.list.map((question) => {
        const isSelected = some(this.selectedQuestions, { id: question.id })
        const indexSelected = findIndex(this.selectedQuestions, {
          id: question.id,
        })
        const isFocused = this.selectedQuestionPreviewCode === question.id

        return {
          ...question,
          isSelected,
          isFocused,
          indexSelected: indexSelected >= 0 ? indexSelected : null,
        }
      }) ?? []

      return {
        ...this.questionsData,
        data: { ...this.questionsData.data, list },
      }
    },
    selectedQuestionPreview() {
      const firstQuestion = this.mappedQuestions.data.list[0]

      const preview = this.mappedQuestions.data.list?.find(
        (question) => question?.id === this.selectedQuestionPreviewCode
      )

      if (!isEmpty(preview)) {
        return preview
      }

      return firstQuestion
    },
    hasQuestions() {
      return (
        this.mappedQuestions.initialized
        && !isEmpty(this.questionsData.data?.list)
      )
    },
    emptyState() {
      return {
        title: 'Que embaraçoso!',
        subtitle:
          'Não encontramos questões para esses filtros. Tente novamente com filtros diferentes.',
        illustration: {
          path: cora,
          alt: '',
        },
      }
    },
    pageParams() {
      return {
        pageNumber: this.questionsData.pageNumber,
        pageSize: 10,
      }
    },
    questionPreviewIndex() {
      return findIndex(this.mappedQuestions.data.list, {
        id: this.selectedQuestionPreview.id,
      })
    },
    isQuestionPreviewFirstElement() {
      return this.questionPreviewIndex === 0
    },
    isQuestionPreviewLastElement() {
      return (
        this.questionPreviewIndex === this.mappedQuestions.data.list.length - 1
      )
    },
    isAllowedGetQuestions() {
      return this.mappedQuestions.data.totalPages >= this.pageParams.pageNumber
    },
  },
  watch: {
    filterParams: {
      handler() {
        this.resetPageNumber()
        this.getQuestions()
        this.resetQuestionsListPreviewScroll()
      },
      deep: true,
    },
    pageParams: {
      handler() {
        this.getQuestions({ addPageParams: true })
      },
    },
  },
  created() {
    this.getQuestions()
  },
  methods: {
    ...mapActions([ 'setSelectedSasQuestions' ]),
    resetQuestionsListPreviewScroll() {
      const el = document.getElementById('questionCardList')
      if (el) {
        el.scrollTop = 0
      }
    },
    resetPageNumber() {
      this.questionsData.pageNumber = 1
    },
    finishSelection() {
      this.setSelectedSasQuestions(this.selectedQuestions)

      const label = this.hasUsedSubject
        ? this.$track.label.usedSubjects
        : this.$track.label.notUsedSubjects

      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.teacherConcludedSASQuestionSelection,
        label,
      })

      this.$router.replace({
        name: 'magna-mission-create-edit',
        params: {
          ...this.$route.params,
          questionnaireCode: this.$route.params?.questionnaireCode,
        },
      })
      this.initialState()
    },
    initialState() {
      this.selectedQuestions = []
      this.filterParams = {}
      this.selectedQuestionPreviewCode = null
      this.selectedQuestions = []
      this.questionsData = {
        initialized: false,
        pageNumber: 1,
        isLoading: false,
        data: null,
        error: null,
      }
    },
    onScrollToCard(questionId) {
      const query = `[data-card-id="${questionId}"]`
      document.querySelector(query)?.scrollIntoView({ behavior: 'smooth' })
    },
    setPreviewQuestion(question) {
      this.selectedQuestionPreviewCode = question.id
    },
    setSelectedQuestion(question) {
      const questionFinded = this.mappedQuestions.data.list.find(
        (selectedQuestion) => selectedQuestion.id === question.id
      )
      this.selectedQuestions.push(questionFinded)
    },
    setRemoveQuestion(question) {
      const index = findIndex(this.selectedQuestions, { id: question.id })
      if (index >= 0) {
        this.$delete(this.selectedQuestions, index)
      }
    },
    setPreviousQuestion() {
      if (this.isQuestionPreviewFirstElement) return

      const questionId = this.mappedQuestions.data.list[this.questionPreviewIndex - 1].id

      this.selectedQuestionPreviewCode = questionId
      this.onScrollToCard(questionId)
    },
    setNextQuestionPreview() {
      if (this.isQuestionPreviewLastElement) return

      const questionId = this.mappedQuestions.data.list[this.questionPreviewIndex + 1].id

      this.selectedQuestionPreviewCode = questionId
      this.onScrollToCard(questionId)
    },
    setFilterParams(params) {
      if (!isEmpty(params.contentIds)) this.hasUsedSubject = true
      this.filterParams = params
    },
    addQuestionPage() {
      this.questionsData.pageNumber += 1
    },
    updateQuestionPreviewCode() {
      const isEmptyCode = isEmpty(this.selectedQuestionPreviewCode)
      const hasNoSelectedCode = !some(this.mappedQuestions.data.list, {
        id: this.selectedQuestionPreviewCode,
      })

      if (isEmptyCode || hasNoSelectedCode) {
        this.selectedQuestionPreviewCode = this.mappedQuestions.data?.list[0].id
      }
    },
    async getQuestions({ addPageParams } = { addPageParams: false }) {
      if (this.questionsData.isLoading && !this.questionsData.initialized) return
      if (addPageParams && !this.isAllowedGetQuestions) return

      this.questionsData.isLoading = true

      const params = { ...this.filterParams, ...this.pageParams }
      try {
        const { data } = await arenaApi.getQuestions(params)
        this.questionsData.error = null
        if (addPageParams) {
          this.questionsData.data.list.push(...data.list)
        } else {
          this.questionsData.data = data
          this.updateQuestionPreviewCode()
        }
      } catch (error) {
        this.questionsData.data = null
        this.questionsData.error = error
      } finally {
        this.questionsData.initialized = true
        this.questionsData.isLoading = false
      }
    },
    hideModal() {
      this.showBackModal = false
    },
    goBack() {
      this.initialState()
      this.$router.replace({
        name: 'magna-mission-create-edit',
        params: {
          ...this.$route.params,
          questionnaireCode: this.$route.params?.questionnaireCode,
        },
      })
    },
    backButtonClicked() {
      if (this.questionsCount < 1) {
        this.goBack()

        return
      }
      this.showBackModal = true
    },
    backMission() {
      this.showBackModal = false
      this.goBack()
    },
  },
}
</script>

<style lang="scss" module>
.main {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: calc(100vh - 120px);

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 991px) {
    overflow-y: auto;
    height: 100vh;
  }
}

.textContainer {
  padding: $size-m $size-m $size-s;
  gap: $size-xs;
  display: flex;
  flex-direction: column;
  background-color: $eureka-color-base;
  border-bottom: 1px solid rgba($eureka-color-ink, 0.2);
}

.titleContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  margin-bottom: $size-xs;
}

.mobileTitle {
  font-family: Rowdies;
  font-size: $font-size-heading-5;
  font-weight: $font-weight-regular;
  line-height: $line-height-heading;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100% !important;
}

.mobileSubtitle {
  font-family: Lato;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  line-height: $line-height-input;
  letter-spacing: 0.4px;
}

.skeletonContainer {
  display: flex;
  height: calc(100vh - 120px);
}

.skeletonList {
  display: flex;
  width: calc(100vw - 312px);
  gap: $size-s;
  margin: 1rem;

  div {
    border-radius: $size-s;
    background-color: $eureka-color-base !important;
    &:last-of-type {
      width: 100%;
    }
  }
}

.headerAction {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $size-s;
  align-items: flex-start;
}

.description {
  display: flex;
  align-items: center;
  font-weight: $font-weight-medium;
  height: $size-xl;

  text-align: center;

  @media screen and (max-width: 991px) {
    margin-top: $size-xs;
    font-family: Lato;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    line-height: $line-height-heading;
    letter-spacing: 0.43px;
    text-align: left;
    height: fit-content;
  }
}

.content {
  gap: $size-s;
  padding: $size-s;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  @media screen and (max-width: 1200px) {
    padding: $size-s 10px;
    gap: 10px;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: 100vh;
    padding: 0;
  }
}

.tabletContent {
  max-height: 80vh;
}
.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  :global {
    .empty-state__illustration {
      object-fit: contain;
      display: block;
      height: 50vh;
    }

    .empty-state__content {
      max-width: 400px;
    }
  }
}

.footerMobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: #F6EADA;
  padding: $size-s $size-m $size-m $size-m;
  box-shadow: 0px 3px 0px 0px #C2AB8C;
}

::v-deep .save-btn-mobile {
  height: 36px;
  box-shadow: 0 3px 0 #848484;

  &:hover {
    box-shadow: 0 5px 0 #848484;
  }

  &:disabled {
    background: #666e75;
    border: 5px solid #666e75;
    box-shadow: 0 3px 0 #848484;
  }
}
</style>
