var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'thumbnail',
    {'--selected': _vm.isSelected},
    {'--opaque': _vm.isNewThumbnail}
  ],attrs:{"role":"button","aria-label":"Selecionar thumbnail"},on:{"click":_vm.callSelect}},[_c('header',{staticClass:"thumbnail__header"},[_c('div',{staticClass:"thumbnail__header__options"},[_c('strong',{class:['thumbnail__order', {'--selected': _vm.isSelected}]},[_vm._v(" "+_vm._s(_vm.order)+" ")]),(_vm.optionsIsVisible && _vm.isOrderable)?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.arrowUpTooltip),expression:"arrowUpTooltip",modifiers:{"bottom":true}}],class:[
            'icon-btn',
            'arrow-up',
            {'--disabled': _vm.isFirst}
          ],attrs:{"role":"button","data-function":"reorder","aria-label":"ordenar para cima","disabled":_vm.isFirst,"aria-disabled":_vm.isFirst},on:{"click":function($event){return _vm.$emit('order-up')}}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.arrowDownTooltip),expression:"arrowDownTooltip",modifiers:{"bottom":true}}],class:[
            'icon-btn',
            'arrow-down',
            {'--disabled': _vm.isLast}
          ],attrs:{"role":"button","aria-label":"ordenar para baixo","data-function":"reorder","disabled":_vm.isLast,"aria-disabled":_vm.isLast},on:{"click":function($event){return _vm.$emit('order-down')}}})]:_vm._e()],2),_c('div',[(_vm.optionsIsVisible && _vm.isDuplicable)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Duplicar'),expression:"'Duplicar'",modifiers:{"bottom":true}}],staticClass:"icon-btn copy",attrs:{"role":"button","aria-label":"duplicar"}}):_vm._e(),(_vm.optionsIsVisible && _vm.isRemovable)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Remover'),expression:"'Remover'",modifiers:{"bottom":true}}],staticClass:"icon-btn trash",attrs:{"role":"button","aria-label":"remover","data-function":"remove"},on:{"click":function($event){return _vm.$emit('delete-thumb')}}}):_vm._e()])]),_c('div',{staticClass:"thumbnail__body"},[(_vm.isNewThumbnail)?_c('GButton',{staticClass:"thumbnail__body__btn",attrs:{"variation":"secondary","size":"small","icon-left":"plus","icon-stroke":4},on:{"click":_vm.addNewQuestion}},[_vm._v(" Adicionar Questão ")]):_c('p',{class:[
        'thumbnail__text',
        {'--centered --opaque': _vm.isEmptyThumbnail}
      ]},[_vm._v(" "+_vm._s(_vm.thumbnailText)+" ")])],1),(_vm.hasError)?_c('AncestralArenaErrorReport',{staticClass:"thumbnail-error",attrs:{"icon-only":"","aria-label":"mensagem de erro"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }