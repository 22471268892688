var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{class:[
    'mission-feedback',
    {
      '--right': _vm.correct,
      '--wrong': !_vm.correct,
    },
  ]},[(_vm.finishError)?_c('g-alert',{attrs:{"variation":"error","message":_vm.$t('game.error.answer.message')}}):_vm._e(),_c('div',{staticClass:"mission-feedback__icon"},[(_vm.correct)?_c('img',{attrs:{"alt":"","src":require("@/assets/feedback/right-badge.svg")}}):_c('img',{attrs:{"alt":"","src":require("@/assets/feedback/wrong-badge.svg")}})]),_c('div',{staticClass:"mission-feedback__text"},[_c('h4',[_vm._v(" "+_vm._s(_vm.correct ? _vm.$t('questions.actions.correct') : _vm.$t('questions.actions.incorrect'))+" ")]),(_vm.xp > 0 && _vm.correct)?[_c('g-block',{attrs:{"asset":"xp.svg","size":"small","theme":"bright"}},[_vm._v(" "+_vm._s(`+${_vm.xp}`)+" ")])]:_vm._e()],2),_c('g-button',{attrs:{"variation":"secondary","size":"large","icon-left":"book-open"},on:{"click":function($event){return _vm.$emit('showExplanation')}}},[_vm._v(" "+_vm._s(_vm.$t('commons.explanation'))+" ")]),(_vm.canBeFinished)?_c('g-button',{attrs:{"variation":"primary","size":"large","loading":_vm.finishingAssignment},on:{"click":_vm.finishMission}},[_vm._v(" "+_vm._s(_vm.$t('questions.actions.finish'))+" ")]):_c('g-button',{attrs:{"variation":"primary","size":"large"},on:{"click":function($event){return _vm.$emit('nextEnigma')}}},[_vm._v(" "+_vm._s(_vm.$t('commons.next.male'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }