<template>
  <div class="magna-mission-room">
    <AncestralArenaEnigma
      v-show="!showRankedWhenLoaded"
      :style="styleEnigma"
      :error="isUnknownError"
      :magna-mission-enigma="mappedMagnaMissionEnigma"
      :is-loading="!error && !magnaMission"
      @retry="connect"
      @enigma-position="setCurrentEnigmaPosition"
      @last-question="setLastQuestion"
    >
      <template #enigmaAction>
        <GButton
          class="magna-mission-room__actions-button"
          :asset="timerIcon"
          @click="enigmaAction"
        >
          {{ textButton }}
        </GButton>
      </template>
    </AncestralArenaEnigma>
    <AncestralArenaRanking
      v-show="showRankedWhenLoaded"
      :last-question="isLastQuestion"
      :ranking="ranking"
      :text-button="textButton"
      :click-action="enigmaAction"
      :magna-mission-enigma="mappedMagnaMissionEnigma"
    />
    <InfoMagnaMissionModal
      v-if="isInfoModalVisible"
      title="Não foi possível finalizar a missão."
      description="Ocorreu algum erro com a conclusão dessa missão Magna.
      Feche essa mensagem e tente novamente."
      :has-buttons="false"
      :button-name="$t('commons.close')"
      @close="toggleInfoMagnaMissionModal"
    />
    <InfoMagnaMissionModal
      v-else-if="isModalVisible"
      title="Finalizando a missão"
      description="A seguir você verá o desempenho da turma e
      poderá discutir sobre esse resultado da missão."
      :button-name="$t('commons.finish')"
      has-cancel-button
      @confirm="navigateToReport"
      @close="closeMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import socketClient from '@/client/socketClient'
import assignmentsApi from '@/service/assignments'
import { possibleLetters } from '@/utils/ancestralArenaUtils'
import AncestralArenaEnigma from '@/components/AncestralArena/AncestralArenaEnigma'
import AncestralArenaRanking from '@/components/AncestralArena/AncestralArenaRanking/AncestralArenaRanking'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import GButton from '@/components/GButton'

const statusEnum = {
  IDLE: 'IDLE',
  PAUSED: 'PAUSED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
}

export default {
  name: 'MagnaMissionTeacherRoom',
  components: {
    AncestralArenaEnigma,
    InfoMagnaMissionModal,
    GButton,
    AncestralArenaRanking,
  },
  data() {
    return {
      magnaMission: null,
      magnaMissionEnigma: null,
      challengeRoomsSubscription: null,
      userQueueErrorsSubscription: null,
      challengeRoomsTimerSubscription: null,
      error: null,
      isShowAnswer: null,
      currentEnigmaPosition: null,
      hasTimerStarted: false,
      isLastQuestion: false,
      isModalVisible: false,
      isInfoModalVisible: false,
      time: null,
      timerStatus: null,
      isShowRanking: false,
      isLoadingChallengeRooms: false,
      ranking: null,
    }
  },
  computed: {
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    enigmaCode() {
      return this.$route.query.enigmaCode
    },
    currentQuestionCode() {
      return this.magnaMission?.currentQuestionCode
    },
    currentTime() {
      if (this.time) {
        return this.time
      }

      if (this.magnaMissionEnigma?.formattedTimer) {
        return this.magnaMissionEnigma.formattedTimer
      }

      return '00:00'
    },
    hasTimer() {
      return this.magnaMissionEnigma?.hasTimer ?? false
    },
    isStateStartingTimer() {
      return !this.isShowAnswer && this.hasTimer && !this.hasTimerStarted
    },
    isUnknownError() {
      return this.error && this.error.type === 'unknown'
    },
    timerIcon() {
      return this.isStateStartingTimer ? 'timer.svg' : ''
    },
    mappedMagnaMissionEnigma() {
      return {
        ...this.magnaMissionEnigma,
        options: this.magnaMissionEnigma?.options?.map((option, index) => ({
          ...option,
          index,
          title: option.description,
          letter: possibleLetters.find((_, i) => i === index),
          isCorrectOption: (
            this.isShowAnswer && this.magnaMissionEnigma.answerKey === option.id
          ) || false,
          variation: this.getCardVariation({
            optionId: option.id,
            answerKey: this.magnaMissionEnigma.answerKey,
          }),
        })),
        magnaMissionInfo: {
          enigmaPosition: this.magnaMission?.questions
            .findIndex(({ id }) => id === this.$route.query.enigmaCode) + 1,
          totalEnigmas: this.magnaMission?.questions.length,
        },
      }
    },
    styleEnigma() {
      return this.isShowAnswer ? {
        boxShadow: `0 0 28px 8px ${this.correctOptionColor}`,
      } : {}
    },
    correctOptionColor() {
      const { options } = this.mappedMagnaMissionEnigma
      const correctOption = options.find((option) => option.isCorrectOption)

      return correctOption?.color
    },
    textButton() {
      if (this.isStateStartingTimer) {
        return 'Iniciar tempo de resposta'
      }

      if (this.isShowAnswer && this.hasRanked && !this.isShowRanking) {
        return 'Mostrar classificação'
      }

      if (this.isLastQuestion && this.isShowAnswer) {
        return 'Finalizar Missão'
      }

      if (!this.isShowAnswer) {
        return 'Mostrar resposta'
      }

      return 'Próxima Questão'
    },
    hasRanked() {
      return this.magnaMission?.ranked ?? false
    },
    enigmaPosition() {
      return this.mappedMagnaMissionEnigma?.magnaMissionInfo?.enigmaPosition ?? 0
    },
    showRankedWhenLoaded() {
      return this.hasRanked && this.isShowRanking && !this.isLoadingChallengeRooms
    },
  },
  watch: {
    hasTimer(value) {
      if (!value) {
        this.$emit('update-time', { currentTime: this.time, hasTimer: value, timerStatus: this.timerStatus })
      }
    },
    isShowRanking() {
      this.$emit('is-show-ranking', this.isShowRanking)
    },
    hasTimerStarted(isTimeStarted) {
      if (isTimeStarted) {
        this.timerStatus = statusEnum.IN_PROGRESS
      }
    },
    isShowAnswer(isShowAnswer) {
      if (isShowAnswer) {
        this.hasTimerStarted = false
      }
    },
    currentTime: {
      immediate: true,
      handler(time) {
        const { hasTimer } = this

        if (hasTimer && time === '00:00') {
          this.sendShowAnswer()
          this.timerStatus = statusEnum.FINISHED
        }

        this.$emit('update-time', { currentTime: time, hasTimer, timerStatus: this.timerStatus })
      },
    },
    isStateStartingTimer(value) {
      if (value) {
        this.timerStatus = statusEnum.IDLE
        this.$emit('update-time',
          { currentTime: this.time, hasTimer: this.hasTimer, timerStatus: this.timerStatus })
      } else if (this.isShowAnswer) {
        this.timerStatus = statusEnum.FINISHED
        this.$emit('update-time',
          { currentTime: this.time, hasTimer: this.hasTimer, timerStatus: this.timerStatus })
      }
    },
    challengeRoomsSubscription() {
      this.sendStartMagnaMission()
      this.sendSubscribeMagnaMission()
    },
    magnaMissionEnigma() {
      if (this.magnaMissionEnigma?.showAnswer) {
        this.isShowAnswer = true
      }
    },
    currentQuestionCode: {
      immediate: true,
      handler() {
        this.isShowAnswer = false

        if (this.currentQuestionCode !== this.enigmaCode) {
          this.setEnigmaCode(this.currentQuestionCode)
        }
      },
    },
    magnaMission: {
      immediate: true,
      handler() {
        if (!this.magnaMission) {
          this.setMagnaMission({ loading: true })

          return
        }

        this.setMagnaMission({ data: this.magnaMission })

        this.setMagnaMissionEnigma(this.currentQuestionCode)

        if (this.timerStatus === statusEnum.IDLE || this.timerStatus === null) {
          this.time = this.magnaMissionEnigma?.formattedTimer ?? null
        }

        if (this.currentQuestionCode !== this.enigmaCode) {
          this.setEnigmaCode(this.currentQuestionCode)
        }
      },
    },
    enigmaPosition() {
      this.setEnigmaPosition(this.enigmaPosition)
      this.setCurrentEnigmaPosition(this.enigmaPosition)
    },
  },
  created() {
    window.addEventListener('online', this.handleStatusConnection, false)
    window.addEventListener('offline', this.handleStatusConnection, false)
  },
  mounted() {
    this.connect()
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleStatusConnection)
    window.removeEventListener('offline', this.handleStatusConnection)
  },
  methods: {
    ...mapActions([ 'setMagnaMission', 'setEnigmaPosition' ]),
    connect() {
      try {
        this.clearErrors()
        this.subscribeChallengeRooms()
        this.subscribeQueueErrors()
        this.subscribeChallengeRoomsTimer()
      } catch {
        socketClient.getClient(this.questionnaireCode).onConnect = () => {
          this.clearErrors()
          this.subscribeChallengeRooms()
          this.subscribeQueueErrors()
          this.subscribeChallengeRoomsTimer()
        }
      }
    },
    handleStatusConnection() {
      if (navigator.onLine) {
        this.clearErrors()
      } else {
        this.handleErrors({
          message: this.$t('ancestralArena.magnaMissionRoom.error.network.message'),
          type: 'connection',
          icon: 'wifi-off',
          isCloseable: false,
        })
      }
    },
    handleErrors(error) {
      const isNetworkError = error.type === 'connection'

      if (isNetworkError) {
        this.error = error
        this.$emit('error-ocurred', error)
      } else {
        this.magnaMission = null
        this.setEnigmaPosition(null)
        this.error = { type: 'unknown' }
      }
    },
    clearErrors() {
      if (this.error) {
        this.error = false
        this.$emit('error-resolved')
      }
    },
    setEnigmaCode(current) {
      this.$router.replace({ ...this.$route, query: { enigmaCode: current } })
    },
    updateCurrentQuestionCode(nextQuestionIndex) {
      if (this.magnaMission?.questions && nextQuestionIndex) {
        const { id: nextCurrentQuestionCode } = this.magnaMission.questions[nextQuestionIndex]
        this.magnaMission = {
          ...this.magnaMission,
          currentQuestionCode: nextCurrentQuestionCode,
          currentQuestionAnswersCount: 0,
        }
      }
    },
    subscribeQueueErrors() {
      const topic = '/user/queue/errors'
      const onError = (response) => {
        const { status, message } = JSON.parse(response.body)
        this.error = {
          message: `[${status}] ${message}`,
          type: 'serverError',
          icon: 'alert-circle',
          isCloseable: true,
        }
        this.$emit('error-ocurred', this.error)
        this.error = false
      }

      this.userQueueErrorsSubscription = socketClient.subscribe(topic, onError)
    },
    subscribeChallengeRooms() {
      const topic = `/topic/teacher.challenge-rooms.${this.questionnaireCode}`
      const onSubscribe = ({ body }) => {
        const magnaMission = JSON.parse(body)
        this.magnaMission = magnaMission

        if (magnaMission?.ranking?.visible) {
          this.ranking = magnaMission.ranking?.values ?? null
        }
        this.isLoadingChallengeRooms = false
      }
      this.challengeRoomsSubscription = socketClient.subscribe(topic, onSubscribe)
    },
    subscribeChallengeRoomsTimer() {
      const topic = `/topic/challenge-rooms.${this.questionnaireCode}.timer`
      const onSubscribe = ({ body }) => {
        if (!this.isShowAnswer) {
          const { timer, questionCode } = JSON.parse(body)

          if (questionCode === this.enigmaCode) {
            if (!this.hasTimerStarted) {
              this.hasTimerStarted = true
            }

            this.time = timer
          }
        }
      }
      this.challengeRoomsTimerSubscription = socketClient.subscribe(topic, onSubscribe)
    },
    sendStartMagnaMission() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/start`
      socketClient.emit(destination)
    },
    sendSubscribeMagnaMission() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/subscribe`
      socketClient.emit(destination)
    },
    startTimer() {
      this.hasTimerStarted = true
      const { hasTimer, currentTime } = this
      this.$emit('update-time', { currentTime, hasTimer, timerStatus: statusEnum.IN_PROGRESS })
      this.sendStartTimer()
    },
    sendStartTimer() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/timer`
      socketClient.emit(destination)
    },
    pauseTimer() {
      this.timerStatus = statusEnum.PAUSED
      const { hasTimer, timerStatus, currentTime } = this
      this.$emit('update-time', { currentTime, hasTimer, timerStatus })
      this.timer = null
    },
    sendShowAnswer() {
      this.isShowAnswer = true
      this.pauseTimer()
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/show-answer`
      socketClient.emit(destination, this.currentQuestionCode)
    },
    sendNextQuestion() {
      this.timerStatus = statusEnum.IDLE
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/question`
      socketClient.emit(destination, this.currentQuestionCode)
    },
    sendFinishMission() {
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/finish`

      socketClient.emit(destination)
    },
    sendShowRanking() {
      this.isLoadingChallengeRooms = true
      this.ranking = null
      const destination = `/eureka/teacher/challenge-rooms/${this.questionnaireCode}/ranking`

      socketClient.emit(destination)
    },
    toggleInfoMagnaMissionModal() {
      this.isInfoModalVisible = !this.isInfoModalVisible
    },
    async finishAwayStudentsAssignments() {
      try {
        await assignmentsApi.finishAssignments(this.questionnaireCode)
      } catch {
        this.closeMagnaMissionModal()
        this.toggleInfoMagnaMissionModal()
      }
    },
    navigateToReport() {
      this.finishAwayStudentsAssignments()

      if (!this.isInfoModalVisible) {
        this.setEnigmaPosition(null)
        this.$trackEvent({
          category: this.$track.category.ancestralArena,
          action: this.$track.action.finishMagnaMission,
        })
        this.$router.replace({ name: 'magna-mission-teacher-report' })
      }
    },
    setMagnaMissionEnigma(enigmaCode) {
      const currentEnigma = this.magnaMission?.questions
        .find((enigma) => enigma.id === enigmaCode)

      this.magnaMissionEnigma = currentEnigma
    },
    getCardVariation({ optionId, answerKey }) {
      if (this.isShowAnswer) {
        return optionId === answerKey ? 'secondary' : 'tertiary'
      }

      return 'primary'
    },
    enigmaAction() {
      if (this.isStateStartingTimer) {
        this.startTimer()

        return
      }

      if (this.isShowAnswer && this.hasRanked && !this.isShowRanking) {
        this.sendShowRanking()
        this.isShowRanking = true

        return
      }

      if (this.isLastQuestion && this.isShowAnswer) {
        this.sendFinishMission()
        this.showMagnaMissionModal()

        return
      }

      this.isShowAnswer = !this.isShowAnswer

      if (this.isShowAnswer) {
        this.sendShowAnswer()
      } else {
        this.isShowRanking = false
        this.updateCurrentQuestionCode(this.currentEnigmaPosition)
        this.sendNextQuestion()
      }
    },
    setCurrentEnigmaPosition(position) {
      this.currentEnigmaPosition = position
    },
    setLastQuestion() {
      this.isLastQuestion = true
    },
    showMagnaMissionModal() {
      this.isModalVisible = true
    },
    closeMagnaMissionModal() {
      this.isModalVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>

.magna-mission-room {
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 0 $size-m;
  max-height: calc(100vh - 130px);

  @media screen and (max-width: 768px) {
    padding: 0 $size-s;
    max-height: calc(100vh - 235px);
  }

  ::v-deep .ancestral-arena-enigma__content {
    padding: $size-m;
  }

  &__actions-button{
    ::v-deep &.g-button {
        border: none;
    }

    ::v-deep  .g-button__image {
      filter: brightness(0) invert(1);
    }
  }
}

::v-deep .ancestral-arena-card-question {
  cursor: default;
}

</style>
