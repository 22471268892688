var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission",style:(_vm.missionBackground),attrs:{"data-testid":"mission-screen"}},[_c('StatusConnection'),_c('transition',{attrs:{"name":"header"}},[_c('div',{class:[
        'mission__mission-header',
        { '--compass-animation': _vm.activeTruthCompassAnim },
      ]},[(_vm.isTeacherTopbarVisible)?_c('TeacherTopbar',{attrs:{"selector-is-hidden":""}}):_vm._e(),(_vm.initReady)?_c('MissionHeader',{on:{"quit-mission":_vm.quitMissionClicked}}):_vm._e()],1)]),(!_vm.mq_xl__mf)?_c('div',{staticClass:"header__content"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h3',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]):_vm._e(),_c('div',{staticClass:"mission__body"},[_c('transition',{attrs:{"name":"question-content"}},[(_vm.contentReady)?_c('MissionContent',{ref:"missionContent",attrs:{"selected-option":_vm.selectedOption,"removed-option":_vm.optionToDisable,"ready":_vm.initReady},on:{"optionClicked":_vm.optionClicked}}):_vm._e()],1),_c('transition-group',{staticClass:"quick-actions",attrs:{"tag":"div","name":"quick-actions"}},[(_vm.bagButtonReady && !_vm.isMobile)?_c('BagButton',{key:"bag",class:{
          '--unclickable':
            _vm.question.loading ||
            _vm.question.error ||
            _vm.progress.loading ||
            _vm.feedbackReady,
        },on:{"click":_vm.openBag}}):_vm._e(),_vm._l((_vm.quickItems),function(item){return _c('QuickItemButton',{key:item,attrs:{"item":item},on:{"click":function($event){return _vm.setUseItem(item)}}})})],2),_c('transition',{attrs:{"name":"feedback"}},[_c('MissionFooter',{attrs:{"feedback-is-visible":_vm.feedbackReady,"is-mobile":_vm.isMobile},scopedSlots:_vm._u([{key:"feedback",fn:function(){return [(_vm.feedbackReady)?_c('MissionFeedback',{attrs:{"correct":_vm.answer.data.correct,"can-be-finished":_vm.progress.data.canBeFinished},on:{"nextEnigma":_vm.nextEnigma,"showExplanation":_vm.showExplanation,"finishMission":_vm.showReport}}):(_vm.canBeAnswered)?_c('MissionActions',{on:{"sendAnswer":_vm.handleSendAnswer}}):_vm._e()]},proxy:true},{key:"bag",fn:function(){return [(_vm.bagButtonReady)?_c('BagButton',{key:"bag",class:{
              '--unclickable':
                _vm.question.loading ||
                _vm.question.error ||
                _vm.progress.loading ||
                _vm.feedbackReady,
            },attrs:{"is-mobile":_vm.isMobile},on:{"click":_vm.openBag}}):_vm._e()]},proxy:true}])})],1),_c('transition',{attrs:{"name":"truth-compass"}},[_c('div',{class:[
          'mission__compass-animation',
          {'--max-width': _vm.activeTruthCompassAnim}]},[_c('CompassAnimation',{ref:"truthCompass",attrs:{"active":_vm.activeTruthCompassAnim},on:{"anim-complete":_vm.handleAnimComplete}})],1)])],1),(_vm.showQuitModal)?_c('QuitMissionModal',{on:{"cancel":_vm.cancelQuitMission,"ok":_vm.quitMission}}):_vm._e(),_c('RewardModal',{on:{"close":_vm.handleCloseModal}}),(_vm.bagReady)?_c('Bag',{ref:"bag",attrs:{"waiting-for-item":_vm.waitingForItem,"use-item-error":_vm.useItemError},on:{"update:useItemError":function($event){_vm.useItemError=$event},"update:use-item-error":function($event){_vm.useItemError=$event},"use-item":function($event){return _vm.setUseItem($event)}}}):_vm._e(),_c('WiseScroll',{ref:"wiseScroll",attrs:{"assignment-code":_vm.assignmentCode}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }