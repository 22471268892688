import { mapGetters } from 'vuex'
import getGradeId from './getGradeId'

export default {
  mixins: [ getGradeId ],
  computed: {
    ...mapGetters([ 'user' ]),
    userIsAdapt() {
      if (this.user?.data) {
        const { data } = this.user
        const [ currentGrade ] = data.grades.filter((grade) => grade.id === this.getGradeId())
        try {
          const { eurekaContentYear, contractType } = currentGrade

          if (eurekaContentYear) {
            const currentYear = new Date().getFullYear()

            return contractType === 'ADAPT' && eurekaContentYear === (currentYear - 1)
          }

          return contractType === 'ADAPT'
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error)
        }
      }

      return true
    },
  },
}
