<template>
  <div class="next-world-modal">
    <GModal
      v-if="showModal"
      @close="close"
    >
      <div class="next-world-modal__body">
        <div class="next-world-modal__body__top">
          <h4 class="next-world-modal__body__top__title">
            {{ $t('game.suggestNewWorld.title') }}
          </h4>
        </div>
        <div class="next-world-modal__body__bottom">
          <img
            class="next-world-modal__body__bottom__avatar"
            src="@/assets/char/char-head-background.svg"
          >
          <p
            class="next-world-modal__body__bottom__description"
            v-html="$t('game.suggestNewWorld.subtitle', {lecture: worldName})"
          />
        </div>
        <GButton
          class="next-world-modal__body__action"
          size="large"
          @click="$emit('goToNext')"
        >
          {{ $t('game.suggestNewWorld.actionLabel') }}
        </GButton>
      </div>
    </GModal>
  </div>
</template>

<script>
import { playSoundFX } from '@/utils/soundEffects'
import GButton from './GButton'
import GModal from './GModal'

export default {
  name: 'NextWorldModal',
  components: {
    GModal, GButton,
  },
  props: {
    next: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: true,
    }
  },
  computed: {
    worlds() {
      return {
        1153: this.$t('lectures.science'),
        1129: this.$t('lectures.portuguese'),
        972: this.$t('lectures.math'),
        1110: this.$t('lectures.physics'),
        1111: this.$t('lectures.chemistry'),
        1123: this.$t('lectures.biology'),
        1115: this.$t('lectures.geography'),
        1114: this.$t('lectures.history'),
      }
    },
    worldName() {
      return this.worlds[this.next.data.toString()]
    },
  },
  methods: {
    close() {
      playSoundFX()
      this.$trackEvent({
        category: this.$track.category.trackMissions,
        action: this.$track.action.closeModalOtherWorld,
      })
      this.showModal = false
    },
  },
}
</script>

<style lang="sass" scoped>
.next-world-modal
  &__body
    max-width: 400px
    padding: $size-l
    +flex-column-center

    &__top
      width: 100%
      border-bottom: 3px solid $eureka-color-base-dark
      padding-bottom: $size-m
      margin:
        bottom: $size-l

    &__bottom
      margin-bottom: $size-l
      +flex-space-between

      &__avatar
        margin-right: $font-size-m

    &__action
      width: 100%
</style>
