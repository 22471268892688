import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([ 'currentGrade', 'userGrades' ]),
  },
  methods: {
    getGradeId() {
      const { data: currentGrade } = this.currentGrade

      if (currentGrade) {
        return currentGrade.id
      }

      return this.userGrades.data?.length ? this.userGrades.data[0].id : null
    },
  },
}
