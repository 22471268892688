<template>
  <div class="danger-magna-mission-modal">
    <MagnaMissionModal
      :title="title"
      :button-name="buttonName"
      button-variation="danger"
      @close="$emit('close')"
      @submit="$emit('confirm')"
    >
      <div class="danger-magna-mission-modal__description">
        {{ description }}
      </div>
    </MagnaMissionModal>
  </div>
</template>
<script>
import MagnaMissionModal from './MagnaMissionModal'

export default {
  name: 'DangerMagnaMissionModal',
  components: {
    MagnaMissionModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.danger-magna-mission-modal {
  background-color: rgba(12, 11, 31, 0.75);
  z-index: $zindex-modal-backdrop;
  @include cover;
  @include flex-center;

  &__description {
    font: {
      family: Lato;
      size: $size-s;
      weight: $font-weight-medium;
    }
    letter-spacing: 0.0275em;
    color: $eureka-color-ink;
    margin: {
      top: $size-m;
      bottom: $size-m;
    }
  }
}

::v-deep .magna-mission-modal {
  max-width: 560px;

  &__header__text__title {
    font: {
      family: Rowdies;
      weight: $font-weight-regular;
      size: $size-l;
    }
  }

  &__footer {
    border-top: none;

    &__cancel-button {
      padding: $size-xs;
    }

    &__submit-button {
      padding: 12px $size-m;
    }
  }
}

</style>
