import parseISO from 'date-fns/parseISO'

export default {
  brazillianDateFormat(_date) {
    const date = parseISO(_date)

    return '@dd/@mm/@yyyy'
      .replace('@dd', date.getDate())
      .replace('@mm', date.getMonth() + 1)
      .replace('@yyyy', date.getFullYear())
  },
}
