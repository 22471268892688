<template>
  <div
    :class="[
      'magna-mission-room',
      { '--has-subheader' : isShowConfirmAnswerButton},
    ]"
  >
    <div
      v-if="isShowConfirmAnswerButton"
      class="magna-mission-room__subheader"
    >
      <h2>Confirme a alternativa antes da resposta ser revelada...</h2>
    </div>
    <AncestralArenaEnigma
      variation="secondary"
      :magna-mission-enigma="mappedMagnaMissionEnigma"
      :selected-option="selectedOption"
      :can-select-option="true"
      :is-loading="isEmptyMappedMagnaMissionEnigma"
      @enigma-position="$emit('enigma-position', $event)"
      @select-option="handleSetOptionTemporarySelected"
    >
      <template #enigmaAction>
        <GButton
          variation="secondary"
          icon-left="align-left"
          :icon-stroke="2"
          @click="hideQuestionLayout = !hideQuestionLayout"
        >
          {{ textButton }}
        </GButton>
      </template>
      <template
        v-if="hideQuestionLayout"
        #layout
      >
        <div class="magna-mission-room__content">
          <section
            class="cards-wrapper"
          >
            <template v-if="magnaMissionEnigma">
              <AncestralArenaCardQuestion
                v-for="option in mappedMagnaMissionEnigma.options"
                :key="option.id"
                :option="option"
                :variation="option.variation"
                @select-option="handleSetOptionTemporarySelected"
              />
            </template>
            <template v-else>
              <div class="cards-wrapper">
                <AncestralArenaCardQuestion
                  v-for="(_, i) in 4"
                  :key="i"
                  :option="{}"
                  :loading="true"
                />
              </div>
            </template>
          </section>
        </div>
      </template>
    </AncestralArenaEnigma>
    <section class="magna-mission-room__footer">
      <GButton
        v-if="isShowConfirmAnswerButton"
        class="bottom-sheet"
        color="white"
        asset="arena-small-warning.svg"
        @click="sendAnswer"
      >
        Confirmar resposta
      </GButton>
      <AncestralArenaFeedback
        v-if="isDisplayArenaFeedback"
        :message="missionFeedbackInfo.message"
        :description="missionFeedbackInfo.description"
        :class="[{ 'custom-feedback': missionFeedbackInfo.type !== types.WAITING }]"
      >
        <template #feedbackIcon>
          <g-loader
            v-if="isShowFeedbackIcon"
            class="magna-mission-room__footer__feedback-icon"
            size="small"
            sand="#F8A916"
            stroke="#2D1F49"
            fill="black"
          />
          <img
            v-else
            :src="missionFeedbackInfo.icon"
          >
        </template>
      </AncestralArenaFeedback>
    </section>
    <InfoMagnaMissionModal
      v-if="isInfoModalVisible"
      class="magna-mission-modal"
      asset="char/tata-head-left.svg"
      title="Missão encerrada"
      description="Essa missão foi encerrada. Aguarde que uma nova missão
      seja iniciada na Arena Ancestral para jogar novamente ou fale com o seu professor."
      button-name="Fechar Missão"
      @confirm="leaveCanceledMagnaMission"
      @close="closeInfoMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import arenaApi from '@/service/arena'
import { possibleLetters } from '@/utils/ancestralArenaUtils'
import socketClient from '@/client/socketClient'
import AncestralArenaCardQuestion from '@/components/AncestralArena/AncestralArenaCardQuestion/AncestralArenaCardQuestion'
import GButton from '@/components/GButton'
import AncestralArenaEnigma from '@/components/AncestralArena/AncestralArenaEnigma'
import GLoader from '@/components/GLoader'
import AncestralArenaFeedback from '@/components/AncestralArena/AncestralArenaFeedback'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import tataHearts from '@/assets/char/tata-hearts.svg'
import tataHead from '@/assets/char/tata-head.svg'

const statusEnum = {
  IDLE: 'IDLE',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
}

export default {
  name: 'MagnaMissionStudentRoom',
  components: {
    AncestralArenaCardQuestion,
    AncestralArenaEnigma,
    GButton,
    GLoader,
    AncestralArenaFeedback,
    InfoMagnaMissionModal,
  },
  data() {
    return {
      hideQuestionLayout: false,
      magnaMissionEnigma: {},
      error: null,
      studentInfoSubscription: null,
      challengeRoomsSubscription: null,
      userQueueErrorsSubscription: null,
      optionTemporarySelected: null,
      selectedOption: null,
      missionFeedbackType: '',
      isInfoModalVisible: false,
      challengeRoomsTimerSubscription: null,
      hasTimerStarted: false,
      timerStatus: statusEnum.IDLE,
      time: null,
      types: {
        STARTED: 'STARTED',
        WAITING: 'WAITING',
        CORRECT: 'CORRECT',
        WRONG: 'WRONG',
        FINISHED: 'FINISHED',
        TIME_OUT: 'TIME_OUT',
        CANCELED: 'CANCELED',
        WAITING_START_TIMER: 'WAITING_START_TIMER',
      },
    }
  },
  computed: {
    isShowFeedbackIcon() {
      return this.missionFeedbackInfo.type === this.types.WAITING
      || this.missionFeedbackInfo.type === this.types.WAITING_START_TIMER
    },
    isDisplayArenaFeedback() {
      return (this.hasTimer || this.arenaFeedbackIsVisible)
       && Boolean(this.missionFeedbackInfo.type)
    },
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
    isShowConfirmAnswerButton() {
      return !this.arenaFeedbackIsVisible
      && Boolean(this.optionTemporarySelected && !this.selectedOption)
      && !this.isWaitingStartTimer
    },
    isEmptyMappedMagnaMissionEnigma() {
      return Object.keys(this.mappedMagnaMissionEnigma).length === 0
    },
    mappedMagnaMissionEnigma() {
      const { magnaMissionEnigma, correctAnswerKey } = this
      if (magnaMissionEnigma?.code && magnaMissionEnigma?.question) {
        const { question: { statement, options } } = magnaMissionEnigma

        return {
          ...magnaMissionEnigma,
          statement,
          options: options.map((op, index) => ({
            ...op,
            letter: possibleLetters.find((_, i) => i === index),
            isSelectedOption: !this.isWaitingStartTimer
            && this.optionTemporarySelected?.id === op.id,
            markOption: this.selectedOption === op.id,
            isCorrectOption: correctAnswerKey === op.id,
            isDisabledOption: this.isWaitingStartTimer || Boolean(this.selectedOption)
            || correctAnswerKey,
            isWrongOption: correctAnswerKey && op.id === this.selectedOption
              ? op.id !== correctAnswerKey
              : false,
            index,
            title: op.description,
            variation: this.getCardVariation({ optionId: op.id, correctAnswerKey }),
          })),
          magnaMissionInfo: {
            enigmaPosition: magnaMissionEnigma?.progress.current,
            totalEnigmas: magnaMissionEnigma?.progress.total,
          },
        }
      }

      return {}
    },
    missionFeedbackInfo() {
      const { missionFeedbackType, types } = this
      const defaultInfo = { type: this.missionFeedbackType }

      if (missionFeedbackType) {
        switch (missionFeedbackType) {
          case types.WAITING_START_TIMER:
            return {
              ...defaultInfo,
              message: 'Aguarde o início do tempo de resposta...',
            }
          case types.WAITING:
            return {
              ...defaultInfo,
              message: this.$t('ancestralArena.magnaMissionRoom.feedback.waitAnswerRevealed'),
            }
          case types.CORRECT:
            return {
              ...defaultInfo,
              message: this.$t('ancestralArena.magnaMissionRoom.feedback.correct.title'),
              description: this.$t('ancestralArena.magnaMissionRoom.feedback.correct.description'),
              icon: tataHearts,
              style: {
                marginRight: '1rem',
                transform: 'translateY(-10px)',
              },
            }
          case types.WRONG:
            return {
              ...defaultInfo,
              message: this.$t('ancestralArena.magnaMissionRoom.feedback.wrong.title'),
              description: this.$t('ancestralArena.magnaMissionRoom.feedback.wrong.description'),
              icon: tataHead,
              style: {
                marginRight: '1rem',
                transform: 'translateY(-10px)',
              },
            }
          case types.TIME_OUT:
            return {
              ...defaultInfo,
              message: 'Não foi dessa vez...',
              description: 'Confirme sua resposta antes da solução ser revelada',
              icon: tataHead,
              style: {
                marginRight: '1rem',
                transform: 'translateY(-10px)',
              },
            }
          default:
            return {}
        }
      }

      return {}
    },
    currentQuestionId() {
      return this.magnaMissionEnigma.question?.id || null
    },
    enigmaCode() {
      return this.$route.query.enigmaCode
    },
    arenaFeedbackIsVisible() {
      return Boolean(this.selectedOption || this.correctAnswerKey)
    },
    correctAnswerKey() {
      return this.magnaMissionEnigma.question?.correctAnswerKey
    },
    userAnswerKey() {
      return this.magnaMissionEnigma.question?.userAnswerKey
    },
    textButton() {
      return this.hideQuestionLayout
        ? this.$t('ancestralArena.magnaMissionRoom.showQuestion')
        : this.$t('ancestralArena.magnaMissionRoom.hideQuestion')
    },
    currentTime() {
      if (this.time) {
        return this.time
      }

      return this.magnaMissionEnigma?.question?.formattedTimer ?? '00:00'
    },
    hasTimer() {
      return this.magnaMissionEnigma?.question?.hasTimer ?? false
    },
    isIdleTimerStatus() {
      return ![ 'CORRECT', 'WRONG', 'FINISHED', 'TIME_OUT', 'CANCELED' ]
        .includes(this.missionFeedbackType) && !this.hasTimerStarted
    },
    isWaitingStartTimer() {
      return this.missionFeedbackType === this.types.WAITING_START_TIMER
    },
  },
  watch: {
    isWaitingStartTimer(value) {
      if (!value) {
        const [ option ] = this.mappedMagnaMissionEnigma.options.filter(
          ({ id }) => id === this.userAnswerKey
        )

        this.setOptionTemporarySelected(option)
      }
    },
    hasTimer(hasTimer) {
      if (hasTimer && this.isIdleTimerStatus && !this.hasTimerStarted) {
        this.missionFeedbackType = this.types.WAITING_START_TIMER
      }
    },
    missionFeedbackType(type) {
      if ([ 'CORRECT', 'WRONG', 'FINISHED', 'TIME_OUT', 'CANCELED' ]
        .includes(type)) {
        this.timerStatus = statusEnum.FINISHED
      } else if (this.hasTimerStarted) {
        this.timerStatus = statusEnum.IN_PROGRESS
      } else {
        this.timerStatus = statusEnum.IDLE
      }

      this.updateTimerParent({
        currentTime: this.currentTime,
        hasTimer: this.hasTimer,
        timerStatus: this.timerStatus,
      })
    },
    challengeRoomsSubscription() {
      this.sendChallengeRoomsEnterConfirmation()
    },
    async magnaMissionEnigma() {
      if (this.magnaMissionEnigma) {
        const { status, title } = this.magnaMissionEnigma

        this.setMagnaMission({ data: { title } })

        if (status === this.types.FINISHED) {
          await arenaApi.updateQuestionnaire(this.questionnaireCode, { status: 'finished' })
          this.$router.replace({ name: 'magna-mission-student-report' })
        }

        if (status === this.types.CANCELED) {
          this.showInfoMagnaMissionModal()
        }

        if (!this.selectedOption && this.userAnswerKey) {
          const [ option ] = this.mappedMagnaMissionEnigma.options.filter(
            ({ id }) => id === this.userAnswerKey
          )

          this.setOptionTemporarySelected(option)
          this.setSelectedOption(this.userAnswerKey)
          this.missionFeedbackType = this.types.WAITING
        }

        if (this.correctAnswerKey) {
          if (!this.selectedOption) {
            this.missionFeedbackType = this.types.TIME_OUT

            return
          }

          this.missionFeedbackType = this.selectedOption === this.correctAnswerKey
            ? this.types.CORRECT
            : this.types.WRONG
        }
      }
    },
    currentQuestionId: {
      immediate: true,
      handler() {
        if (this.currentQuestionId && this.currentQuestionId !== this.enigmaCode) {
          this.setEnigmaCode(this.currentQuestionId)

          if (!this.userAnswerKey) {
            this.setSelectedOption(null)
            this.setOptionTemporarySelected(null)
          }
        }
      },
    },
    hasTimerStarted: {
      handler(isTimeStarted) {
        if (isTimeStarted) {
          this.timerStatus = statusEnum.IN_PROGRESS
        }
      },
    },
    currentTime(time) {
      if (this.hasTimerStarted && this.missionFeedbackType === this.types.WAITING_START_TIMER) {
        this.missionFeedbackType = ''
      }

      this.updateTimerParent({
        currentTime: time,
        hasTimer: this.hasTimer,
        timerStatus: this.timerStatus,
      })
    },
    enigmaCode() {
      if (!this.arenaFeedbackIsVisible) {
        this.missionFeedbackType = ''
        this.time = null
        this.hasTimerStarted = false

        if (this.hasTimer && this.isIdleTimerStatus) {
          this.missionFeedbackType = this.types.WAITING_START_TIMER
        }
      }
    },
  },
  mounted() {
    this.connect()
  },
  methods: {
    ...mapActions([ 'setMagnaMission' ]),
    connect() {
      try {
        this.subscribeChallengeRooms()
        this.subscribeStudentChannel()
        this.subscribeQueueErrors()
        this.subscribeChallengeRoomsTimer()
      } catch (e) {
        socketClient.getClient(this.questionnaireCode).onConnect = () => {
          this.subscribeChallengeRooms()
          this.subscribeStudentChannel()
          this.subscribeQueueErrors()
          this.subscribeChallengeRoomsTimer()
        }
      }
    },
    subscribeChallengeRooms() {
      const topic = `/topic/student.challenge-rooms.${this.questionnaireCode}`
      const onSubscribe = ({ body }) => {
        this.magnaMissionEnigma = { ...this.magnaMissionEnigma, ...JSON.parse(body) }
      }
      this.challengeRoomsSubscription = socketClient.subscribe(topic, onSubscribe)
    },
    subscribeStudentChannel() {
      const topic = '/user/queue/notifications'
      const onSubscribe = ({ body }) => {
        this.magnaMissionEnigma = { ...this.magnaMissionEnigma, ...JSON.parse(body) }
      }
      this.studentInfoSubscription = socketClient.subscribe(topic, onSubscribe)
    },
    subscribeQueueErrors() {
      const topic = '/user/queue/errors'
      const onError = (response) => {
        const { status, message } = JSON.parse(response.body)
        this.error = {
          message: `[${status}] ${message}`,
          type: 'serverError',
          icon: 'alert-circle',
          isCloseable: true,
        }
        this.$emit('error-ocurred', this.error)
        this.error = false
      }

      this.userQueueErrorsSubscription = socketClient.subscribe(topic, onError)
    },
    sendChallengeRoomsEnterConfirmation() {
      const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/subscribe`
      socketClient.emit(destination)
    },
    sendAnswer() {
      const { index, id, isDisabledOption } = this.optionTemporarySelected

      if (!isDisabledOption) {
        const questionId = this.magnaMissionEnigma?.question.id
        const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/answer`
        const body = { questionId, markedOption: index }

        this.setSelectedOption(id)
        socketClient.emit(destination, body)
        this.missionFeedbackType = this.types.WAITING
      }
    },
    handleSetOptionTemporarySelected(option) {
      if (!this.isWaitingStartTimer && !this.isDisplayArenaFeedback) {
        this.setOptionTemporarySelected(option)
      }
    },
    setOptionTemporarySelected(value) {
      this.optionTemporarySelected = value
    },
    getCardVariation({ optionId, correctAnswerKey }) {
      if (this.optionTemporarySelected) {
        if ((this.optionTemporarySelected.id === optionId) || (optionId === correctAnswerKey)) {
          return 'secondary'
        }

        return 'tertiary'
      }

      return 'primary'
    },
    setSelectedOption(value) {
      if (value !== null && typeof value === 'object' && !value.isDisabledOption) {
        this.selectedOption = value.id
      } else {
        this.selectedOption = value
      }
    },
    setEnigmaCode(current) {
      this.$router.replace({ ...this.$route, query: { enigmaCode: current } })
    },
    leaveCanceledMagnaMission() {
      const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/unsubscribe`
      socketClient.emit(destination)
      socketClient.disconnect()
      this.$router.replace({ name: 'arena' })
    },
    showInfoMagnaMissionModal() {
      this.isInfoModalVisible = true
    },
    closeInfoMagnaMissionModal() {
      this.isInfoModalVisible = false
    },
    subscribeChallengeRoomsTimer() {
      const topic = `/topic/challenge-rooms.${this.questionnaireCode}.timer`
      const onSubscribe = ({ body }) => {
        this.hasTimerStarted = true
        const { timer, questionCode } = JSON.parse(body)
        if (questionCode === this.enigmaCode) {
          this.time = timer
        }
      }
      this.challengeRoomsTimerSubscription = socketClient.subscribe(topic, onSubscribe)
    },
    updateTimerParent({ currentTime, hasTimer, timerStatus }) {
      this.$emit('update-time', {
        currentTime,
        hasTimer,
        timerStatus,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-room {
  --subheader-height: 0px;
  --subheader-margin-top-and-bottom: 0px;

  width: 100%;
  padding: 0 30px;

  &.--has-subheader {
    --subheader-height: 30px;
    --subheader-margin-top-and-bottom: 4rem;
  }

  &__subheader {
    border-top: 1px solid rgba($color-white, 0.2);

    h2 {
      margin: $size-m 0 $size-xxs;
      font: {
        family: Rowdies;
        size: 1.5rem;
        weight: 300;
      }
      color: $color-white;
      text-align: center;
    }
  }

  &__footer {
    @include flex-center;
    display: absolute;
    bottom: 0;
    width: 100%;
    background: rgb(240, 186, 91);

    &__feedback-icon.g-loader {
      ::v-deep svg {
        margin-right: $size-s;
        transform: translateY(4px);
        height: $size-xl;
        width: auto;
      }
    }

    ::v-deep .custom-feedback.ancestral-arena-feedback {
      padding: $size-xs $size-l;

      img {
        margin-right: $size-m;
        transform: translateY(-20px) scale(1.3);
      }
    }
  }

   ::v-deep .ancestral-arena-enigma {
    display: flex;
    flex-direction: column;
    max-height: none;
    height: calc(100vh - 125px - var(--subheader-height) - var(--subheader-margin-top-and-bottom));

    &__content {
      max-height: none;
      flex-grow: 1;
      height: 95%;

      &__statement {
        max-height: none;
        padding: $size-l $size-s $size-l $size-l;
        background-color: $eureka-color-base-lighter;
        box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
        border-radius: 16px;
        color: $eureka-color-ink;
        font: {
          family: Rowdies;
          size: 1.5rem;
          weight: $font-weight-light;
        }
      }
    }

    @media screen and (max-width: 991px) {
      display: block;
      height: auto;

      &__content {
        height: auto;
        padding: 1rem 1.5rem;
        flex-direction: column;

        &__statement {
          width: auto;
          max-width: none;
        }

        &__alternatives {
          width: auto;
          margin-top: 2rem;
          margin-left: 0;
        }
      }
    }
  }
}

.magna-mission-room__content {
  width: 100%;
  overflow-y: auto;

  .cards-wrapper {
    width: 100%;
    grid-gap: $size-m;
    padding: 10px;
    display: grid;
    grid-template-areas:
      "left right"
      "left right";

     @media screen and (max-width: 991px) {
       grid-template-areas: none;
     }
  }
}

.g-button {
  border-radius: $border-radius-xs;
  cursor: pointer;
  display: block;
  padding: 25px 20px;
  position: relative;
  color: $game-background-color;
  @include flex-center;
  @include transition($speed-x-fast);

  &.--secondary {
    color: $game-background-color;
  }

  &:hover {
    background: rgba($color-black, 0.2);
  }

  &:active {
    background: rgba($color-black, 0.2);
  }
}

.bottom-sheet {
  position: fixed;
  bottom: -0.5%;
  width: max-content;
  background: $eureka-color-secondary;
  border: 0;
  border-radius: 24px 24px 0 0;
  box-shadow: 0 -5px 32px rgba(0, 0, 0, 0.35);
  animation: animate-top 1s;
  padding: $size-m;
  min-height: 60px;

  &:hover, &:active {
    background: $eureka-color-secondary;
    box-shadow: 0 -5px 32px rgba(0, 0, 0, 0.35);
  }

  ::v-deep .g-button {
    &__text {
      font: {
        family: Rowdies;
        size: 1.5rem;
        weight: $font-weight-regular;
      }
      text-shadow: 0 1px 0 #000000;
      padding-bottom: $size-xs;
    }

    &__image {
      position: relative;
      bottom: 0.9rem;
      width: auto;
      margin-right: 0;
    }
  }
}

@keyframes animate-top {
  0% { bottom: -2%; }
  100% { bottom: -0.5%;}
}
</style>
