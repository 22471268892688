<template>
  <div>
    <component
      :is="sceneComponent"
      :template="currentTemplateScene"
      :scene="currentScene"
      :is-skiping="enableSkipingEffects"
      :going-to-next="enableAdvancingSceneEffects"
      :next-scene="nextScene"
      :used-options="usedOptions"
      :show-options="showOptions"
      class="scene-container"
      @select-option="handleSelectOption"
      @go-to-next-scene="handleNextScene"
      @go-to-next-scene-from-transition="handleNextTransitionScene"
      @skip="closeMovie"
      @is-last-scene="closeMovie(2000)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import {
  pauseBackground,
  playBackground,
  getBackground
} from '@/utils/soundEffects'
import { templatesTypes } from '@/components/Adventure/templatesTypes'
import Cutscene from '@/components/Adventure/AdventureScenes/Cutscene'
import DialogScene from '@/components/Adventure/AdventureScenes/DialogScene'
import TransitionScene
  from '@/components/Adventure/AdventureScenes/TransitionScene'

export default {
  name: 'ScenesContainer',
  data() {
    return {
      currentNumberScene: 1,
      currentTemplateScene: '',
      transitionDurationTime: 4000,
      defaultTimeToFinish: 100,
      enableSkipingEffects: false,
      enableAdvancingSceneEffects: false,
      usedOptions: [],
      mustBeClosed: false,
      showOptions: false,
    }
  },
  computed: {
    ...mapGetters([
      'historyEpisode',
      'historySeasons',
    ]),
    currentScene() {
      if (this.historyEpisode.data.length) {
        return this.getSceneByKey('number', this.currentNumberScene)
      }

      return {}
    },
    nextScene() {
      return this.currentScene
        ? this.getSceneByKey('number', this.currentScene?.nextSceneNumber)
        : null
    },
    sceneComponent() {
      const { currentTemplateScene } = this

      switch (currentTemplateScene) {
        case templatesTypes.DIALOG:
          return DialogScene

        case templatesTypes.TRANSITION:
          return TransitionScene

        case templatesTypes.CUTSCENE:
          return Cutscene

        default:
          return null
      }
    },
    soundTrackBackground() {
      return this.currentScene.sound?.trackUrl
    },
    soundTrackEffect() {
      return this.currentScene.sound?.effectUrl
    },
  },
  watch: {
    currentScene() {
      if (this.currentScene?.episodeId) {
        this.setEpisodeScene(this.currentScene)

        if (this.currentScene.last) {
          this.mustBeClosed = true
        }
      }
    },
    soundTrackBackground(value) {
      if (value !== undefined) {
        // eslint-disable-next-line no-underscore-dangle
        const backgroundSource = getBackground()?._src
        const { soundTrackBackground } = this

        if (soundTrackBackground) {
          if (backgroundSource !== soundTrackBackground) {
            pauseBackground()
            playBackground(soundTrackBackground)
          } else {
            playBackground(soundTrackBackground)
          }
        }
      }
    },
  },
  beforeDestroy() {
    pauseBackground()
    this.clearHistoryEpisodeAndSceneData()
  },
  async created() {
    pauseBackground()
    if (!this.historyEpisode.data?.length) {
      const {
        episodeId,
        seasonId,
      } = this.$route.params
      await this.getHistoryEpisode({ episodeId, seasonId })
    }
    if (!this.currentTemplateScene) {
      const defaultScene = this.getSceneByKey('number')

      this.currentTemplateScene = defaultScene.template
      this.currentNumberScene = defaultScene.number
      this.setEpisodeScene(defaultScene)
    }
  },
  methods: {
    ...mapActions([
      'getHistoryEpisode',
      'setEpisodeScene',
      'clearHistoryEpisodeAndSceneData',
    ]),
    handleSelectOption({ number, text }) {
      const nextScene = this.getSceneByKey('number', number)
      if (this.currentScene.outgoingScenes.length) {
        this.showOptions = true
      }
      this.enableAdvancingSceneEffects = true

      this.setNext(nextScene.number, nextScene.template, () => {
        this.usedOptions.push(text)
        this.showOptions = false
      })

      this.showOptions = false
    },
    setNext(nextSceneId, nextSceneTemplate, callback) {
      if (this.mustBeClosed) {
        this.closeMovie()
      } else {
        setTimeout(() => {
          this.currentNumberScene = nextSceneId
          this.currentTemplateScene = nextSceneTemplate
          this.enableAdvancingSceneEffects = false
        }, 100)

        if (callback) {
          callback()
        }
      }
    },
    handleNextScene({ nextSceneNumber, chooseByOption }) {
      const {
        currentTemplateScene,
        getSceneByKey,
        handleNextTransitionScene,
        mustBeClosed,
      } = this
      const { TRANSITION } = templatesTypes
      const nextScene = getSceneByKey('number', nextSceneNumber)

      if (this.currentScene.outgoingScenes.length) {
        this.showOptions = true

        return
      }

      if (!chooseByOption) {
        this.enableAdvancingSceneEffects = true
      }

      this.setNext(nextSceneNumber, nextScene.template, () => {
        if (currentTemplateScene === TRANSITION && !mustBeClosed) {
          handleNextTransitionScene()
        }
      })
    },
    getSceneByKey(key, value) {
      return this.historyEpisode.data
        ?.filter((item) => item[key])
        .find((item) => {
          if (value) {
            return item[key] === value
          }

          return item[key]
        })
    },
    handleNextTransitionScene() {
      const { nextSceneNumber } = this.currentScene
      const nextScene = this.getSceneByKey('number', nextSceneNumber)

      setTimeout(() => {
        this.currentNumberScene = nextSceneNumber
        this.currentTemplateScene = nextScene.template
      }, this.transitionDurationTime)
    },
    closeMovie(time) {
      const timeToEmit = time || this.defaultTimeToFinish
      setTimeout(() => {
        this.$emit('finish-history')
      }, timeToEmit)
    },
  },
}
</script>

<style lang="sass" scoped>
.scene-container
  overflow: hidden
</style>
