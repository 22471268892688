<template>
  <p
    v-if="totalTimeAvailableFormatted"
    class="total-time"
  >
    {{ totalTimeAvailableFormatted }}
  </p>
</template>

<script>
import isEmpty from '@/utils/isEmpty'

export default {
  name: 'TotalTime',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalTimeAvailableInSeconds() {
      if (isEmpty(this.list)) {
        return 0
      }

      const totalInSeconds = this.list.reduce(
        (total, { timer, hasTimer }) => (hasTimer ? total + timer : total), 0
      )

      return totalInSeconds
    },
    hasTotalTimeAvailable() {
      return this.totalTimeAvailableInSeconds > 0
    },
    totalTimeAvailable() {
      const minutes = Math.floor(this.totalTimeAvailableInSeconds / 60)
      const remainderSeconds = this.totalTimeAvailableInSeconds % 60

      let totalTimeFormatted = ''

      if (minutes > 0) {
        totalTimeFormatted += `${minutes}min`
      }

      if (minutes > 0 && remainderSeconds > 0) {
        totalTimeFormatted += ' e '
      }

      if (remainderSeconds > 0) {
        totalTimeFormatted += `${remainderSeconds}s`
      }

      return totalTimeFormatted
    },
    totalTimeAvailableFormatted() {
      return isEmpty(this.totalTimeAvailable)
        ? this.totalTimeAvailable
        : `Tempo disponível para resposta: ${this.totalTimeAvailable}`
    },

  },
  watch: {
    hasTotalTimeAvailable: {
      handler(hasTime) {
        this.$emit('change-has-time-available', hasTime)
        this.$emit('total-time-available', {
          formattedTime: this.totalTimeAvailable,
          timeInSeconds: this.totalTimeAvailableInSeconds,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .total-time {
      padding-left: $element-size-s;
      font-family: Lato;
      font-weight: $font-weight-medium;
      font-size: $font-size-m;
      line-height: $line-height-text;
      letter-spacing: 0.0275rem;
      color: $eureka-color-ink-light;
      background: url('~@/assets/ancestral-arena/clock.svg') no-repeat left center;
   }
</style>
