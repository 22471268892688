<template>
  <GButton
    variation="secondary"
    class="toggle-full-screen"
    :icon="iconType"
    :size="size"
    :icon-stroke="3"
    @click="toggleFullScreen"
  />
</template>
<script>
import GButton from './GButton'

export default {
  name: 'ToggleFullScreen',
  components: { GButton },
  props: {
    references: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => value.match(/(small|medium|large)/),
    },
  },
  data() {
    return {
      fullscreen: false,
    }
  },
  computed: {
    iconType() {
      if (this.fullscreen) {
        return 'minimize-2'
      }

      return 'maximize-2'
    },
    container() {
      return this.references?.container
    },
  },
  mounted() {
    this.container.addEventListener('fullscreenchange', this.callbackFullscreenEvent)
  },
  beforeDestroy() {
    this.container.removeEventListener('fullscreenchange', this.callbackFullscreenEvent)
  },
  methods: {
    toggleFullScreen() {
      if (this.fullscreen) {
        this.closeFullScreen()
      } else {
        this.openFullScreen()
      }
    },
    openFullScreen() {
      if (this.container.requestFullscreen) {
        this.container.requestFullscreen()
      } else if (this.container.webkitRequestFullscreen) {
        this.container.webkitRequestFullscreen()
      } else if (this.container.msRequestFullscreen) {
        this.container.msRequestFullscreen()
      }
    },
    closeFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    callbackFullscreenEvent() {
      if (document.fullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.toggle-full-screen {
  border-radius: $border-radius-m;
  padding: 25px 20px;

  &:hover {
    background: rgba($color-black, 0.2);
  }

  &:active {
    background: rgba($color-black, 0.2);
  }
}
</style>
