<template>
  <div
    :class="[
      'container',
      `--${variation}`,
      {
        '--mozillaFullScroll' : mozillaFullScroll
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ScrollContainer',
  props: {
    variation: {
      type: String,
      default: 'primary',
      validator: (value) => [ 'primary', 'secondary' ].includes(value),
    },
    mozillaFullScroll: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.container {
  --scrollbar-width: 1rem;
  @include mq_s {
    --scrollbar-width: 0;
  }
  --trackColor: rgba(13, 4, 37, 0.32);
  --scrollWidth: thin;

  overflow-y: auto !important;
  overflow-x: hidden;
  scrollbar-gutter: stable; //It reserves scroll space in compatible browsers
  scrollbar-width: var(--scrollWidth);
  scrollbar-color: $eureka-color-base-light var(--trackColor);

  &.--secondary {
    --trackColor: rgba($color-white, 0.32);
  }

  &.--mozillaFullScroll {
    --scrollWidth: auto;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 1rem; //When in horizontal mode
    padding: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--trackColor);
    padding: 0.2rem;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $eureka-color-base-light;
    border: 0.2rem solid rgba(0,0,0,0);
    background-clip: content-box;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
</style>
