import isEmpty from '@/utils/isEmpty'

const DELAY_MILISSEGUNDOS = 100

export default {
  data() {
    return {
      editor: {
        imageButton: null,
        moreOptionsButton: null,
        dialog: {
          uploadTab: null,
        },
      },
    }
  },
  computed: {
    isEmptyImageButton() {
      return isEmpty(this.editor.imageButton)
    },
  },
  destroyed() {
    this.editor.imageButton?.removeEventListener('click', this.handleImageButtonClick)
    this.editor.moreOptionsButton?.removeEventListener('click', this.handleMoreOptionsButtonClick)
  },
  methods: {
    onEditorReady() {
      this.setEditorImageButton()
    },
    setEditorImageButton() {
      this.editor.imageButton = document.querySelector('button.tox-tbtn[aria-label="Inserir/Editar imagem"]')

      if (this.isEmptyImageButton) {
        this.editor.moreOptionsButton = document.querySelector('button.tox-tbtn[aria-label="Mais..."]')
        this.editor.moreOptionsButton.addEventListener('click', this.handleMoreOptionsButtonClick)
      } else {
        this.editor.imageButton.addEventListener('click', this.handleImageButtonClick)
      }
    },
    registerMoreOptionsButtonClickEvent() {
      if (this.isEmptyImageButton) {
        setTimeout(() => {
          this.editor.imageButton = document.querySelector('button.tox-tbtn[aria-label="Inserir/Editar imagem"]')
          this.editor.imageButton.addEventListener('click', this.handleImageButtonClick)
        }, DELAY_MILISSEGUNDOS)
      }
    },
    handleMoreOptionsButtonClick() {
      this.registerMoreOptionsButtonClickEvent()
    },
    simulateClickOnUploadTab() {
      setTimeout(() => {
        this.editor.dialog.uploadTab = document.querySelector('div.tox-dialog__body-nav-item.tox-tab[id^="aria_"]:last-child')
        this.editor.dialog.uploadTab?.click()
      }, DELAY_MILISSEGUNDOS)
    },
    handleImageButtonClick() {
      this.simulateClickOnUploadTab()
    },
    saveImageAutomatically() {
      setTimeout(() => {
        const inputSource = document.querySelector('.tox-dialog__body .tox-form__controls-h-stack input[type=url].tox-textfield ')
        if (inputSource?.value) {
          document.querySelector('.tox .tox-dialog .tox-dialog__footer .tox-dialog__footer-end button.tox-button[title="Salvar"]')?.click()
        }
      }, DELAY_MILISSEGUNDOS)
    },
  },
}
