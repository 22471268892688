<template>
  <Drawer
    v-if="visible"
    class="bag"
    :size="bagSize"
    background="#F2E9D0"
    @close="hide"
  >
    <header class="bag__header">
      <img
        class="bag__header__image"
        src="@/assets/bag.svg"
      >
      <div class="bag__header__title">
        <h3 class="bag__header__title__text">
          {{ $t('game.bag.title') }}
        </h3>
      </div>
      <button
        class="bag__header__close"
        @click="closeButton"
      />
    </header>
    <div
      v-if="video.done && bagItems.done"
      class="bag__content"
    >
      <transition name="confirm">
        <div
          v-if="selectedItem"
          key="confirm"
          class="bag__content__confirm-wrapper"
        >
          <g-button
            class="bag__content__confirm-wrapper__back"
            size="large"
            variation="secondary"
            icon-left="arrow-left"
            @click="backToMyItems"
          >
            Meus itens
          </g-button>
          <g-alert
            v-if="useItemError"
            variation="error"
            :message="errorMessage"
          />
          <ItemDetails :item="selectedItem">
            <g-button
              size="large"
              :loading="waitingForItem"
              @click="$emit('use-item', selectedItem.item.id)"
            >
              {{ $t('game.useItem') }}
            </g-button>
          </ItemDetails>
        </div>
        <div
          v-else
          key="items"
          class="bag__content__items-wrapper"
        >
          <BagSection
            v-if="isNotEmpty(usedItemsAvailableToReuse) && !mq_xl__mf"
            title="Use novamente"
            is-grid
          >
            <ItemThumbnail
              v-for="(item, index) in usedItemsAvailableToReuse"
              :key="index"
              :bag-item="item"
              @click="selectItem(item.item)"
            />
          </BagSection>
          <ReviewVideo v-if="video.success" />
          <EmptyBag
            v-if="!bagItems.success"
            @tryAgain="refresh"
          />
          <template v-else>
            <BagSection
              title="Meus itens"
              is-grid
            >
              <ItemThumbnail
                v-for="(item, index) in items"
                :key="index"
                :bag-item="item"
                :disabled="item.item.useAtMission"
                @click="selectItem(item)"
              />
            </BagSection>
          </template>
        </div>
      </transition>
      <ShopButton
        @click="goToShop"
      />
    </div>
  </Drawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as types from '@/store/items/types'
import { Drawer } from '@sas-te/alfabeto-vue'

import { playSoundFX } from '@/utils/soundEffects'
import { isNotEmpty } from '@sas-te/frontend-utils/modules/arrays'
import GButton from '@/components/GButton'
import GAlert from '@/components/GAlert'
import ItemThumbnail from '@/components/Item/ItemThumbnail/ItemThumbnail'
import ItemDetails from '@/components/Item/ItemDetails/ItemDetails'
import mediaQueries from '@/mixins/mediaQueries'
import ReviewVideo from './partials/ReviewVideo/ReviewVideo'
import ShopButton from './partials/ShopButton/ShopButton'
import EmptyBag from './partials/EmptyBag/EmptyBag'
import BagSection from './partials/BagSection/BagSection'

export default {
  name: 'Bag',
  components: {
    GButton,
    GAlert,
    ReviewVideo,
    ItemThumbnail,
    ItemDetails,
    ShopButton,
    EmptyBag,
    Drawer,
    BagSection,
  },
  mixins: [ mediaQueries ],
  props: {
    waitingForItem: {
      type: Boolean,
      default: false,
    },
    useItemError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      selectedItem: null,
      notAvailable: false,
    }
  },
  computed: {
    ...mapGetters([
      'bagItems',
      'usedItems',
      'question',
      'video',
      'itemEffects',
    ]),
    bagSize() {
      if (this.isMediumBreakpoint) return '60%'
      if (this.isLargeBreakpoint) return '40%'
      if (this.isXLargeBreakpoint) return '30%'

      return '100%'
    },
    missionId() {
      return this.question.data.assignment
        ? this.question.data.assignment.code
        : ''
    },
    questionId() {
      return this.question.data.id
        ? this.question.data.id
        : ''
    },
    bookMissionPath() {
      const image = require.context('@/assets/items/', false, /\.svg$/)

      return image('./missionBook.svg')
    },
    items() {
      if (!this.bagItems.done) {
        return []
      }

      return [
        {
          item: {
            id: 'missionBook',
            image: { svg: this.bookMissionPath },
            name: this.$t('game.items.missionBook.name'),
            description: this.$t('game.items.missionBook.description'),
          },
          quantity: -1,
        },
        ...this.bagItems.data,
      ]
    },
    emptyBag() {
      return this.items.length === 0
    },
    errorMessage() {
      return this.itemEffects?.error?.message || this.$t('game.error.useItem.message')
    },
    usedItemsAvailableToReuse() {
      const itemEffects = this.itemEffects.data || []
      const usedItemsAvailableForReuse = Array.isArray(this.usedItems.data)
        ? this.usedItems.data
          .filter((item) => typeof item !== 'string')
          .flatMap(({ items, category }) => items.map((item) => ({ ...item, category })))
          .filter((item) => item.reusableByQuestion)
        : []

      if (isNotEmpty(usedItemsAvailableForReuse) && isNotEmpty(itemEffects)) {
        const availableEffects = itemEffects.map((effect) => effect.type)

        return usedItemsAvailableForReuse
          .filter(({ id }) => availableEffects.includes(id))
          .map((item) => ({ item, quantity: -1 }))
      }

      return []
    },
  },
  created() {
    if (!this.bagItems.done) {
      this.refresh()
    }
    this.getVideo(this.$route.params.questionnaireCode)
      .catch((error) => {
        console.error(error)
      })
  },
  beforeDestroy() {
    this.resetBagItems()
  },
  methods: {
    ...mapActions([
      'getBagItems',
      'resetBagItems',
      'getVideo',
      'resetEffects',
    ]),
    ...mapMutations({
      resetItemError: types.CLEAR_ITEM_EFFECTS_ERROR,
    }),
    isNotEmpty,
    show() {
      this.visible = true
    },
    hide() {
      this.selectedItem = null
      this.visible = false
      this.resetItemError()
    },
    refresh() {
      this.getBagItems({
        missionId: this.missionId,
        questionId: this.questionId,
      })
    },
    selectItem(item) {
      playSoundFX()
      if (this.usedItems.data.includes(item.id)) {
        this.$emit('use-item', item.id)
      } else {
        this.selectedItem = item
      }
    },
    goToShop() {
      playSoundFX()
      this.$trackEvent({
        category: this.$track.category.mission,
        action: this.$track.action.enterMarket,
      })
      this.$router.push({
        name: 'shop',
        query: { ...this.$route.query },
      })
    },
    backToMyItems() {
      playSoundFX()
      this.selectedItem = null
      this.$emit('update:useItemError', false)
    },
    closeButton() {
      this.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.bag {
  ::v-deep .drawer {
    box-shadow: 0 $size-xxs 28px rgba(0, 0, 0, 0.55);
    border-left: 2px solid #D1AD61;
    display: flex;
    flex-direction: column;
  }

  &__header {
    @include flex-center;
    background-color: #FBF1E1;
    box-shadow: 0 $size-xxs 0 #EECE8A, 0 5px 2px rgba(144, 111, 26, 0.42);
    padding: $size-s $size-m;

    &__image {
      width: 40px;
      margin-right: 12px;
    }

    &__title {
      flex-grow: 1;
      display: flex;

      &__text {
        font-weight: $font-weight-bold;
        font-size: 22px;
        line-height: 1;
        flex: 0 1 0;
      }
    }

    &__close {
      cursor: pointer;
      background-color: transparent;
      background-image: url(~@/assets/icon-x-secondary.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: $size-l;
      border: none;
      border-radius: 6px;
      height: $size-l;
      width: $size-l;
      @include transition($speed-x-fast);

      &:hover {
        background-color: transparentize(#834905, 0.9);
      }

      &:active {
        background-color: transparentize(#834905, 0.8);
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__content {
    margin: $size-l $size-l 160px;
    position: relative;
    flex-grow: 1;

    &__confirm-wrapper {
      &__back {
        margin-bottom: $size-s;
      }

      &.confirm-enter,
      &.confirm-leave-to {
        transform: translateX(110%);
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &__items-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      &.confirm-enter,
      &.confirm-leave-to {
        transform: translateX(-110%);
        position: absolute;
        top: 0;
        width: 100%;
      }

      .empty-bag {
        flex-grow: 1;
      }
    }

    .shop-button {
      position: fixed;
      bottom: 0;
      right: 0;
    }
  }
}

.empty-bag {
  &.confirm-enter,
  &.confirm-leave-to {
    position: absolute;
    opacity: 0;
  }
}

.confirm {
  &-enter-active,
  &-leave-active {
    @include transition($speed-normal);
  }
}

.g-alert {
  margin-bottom: 14px
}
</style>
