<template>
  <div
    class="wallet-display"
    :class="{ '--mfe-wallet-display': isMFE }"
    data-testid="hud-money"
  >
    <g-block
      :asset="`${currencyType}.svg`"
      :theme="themeColor"
      :size="size"
    >
      {{ animatedQuantity }}
    </g-block>
  </div>
</template>

<script>
import { gsap } from 'gsap'

import GBlock from './GBlock'

export default {
  name: 'WalletDisplay',
  components: {
    GBlock,
  },
  props: {
    quantity: {
      type: Number,
      required: true,
      default: 0,
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => (
        value.match(/(small|medium|large)/)
      ),
    },
    currencyType: {
      type: String,
      required: true,
      validator: (value) => (
        value.match(/(coin|gem)/)
      ),
    },
    themeColor: {
      type: String,
      required: false,
      default: 'bright',
      validator: (value) => (
        value.match(/(bright|success-inverted|ocean-blue)/)
      ),
    },
  },
  data() {
    return {
      animatedQuantity: this.quantity,
    }
  },
  computed: {
    isMFE() {
      return window.eureka_init
    },
  },
  watch: {
    quantity(newQuantity, oldQuantity) {
      const diff = Math.abs(oldQuantity - newQuantity)
      const duration = 0.25 * Math.log(diff)
      gsap.to(
        this,
        duration,
        {
          animatedQuantity: newQuantity,
          roundProps: 'animatedQuantity',
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.--mfe-wallet-display {
  ::v-deep .g-block__text {
    padding: 12px 46px 10px 8px !important;
  }
}

</style>
