import qs from 'qs'
import api from '@/service/api'
import { apiBff } from '@/service/apiBff'

export default {
  getAssignmentsMagnaMissions: () => apiBff.get('challenge-assignments'),
  getQuestionnairesMagnaMissions: ({ pageNumber = 1, pageSize = 8 } = {}) => apiBff
    .get('/v2/challenge-questionnaires-model', { params: { pageNumber, pageSize } }),
  getQuestionnairesMagnaMissionsBy: (questionnaireCode, isLiveMission = false) => {
    if (isLiveMission) {
      return apiBff.get(`questionnaires/${questionnaireCode}`)
    }

    return apiBff.get(`challenge-questionnaires-model/${questionnaireCode}`)
  },
  updateChallengeQuestionnaire: (questionnaireCode, body) => api
    .put(`challenge-questionnaires/${questionnaireCode}`, body),
  getReport: (questionnaireCode) => api.get(`questionnaires/${questionnaireCode}/reports`),
  getAssignmentReport: (assignmentCode) => apiBff.get(`assignments/${assignmentCode}/report`),
  getStatistics: (questionnaireCode, classroomId) => api
    .get(`/v2/questionnaires/${questionnaireCode}/classrooms/${classroomId}/statistics`),
  createAssignment: (questionnaireCode) => apiBff
    .post('challenge-assignments', { questionnaireCode }),
  updateQuestionnaire: (questionnaireCode, body) => api
    .put(`questionnaires/${questionnaireCode}`, body),
  updateQuestionnaireBff: (questionnaireCode, body) => apiBff
    .put(`questionnaires/${questionnaireCode}`, body),
  getArenaAccess: () => api.get('arena-access'),
  createMagnaMission: (body) => apiBff.post('/challenge-questionnaires-model', body),
  createLiveAndHomeMagnaMission: (body) => apiBff.post('/questionnaires', body),
  getMagnaMissionByCode: (questionnaireCode) => apiBff
    .get(`/challenge-questionnaires-model/${questionnaireCode}`),
  updateMagnaMission: (questionnaireCode, body) => apiBff
    .put(`/challenge-questionnaires-model/${questionnaireCode}`, body),
  removeMagnaMission: (questionnaireCode) => api
    .delete(`/challenge-questionnaires-model/${questionnaireCode}`),
  getFilters: () => apiBff.get('/filters'),
  getFilterContents: (params) => apiBff.get('/filters/contents', {
    params,
    paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'comma' }),
  }),
  getQuestions: (params) => {
    const pageSize = params?.pageSize ?? 10
    const pageNumber = params?.pageNumber ?? 1

    return apiBff.get('/questions', {
      params: { ...params, pageSize, pageNumber },
      paramsSerializer: (_params) => qs.stringify(_params, { arrayFormat: 'comma' }),
    })
  },
  getQuestionnairesMagnaMissionsStatus: (
    {
      pageNumber = 1, pageSize = 8, finished = false,
    } = {}
  ) => apiBff.get('/challenge-questionnaires', {
    params: {
      pageNumber, pageSize, finished,
    },
  }),
  getHomeQuestions(questionnaireCode) {
    return apiBff.get(`/questionnaires/${questionnaireCode}/questions`)
  },
  putQuestionAnswer({ assignmentCode, questionId, markedOption }) {
    const data = {
      questionId,
      markedOption,
    }

    return apiBff.put(`/assignments/${assignmentCode}/answers`, data)
  },
}
