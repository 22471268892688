<template>
  <ScrollContainer
    :class="[
      'academic-record-tab',
      { '--error': academicRecords.error }]"
  >
    <ErrorMessage
      v-if="academicRecords.error"
      image-name="refresh.svg"
      message="Não foi possível carregar a listagem de histórico"
      action="Recarregar"
      @click="getAcademicRecords"
    />
    <div
      v-else
      class="academic-record-tab__content"
    >
      <template v-if="academicRecords.loading">
        <AcademicRecordSkeleton
          v-for="skeleton in academicRecords.skeletonLoadingCount"
          :key="skeleton"
          class="academic-record-card"
        />
      </template>
      <template v-else>
        <div
          v-for="academicRecord in academicRecords.data"
          :key="academicRecord.id"
          class="academic-record-card"
        >
          <h5 class="academic-record-card__title">
            {{ academicRecord.title }}
          </h5>
          <div class="academic-record-card__year">
            {{ academicRecord.year }}
          </div>
          <div class="academic-record-card__content">
            <div class="academic-record-card__info">
              <p class="academic-record-card__info-title">
                Insígnia Conquistada:
              </p>
              <span class="academic-record-card__description">
                <LevelDisplay
                  class="academic-record-card__level"
                  :rank="generateRank(academicRecord.insignia)"
                  rank-label-is-visible
                  :show-level-number="false"
                />
              </span>
            </div>
            <div class="academic-record-card__info">
              <p class="academic-record-card__info-title">
                Gemas e Moedas:
              </p>
              <span class="academic-record-card__description">
                <img
                  class="academic-record-card__icon"
                  src="@/assets/gem.svg"
                >
                {{ `${academicRecord.gems.conquered}/${academicRecord.gems.total}` }}
                <img
                  class="academic-record-card__icon"
                  src="@/assets/coin.svg"
                >
                {{ academicRecord.coins }}
              </span>
            </div>
            <div class="academic-record-card__info academic-record-card__explored-worlds">
              <p class="academic-record-card__info-title">
                Mundos explorados:
              </p>
              <span class="academic-record-card__description">
                <span
                  v-for="exploredWorld in academicRecord.exploredWorlds"
                  :key="exploredWorld.id"
                  v-tooltip.top="exploredWorld.title"
                  class="academic-record-card__explored-worlds"
                >
                  <img
                    :alt="exploredWorld.title"
                    class="academic-record-card__explored-worlds-icon"
                    :src="exploredWorld.image.svg"
                  >
                </span>
              </span>
            </div>
          </div>
          <div class="retrospective">
            <GButton
              v-if="showRetrospectiveByFeatureToggle"
              class="retrospective__content"
              asset="profile/telescope.svg"
              variation="secondary"
              @click="navigateToRetrospective(academicRecord.year)"
            >
              Visualizar retrospectiva do ano
            </GButton>
            <div
              v-else
              class="retrospective__content retrospective__content--preview"
            >
              <p class="retrospective__description">
                Retrospectiva Eureka em breve
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </ScrollContainer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ScrollContainer from '@/components/ScrollContainer'
import ErrorMessage from '@/components/ErrorMessage'
import LevelDisplay from '@/components/LevelDisplay'
import GButton from '@/components/GButton'
import AcademicRecordSkeleton from './AcademicRecordSkeleton'

export default {
  name: 'AcademicRecordTab',
  components: {
    AcademicRecordSkeleton,
    ScrollContainer,
    ErrorMessage,
    LevelDisplay,
    GButton,
  },
  computed: {
    ...mapState({
      academicRecords: ({ profileTabs }) => profileTabs.academicRecords,
      showRetrospectiveByFeatureToggle: ({ featureToggles }) => featureToggles
        .toggles.eurekaRetrospective,
    }),
  },
  created() {
    this.$trackEvent({
      category: this.$track.category.profile,
      action: this.$track.action.accessHistorical,
    })
  },
  methods: {
    ...mapActions([
      'getAcademicRecords',
    ]),
    generateRank({ image, title }) {
      return {
        insigniaImage: image,
        insigniaTitle: title,
      }
    },
    navigateToRetrospective(year) {
      this.$trackEvent({
        category: this.$track.category.Historical,
        action: this.$track.action.accessRetrospective,
      })

      this.$router.push({
        name: 'retrospective',
        params: {
          retrospectiveId: year,
        },
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.academic-record-tab {
  &.--error {
    @include flex-center;
  }

  &__content {
    width: 100%;
    gap: $size-m;
    display: flex;
    flex-direction: column;
    padding: $size-m $size-xl $size-m $size-xl;

    @media screen and (max-width: 579px) {
      padding: $size-m $size-s $size-m $size-s;
    }
  }

  &__description {
    @media screen and (max-width: 579px) {
      flex-direction: row;
    }
  }

  ::v-deep .error-message {
    &__description {
      width: 64%;
    }
  }
}

.academic-record-card {
  width: 100%;
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  background-color: $eureka-color-base-light;
  box-shadow: 0 4px 4px #c19267, 0 3px 0 #c2ab8c,
    inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 16px;

  &__title {
    padding: $size-s;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
    color: $eureka-color-ink;
  }

  &__year {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $eureka-color-ink;
    padding: $size-xs $size-s;
    border-radius: 0 16px 0 $border-radius-m;
    color: $color-white;
    font-family: Rowdies;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
  }

  &__content {
    width: calc(100% - 32px);

    gap: $size-s;
    display: flex;
    flex-direction: column;

    padding: $size-s;
    margin: 0 $size-s $size-s;
    background-color: $eureka-color-base-lighter;
    border-radius: $border-radius-m;
  }

  &__info {
    gap: $size-s;
    display: flex;
    align-items: center;
    font-family: "Metropolis";
    color: $eureka-color-ink;

    @media screen and (max-width: 479px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: $size-m;
    height: $size-m;
    margin: 0 $size-xs 0 $size-xxs;

    &:last-child {
      margin-left: $size-s;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-weight: $font-weight-bold;
  }

  &__level {
    margin-bottom: 0;

    ::v-deep {
      img {
        height: 28px;
      }
    }
  }

  &__explored-worlds {
    display: flex;
  }

  &__explored-worlds-icon {
    border-radius: 2px;
    height: $size-m;
    width: $size-m;
    margin: 0 $size-xxs;
  }

  .retrospective {
    width: 100%;

    &__content {
      background-color: $eureka-color-base;
      padding: 12px $size-s;
      border-radius: 0 0 16px 16px;
      font-family: Rowdies;
      color: $eureka-color-ink;
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      height: 45px;
      font-size: $font-size-s;

      &--preview {
        cursor: default;
      }
    }

    &__icon {
      margin-right: $size-xs;
      height: $size-m;
      width: $size-m;
    }

    &__description {
      opacity: 0.6;
    }

  }
}
</style>
