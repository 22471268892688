<template>
  <div
    ref="classPicker"
    class="class-picker"
  >
    <ClassPickerSkeleton v-if="loading" />
    <AdaptiveSelector
      v-else
      filterable
      group-by="gradeName"
      :options-title="'Selecionar turma'"
      :options="mappedClassrooms"
      :selected-option="mappedSelectedClassroom"
      :threshold="0"
      :filter-placeholder="'Selecionar turma'"
      :empty-string="`Nenhuma turma encontrada com ${searchString}`"
      :disabled="disabled"
      @search="searchString = $event"
      @select="select"
      @close="searchString = ''"
    >
      <template #selectedOption="{ option }">
        <icon
          class="class-picker__handle__icon"
          type="users"
        />
        <div class="class-picker__handle__text">
          <div class="class-picker__class-name">
            {{ option.gradeName }}
            ·
            {{ option.name }}
          </div>
          <div class="class-picker__details">
            {{ $t(`shifts.${option.shift}`) }}
            ·
            {{ $tc(
              'commons.studentsCount',
              option.studentsAmount,
              { count: option.studentsAmount },
            ) }}
          </div>
        </div>
      </template>
      <template #skeleton>
        <slot name="skeleton" />
      </template>
      <template #optionsTitle>
        <icon
          class="class-picker__options__header__icon"
          type="users"
        />
        <h4 class="class-picker__options__header__title">
          Selecionar turma
        </h4>
      </template>
      <template #option="{ option }">
        <div class="class-picker__class-name">
          {{ option.name }}
        </div>
        <div class="class-picker__details">
          {{ $t(`shifts.${option.shift}`) }}
          ·
          {{ $tc(
            'commons.studentsCount',
            option.studentsAmount,
            { count: option.studentsAmount },
          ) }}
        </div>
      </template>
    </AdaptiveSelector>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import accentFold from '@/mixins/accentFold'
import AdaptiveSelector from '@/components/AdaptiveSelector/AdaptiveSelector'
import ClassPickerSkeleton from './ClassPickerSkeleton'

export default {
  name: 'ClassPicker',
  components: {
    AdaptiveSelector,
    ClassPickerSkeleton,
  },
  mixins: [ accentFold ],
  model: {
    prop: 'selectedClassroom',
    event: 'select',
  },
  props: {
    classrooms: {
      type: Array,
      default: null,
    },
    selectedClassroom: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    mappedSelectedClassroom() {
      if (this.selectedClassroom === null) {
        return null
      }

      const {
        id,
        name,
        shift,
        studentsAmount,
        grade,
        gradeName,
        gradeId,
        lectures,
      } = this.selectedClassroom

      return {
        id,
        name,
        shift: shift?.toLowerCase(),
        studentsAmount,
        gradeName: grade?.name ?? gradeName,
        gradeId: grade?.id ?? gradeId,
        lectures,
        disabled: false,
      }
    },
    mappedClassrooms() {
      if (!this.filteredClassrooms) {
        return []
      }

      return this.filteredClassrooms.map(({
        id,
        name,
        shift,
        studentsAmount,
        grade,
        lectures,
      }) => ({
        id,
        name,
        shift: shift?.toLowerCase(),
        studentsAmount,
        gradeName: grade.name,
        gradeId: grade.id,
        lectures,
        disabled: studentsAmount === 0,
      }))
    },
    filteredClassrooms() {
      if (!this.classrooms) {
        return []
      }

      return this.classrooms.filter((classroom) => (
        this.accentFold(classroom.name.toLocaleLowerCase())
          .includes(
            this.accentFold(this.searchString.toLocaleLowerCase())
          )
      ))
    },
  },
  watch: {
    selectedClassroom() {
      this.setActiveClassroom(this.selectedClassroom)
    },
  },
  methods: {
    ...mapActions([
      'setActiveClassroom',
    ]),
    select(classroom) {
      if (classroom) {
        this.$emit('select', this.classrooms.find(
          ({ id }) => id === classroom.id
        ))
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$class-picker-shadow: $shadow-s rgba($color-ink, .25)

.class-picker
  color: $color-ink

  &__handle
    &__text
      flex-grow: 1

    ::v-deep &__icon
      color: $color-ink-lighter
      flex-shrink: 1
      margin-right: $size-s
      height: $size-m
      width: $size-m

  &__class-name
    font-size: $font-size-heading-6-small
    font-weight: $font_weight_medium
    margin-bottom: $size-xxs

    +mq-m--mf
      font-size: $font-size-heading-6

  &__details
    font-size: $font-size-s
    color: $color-ink-light

  &__options
    &__header
      &__title
        flex-grow: 1

      &__icon
        color: $color-ink-lighter
        flex-shrink: 1
        margin-right: $size-s
        height: $size-m
        width: $size-m

        ::v-deep .feather__content
          height: 100%
          width: 100%
</style>
