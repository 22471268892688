<template>
  <transition name="fade">
    <div
      v-if="userGameData.success"
      :class="hideBadgeOnMobileClass"
      class="user-status-bar"
    >
      <LevelDisplay
        class="user-status-bar__control"
        :level="userGameData.data.level"
        :rank="userGameData.data.rank"
        :rank-label-is-visible="mq_xl__mf"
        :color="color"
      />
      <XpDisplay
        class="user-status-bar__control"
        :experience="userExperience"
        :level="userGameLevel"
        :xp-amount="true"
        :has-frame="false"
        variation="polygon"
      />
      <WalletDisplay
        class="user-status-bar__control"
        currency-type="coin"
        :quantity="userGameData.data.coin.quantity"
      />
      <UserConfig
        v-if="soundPreferences.data"
        class="user-status-bar__control hide-on-mobile"
      >
        <ConfigControl
          :label="$t('commons.sounds')"
          :control-id="`${$route.name}-sounds`"
          :control-value="soundPreferences.data.sound"
          @valueChange="toggleSounds"
        />
        <ConfigControl
          :label="$t('commons.music')"
          :control-id="`${$route.name}-background`"
          :control-value="soundPreferences.data.music"
          @valueChange="toggleMusic"
        />
        <ConfigControl
          class="hide-on-desktop"
          label="Grade"
          :icon="gridIcon"
          :control-id="`${$route.name}-grid`"
          :control-value="userPreferences.data.worldsLayoutGrid"
          @valueChange="toggleGrid"
        />
      </UserConfig>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { trackSoundPreferenceEvent, trackMusicPreferenceEvent } from '@/components/UserConfig/events'
import mediaQueries from '@/mixins/mediaQueries'
import grid from '@/assets/grid.svg'
import ConfigControl from './UserConfig/ConfigControl'
import XpDisplay from './XpDisplay'
import WalletDisplay from './WalletDisplay'
import LevelDisplay from './LevelDisplay'
import UserConfig from './UserConfig/UserConfig'

export default {
  name: 'UserStatusBar',
  components: {
    XpDisplay,
    LevelDisplay,
    UserConfig,
    ConfigControl,
    WalletDisplay,
  },
  mixins: [ mediaQueries ],
  props: {
    color: {
      type: String,
      default: '#000000',
    },
    hideBadgeOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridIcon: grid,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'userGameData',
      'userPreferences',
      'soundPreferences',
    ]),
    userExperience() {
      return this.userGameData.data?.experience
        || { amount: 0, currentLevelValue: 0, nextLevelValue: 0 }
    },
    userGameLevel() {
      return this.userGameData.data?.level || 0
    },
    hideBadgeOnMobileClass() {
      return this.hideBadgeOnMobile ? 'hide-badge-on-mobile' : ''
    },
  },
  methods: {
    ...mapActions([ 'setSoundPreferences', 'setUserPreferences' ]),
    toggleSounds({ value }) {
      trackSoundPreferenceEvent(this, value)
      this.setSoundPreferences({ sound: value })
    },
    toggleMusic({ value }) {
      trackMusicPreferenceEvent(this, value)
      this.setSoundPreferences({ music: value })
    },
    toggleGrid({ value }) {
      this.$trackEvent({
        category: this.$track.category.worlds,
        action: this.$track.action.worldsVisualization,
        label: value ? 'grid' : 'carousel',
      })
      this.setUserPreferences({
        worldsLayoutGrid: value,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-on-mobile {
  display: none;

  @include mq-media--mf(680px){
    display: block;
  }
}

.user-status-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: $size-xs $size-s;

  @media screen and (max-width: 991px) {
    justify-content: center;
  }

  &__control {
    @include space-inline($size-xs);

    @include mq_l__mf {
      @include space-inline($size-m);
    }

    .hide-on-desktop {
      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .hide-badge-on-mobile {
    padding: $size-xs 0 6px $size-xs;
    justify-content: flex-end;

    .level-display {
      display: none !important;
    }
  }
}
</style>
