var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.hasInitialSplashScreen && _vm.splashScreen)?_c('SplashScreen',{on:{"close":_vm.closeSplashScreen}}):_c('div',{class:[
        'worlds',
        {
          '--has-teacher-bar': _vm.isTeacherTopbarVisible,
          '--adapt': _vm.userIsAdapt,
          '--mfe-margin': _vm.isMFE,
        }
      ]},[(_vm.isTeacherTopbarVisible)?_c('TeacherTopbar'):_vm._e(),_c('header',{staticClass:"worlds__header"},[_c('div',{staticClass:"worlds__header__title"},[_c('img',{staticClass:"worlds__header__logo",attrs:{"src":require("@/assets/logo.svg")}}),(_vm.userPreferences.data || _vm.soundPreferences.data)?_c('UserConfig',{staticClass:"user-status-bar__control hide-on-desktop",attrs:{"aria-label":"Configuração de Som"}},[_c('ConfigControl',{attrs:{"label":"Sons","icon":_vm.soundIcon,"control-id":`${_vm.$route.name}-sounds`,"control-value":_vm.soundPreferences.data.sound},on:{"valueChange":_vm.toggleSounds}}),_c('ConfigControl',{attrs:{"label":"Música","icon":_vm.soundIcon,"control-id":`${_vm.$route.name}-background`,"control-value":_vm.soundPreferences.data.music},on:{"valueChange":_vm.toggleMusic}}),_c('ConfigControl',{attrs:{"label":"Grade","icon":_vm.gridIcon,"control-id":`${_vm.$route.name}-grid`,"control-value":_vm.userPreferences.data.worldsLayoutGrid},on:{"valueChange":_vm.toggleGrid}})],1):_vm._e()],1),_c('UserStatusBar')],1),(_vm.lectures.success && !_vm.lectures.loading)?_c('div',{class:['worlds__content', {'--column-4': _vm.columnStyle}]},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.isGridView && !_vm.isThirdYearUser)?_c('WorldsListCarousel',{on:{"go-to-world":_vm.goToWorld,"active-world":_vm.activeWorld}}):_vm._e(),(_vm.isGridView || _vm.isThirdYearUser)?_c('WorldsListGrid',{on:{"go-to-world":_vm.goToWorld,"active-world":_vm.activeWorld}}):_vm._e()],1)],1):_c('div',[_c('GLoader',{staticClass:"load-state"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }