/*
-------------      false      ------------
full string:   "asdasd"             false
full array:    ["asd", "dsa"]       false
full array:    [{a:aaa, b:bbb}]     false
full object:   {a:asd, b:asd}       false
number:        0                    false
number:        1                    false
number:        2.5                  false
number:        -2.5                 false

--------------      true      -------------
null:                               true
undefined:                          true
empty string:  ""                   true
empty array:   []                   true
empty object:  {}                   true
*/

export default function isEmpty(value) {
  if (typeof value === 'number') {
    return false
  }

  return [ Object, Array ].includes((value || {}).constructor)
    && !Object.entries((value || {})).length
}
