<template>
  <div
    :class="[
      'cine-mode',
      {
        '--no-header': mustHideHeader,
        '--mfe-padding': isMFE
      }]"
  >
    <div
      :class="['cine-mode__header', { '--is-mobile': !isXLargeBreakpoint }]"
    >
      <SkipButton
        class="skip-button"
        :theme="skipButtonTheme"
        @skip="$emit('skip')"
      />
    </div>
    <div
      class="cine-mode__body"
      :style="{backgroundImage: backgroundScene}"
    >
      <slot />
    </div>
    <div class="cine-mode__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import mediaQueries from '@/mixins/mediaQueries'
import SkipButton from './SkipButton'

export default {
  name: 'CineMode',
  components: { SkipButton },
  mixins: [ mediaQueries ],
  props: {
    backgroundScene: {
      type: String,
      default: '',
    },
    mustHideHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    skipButtonTheme() {
      return this.isXLargeBreakpoint ? 'light' : 'dark'
    },
    isMFE() {
      return window.eureka_init
    },
  },
}
</script>

<style lang="sass" scoped>
.cine-mode
  +cover
  background: #000
  height: 100%
  display: grid
  grid-template-rows: 56px 9fr 56px

  &.--mfe-padding
    padding-top: 56px
    padding-bottom: 56px

  &.--no-header
    grid-template-rows: 0px 9fr 1fr

  &__header
    display: flex
    align-items: center
    justify-content: flex-end
    box-sizing: content-box
    z-index: 3

    .skip-button
      position: absolute
      top: 0
      right: 0

      @media screen and (min-width: 991px)
        position: relative

    &.--is-mobile
      align-items: flex-start

  &__body
    background-repeat: no-repeat
    background-attachment: fixed
    background-position: bottom
    background-size: cover
</style>
