<template>
  <button
    v-tooltip="{
      disabled: !isAnswered,
      content: 'Questão respondida',
      placement: 'top',
    }"
    :class="[
      'question-selector',
      {
        '--answered': isAnswered,
        '--selected': isSelected,
      },
    ]"
    :aria-label="`questão ${text}`"
    @click="handleClick"
  >
    {{ text }}
  </button>
</template>

<script>

export default {
  name: 'QuestionSelector',
  props: {
    id: {
      type: [ String, Number ],
      required: true,
    },
    text: {
      type: [ String, Number ],
      required: true,
    },
    isAnswered: {
      type: Boolean,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.question-selector {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: rgba($eureka-color-ink-light, 0.5);
  border-radius: 50%;
  cursor: pointer;
  border: 1.5px solid transparent;
  font-family: Rowdies;
  font-weight: $font-weight-regular;
  font-size: $font-size-m;
  line-height: $line-height-heading;
  color: $color-white;
  text-shadow: 0px 1px 0px $color-black;
  margin: 0 8px;

  &:hover {
    background: $eureka-color-ink-light;
  }

  &.--answered {
    background: $eureka-color-notice;

    &:hover {
      background: $eureka-color-notice-light;
    }
  }

  &.--selected {
    border: 1.5px solid $color-white;
  }
}
</style>
