<template>
  <div class="user-config">
    <button
      class="user-config__icon"
      background="transparent"
      variation="tertiary"
      aria-label="Configuração de Som"
      @click="toggleViewConfig"
    >
      <img
        alt=""
        src="@/assets/config-icon.svg"
      >
    </button>
    <div
      v-if="showConfigOptions"
      class="vue-overlay"
      @click="toggleViewConfig"
    />
    <GFrame
      v-if="showConfigOptions"
      class="user-config__frame"
      :overlay-enabled="false"
      :close-button-enabled="false"
      @close="setShowConfig(false)"
    >
      <div class="user-config__frame__body">
        <slot />
        <div
          class="user-config__close-app"
          @click="goOutOfPortal"
        >
          <img
            alt="Voltar para o Portal SAS Educação"
            src="@/assets/news-arrow-left.svg"
          >
          Fechar o Eureka
        </div>
      </div>
    </GFrame>
  </div>
</template>

<script>
import { playSoundFX } from '@/utils/soundEffects'
import redirectTo from '@/utils/redirectTo'
import GFrame from '@/components/GFrame'
import { getPortalHomeURL } from '@/utils/urls'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

export default {
  name: 'UserConfig',
  components: {
    GFrame,
  },
  data() {
    return {
      showConfigOptions: false,
      title: 'Opções',
    }
  },
  methods: {
    goOutOfPortal() {
      const isMicrofrontend = getIsMicrofrontend()
      const portalHomeURL = getPortalHomeURL(isMicrofrontend)

      redirectTo(portalHomeURL)
    },
    toggleViewConfig() {
      const { showConfigOptions } = this

      playSoundFX()
      this.showConfigOptions = !showConfigOptions

      if (!showConfigOptions) {
        this.$trackEvent({
          category: this.$track.category.menus,
          action: this.$track.action.openSettings,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vue-overlay {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2.5px);
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 100;
  position: fixed;
  cursor: pointer;
}

.user-config {
  position: relative;
  height: 47px;

  &__close-app {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 18px;
    font-weight: 700;
    font-size: 1.125rem;
    cursor: pointer;

    img {
      width: 38px;
      height: 38px;
      margin: 0 0 3px;
    }
  }

  &__frame {
    z-index: 999;
    position: absolute;
    right: -5px;
    top: 45px;
    width: 270px;
    background: transparent;
    box-shadow: none;

    &__close-button {
      border: none !important;
      background-color: transparent;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      img {
        display: block;
        outline: none;
      }
    }

    &__header {
      @include flex-space-between;
      padding: $font-size-xs $size-s;
      margin-bottom: $size-m;
      border-bottom: 2px solid #F3E8CC;
    }

    ::v-deep .g-frame {
      width: 280px;
    }

    &__body {
      padding: $size-s;
      gap: 18px;
      display: flex;
      flex-direction: column;
    }
  }

  &__icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 44px;
    width: 37px;
    margin-right: 16px;

    &:focus {
      outline: none;
    }

    img {
      display: block;
      outline: none;
    }
  }
}
</style>
