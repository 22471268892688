<template>
  <div class="hit-rate">
    <div class="hit-rate__body">
      <div class="hit-rate__chart">
        <radial-progress-bar
          inner-stroke-color="#CEC1BD"
          start-color="#2B70C9"
          stop-color="#2B70C9"
          :inner-stroke-width="donutStroke"
          :stroke-width="donutStroke"
          :diameter="donutSize"
          :total-steps="100"
          :completed-steps="hitRate"
        >
          <h4 class="hit-rate__percentage">
            {{ hitRate }}%
          </h4>
        </radial-progress-bar>
      </div>
      <div class="hit-rate__text">
        <h3 class="hit-rate__text__title">
          {{ title }}
        </h3>
        <p class="hit-rate__text__count">
          {{ subtitle }}
        </p>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'HitRate',
  components: {
    RadialProgressBar,
  },
  mixins: [ mediaQueries ],
  props: {
    hitRate: {
      type: Number,
      default: 0,
    },
    correct: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    standard: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: null,
    },
    stroke: {
      type: Number,
      default: null,
    },
  },
  computed: {
    subtitle() {
      return this.standard ? this.$t('ancestralArena.magnaMissionReport.studentReport.hitsCount',
        {
          count: this.correct,
          total: this.total,
        }) : this.$t('ancestralArena.magnaMissionReport.teacherReport.hitsCount', {
        total: this.total,
      })
    },
    donutSize() {
      if (this.size) return this.size
      if (this.mq_s) return 90
      if (this.mq_m) return 110
      if (this.mq_l) return 120

      return 160
    },
    donutStroke() {
      if (this.stroke) return this.stroke
      if (this.mq_s) return 8
      if (this.mq_l) return 10

      return 12
    },
  },
}
</script>

<style lang="scss" scoped>
.hit-rate {
  &__body {
    padding: $size-s $size-m;
    @include flex-center-start;
  }

  &__chart {
    margin-right: $size-s;

    @include mq-m__mf {
      margin-right: $size-l;
    }
  }

  &__percentage {
      color: $eureka-color-ink;
      font: {
        family: 'Rowdies';
        size: 2rem;
      }
  }

  &__text {
    &__title {
      margin-bottom: $size-xs;
      white-space: nowrap;
      color: $eureka-color-ink;
      font: {
        family: 'Rowdies';
        size: 2.5rem;
      }
      @media screen and (max-height: 750px) {
        font-size: $font-size-heading-3;
      }
    }

    &__count {
      color: $color-ink-light;
      font: {
        family: 'Lato';
        size: $font-size-heading-5;
        weight: $font-weight-medium;
      }
      @media screen and (max-height: 750px) {
        font-size: $font-size-heading-6-small;
      }
    }
  }
}
</style>
