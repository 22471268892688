<template>
  <section class="cutscene">
    <CineMode @skip="skipScene">
      <div class="cutscene__body">
        <template v-if="contentsType === 'IMAGE'">
          <img
            class="cutscene__body__comic-image"
            :src="sceneContents.image.png"
          >
        </template>
        <template v-else-if="contentsType === 'VIDEO'">
          <video
            class="cutscene__body__comic-video"
            autoplay="true"
          >
            <source
              :src="sceneContents.video.url"
            >
          </video>
        </template>
        <template v-else-if="contentsType === 'LOTTIE'">
          <Lottie :options="defaultOptions" />
        </template>
        <AdventureDialog
          :class="['dialog', {'leave-transition': isSkiping}]"
          :title="dialog.actor"
          :dialog="dialog.text"
          :hide-next-button="dialog.hasNextButton"
          @next="$emit('go-to-next-scene', scene)"
        />
      </div>
    </CineMode>
  </section>
</template>

<script>
import gsap from 'gsap'
import { mapGetters } from 'vuex'

import Lottie from '../Lottie'
import AdventureDialog from '../AdventureDialog/AdventureDialog'

import CineMode from '../CineMode'

export default {
  name: 'Cutscene',
  components: {
    CineMode,
    Lottie,
    AdventureDialog,
  },
  props: {
    scene: {
      type: Object,
      required: true,
    },
    isSkiping: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([ 'historyEpisode' ]),
    sceneContents() {
      return this.scene.contents
        .find(({ platform }) => platform === 'web')
    },
    contentsType() {
      return this.sceneContents.type
    },
    defaultOptions() {
      if (this.contentsType === 'LOTTIE') {
        return {
          path: this.sceneContents.content,
        }
      }

      return {}
    },
    dialog() {
      const { scene } = this
      const [ actorInEvidence ] = scene.characters
        .filter(({ inEvidence }) => inEvidence)

      return {
        actor: actorInEvidence?.name,
        text: scene.dialog,
        hasNextButton: false || scene.outgoingScenes.length > 0,
      }
    },
  },
  watch: {
    sceneContents() {
      this.fadeCutscene()
    },
  },
  mounted() {
    this.fadeCutscene()
  },
  methods: {
    skipScene() {
      this.$trackEvent({
        category: this.$track.category.history,
        action: this.$track.action.skipHistory,
        label: this.scene.number,
      })
      this.$emit('skip')
    },
    fadeCutscene() {
      gsap.fromTo('.cutscene__body', { opacity: 0 }, { opacity: 1, duration: 2 })
    },
  },
}
</script>

<style lang="scss" scoped>
.cutscene {
  position: relative;
  height: 100vh;

  &__body {
    height: 100%;
    bottom: $size-xl;
    @include flex-center;
    flex-direction: column;

    padding: 0 12%;

    @media screen and (max-width: 991px) {
      padding: 0 6%;
    }

    @media screen and (max-width: 568px) {
      padding: 0 3%;
    }

    &__comic-image {
      max-height: 100%;
      max-width: 100%;
      border-radius: $size-l;
    }

    &__comic-video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  ::v-deep .cine-mode {
    &__body {
      max-height: 85%;
    }
  }
}

.dialog {
  @include adventure-dialog-position {
    width: 76%;
  }
}

</style>
