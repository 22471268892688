<template>
  <div class="magna-mission-satisfaction-survey">
    <img
      :src="icon"
      alt=""
    >
    <button
      class="magna-mission-satisfaction-survey__button"
      @click="openFeedbackSurvey"
    >
      {{ $t('survey.magnaMissionReport.whatDidYouThinkAboutMission') }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { arrayUniq } from '@sas-te/frontend-utils/modules/arrays'
import typeform from '@/service/typeform'
import startIcon from '@/assets/star.svg'
import userIsStudent from '@/mixins/userIsStudent'
import urls from '@/utils/urls'

export default {
  name: 'MagnaMissionSatisfactionSurvey',
  mixins: [ userIsStudent ],
  props: {
    isOpenAutomatically: Boolean,
  },
  computed: {
    ...mapState({
      magnaMission: (state) => state.arena.magnaMission.data,
    }),
    ...mapGetters([
      'user',
      'grade',
      'classrooms',
    ]),
    icon() {
      return startIcon
    },
    teacherGrades() {
      const gradeNamesList = this.user.data.grades.map(({ name }) => name)

      return arrayUniq(gradeNamesList)
    },
    teacherLectures() {
      const list = []

      this.classrooms.data
        .forEach(({ lectures }) => {
          lectures.forEach(({ name }) => { list.push(name) })
        })

      return arrayUniq(list)
    },
  },
  watch: {
    isOpenAutomatically: {
      immediate: true,
      handler(isOpenAuto) {
        if (isOpenAuto) {
          this.openFeedbackSurvey()
        }
      },
    },
  },
  methods: {
    openFeedbackSurvey() {
      const appBaseUrl = urls.BASE_URL
      const formId = 'dYhUtdPZ'
      const url = appBaseUrl.concat(this.$route.path)
      const accountId = this.user.data.id
      const grade = this.userIsStudent ? this.grade?.name : this.teacherGrades
      const lecture = this.userIsStudent ? null : this.teacherLectures
      const platform = 'web'
      const profile = this.user.data.profiles[0].name
      const acertos = this.userIsStudent ? this.magnaMission.hit : `${this.magnaMission.hit}%`
      const magnaTitle = this.magnaMission.title

      const typeformPopup = typeform.createPopup({
        formId,
        hiddenFields: {
          url,
          account_id: accountId,
          grade,
          lecture,
          platform,
          profile,
          acertos,
          magna_title: magnaTitle,
        },
      })

      typeformPopup.open()
      this.$trackEvent({
        category: this.$track.category.ancestralArena,
        action: this.$track.action.replyFeedback,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.magna-mission-satisfaction-survey {
  @include flex-center;
  gap: $size-s;

  &__button {
    color: $color-white;
    background: transparent;
    border: none;
    cursor: pointer;
    font: {
      family: Rowdies;
      weight: $font-weight-regular;
      size: $font-size-s;
    }
    text-shadow: 0 0.8px 0 $color-black;
    margin: 0 12px;
    font-size: $font-size-heading-5;

    @media screen and (max-width: 579px) {
      margin: $size-s 0;
    }
  }
}
</style>
