var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['ancestral-arena-card-question', {
    '--disabled': _vm.option.isDisabledOption,
    '--correct' : _vm.option.isCorrectOption,
    '--wrong' : _vm.option.isWrongOption,
    '--selected': _vm.option.isSelectedOption,
    '--error': _vm.option.hasSomeError,
  }],style:(_vm.styleCard),attrs:{"aria-label":"Alternativa"},on:{"click":_vm.selectOption}},[(_vm.option.markOption)?_c('div',{staticClass:"--selected-mark",style:(_vm.cardMarkStyle),attrs:{"aria-label":"Alternativa Selecionada"}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"ancestral-arena-card-question__info"},[_c('skeleton-loader',{staticClass:"ancestral-arena-card-question__info__letter",attrs:{"opacity":"0.5","width":"40px","height":"68px","border-radius":"8px 0px 0px 8px"}}),_c('skeleton-loader',{staticClass:"ancestral-arena-card-question__info__description__skeleton",attrs:{"opacity":"0.5","width":"300px","height":"20px","border-radius":"8px"}})],1):[_c('div',{staticClass:"ancestral-arena-card-question__info"},[_c('div',{staticClass:"ancestral-arena-card-question__info__letter",style:(_vm.styleLetter),attrs:{"aria-label":`Alternativa ${_vm.option.letter}`}},[_vm._v(" "+_vm._s(_vm.option.letter)+" ")]),_vm._t("description",function(){return [_c('div',{staticClass:"ancestral-arena-card-question__info__description",attrs:{"aria-label":"Descrição"},domProps:{"innerHTML":_vm._s(_vm.option.description)}})]}),_vm._t("icon",function(){return [(_vm.option.isCorrectOption)?_c('img',{staticClass:"ancestral-arena-card-question__info__icon",attrs:{"aria-label":"Alternativa Correta","src":require("@/assets/feedback/check-badge.svg"),"alt":""}}):_vm._e(),(_vm.option.isWrongOption)?_c('img',{staticClass:"ancestral-arena-card-question__info__icon",attrs:{"data-testid":"Alternativa Incorreta","src":require("@/assets/feedback/wrong-badge.svg"),"alt":""}}):_vm._e()]})],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }