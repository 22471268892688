<template>
  <div
    v-if="!isSmallScreen"
    class="empty-state"
  >
    <img
      class="empty-state__illustration"
      :src="illustration.path"
      :alt="illustration.alt"
    >
    <section class="empty-state__content">
      <h3 class="empty-state__content__title">
        {{ title }}
      </h3>
      <p class="empty-state__content__subtitle">
        {{ subtitle }}
      </p>
      <slot />
    </section>
  </div>
  <div
    v-else
    class="empty-state"
  >
    <section class="empty-state__content">
      <h3 class="empty-state__content__title">
        {{ title }}
      </h3>
      <p class="empty-state__content__subtitle">
        {{ subtitle }}
      </p>
      <slot />
    </section>
    <img
      class="empty-state__illustration"
      :src="illustration.path"
      :alt="illustration.alt"
    >
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    illustration: {
      type: Object,
      default: () => ({ path: '', alt: '' }),
      validator: (value) => 'path' in value && 'alt' in value,
    },
    isSmallScreen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-state {
  gap: $size-xl;
  margin: $size-m auto;
  display: flex;
  align-items: center;

  @media screen and (max-width: 579px) {
    flex-direction: column;
    height: 100%;
    gap: $size-m;
    margin: 0;
  }

  &__content{
    @media screen and (max-width: 579px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__title {
      @include arena-title-style;
      margin-top: 0;
      text-shadow: 0 1px 0 $color-black;
    }

    &__subtitle {
      color: $color-white;
      font: {
        family: 'Lato';
        weight: 500;
      }
    }
  }
}
</style>
