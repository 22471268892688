<template>
  <div class="news-article-skeleton">
    <div class="news-article-skeleton__info">
      <skeleton-loader
        width="60px"
        height="16px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        width="64px"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
    </div>
    <div class="news-article-skeleton__body">
      <skeleton-loader
        height="120px"
        opacity="0.3"
        border-radius="8px"
        class="news-article-skeleton__body__image"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="65%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
      <skeleton-loader
        class="news-article-skeleton__body__text"
        width="90%"
        height="8px"
        opacity="0.3"
        border-radius="8px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsArticleSkeleton',
}
</script>

<style lang="sass" scoped>
.news-article-skeleton
  &__info
    +flex-center-start
    margin-bottom: $size-xs

    & > *
      margin-right:$size-xs

  &__title
    & > *
      margin-bottom: $size-xs

  &__body
    &__image
      margin: $size-s 0

    & > :not(:first-child)
      margin-bottom: $size-xs
</style>
