<template>
  <Dropdown
    ref="multiselectDrop"
    class="dropdown-action"
    :disabled="disabled"
    :loading="loading"
    :multiselect-tags="multiselectTags || multiselectCounter"
    origin="bottom-left"
    :size="size"
    :status="status"
    :text="text"
    @blur="$emit('blur')"
    @clicked="$emit('clicked')"
    @toggle="onDropdownToggle($event)"
  >
    <template
      v-if="multiselectTags || multiselectCounter"
      #multiselect-tags
    >
      <div
        v-if="selectedItems.length === 0"
        :id="labelId"
        class="dropdown-action-tag-placeholder"
      >
        {{ placeholder }}
      </div>

      <div
        v-if="multiselectCounter && selectedItems.length !== 0"
        :id="labelId"
        class="dropdown-action-tag-placeholder"
      >
        {{ selectedItems[0].name || selectedItems[0].text }}
      </div>

      <template v-if="selectedItems.length !== 0">
        <div
          v-if="multiselectCounter && selectedItems.length > 1"
          ref="dropdownActionTag"
          data-testid="counter"
          class="dropdown-action-tag-counter"
        >
          {{ `+${selectedItems.length - 1}` }}
        </div>
        <div v-if="!multiselectCounter">
          <div
            v-for="(item, index) of selectedItems"
            :key="index"
            ref="dropdownActionTag"
            class="dropdown-action-tag"
          >
            {{ getText(item) }}

            <Icon
              v-if="isOpen"
              class="dropdown-action-unselect"
              size="18"
              stroke-width="3"
              type="x"
              @click="unselectItem(item)"
            />
          </div>
        </div>
      </template>
    </template>

    <ul
      :aria-labelledby="labelId"
      :class="{ 'scroll-container': scrollOptionsContainer }"
    >
      <li
        v-if="multiselect && canSelectAll && items.length > 0"
        @click="toggleSelectAll($event)"
      >
        <div class="dropdown-action-item">
          <input
            :checked="isAllSelected"
            class="dropdown-action-input"
            type="checkbox"
          >
          <label>Todos</label>
        </div>
      </li>

      <li
        v-for="(item, index) of organizedItems"
        :key="index"
      >
        <template v-if="!!groupBy">
          <div class="dropdown-action-group-label">
            {{ item[0][groupTitle] || index }}
          </div>

          <div
            v-for="_item of item"
            :key="getValue(_item)"
            :class="[
              'dropdown-action-item',
              {
                multiselect,
                'dropdown-action-item-active': !multiselect
                  && isSelected(_item),
              },
            ]"
            @click.stop="setValue(_item)"
          >
            <template v-if="multiselect">
              <input
                :checked="isSelected(_item)"
                class="dropdown-action-input"
                type="checkbox"
              >
              <label>{{ getText(_item) }}</label>
            </template>

            <template v-else>
              {{ getText(_item) }}
            </template>
          </div>
        </template>

        <div
          v-else
          :class="[
            'dropdown-action-item',
            {
              multiselect,
              'dropdown-action-item-active': !multiselect && isSelected(item),
            },
          ]"
          @click.stop="setValue(item)"
        >
          <template v-if="multiselect">
            <input
              :checked="isSelected(item)"
              class="dropdown-action-input"
              type="checkbox"
            >
            <label>{{ getText(item) }}</label>
          </template>

          <template v-else>
            {{ getText(item) }}
            <icon
              v-if="isSelected(item)"
              class="dropdown-action-item-icon"
              type="check"
              stroke-width="3"
            />
          </template>
        </div>
      </li>
    </ul>

    <button
      v-for="(action, index) of actions"
      :key="index"
      class="dropdown-action-btn"
      @click="doAction(action)"
    >
      {{ action.name }}
    </button>
  </Dropdown>
</template>

<script>
import { Dropdown, Icon } from '@sas-te/alfabeto-vue'
import { groupBy } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'

import { getPropertyFromItem } from './utils/helpers'

export default {
  name: 'DropdownAction',
  components: {
    Dropdown,
    Icon,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    actions: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: [ String, Array, Function ],
      default: 'text',
    },
    itemValue: {
      type: [ String, Array, Function ],
      default: 'value',
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    staticPlaceholder: Boolean,
    multiselect: Boolean,
    multiselectTags: Boolean,
    multiselectCounter: Boolean,
    scrollOptionsContainer: {
      type: Boolean,
      default: true,
    },
    groupBy: {
      type: String,
      default: null,
    },
    groupTitle: {
      type: String,
      default: 'groupTitle',
    },
    value: {
      type: [ String, Object, Number, Array ],
      default: () => {},
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => value.match(/(small|medium|large)/),
    },
    status: {
      type: String,
      default: null,
      validator: (value) => value.match(/(success|error|warning)/),
    },
    disabled: Boolean,
    loading: Boolean,
    canSelectAll: Boolean,
    resetWhenItemsChange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lastAction: null,
      selectedItems: [],
    }
  },
  computed: {
    labelId() {
      return `${uuidv4()}Label`
    },
    text() {
      const {
        returnObject,
        getValue,
        getText,
        items,
        placeholder,
        multiselect,
        multiselectTags,
        selectedItems,
        lastAction,
        value,
      } = this

      if (lastAction !== null) {
        return lastAction.name
      }

      if (selectedItems.length === 0 || this.staticPlaceholder) {
        return placeholder
      }

      if (multiselect) {
        let labels = selectedItems

        if (multiselectTags) {
          return ''
        }

        if (!returnObject) {
          labels = items
            .filter((_item) => selectedItems.includes(getValue(_item)))
        }

        return labels.map((item) => getText(item)).join(', ')
      }

      const item = items
        .find((_item) => getValue(_item) === (
          returnObject
            ? getValue(value)
            : value
        ))

      return item ? getText(item) : placeholder
    },
    isAllSelected() {
      return this.selectedItems.length === this.items.length
    },
    organizedItems() {
      if (!this.groupBy) {
        return this.items
      }

      return groupBy(this.items, this.groupBy)
    },
    isOpen() {
      if (this.$refs.multiselectDrop) {
        return this.$refs.multiselectDrop.menuVisible
      }

      return false
    },
  },
  watch: {
    items() {
      if (this.resetWhenItemsChange) {
        this.lastAction = null
        this.selectedItems = []
        this.close()
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (this.value) {
          this.selectedItems = this.multiselect ? [ ...this.value ] : [ this.value ]
        }
      },
    },
  },
  methods: {
    getText(item) {
      return String(getPropertyFromItem(item, this.itemText, item))
    },
    getValue(item) {
      return getPropertyFromItem(item, this.itemValue, this.getText(item))
    },
    setValue(item) {
      const returnValue = this.returnObject ? item : this.getValue(item)

      this.lastAction = null

      if (!this.multiselect) {
        this.selectedItems = [ returnValue ]
        this.close()

        return
      }

      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => {
          if (this.returnObject) {
            return this.getValue(selectedItem) !== this.getValue(item)
          }

          return selectedItem !== returnValue
        })
      } else {
        this.selectedItems.push(returnValue)
      }
    },
    doAction(action) {
      if (typeof action.callback !== 'function') {
        return
      }

      if (!action.hideLabel) {
        this.lastAction = action
        this.selectedItems = []
      }

      this.close()
      action.callback()
    },
    unselectItem(item) {
      this.$emit('unselect-tag', this.returnObject ? item : this.getValue(item))
    },
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.selectedItems = []
      } else if (this.returnObject) {
        this.selectedItems = this.items
      } else {
        this.selectedItems = this.items.map((item) => this.getValue(item))
      }
    },
    isSelected(item) {
      const {
        getValue, selectedItems, returnObject,
      } = this

      if (returnObject) {
        return (Array.isArray(selectedItems) ? selectedItems : [])
          .some((_item) => getValue(_item) === getValue(item))
      }

      return selectedItems.includes(getValue(item))
    },
    onDropdownToggle(isOpen) {
      const returnValue = this.multiselect
        ? this.selectedItems
        : this.selectedItems[0]

      if (isOpen) {
        return this.$emit('open')
      }

      if (
        !!returnValue
        && this.value !== returnValue
      ) {
        this.$emit('change', returnValue)
      }

      return this.$emit('close')
    },
    close() {
      this.$refs.multiselectDrop.menuVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-action {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 0 !important;
}

.dropdown-action-input {
  margin-right: 18px;
  min-width: $size-m;
}

.dropdown-action-label {
  display: flex;
  align-items: center;
  height: auto !important;
  padding: $size-s 22px;
  font-size: $font-size-s;
  font-weight: $font-weight-medium;
  color: $color-ink-light;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background-color: $eureka-color-base;
  }
}

.dropdown-action-unselect {
  width: 18px;
  height: 18px;
  margin-left: 7px;
  cursor: pointer;
}

.dropdown-action-tags-count {
  position: absolute;
  top: $size-xxs;
  right: 30px;
}

.dropdown-action-tag-counter {
  display: inline-flex;
  align-items: center;
  margin-top: $size-xs;
  margin-bottom: $size-xs;
  margin-left: $size-s;
  padding: 2px $size-xs;
  background: $eureka-color-ink-light;
  border-radius: 20px;
  font-size: $font-size-s;
  cursor: default;
  color: $eureka-color-ice;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-action-tag {
  display: inline-flex;
  align-items: center;
  margin: $size-xxs;
  padding: $size-xxs 10px;
  background: $color-primary-lightest;
  border-radius: 20px;
  font-size: $font-size-s;
  cursor: default;
  color: $color-primary-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-container {
  max-height: 304px;
  overflow-y: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: auto;
  scrollbar-width: thin;
  scrollbar-color: $eureka-color-base-darker $eureka-color-base-lighter;
}

.dropdown-action-item {
  font-family: Rowdies;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;

  label {
    color: #2D1F49;
  }
}

.dropdown-action-item,
.dropdown-action-btn {
  padding: $size-s 14px;
  font-size: $font-size-m;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $eureka-color-base;
  }

  label {
    cursor: pointer;
  }
}

.dropdown-action-item-active {
  justify-content: space-between;
}

::v-deep .dropdown-action-item-icon {
  color: $eureka-color-ink-light;
}

.dropdown-action-btn {
  appearance: none;
  background-color: $color-ice;
  border: 0;
  border-top: 1px solid $color-ink-lightest;
  width: 100%;
  margin-bottom: -$size-xxs;
  border-radius: 0 0 $border-radius-m $border-radius-m;
  cursor: pointer;
  @include transition(.3s);

  &:hover {
    background-color: $color-ink-lightest;
  }
}

.dropdown-action-group-label {
  padding: 20px $size-s 12px $size-s;
  font-weight: $font-weight-medium;
  font-size: 0.6875rem;
  color: $color-ink-light;
  text-transform: uppercase;
}

::v-deep {
  .sas-dropdown-menu {
    width: 100%;
    background-color: $eureka-color-base-light !important;
    box-shadow: 0px 3px 0px #C2AB8C, inset 0px 1px 0px rgba($color-white, 0.75);
    border-radius: 16px;

    input[type="checkbox"]:checked {
      background-color: $eureka-color-ink-light;
    }

    .dropdown-action-input {
      background: $eureka-color-base-lighter;
      border: 1px solid $eureka-color-base-dark;
      box-shadow: inset 0px 2px 2px rgba($color-ink, 0.2);
      border-radius: $border-radius-xs;

    }
  }

  .sas-button {
    height: auto;
    min-height: 44px;
    padding-right: 17px;
    padding-left: 17px;
    background-color: $eureka-color-base-light;
    font-family: Lato;
    font-weight: $font-weight-medium;
    font-size: $size-s;
    line-height: $line-height-text;
    box-shadow: 0px 4px 0px #C2AB8C;
    background: $eureka-color-base-light;
    border: 1px solid $eureka-color-base-dark;
    border-radius: $border-radius-m;

    &:hover {
      border-color: $color-ink-lighter;
      box-shadow: $shadow-s rgba($color-black, 0.15);
    }

    &.--secondary:focus {
      box-shadow: 0 0 0 2px $eureka-color-ink-light !important;
    }

    b {
      width: calc(100% - 22px);
      max-height: 44px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      padding: 5px 0;

      &.opened b {
        max-height: 100%;
      }
    }

    .sas-button__text {
      width: 100%;
      text-align: left;
    }

    .multiselect-container {
      white-space: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 991px) {
  .MagnaMissionQuestionSearchFilters {
    width: calc(50% - 8px) !important;
  }
}
</style>
