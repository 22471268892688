<template>
  <div
    class="mission-results"
    :style="missionBackground"
  >
    <TeacherTopbar
      v-if="isTeacherTopbarVisible"
      selector-is-hidden
    />
    <transition name="fade">
      <GLoader
        v-if="report.loading"
        size="small"
        :frame="true"
      />
      <div
        v-else-if="report.success"
        class="mission-results__wrapper"
      >
        <GFrame class="mission-results__wrapper__content">
          <div class="mission-results__wrapper__content__body">
            <img
              class="mission-results__wrapper__content__banner"
              src="@/assets/char/tata-illustration.png"
              alt=""
            >
            <MissionReportBody @toggle-background="toggleBackground()" />
            <img
              class="mission-results__divider"
              :src="require('@/assets/report-divider.svg')"
              alt=""
            >
            <g-button
              v-if="isTypeformReady"
              variation="primary"
              class="typeform-button"
              size="large"
              icon-left="star"
              @click="openFeedbackSurvey"
            >
              {{ $t('survey.report.whatDidYouThinkAboutMission') }}
            </g-button>
          </div>
        </GFrame>
      </div>
      <MissionReportError
        v-else-if="report.error"
        context="report"
      />
    </transition>
    <RedoConfirmation
      ref="redoModal"
      @confirm="redoMission"
    />
    <MissionReportFooter
      class="mission-results__footer"
      @go-to-missions="goToMissions"
      @redo-mission="showRedoModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { playSoundFX } from '@/utils/soundEffects'
import typeformService from '@/service/typeform'
import { effectsNames } from '@/service/soundController/loadSounds'
import loadTheme from '@/mixins/loadTheme'
import mediaQueries from '@/mixins/mediaQueries'
import GLoader from '@/components/GLoader'
import GButton from '@/components/GButton'
import urls from '@/utils/urls'
import TeacherTopbar from '../../components/TeacherTopbar'
import GFrame from '../../components/GFrame'
import MissionReportBody from './partials/MissionReportBody'
import RedoConfirmation from './partials/RedoConfirmation'
import MissionReportFooter from './partials/MissionReportFooter'
import MissionReportError from './partials/MissionReportError'

export default {
  name: 'MissionResults',
  components: {
    GLoader,
    GButton,
    MissionReportBody,
    RedoConfirmation,
    MissionReportError,
    MissionReportFooter,
    GFrame,
    TeacherTopbar,
  },
  mixins: [ mediaQueries, loadTheme ],
  data() {
    return {
      backgroundOverlay: false,
      missionItem: [],
    }
  },
  computed: {
    ...mapState({
      isTypeformReady: ({ typeform }) => typeform.isTypeformReady,
    }),
    ...mapGetters([
      'theme',
      'report',
      'user',
      'userGrades',
      'currentGrade',
      'grade',
    ]),
    isTeacherTopbarVisible() {
      if (this.user.data) {
        return !this.user.data.userIsStudent && this.userGrades.data?.length
      }

      return false
    },
    lectureId() {
      return this.$route.params.lectureId
    },
    missionBackground() {
      if (this.theme.success) {
        if (this.theme.data.mission.background) {
          return `background-image: url(${this.theme.data.mission.background})`
        }
        if (this.theme.data.sky.background) {
          return `background-color: ${this.theme.data.sky.background}`
        }
      }

      return ''
    },
  },
  async created() {
    try {
      const { questionnaireCode } = this.$route.params
      await this.getReport(questionnaireCode)
      await this.setAssignment({
        title: this.report.data.title,
        subtitle: this.report.data.subtitle,
      })
      this.$prependTitle(`${this.report.data.title} | ${this.report.data.subtitle} | `)
      if (this.$route.params.shouldPlaySoundEffect) {
        playSoundFX(effectsNames.GOOD_PERFORMANCE)
      }

      this.$trackEvent({
        category: this.$track.category.eureka,
        action: this.$track.action.enterReport,
        label: `${this.report.data.subtitle} - ${this.report.data.title}`,
      })
    } catch (error) {
      this.$prependTitle(`${this.$t('commons.error')} | `)
      console.error(error)
    }
  },
  beforeDestroy() {
    const gradeId = parseInt(this.$route.query.gradeId, 10)
    this.getLectures(gradeId)
  },
  methods: {
    ...mapActions([
      'getReport',
      'redoAssignment',
      'setAssignment',
      'resetBagItems',
      'getVideo',
      'getLectures',
    ]),
    toggleBackground() {
      this.backgroundOverlay = !this.backgroundOverlay
    },
    goToMissions() {
      playSoundFX()
      const { lectureId } = this.$route.params
      this.$router.push({
        name: 'assignments',
        query: { ...this.$route.query },
        params: { lectureId },
      })
      this.$trackEvent({
        category: this.$track.category.report,
        action: this.$track.action.goToMissions,
      })
    },
    async redoMission(payload) {
      const { assignmentCode } = this.report.data
      const { questionnaireCode, lectureId, chapterId } = this.$route.params

      if (payload?.length) {
        payload.forEach((item) => {
          this.missionItem.push(item)
        })
      }
      try {
        await this.redoAssignment({ assignmentCode })
        this.$trackEvent({
          category: this.$track.category.report,
          action: this.$track.action.redoMission,
          label: `${this.report.data.subtitle} - ${this.report.data.title}`,
        })

        this.$router.push({
          name: 'question',
          query: { ...this.$route.query },
          params: {
            questionnaireCode,
            chapterId,
            lectureId,
            missionItem: this.missionItem,
          },
        })
      } catch (error) {
        console.error(error)
      }
    },
    showRedoModal() {
      const { questionnaireCode } = this.$route.params
      this.getVideo(questionnaireCode)
        .catch((error) => {
          console.error(error)
        })

      playSoundFX()
      this.resetBagItems()
      this.$refs.redoModal.show()
    },
    openFeedbackSurvey() {
      const {
        title,
        chapterOrder,
        correctAnswers,
        lectureName,
      } = this.report.data
      const url = urls.BASE_URL.concat(this.$route.path)
      const accountId = this.user.data.id
      const gradeName = this.grade?.name
      const chapterTitle = title
      const chapterNumber = chapterOrder
      const profile = this.user.data.profiles[0].name
      const acertos = correctAnswers

      const typeformPopup = typeformService.createPopup({
        formId: 'f5IUmsbA',
        hiddenFields: {
          url,
          account_id: accountId,
          platform: 'web',
          chapter_title: chapterTitle,
          chapter_number: chapterNumber,
          grade: gradeName,
          profile,
          acertos,
          lecture: lectureName,
        },
      })

      typeformPopup.open()

      this.$trackEvent({
        category: this.$track.category.feedback,
        action: this.$track.action.feedbackSurvey,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mission-results {
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;

  &__wrapper {
    display: flex;
    justify-content: center;

    &__content {
      position: absolute;
      max-width: 471px;
      height: 80vh;
      padding: 0;
      margin: $size-l;
      color: $eureka-color-ink;
      background: $color-white;
      border-radius: $size-m;
      overflow: auto;

      ::v-deep .g-frame {
        padding: 0;
        background: none;
      }

      ::v-deep .g-frame__inner {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr auto;
        background: linear-gradient(180deg, #728DD2 0%, rgba(196, 196, 196, 0) 30%);
      }

      ::v-deep .mission-report-body__content__slot-score {
         font: {
            family: Rowdies;
            size: 2.5rem;
            weight: $font-weight-extra-bold;
          }
      }

      ::v-deep .mission-report-card {
        &__header__description {
          font: {
            family: Lato;
            size: $font-size-s;
            weight: $font-weight-bold;
          }
        }

        &__title--text {
          font: {
            family: Lato;
            size: 1rem;
            weight: $font-weight-medium;
          }
        }
      }

      ::v-deep .mission-report-header {
        &__order-badge {
          background-color: $color-white;
        }

        &__title {
          font: {
            family: Rowdies;
            size: $font-size-heading-3-small;
            weight: $font-weight-regular;
          }
        }

        &__subtitle {
          font: {
            family: Lato;
            size: $font-size-s;
            weight: $font-weight-extra-bold;
          }
        }
      }

      ::v-deep .new-record-card {
        &__body__description {
          font: {
            family: Rowdies;
            size: $font-size-s;
            weight: $font-weight-regular;
          }
        }

        &__title--text {
          font-size: $font-size-s;
        }
      }

      ::v-deep .g-block__text {
        font: {
          family: Lato;
          size: $font-size-s;
          weight: $font-weight-extra-bold;
        }
        color: $eureka-color-ink;
      }

      &__banner {
        margin: 0 auto;
      }

      &__body {
        display: grid;
        grid-template-rows: 1fr;
        padding-top: $size-m;
      }
    }
  }

  .mission-report-body {
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__divider {
    margin: 0 auto;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    justify-content: space-between;
    background-color: $eureka-color-base-light;
    @media screen and (max-width: 471px) {
      justify-content: center;
    }
  }
}
.g-loader {
  @include float-center;
}

::v-deep .typeform-button.g-button {
  width: 70%;
  margin: $size-m auto;
  margin-bottom: $size-xl;
  color: $eureka-color-ink;
  background: #FBF1E1;
  border: 5px solid $color-white;
  box-shadow: 0 4px 0 $eureka-color-base;

  &__text {
    font-size: $font-size-s;
  }

  &:hover {
    box-shadow: 0 5px 0 $eureka-color-base;
  }

  &:active,
  &.--active {
    box-shadow: 0 0 0 $eureka-color-base;
  }
}

::v-deep .typeform-button .g-button__text {
  font: {
    family: Rowdies;
    size: $font-size-s;
    weight: $font-weight-regular;
  }
}
</style>
