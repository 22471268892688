<template>
  <div class="result-loading">
    <div class="result-loading__content">
      <MagnaMissionResultCard>
        <template #skeleton>
          <div class="result-loading__info">
            <skeleton-loader
              class="result-loading__info-card"
              opacity="0.5"
              width="271px"
              height="20px"
              border-radius="8px"
            />
            <skeleton-loader
              class="result-loading__info-card"
              opacity="0.5"
              width="123px"
              height="16px"
              border-radius="8px"
            />
          </div>
          <div class="result-loading__action">
            <skeleton-loader
              class="result-loading__info-card"
              opacity="0.5"
              width="135px"
              height="41px"
              border-radius="8px"
            />
          </div>
        </template>
      </MagnaMissionResultCard>
      <div class="result-loading__content">
        <MagnaMissionResultCard>
          <template #skeleton>
            <div class="result-loading__info">
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="271px"
                height="20px"
                border-radius="8px"
              />
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="123px"
                height="16px"
                border-radius="8px"
              />
            </div>
            <div class="result-loading__action">
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="135px"
                height="41px"
                border-radius="8px"
              />
            </div>
          </template>
        </MagnaMissionResultCard>
        <MagnaMissionResultCard class="margin-top-s">
          <template #skeleton>
            <div class="result-loading__info">
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="271px"
                height="20px"
                border-radius="8px"
              />
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="123px"
                height="16px"
                border-radius="8px"
              />
            </div>
            <div class="result-loading__action">
              <skeleton-loader
                class="result-loading__info-card"
                opacity="0.5"
                width="135px"
                height="41px"
                border-radius="8px"
              />
            </div>
          </template>
        </MagnaMissionResultCard>
      </div>
    </div>
  </div>
</template>

<script>
import MagnaMissionResultCard from '@/views/AncestralArena/MagnaMissionDetail/partials/MagnaMissionResultCard'

export default {
  name: 'ResultLoading',
  components: { MagnaMissionResultCard },
}
</script>

<style lang="scss" scoped>
.result-loading {
  &__info {
    *:not(:last-child) {
      margin-bottom: $size-xs;
    }

    &-card {
      background: $eureka-color-base-darker !important;
    }
  }
}

.margin-top-s {
  margin-top: $size-s;
}
</style>
