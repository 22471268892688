import { render, staticRenderFns } from "./ConfigControl.vue?vue&type=template&id=33bc4467&scoped=true"
import script from "./ConfigControl.vue?vue&type=script&lang=js"
export * from "./ConfigControl.vue?vue&type=script&lang=js"
import style0 from "./ConfigControl.vue?vue&type=style&index=0&id=33bc4467&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bc4467",
  null
  
)

export default component.exports