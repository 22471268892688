<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <skeleton-loader
        opacity="0.5"
        width="300px"
        height="150px"
        border-radius="8px"
      />
    </div>
    <div :class="$style.separator" />
    <div :class="$style.footer">
      <div :class="$style.tagsContainer">
        <div
          tabindex="-1"
          :class="$style.skeletonTag"
        >
          <skeleton-loader
            opacity="0.5"
            width="50px"
            height="25px"
            border-radius="8px"
          />
        </div>
        <div
          tabindex="-1"
          :class="$style.skeletonTag"
        >
          <skeleton-loader
            opacity="0.5"
            width="50px"
            height="25px"
            border-radius="8px"
          />
        </div>
      </div>
      <p class="view-question">
        <GButton
          variation="secondary"
          tabindex="0"
          size="small"
          :icon-stroke="3"
          :class="$style.questionPreviewViewQuestion"
        >
          <p>Visualizar questão</p>
          <icon
            type="chevron-right"
            :class="$style.icon"
          />
        </GButton>
      </p>
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'MagnaMissionSASQuestionPreviewCardSkeleton',
  components: {
    GButton,
  },
}
</script>

<style lang="scss" module>
.container {
  height: 244px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-white;
  border-radius: $size-s;
  box-shadow: 0 3px 0 #c2ab8c, inset 0 1px 0 rgba($color-white, 0.75);
  padding-top: $size-xxs;
  margin: 0 $size-s;

  &:first-of-type {
    margin-top: $size-s;
  }

  &:hover:not(.isSelected) {
    box-shadow: 0 6px 0 #c2ab8c, inset 0 3px 0 rgba($color-white, 0.75);
    outline: $size-xxs solid $eureka-color-base-darker;
  }

  &:focus:not(.isSelected) {
    outline: $size-xxs solid $eureka-color-base-darker;
    background: $eureka-color-base-lighter;
  }

  :global {
    .g-button__text {
      font-weight: $font-weight-medium;
      display: flex;
      align-items: center;
    }
  }
}

.badge {
  width: 110px;
  padding-left: $size-xxs;
  font-family: Rowdies;
  font-weight: $font-weight-medium;
  color: $color-white;
  background: $eureka-color-secondary;
  border-radius: $size-s 0 $size-s;
  position: absolute;
  top: 0;
  left: 0;
}

.isSelected {
  outline: 5px solid $eureka-color-secondary;
  filter: drop-shadow(0 $size-xxs 0 #004eb3);
  margin-top: $size-xxs;

  .content {
    margin-top: $size-m;
  }
}

.content {
  overflow: hidden;
  margin: $size-s;
  max-width: 344px;
  min-height: 160px;
  line-height: 20px;
  font-family: Rowdies;
  font-weight: $font-weight-light;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  img {
    height: 100%;
    width: 100%;
    margin-top: $size-s;
    object-fit: scale-down;
    display: block;
  }

  p {
    margin-left: 0 !important;
  }
}

.questionPreviewViewQuestion {
  &:focus,
  &:hover {
    background: rgba($color-black, 0.07);
  }

  svg {
    stroke-width: 3;
  }
}

.separator {
  border-bottom: 1px solid $eureka-color-ink-lightest;
  width: 100%;
}

.footer {
  font-family: Rowdies;
  color: $eureka-color-ink;
  display: flex;
  justify-content: space-between;
  padding: $size-xs;

  .tagsContainer {
    display: flex;
    gap: $size-s;

    .skeletonTag {
      background: $eureka-color-ice;
      border: 1px solid $eureka-color-ink-lightest;
      border-radius: $size-m;
      font-size: $font-size-s;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }

  :global {
    .feather {
      height: $size-s;
    }
  }
}
</style>
