var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GModal',{class:[
      'user-icons-list__frame',
      { '--error' : _vm.gameIcons.error }
    ],attrs:{"close-button-enabled":false,"variation":"secondary"},on:{"close":_vm.close}},[_c('section',{staticClass:"user-icons-list__header"},[_c('h1',{staticClass:"user-icons-list__header__title"},[_vm._v(" Escolha um ícone de perfil ")]),_c('GButton',{staticClass:"close-button",attrs:{"icon-only":"","size":"medium","variation":"secondary","asset":"gray-x.svg","aria-label":"Fechar"},on:{"click":_vm.close}})],1),(_vm.gameIcons.error)?_c('ErrorMessage',{attrs:{"image-name":"refresh.svg","message":"Não foi possível carregar a lista de ícones disponíveis","action":"Recarregar"},on:{"click":_vm.getUserIcons}}):_c('section',{staticClass:"user-icons-list__body"},[(_vm.gameIcons.loading)?_vm._l((8),function(index){return _c('skeleton-loader',{key:index,attrs:{"width":"136px","height":"136px","border-radius":"14px"}})}):(_vm.gameIcons.data)?_vm._l((_vm.gameIcons.data),function(icon){return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.getTooltipMessage(icon)),expression:"getTooltipMessage(icon)",modifiers:{"top":true}}],key:icon.id,class:[
            'user-icons-list__icon-btn',
            {'--selected': _vm.isSelectedIcon(icon.id)},
            {'--disabled': !icon.available}
          ],attrs:{"data-testid":"user-icon"},on:{"click":function($event){return _vm.setSelectedIcon(icon)}}},[_c('img',{staticClass:"user-icons-list__icon-btn__image",attrs:{"src":icon.image.png || icon.image.svg,"alt":icon.name}}),(_vm.isUserGameIcon(icon))?_c('img',{staticClass:"user-icons-list__icon-btn__check-badge",attrs:{"src":require("@/assets/check-badge.svg"),"alt":""}}):_vm._e()])}):_vm._e()],2)],1),(_vm.errorToastIsVisible)?_c('GToast',{key:"error-toast",attrs:{"text":"Ocorreu um problema e não foi possível alterar o ícone, tente novamente","variation":"error"},on:{"close":_vm.changeErrorToastVisibility}}):_vm._e(),(_vm.successToastIsVisible)?_c('GToast',{key:"success-toast",attrs:{"text":_vm.toastMessage},on:{"close":_vm.changeSuccessToastVisibility}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }