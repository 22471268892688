<template>
  <div class="teacher-finished-mission ">
    <h1 class="teacher-finished-mission__title">
      A missão foi finalizada
    </h1>
    <h3 class="teacher-finished-mission__subtitle">
      Seu professor finalizou a missão, <br>
      não é possível continuar
    </h3>
    <GButton
      size="large"
      no-border
      @click="goToArena"
    >
      Voltar para a página inicial
    </GButton>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'TeacherFinishedMission',
  components: {
    GButton,
  },
  methods: {
    goToArena() {
      this.$router.replace({
        name: 'arena',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.teacher-finished-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(
                0deg,
                $eureka-color-ink 0%,
                rgba($eureka-color-ink, 0.66) 40.1%,
                rgba($eureka-color-ink, 0) 100%),
              url("~@/assets/ancestral-arena/monte_cristalino.png") center center / cover no-repeat;
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;

  &__title {
    font-family: Rowdies;
    font-size: 3rem;
    color: $color-white;
  }

  &__subtitle {
    font-family: Rowdies;
    font-size: $font-size-heading-2;
    font-weight: $font-weight-light;
    text-align: center;
    margin-bottom: 56px;
    color: $color-white;
  }

  ::v-deep .g-button {
    padding: 1rem 2rem;
    border-radius: 32px;
    height: unset;
    &__text {
      font-size: $font-size-heading-4;
      line-height: $line-height-input;
      font-family: Rowdies;
      font-weight: $font-weight-regular;
    }
  }
}
</style>
