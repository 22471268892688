<template>
  <keep-alive :max="3">
    <router-view
      :mission-name="missionName"
      :mission-description="missionDescription"
      :has-required-data="hasRequiredData"
      :questionnaire-code="questionnaireCode"
    />
  </keep-alive>
</template>

<script>

export default {
  name: 'CreateMagnaMissionLayout',
  props: {
    missionName: {
      type: String,
      default: '',
    },
    missionDescription: {
      type: String,
      default: '',
    },
    hasRequiredData: {
      type: Boolean,
      default: true,
    },
    questionnaireCode: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
