<template>
  <div :class="['g-radio', {'--error': error}]">
    <input
      :id="computedId"
      v-model="internalValue"
      class="g-radio__input"
      :disabled="disabled"
      :name="name"
      :value="radioValue"
      type="radio"
    >
    <label
      v-if="hasLabel"
      :class="labelClasses"
      :for="computedId"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'GRadio',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    radioValue: {
      type: String,
      required: true,
    },
    disabled: Boolean,
    error: Boolean,
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lazyValue: this.value,
    }
  },
  computed: {
    labelClasses() {
      return [
        'g-radio__label',
        {
          'g-radio__label--disabled': this.disabled,
        },
      ]
    },
    /**
     * Proxy for `lazyValue`
     * This allows an input to function without a provided model
     */
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(val) {
        this.lazyValue = val
        this.$emit(this.$_modelEvent, val)
      },
    },
    computedId() {
      return this.id || `input-${uuidv4()}`
    },
    hasLabel() {
      return !!this.label
    },
  },
  watch: {
    value(val) {
      this.lazyValue = val
    },
  },
  beforeCreate() {
    this.$_modelEvent = this.$options.model
      ? this.$options.model.event
      : 'input'
  },
}
</script>

<style lang="scss">
.g-radio {
  &__label {
    font-size: $font-size-m;
    color: $color-ink;

    &--disabled {
      color: $color-ink-lightest;
    }
  }

  &.--error {
    input[type="radio"] {
      border: 3px solid $eureka-color-danger;
      box-shadow: 0 4px 0 $eureka-color-danger-dark;

      &:checked {
        background-color: $eureka-color-success;
        box-shadow: 0 3px 0 #8CC29F;
        border: none;

        &::after {
          background-size: 90%;
          background-color: $eureka-color-success;
        }

        &:focus,
        &:hover {
          background-color: #8CC29F;
          box-shadow: 0 3px 0 #8CC29F;
        }
      }

      &:not(:checked):not(:disabled):not(:focus):hover {
        border: 3px solid $eureka-color-danger;
        box-shadow: 0 4px 0 $eureka-color-danger-dark;
      }
    }
  }
}

input[type="radio"] {
  position: relative;
  width: $size-m;
  height: $size-m;
  margin: 0;
  appearance: none;
  @include transition(200ms);

  border-radius: 50%;
  background-color: $eureka-color-base-lighter;
  border: 3px solid $eureka-color-base-darker;
  box-sizing: border-box;
  box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    content: "";
    border-radius: 50%;
    background: {
      position: center;
      image: url("~@/assets/check.svg");
      repeat: no-repeat;
      size: 0%;
    }
    @include transition(200ms);

    &:not(:checked):not(:disabled):not(:focus) {
      box-shadow: inset 0 2px 2px rgba(30, 33, 36, .2);
    }
  }

  &:checked {
    background-color: $eureka-color-success;
    border: none;

    &::after {
      background-size: 90%;
      background-color: $eureka-color-success;
    }

    &:focus,
    &:hover {
      background-color: #8CC29F;
      box-shadow: 0 3px 0 #8CC29F;
    }
  }

  &:not(:checked):not(:disabled):not(:focus):hover {
    border-color: $eureka-color-base-darker;
  }

  &:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 3px #8CC29F;
  }

  &:disabled {
    cursor: default;
    background-color: #e5e5e5;
    border-color: $color-ink-lightest;
  }
}
</style>
