<template>
  <div class="lobby-student">
    <div class="lobby-student__content">
      <GLoader />
      <div class="lobby-student__text">
        {{ $t('ancestralArena.lobby.waitForTeacherStartMission') }}
      </div>
    </div>
    <InfoMagnaMissionModal
      v-if="isInfoModalVisible"
      class="magna-mission-modal"
      asset="char/tata-head-left.svg"
      title="Missão encerrada"
      description="Essa missão foi encerrada. Aguarde que uma nova missão
    seja iniciada na Arena Ancestral para jogar novamente ou fale com o seu professor."
      button-name="Fechar Missão"
      @confirm="leaveCanceledMagnaMission"
      @close="closeInfoMagnaMissionModal"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import socketClient from '@/client/socketClient'
import InfoMagnaMissionModal from '@/components/AncestralArena/MagnaMissionModal/InfoMagnaMissionModal'
import GLoader from '@/components/GLoader'

export default {
  name: 'MagnaMissionLobbyStudent',
  components: {
    GLoader,
    InfoMagnaMissionModal,
  },
  data() {
    return {
      currentMagnaMission: null,
      started: false,
      error: null,
      isInfoModalVisible: false,
      subscriptions: {
        studentChallengeRooms: null,
        userQueueNotifications: null,
        userQueueErrors: null,
      },
    }
  },
  computed: {
    questionnaireCode() {
      return this.$route.params.questionnaireCode
    },
  },
  watch: {
    currentMagnaMission: {
      deep: true,
      handler(newCurrentMagnaMission, oldCurrentMagnaMission) {
        if (newCurrentMagnaMission !== oldCurrentMagnaMission) {
          if (newCurrentMagnaMission.status === 'CANCELED') {
            this.showInfoMagnaMissionModal()
          }

          this.setMagnaMission({ data: newCurrentMagnaMission })
        }
      },
    },
    started: {
      immediate: true,
      handler() {
        if (this.started) {
          this.unsubscribeAllSubscriptions()
          this.$router.replace({ name: 'magna-mission-room-student' })
        }
      },
    },
  },
  mounted() {
    this.connect()
  },
  methods: {
    ...mapActions([ 'setMagnaMission' ]),
    connect() {
      if (socketClient.clientIsConnected()) {
        this.enterLobby()
      } else {
        socketClient.getClient(this.questionnaireCode).onConnect = () => {
          this.enterLobby()
        }
      }
    },
    enterLobby() {
      const topic = `/topic/student.challenge-rooms.${this.questionnaireCode}`
      const userQueue = '/user/queue/notifications'
      const onSubscribe = (response) => {
        const { title, status } = JSON.parse(response.body)
        this.currentMagnaMission = { title, status }
        this.started = status === 'STARTED'
      }

      this.subscriptions.studentChallengeRooms = socketClient.subscribe(topic, onSubscribe)
      this.subscriptions.userQueueNotifications = socketClient.subscribe(userQueue, onSubscribe)
      this.subscriptions.userQueueErrors = this.subscribeQueueErrors()
      this.sendStudentEntryConfirmation()
    },
    subscribeQueueErrors() {
      const topic = '/user/queue/errors'
      const onError = (response) => {
        const { status, message } = JSON.parse(response.body)
        this.error = {
          message: `[${status}] ${message}`,
          type: 'serverError',
          icon: 'alert-circle',
          isCloseable: true,
        }
        this.$emit('error-ocurred', this.error)
        this.error = false
      }

      return socketClient.subscribe(topic, onError)
    },
    sendStudentEntryConfirmation() {
      const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/subscribe`
      socketClient.emit(destination)
    },
    unsubscribeAllSubscriptions() {
      Object.values(this.subscriptions).forEach((subscription) => {
        socketClient.unsubscribe(subscription)
      })
    },
    leaveCanceledMagnaMission() {
      const destination = `/eureka/student/challenge-rooms/${this.questionnaireCode}/unsubscribe`
      socketClient.emit(destination)
      setTimeout(() => {
        socketClient.disconnect()
      }, 1000)
      this.$router.replace({ name: 'arena' })
    },
    showInfoMagnaMissionModal() {
      this.isInfoModalVisible = true
    },
    closeInfoMagnaMissionModal() {
      this.isInfoModalVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lobby-student {
  &__content {
    @include flex-column-center;
    height: 80vh;
  }
  &__text {
    font-family: 'Rowdies';
    font-size: $font-size-heading-2;
    color: $color-white;
    text-align: center;

    @media screen and (max-width: 991px) {
      font-size: 1.5rem;
    }
  }
}
</style>
