<template>
  <GButton
    :class="[`--${theme}`]"
    variation="secondary"
    size="large"
    icon-left="x"
    @click="skip"
  >
    {{ $t('commons.skip') }}
  </GButton>
</template>

<script>

import GButton from '@/components/GButton'
import { playSoundFX } from '@/utils/soundEffects'

export default {
  name: 'SkipButton',
  components: {
    GButton,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
      validator(theme) {
        return [ 'light', 'dark' ].includes(theme)
      },
    },
  },
  methods: {
    skip() {
      playSoundFX()
      this.$emit('skip')
    },
  },
}
</script>

<style lang="scss" scoped>
.g-button {
  color: #fff;

  &.--dark {
    background-color: #1D0946;
    box-shadow: 0 3px 0 #151142;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
