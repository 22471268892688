<!-- eslint-disable -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="84"
    fill="none"
  >
    <g filter="url(#filter0_d)">
      <path
        fill="#D97500"
        d="M66 47v7.3a9 9 0 01-4.6 7.8L40.6 73.8a9.4 9.4 0 01-9.2 0L10.6 62.1A9 9 0 016 54.3V47h60z"
      />
      <path
        fill="#FCF5E8"
        d="M31.9 8.1a8.3 8.3 0 018.2 0L59 19a8.3 8.3 0 014.1 7.2v21.6c0 3-1.6 5.8-4.1 7.2L40 66a8.3 8.3 0 01-8.2 0L13 55A8.3 8.3 0 019 47.8V26.2c0-3 1.6-5.8 4.1-7.2L32 8z"
      />
      <path
        fill="#FFFBF3"
        d="M60.2 25.3l-37 32.3 4.2 2.5 32.8-28.7v-6zM45.9 15l12.6 7.2L20 55.8 13.5 52c-1-.6-1.7-1.8-1.7-3v-4.6l34-29.6z"
      />
      <path
        fill="#D97500"
        d="M58.5 23.2L37.7 11.9c-1-.6-2.3-.6-3.4 0L13.5 23.2c-1 .6-1.7 1.6-1.7 2.8H6c0-3.1 1.8-6 4.6-7.5L31.4 7.2a9.7 9.7 0 019.2 0l20.8 11.3A8.6 8.6 0 0166 26h-5.8c0-1.2-.7-2.2-1.7-2.8z"
      />
      <path
        fill="#FFD028"
        fill-rule="evenodd"
        d="M58.5 22.1l-20.8-12c-1-.5-2.3-.5-3.4 0l-20.8 12c-1 .6-1.7 1.7-1.7 3v23.8c0 1.3.7 2.4 1.7 3l20.8 12c1 .5 2.3.5 3.4 0l20.8-12c1-.6 1.7-1.7 1.7-3V25.1c0-1.3-.7-2.4-1.7-3zM40.6 5.2a9.3 9.3 0 00-9.2 0l-20.8 12A9.1 9.1 0 006 25v23.8c0 3.3 1.8 6.3 4.6 8l20.8 11.9a9.3 9.3 0 009.2 0l20.8-12A9.1 9.1 0 0066 49V25.1c0-3.3-1.8-6.3-4.6-8L40.6 5.3z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        width="72"
        height="84"
        x="0"
        y="0"
        color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow"
          mode="normal"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MissionBadgeResume',
}
</script>
<!-- eslint-enable -->
