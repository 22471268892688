<template>
  <div class="container">
    <GModal
      :class="$style.container"
      :close-button-enabled="false"
    >
      <div :class="$style.content">
        <div :class="$style.containerText">
          <h3 :class="$style.title">
            Deseja voltar?
          </h3>
          <div :class="$style.message">
            Se você voltar, todas as questões selecionadas serão perdidas. Caso queira continuar,
            clique em ”Cancelar” e conclua a seleção de questões.
          </div>
        </div>
        <div :class="$style.controls">
          <a
            :class="$style.cancel"
            @click="hideModal"
          >
            Cancelar
          </a>
          <g-button
            :class="$style.back"
            variation="danger"
            size="large"
            @click="goBack"
          >
            Voltar
          </g-button>
        </div>
      </div>
    </GModal>
  </div>
</template>

<script>
import GModal from '@/components/GModal'
import GButton from '@/components/GButton'

export default {
  name: 'GoBackModal',
  components: {
    GModal,
    GButton,
  },
  methods: {
    hideModal() {
      this.$emit('cancel')
    },
    goBack() {
      this.$emit('back')
    },
  },
}
</script>

<style lang="scss" module>
.container {
  :global {
    .g-frame {
      background: none !important;
      box-shadow: none !important;
    }
  }
}
.content {
  max-width: 480px;
  padding: $size-l $size-m $size-m $size-l;
  flex-wrap: wrap;
  font-family: Rowdies;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.containerText {
  flex-basis: 50%;
  flex-grow: 1;
}

.title {
  margin-bottom: 24px;
  font-family: Rowdies;
  font-size: 2em;
}

.message {
  font-family: Lato;
}

.controls {
  margin-top: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .cancel {
    cursor: pointer;
    font-size: $font-size-heading-4;
    display: flex;
    align-items: center;
    color: $eureka-color-ink;
    opacity: 0.7;
  }

  .back {
    width: 125px;
    height: $size-xl;
    border: none;
    background: $eureka-color-danger;
    box-shadow: 0px 4px 0px $eureka-color-danger-dark;
    text-shadow: 0px 1px 0px $color-black;

    b {
      font-weight: $font-weight-regular;
      font-size: $font-size-heading-4;
    }
  }
}
</style>
