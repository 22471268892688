<template>
  <TourTooltip
    v-if="isEnabled"
    id="TourQuestionSelector"
    :steps="steps"
    @finish="skipTour"
    @skip="skipTour"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'
import tourMixin from '@/mixins/tour'

export default {
  name: 'TourQuestionSelector',
  components: {
    TourTooltip,
  },
  mixins: [ tourMixin ],
  data() {
    return {
      steps: [
        {
          target: '[data-tour="question-selector"]',
          content: `
            <h4 class="tour__title">✨ Novidade!</h4>
            <p class="tour__content">
              Agora você pode criar missões com questões prontas do banco SAS Educação além das criadas
               por você! Clique em ”Adicionar questões do banco SAS Educação” para conhecer nosso banco!
            </p>`,
          params: {
            placement: 'top',
          },
          offset: 150,
        },
      ],
    }
  },
  computed: {
    isEnabled() {
      return this.eurekaArenaOnboardingEnabled
      && this.isTourActive('magnaMissionQuestionSelector')
    },
  },
  methods: {
    skipTour() {
      this.endTour({ magnaMissionQuestionSelector: false })
    },
  },
}
</script>
