var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'container',
    `--${_vm.variation}`,
    {
      '--mozillaFullScroll' : _vm.mozillaFullScroll
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }