<template>
  <button
    v-tooltip.bottom-start="$t(`game.items.${item}.quick`)"
    class="quick-item-button"
    @click="$emit('click')"
  >
    <img
      class="quick-item-button__image"
      :src="require(`@/assets/items/${item}.svg`)"
    >
  </button>
</template>

<script>
export default {
  name: 'QuickItemButton',
  props: {
    item: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="sass" scoped>
.quick-item-button
  padding: 4px
  background: #F3E8CC
  border: 6px solid rgba(255, 255, 255, 0.3)
  border-left: none
  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.25), 0px 6px 0px #DFB959
  border-radius: 0 16px 16px 0
  cursor: pointer
  transform-origin: top left
  transition: transform $speed-fast

  &:focus
    outline: none

  &:active
    transform: translate(-1px, 2px)

  &__image
    width: 48px
    -webkit-user-drag: none
</style>
