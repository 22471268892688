<template>
  <div class="error-state">
    <img
      v-if="imagePath.length"
      class="error-state__icon"
      :src="require(`@/assets/${imagePath}`)"
    >
    <div class="error-state__message">
      <h4 class="error-state__message__title">
        {{ messageText }}
      </h4>
      <p
        class="error-state__message__description"
      >
        {{ descriptionText }}
      </p>
      <GButton
        :icon-left="action.icon"
        :class="[{ '--has-icon': action.icon }]"
        @click="$emit('click')"
      >
        {{ actionText }}
      </GButton>
    </div>
  </div>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'ErrorState',
  components: {
    GButton,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    action: {
      type: [ String, Object ],
      default: '',
    },
    imagePath: {
      type: String,
      default: '',
    },
  },
  computed: {
    messageText() {
      return this.message || this.$t('game.error.default.message')
    },
    descriptionText() {
      return this.description || this.$t('game.error.default.observation')
    },
    actionText() {
      if (!this.action) {
        return this.$t('game.error.default.action')
      }

      return this.action.text
    },
  },
}
</script>

<style lang="scss" scoped>
.error-state{
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: #382517;

  &__icon {
    margin-bottom: $size-m;
  }

  &__message {
    @include flex-column-center;

    &__title {
      @include arena-title-style;
      color: $eureka-color-ink;
      font-weight: $font-weight-bold;
      font-size: $font-size-heading-4;
    }

    &__description {
      font-family: 'Metropolis';
      text-align: center;
      margin-bottom: $size-s;
      opacity: 0.6;
    }
  }
}

.g-button {
  border: 0;
  padding: 0 $size-s;

  ::v-deep &.--has-icon &__icon i[data-name=refresh-ccw] {
    transform: translateY(2px);
  }

  &:disabled {
    border: 0 !important;
  }
}
</style>
