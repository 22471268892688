import { render, staticRenderFns } from "./RetrospectiveTemplate.vue?vue&type=template&id=bbc6ba80&scoped=true"
import script from "./RetrospectiveTemplate.vue?vue&type=script&lang=js"
export * from "./RetrospectiveTemplate.vue?vue&type=script&lang=js"
import style0 from "./RetrospectiveTemplate.vue?vue&type=style&index=0&id=bbc6ba80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbc6ba80",
  null
  
)

export default component.exports