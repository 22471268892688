<template>
  <div
    v-if="showBadge"
    class="question-sas-badge"
  >
    <div class="question-sas-badge__content">
      <icon
        type="info"
        class="question-sas-badge__icon"
      />
      <span>Questões do banco SAS Educação não podem ser editadas</span>
    </div>
    <SButton
      variation="tertiary"
      icon="x"
      @click="close"
    />
  </div>
</template>

<script>
import { SButton } from '@sas-te/alfabeto-vue'

export default {
  name: 'QuestionSasBadge',
  components: {
    SButton,
  },
  props: {
  },
  data: () => ({
    showBadge: true,
  }),
  computed: {
    isShow() {
      return this.question.sas
    },
  },
  methods: {
    close() {
      this.showBadge = false
    },
  },
}
</script>

<style lang="scss" scoped>
.question-sas-badge {
    background-color: $color-primary-lightest;
    border-radius: 25px;
    padding: $size-xxs 12px;
    font-family: Lato;
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    line-height: $line-height-text;
    display: flex;
    align-items: center;
    letter-spacing: 0.44px;
    color: $color-primary-dark;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px $size-s $size-m;

  &__content {
    display: flex;
    gap: $size-xs;
  }
}

</style>>
