import { render, staticRenderFns } from "./MissionContentSkeleton.vue?vue&type=template&id=d2b216bc&scoped=true"
import script from "./MissionContentSkeleton.vue?vue&type=script&lang=js"
export * from "./MissionContentSkeleton.vue?vue&type=script&lang=js"
import style0 from "./MissionContentSkeleton.vue?vue&type=style&index=0&id=d2b216bc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2b216bc",
  null
  
)

export default component.exports