<template>
  <div class="slide">
    <section
      v-if="hasValidData"
      class="slide__content"
    >
      <h1 class="slide__title --large ">
        Foi um ano e tanto!
      </h1>
      <p class="slide__text">
        Você fez
        <strong class="slide__text --important">
          {{ totalMissionsData.missionCount }} missões
        </strong>
        e respondeu
        <strong class="slide__text --important">
          {{ totalMissionsData.enigmaCount }} enigmas!
        </strong>
      </p>
      <CollectionItems
        title="Você coletou no total:"
        :items="totalMissionsData.collectionItems"
      />
    </section>
  </div>
</template>
<script>
import pick from 'lodash/pick'
import isEmpty from '@/utils/isEmpty'
import isNotEmpty from '@/utils/isNotEmpty'

import CollectionItems from './CollectionItems'

export default {
  name: 'RetrospectiveTotalMissions',
  components: {
    CollectionItems,
  },
  props: {
    isVisible: Boolean,
    retrospectiveData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasNoRetrospectiveData() {
      return isEmpty(this.retrospectiveData)
    },
    hasValidData() {
      if (isEmpty(this.totalMissionsData)) {
        return false
      }

      const {
        missionCount,
        enigmaCount,
        coins,
        gems,
        collectionItems,
      } = this.totalMissionsData

      return isNotEmpty(missionCount)
      && isNotEmpty(enigmaCount)
      && isNotEmpty(coins)
      && isNotEmpty(gems?.conquered)
      && isNotEmpty(gems?.total)
      && isNotEmpty(collectionItems)
    },
    totalMissionsData() {
      if (this.hasNoRetrospectiveData) {
        return {}
      }

      const totalMissionsData = pick(this.retrospectiveData,
        [ 'enigmaCount', 'missionCount', 'gems', 'coins' ])

      const collectionItems = [
        {
          size: 'small',
          type: 'gem',
          themeColor: 'transparent',
          text: `${totalMissionsData.gems?.conquered}/${totalMissionsData.gems?.total}`,
        },
        {
          size: 'small',
          type: 'coin',
          themeColor: 'transparent',
          quantity: totalMissionsData.coins,
        },
      ]

      return { ...totalMissionsData, collectionItems }
    },
  },
  watch: {
    totalMissionsData: {
      immediate: true,
      handler(newTotalMissionsData, oldTotalMissionsData) {
        if (newTotalMissionsData !== oldTotalMissionsData) {
          this.$emit('updateValidation', { name: 'totalMissions', isValid: this.hasValidData })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  @include flex-center;
  background-image: url('~@/assets/retrospective/background-retro-2.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
