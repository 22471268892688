<template>
  <div
    class="track"
  >
    <div
      ref="track"
      class="track__wrapper"
    >
      <MissionListItem
        v-for="mission in missions"
        :key="mission.id"
        ref="points"
        :mission="mission"
        :color="color"
        @click="$emit('click-on-mission', mission)"
      />
      <svg
        id="svg"
        ref="svg"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="line"
          ref="originalLine"
          :stroke="strokeColor"
          class="line original"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import MissionListItem from './MissionListItem'

export default {
  name: 'Track',
  components: { MissionListItem },
  props: {
    missions: {
      type: Array,
      default: () => [],
    },
    strokeColor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#000000',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.drawTrack()
    }, 100)
    window.addEventListener('resize', this.drawTrack)
  },
  destroyed() {
    window.removeEventListener('resize', this.drawTrack)
  },
  methods: {
    drawTrack() {
      const { points, originalLine } = this.$refs
      const oldLines = document.querySelectorAll('.deleteMe')
      oldLines.forEach((l) => l.remove())
      points.forEach((point, index) => {
        if (points[index + 1]) {
          this.makeLine(
            originalLine, point.$refs.order, points[index + 1].$refs.order
          )
        }
      })
    },
    makeLine(line, el1, el2) {
      const lineId = `${el1.getAttribute('id')}__${el2.getAttribute('id')}`

      const cloneLine = line.cloneNode(true)
      cloneLine.setAttribute('id', lineId)
      cloneLine.classList.add('deleteMe')
      cloneLine.classList.remove('original')
      document.getElementById('svg').appendChild(cloneLine)

      const container = this.$refs.track.getBoundingClientRect()
      const rect1 = el1.getBoundingClientRect()
      const rect2 = el2.getBoundingClientRect()
      const x1 = rect1.left - container.left + (rect1.width / 2)
      const y1 = rect1.top - container.top + (rect1.height / 2)
      const x2 = rect2.left - container.left + (rect2.width / 2)
      const y2 = rect2.top - container.top + (rect2.height / 2)

      cloneLine.setAttribute('d', `
        M ${x1}, ${y1}
        C ${x1}, ${y2}
          ${x2}, ${y1}
          ${x2}, ${y2}
      `)
    },
  },
}
</script>

<style lang="scss" scoped>
.track {
  position: relative;
  margin: 0 auto;
  min-width: 230px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }
}

#svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

.line {
  fill: none;
  stroke-width: 45px;
}
</style>
