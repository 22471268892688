<template>
  <header
    ref="container"
    class="g-header"
  >
    <Wrapper
      :style="headerStyle"
      :class="[
        'g-header__wrapper',
        {
          '--has-center-slot': hasCenterSlot,
          '--is-responsive-report-page': isResponsiveRouteOrReportPage,
        },
      ]"
      :full-width="true"
    >
      <div class="group">
        <GButton
          v-if="hasBackButton"
          class="g-header-game__back-button"
          icon="arrow-left"
          size="large"
          variation="secondary"
          @click="$emit('go-back')"
        />
        <div
          v-if="hasBackButton"
          class="separator"
        />
        <div
          class="g-header-game__container"
          :style="{
            width: isMagnaMissionRoomTeacherRoute || isMagnaMissionReport ? '100%' : ''
          }"
        >
          <h1
            v-if="dynamicTitle && !isMobile
              || !isTeacherLobbyRoute"
            id="game-title"
            class="g-header-game__title"
          >
            {{ dynamicTitle }}
          </h1>
          <div
            class="g-header-game__controls"
          >
            <ToggleFullScreen
              class="g-header-game__items"
              :references="$refs"
              size="large"
            />
            <GButton
              id="gheader__options-button"
              class="group__items"
              variation="secondary"
              size="large"
              icon="more-horizontal"
              :icon-stroke="3"
              @click="toggleOptions"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isGProfileActive"
        class="g-header-game__profile"
      >
        <GProfile
          v-if="author"
          :profile-name="author.user.name"
          :profile-picture="author.user.profilePicture"
          size="medium"
          color="white"
        >
          <span
            v-if="classroomName"
            class="classroom-name"
          >
            | {{ classroomName }}
          </span>
        </GProfile>
      </div>
      <slot name="center" />
      <slot name="menu" />
    </Wrapper>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { Wrapper } from '@sas-te/alfabeto-vue'
import { truncateText } from '@sas-te/frontend-utils/modules/truncate'
import GProfile from '@/components/GProfile'
import mediaQueries from '@/mixins/mediaQueries'
import GButton from '@/components/GButton'
import isEmpty from 'lodash/isEmpty'
import handleResize from '@/mixins/handleResize'
import ToggleFullScreen from '@/components/ToggleFullScreen'

const routesEnum = {
  TEACHER_LOBBY: 'lobby-teacher',
  MAGNA_MISSION_ROOM_TEACHER: 'magna-mission-room-teacher',
  MAGNA_MISSION_REPORT: 'magna-mission-teacher-report',
}

export default {
  name: 'GHeaderGame',
  components: {
    Wrapper,
    GProfile,
    GButton,
    ToggleFullScreen,
  },
  mixins: [ handleResize, mediaQueries ],
  props: {
    hasBackButton: Boolean,
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isGProfileActive: false,
    }
  },
  computed: {
    ...mapState({
      magnaMission: ({ arena }) => arena.magnaMission,
    }),
    isMobile() {
      return this.isXSmallBreakpoint || this.isSmallBreakpoint
    },
    canShowOnTeacherRoom() {
      return !this.isTeacherLobbyRoute
    },
    isResponsiveRouteOrReportPage() {
      return (this.isMagnaMissionReport || this.isMagnaMissionRoomTeacherRoute)
      && this.isSmallBreakpoint
    },
    isTeacherLobbyRoute() {
      return this.$route.name === routesEnum.TEACHER_LOBBY
    },
    isMagnaMissionRoomTeacherRoute() {
      return this.$route.name === routesEnum.MAGNA_MISSION_ROOM_TEACHER
    },
    isMagnaMissionReport() {
      return this.$route.name === routesEnum.MAGNA_MISSION_REPORT
    },
    dynamicTitle() {
      const title = this.title ?? this.magnaMission.data?.title
      if (this.isSmallBreakpoint) return truncateText(title, 20)

      return title
    },
    classroomName() {
      return this.magnaMission.data?.classroomName
    },
    author() {
      return this.magnaMission.data?.author
    },
    hasCenterSlot() {
      return !isEmpty(this.$slots.center)
    },
    headerWidth() {
      const spacings = {
        'magna-mission-room-teacher': this.isMediumScreen ? 32 : 620,
        'magna-mission-teacher-report': this.isMediumScreen ? 32 : 300,
      }

      const spacing = spacings[this.$route.name] || 150

      return { width: `${this.windowWidth - spacing}px` }
    },
    headerStyle() {
      const styles = {
        'magna-mission-room-teacher': this.isMediumScreen
          ? { display: 'flex', flexDirection: 'column', textAlign: 'center' }
          : {},
        'magna-mission-teacher-report': this.isMediumScreen
          ? { display: 'flex', flexDirection: 'column', textAlign: 'center' }
          : {},
      }

      const style = styles[this.$route.name] || {}

      return style
    },
  },
  methods: {
    toggleOptions() {
      this.$emit('toggle-options')
    },
  },
}
</script>

<style lang="scss" scoped>
.g-header {
  padding: $size-m $size-l $size-l;
  min-height: 76px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.--has-center-slot {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  }
}

.g-header-game {
  flex-grow: 1;

  &__back-button {
    ::v-deep &.g-button {
      color: $color-white;
    }
    vertical-align: sub;
  }

  &__title {
    display: inline-block;
    color: $game-background-color;
    margin-bottom: $size-xxs;
    font-family: Rowdies;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    text-shadow: 0 4px 4px #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 991px) {
      width: 100% !important;
      font-size: $font-size-heading-4-small;
      font-weight: $font-weight-regular;
      line-height: $line-height-heading;
      text-align: left;
      display: flex;
      align-items: center;
      max-width: 100%;
    }
  }

  &__profile {
    margin-top: 10px;
  }

  &__controls {
    display: flex;
  }

  &__items {
    border-radius: $border-radius-xs;
    cursor: pointer;
    display: block;
    position: relative;
    color: $game-background-color !important;
    @include flex-center;
    @include transition($speed-x-fast);

    &:not(:last-child) {
      margin-right: $size-s;
    }

    & ::v-deep .g-button__icon i {
      transform: translateY(2px);
    }

    &:hover {
      background: rgba($color-black, 0.2);
    }

    &:active {
      background: rgba($color-black, 0.2);
    }

    &.magna-mission-players-counter {
      border-right: 1px solid rgba($color-white, 0.2);
      padding-right: $size-s;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 579px) {
      width: unset;
    }
  }
}

.g-profile__informations {
  ::v-deep &__profile-name {
    font-family: "Rowdies";
    font-size: $font-size-m;
  }
}

.classroom-name {
  font-family: "Rowdies";
  color: $color-white;
  margin: $size-xs;
  font-size: $font-size-m;
}

.group {
  width: 100%;
  display: inline-flex;

  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid rgba($color-white, 0.2);
  }

  &__items {
      border-radius: $border-radius-xs;
      cursor: pointer;
      display: block;
      position: relative;
      color: $game-background-color !important;
      @include flex-center;
      @include transition($speed-x-fast);

      &:not(:last-child) {
        margin-right: $size-s;
      }

      & ::v-deep .g-button__icon i {
        transform: translateY(2px);
      }

      &:hover {
        background: rgba($color-black, 0.2);
      }

      &:active {
        background: rgba($color-black, 0.2);
      }

      &.magna-mission-players-counter {
        border-right: 1px solid rgba($color-white, 0.2);
        padding-right: $size-s;

        @media screen and (max-width: 768px) {
          border-right: none;
      }
      }
    }
}

.--is-responsive-report-page {
  flex-direction: row !important;
  text-align: unset !important;
}

.separator {
  margin-right: $size-s;
  border-right: 2px solid $color-white;
  opacity: 0.25;

  @media screen and (max-width: 579px) {
    border-right: none;
  }
}
</style>
