<template>
  <transition
    appear
    name="wise-scroll"
  >
    <div
      v-if="visible && !isMobile"
      class="wise-scroll"
    >
      <div class="wise-scroll__overlay" />
      <div class="wise-scroll__wrapper">
        <div class="wise-scroll__icon">
          <img
            class="wise-scroll__icon__image"
            src="@/assets/items/wiseScroll.svg"
          >
        </div>
        <div class="wise-scroll__content">
          <div class="wise-scroll__content__header">
            <div class="wise-scroll__content__header__title">
              {{ $t('game.items.wiseScroll.name') }}
            </div>
            <button
              class="wise-scroll__content__header__close"
              @click="hide"
            />
          </div>
          <div
            v-if="itemEffects.success"
            class="wise-scroll__content__text"
            v-html="wiseScrollTip"
          />
          <div
            v-else
            class="wise-scroll__content__text"
          >
            <skeleton-loader
              height="18px"
              width="100%"
            />
            <skeleton-loader
              height="18px"
              width="100%"
            />
            <skeleton-loader
              height="18px"
              width="50%"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="visible && isMobile"
      class="wise-scroll"
    >
      <div class="wise-scroll__overlay" />
      <div class="wise-scroll__wrapper">
        <div class="wise-scroll__content">
          <div class="wise-scroll__content__header">
            <div class="wise-scroll__content__header__title">
              <img
                class="wise-scroll__icon__image"
                src="@/assets/items/wiseScroll.svg"
              >
              <p> {{ $t('game.items.wiseScroll.name') }} </p>
            </div>
          </div>
          <div v-if="itemEffects.success">
            <div
              class="wise-scroll__content__text"
              v-html="wiseScrollTip"
            />
            <div class="wise-scroll__content__footer">
              <GButton
                class="wise-scroll__content__button"
                size="large"
                variation="primary"
                rowdies
                @click="hide"
              >
                Fechar
              </GButton>
            </div>
          </div>
          <div
            v-else
            class="wise-scroll__content__text"
          >
            <skeleton-loader
              height="18px"
              width="100%"
            />
            <skeleton-loader
              height="18px"
              width="100%"
            />
            <skeleton-loader
              height="18px"
              width="50%"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import GButton from '@/components/GButton'
import mediaQueries from '@/mixins/mediaQueries'
import { playSoundFX } from '@/utils/soundEffects'

export default {
  name: 'WiseScroll',
  components: {
    GButton,
  },
  mixins: [ mediaQueries ],
  props: {
    assignmentCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapGetters([
      'question',
      'itemEffects',
    ]),
    wiseScrollTip() {
      const { data } = this.itemEffects
      const wiseScroll = data.find(({ type }) => type === 'wiseScroll')
      if (!wiseScroll) {
        return ''
      }

      return wiseScroll.tip
    },
    isMobile() {
      return this.isSmallBreakpoint || this.isXSmallBreakpoint
    },
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      playSoundFX()
      this.visible = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.wise-scroll {
  @include cover(fixed);
  @include flex-center;
  z-index: 100;

  &__overlay {
    @include cover(fixed);
    @include transition($speed-normal);
    background: rgba($color-ink, .5);
  }

  &__wrapper {
    @include transition($speed-slow);
    position: absolute;
    bottom: 42px;
    max-width: 788px;
    width: 100%;
    padding: 0 $size-m;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: 768px) {
      bottom: 0;
      padding: 0;
      flex-direction: column;
      align-items: center;
    }
  }

  &__icon {
    @include flex-center;
    position: relative;
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    margin-right: $size-s;

    &::before {
      @include pseudo;
      top: 3px;
      bottom: 3px;
      left: 3px;
      right: 3px;
      background: #C5662E;
      border-radius: 50%;
      border: 6px solid rgba(255, 255, 255, 0.17);
      box-shadow: 0 $game-size-m 36px rgba(0, 0, 0, 0.25);
      z-index: 1;

      @media screen and (max-width: 768px) {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: none;
        border: none;
        box-shadow: none;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      display: block;
      -webkit-user-drag: none;
      z-index: 2;

      @media screen and (max-width: 768px) {
        width: 91px;
        height: 91px;
        position: absolute;
        left: 0;
      }
    }
  }

  &__content {
    flex-grow: 1;

    @media screen and (max-width: 479px) {
      margin-bottom: 5em;
    }

    &__header {
      @include flex-space-between;

      @media screen and (max-width: 768px) {
        display: block;
      }

      &__title {
        background: #C5662E;
        border-radius: $size-s $size-s 0 0;
        border: 6px solid rgba(255, 255, 255, 0.17);
        border-bottom: none;
        font-weight: bold;
        font-size: $game-size-m;
        color: $color-white;
        text-shadow: 0 2px 0 rgba(0, 0, 0, 0.55);
        padding: $size-xxs $size-s $size-s;
        position: relative;
        bottom: -12px;
        z-index: 1;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          min-height: 80px;
          padding-bottom: 36px;
        }
      }

      &__close {
        @include transition($speed-x-fast);
        cursor: pointer;
        background-color: transparent;
        background-image: url(~@/assets/icon-x-secondary.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: $game-size-l;
        border: none;
        border-radius: 6px;
        height: $game-size-l;
        width: $game-size-l;

        &:focus {
          outline: none;
        }
      }
    }

    &__text {
      position: relative;
      background: #FBF1E1;
      border: 8px solid $eureka-color-base-dark;
      box-shadow: 0 6px 0 #CEA464, 0 $game-size-m 36px rgba(0, 0, 0, 0.25);
      border-radius: 18px;
      padding: $size-m;
      z-index: 2;

      .sas-skeleton-loader {
        @include space-stack($game-size-xxs);
      }

      @media screen and (max-width: 768px) {
        border-radius: $size-m $size-m 0 0;
        box-shadow: none;
        padding: $size-m $size-s;
        margin-top: -12px;
        border-bottom: none;
      }
    }

    &__button {
      width: 100%;
    }

    &__footer {
      background-color: #FBF1E1;
      width: 100%;
      padding: $size-m;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }

  &-enter-active,
  &-leave-active {
    @include transition($speed-slow);
  }

  &-enter,
  &-leave-to {

    .wise-scroll__wrapper {
      transform: translateY(120%)
    }

    .wise-scroll__overlay {
      @include transition($speed-slow);
    }
  }
}
</style>
