<template>
  <div
    class="g-loader"
    :class="{
      '--frame': frame
    }"
    data-testid="g-loader"
  >
    <!-- eslint-disable -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="uil-hourglass"
      :width="dimensions"
      :height="dimensions"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <rect
        x="0"
        y="0"
        width="100"
        height="100"
        fill="none"
        class="bk"
      />
      <g>
        <path
          class="glass"
          fill="none"
          stroke-width="5"
          stroke-miterlimit="10"
          d="M58.4,51.7c-0.9-0.9-1.4-2-1.4-2.3s0.5-0.4,1.4-1.4 C70.8,43.8,79.8,30.5,80,15.5H70H30H20c0.2,15,9.2,28.1,21.6,32.3c0.9,0.9,1.4,1.2,1.4,1.5s-0.5,1.6-1.4,2.5 C29.2,56.1,20.2,69.5,20,85.5h10h40h10C79.8,69.5,70.8,55.9,58.4,51.7z"
          :stroke="stroke"
        />
        <clipPath id="uil-hourglass-clip1">
          <rect
            class="clip"
            x="15"
            y="20"
            width="70"
            height="25"
          >
            <animate
              attributeName="height"
              from="25"
              to="0"
              dur="3s"
              repeatCount="indefinite"
              values="25;0;0"
              keyTimes="0;0.5;1"
            />
            <animate
              attributeName="y"
              from="20"
              to="45"
              dur="3s"
              repeatCount="indefinite"
              values="20;45;45"
              keyTimes="0;0.5;1"
            />
          </rect>
        </clipPath>
        <clipPath id="uil-hourglass-clip2">
          <rect
            x="15"
            y="55"
            width="70"
            height="25"
            class="clip"
          >
            <animate
              attributeName="height"
              from="0"
              to="25"
              dur="3s"
              repeatCount="indefinite"
              values="0;25;25"
              keyTimes="0;0.5;1"
            />
            <animate
              attributeName="y"
              from="80"
              to="55"
              dur="3s"
              repeatCount="indefinite"
              values="80;55;55"
              keyTimes="0;0.5;1"
            />
          </rect>
        </clipPath>
        <path
          class="sand"
          clip-path="url(#uil-hourglass-clip1)"
          d="M29,23c3.1,11.4,11.3,19.5,21,19.5S67.9,34.4,71,23H29z"
          :fill="sand"
        />
        <path
          class="sand"
          clip-path="url(#uil-hourglass-clip2)"
          d="M71.6,78c-3-11.6-11.5-20-21.5-20s-18.5,8.4-21.5,20H71.6z"
          :fill="sand"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="180 50 50"
          repeatCount="indefinite"
          dur="3s"
          values="0 50 50;0 50 50;180 50 50"
          keyTimes="0;0.5;1"
        />
      </g>
    </svg>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'GLoader',
  props: {
    stroke: {
      type: String,
      default: '#74492A',
    },
    sand: {
      type: String,
      default: '#FFBC23',
    },
    fill: {
      type: String,
      default: 'transparent',
    },
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: (value) => value.match(/(small|medium|large)/),
    },
    frame: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dimensions() {
      switch (this.size) {
        case 'small':
          return '80px'
        case 'large':
          return '112px'
        default:
          return '96px'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.--frame
  background: $frame-bg-color
  box-shadow: $frame-shadow, 0 3px 10px rgba(0, 0, 0, 0.15)
  padding: 16px
  border-radius: 16px
</style>
