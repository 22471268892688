<template>
  <div
    :class="[
      'resource-bar',
      `--${variation}`,
      `--${size}`,
      { '--framed': frame },
    ]"
  >
    <div
      v-if="frame"
      class="resource-bar__frame"
    />
    <div class="resource-bar__slot">
      <div class="resource-bar__slot__padding">
        <div
          class="resource-bar__bar"
          data-testid="resource-bar"
          :style="{ width: `${barWidth}%` }"
        />
        <div class="resource-bar__content">
          <span class="resource-bar__content__text">
            <slot />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceBar',
  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#999999',
    },
    variation: {
      type: String,
      required: false,
      default: 'purple',
      validator: (value) => (
        value.match(/(purple|yellow|blue)/)
      ),
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: (value) => (
        value.match(/(small|medium|large|xsmall)/)
      ),
    },
    frame: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    invalidValues() {
      const valueIsZero = this.value === 0
      const totalIsZero = this.total === 0

      return {
        isInvalid: (valueIsZero && totalIsZero) || this.total < this.value,
        totalIsLessThanValue: this.total < this.value,
      }
    },
    barWidth() {
      const { isInvalid, totalIsLessThanValue } = this.invalidValues

      if (isInvalid && totalIsLessThanValue) {
        return 100
      }

      return isInvalid ? 0 : (100 * this.value) / this.total
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-bar {
  position: relative;
  width: 200px;

  @media screen and (max-width: 479px) {
    width: 180px;
  }

  &.--framed {
    padding: 4px;
  }

  &__frame {
    background: #FBF1E1;
    box-shadow: 0px 3px 0px #CBA976, inset 0px 1px 0px rgba(255, 255, 255, 0.85);
    border-radius: 99px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__slot {
    width: 200px;
    background: #C19267;
    padding: 2px;
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.6),
      inset 0px 6px 0px #A06D3F,
      inset -1px -1px 0px #A06D3F;
    border-radius: 99px;
    position: relative;

    @media screen and (max-width: 479px) {
      width: unset;
    }

    &__padding {
      overflow: hidden;
      border-radius: 99px;
      height: 100%;
    }
  }

  &__bar {
    border-radius: 999px;
    position: relative;
    @include transition($speed-slow);

    .--xsmall & {
      height: 12px;
    }

    .--small & {
      height: 20px;
    }

    .--medium & {
      height: 24px;
    }

    .--large & {
      height: 32px;
    }

    .--purple & {
      background: #9960D2;
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.25), inset 0px -6px 3px #782AC6;
    }

    .--yellow & {
      background: #FFD642;
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.25), inset 0px -6px 3px #ED940E;
    }

    .--blue & {
      background: #77CDFE;
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.25), inset 0px -6px 3px #3A9DD6;
    }

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 8px;
      right: 8px;
      height: 4px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 99px;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include flex-center;

    &__text {
      font-weight: 700;
      color: $color-white;
      text-align: center;

      .--small & {
        font-size: 12px;
      }

      .--medium & {
        font-size: 14px;
      }

      .--large & {
        font-size: 20px;
      }
    }
  }
}

</style>
