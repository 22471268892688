const isUndefined = (value) => typeof value === 'undefined'
const isNull = (value) => value === null
const isString = (value) => typeof value === 'string'
const isFunction = (value) => typeof value === 'function'

export function getNestedValue(obj, path, fallback) {
  const last = path.length - 1
  let newObj

  if (last < 0) {
    return isUndefined(obj) ? fallback : obj
  }

  for (let i = 0; i < last; i += 1) {
    if (isNull(obj)) {
      return fallback
    }

    newObj = obj[path[i]]
  }

  if (isNull(newObj)) {
    return fallback
  }

  return isUndefined(newObj[path[last]])
    ? fallback
    : newObj[path[last]]
}

export function getObjectValueByPath(obj, path, fallback) {
  if (isNull(obj) || !path || !isString(path)) {
    return fallback
  }

  if (!isUndefined(obj[path])) {
    return obj[path]
  }

  let newPath = path.replace(/\[(\w+)\]/g, '.$1')
  newPath = path.replace(/^\./, '')

  return getNestedValue(obj, newPath.split('.'), fallback)
}

export function getPropertyFromItem(item, property, fallback) {
  if (isNull(property)) {
    return isUndefined(item) ? fallback : item
  }

  if (item !== Object(item)) {
    return isUndefined(fallback) ? item : fallback
  }

  if (isString(property)) {
    return getObjectValueByPath(item, property, fallback)
  }

  if (Array.isArray(property)) {
    return getNestedValue(item, property, fallback)
  }

  if (!isFunction(property)) {
    return fallback
  }

  const value = property(item, fallback)

  return isUndefined(value) ? fallback : value
}
