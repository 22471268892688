<template>
  <div class="mission-content">
    <transition name="fade">
      <div
        v-if="ready && progress.success"
        class="mission-content__progress"
      >
        <span class="mission-content__progress__current">
          {{ progress.data.current }}
        </span>
        <span class="mission-content__progress__total">
          / {{ progress.data.total }}
        </span>
      </div>
    </transition>
    <ScrollContainer class="mission-content__body">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="ready && question.success"
          class="mission-content__body-content"
        >
          <RichTextViewer
            class="mission-content__body__statement"
            :text="question.data.statement"
          />
          <MissionContentOptions
            :options="question.data.options"
            :selected-option-index="selectedOptionIndex"
            :index-to-disable="removedOption"
            :correct-option-index="correctOptionIndex"
            @optionClicked="optionClicked"
          />
        </div>
        <MissionContentSkeleton v-else-if="ready && !question.error" />
        <MissionError v-else-if="ready && question.error" />
        <GLoader v-else />
      </transition>
      <transition name="fade">
        <div
          v-if="visibleExplanation"
          id="explanation"
          class="mission-content__body__explanation"
        >
          <img
            class="mission-content__body__explanation__icon"
            src="@/assets/book-open.svg"
          >
          <h3 class="mission-content__body__explanation__title">
            {{ $t('commons.explanation') }}
          </h3>
          <div
            class="mission-content__body__explanation__content"
            v-html="answer.data.explanation"
          />
        </div>
      </transition>
    </ScrollContainer>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { mapGetters } from 'vuex'

import GLoader from '@/components/GLoader'
import RichTextViewer from '@/components/RichTextViewer'
import ScrollContainer from '@/components/ScrollContainer'
import MissionContentOptions from './MissionContentOptions'
import MissionContentSkeleton from './MissionContentSkeleton'
import MissionError from './MissionError'

gsap.registerPlugin(ScrollToPlugin)

export default {
  name: 'MissionContent',
  components: {
    MissionContentOptions,
    MissionContentSkeleton,
    MissionError,
    RichTextViewer,
    GLoader,
    ScrollContainer,
  },
  props: {
    selectedOption: {
      type: Object,
      default: null,
    },
    ready: {
      type: Boolean,
      default: false,
    },
    removedOption: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visibleExplanation: false,
    }
  },
  computed: {
    ...mapGetters([
      'question',
      'progress',
      'answer',
    ]),
    selectedOptionIndex() {
      if (this.answer.success) {
        return this.answer.data.markedOption
      }
      if (this.selectedOption) {
        return this.question.data.options.findIndex((option) => (
          option === this.selectedOption
        ))
      }

      return -1
    },
    correctOptionIndex() {
      if (this.answer.success) {
        return this.answer.data.correctOption
      }

      return -1
    },
  },
  watch: {
    question(newQuestion, oldQuestion) {
      if (oldQuestion.success && !newQuestion.success) {
        this.hideExplanation()
        gsap.to(
          window,
          {
            duration: 0.5,
            scrollTo: 0,
          }
        )
      }
    },
  },
  methods: {
    optionClicked(clickedOption) {
      this.$emit('optionClicked', clickedOption)
    },
    showExplanation() {
      this.visibleExplanation = true
      this.$nextTick(() => {
        gsap.to(
          window,
          {
            duration: 0.5,
            scrollTo: {
              y: '#explanation',
              offsetY: 92,
            },
          }
        )
      })
    },
    hideExplanation() {
      this.visibleExplanation = false
    },
  },
}
</script>

<style lang="scss" scoped>
.mission-content {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  max-width: $mission-max-width;
  background: #FBF1E1;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
  border-radius: 24px 24px 0 0;
  padding: 0.625rem 0.625rem 0;
  color: #462F1E;
  height: 100%;

  &__progress {
    position: absolute;
    top: 0;
    left: 6.125rem;
    background: #FBF1E1;
    padding: 0.375rem 1.25rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: inset 0 -3px 0 #CEB979;
    z-index: 1;

    @media screen and (max-width: 479px) {
      left: 5rem;
    }

    &__current {
      font: {
        size: 1.5rem;
        weight: $font-weight-bold;
      }

      @media screen and (max-width: 479px) {
        font-size: 1.25rem;
      }
    }

    &__total {
      font: {
        size: 1.125rem;
        weight: $font-weight-medium;
      }

      @media screen and (max-width: 479px) {
        font-size: 1rem;
      }
    }
  }

  &__body {
    position: relative;
    background: $color-white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 16px 16px 0 0;
    padding: 10% 88px 20%;
    height: 100%;

    &-content {
      @media screen and (max-width: 479px) {
        margin-bottom: 7rem;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 10% 30px 20% 42px;
    }

    @media screen and (max-width: 479px) {
      padding: 10% 10px 20% 22px;
    }

    &__statement {
      margin-bottom: 2.5rem;

      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 479px) {
        margin-bottom: 0.5rem;
      }
    }

    &__explanation {
      background: #FEFBF7;
      border: 2px solid #FBF1E1;
      border-radius: 18px;
      margin-top: 2.5rem;
      padding: 1rem 1.5rem;
      @include flex-center-start;
      flex-wrap: wrap;

      &__icon {
        display: block;
        width: 3.25rem;
        height: 3.25rem;
        margin-right: 1rem;
      }

      &__title {
        font: {
          size: 1.5rem;
          weight: $font-weight-semi-bold;
        }

        line-height: 1.5rem;
        flex-grow: 1;
      }

      &__content {
        flex: 1 0 100%;
        margin-top: 0.5rem;
      }

      @media screen and (max-width: 479px) {
        margin-bottom: 7rem;
      }
    }

    &__statement,
    &__explanation__content {
      ::v-deep {
        p {
          font-size: 1.125rem !important;
          @include embedded-paragraph;
        }

        img {
          @include embedded-image;
        }
      }
    }
  }
}

.g-loader {
  @include float-center;
}
</style>
