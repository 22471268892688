var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['navigation-menu-border', {
    '--showBorderDarkMode': _vm.isLogosThemeActivated,
    '--collapsed': _vm.addMarginTop,
    '--logos': _vm.isLogosRoute
  }, ]},[_c('div',{class:['navigation-menu', { '--darkModeMenu': _vm.isLogosThemeActivated }, ],attrs:{"data-testid":"navigation-menu"}},[(_vm.isPreviousButtonVisible)?_c('button',{staticClass:"navigation-menu__button --control --control-up",class:[
        {
          '--dark-mode-button': _vm.isLogosThemeActivated,
        },
      ],attrs:{"data-testid":"navigation-up-button"},on:{"click":_vm.previousItem}},[(_vm.isLogosThemeActivated)?_c('img',{attrs:{"src":require("@/assets/arrow-up-dark.svg")}}):_vm._e()]):_vm._e(),_vm._l((_vm.visibleItems),function(item){return _c('div',{key:item.id,class:[
        'navigation-menu__item',
        { '--selected': _vm.isSelected(item),
          '--darkModeItem': _vm.isLogosThemeActivated,
        },
      ],attrs:{"data-testid":"navigation-menu-item"}},[_c('button',{staticClass:"navigation-menu__button",on:{"click":function($event){return _vm.navigateTo(item)}}},[_c('img',{staticClass:"navigation-menu__image",attrs:{"src":_vm.iconImage(item),"alt":""}}),_c('span',{class:['navigation-menu__description',
                   { '--isDarkModeText': _vm.isLogosThemeActivated },
          ],attrs:{"data-testid":"navigation-menu-item-name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]),(_vm.showBadge(item))?_c('div',{staticClass:"navigation-menu__badge item-badge",attrs:{"data-testid":"navigation-notification-badge"}},[_c('span',[_vm._v(_vm._s(item.notificationCount))])]):_vm._e()])}),(_vm.isNextButtonVisible)?_c('button',{staticClass:"navigation-menu__button --control --control-down",class:[
        {
          '--dark-mode-button': _vm.isLogosThemeActivated,
        },
      ],on:{"click":_vm.nextItem}},[(_vm.isLogosThemeActivated)?_c('img',{attrs:{"src":require("@/assets/arrow-down-dark.svg")}}):_vm._e()]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }