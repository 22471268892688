<template>
  <ScrollContainer class="news-list">
    <ArticlePreview
      v-for="(article, index) in list"
      :key="`preview-${index}`"
      :article="article"
      class="article-preview"
      @click="showArticle(article)"
    />
  </ScrollContainer>
</template>

<script>
import ScrollContainer from '@/components/ScrollContainer'
import ArticlePreview from './ArticlePreview'

export default {
  name: 'ArticlePreviewList',
  components: {
    ArticlePreview,
    ScrollContainer,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showArticle(article) {
      this.$emit('show-article', article)
    },
  },
}
</script>

<style lang="sass" scoped>
.article-preview
  cursor: pointer

  &:not(:last-child)::after
    content: ''
    display: block
    width: 100%
    height: 1px
    background-color: #CBA976
    border-bottom: 1px solid $color-white
    margin: $size-l 0
    box-sizing: content-box
</style>
