export const templates = [
  {
    name: 'DIALOG',
    description: 'Cena com atores e diálogo, pode conter tomada de decisão do usuário',
  },
  {
    name: 'TRANSITION',
    description:
      'Cena sem atores, background preto e uma frase de transição para próxima cena',
  },
  {
    name: 'CUTSCENE',
    description:
      'Cena sem atores, background com cor estática e imagens e diálogo contando uma história',
  },
]
export const templatesTypes = templates.reduce(
  (acc, { name }) => ({ ...acc, [name]: name }),
  {}
)
