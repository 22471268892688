<template>
  <div>
    <div
      v-if="loading"
      class="item-thumbnail"
    >
      <skeleton-loader
        width="64px"
        height="64px"
        border-radius="50%"
      />
    </div>
    <button
      v-else
      v-tooltip.bottom="bagItem.item.name"
      :class="[
        'item-thumbnail',
        { '--hoverable': hoverable }
      ]"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <img
        class="item-thumbnail__image"
        :class="disabled ? '--disabled' : ''"
        :src="bagItem.item.image
          ? bagItem.item.image.svg
          : bookMissionPath"
      >
      <div
        v-if="bagItem.quantity >= 0 && !alreadyUsed"
        :class="[
          'item-thumbnail__quantity',
          { '--has-border': border }
        ]"
      >
        <span class="item-thumbnail__quantity__number">
          {{ bagItem.quantity }}
        </span>
      </div>
      <div
        v-else-if="alreadyUsed"
        class="item-thumbnail__icon"
      >
        <icon
          type="check"
          size="16"
          stroke="white"
          :stroke-width="5"
        />
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ItemThumbnail',
  props: {
    bagItem: {
      type: Object,
      required: true,
    },
    hoverable: {
      type: Boolean,
      default: true,
    },
    alreadyUsed: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bookMissionPath() {
      const image = require.context('@/assets/items/', false, /\.svg$/)

      return image('./missionBook.svg')
    },
  },
}
</script>

<style lang="sass" scoped>
.item-thumbnail
  background: #FBF1E1
  border: 5px solid rgba(255, 255, 255, 0.6)
  box-shadow: 0px 4px 0px #D9AF54
  border-radius: 12px
  padding: 12px
  position: relative
  +flex-center
  +transition($speed-fast)

  &:focus
    outline: none

  &.--hoverable
    cursor: pointer

    &:hover
      box-shadow: 0px 6px 0px #D9AF54
      transform: translateY(-2px)

    &:active
      box-shadow: 0px 1px 0px #D9AF54
      transform: translateY(3px)
      +transition($speed-x-fast)

  &__image
    width: 64px
    -webkit-user-drag: none

  &__quantity
    background: $color-white
    border: 3px solid $eureka-color-base-dark
    border-radius: 99px
    box-shadow: 0px 4px 0px #D9AF54
    padding: 4px
    bottom: 0px
    right: 6px
    position: absolute
    min-width: 30px
    +flex-center

    &__number
      font-weight: bold
      font-size: 15px
      line-height: 1

  &__icon
    background: #58B060
    border: 3px solid #58B060
    border-radius: 99px
    box-shadow: 0px 4px 0px #33813A
    padding: 4px
    position: absolute
    bottom: 0
    right: 6px
    min-width: 30px
    +flex-center

.--has-border
  bottom: -10px
  right: -12px

.--disabled
  opacity: 0.5
  cursor: not-allowed

</style>
