<template>
  <div class="thumbnails-list">
    <div class="thumbnails-list__count">
      <div
        class="thumbnails-list__count__body"
        @click="hideQuestionSelector = !hideQuestionSelector"
      >
        <GButton
          class="close-btn"
          :class="{ 'rotate-icon': hideQuestionSelector }"
          variation="secondary"
        >
          <img src="@/assets/arrow-down.svg">
        </GButton>
        <span>{{ thumbnailsCount }} questões</span>
      </div>
      <QuestionSelectorPopup
        v-if="isVisibleAddQuestionButton"
        @add-new-question="addNewQuestion"
      />
    </div>
    <div
      class="thumbnails-list__body"
      :class="{ 'hide-question-selector': hideQuestionSelector }"
    >
      <Thumbnail
        v-for="thumbnail in thumbnails"
        :key="thumbnail.order"
        class="thumbnails-list__thumbnail"
        :type="thumbnail.type"
        :order="thumbnail.order"
        :text="thumbnail.text"
        :is-selected="thumbnail.isSelected"
        :has-error="thumbnailHasError(thumbnail)"
        :is-orderable="thumbnailsCount > 1"
        :is-first="thumbnail.order === 1"
        :is-last="thumbnail.order === thumbnailsCount"
        :is-removable="isRemovable"
        @click.capture="select(thumbnail)"
        @delete-thumb="$emit('delete-thumb', thumbnail)"
        @order-up="setThumbnailOrder(thumbnail, -1)"
        @order-down="setThumbnailOrder(thumbnail, 1)"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import Thumbnail from '@/components/Thumbnail/Thumbnail'
import GButton from '@/components/GButton'
import QuestionSelectorPopup from './partials/QuestionSelectorPopup'

export default {
  name: 'ThumbnailsList',
  components: { Thumbnail, GButton, QuestionSelectorPopup },
  props: {
    thumbnails: {
      type: Array,
      required: true,
    },
    isRemovable: {
      type: Boolean,
      default: true,
    },
    isVisibleAddQuestionButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hideQuestionSelector: true,
    }
  },
  computed: {
    thumbnailsCount() {
      return this.thumbnails?.length ?? 0
    },
  },
  methods: {
    addNewQuestion() {
      this.$emit('add-new-question')
    },
    select(thumbnail) {
      this.$emit('select', thumbnail)
    },
    setThumbnailOrder(thumbnail, orderModifier) {
      const { length } = this.thumbnails

      if (length > 1) {
        const newOrder = thumbnail.order + orderModifier

        if (newOrder > 0 && newOrder <= length) {
          this.$emit('set-item-order', { ...thumbnail, newOrder })
        }
      }
    },
    thumbnailHasError(thumbnail) {
      const thumbnailIsNotValid = !thumbnail.validation?.questionIsValid
      const thumbnailHasSomeError = thumbnail.errors?.length > 0

      return thumbnailIsNotValid && thumbnailHasSomeError
    },
  },
}
</script>

<style lang="scss" scoped>
.thumbnails-list {
  width: min-content;
  background: rgba($eureka-color-ink, 0.5);

  @media screen and (max-width: 579px) {
    width: 100%;

    bottom: 0;
    z-index: 100;
    position: absolute;
    background: transparent;
  }

  &__body {
    gap: $size-m;
    display: flex;
    flex-direction: column;

    height: 100%;
    overflow-y: auto;
    padding: $size-s;

    background: rgba($eureka-color-ink, 0.75);

    @media screen and (max-width: 579px) {
      transition: all 0.3s;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-height: 367px;
      padding: $size-xs;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    font-family: Rowdies;
    font-style: normal;
    font-weight: $font-weight-regular;
    font-size: $font-size-heading-5;
    line-height: $line-height-heading;
    border-bottom: 2px solid rgba($color: $color-white, $alpha: 0.2);
    padding: $size-s;

    .close-btn {
      display: none;
      transition: all 0.5s;
    }

    @media screen and (max-width: 579px) {
      height: 75px;
      color: $eureka-color-ink !important;
      background: rgba($eureka-color-base-light, 1);
      border-bottom: 2px solid rgba($color: $color-black, $alpha: 0.2);
      border-radius: 6px !important;

      &__body {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $size-xs;
        height: 40px;
        width: 100%;

        span {
          margin-top: -2.5px;
        }
      }
      .close-btn {
        display: block;
      }
    }
  }
}

::-webkit-scrollbar {
  width: $size-xs;
}

::-webkit-scrollbar-track {
  background: rgba($color-black, 0.2);
}

::-webkit-scrollbar-thumb {
  background: #FBF1E1;

  @media screen and (max-width: 579px) {
    background: $eureka-color-ink;
  }

  box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: $size-s;

  &:hover {
    opacity: 0.8;
  }
}
.hide-question-selector {
  @media screen and (max-width: 579px) {
    max-height: 0;
    padding: 0;
  }
}

.rotate-icon {
  transform: rotate(-180deg);
}
</style>
