<template>
  <div class="timerform">
    <div class="timerform__checkbox">
      <input
        id="responsetimeflag"
        v-model="hasResponseTime"
        type="checkbox"
        name="responsetimeflag"
      >
      <label
        class="timeform__label"
        for="responsetimeflag"
      >Tempo de resposta</label>
    </div>
    <InputTimer
      :time="time"
      :is-disabled="!hasResponseTime"
      @change-time="sendTime"
    />
    <span class="timerform__message">( Máximo de 300 segundos)</span>
  </div>
</template>

<script>
import InputTimer from './partials/InputTimer'

export default {
  name: 'TimeForm',
  components: {
    InputTimer,
  },
  props: {
    isChecked: Boolean,
    time: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      hasResponseTime: this.isChecked,
    }
  },
  watch: {
    isChecked(newIsChecked) {
      this.hasResponseTime = newIsChecked
      this.sendTime(this.time)
    },
    hasResponseTime(newHasReponseTime) {
      this.$emit('change-response-time-flag', newHasReponseTime)
    },
    time(newTime) {
      this.sendTime(newTime)
    },
  },
  methods: {
    sendTime(time) {
      this.$emit('change-time', time)
    },
  },
}
</script>

<style lang="scss" scoped>
.timerform {
  display: inline-flex;
  align-items: center;
  gap: $size-s;

  &__message {
    font-family: Lato;
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
    color: $eureka-color-ink-light;
    line-height: $line-height-text;
    letter-spacing: 0.0275rem;

  }

  &__checkbox {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      width: $size-s;
      height: $size-s;
    }
  }
}

.timerform__message, .timeform__label {
  white-space: nowrap;
}
</style>
