var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scene"},[_c('CineMode',{attrs:{"background-scene":_vm.sceneBackgroundImage,"must-hide-header":!_vm.isXLargeBreakpoint},on:{"skip":function($event){return _vm.$emit('skip')}}},[_c('div',{staticClass:"scene__body",class:{ '--has-options': _vm.showOptions }},[(_vm.isDialogTemplate && _vm.actors.length)?_vm._l((_vm.actors),function({
              presentInNext,
              position,
              image,
              className,
              inEvidence,
              characterIsTude,
              characterIsRocco,
            },index){return _c('div',{key:index,class:[
            'scene__body__character',
            `--${position}`,
            { '--flip': !(position === 'left') },
            {
              'leave-transition':
                _vm.isSkiping || (_vm.goingToNext && !presentInNext),
            },
            { '--in-evidence': inEvidence },
            { '--tude-character': characterIsTude },
            { '--rocco-character': characterIsRocco },
          ]},[_c('img',{class:`${className}__image`,attrs:{"src":image.png || image.svg}})])}):_vm._e()],2)]),(_vm.isDialogTemplate && _vm.dialog)?_c('AdventureDialog',{class:['dialog', { 'leave-transition': _vm.isSkiping }],style:({ position: _vm.isMobile ? 'fixed': 'absolute' }),attrs:{"title":_vm.dialog.actor,"dialog":_vm.dialog.text,"hide-next-button":_vm.dialog.hideNextButton},on:{"next":function($event){return _vm.$emit('go-to-next-scene', _vm.scene)}}}):_vm._e(),(_vm.isDialogTemplate && _vm.options.length && _vm.showOptions)?_c('AdventureOptionsList',{attrs:{"options":_vm.options,"used-options":_vm.usedOptions},on:{"click":_vm.emitOption}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }