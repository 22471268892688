<template>
  <div
    class="game-base-layout"
    data-testid="game-wrapper"
  >
    <transition name="menu">
      <SMenu
        v-if="!$route.meta.hideHeader && !isAdaptUser && !isMFE"
      />
    </transition>
    <transition
      name="views"
      mode="out-in"
    >
      <GameError
        v-if="isShowError"
        :status="httpError"
      />
      <div
        v-else
      >
        <GLoader
          v-if="!user.data && !isMFE"
          ref="loader"
          class="load-state"
        />
        <router-view
          v-else
          @show-on-boarding="canShowOnBoarding = true"
          @splash-screen-visibility="setSplashScreenVisibility"
        />
      </div>
    </transition>
    <transition name="fade">
      <EurekaOnBoarding
        v-if="showBoard && onBoarding"
        @eurekaOnBoardingDone="eurekaOnBoardingDone"
      />
    </transition>
    <QuickUserPreferencesToggle />
    <Navigation :splash-screen-is-visible="splashScreenIsVisible" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import getGradeId from '@/mixins/getGradeId'
import gradeId from '@/mixins/gradeId'
import userIsAdapt from '@/mixins/userIsAdapt'
import storage from '@/utils/storage'
import { SMenu } from '@sas-te/main-menu-vue'

import GameError from '@/views/GameError'
import EurekaOnBoarding from '@/components/EurekaOnBoarding'
import GLoader from '@/components/GLoader'
import Navigation from '@/components/Navigation'
import mediaQueries from '@/mixins/mediaQueries'
import QuickUserPreferencesToggle from '@/components/QuickUserPreferencesToggle/QuickUserPreferencesToggle'

export default {
  name: 'GameBaseLayout',
  components: {
    GameError,
    EurekaOnBoarding,
    QuickUserPreferencesToggle,
    GLoader,
    SMenu,
    Navigation,
  },
  mixins: [ gradeId, getGradeId, mediaQueries, userIsAdapt ],
  data() {
    return {
      httpError: null,
      onBoarding: false,
      canShowOnBoarding: false,
      showHistoryReward: true,
      newsDrawerVisible: true,
      splashScreenIsVisible: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'theme',
      'userGrades',
      'currentGrade',
      'grade',
    ]),
    isMFE() {
      return window.eureka_init
    },
    isAdaptUser() {
      return this.userIsAdapt
    },
    isShowError() {
      return this.$te(`game.error.${this.httpError}`)
    },
    showBoard() {
      if (this.user?.data) {
        if (this.canShowOnBoarding) {
          const onBoardedUsers = storage.getItem('@onboarding/Eureka') ?? []

          return !onBoardedUsers.includes(this.user?.data?.id)
        }
      }

      return false
    },
    userIsHighSchoolStudent() {
      return this.user.data?.isHighSchoolStudent
    },
  },
  watch: {
    user() {
      const { data } = this.user
      const { name } = this.$route

      if (data && name === 'base-layout') {
        if (!data.userIsStudent) {
          if (name !== 'home-teacher') {
            this.$router.push({ name: 'home-teacher' })
          }
        } else if (name !== 'worlds') {
          this.$router.push({ name: 'worlds' })
        }
      }
    },
    userIsHighSchoolStudent: {
      immediate: true,
      handler(isHighSchoolStudent) {
        if (isHighSchoolStudent) {
          this.httpError = 403
        }
      },
    },
  },
  created() {
    const appName = this.$t('app.gameTitle')
    this.$setTitle(appName)

    if (this.gradeId) {
      this.setCurrentGrade({ id: this.gradeId })
    }

    if (this.$route.name === 'worlds') {
      this.setSplashScreenVisibility(true)
    }

    this.handleHttpErrorVisibility()
  },
  beforeDestroy() {
    this.destroyHandleHttpErrorVisibility()
  },
  methods: {
    ...mapActions([
      'setCurrentGrade',
    ]),
    httpErrorHandler(event) {
      this.httpError = event?.reason?.response?.status
    },
    handleHttpErrorVisibility() {
      if (this.shouldApplyHttpError()) {
        window.addEventListener('unhandledrejection', this.httpErrorHandler)
      }
    },
    destroyHandleHttpErrorVisibility() {
      window.removeEventListener('unhandledrejection', this.httpErrorHandler)
    },
    setSplashScreenVisibility(value) {
      this.splashScreenIsVisible = value
    },
    shouldApplyHttpError() {
      const routesToExclude = [ 'progression-paths', 'test-knowledge' ]

      if (routesToExclude.includes(this.$route.name)) {
        return false
      }

      return true
    },
    eurekaOnBoardingDone() {
      const onBoardedUsers = storage.getItem('@onboarding/Eureka') ?? []
      storage.setItem('@onboarding/Eureka', [
        ...onBoardedUsers,
        this.user?.data?.id,
      ])
      this.onBoarding = false
    },
  },
}
</script>

<style lang="scss" scoped>
.load-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.game-base-layout {
  height: 100%;
  background: linear-gradient(180deg, #C5CFF4 12.57%, #D9DFF3 93.88%);
}

.menu {
  &-enter-active {
    &,
    .content-menu {
      transition: all $speed-normal ease-in-out;
    }
  }

  &-leave-active {
    &,
    .content-menu {
      transition: all $speed-normal ease-in-out $speed-fast
    }
  }

  &-enter,
  &-leave-to {
    .content-menu {
      transform: translateY(-100%);
    }
  }
}

@include v-transition(views) {
  opacity: 0
}
</style>
