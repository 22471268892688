<template>
  <transition
    name="truthcompass-transition"
    appear
  >
    <Lottie
      v-if="visible"
      :options="defaultOptions"
      class="lottie"
      @animCreated="handleAnimation"
    />
  </transition>
</template>
<script>
import Lottie from 'vue-lottie'
import animationData from '@/assets/animations/compass-animation'

export default {
  name: 'CompassAnimation',
  components: {
    Lottie,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      defaultOptions: {
        animationData,
        loop: false,
      },
      animationSpeed: 1,
    }
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim
      setTimeout(() => this.$emit('anim-complete'), 5200)
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
  },
}
</script>
<style lang="sass" scoped>
.lottie
  position: fixed
  bottom: 0
  right: 0
  width: auto !important
  height: 100%
  transition: all 600ms ease-in-out
  ::v-deep svg
    width: auto !important
.truthcompass-transition
  &-enter-active,&-leave-active
    right: 0
  &-enter, &-leave-to
    right: -100%
</style>
