import urls from '@/utils/urls'
import api from './api'
import legacyApi from './legacy'

const configEureka = { baseURL: urls.EUREKA_BFF_API }

export default {
  getUser: () => api.get('v1/users/me', configEureka),
  getPersonalData: () => api.get('v2/personal-data', configEureka),
  getUserGrades: () => api.get('game/grades'),
  getSignedIn: () => api.get('/signin'),
  getUserGameData: (params) => api.get('/v2/students', { params }),
  getUserStats: () => api.get('/students/me/stats'),
  getReallocationDateLimit: () => legacyApi.get('/users/me/reallocation-login-date-limit'),
  postExchangeToken: (token) => api.post('/v1/exchange-token/onb-to-sas/', { token }, configEureka),
}
