<template>
  <div
    :class="[
      'world-title',
      `--${framed ? 'hasFrame' : 'hasNotFrame'}`,
      `--${size}`,
    ]"
  >
    <div class="world-title__content">
      <p class="world-title__content__title">
        {{ title }}
      </p>
      <div
        class="world-title__content__gems"
      >
        <DiamondDisplay
          :content="gems"
          theme-display="ocean-blue"
        />
      </div>
      <div
        v-if="!framed"
        class="world-title__content__badge"
      >
        <BadgeCount
          v-if="badgeCount && showBadge"
          class="item-badge__count"
          :count="badgeCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DiamondDisplay from '@/components/DiamondDisplay'
import BadgeCount from '@/components/BadgeCount'

export default {
  name: 'WorldTitle',
  components: {
    DiamondDisplay,
    BadgeCount,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    gems: {
      type: Object,
      default: () => {},
    },
    framed: {
      type: Boolean,
      default: true,
    },
    badgeCount: {
      type: Number,
      default: 0,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
}
</script>

<style lang="sass" scoped>
.world-title
  position: relative
  border-radius: 16px
  z-index: 1

  &.--large
    margin-top: 10px
    padding: 0 4px 9px 4px

    .world-title__content__title
      font-size: 40px

      @media screen and (max-width: 768px)
        font-size: 32px

  &.--small
    padding: 8px 4px 14px 4px

    .world-title__content
      &__title
        font-size: 24px

      &__gems
        width: max-content
        transform: scale(0.88)

  &__content
    +flex-center
    justify-content: flex-start
    flex-direction: column

    &__title
      text-align: center
      font-weight: 600
      color: $eureka-color-ink
      line-height: 1.25

    &__badge
      position: absolute
      text-align: center
      left: 100%
      top: 0

.--hasFrame
  background: $eureka-color-base
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.19)
  position: relative

  .world-title__content__gems
    position: absolute
    bottom: -20px

.--hasNotFrame
  background: transparent
  box-shadow: none
</style>
