<template>
  <div class="magna-mission-result-tab">
    <header class="magna-mission-result-tab__header">
      <div v-if="!resultsIsEmpty">
        <h1 class="magna-mission-result-tab__header__title">
          Partida
        </h1>
        <h4
          class="magna-mission-result-tab__header__subtitle"
        >
          Seu histórico de partidas com essa missão
        </h4>
      </div>
    </header>
    <ResultLoading v-if="magnaMissions.loading" />
    <EmptyState
      v-else-if="resultsIsEmpty"
      class="magna-mission-result-tab__empty-state"
      v-bind="emptyState"
    />
    <div
      v-else
      class="magna-mission-result-tab__card-list"
    >
      <MagnaMissionResultCard
        v-for="result in results"
        :key="result.code"
        :magna-mission="result"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@sas-te/frontend-utils/modules/arrays'
import coraWorried from '@/assets/char/cora/cora-worried.svg'
import EmptyState from '@/components/EmptyState'
import MagnaMissionResultCard from '@/views/AncestralArena/MagnaMissionDetail/partials/MagnaMissionResultCard'
import ResultLoading from '@/views/AncestralArena/MagnaMissionDetail/partials/ResultLoading'

export default {
  name: 'MagnaMissionResultTab',
  components: {
    MagnaMissionResultCard,
    ResultLoading,
    EmptyState,
  },
  props: {
    magnaMissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      emptyState: {
        illustration: {
          path: coraWorried,
          alt: '',
        },
        title: 'Você ainda não jogou essa missão com suas turmas',
        subtitle: 'Sempre que você jogar essa missão aqui será exibido histórico de cada partida.',
      },
    }
  },
  computed: {
    resultsIsEmpty() {
      return isEmpty(this.results)
    },
    results() {
      return this.magnaMissions.data.challenges?.filter(
        (item) => !item.canceled
      ).map((challenge, index) => ({
        ...challenge,
        order: index + 1,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-result-tab {
  width: 100%;

  gap: $size-m;
  display: flex;
  flex-direction: column;
  padding: $size-m $size-l;

  @media screen and (max-width: 579px) {
    padding: $size-m $size-s;
  }

  &__header {
    color: $color-white;

    &__title {
      font-family: Rowdies;
    }

    &__subtitle {
      font: {
        family: Lato;
        weight: $font-weight-medium;
      }
      letter-spacing: 0.0275rem;
    }
  }

  &__card-list {
    gap: $size-s;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 360px);

    ::-webkit-scrollbar-thumb {
      background: rgba($color-black, 0.1);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: rgba($color-black, 0.7);
    }
  }
}
</style>
