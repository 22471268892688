const production = {
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br/v1',
  AUTH_API_V2: 'https://auth-server.portalsaseducacao.com.br/v2',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  NEW_PORTAL_URL: 'https://app.portalsaseducacao.com.br',
  BASE_URL: 'https://eureka.portalsaseducacao.com.br',
  EUREKA_2020_URL: 'https://eureka-new.portalsaseducacao.com.br',
  ASSIGNMENT_REPORT_URL: 'https://assignment-report.portalsaseducacao.com.br',
  CROSS_PORTAL_ASSIGNMENT_REPORT_URL: 'https://app.portalsaseducacao.com.br/eureka/relatorios',
  PUBLIC_STORAGE_URL: 'https://storage.portalsaseducacao.com.br/public.com.br',
  // SAS DIGITAL
  ASSIGNMENTS_API: 'https://assignment-backend.sasdigital.com.br',
  EUREKA_BFF_API: 'https://api.sasdigital.com.br/eureka-frontend-bff',
  EUREKA_BFF_API_V2: 'https://api.sasdigital.com.br/eureka-frontend-bff/v2',
  STORAGE_API: 'https://storage-service.sasdigital.com.br',
  SAS_PORTAL_HOME_URL: 'https://home.portalsaseducacao.com.br/',
  CROSS_PORTAL_HOME_URL: 'https://app.portalsaseducacao.com.br/painel/',
}

const staging = {
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br/v1',
  AUTH_API_V2: 'https://auth-server.staging.portalsaseducacao.com.br/v2',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  NEW_PORTAL_URL: 'https://app-stg.portalsaseducacao.com.br',
  BASE_URL: 'https://eureka.staging.portalsaseducacao.com.br',
  EUREKA_2020_URL: 'https://eureka-new.staging.portalsaseducacao.com.br',
  ASSIGNMENT_REPORT_URL: 'https://assignment-report.staging.portalsaseducacao.com.br',
  CROSS_PORTAL_ASSIGNMENT_REPORT_URL: 'https://app-stg.portalsaseducacao.com.br/eureka/relatorios',
  PUBLIC_STORAGE_URL: 'https://storage.staging.portalsaseducacao.com.br/public',
  BFF_NEW_PORTAL: 'https://portal-bff-stg.portalsaseducacao.com.br/',
  // SAS DIGITAL
  ASSIGNMENTS_API: 'https://assignment-backend.staging.sasdigital.com.br',
  EUREKA_BFF_API: 'https://api.staging.sasdigital.com.br/eureka-frontend-bff',
  EUREKA_BFF_API_V2: 'https://api.staging.sasdigital.com.br/eureka-frontend-bff/v2',
  STORAGE_API: 'https://storage-service.staging.sasdigital.com.br',
  SAS_PORTAL_HOME_URL: 'https://home.staging.portalsaseducacao.com.br/',
  CROSS_PORTAL_HOME_URL: 'https://app-stg.portalsaseducacao.com.br/painel/',
}

const development = {
  ...staging,
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]
export const getPortalURL = (isMicrofrontend) => (isMicrofrontend
  ? urls.NEW_PORTAL_URL
  : urls.PORTAL_URL)

export const getPortalHomeURL = (isMicrofrontend) => (isMicrofrontend
  ? urls.CROSS_PORTAL_HOME_URL
  : urls.SAS_PORTAL_HOME_URL)

export const getAssignmentReportURL = (isMicrofrontend) => (isMicrofrontend
  ? urls.CROSS_PORTAL_ASSIGNMENT_REPORT_URL
  : urls.ASSIGNMENT_REPORT_URL)

export default urls
