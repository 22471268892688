<template>
  <ContentLoading v-if="magnaMissions.loading" />
  <div
    v-else
    class="magna-mission-content-tab"
  >
    <div class="magna-mission-content-tab__cards-container">
      <div
        class="magna-mission-content-tab__cards-container__title"
        @click="hideQuestionSelector = !hideQuestionSelector"
      >
        <GButton
          class="close-btn"
          :class="{ 'rotate-icon': hideQuestionSelector }"
          variation="secondary"
        >
          <img src="@/assets/arrow-down.svg">
        </GButton>
        {{ $t('commons.questions', { quantity: enigmaQuantity }) }}
      </div>
      <div
        class="magna-mission-content-tab__list-cards"
        :class="{ 'hide-question-selector': hideQuestionSelector }"
      >
        <Thumbnail
          v-for="enigma in enigmaList"
          :key="enigma.order"
          class="magna-mission-content-tab__thumbnail"
          type="readonly"
          :order="enigma.order"
          :text="enigma.text"
          :is-selected="selectedEnigma === enigma.order"
          @click="selectedEnigmaDetail(enigma)"
        />
      </div>
    </div>
    <AncestralArenaEnigma
      class="magna-mission-content-tab__enigma"
      :magna-mission-enigma="mappedMagnaMissionEnigma"
    >
      <template #enigmaAction>
        <div
          v-if="currentTimer"
          class="time"
        >
          Tempo de resposta
          <img
            class="time__icon"
            src="@/assets/timer.svg"
          >
          <span class="time__value">{{ currentTimer }} s</span>
        </div>
      </template>
    </AncestralArenaEnigma>
  </div>
</template>

<script>
import ContentLoading from '@/views/AncestralArena/MagnaMissionDetail/partials/ContentLoading'
import Thumbnail from '@/components/Thumbnail/Thumbnail'
import GButton from '@/components/GButton'
import AncestralArenaEnigma from '@/components/AncestralArena/AncestralArenaEnigma'
import { possibleLetters } from '@/utils/ancestralArenaUtils'
import { isEmpty } from '@sas-te/frontend-utils/modules/arrays'

export default {
  name: 'MagnaMissionContentTab',
  components: {
    ContentLoading,
    Thumbnail,
    GButton,
    AncestralArenaEnigma,
  },
  props: {
    magnaMissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      positionEnigma: 0,
      selectedEnigma: 1,
      hideQuestionSelector: true,
    }
  },
  computed: {
    mappedMagnaMissionEnigma() {
      const answers = this.magnaMissions.data.questions?.map((question, questionIndex) => ({
        ...question,
        timer: question.timer,
        hasTimer: Boolean(question.timer),
        statement: question.formattedWording,
        origin: question.origin,
        options: question.options.map((option, optionIndex) => ({
          ...option,
          letter: possibleLetters.find((_, index) => index === optionIndex),
          isCorrectOption: question.answerKey === option.id,
          variation: this.getCardVariation({
            optionId: option.id,
            correctAnswerKey: question.answerKey,
          }),
        })),
        magnaMissionInfo: {
          enigmaPosition: questionIndex + 1,
          totalEnigmas: null,
        },
      }))[this.positionEnigma]

      return answers
    },
    enigmaList() {
      const { questions } = this.magnaMissions.data

      if (isEmpty(questions)) return []

      return questions.map((question, index) => ({
        ...question,
        text: question.wording,
        order: index + 1,
      }))
    },
    currentTimer() {
      return this.selectedQuestion?.timer
    },
    selectedQuestion() {
      return this.enigmaList?.find(({ order }) => this.selectedEnigma === order)
    },
    enigmaQuantity() {
      return this.magnaMissions.data.questions?.length || 0
    },
  },
  methods: {
    selectedEnigmaDetail(enigma) {
      this.positionEnigma = (enigma.order - 1)
      this.selectedEnigma = enigma.order
    },
    getCardVariation({ optionId, correctAnswerKey }) {
      if (optionId === correctAnswerKey) {
        return 'secondary'
      }

      return 'tertiary'
    },
  },
}
</script>

<style lang="scss" scoped>
.magna-mission-content-tab {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__cards-container {
    display: flex;
    flex-direction: column;
    background: rgba($eureka-color-ink, 0.5);

    @media screen and (max-width: 579px) {
      position: absolute;
      width: 100%;
      z-index: 100;
      max-height: 397px;
      bottom: 0;
      background: rgba($eureka-color-ink, 0.75);
    }

    &__title {
      gap: $size-xs;
      display: flex;
      align-items: center;
      padding: 0 $size-s 0;

      height: 60px;
      line-height: 60px;

      .close-btn {
        display: none;
        transition: all 0.5s;
      }

      font: {
        size: $font-size-m;
        family: Rowdies;
        weight: $font-weight-medium;
      }
      color: $color-white;

      @media screen and (max-width: 579px) {
        color: $eureka-color-ink !important;
        background: rgba($eureka-color-base-light, 1);
        border-bottom: 2px solid rgba($color: $color-black, $alpha: 0.2);

        .close-btn {
          display: block;
        }
      }
    }
  }

  &__list-cards {
    width: 100%;
    gap: $size-s;
    padding: $size-s;

    display: flex;
    overflow-y: auto;
    flex-direction: column;
    max-height: calc(100vh - 240px);

    @media screen and (max-width: 579px) {
      flex-wrap: wrap;
      flex-direction: row;
      transition: all 0.3s;
      max-height: 397px;
    }
  }

  &__enigma {
    height: calc(100vh - 240px);
    width: 100%;
    margin: $size-m;

    @media screen and (max-width: 579px){
      margin: $size-s !important;
      height: calc(100vh - 260px);
    }

    ::v-deep .ancestral-arena-enigma__content {
      height: 100%;
      max-height: 100%;
    }

    .time {
      font-family: Rowdies;
      font-weight: $font-weight-light;
      font-size: $font-size-m;
      color: $eureka-color-ink-light;

      &__icon {
        vertical-align: text-bottom;
        filter: invert(29%) sepia(58%) saturate(445%)
        hue-rotate(208deg) brightness(60%) contrast(83%);
      }
      &__value {
        padding: $size-xs 0 $size-xs $size-xxs;
      }
    }
  }

  &__thumbnail {
    @media screen and (max-width: 579px) {
      width: calc(50% - 9px);
    }
  }
}

.--selected-enigma {
  ::v-deep .g-button {
    transition: none;
    border: 5px solid $eureka-color-secondary;
    box-shadow: 0 4px 0 #004EB3;
  }
}

::v-deep .ancestral-arena-enigma {
  max-height: none;

  &__header {
    background: $eureka-color-base;

    &__title {
      border-right: 1px solid rgba($eureka-color-ink, 0.2);
      padding-right: $size-s;

      &__current {
        font-size: $font-size-heading-4;
      }
    }
  }

  &__content {
    padding: $size-l;

    &__alternatives {
      margin-bottom: $size-xl;
    }
  }
}

.rotate-icon {
  transform: rotate(-180deg);
}

.hide-question-selector {
  @media screen and (max-width: 579px) {
    max-height: 0 !important;
    padding: 0;
  }
}
</style>
