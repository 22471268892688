<template>
  <div class="stats-card">
    <div class="stats-card__header">
      <p class="stats-card__header__title">
        {{ title }}
      </p>
      <icon
        v-tooltip.bottom="info"
        class="stats-card__header__info"
        type="help-circle"
        stroke="#CBA976"
        aria-label="Informações"
        data-testid="tooltip"
      />
    </div>
    <p class="stats-card__content">
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: [ String, Number ],
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.stats-card
  background: #FBF1E1
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.19), 0px 4px 4px #C19267
  border-radius: $size-s
  padding: 12px $size-s
  color: #382517

  &__header
    display: flex
    align-items: center

    &__title
      margin-right: $size-s
      opacity: 0.6
      font:
        weight: $font-weight-bold
        size: $size-s

      @media screen and (max-width: 768px)
        margin-right: $size-xs

    &__info
      width: 20px
      height: 20px

      ::v-deep &.feather
        overflow: visible

  &__content
    margin-top: $size-xs
    line-height: 24px
    font:
      weight: $font-weight-bold
      size: $size-m
</style>
