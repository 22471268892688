<template>
  <div class="feed-header-skeleton">
    <div class="feed-header-skeleton__title">
      <skeleton-loader
        height="18px"
        opacity="0.5"
        border-radius="8px"
      />
      <skeleton-loader
        height="18px"
        width="60%"
        opacity="0.5"
        border-radius="8px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedHeaderSkeleton',
}
</script>

<style lang="sass" scoped>
.feed-header-skeleton
  width: 100%

  &__title
    .sas-skeleton-loader:first-child
      margin-bottom: $size-xs
</style>
