<template>
  <div class="mission-report-card">
    <section class="mission-report-card__title">
      <span class="mission-report-card__title--text">
        {{ title }}
      </span>
    </section>
    <section class="mission-report-card__body">
      <slot name="customStyle" />
    </section>
    <section class="mission-report-card__content">
      <slot name="gridStyle" />
    </section>
  </div>
</template>

<script>
export default {
  name: 'MissionReportBody',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.mission-report-card
  &__title
    margin-top: $size-m
    text-align: center
    color: #382517
    opacity: 0.7

    &--text
      font-size: $font-size-m
      font-weight: bold

  &__body
    text-align: center

  &__content
    +flex-center
    flex-wrap: wrap
</style>
