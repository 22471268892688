<template>
  <div class="news-article">
    <template v-if="novelty.data">
      <FeedHeader
        v-if="novelty.data"
        :title="novelty.data.title"
        back-button="news-arrow-left.svg"
        :loading="novelty.loading"
        @back="previousScreen"
      />
      <ScrollContainer class="news-article__body">
        <NewsArticleSkeleton v-if="novelty.loading" />
        <NewsErrorState
          v-if="novelty.error"
          :title="$t('game.news.error.title')"
          :description="$t('game.news.error.description')"
          @click="getNovelty(articleId)"
        />
        <div
          v-else
          class="novelty"
          v-html="novelty.data.body"
        />
      </ScrollContainer>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ScrollContainer from '@/components/ScrollContainer'
import NewsErrorState from './partials/NewsErrorState'
import NewsArticleSkeleton from './partials/NewsArticleSkeleton'
import FeedHeader from './partials/FeedHeader'

export default {
  name: 'NewsArticle',
  components: {
    FeedHeader,
    NewsArticleSkeleton,
    NewsErrorState,
    ScrollContainer,
  },
  computed: {
    ...mapGetters([ 'novelty' ]),
    articleId() {
      return this.$route.params.articleId
    },
  },
  created() {
    if (this.articleId) {
      this.getNovelty(this.articleId)
    }
  },
  methods: {
    ...mapActions([
      'getNovelty',
      'getUnreadNews',
    ]),
    previousScreen() {
      this.getUnreadNews()
      this.$emit('back')
    },
  },
}
</script>

<style lang="scss">
.news-article {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.news-article__body {
  position: relative;
  flex-grow: 1;
  height: 100%;

  img {
    width: 100%;
    border-radius: $size-s;
    margin-bottom: $size-s;
  }

  p {
    margin-bottom: 1.2em;
  }

  .novelty {
    position: absolute;
    height: 100%;
    padding: 1rem;

    ul {
      margin-left: 40px;

      li {
        list-style: disc;
      }
    }
  }
}
</style>
