<template>
  <div class="ancestral-arena-editable-alternatives-list">
    <AncestralArenaCardQuestion
      v-for="(option, index) in options"
      :key="index"
      variation="editable"
      :option="option"
    >
      <template #description>
        <div class="ancestral-arena-editable-alternatives-list__editable-description">
          <SInput
            :id="`${option.letter}__description`"
            v-model="alternativeInputs[option.letter].value"
            :placeholder="option.placeholder"
            size="large"
            width="100%"
            autocomplete="off"
          />
        </div>
      </template>
      <template #icon>
        <div
          v-if="isTourEnabled || option.hasValue"
          class="ancestral-arena-editable-alternatives-list__alternative-selector"
        >
          <div
            class="tour__correct-alternative"
            :data-tour="`new-magna-mission-correct-alternative-${index}`"
          >
            <GRadio
              v-if="isTourEnabled || option.hasValue"
              v-model="selectedAlternativeItem"
              :error="option.missingAnswer"
              :radio-value="option.letter"
              name="radio-alternatives"
              :title="option.letter"
            />
          </div>
        </div>
      </template>
    </AncestralArenaCardQuestion>
    <AncestralArenaErrorReport
      v-if="errorMessageFeedback"
      :error-message="errorMessageFeedback"
    />
  </div>
</template>

<script>
import { SInput } from '@sas-te/alfabeto-vue'

import { questionHasRequiredAlternatives } from '@/utils/questionValidate'
import { possibleErrorsOnCreateMissions } from '@/utils/ancestralArenaUtils'
import { isNotEmpty, isEmpty } from '@sas-te/frontend-utils/modules/arrays'
import GRadio from '@/components/GRadio'
import AncestralArenaCardQuestion from './AncestralArenaCardQuestion/AncestralArenaCardQuestion'
import AncestralArenaErrorReport from '../AncestralArenaErrorReport/AncestralArenaErrorReport'

export default {
  name: 'AncestralArenaEditableAlternativesList',
  components: {
    AncestralArenaCardQuestion,
    SInput,
    GRadio,
    AncestralArenaErrorReport,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    selectedAlternative: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hasRequiredAlternatives: false,
    }
  },
  computed: {
    isTourEnabled() {
      return this.$tours?.onboarding?.isRunning
    },
    selectedAlternativeItem: {
      get() {
        return this.selectedAlternative
      },
      set(value) {
        this.$emit('selected-alternative-changes', value)
      },
    },
    alternativeInputs: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    errorMessageFeedback() {
      const hasErrorMessage = isNotEmpty(this.errorMessages)
      const hasMissingOptionsError = !this.hasRequiredAlternatives
        && Boolean(this.getErrorMessage('missingOptions')?.length)
      const hasMissingAnswerError = !this.selectedAlternativeItem
        && Boolean(this.getErrorMessage('missingAnswer')?.length)
      let message = ''

      if (!hasErrorMessage) return ''

      if (hasMissingOptionsError) {
        message = this.getErrorMessage('missingOptions')
      } else if (hasMissingAnswerError) {
        message = this.getErrorMessage('missingAnswer')
      }

      return (!this.hasRequiredAlternatives || !this.selectedAlternativeItem)
        && message ? message : ''
    },
    options() {
      return [{ letter: 'A' }, { letter: 'B' }, { letter: 'C' }, { letter: 'D' }]
        .map((option) => {
          const currentOption = this.alternativeInputs[option.letter]
          const currentOptionValue = currentOption.value

          return ({
            ...option,
            color: this.getCardColor(option.letter),
            placeholder: this.getCardPlaceholder(option),
            hasValue: Boolean(currentOptionValue.trim()),
            hasSomeError: Boolean(currentOption.missingOptions)
              && currentOptionValue.trim().length === 0
              && !this.hasRequiredAlternatives,
            missingAnswer: Boolean(currentOption.missingAnswer)
              && !this.selectedAlternativeItem,
          })
        })
    },
  },
  watch: {
    alternativeInputs: {
      deep: true,
      handler(value) {
        this.hasRequiredAlternatives = questionHasRequiredAlternatives({ alternatives: value })
      },
    },
  },
  methods: {
    getCardColor(option) {
      const colorsByLetter = {
        A: '#8A5BC0',
        B: '#48ACB3',
        C: '#E39F4F',
        D: '#D66565',
        E: '#48ACB3',
      }

      return colorsByLetter[option]
    },
    getCardPlaceholder(option) {
      const requiredAlternatives = [ 'A', 'B' ]

      return requiredAlternatives.includes(option.letter)
        ? 'Escreva a alternativa aqui'
        : 'Escreva a alternativa aqui (opcional)'
    },
    getErrorMessage(type) {
      return isEmpty(this.errorMessages) ? '' : this.errorMessages
        .find(({ error }) => error === possibleErrorsOnCreateMissions[type]).error
    },
  },
}
</script>

<style lang="scss" scoped>
$checkbox_width: 36px;
$checkbox_left_right_margin: 32px;

.ancestral-arena-editable-alternatives-list {
  &__editable-description {
    @include flex-center;
    padding: $size-s;
    flex: 1;
  }

  &__alternative-selector {
    @include flex-center;
    width: $checkbox_width + $checkbox_left_right_margin;

    .g-radio {
      width: max-content;
    }
  }
}

.ancestral-arena-card-question {
  margin-bottom: $size-s;
}

::v-deep .ancestral-arena-card-question {
  background-color: $eureka-color-base-lighter;

  &__info {
    cursor: auto;
  }
}

::v-deep .g-radio {
  .g-radio__input {
    width: $checkbox_width;
    height: $checkbox_width;
  }
}

::v-deep .sas-input {
  .sas-input__wrapper .sas-input__field {
    background-color: $eureka-color-base-lighter;
    box-shadow: none;
    border: 0;

    &::placeholder {
      color: $eureka-color-ink;
      opacity: 0.6;
      font-family: Rowdies;
    }
  }
}

.tour {
  &__correct-alternative {
    &.tour-active {
      padding: 2px 2px 5px 2px;
    }
  }
}
</style>
