<template>
  <div class="config-control">
    <div class="config-control__icon">
      <img
        :src="icon"
        alt="icon"
      >
      <label
        class="switch--shadow__label"
        :for="formatId"
      >{{ label }}</label>
    </div>
    <input
      :id="formatId"
      v-model="configControl"
      class="switch switch--shadow"
      type="checkbox"
    >
    <label
      class="switch--shadow__controller"
      :for="formatId"
    />
  </div>
</template>

<script>
import { playSoundFX } from '@/utils/soundEffects'
import { mapGetters } from 'vuex'
import soundOn from '@/assets/audio/light-sound-on.svg'

export default {
  name: 'ConfigControl',
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: soundOn,
    },
    controlId: {
      type: String,
      required: true,
    },
    controlValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([ 'soundPreferences' ]),
    configControl: {
      get() {
        return this.controlValue
      },
      set(newValue) {
        playSoundFX()
        this.$emit('valueChange', { id: this.controlId, value: newValue })
      },
    },
    controlActive() {
      return this.active
    },
    formatId() {
      return `switch-control-${this.controlId.toLowerCase()}`
    },
  },
}
</script>

<style lang="sass" scoped>
.config-control
  +flex-space-between

  &__icon
    gap: 8px
    height: 30px
    display: flex
    align-items: center
    justify-content: center

    img
      margin-bottom: 3px

.switch
  position: absolute
  margin-left: -9999px
  visibility: hidden

.switch--shadow
  &__label
    font-weight: 600
    font-size: $font-size-heading-6
    color: #382517

  &__controller
    width: $size-xxl
    height: $size-m
    background-color: #977657
    border-radius: $size-l
    transition: all 0.2s
    box-shadow: inset 0px 4px 0px rgba(149, 80, 0, 0.5)
    display: block
    position: relative
    cursor: pointer
    outline: none
    user-select: none

    &:after
      display: block
      position: absolute
      top: -2px
      left: 1px
      content: ''
      width: $size-m
      height: $size-m
      background: #EC7F00
      box-shadow: 0px 1px 0px 1px rgba(149,79,0,1)
      transition: all 0.3s
      border: 2px solid rgba(255, 255, 255, 0.27)
        radius: 100%

  &:checked + &__controller
    background-color: #EC7F00
  &:checked + &__controller:after
    transform: translateX(38px)
</style>
