<template>
  <div
    :class="['navigation-menu-border', {
      '--showBorderDarkMode': isLogosThemeActivated,
      '--collapsed': addMarginTop,
      '--logos': isLogosRoute
    }, ]"
  >
    <div
      :class="['navigation-menu', { '--darkModeMenu': isLogosThemeActivated }, ]"
      data-testid="navigation-menu"
    >
      <button
        v-if="isPreviousButtonVisible"
        data-testid="navigation-up-button"
        class="navigation-menu__button --control --control-up"
        :class="[
          {
            '--dark-mode-button': isLogosThemeActivated,
          },
        ]"
        @click="previousItem"
      >
        <img
          v-if="isLogosThemeActivated"
          src="@/assets/arrow-up-dark.svg"
        >
      </button>

      <div
        v-for="item in visibleItems"
        :key="item.id"
        data-testid="navigation-menu-item"
        :class="[
          'navigation-menu__item',
          { '--selected': isSelected(item),
            '--darkModeItem': isLogosThemeActivated,
          },
        ]"
      >
        <button
          class="navigation-menu__button"
          @click="navigateTo(item)"
        >
          <img
            class="navigation-menu__image"
            :src="iconImage(item)"
            alt=""
          >
          <span
            data-testid="navigation-menu-item-name"
            :class="['navigation-menu__description',
                     { '--isDarkModeText': isLogosThemeActivated },
            ]"
          >
            {{ item.name }}
          </span>
        </button>
        <div
          v-if="showBadge(item)"
          class="navigation-menu__badge item-badge"
          data-testid="navigation-notification-badge"
        >
          <span>{{ item.notificationCount }}</span>
        </div>
      </div>
      <button
        v-if="isNextButtonVisible"
        class="navigation-menu__button --control --control-down"
        :class="[
          {
            '--dark-mode-button': isLogosThemeActivated,
          },
        ]"
        @click="nextItem"
      >
        <img
          v-if="isLogosThemeActivated"
          src="@/assets/arrow-down-dark.svg"
        >
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { isEmpty } from '@sas-te/frontend-utils/modules/arrays'
import { playSoundFX } from '@/utils/soundEffects'
import { isNil } from 'lodash-es'
import mediaQueries from '@/mixins/mediaQueries'

const routesByMenuItem = {
  adventure: 'adventure',
  arena: 'arena',
  feedback: 'feedback',
  news: 'news',
  profile: 'profile',
  shop: 'shop',
  worlds: 'worlds',
  logos: 'logos',
}

const EIGHT_ITEMS_HEIGHT = 576
const NINE_ITEMS_HEIGHT = 768
const TEN_ITEMS_HEIGHT = 768

export default {
  name: 'NavigationMenu',
  mixins: [ mediaQueries ],
  props: {
    items: {
      type: Array,
      required: true,
    },
    modalComponentVisible: {
      type: String,
      default: '',
    },
    isLogosRoute: {
      type: Boolean,
    },
  },
  data() {
    return {
      maxVisibleItems: 4,
      windowHeight: window.innerHeight,
      indexFirstVisibleItem: 0,
      selectedItem: '',
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'importantNovelty',
      'userPreferences',
      'logosTheme',
    ]),
    addMarginTop() {
      return this.isPreviousButtonVisible || this.isNextButtonVisible
    },
    isScrollMenuOnLogosRoute() {
      return this.isLogosRoute && !this.mq_xl__mf
    },
    isDefaultHeight() {
      return this.windowHeight >= TEN_ITEMS_HEIGHT
    },
    isPreviousButtonVisible() {
      if (this.isDefaultHeight || this.isScrollMenuOnLogosRoute) {
        return false
      }

      return this.indexFirstVisibleItem > 0
    },
    isNextButtonVisible() {
      if (this.isDefaultHeight || this.isScrollMenuOnLogosRoute) {
        return false
      }

      return this.indexFirstVisibleItem < (this.items.length - this.maxVisibleItems)
    },
    itemsWithNotification() {
      return this.items
        .filter(({ notificationCount }) => notificationCount > 0)
    },
    notificationCountAtBottom() {
      const { itemsWithNotification } = this

      if (isEmpty(itemsWithNotification)) {
        return 0
      }

      const indexLastVisibleItem = this.indexFirstVisibleItem + this.maxVisibleItems - 1

      const count = itemsWithNotification
        .filter((element) => this.getElementIndex(element) > indexLastVisibleItem)
        .reduce((total, element) => total + element.notificationCount, 0)

      return count
    },
    notificationCountAtTop() {
      const { itemsWithNotification } = this

      if (isEmpty(itemsWithNotification)) {
        return 0
      }

      const count = itemsWithNotification
        .filter((element) => this.getElementIndex(element) < this.indexFirstVisibleItem)
        .reduce((total, element) => total + element.notificationCount, 0)

      return count
    },
    logosThemeUser() {
      return this.userPreferences.data?.logosTheme ?? false
    },
    isLogosThemeActivated() {
      const { path } = this.$route
      const isLogosPath = path.includes('/logos')

      const enableNightMode = (this.logosThemeUser || this.logosTheme) && isLogosPath

      if (enableNightMode) {
        this.selectItem('logos')
      }

      return enableNightMode
    },
    visibleItems() {
      if (this.isScrollMenuOnLogosRoute) {
        return this.items
      }

      if (this.windowHeight < TEN_ITEMS_HEIGHT && this.isDefaultHeight) {
        return this.items
      }

      const { indexFirstVisibleItem, maxVisibleItems, items } = this

      if (items.length <= maxVisibleItems || this.isDefaultHeight) {
        return this.items
      }

      return this.items.slice(
        indexFirstVisibleItem,
        indexFirstVisibleItem + maxVisibleItems
      )
    },
    controlBadgeIsVisibile(count) {
      return this.showBadge(count) && count > 0
    },
  },
  watch: {
    windowHeight: {
      immediate: true,
      handler() {
        if (this.windowHeight < EIGHT_ITEMS_HEIGHT) {
          this.maxVisibleItems = 4
        } else if (this.windowHeight < NINE_ITEMS_HEIGHT) {
          this.maxVisibleItems = 5
        } else if (this.windowHeight < TEN_ITEMS_HEIGHT) {
          this.maxVisibleItems = 6
        }
      },
    },
    importantNovelty: {
      immediate: true,
      handler(newImportantNovelty) {
        if (newImportantNovelty && this.modalComponentVisible !== 'news') {
          this.navigateTo({ slug: 'news', isModalComponent: true, isAutoModal: true })
        }
      },
    },
    modalComponentVisible: {
      immediate: true,
      handler() {
        if (!this.modalComponentVisible) {
          this.selectItemByRoute()
        }
      },
    },
    $route: {
      handler() {
        this.selectItemByRoute()
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight
    },
    iconImage(item) {
      if (this.isLogosThemeActivated) {
        return item.imageDark.svg
      }

      return item.image.svg
    },
    isSelected(item) {
      return item.slug === this.selectedItem
    },
    selectItem(item) {
      this.selectedItem = item
    },
    previousItem() {
      this.indexFirstVisibleItem -= 1
    },
    nextItem() {
      this.indexFirstVisibleItem += 1
    },
    navigateTo({ slug: section, isModalComponent, isAutoModal = false }) {
      const { name } = this.$route

      if (section !== name) {
        playSoundFX()

        this.$trackEvent({
          category: this.$track.category.menus,
          action: this.$track.action.enter,
          label: section,
        })

        if (isModalComponent) {
          this.selectItem(section)
          this.$emit('show-modal-component', { id: section, isAutoModal })
        } else {
          this.$router.push({
            name: section,
            query: { ...this.$route.query },
            params: {
              hasInitialSplashScreen: false,
              history: name,
              userIsStudent: this.user.data.userIsStudent,
            },
          })
        }
      }
    },
    showBadge(item) {
      const itemIsNull = isNil(item.notificationCount)
      const itemHasNotifications = item.notificationCount > 0

      return !itemIsNull && itemHasNotifications
    },
    selectItemByRoute() {
      const { name: routeName } = this.$route
      const menuItemRoutes = Object.keys(routesByMenuItem)
      const routeCorrespondingToItemMenu = menuItemRoutes.includes(routeName)

      if (routeCorrespondingToItemMenu) {
        const itemByRoute = menuItemRoutes.find((route) => route === routeName)

        this.selectItem(itemByRoute)
      } else {
        this.selectItem('')
      }
    },
    controlBadgeIsVisible(count) {
      return this.showBadge(count) && count > 0
    },
    getElementIndex(element) {
      return this.items.findIndex(({ id }) => id === element.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.navigation-menu__badge {
  @include flex-center;
  position: absolute;
  background: $eureka-color-danger;
  border-radius: 37px;
  width: 20px;
  height: 20px;
  color: $color-white;
  font-size: $font_size_xs;
  text-shadow: 0 1px 0 #000000;
}

::v-deep .navigation-menu__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-menu-border {
  &.--collapsed {
    @media screen and (min-width: 992px) {
      transform: translate(0, 55%) !important
    }
  }

  &.--logos {
    border: 0;

    @media screen and (max-width: 992px) {
      bottom: 0;
    }

    .navigation-menu {
      height: 100%;
      width: 100%;
      max-width: 640px;
      overflow-x: auto;

      &.--darkModeMenu {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: content-box;
        padding-bottom: 6px;
        overflow-y: hidden;
      }

      &__item {
        border: 0;
      }
    }
  }

  .navigation-menu {
    border: $size-xxs solid rgba(255, 255, 255, 0.28);
    box-sizing: border-box;
    box-shadow: 2px 3px 0 #D0AA4B, 0 7px 20px rgba(129, 95, 11, 0.58);
    border-radius: $size-s;
    background: #FBF1E1;
    display: flex;
    width: auto;
    flex-direction: row;
    overflow-x: auto;

    @media screen and (min-width: 991px) {
      flex-direction: column;
    }

    &:first-child {
      border-top-right-radius: $size-s;
      border-top-left-radius: $size-s;
    }

    &:last-child {
      border-bottom-right-radius: $size-s;
      border-bottom-left-radius: $size-s;
    }

    &.--darkModeMenu {
      background: #1B144A;
      border: 1px solid rgba($color-black, 1);
      box-shadow: none;
      border-radius: 16px;
    }

    &__item {
      width: 80px;
      height: 100%;
      border-bottom: solid 1px rgb(203,169,118, 0.5);
      position: relative;

      &:last-child {
        border-bottom: none;
      }

      &:first-child:hover {
        border-radius: 16px 0 0 16px;
      }

      &:hover {
        box-shadow: $shadow-m rgba(129, 95, 11, 0.20) inset;
      }

      &:last-child:hover {
        border-radius: 0 16px 16px 0;
      }

      @media screen and (min-width: 992px) {
        &:first-child:hover {
          border-radius: 16px 16px 0 0;
        }

        &:last-child:hover {
          border-radius: 0 0 16px 16px;
        }
      }

      &.--darkModeItem {
        border-bottom: solid 1px rgba(#1b30a1, 1);

        button {
          color: white;
        }

        &.--selected {
          background: #272D71;
        }

        &:hover {
          box-shadow: $shadow-m rgba(#1b30a1, 1) inset;
        }
      }

      &.--selected {
        background: #F9E0BF;
      }

      .item-badge {
        right: 2px;
        top: 4px;
      }
    }

  &__button {
    width: 80px;
    text-align: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 12px $size-xs;

    &.--dark-mode-button {
      background-color: #1B144A !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px rgba(#1b30a1, 1) !important;

      &:hover {
        background-color: #272D71 !important;
      }
    }

    &.--control {
      display: block;
      width: 80px;
      height: 24px;
      background-color: rgba(#F9E0BF, 0.32);

      &:hover {
        background-color: $eureka-color-base-darker
      }
    }

    &.--control-up {
      border-radius: $size_s $size_s 0 0;
      background-image: url('~@/assets/arrow-up.svg');
      background-repeat: no-repeat;
      background-position: center;
      border-bottom: solid 1px rgb(203, 169, 118, 0.5);
      transform: rotate(270deg);
      margin-top: 25px;
      margin-left: -25px;

      @media screen and (min-width: 992px) {
        transform: none;
        margin: 0;
      }
    }

    &.--control-down {
      border-radius: 0 0 $size_s $size_s;
      background-image: url('~@/assets/arrow-down.svg');
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(270deg);
      margin-top: 25px;
      margin-right: -27px;

      @media screen and (min-width: 992px) {
        transform: none;
        margin: 0;
      }
    }

    .control-badge {
      top: -$size-xxs;
      right: -12px;
    }
  }

  &__image {
    width: 32px;
    height: 32px;
  }

  &__description {
    display: block;
    font-family: Rowdies;
    font-weight: $font_weight_light;
    font-size: $font-size-s;
    line-height: 100%;
    text-align: center;
    padding-top: $size_xxs;
  }
  }
}

</style>
