<template>
  <div ref="lottie" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'Lottie',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const { path, loop, autoplay } = this.options

    this.anim = lottie.loadAnimation({
      container: this.$refs.lottie,
      renderer: 'svg',
      loop: loop || true,
      autoplay: autoplay || true,
      path,
    })
  },
}
</script>
