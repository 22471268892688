<template>
  <transition
    appear
    name="alert"
  >
    <div
      :class="[
        'g-alert',
        `--${variation}`,
      ]"
    >
      <icon
        v-if="iconType"
        class="g-alert__icon"
        :type="iconType"
      />
      <p class="g-alert__message">
        {{ message }}
      </p>
      <GButton
        v-if="hasRefreshButton"
        class="g-alert__button"
        variation="secondary"
        size="medium"
        @click="refresh"
      >
        Atualizar
      </GButton>
      <GButton
        v-if="isCloseable"
        class="g-alert__close-button"
        variation="secondary"
        size="medium"
        icon="x"
        @click="close"
      />
    </div>
  </transition>
</template>

<script>
import GButton from '@/components/GButton'

export default {
  name: 'GAlert',
  components: {
    GButton,
  },
  props: {
    variation: {
      type: String,
      default: 'error',
    },
    message: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    isCloseable: {
      type: Boolean,
      default: false,
    },
    hasRefreshButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconType() {
      if (this.icon) {
        return this.icon
      }

      switch (this.variation) {
        case 'error':
          return 'alert-triangle'
        default:
          return ''
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    refresh() {
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.g-alert {
  position: absolute;
  border-radius: 18px;
  padding: $size-s;
  @include flex-center-start;
  width: 100%;
  bottom: 4em;

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    ::v-deep svg {
       stroke-width: 5;
    }
  }

  &__message {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    line-height: 22px;
    word-break: break-word;
    width: 100%;
  }

  &__button {
    color: $color-danger-darker;
  }

  ::v-deep &__icon {
    svg {
      stroke-width: 3;
      margin-right: $size-m;
    }
  }

  &.--error {
    background: $color-danger-lightest;

    .g-alert__message {
      color: $color-danger-darker;
    }

    .feather {
      color: $color-danger-dark;
      margin-right: 14px;
      flex-shrink: 0;
    }

    ::v-deep svg {
      color: $color-danger-dark;
    }
  }
}

@include v-transition(alert) {
  opacity: 0;
  transform: translateY(10%);
}
</style>
