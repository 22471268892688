<template>
  <div class="level-modal-content">
    <div class="level-modal-content__feature">
      <div
        ref="level"
        class="level-modal-content__level"
      >
        <div class="level-modal-content__level__label">
          {{ $t('game.level') }}
        </div>
        <div class="level-modal-content__level__number">
          {{ level }}
        </div>
      </div>
      <div
        v-if="rank"
        ref="rank"
        class="level-modal-content__rank"
      >
        <div class="level-modal-content__rank__label">
          {{ $t('game.reward.rankLabel') }}
        </div>
        <img
          class="level-modal-content__rank__badge"
          :src="insigniaImage"
        >
        <div class="level-modal-content__rank__title">
          {{ insigniaTitle }}
        </div>
      </div>
    </div>
    <div class="level-modal-content__divider" />
    <p class="level-modal-content__items-label">
      Você ganhou
    </p>
    <div class="level-modal-content__items">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="level-modal-content__items__item"
      >
        <g-block
          :asset="`${item.type}.svg`"
          theme="bright"
        >
          {{ `+ ${item.value}` }}
        </g-block>
      </div>
    </div>
    <g-button
      class="level-modal-content__close"
      size="large"
      @click="$emit('close')"
    >
      {{ $t('game.reward.ok') }}
    </g-button>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

import GButton from '@/components/GButton'
import GBlock from '@/components/GBlock'

export default {
  name: 'LevelModalContent',
  components: {
    GButton,
    GBlock,
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showRank: false,
    }
  },
  computed: {
    ...mapGetters([
      'rewards',
    ]),
    coins() {
      return 1
    },
    rank() {
      if (this.rewards.data.length > 0) {
        const rankReward = this.rewards.data.find((reward) => (
          reward.type === 'rank'
        ))

        if (rankReward) {
          return rankReward.items[0]
        }
      }

      return false
    },
    insigniaImage() {
      return this.rank?.insignia?.image?.png || this.rank?.insignia?.image?.svg
    },
    insigniaTitle() {
      return this.rank?.insignia?.title || ' '
    },
  },
  mounted() {
    if (this.rank) {
      this.$nextTick(() => {
        const delay = 0.6
        const duration = 0.5
        const { level, rank } = this.$refs
        const tl = gsap.timeline()
        tl
          .fromTo(
            level,
            {
              x: 80,
              scale: 0,
            },
            {
              x: 80,
              scale: 1.2,
              duration,
              ease: 'power1.out',
            },
            delay
          )
          .to(
            level,
            {
              x: 0,
              scale: 1,
              duration,
              ease: 'power1.out',
            },
            '+=0.2'
          )
          .from(
            rank,
            {
              x: -80,
              duration,
              ease: 'power1.out',
            },
            `-=${duration}`
          )
          .from(
            rank,
            {
              autoAlpha: 0,
              duration,
              ease: 'power1.in',
            },
            `-=${duration}`
          )
      })
    }
  },
}
</script>

<!-- eslint-disable max-len -->
<style lang="sass" scoped>
$divider-bg: #f8e1c2

.level-modal-content
  padding: 26px 0
  position: relative
  width: 320px
  +flex-column-center

  &__feature
    position: relative
    margin-bottom: 32px
    padding: 0 16px
    width: 100%
    +flex-center

  &__level
    color: $color-white
    font-weight: bold
    line-height: 1
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.55)
    background-image: url(~@/assets/reward/level-up-star.svg)
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    height: 174px
    width: 160px
    margin-right: 10px
    transform: scale(1)
    z-index: 2
    +flex-column-center

    &__label,
    &__number
      position: relative
      top: -8px

    &__label
      font-size: 12px

    &__number
      font-size: 48px

    &:only-child
      margin-right: 0
      transform: scale(1.2)

  &__rank
    +flex-column-center
    max-width: 120px
    z-index: 1

    &__label
      color: transparentize($game-ink, 0.35)
      font-size: 14px
      font-weight: 600
      margin-bottom: 8px

    &__badge
      height: 56px
      margin-bottom: 4px

    &__title
      color: $color-ink
      font-size: 18px
      font-weight: bold
      line-height: 1.1
      text-align: center

  &__divider
    background: linear-gradient(90deg, transparentize($divider-bg, 1) 0%, $divider-bg 50%, transparentize($divider-bg, 1) 100%)
    width: 240px
    height: 2px
    margin-bottom: 8px

  &__items-label
    font-size: 14px
    font-weight: 500
    color: transparentize($game-ink, 0.3)
    margin-bottom: 12px

  &__items
    margin-bottom: 32px

    &__item
      display: inline-block
      +space-inline(24px)

  &__close
    width: 200px
.level-rank
  &-enter
    position: absolute
    left: 50%

  &-move
    +transition($speed-x-slow)
</style>
<!-- eslint-enable max-len -->
