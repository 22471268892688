import VimeoPlayer from '@vimeo/player'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([ 'user' ]),
  },
  methods: {
    setupPlayerEvents({ ...additional }) {
      const additionalParams = additional ? { ...additional } : {}

      setTimeout(() => {
        const iframe = document.querySelector('iframe')
        const player = new VimeoPlayer(iframe)

        player.on('play', () => {
          this.$trackEvent({
            category: this.$track.category.eureka,
            action: this.$track.action.watchChapterVideo,
            accountId: this.user.data.id,
            ...additionalParams,
          })
        })
      }, 100)
    },
  },
}
