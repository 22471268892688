<template>
  <div class="lobby-student-home-mission">
    <div class="countdown">
      <p class="countdown__text">
        Iniciando Missão
      </p>
      <BadgeCount
        class="countdown__badge"
        :disable-format-count="true"
        color="$eureka-color-notice"
        :count="count"
        size="large"
      />
    </div>
  </div>
</template>

<script>
import BadgeCount from '@/components/BadgeCount'

export default {
  name: 'MagnaMissionLobbyStudentHomeMission',
  components: {
    BadgeCount,
  },
  props: {
    badgeCount: {
      type: Number,
      default: 0,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      count: 3,
    }
  },
  watch: {
    count: {
      immediate: true,
      handler(count) {
        if (count === 0) {
          this.goToRoom()
        }
      },
    },
  },
  created() {
    this.startCountDown(this.count)
  },
  methods: {
    startCountDown(currentCount) {
      const countdownInterval = 1000
      if (currentCount < 0) return

      this.count = currentCount
      setTimeout(() => {
        if (currentCount > 0) this.startCountDown(currentCount - 1)
      }, countdownInterval)
    },
    goToRoom() {
      this.$router.replace({
        name: 'magna-mission-student-home-room',
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.lobby-student-home-mission {
  @include flex-column-center;
  height: 80vh;

  .countdown {
    transform: scale(4);

    @media screen and (max-width: 768px) {
      transform: scale(3);
    }

    &__badge {
      position: static;
      margin: 0 auto;
      background-color: $eureka-color-notice;
      font-family: Rowdies;
      font-weight: $font-weight-regular;
      font-size: 6rem;
      color: $color-white;
      text-shadow: 0px 1px 0px $color-black;
    }

    &__text {
      line-height: 2.5;
      font-size: $font-size-xs;
      text-shadow: 0 4px 4px $color-black;
      font-family: Rowdies;
      color: $color-white;
      font-weight: $font-weight-bold;
    }
  }
}
</style>
