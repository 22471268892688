<template>
  <div
    :class="[
      'profile-card',
      {'--has-teacher-top-bar': !userIsStudent }
    ]"
  >
    <div class="profile-card__picture">
      <img
        class="profile-card__picture__image"
        :src="profileImage"
      >
      <LevelDisplay
        class="user-status-bar__control"
        :level="userGameLevel"
        :rank="userGameRank"
        :show-level-number="false"
        :color="'#2D1F49'"
        rank-label-is-visible
      />
      <GButtton
        v-if="isAllowedChangeProfilePicture"
        asset="edit-icon.svg"
        class="profile-card__picture__edit-picture"
        icon-only
        size="small"
        @click="$emit('edit-profile')"
      />
    </div>
    <div class="profile-card__body">
      <h3 class="profile-card__body__title">
        {{ userNameTruncated }}
      </h3>
      <div class="profile-card__body__experience">
        <XpDisplay
          :experience="userExperience"
          :level="userGameLevel"
          :xp-amount="true"
          :has-frame="false"
          class="user-status-bar__control"
          variation="polygon"
        />
      </div>
      <div class="profile-card__body__wallet">
        <WalletDisplay
          class="profile-card__body__wallet--money"
          size="small"
          currency-type="coin"
          :quantity="userCoins"
        />
        <WalletDisplay
          class="profile-card__body__wallet--gems"
          size="small"
          currency-type="gem"
          :quantity="userGems"
        />
        <MagnaMissionTabs
          :tabs="tabs"
          variation="secondary"
          class="hide-in-mobile"
          @change-component="$emit('change-tab', $event)"
        />
      </div>
    </div>
    <MagnaMissionTabs
      :tabs="tabs"
      variation="secondary"
      class="hide-in-desktop"
      @change-component="$emit('change-tab', $event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { truncateText } from '@sas-te/frontend-utils/modules/truncate'

import GButtton from '@/components/GButton'
import LevelDisplay from '@/components/LevelDisplay'
import WalletDisplay from '@/components/WalletDisplay'
import XpDisplay from '@/components/XpDisplay'
import MagnaMissionTabs from '@/components/MagnaMissionTabs'
import charProfile from '@/assets/char/char-profile.svg'
import userPreferencesApi from '@/service/userPreferences'
import getGradeId from '@/mixins/getGradeId'
import userIsStudent from '@/mixins/userIsStudent'

export default {
  name: 'ProfileCard',
  components: {
    GButtton,
    LevelDisplay,
    WalletDisplay,
    XpDisplay,
    MagnaMissionTabs,
  },
  mixins: [ getGradeId, userIsStudent ],
  props: {
    userGameInfo: {
      type: Object,
      default: () => {},
    },
    gameIcon: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isAllowedChangeProfilePicture: false,
      tabs: [
        {
          id: 0,
          name: 'performance',
          component: 'PerformanceTab',
          text: 'Desempenho',
        },
        {
          id: 1,
          name: 'insignias',
          component: 'InsigniasTab',
          text: 'Insígnias',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([ 'user', 'hasAcademicRecords' ]),
    userNameTruncated() {
      return truncateText(this.userName, 34)
    },
    profileImage() {
      if (!this.gameIcon) return charProfile

      const { png, svg } = this.gameIcon.image

      return svg || png
    },
    userGems() {
      return this.userGameInfo?.gems || 0
    },
    userCoins() {
      return this.userGameInfo?.coin?.quantity || 0
    },
    userExperience() {
      return this.userGameInfo?.experience
        || { amount: 0, currentLevelValue: 0, nextLevelValue: 0 }
    },
    userGameLevel() {
      return this.userGameInfo?.level || 0
    },
    userGameRank() {
      return this.userGameInfo?.rank
    },
    userName() {
      return this.user?.data.name
    },
    hasNoTabAcademicRecords() {
      return this.tabs.some((tab) => tab.name === 'historico')
    },
  },
  watch: {
    hasAcademicRecords() {
      this.addAcademicRecordTab()
    },
  },
  created() {
    this.getAcademicRecords()
    this.addAcademicRecordTab()

    userPreferencesApi.getAllowedChangePicture(
      { gradeId: this.getGradeId() }
    ).then((response) => {
      this.isAllowedChangeProfilePicture = response?.status === 200
    }).catch(() => {
      this.isAllowedChangeProfilePicture = false
    })
  },
  methods: {
    ...mapActions([
      'getAcademicRecords',
    ]),
    addAcademicRecordTab() {
      if (this.userIsStudent
       && this.hasAcademicRecords
        && !this.hasNoTabAcademicRecords) {
        this.tabs.push({
          id: this.tabs.length,
          name: 'academic-records',
          component: 'AcademicRecordTab',
          text: 'Histórico',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-in-desktop {
  display: none;
}

@media screen and (max-width: 479px) {
  .hide-in-mobile {
    display: none;
  }
  .hide-in-desktop {
    display: flex;
  }
}

.profile-card.--has-teacher-top-bar {
  @media screen and (max-width: 479px) {
    padding-bottom: 65px;
  }
}

.profile-card {
  width: 100%;
  min-height: 225px;
  z-index: 3;
  gap: 32px;
  display: flex;
  position: relative;
  background: #FBF1E1;

  padding: $size-s;
  border-radius: $size-m;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.25), 0 4px 20px #C19267;

  @media screen and (max-width: 991px) {
    border-radius: 0 0 $size-m $size-m;
    gap: 24px;
  }

  @media screen and (max-width: 479px) {
    gap: 16px;
    padding-bottom: 45px;
  }

  &__picture {
    max-width: min-content;
    min-width: 152px;
    position: relative;
    padding: $size-xs;
    background-color: $color-white;

    box-shadow: 0 3px 0 #C2AB8C, inset 0 1px 0 rgba(255, 255, 255, 0.75);
    border-radius: 14px;

    @media screen and (max-width: 479px) {
      min-width: 122px;
    }

    &__edit-picture {
      position: absolute;
      right: 0;
      top: 0;

      border: none;
    }

    &__image {
      width: 136px;

      @media screen and (max-width: 479px) {
        width: 106px;
      }
    }
  }

  &__body {
    gap: $size-s;
    display: flex;
    flex-direction: column;

    &__title {
      color: $game-ink;
      font-family: Rowdies;
      font-weight: $font-weight-regular;
      font-size: 2rem;

      @media screen and (max-width: 991px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 479px) {
        font-size: 1.3rem;
        margin-top: 2px;
      }
    }

    &__wallet {
      gap: $size-l;
      display: flex;

      @media screen and (max-width: 479px) {
        gap: $size-s;
      }
    }
  }
}
::v-deep .user-status-bar__control {
  display: flex;
  align-items: center;
  justify-content: center;
  .level-display__badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .level-display__text__rank {
    align-items: center;
    justify-content: center;

    font: {
      family: Rowdies;
      size: 0.875rem;
      weight: $font-weight-regular;
    }
  }
}

::v-deep .g-button {
  transform: translateY(0);

  &__image {
    margin-right: 0;
  }
}

::v-deep .g-block.--bright.--small {
    box-shadow: 0 2px 0 #C2AB8C;
}
</style>
