<template>
  <div class="news-error-state">
    <img src="@/assets/news-error.svg">
    <div class="news-error-state__content">
      <h4>{{ title }}</h4>
      <p>{{ description }}</p>
    </div>
    <GButton @click="clickAction">
      {{ $t('game.news.error.action') }}
    </GButton>
  </div>
</template>

<script>
import { playSoundFX } from '@/utils/soundEffects'
import GButton from '../../GButton'

export default {
  name: 'NewsErrorState',
  components: { GButton },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  methods: {
    clickAction() {
      playSoundFX()
      this.$emit('click')
    },
  },
}
</script>

<style lang="sass" scoped>
.news-error-state
  align-self: center
  +flex-column-center
  max-width: 80%
  margin: auto

  &__content
    +flex-column-center
    margin: $size-m 0

    &:first-child
      margin-bottom: $size-xs
</style>
