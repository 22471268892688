<template>
  <section
    :class="[$style.container, { [$style.mfeContainer]: isMicrofrontend }]"
  >
    <ScrollContainer :class="$style.containerContent">
      <header :class="$style.header">
        <div
          v-if="difficultyLevel"
          :class="$style.badge"
        >
          {{ difficultyLevel }}
        </div>
        <div
          v-if="gradeName"
          :class="$style.badge"
        >
          {{ gradeName }}
        </div>
      </header>
      <div :class="$style.separator" />
      <div
        :class="$style.content"
        v-html="statement"
      />
      <div :class="$style.optionsContainer">
        <AncestralArenaCardQuestion
          v-for="option in questionOptions"
          :key="option.id"
          :option="option"
          :variation="option.variation"
          :disabled="option.isDisabled"
          :class="$style.options"
        />
      </div>
    </ScrollContainer>
    <footer :class="$style.footer">
      <GButton
        icon="arrow-left"
        size="medium"
        :icon-stroke="5"
        variation="primary"
        no-border
        :disabled="isFirstQuestion"
        @click="prevQuestion"
      />
      <GButton
        v-if="!isSelected"
        size="medium"
        variation="primary"
        no-border
        rowdies
        @click="selectQuestion"
      >
        Selecionar questão
      </GButton>
      <GButton
        v-else
        size="medium"
        variation="danger"
        no-border
        rowdies
        @click="removeQuestion"
      >
        Remover Questão
      </GButton>
      <GButton
        icon="arrow-right"
        size="medium"
        :icon-stroke="5"
        variation="primary"
        no-border
        :disabled="isLastQuestion"
        @click="nextQuestion"
      />
    </footer>
  </section>
</template>

<script>
import GButton from '@/components/GButton'
import { isEmpty } from 'lodash-es'
import ScrollContainer from '@/components/ScrollContainer'
import AncestralArenaCardQuestion from '@/components/AncestralArena/AncestralArenaCardQuestion/AncestralArenaCardQuestion'
import { possibleLetters } from '@/utils/ancestralArenaUtils'
import getIsMicrofrontend from '@/utils/getIsMicrofrontend'

const difficultyEnum = {
  EASY: 'Fácil',
  MEDIUM: 'Médio',
  HARD: 'Difícil',
}

export default {
  name: 'MagnaMissionContentPreview',
  components: {
    GButton,
    ScrollContainer,
    AncestralArenaCardQuestion,
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFirstQuestion: {
      type: Boolean,
      default: false,
    },
    isLastQuestion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMicrofrontend: false,
    }
  },
  computed: {
    hasQuestion() {
      return isEmpty(this.question)
    },
    statement() {
      return this.question?.statement ?? null
    },
    gradeName() {
      return this.question?.grade?.name ?? null
    },
    difficultyLevel() {
      return difficultyEnum[this.question?.difficultyLevel] ?? ''
    },
    questionOptions() {
      return (
        this.question?.options.map((option, optionIndex) => {
          const letter = possibleLetters.find((_, index) => index === optionIndex)
          const answerKey = Number(this.question?.answerKey)

          return {
            id: optionIndex,
            description: option.description,
            letter,
            isCorrectOption: answerKey === optionIndex,
            variation: this.getCardVariation({
              optionId: optionIndex,
              correctAnswerKey: answerKey,
            }),
            color: this.getCardColor(letter),
          }
        }) ?? []
      )
    },
  },
  beforeMount() {
    this.isMicrofrontend = getIsMicrofrontend()
  },
  methods: {
    selectQuestion() {
      this.$emit('on-select-question', this.question)
    },
    removeQuestion() {
      this.$emit('on-remove-question', this.question)
    },
    prevQuestion() {
      this.$emit('on-prev-question', this.question)
    },
    nextQuestion() {
      this.$emit('on-next-question', this.question)
    },
    getCardVariation({ optionId, correctAnswerKey }) {
      if (optionId === correctAnswerKey) {
        return 'secondary'
      }

      return 'tertiary'
    },
    getCardColor(option) {
      const colorsByLetter = {
        A: '#8A5BC0',
        B: '#48ACB3',
        C: '#E39F4F',
        D: '#D66565',
        E: '#48ACB3',
      }

      return colorsByLetter[option]
    },
  },
}
</script>
<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex-grow: 1;
  border-radius: 16px;
  background: $eureka-color-base;
  box-shadow: inset 0px 1px 0px rgba($color-white, 0.75);
  filter: drop-shadow(0px 3px 0px #c2ab8c);
  height: 83vh;
  max-width: 100%;

  @media screen and (max-width: 1200px) {
    height: calc(100vh - 200px);
  }

  @media screen and (max-width: 991px) {
    height: calc(100vh);
    margin: 0 $size-s;
  }
}

.containerContent {
  padding: $size-s;
  height: 100%;
  background: $eureka-color-base;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  --trackColor: #EFDABE !important;
  --scrollbar-width: .75rem !important;

  @media screen and (max-width: 1200px) {
    padding: $size-xs;
    margin-bottom: 76px;
  }

  &::-webkit-scrollbar-thumb {
    background: $eureka-color-base-darker !important;
    border: 8px solid #E4CCAA;
    box-shadow: inset -1px -4px 0px #CCA56D;
  }
  &::-webkit-scrollbar {
    padding: 0.5rem;
  }
}

.mfeContainer {
  height: 80vh;
}

.header {
  display: flex;
  flex-direction: row;
  gap: $size-xs;
  font-family: Rowdies;
  font-weight: $font-weight-regular;
  font-size: $font-size-s;
  text-align: center;
  color: #161616;
  background-color: $color-white;
  box-shadow: 0px 1px 0px $color-ink-lightest;
  border-radius: 16px 16px 0 0;
  padding: 8px;
}

.badge {
  padding: $size-xs 12px;
  background: $eureka_color_ice;
  border: 1px solid $eureka_color_ink_lightest;
  border-radius: $size-l;
  line-height: $line-height-reset;
}

.separator {
  border-bottom: 1px solid $color-ink-lightest;
}

.content {
  padding: $size-s $size-m;
  font-family: Rowdies;
  font-weight: $font-weight-light;
  font-size: $font-size-m;
  color: $eureka-color-ink;
  background-color: $color-white;
  flex: 1;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  max-width: 100%;
  scrollbar-color: $eureka-color-base-light rgba(#0d0425, 0.32);

  table {
    max-width: 100%;
  }

  img {
    max-width: 100%;
  }
}

.optionsContainer {
  background-color: $color-white;
  gap: $size-s;
  padding: $size-s;
  display: flex;
  flex-direction: column;
}

.options {
  cursor: auto !important;
}

.footer {
  padding: $size-s $size-m $size-xs;
  gap: $size-xl;
  display: flex;
  justify-content: center;
  border-top: 1px solid #efdabe;
}
</style>
