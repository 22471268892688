<template>
  <div class="adventure-episode-card">
    <skeleton-loader
      v-if="loading"
      :border-radius="$tokens.size_s"
      height="118px"
    />
    <GameCard
      v-else
      :title="title"
      :number="number"
      :img="imgSrc"
      :is-locked="locked"
      :is-new="isNew"
      @click="$emit('click')"
    >
      <template
        v-if="locked"
        #gems
      >
        <WalletDisplay
          currency-type="gem"
          :quantity="minGems"
          theme-color="ocean-blue"
        />
      </template>
      <template v-if="rewards.length">
        <div class="adventure-episode-card__reward">
          <p class="adventure-episode-card__reward__title">
            {{ $t('game.adventure.reward') }}
          </p>
          <div
            v-if="rewards.length"
            class="adventure-episode-card__reward__item"
          >
            <itemReward
              v-for="(reward, index) in rewards"
              :key="index"
              :item-image="itemRewardImage(index)"
              :locked-item="locked"
              :quantity="reward.quantity"
              :acquired="rewardReceived"
              class="item-reward"
            />
          </div>
        </div>
      </template>
    </GameCard>
  </div>
</template>
<script>
/* eslint-disable global-require */
import WalletDisplay from '@/components/WalletDisplay'
import GameCard from '@/components/GameCard'
import ItemReward from '@/components/Adventure/ItemReward'

export default {
  name: 'AdventureEpisodeCard',
  components: {
    GameCard,
    WalletDisplay,
    ItemReward,
  },
  props: {
    cover: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    minGems: {
      type: Number,
      default: null,
    },
    rewards: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    rewardReceived: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imgSrc() {
      const { locked } = this

      return locked
        ? require('@/assets/locked.svg')
        : this.cover
    },
  },
  methods: {
    itemRewardImage(itemIndex) {
      const { item } = this.rewards[itemIndex]

      return this.locked
        ? item.imageSilhouette.svg
        : item.image.svg
    },
  },
}
</script>
<style lang="scss" scoped>
.adventure-episode-card {
  ::v-deep .game-card {
    &__body {
      &__description {
        font-size: $size-m;
        color: $eureka-color-ink;
        font-weight: $font-weight-bold;
      }
    }
  }

  &__reward {
    @include flex-center-start;
    flex-wrap: wrap;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    line-height: 150%;
    align-items: center;
    color: $eureka-color-ink;
    opacity: 0.75;
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid $eureka-color-base;
    box-sizing: border-box;
    border-radius: $border-radius-m;
    width: fit-content;

    &__title {
      margin-right: $size-xs;
    }

    &__item {
      display: inherit;
    }
  }
}

.item-reward {
  margin-right: $size-s;
}
</style>
