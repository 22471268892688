<template>
  <div class="adventure-options-list">
    <div class="adventure-options-list__container">
      <div
        v-for="option in options"
        :key="option.text"
        class="adventure-options-list__wrapper"
      >
        <AdventureOptions
          :option="option"
          :disabled="optionAlreadyUsed(option)"
          @click="selectOption(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import AdventureOptions from './AdventureOptions'

export default {
  name: 'AdventureOptionsList',
  components: { AdventureOptions },
  props: {
    options: {
      type: Array,
      required: true,
    },
    usedOptions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    gsap.from('.adventure-options-list__wrapper', {
      y: 50,
      opacity: 0,
      stagger: 0.1,
      duration: 0.5,
    })
  },
  methods: {
    optionAlreadyUsed({ text }) {
      return Boolean(this.usedOptions.find((option) => option === text))
    },
    selectOption(option) {
      setTimeout(() => this.$emit('click', { ...option }), 300)
    },
  },
}
</script>

<style lang="sass" scoped>
$cine-mode-footer-height: 5%
$adventure-dialog-height: 122px

.adventure-options-list
  position: relative
  width: 100%
  height: calc(100vh - #{$cine-mode-footer-height} - #{$adventure-dialog-height})
  display: flex
  justify-content: flex-end
  align-items: center
  bottom: 48px

  &__container
    display: grid
    grid-template-columns: 1fr
    grid-area: right

    :not(:last-child)
      margin-bottom: 16px

::v-deep .adventure-options
  animation: fade-in 1s
</style>
